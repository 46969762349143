import { apiCommonSite } from '@/api/logisticsOrders';
import { feePage } from '@/api/finance/partition';
import store from '@/store';

/**
 * 获取网点、费用类型的下拉数据
 * @returns {Promise<any[]>} 返回结果
 */
export const initGetData = (() => {
  const feeTypeList = 'feeTypeList'; // 获取费用类型
  const siteList = 'siteList'; // 获取网点

  // 从缓存中提前数据
  const getStorageData = (key) => {
    if (store.state.feesPayable[key] && store.state.feesPayable[key].length > 0) {
      return store.state.feesPayable[key];
    }
    return undefined;
  };

  /**
   * 获取网点数据
   * @returns
   */
  const paramsSite = {
    pageNumber: 1,
    pageSize: 999999,
    params: {
      siteStatus: 1, // 1 启用 0 禁用
      name: ''
    }
  };
  const getSitePageList = () => {
    const arr = getStorageData(siteList);

    if (arr) {
      return Promise.resolve(arr);
    }
    return apiCommonSite(paramsSite, false).then(res => {
      if (res.code === 200) {
        const { records } = res.data;
        const list = records.map((item) => {
          return {
            id: item.id,
            name: item.name
          };
        });

        store.commit('feesPayable/SET_OUTLETS_LIST', list);
        return records;
      }
      throw new Error('获取网点数据失败');
    }).catch(err => {
      console.error(err);
      return [];
    });
  };

  /**
   * 获取费用类型
   * @returns
   */
  const paramsFee = {
    orderDirection: 'DESC',
    pageNumber: 1,
    pageSize: 999999,
    params: {
      state: 1, // 1 有效，0 无效
      attribute: 2, // 1 应收，2 应付
      businessType: '',
      accountType: 2 // 1 应付，2 成本
    }
  };
  const getFeeOptionList = () => {
    const arr = getStorageData(feeTypeList);

    if (arr) {
      return Promise.resolve(arr);
    }
    return feePage(paramsFee, false).then(res => {
      // @ts-ignore
      if (res.code === 200) {
        const { records } = res.data;

        store.commit('feesPayable/SET_FEE_TYPE_OPTION', records);
        return records;
      }
      throw new Error('获取费用类型数据失败');
    }).catch(err => {
      console.error(err);
      return [];
    });
  };
  // 初始化数据
  return () => {
    const arr = [getSitePageList(), getFeeOptionList()];
    return Promise.all(arr).then(res => {
      console.log(res, 'initGetData-缓存数据');
      return res;
    });
  };
})();
