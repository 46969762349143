<!--服务商数据-->
<template>
  <ElSelectV2
    v-model="value"
    :multiple="multiple"
    clearable
    :options="options"
    filterable
    size="small"
    :placeholder="$t('operationCenter.PleaseSelect')"
    style="width: 100%"
    @change="onChange"
  />
</template>

<script>
import ElSelectV2 from 'el-select-v2';

export default {
  name: '',

  components: {
    ElSelectV2
  },

  props: {
    // 是否多选
    multiple: {
      type: Boolean,
      default: false
    },

    // 是否可以清空选项
    clearable: {
      type: Boolean,
      default: true
    },

    // 多选时用户最多可以选择的项目数，为 0 则不限制
    multipleLimit: {
      type: Number,
      default: 0
    },

    // id集合 用于回显
    val: {
      type: [String, Number],
      default: () => ''
    },

    // 分区明细
    list: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      value: ''
    };
  },

  computed: {
    options() {
      return this.list.map(item => ({
        ...item,
        label: item.partitionIdentifier,
        value: item.id
      }));
    }
  },

  watch: {
    val: {
      immediate: true,
      handler(val) {
        if (Array.isArray(val)) {
          this.value = [...val];
        } else if (typeof val === 'number') {
          this.value = val;
        } else {
          this.value = val || '';
        }
      }
    }
  },

  methods: {
    onChange(val) {
      this.$emit('change', val);
    }
  }
};
</script>
