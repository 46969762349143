var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "top", size: "small" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("template", { slot: "label" }, [
                                    _c(
                                      "div",
                                      { staticClass: "selectLabel" },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            model: {
                                              value:
                                                _vm.queryForm.params.numberType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.queryForm.params,
                                                  "numberType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "queryForm.params.numberType",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              key: "1",
                                              attrs: {
                                                label: _vm.$t(
                                                  "GlobalSetObj.waybillNumber"
                                                ),
                                                value: 0,
                                              },
                                            }),
                                            _c("el-option", {
                                              key: "2",
                                              attrs: {
                                                label: _vm.$t(
                                                  "GlobalSetObj.ScanNumber"
                                                ),
                                                value: 1,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "GlobalSetObj.CanBeEnteredMultiple2000"
                                      ),
                                      rows: 5,
                                      type: "textarea",
                                    },
                                    model: {
                                      value: _vm.returnWaybillNumber,
                                      callback: function ($$v) {
                                        _vm.returnWaybillNumber = $$v
                                      },
                                      expression: "returnWaybillNumber",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 19 } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 9 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("template", { slot: "label" }, [
                                    _c(
                                      "div",
                                      { staticClass: "selectLabel" },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            model: {
                                              value:
                                                _vm.queryForm.params.timeType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.queryForm.params,
                                                  "timeType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "queryForm.params.timeType",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              key: "1",
                                              attrs: {
                                                label: _vm.$t(
                                                  "collectionCenter.RegistrationT"
                                                ),
                                                value: 1,
                                              },
                                            }),
                                            _c("el-option", {
                                              key: "2",
                                              attrs: {
                                                label: _vm.$t(
                                                  "GlobalSetObj.newReplyTime"
                                                ),
                                                value: 2,
                                              },
                                            }),
                                            _c("el-option", {
                                              key: "3",
                                              attrs: {
                                                label: _vm.$t(
                                                  "GlobalSetObj.Deadline"
                                                ),
                                                value: 3,
                                              },
                                            }),
                                            _c("el-option", {
                                              key: "4",
                                              attrs: {
                                                label: _vm.$t(
                                                  "driver.closedLoopTime"
                                                ),
                                                value: 4,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: false,
                                      "default-time": ["00:00:00", "23:59:59"],
                                      format:
                                        _vm.$i18n.locale === "zh"
                                          ? _vm.zhDate
                                          : _vm.ptDate,
                                      placeholder: _vm.$t(
                                        "newOrder.SelectDatetime"
                                      ),
                                      "range-separator": "~",
                                      type: "datetimerange",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                    },
                                    model: {
                                      value: _vm.timeValue,
                                      callback: function ($$v) {
                                        _vm.timeValue = $$v
                                      },
                                      expression: "timeValue",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 5 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "GlobalSetObj.ProcessingStatus"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "GlobalSetObj.pleaseChoose"
                                        ),
                                        clearable: "",
                                        "collapse-tags": "",
                                        multiple: "",
                                      },
                                      model: {
                                        value:
                                          _vm.queryForm.params
                                            .processingStateList,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "processingStateList",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.processingStateList",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        key: "2",
                                        attrs: {
                                          label: _vm.$t("GlobalSetObj.Pending"),
                                          value: 0,
                                        },
                                      }),
                                      _c("el-option", {
                                        key: "3",
                                        attrs: {
                                          label: _vm.$t("newOrder.processing"),
                                          value: 1,
                                        },
                                      }),
                                      _c("el-option", {
                                        key: "4",
                                        attrs: {
                                          label: _vm.$t(
                                            "collectionCenter.closedLoop"
                                          ),
                                          value: 2,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 5 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "collectionCenter.processingResult"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "GlobalSetObj.pleaseChoose"
                                        ),
                                        clearable: "",
                                        "collapse-tags": "",
                                        multiple: "",
                                      },
                                      model: {
                                        value:
                                          _vm.queryForm.params.processingResult,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "processingResult",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.processingResult",
                                      },
                                    },
                                    _vm._l(
                                      _vm.processingResultList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 5 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "orderCenterCont.TimeoutOrNot"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "GlobalSetObj.pleaseChoose"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.queryForm.params.istimeOut,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "istimeOut",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.istimeOut",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t("GlobalSetObj.all"),
                                          value: null,
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t("GlobalSetObj.yes"),
                                          value: 1,
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t("GlobalSetObj.No"),
                                          value: 0,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.client"),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "GlobalSetObj.pleaseChoose"
                                        ),
                                        "remote-method": _vm.getCustomerPage,
                                        clearable: "",
                                        filterable: "",
                                        multiple: "",
                                        remote: "",
                                        "reserve-keyword": "",
                                      },
                                      on: { clear: _vm.getCustomerPage },
                                      model: {
                                        value: _vm.queryForm.params.customerId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "customerId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.customerId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.customerList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label:
                                              item.customerAlias +
                                              "（" +
                                              item.username +
                                              "）",
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 5 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("placeOrder.CommercialOwner"),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseChoose"
                                        ),
                                        "remote-method": _vm.getShanghu,
                                        clearable: "",
                                        filterable: "",
                                        multiple: "",
                                        remote: "",
                                        "reserve-keyword": "",
                                      },
                                      on: { clear: _vm.getShanghu },
                                      model: {
                                        value:
                                          _vm.queryForm.params.merchantName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "merchantName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.merchantName",
                                      },
                                    },
                                    _vm._l(_vm.commercialList, function (item) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: item.id,
                                          attrs: {
                                            label: item.merchantName,
                                            value: item.merchantName,
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _c("span", [
                                              _vm._v(_vm._s(item.merchantName)),
                                            ]),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "30px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.merchantCode)
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 5 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "collectionCenter.stateSite1"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "GlobalSetObj.pleaseChoose"
                                        ),
                                        clearable: "",
                                        filterable: "",
                                        multiple: "",
                                      },
                                      model: {
                                        value: _vm.queryForm.params.state,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "state",
                                            $$v
                                          )
                                        },
                                        expression: "queryForm.params.state",
                                      },
                                    },
                                    _vm._l(_vm.stateList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.nameEn,
                                          value: item.twoCode,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 5 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.entrega"),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "GlobalSetObj.pleaseChoose"
                                        ),
                                        "remote-method": _vm.getAllSiteList,
                                        clearable: "",
                                        filterable: "",
                                        multiple: "",
                                        remote: "",
                                        "reserve-keyword": "",
                                      },
                                      on: { clear: _vm.getAllSiteList },
                                      model: {
                                        value: _vm.queryForm.params.siteName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "siteName",
                                            $$v
                                          )
                                        },
                                        expression: "queryForm.params.siteName",
                                      },
                                    },
                                    _vm._l(_vm.siteList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.name,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 5 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("collectionCenter.registrar"),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "GlobalSetObj.pleaseChoose"
                                        ),
                                        "remote-method":
                                          _vm.getRegisterUserList,
                                        clearable: "",
                                        filterable: "",
                                        multiple: "",
                                        remote: "",
                                        "reserve-keyword": "",
                                      },
                                      on: {
                                        clear: function ($event) {
                                          return _vm.getRegisterUserList()
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.queryForm.params.registerUserId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm.params,
                                            "registerUserId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryForm.params.registerUserId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.registerUserList,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.username,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-search",
                    size: "small",
                    type: "primary",
                  },
                  on: { click: _vm.enterSearch },
                },
                [_vm._v(_vm._s(_vm.$t("newOrder.search")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-refresh-left",
                    size: "small",
                    type: "info",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.resetForm()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("newOrder.reset")) + " ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:complaintWorkOrder:bulkImport",
                      expression: "'btn:complaintWorkOrder:bulkImport'",
                      arg: "remove",
                    },
                  ],
                  attrs: { icon: "el-icon-upload2", size: "small" },
                  on: { click: _vm.allImportOrder },
                },
                [_vm._v(_vm._s(_vm.$t("newOrder.bulkImport")) + " ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:complaintWorkOrder:fixTime",
                      expression: "'btn:complaintWorkOrder:fixTime'",
                      arg: "remove",
                    },
                  ],
                  attrs: { size: "small" },
                  on: { click: _vm.clickDeadline },
                },
                [_vm._v(" " + _vm._s(_vm.$t("home.fixTime")) + " ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:complaintWorkOrder:fixResult",
                      expression: "'btn:complaintWorkOrder:fixResult'",
                      arg: "remove",
                    },
                  ],
                  attrs: { size: "small" },
                  on: { click: _vm.clickFixResult },
                },
                [_vm._v(" " + _vm._s(_vm.$t("home.fixResult")) + " ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:complaintWorkOrder:close",
                      expression: "'btn:complaintWorkOrder:close'",
                      arg: "remove",
                    },
                  ],
                  attrs: { icon: "el-icon-circle-close", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.closeOrder()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("GlobalSetObj.Close")) + " ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:complaintWorkOrder:export",
                      expression: "'btn:complaintWorkOrder:export'",
                      arg: "remove",
                    },
                  ],
                  attrs: { icon: "el-icon-upload2", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.exportOrder()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("navbar.export")) + " ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:complaintWorkOrder:exportImage",
                      expression: "'btn:complaintWorkOrder:exportImage'",
                      arg: "remove",
                    },
                  ],
                  attrs: { size: "small" },
                  on: { click: _vm.exportOrderImage },
                },
                [_vm._v(" " + _vm._s(_vm.$t("home.exportPicture")) + " ")]
              ),
              _c(
                "el-radio-group",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { size: "small" },
                  on: { change: _vm.enterSearch },
                  model: {
                    value: _vm.queryForm.params.testData,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm.params, "testData", $$v)
                    },
                    expression: "queryForm.params.testData",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("newOrder.Formal"))),
                  ]),
                  _c("el-radio-button", { attrs: { label: 2 } }, [
                    _vm._v(_vm._s(_vm.$t("newOrder.test1"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "margin-top": "10px" } },
        [
          _c(
            "el-table",
            {
              ref: "tableRef",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, border: "", height: "520" },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  fixed: "left",
                  type: "selection",
                  width: "50",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.serial"),
                  align: "center",
                  type: "index",
                  width: "70",
                },
              }),
              _vm._l(_vm.tableHeaderList, function (item) {
                return _c("el-table-column", {
                  key: item.id,
                  attrs: {
                    align: item.align,
                    label: item.label,
                    "min-width": item.cloWidth,
                    prop: item.prop,
                    "show-overflow-tooltip": true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            item.prop === "processingState"
                              ? _c(
                                  "div",
                                  [
                                    scope.row[item.prop] === 0
                                      ? _c(
                                          "el-tag",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "success",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("GlobalSetObj.Pending")
                                              ) + " "
                                            ),
                                          ]
                                        )
                                      : scope.row[item.prop] === 1
                                      ? _c(
                                          "el-tag",
                                          { attrs: { size: "small" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("newOrder.processing")
                                              ) + " "
                                            ),
                                          ]
                                        )
                                      : scope.row[item.prop] === 2
                                      ? _c(
                                          "el-tag",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "warning",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "collectionCenter.closedLoop"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : item.prop === "hasReply"
                              ? _c("div", [
                                  scope.row.processingState === 2
                                    ? _c("div", [_vm._v("-")])
                                    : _c(
                                        "div",
                                        [
                                          scope.row[item.prop] === 0
                                            ? _c(
                                                "el-tag",
                                                {
                                                  attrs: {
                                                    size: "small",
                                                    type: "info",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("GlobalSetObj.No")
                                                    ) + " "
                                                  ),
                                                ]
                                              )
                                            : scope.row[item.prop] === 1
                                            ? _c(
                                                "el-tag",
                                                {
                                                  attrs: {
                                                    size: "small",
                                                    type: "success",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("GlobalSetObj.yes")
                                                    ) + " "
                                                  ),
                                                ]
                                              )
                                            : !scope.row[item.prop]
                                            ? _c("div", [_vm._v("-")])
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                ])
                              : item.prop === "isTimeOut"
                              ? _c(
                                  "div",
                                  [
                                    scope.row[item.prop] === 0
                                      ? _c(
                                          "el-tag",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "info",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("GlobalSetObj.No")
                                              ) + " "
                                            ),
                                          ]
                                        )
                                      : scope.row[item.prop] === 1
                                      ? _c(
                                          "el-tag",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "success",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("GlobalSetObj.yes")
                                              ) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : item.prop === "newReply"
                              ? _c("div", [
                                  scope.row[item.prop] !== null &&
                                  scope.row[item.prop] !== "" &&
                                  scope.row[item.prop] !== undefined
                                    ? _c("div", [
                                        _c("div", {
                                          staticClass: "tableImg",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              scope.row[item.prop]
                                            ),
                                          },
                                        }),
                                      ])
                                    : _c("div", [_vm._v("-")]),
                                ])
                              : _c("div", [
                                  scope.row[item.prop] !== null &&
                                  scope.row[item.prop] !== "" &&
                                  scope.row[item.prop] !== undefined
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row[item.prop]) +
                                            " "
                                        ),
                                      ])
                                    : _c("div", [_vm._v("-")]),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("newOrder.operation"),
                  align: "center",
                  fixed: "right",
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-around",
                              "align-items": "center",
                            },
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                directives: [
                                  {
                                    name: "permit",
                                    rawName: "v-permit:remove",
                                    value: "btn:complaintWorkOrder:Reply",
                                    expression:
                                      "'btn:complaintWorkOrder:Reply'",
                                    arg: "remove",
                                  },
                                ],
                                staticClass: "item",
                                attrs: {
                                  content: _vm.$t("GlobalSetObj.Reply"),
                                  effect: "dark",
                                  placement: "top",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "iconfont icon-chakantiezihuifu textOperator2",
                                  on: {
                                    click: function ($event) {
                                      return _vm.replyMessage(scope.row)
                                    },
                                  },
                                }),
                              ]
                            ),
                            scope.row.processingState !== 2
                              ? _c(
                                  "el-tooltip",
                                  {
                                    directives: [
                                      {
                                        name: "permit",
                                        rawName: "v-permit:remove",
                                        value: "btn:complaintWorkOrder:close",
                                        expression:
                                          "'btn:complaintWorkOrder:close'",
                                        arg: "remove",
                                      },
                                    ],
                                    staticClass: "item",
                                    attrs: {
                                      content: _vm.$t("GlobalSetObj.Close"),
                                      effect: "dark",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-circle-close textOperator",
                                      on: {
                                        click: function ($event) {
                                          return _vm.closeOrder(scope.row)
                                        },
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _c("el-pagination", {
            staticStyle: { margin: "10px 0", float: "right" },
            attrs: {
              "current-page": _vm.queryForm.pageNumber,
              "page-size": _vm.queryForm.pageSize,
              "page-sizes": [10, 50, 100, 500, 1000],
              total: _vm.queryForm.total,
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          staticClass: "drawerClass",
          attrs: {
            "close-on-click-modal": true,
            "destroy-on-close": true,
            title: _vm.$t("GlobalSetObj.CommunicationContent"),
            visible: _vm.seeDialogVisible,
            direction: "ltr",
            size: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.seeDialogVisible = $event
            },
            close: _vm.closeDrawer,
          },
        },
        [
          _c("messageDetail", {
            ref: "messageDetail",
            attrs: { "reply-message-data": _vm.replyMessageData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("basicData.BulkImport"),
            visible: _vm.importForm.messageRemrkDialog,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.importForm, "messageRemrkDialog", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "remarkBox" },
            [
              _c(
                "el-form",
                {
                  key: _vm.importForm.messageRemrkDialog,
                  ref: "importForm",
                  attrs: { model: _vm.importForm },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "importNumberType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            rules: {
                              required: true,
                              message: _vm.$t("GlobalSetObj.pleaseChoose"),
                              trigger: ["blur", "change"],
                            },
                          },
                          model: {
                            value: _vm.importForm.importNumberType,
                            callback: function ($$v) {
                              _vm.$set(_vm.importForm, "importNumberType", $$v)
                            },
                            expression: "importForm.importNumberType",
                          },
                        },
                        [
                          _c("el-option", {
                            key: "1",
                            attrs: {
                              label: _vm.$t("GlobalSetObj.waybillNumber"),
                              value: 0,
                            },
                          }),
                          _c("el-option", {
                            key: "2",
                            attrs: {
                              label: _vm.$t("GlobalSetObj.ScanNumber"),
                              value: 1,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        rules: {
                          required: true,
                          message: _vm.$t("GlobalSetObj.pleaseInput"),
                          trigger: ["blur", "change"],
                        },
                        prop: "importNumber",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t(
                            "receivablePayable.MultipleNumberWrapEntry"
                          ),
                          rows: 6,
                          type: "textarea",
                        },
                        model: {
                          value: _vm.importForm.importNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.importForm, "importNumber", $$v)
                          },
                          expression: "importForm.importNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.messageRemarkSubmit(1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.messageRemarkSubmit(2)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("GlobalSetObj.confirm")) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("GlobalSetObj.ImportResults"),
            visible: _vm.resultData.showImportResults,
            width: "710px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.resultData, "showImportResults", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "remarkBox" }, [
            _c("div", { staticStyle: { display: "flex" } }, [
              _c("div", { staticStyle: { width: "220px" } }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("receivablePayable.TotalImport")) + "："
                ),
                _c("span", { staticStyle: { color: "#409eff" } }, [
                  _vm._v(_vm._s(_vm.resultData.all)),
                ]),
              ]),
              _c("div", { staticStyle: { flex: "1" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("orderCenterCont.ImportWasSuccessful")) +
                    "：" +
                    _vm._s(_vm.resultData.success) +
                    " "
                ),
              ]),
              _c("div", { staticStyle: { width: "220px" } }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("orderCenterCont.ImportFailed")) + "："
                ),
                _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v(_vm._s(_vm.resultData.err)),
                ]),
              ]),
            ]),
            _vm.resultData.err > 0
              ? _c("div", [
                  _c("div", { staticStyle: { margin: "20px 0 0 0" } }, [
                    _vm._v(_vm._s(_vm.$t("GlobalSetObj.ReasonForFailure"))),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "max-height": "310px",
                        "overflow-y": "auto",
                      },
                    },
                    _vm._l(_vm.resultData.errData, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticStyle: { "margin-top": "10px" } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(index) + "：" + _vm._s(item)),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.resultData.showImportResults = false
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("GlobalSetObj.confirm")) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("collectionCenter.processingResult"),
            visible: _vm.closeReturn.showCloseComplaints,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.closeReturn, "showCloseComplaints", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "remarkBox" },
            [
              _c("div", { staticStyle: { margin: "20px 0" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("GlobalSetObj.totalOptions")) +
                    "：" +
                    _vm._s(_vm.closeReturn.selectedNum) +
                    " "
                ),
              ]),
              _c(
                "el-form",
                {
                  key: _vm.closeReturn.showCloseComplaints,
                  ref: "closeReturn",
                  attrs: { model: _vm.closeReturn },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.$t("collectionCenter.processingResult") + "：",
                        rules: {
                          required: true,
                          message: _vm.$t("GlobalSetObj.pleaseChoose"),
                          trigger: ["change", "blur"],
                        },
                        prop: "processingResult",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                          },
                          model: {
                            value: _vm.closeReturn.processingResult,
                            callback: function ($$v) {
                              _vm.$set(_vm.closeReturn, "processingResult", $$v)
                            },
                            expression: "closeReturn.processingResult",
                          },
                        },
                        _vm._l(
                          _vm.processingResultList01,
                          function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.label, value: item.value },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.closeReturnSubmit(1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.closeReturnSubmit(2)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("GlobalSetObj.confirm")) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("home.fixTime"),
            visible: _vm.changeDeadline,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.changeDeadline = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "40px" } },
            [
              _c("el-date-picker", {
                attrs: {
                  placeholder: _vm.$t("order.orderDatePlaceholder"),
                  type: "date",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.time01,
                  callback: function ($$v) {
                    _vm.time01 = $$v
                  },
                  expression: "time01",
                },
              }),
              _c("el-time-picker", {
                staticStyle: { "margin-left": "30px" },
                attrs: {
                  placeholder: _vm.$t("base.selectTime"),
                  "value-format": "HH:mm:ss",
                },
                model: {
                  value: _vm.time02,
                  callback: function ($$v) {
                    _vm.time02 = $$v
                  },
                  expression: "time02",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.changeDeadline = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.confirmUpdataDeadline },
                },
                [_vm._v(" " + _vm._s(_vm.$t("GlobalSetObj.confirm")) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("home.fixResult"),
            visible: _vm.modifyingResultDialog,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.modifyingResultDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "remarkBox" },
            [
              _c("div", { staticStyle: { margin: "10px 0", color: "black" } }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("GlobalSetObj.totalOptions")) + "："
                ),
                _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v(_vm._s(_vm.modifyingResult.selectedNum)),
                ]),
              ]),
              _c(
                "el-form",
                {
                  ref: "modifyingResultRef",
                  attrs: { model: _vm.modifyingResult },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("home.fixResult") + "：",
                        rules: {
                          required: true,
                          message: _vm.$t("GlobalSetObj.pleaseChoose"),
                          trigger: ["change", "blur"],
                        },
                        prop: "updateProcessingResult",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                          },
                          model: {
                            value: _vm.modifyingResult.updateProcessingResult,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.modifyingResult,
                                "updateProcessingResult",
                                $$v
                              )
                            },
                            expression:
                              "modifyingResult.updateProcessingResult",
                          },
                        },
                        _vm._l(
                          _vm.processingResultList01,
                          function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.label, value: item.value },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.submitModifyingResult(1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitModifyingResult(2)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("GlobalSetObj.confirm")) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("GlobalSetObj.tips"),
            visible: _vm.tipDialog,
            width: "550px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.tipDialog = $event
            },
          },
        },
        [
          _c("div", [
            _c("div", { staticClass: "flx-between" }, [
              _c("div", [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("home.operationTotal")) + "："),
                ]),
                _c("span", [_vm._v(_vm._s(_vm.tipObj.totalNumber))]),
              ]),
              _c("div", [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("operationCenter.NumberOfSuccesses")) + "："
                  ),
                ]),
                _c("span", [_vm._v(_vm._s(_vm.tipObj.succeedNumber))]),
              ]),
              _c("div", [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("operationCenter.NumberOfFailures")) + "："
                  ),
                ]),
                _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v(_vm._s(_vm.tipObj.failNumber)),
                ]),
              ]),
            ]),
            _vm.tipObj.failNumber
              ? _c(
                  "div",
                  { staticStyle: { height: "200px", "overflow-y": "auto" } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "20px",
                          "margin-bottom": "8px",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("GlobalSetObj.ReasonForFailure")) +
                            ": "
                        ),
                      ]
                    ),
                    _vm._l(_vm.tipObj.failMap, function (key, val, index) {
                      return _c("div", { key: key }, [
                        _c("span", [_vm._v(_vm._s(index + 1) + ". ")]),
                        _c("span", [_vm._v(_vm._s(key) + ": ")]),
                        _c("span", [_vm._v(_vm._s(val))]),
                      ])
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.tipDialog = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Close")) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }