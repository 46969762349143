var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          attrs: {
            size: "small",
            type: "primary",
            disabled: _vm.checkList.length === 0,
          },
          on: { click: _vm.oneClickDel },
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("compensationManagement.oneClickRejection")) +
              " "
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("basicData.tips"),
            visible: _vm.dialogVisible,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: function ($event) {
              _vm.dialogVisible = false
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "ruleForm", attrs: { size: "small", model: _vm.ruleForm } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("collectionCenter.AnjunTrackingNumber"),
                    prop: "orderNumbers",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      autosize: { minRows: 1, maxRows: 3 },
                      type: "textarea",
                      clearable: "",
                      placeholder:
                        _vm.$t("collectionCenter.BatchQuery") +
                        "，" +
                        _vm.$t("compensationManagement.Maximum500Orders"),
                    },
                    model: {
                      value: _vm.ruleForm.orderNumbers,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "orderNumbers", $$v)
                      },
                      expression: "ruleForm.orderNumbers",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("compensationManagement.dataRange"),
                    prop: "claimsOrdersSource",
                    rules: {
                      required: true,
                      message: _vm.$t("GlobalSetObj.required"),
                      trigger: ["blur", "change"],
                    },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                        clearable: "",
                      },
                      model: {
                        value: _vm.ruleForm.claimsOrdersSource,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "claimsOrdersSource", $$v)
                        },
                        expression: "ruleForm.claimsOrdersSource",
                      },
                    },
                    [
                      _c("el-option", {
                        key: "1",
                        attrs: {
                          label: _vm.$t(
                            "compensationManagement.dispatcherIsEmpty"
                          ),
                          value: 1,
                        },
                      }),
                      _c("el-option", {
                        key: "2",
                        attrs: {
                          label: _vm.$t(
                            "compensationManagement.locationsAreEmpty"
                          ),
                          value: 2,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitBtn } },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.determine")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }