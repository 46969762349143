<!--导入修改费用-->
<template>
  <div class="flex-c">
    <el-button
      icon="el-icon-download"
      size="small"
      type="primary"
      style="margin: 0 10px 10px 0"
      @click="operationBtn"
    >
      {{ $t('basicData.DownloadTheTemplate') }}
    </el-button>
    <upload-excel
      :on-success="importLogistics"
      :but-name="$t('receivablePayable.ImportModifyExpenses')"
    />
  </div>
</template>

<script>
import uploadExcel from '@/components/UploadExcel';
import { getImportFee, getImportTemplate } from '@/api/finance/costManagement.js';
import { goFileUploadManagement } from '@/utils/goExportList.js';
import { saveAs } from 'file-saver';

const MAX_LENGTH = 100000;

export default {
  name: 'ImportEditFees',

  components: {
    uploadExcel
  },

  props: {

  },
  data() {
    return {
      feeType: null
    };
  },

  created() {
    // 每次最多导入xxxx条数据
    this.lenTips = this.$t('receivablePayable.Only100000PiecesImport');
  },
  methods: {
    // 导入文件为填项补充
    dataSorting(header, result) {
      return result.map(item => {
        header.map(list => {
          item[list] = item[list];
          for (const k in item) {
            if (list === k) {
              item[list] = (item[list] !== null && item[list] !== undefined && item[list] !== '') ? item[list] : '';
            }
          }
        });
        return item;
      });
    },
    // 将文件头按组分类
    groupHeader(arr, length) {
      let ind = 0;
      const newArr = [];
      while (ind < arr.length) {
        newArr.push(arr.slice(ind, ind += length));
      }
      return newArr;
    },

    /**
     * 导入修改费用数据
     * @param arr {Array} 文件头数据
     */
    importLogistics(arr) {
      const feeType1 = arr[1].results[0];
      const headerDataArr = [];
      arr[0].header.map(item => {
        if (item.indexOf('UNKNOWN') === -1) {
          headerDataArr.push(item);
        }
      });
      const logisticsNo = {};
      logisticsNo[headerDataArr[0]] = 'waybillNumber';
      logisticsNo[headerDataArr[1]] = 'businessType';
      logisticsNo[headerDataArr[2]] = 'siteId';
      logisticsNo[headerDataArr[3]] = 'chargeWeight';

      const headerArr = headerDataArr.slice(4);
      const header = this.groupHeader(headerArr, 2);
      const headObj = header.map(item => {
        const obj = {};
        item.map((list, ind) => {
          switch (ind) {
            case 0:
              obj[list] = 'fee';
              break;
            case 1:
              obj[list] = 'remark';
              break;
          }
        });
        return obj;
      });
      const results = arr[0].results.slice(2);
      const allData = this.dataSorting(headerDataArr, results);
      const dataArr = [];
      allData.map((item) => {
        const dataObj = {};
        const priceItemList = [];
        headObj.map(list => {
          const dataKey = {};
          let chargeWeight = '';
          let businessType = '';
          let siteId = '';
          for (const k in item) {
            if (k === headerDataArr[0]) {
              dataObj[logisticsNo[k]] = item[k];
              dataObj['priceItemList'] = [];
            }
            if (k === headerDataArr[1]) {
              if (item[k]) {
                businessType = item[k].split('-')[1] || '';
                dataObj[logisticsNo[k]] = businessType;
              }
            }
            if (k === headerDataArr[2]) {
              siteId = item[k].split('-').pop() || '';
              dataObj[logisticsNo[k]] = siteId;
            }

            if (k === headerDataArr[3]) {
              chargeWeight = item[k] ? item[k] : '';
              dataObj[logisticsNo[k]] = chargeWeight;
            }
            dataKey[list[k]] = list[k] ? item[k] : '';
            if (list[k] === 'fee') {
              dataKey['feeCode'] = feeType1[k];
            }
          }
          if (dataKey.fee || dataKey.remark) {
            const obj = {
              fee: (dataKey.fee !== null && dataKey.fee !== undefined && dataKey.fee !== '') ? dataKey.fee.toString().trim() : '',
              feeCode: (dataKey.feeCode !== null && dataKey.feeCode !== undefined && dataKey.feeCode !== '') ? dataKey.feeCode.toString().trim() : '',
              remark: (dataKey.remark !== null && dataKey.remark !== undefined && dataKey.remark !== '') ? dataKey.remark.toString().trim() : ''
            };
            priceItemList.push(obj);
          }
        });
        dataObj['priceItemList'] = priceItemList;
        dataArr.push(dataObj);
      });

      // 数据数量校验
      if (dataArr.length === 0) {
        this.$message({
          type: 'warning',
          message: this.$t('userManage.ImportDataIsEmpty')
        });
        return;
      }
      if (dataArr.length > MAX_LENGTH) {
        this.$message({
          type: 'warning',
          message: this.$t('receivablePayable.Only100000PiecesImport')
        });
        return;
      }
      getImportFee(dataArr).then(res => {
        if (res.code === 200) {
          this.$message.success(this.$t('basicData.TheOperationSucceeded'));
          goFileUploadManagement(this);
          this.$emit('changeFeeList', true);
        }
      });
    },

    // 下载模板
    operationBtn() {
      getImportTemplate().then(res => {
        saveAs(res.data, this.$t('receivablePayable.ImportModifyExpenses') + '.xlsx');
      });
    }
  }
};
</script>

<style scoped>
.flex-c {
  display: flex;
  flex-wrap: wrap;
}
</style>
