var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("basicData.numberOrInvoiceNumber"),
        visible: _vm.dialogVisible,
        "label-position": "top",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onClose,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.ruleForm.orderType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "orderType", $$v)
                            },
                            expression: "ruleForm.orderType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("basicData.InvoiceNumber"),
                              value: 1,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("receivablePayable.TicketNumber"),
                              value: 2,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("collectionCenter.businessT") } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                          },
                          model: {
                            value: _vm.ruleForm.businessType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "businessType", $$v)
                            },
                            expression: "ruleForm.businessType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("collectionCenter.collect"),
                              value: "COLLECT",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("collectionCenter.dispatch"),
                              value: "DELIVERY",
                            },
                          }),
                          _c("el-option", {
                            attrs: { label: "SFS", value: "SFS" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: " ", prop: "orderNumber" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder:
                    _vm.$t("orderCenterCont.MultipleLineBreaks") +
                    "," +
                    _vm.$t("orderCenterCont.canOnlyExport50000"),
                  type: "textarea",
                  rows: 12,
                },
                model: {
                  value: _vm.ruleForm.orderNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "orderNumber", $$v)
                  },
                  expression: "ruleForm.orderNumber",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onClose } }, [
            _vm._v(_vm._s(_vm.$t("basicData.Cancel"))),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
            [_vm._v(_vm._s(_vm.$t("basicData.determine")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }