import request from '@/utils/request';
// 大件订单分页查询
export const getServiceList = (data) => {
  return request.post('/cargoOrder/getPage', data);
};
// 大件订单支付凭证查询
export const getCargoOrderDetail = (id) => {
  return request.get(`/cargoOrder/${id}`);
};
// 大件订单分页查询
export const cargoExportAll = (data) => {
  return request.post(`/cargoOrder/cargoExportAll`, data);
};
