<!--服务商数据-->
<template>
  <ElSelectV2
    v-model="value"
    :multiple="multiple"
    :clearable="clearable"
    :multiple-limit="multipleLimit"
    :loading="loading"
    :options="list"
    :fit-input-width="false"
    filterable
    :placeholder="$t('operationCenter.PleaseSelect')"
    style="width: 100%"
    @change="onChange"
  />
</template>

<script>
import ElSelectV2 from 'el-select-v2';
import { collectDriverQueryCourierNew } from '@/api/logisticsOrders';

const param = {
  pageSize: 999999,
  pageNumber: 1,
  params: {
    deliveryManName: ''
  }
};

const QueryPmUserV2Cmp = 'QueryPmUserV2Cmp';

export default {
  name: '',

  components: {
    ElSelectV2
  },

  props: {
    // 是否多选
    multiple: {
      type: Boolean,
      default: false
    },

    // 是否可以清空选项
    clearable: {
      type: Boolean,
      default: true
    },

    // 多选时用户最多可以选择的项目数，为 0 则不限制
    multipleLimit: {
      type: Number,
      default: 0
    },

    // id集合 用于回显
    val: {
      type: [Array, String, Number],
      default: () => []
    }
  },

  data() {
    return {
      loading: false,
      list: [],
      value: []
    };
  },

  watch: {
    val: {
      immediate: true,
      handler(val) {
        if (Array.isArray(val)) {
          this.value = [...val];
        } else if (typeof val === 'number') {
          this.value = val;
        } else {
          this.value = val || '';
        }
      }
    }
  },

  created() {
    const list = window.sessionStorage.getItem(QueryPmUserV2Cmp);
    if (list) {
      this.list = JSON.parse(list).map(item => {
        return Object.freeze(item);
      });
      return;
    }
    setTimeout(() => {
      this.asyncGetUserPage();
    }, 100);
  },

  methods: {
    // 获取用户信息（含职务）
    asyncGetUserPage() {
      this.loading = true;

      collectDriverQueryCourierNew(param, false).then(res => {
        const { code, data } = res;
        if (code !== 200) return;

        const list = (data || []).map(item => {
          const { deliveryManName, supplierId } = item;
          return Object.freeze({
            source: 2,
            dispatcherName: deliveryManName,
            dispatcherId: supplierId,
            value: supplierId,
            label: deliveryManName
          });
        });

        window.sessionStorage.setItem(QueryPmUserV2Cmp, JSON.stringify(list));

        this.list = list;
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    onChange(val) {
      this.$emit('change', val);
    }
  }
};
</script>
