var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        { attrs: { "label-position": "top", "label-width": "80px" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("Resource.执行时间") } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "default-time": ["00:00:00", "23:59:59"],
                          "end-placeholder": _vm.$t("GlobalSetObj.endDate"),
                          "start-placeholder": _vm.$t("GlobalSetObj.startDate"),
                          "range-separator": "-",
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        model: {
                          value: _vm.queryForm.params.time,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm.params, "time", $$v)
                          },
                          expression: "queryForm.params.time",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.waybillNumber") } },
                    [
                      _c("InputTextareaCmp", {
                        ref: "inputTextareaRef",
                        attrs: {
                          "max-length": 200,
                          val: _vm.queryForm.params.waybillNumbers,
                        },
                        on: {
                          input: function (_, val) {
                            return (_vm.queryForm.params.waybillNumbers = val)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-search", size: "small", type: "primary" },
              on: { click: _vm.asyncGetTableData },
            },
            [_vm._v(_vm._s(_vm.$t("newOrder.search")) + " ")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "but:system-manage:trajectoryStatusMonitoring:export",
                  expression:
                    "'but:system-manage:trajectoryStatusMonitoring:export'",
                  arg: "remove",
                },
              ],
              attrs: { icon: "el-icon-refresh", size: "mini" },
              on: { click: _vm.onReset },
            },
            [_vm._v(_vm._s(_vm.$t("basicData.reset")) + " ")]
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-card",
        [
          _c(
            "div",
            { staticClass: "operation" },
            [
              _c(
                "div",
                { staticStyle: { "font-weight": "bold", "font-size": "14px" } },
                [_vm._v(" " + _vm._s(_vm.$t("navbar.tip0305")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-download",
                    size: "small",
                    type: "primary",
                  },
                  on: { click: _vm.asyncExport },
                },
                [_vm._v(_vm._s(_vm.$t("newOrder.export")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticClass: "table-height",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                height: _vm.tableHeightRow.height,
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operationCenter.ind"),
                  align: "center",
                  width: "80px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.queryForm.pageNumber - 1) *
                                _vm.queryForm.pageSize +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("Resource.执行时间"),
                  prop: "createTime",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.waybillNumber"),
                  prop: "waybillNumber",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("orderCenterCont.latestStatusTime"),
                  prop: "lastStatusTime",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("newOrder.waybillStatus"),
                  prop: "waybillStatusStr",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("newOrder.LatestTime"),
                  prop: "lastTrackTime",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("orderCenterCont.zuixinguijineir"),
                  prop: "lastTrackDescribe",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                staticStyle: { margin: "10px 0" },
                attrs: {
                  "current-page": _vm.queryForm.pageNumber,
                  "page-size": _vm.queryForm.pageSize,
                  "page-sizes": [10, 20, 50, 100],
                  total: _vm.total,
                  background: "",
                  layout: "total, sizes, prev, pager, next, jumper",
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "current-change": _vm.asyncGetTableData,
                  "size-change": _vm.asyncGetTableData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }