<template>
  <div class="Box">
    <div class="topTitle t1">{{ $t('collectionCenter.BagManage') }}</div>
    <el-table :data="totalTableData" style="width: 100%" :empty-text="$t('collectionCenter.nullMsg')">
      <!--       <el-table-column prop="orderId" label="orderId" />-->
      <!--      <el-table-column prop="num" label="num" />-->
      <el-table-column prop="type" :label="$t('collectionCenter.Type')" />
      <el-table-column prop="sum" :label="$t('collectionCenter.numberPackages')" />
      <el-table-column
        v-if="isSealingBags"
        width="80"
        align="center"
      >
        <template slot-scope="scope">
          <div class="bagClass" @click="openSeal(scope.row, scope.$index)">{{ $t('singleScan.closeBag') }}</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="btw bor-mar">
      <div class="topTitle t2">{{ $t('collectionCenter.packageCollection') }}</div>
      <el-button
        v-permit:remove="'btn:collection-center:single-scan:signature'"
        type="success"
        @click="openSign"
      >{{ $t('collectionCenter.confirmPackage') }}</el-button>
    </div>
    <div class="scanBox">
      <div class="text-left tl1">
        <span>{{ $t('collectionCenter.orderNumber') }}</span>
        <el-input
          v-model="packageNumber"
          clearable
          :placeholder="$t('collectionCenter.PleaseScan')"
          @keyup.enter.native="addTable"
        />
      </div>
      <div class="text-left tl2">
        <span>{{ $t('collectionCenter.scanCount') }}</span>
        <el-input v-model="tableData.length" :disabled="true" />
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      :empty-text="$t('collectionCenter.nullMsg')"
      max-height="500"
    >
      <el-table-column prop="num" :label="$t('singleScan.numberPackage')" align="center" /><!-- 订单号 -->
      <el-table-column prop="type" :label="$t('singleScan.bag')" align="center" /><!-- 大袋类型 -->
      <el-table-column prop="merchantName" :label="$t('collectionCenter.CommercialOwner')" align="center" /><!-- 商户 -->
      <el-table-column prop="date" :label="$t('collectionCenter.scanTime')" align="center" /><!-- 扫描时间 -->
      <el-table-column
        width="80"
        align="center"
      >
        <template slot-scope="scope">
          <!--确定删除吗？-->
          <el-popconfirm
            :title="$t('basicData.AreYouSureDelete')"
            @confirm="deleteRow(scope.row, scope.$index)"
          >
            <el-button
              slot="reference" type="danger" icon="el-icon-delete"
              circle
            />
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 签名 -->
    <div v-if="signVisible" class="signMask">
      <div class="signVisibleClass">
        <div class="el-icon-close signClose" @click="signCloseClick" />
        <div class="descriptionsClass">
          <div ref="image" style="border: 1px solid #a8a9ad;">
            <div class="logo-box">
              <span>{{ $t('collectionCenter.LSSite') }}{{ scanList.signDot }}</span>
              <div class="logo-img">
                <img :src="LOGO" alt="">
              </div>
            </div>
            <div class="desTitle" />
            <el-descriptions
              class="margin-top" :column="1" border
              :content-style="rowClass" :label-style="labelRowClass"
            >
              <el-descriptions-item>
                <template slot="label">
                  <span style="text-align: center;display: block">{{ $t('collectionCenter.LanShouPerson') }}</span>
                </template>
                {{ scanList.signUser }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <span style="text-align: center;display: block">{{ $t('collectionCenter.lanshouTime') }}</span>
                </template>
                {{ scanList.signTime }}
              </el-descriptions-item>
            </el-descriptions>
            <div class="desTitle">{{ $t('collectionCenter.lanshouMsg') }}</div>
            <el-descriptions
              class="margin-top" :column="1" border
              :content-style="rowClass" :label-style="labelRowClass"
            >
              <el-descriptions-item>
                <template slot="label">
                  <!-- 商家 -->
                  <span style="text-align: center;display: block">{{ $t('collectionCenter.merchant') }}：</span>
                </template>
                {{ scanList.merchantCode }} / {{ scanList.merchantName }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <!--                  揽收总票数-->
                  <span style="text-align: center;display: block">{{ $t('collectionCenter.LSSum') }}</span>
                </template>
                {{ scanList.signSum }}
              </el-descriptions-item>
            </el-descriptions>
            <div class="desTitle">{{ $t('collectionCenter.customerSignature') }}</div><!-- 客户签名 -->
            <div class="page-content">
              <div class="content">
                <vue-esign
                  ref="esignImg" :width="500" :height="300"
                  :line-width="5" line-color="#000" bg-color="#FFF"
                />
              </div>
            </div>
          </div>
          <div class="flex">
            <el-button class="btn" type="danger" @click="handleReset">{{ $t('collectionCenter.empty') }}</el-button><!-- 清空 -->
            <el-button class="btn" type="success" @click="handleGenerate">{{ $t('collectionCenter.submitSignature') }}</el-button><!-- 提交签名 -->
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="loadingClass" />

    <!-- 封袋 -->
    <div v-if="sealingVisible" class="sealingClass">
      <el-dialog
        title=""
        :visible.sync="sealingVisible"
        width="30%"
        @close="handleClose"
      >
        <span>{{ $t('GlobalSetObj.bagNumber') }}：</span>
        <el-input
          v-model="bagNumber"
          minlength="6"
          maxlength="12"
          show-word-limit
          clearable
          :placeholder="$t('GlobalSetObj.scannerOrinput')"
        />
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">{{ $t('collectionCenter.cancel') }}</el-button>
          <el-button type="primary" @click="sealingBags">{{ $t('collectionCenter.confirm') }}</el-button>
        </span>
      </el-dialog>
    </div>

    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';
import { uploadOos } from '@/utils/ali_oos_util';
import {
  directScan,
  signPushNoBR,
  sealerBagNoBR,
  deleteRedisPackage,
  collectOrderScanScanInfoQueryScanLog,
  pcBagBarcodeScan,
  pcCollectionCheck
} from '@/api/lanshou';
const okMp3 = '/static/scan_success.mp3';
const errorMp3 = '/static/scan_fail.mp3';
export default {
  /**
   * 1、校验订单号扫描
   * 2、签名截图——打印
   */
  data() {
    return {
      isSealingBags: this.$store.getters.button_permissions.includes('btn:collection-center:single-scan:isSealingBags'),
      orderIdList: {},
      bagNumber: '',
      sealingVisible: false,
      totalTableData: [],
      input: '',
      packageNumber: '',
      tableData: [],
      signVisible: false,
      rowClass: {
        'border-color': '#a8a9ad'
      },
      labelRowClass: {
        'border-color': '#a8a9ad'
      },
      isLoading: false, // 加载动画
      sealIndex: -1,
      scanList: {
        signDot: '',
        signUser: '',
        signTime: '',
        signSum: '',
        merchantCode: '',
        merchantName: ''
      },
      LOGO: '/static/anjunLogo.png',
      printImg: '',
      okMp3,
      errorMp3,
      scanForm: {
        customerId: '',
        merchantName: '',
        merchantCode: ''
      }
    };
  },
  created() {
    // 本地存储没有数据时通过接口获取数据
    if (this.tableData && this.tableData.length === 0) {
      this.getScanLog();
    }
    this.totalTableData = JSON.parse(localStorage.getItem('totalTableData')) || [];
  },
  methods: {
    // 获取扫描数据
    getScanLog() {
      collectOrderScanScanInfoQueryScanLog(11, 1)
        .then((res) => {
          if (res.code === 200) {
            if (res.data && res.data.length) {
              // 添加数据
              this.tableData = res.data.map((item) => {
                const type1 = item.customerName === 'sheind2d' ? 'D2D' : item.customerName === 'shein' ? 'RC01' : item.customerName === 'kwai' ? 'M01' : 'express';
                return {
                  num: item.scanNumber,
                  type: type1,
                  name: item.sellerId,
                  merchantName: item.merchantName,
                  date: item.scanTimeStr,
                  orderId: item.orderId,
                  scanId: item.id,
                  customerId: item.customerId,
                  merchantCode: item.merchantCode,
                  orderTypeCode: item.orderTypeCode
                };
              });
              this.scanList.signDot = this.$store.state.user.dotName;
              this.scanList.signUser = this.$store.state.user.realName;
              this.scanList.merchantCode = res.data[res.data.length - 1].merchantCode;
              this.scanList.merchantName = res.data[res.data.length - 1].merchantName;

              this.scanForm = {
                customerId: res.data[res.data.length - 1].customerId || null,
                merchantName: res.data[res.data.length - 1].merchantName,
                merchantCode: res.data[res.data.length - 1].merchantCode
              };
            } else {
              this.tableData = [];
              this.scanList = {};
              this.scanForm = {
                customerId: null,
                merchantName: null,
                merchantCode: null
              };
            }
          }
        });
    },
    filtration(type1, data) {
      if (this.totalTableData.length > 1) {
        this.totalTableData.forEach((el, idx) => {
          if (el.type === data.type && idx !== (this.totalTableData.length - 1)) {
            this.totalTableData[idx].orderId = this.totalTableData[idx].orderId + ',' + data.orderId;
            this.totalTableData[idx].sum = this.totalTableData[idx].orderId.split(',').length;
            this.totalTableData[idx].num = this.totalTableData[idx].num + ',' + data.num; // 可删除
            this.totalTableData.splice(this.totalTableData.length - 1, 1);
          }
        });
        localStorage.setItem('totalTableData', JSON.stringify(this.totalTableData));
      }
    },
    /**
     * 签名截图——打印
     */
    signCloseClick() {
      this.signVisible = false;
    },
    // 签名——清空
    handleReset() {
      this.$refs['esignImg'].reset(); // 清空画布
    },
    handleGenerate() {
      // // 查看截图样式
      // html2canvas(this.$refs.image).then(res => {
      //   const imgUrl = res.toDataURL('image/png');
      //   const aLink = document.createElement('a');
      //   aLink.href = imgUrl;
      //   aLink.download = new Date().toLocaleString() + '.png';	// 导出文件名，这里以时间命名
      //   document.body.appendChild(aLink);
      //   aLink.click();
      // });
      // return;

      const form = {
        orderIdList: []
      };
      this.tableData.forEach((item) => {
        form.orderIdList.push(item.orderId);
      });
      pcCollectionCheck(form).then(res => {
        this.scanList.signSum = res.data.orderIdList.length;
        this.scanList.signTime = res.data.scanTime;
        /**
         * 根据返回值去除其余数据
         */
        // 暂时不需要过滤
        // const tableData = this.tableData.filter(item => res.data.orderIdList.includes(item.orderId));
        // this.tableData = tableData;

        this.$refs['esignImg'].generate().then(res => {
          this.isLoading = true;
          setTimeout(() => {
            this.canvasImg();
          }, 300);
        }).catch((err) => {
          console.log(err);
          this.$message({
            message: this.$t('collectionCenter.unsigned'), // '未签名!'
            type: 'warning'
          });
        });
      }).catch({});
    },
    canvasImg() {
      const canvas = document.createElement('canvas'); // 截图区
      const canvasBox = this.$refs.image; // 父级
      const windowSize = window.devicePixelRatio; // 浏览器缩放大小会影响截屏最终展示
      const width = parseInt(window.getComputedStyle(canvasBox).width) * windowSize;
      const height = parseInt(window.getComputedStyle(canvasBox).height) * windowSize;
      canvas.width = width * 2;
      canvas.height = height * 2;
      canvas.style.width = width + 'px';
      canvas.style.height = height + 'px';
      const context = canvas.getContext('2d');
      context.scale(2, 2);
      const options = {
        backgroundColor: null,
        canvas: canvas,
        useCORS: true
      };
      html2canvas(canvasBox, options).then((canvas) => {
        // toDataURL 图片格式转成 base64
        const dataURL = canvas.toDataURL('image/png');
        const rSignImg = this.base64ImgtoFile(dataURL, 'file'); // 得到文件格式
        uploadOos(rSignImg, 'Order').then(res => {
          if (res.code === 200) {
            if (res.url) {
              this.printImg = res.url;
              this.upload();
            } else {
              this.printImg = '';
            }
          }
        });
      });
    },
    base64ImgtoFile(dataurl, filename = 'file') {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const suffix = mime.split('/')[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      });
    },
    // 签名并提交
    upload() {
      if (!this.printImg) {
        this.$message.warning(this.$t('collectionCenter.notGetSignImg')); // '还未获取到签名截图，请稍后...'
        return;
      }
      const signForm = {
        finishSignUrl: this.printImg,
        orderIdList: [],
        scanIdList: []
      };
      this.tableData.forEach((item) => {
        signForm.orderIdList.push(item.orderId);
        signForm.scanIdList.push(item.scanId);
      });
      signPushNoBR(signForm).then(res => {
        if (res.code === 200) {
          this.isLoading = false;
          this.$message.success(this.$t('collectionCenter.submitSuccessfully')); // 提交成功
          this.signVisible = false;
          this.tableData = [];
          this.scanForm = {
            customerId: null,
            merchantName: null,
            merchantCode: null
          };
          this.printImg = '';
          this.packageNumber = '';
        }
      }).catch((err) => {
        this.isLoading = false;
        console.error(err);
      });
    },

    deleteRow(row, index) {
      const form = {
        scanId: row.scanId,
        orderId: row.orderId
      };
      deleteRedisPackage(form).then(res => {
        if (res.code === 200) {
          this.tableData.splice(index, 1);
          if (this.tableData.length <= 0 || row.num == this.packageNumber) {
            this.packageNumber = '';
          }
          this.totalTableData.forEach((arr, idx) => {
            if (arr.type === row.type && arr.sum <= 1) {
              this.totalTableData.splice(idx, 1);
            } else if (arr.type === row.type && arr.sum > 1) {
              this.totalTableData[idx].sum = this.totalTableData[idx].sum - 1;
              const orderIdDel = this.totalTableData[idx].orderId.split(',').map(Number);
              const isDelIdx = orderIdDel.indexOf(row.orderId);
              if (isDelIdx > -1) {
                orderIdDel.splice(isDelIdx, 1);
                this.totalTableData[idx].orderId = orderIdDel.toString();
              }
            }
          });
          this.getScanLog();
          localStorage.setItem('totalTableData', JSON.stringify(this.totalTableData));
        }
      });
    },
    addTable() {
      if (!this.packageNumber) {
        this.$message({
          message: this.$t('collectionCenter.PleaseScan'),
          type: 'warning'
        });
        return;
      }
      // 重复过滤
      const isAdd = this.tableData.some(el => {
        return el.num === this.packageNumber;
      });
      if (isAdd && this.tableData.length > 0) {
        this.$message({
          message: this.$t('collectionCenter.currentOddNumberRepeat'), // '当前单号重复'
          type: 'warning'
        });
        this.$refs.errRef.play();
        return;
      }
      const form = {
        scanNumber: this.packageNumber,
        customerId: this.scanForm.customerId,
        merchantName: this.scanForm.merchantName,
        merchantCode: this.scanForm.merchantCode,
        operationType: 11,
        sourceType: 1,
        orderTypeCode: this.tableData.length > 0 ? this.tableData[this.tableData.length - 1].orderTypeCode : null,
        scannRecordBool: this.tableData.length > 0
      };
      directScan(form).then(res => {
        if (res.code === 200) {
          this.$refs.okRef.play();
          const type1 = res.data.customerName === 'sheind2d' ? 'D2D' : res.data.customerName === 'shein' ? 'RC01' : res.data.customerName === 'kwai' ? 'M01' : 'express';
          this.totalTableData.push({
            num: this.packageNumber,
            type: type1,
            sum: 1,
            orderId: res.data.orderId
          });
          const ttd = {
            num: this.packageNumber,
            type: type1,
            sum: 1,
            orderId: res.data.orderId
          };
          this.filtration(type1, ttd);
          // this.getScanLog();
          this.tableData.unshift({
            num: res.data.scanNumber,
            type: type1,
            name: res.data.sellerId,
            date: res.data.scanTime,
            orderId: res.data.orderId,
            scanId: res.data.scanId,
            customerId: res.data.customerId,
            merchantName: res.data.merchantName,
            merchantCode: res.data.merchantCode,
            orderTypeCode: res.data.orderTypeCode
          });
          this.scanList.signDot = this.$store.state.user.dotName;
          this.scanList.signUser = this.$store.state.user.realName;
          this.scanList.merchantCode = res.data.merchantCode;
          this.scanList.merchantName = res.data.merchantName;

          this.scanForm = {
            customerId: this.tableData[this.tableData.length - 1].customerId || null,
            merchantName: this.tableData[this.tableData.length - 1].merchantName,
            merchantCode: this.tableData[this.tableData.length - 1].merchantCode
          };
          this.packageNumber = '';
        } else {
          this.packageNumber = '';
          this.$refs.errRef.play();
        }
      }).catch(() => {
        this.packageNumber = '';
        this.$refs.errRef.play();
      });
    },
    openSign() {
      if (this.tableData.length <= 0) {
        this.$message({
          message: this.$t('operatingPlatform.nullMsg'), // '暂无数据'
          type: 'warning'
        });
        return;
      }
      const form = {
        orderIdList: []
      };
      this.tableData.forEach((item) => {
        form.orderIdList.push(item.orderId);
      });
      pcCollectionCheck(form).then(res => {
        this.scanList.signSum = res.data.orderIdList.length;
        this.scanList.signTime = res.data.scanTime;
        this.scanList.signDot = this.$store.state.user.dotName;
        this.scanList.signUser = this.$store.state.user.realName;
        this.scanList.merchantCode = this.scanForm.merchantCode;
        this.scanList.merchantName = this.scanForm.merchantName;
        this.signVisible = true;
      });
    },
    /**
     * 封袋
     */
    handleClose() {
      this.bagNumber = '';
      this.sealingVisible = false;
    },
    openSeal(row, index) {
      // if (this.tableData.length > 0) {
      //   this.$message({
      //     message: this.$t('collectionCenter.unsigned'), // '未签名!'
      //     type: 'warning'
      //   });
      //   return;
      // }
      this.sealingVisible = true;
      this.sealIndex = index;
      this.orderIdList = row;
    },
    sealingBags() {
      // 校验袋号格式：6到12位数字或字母开头12位数字
      if (this.bagNumber && this.bagNumber.length < 6 || this.bagNumber.length > 13) {
        this.$message.error(this.$t('operatingPlatform.BagNumberLimitNew'));
        this.bagNumber = '';
        return;
      }

      /**
       * 校验单号
       */
      pcBagBarcodeScan(this.bagNumber).then(res => {
        if (res.code === 200) {
          /**
           * 校验成功后调用提交接口
           */
          const orderIdList = this.orderIdList.orderId.toString().split(',').map(Number);
          const sealForm = {
            bagNumber: this.bagNumber,
            orderIdList,
            siteId: this.$store.state.user.siteId,
            scanUserId: this.$store.state.user.id
          };
          sealerBagNoBR(sealForm).then(res => {
            if (res.code === 200) {
              this.bagNumber = '';
              this.packageNumber = '';
              this.sealingVisible = false;
              this.$message.success(this.$t('basicData.TheOperationSucceeded')); // 操作成功

              if (this.totalTableData.length <= 1) {
                this.scanList = {};
              }

              this.totalTableData.splice(this.sealIndex, 1);
              localStorage.setItem('totalTableData', JSON.stringify(this.totalTableData));
            } else {
              if (res.msg === this.$t('collectionCenter.theParcelBagged')) {
                this.totalTableData.splice(this.sealIndex, 1);
                localStorage.setItem('totalTableData', JSON.stringify(this.totalTableData));
              }
            }
          }).catch((err) => {
            if (err.msg === this.$t('collectionCenter.theParcelBagged')) {
              this.totalTableData.splice(this.sealIndex, 1);
              this.sealingVisible = false;
              localStorage.setItem('totalTableData', JSON.stringify(this.totalTableData));
            }
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>

.sealingClass span{
  color: #000;
  font-size: 16px;
}
.bagClass{
  color: #21984c;
  cursor: pointer;
}
.bagClass:hover{
  color: rgba(70, 161, 103, 0.87);
}
.bagClass:active{
  color: #21984c;
}
.Box{
  padding: 20px;
  box-sizing: border-box;
}
.topTitle{
  font-size: 24px;
  font-weight: bold;
  padding: 24px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  box-sizing: border-box;
}
.topTitle.t1{ margin-bottom: 20px; }
.topTitle.t2{ border: none; }

::v-deep .el-table {
  border: 1px solid #e2e8f0;
  border-bottom: none;
  border-radius: 6px;
}

.btw{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btw.bor-mar { margin-top: 50px;border: 1px solid #e2e8f0;border-radius: 6px 6px 0 0;box-sizing: border-box; }
.btw.bor-mar ::v-deep .el-button { margin-right: 16px;background-color: #199e53; }

.scanBox{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e2e8f0;
  border-top: none;
  border-radius: 0 0 6px 6px;
  padding: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  .tl1 { width: 55%; }
  .tl2 { width: 15%;margin-left: 20px; }
}

.text-left.tl2 ::v-deep .el-input.is-disabled .el-input__inner {
  color: red;
  font-size: 24px;
}
.signMask{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99;
  min-height: 100vh;
  .signVisibleClass{
    overflow: auto;
    width:100%;
    height:100%;
    background:#fff;
    padding:20px 0;
    // overflow: hidden;
  }
  .signClose{
    position: absolute;
    top: 25px;
    right: 40px;
    transform: scale(1.3);
  }
  .page-content{
    margin: 0 auto;
    width: 100%;
    height: auto;
    overflow-y: auto;
    box-sizing: border-box;
    //max-height: 644px;
  }
  .content {
    border: 1px solid #EBEEF5;
    box-sizing: border-box;
    overflow: hidden;
  }
  .flex {
    margin: 10px;
    text-align: center;
    position: absolute;
    right: -200px;
    bottom: 212px;
  }
}
.flex ::v-deep .el-button{
  height: 40px;
  margin: 20px 0;
  display: block;
  padding: 0 20px;
  width: auto;
}
.flex ::v-deep .el-button--danger{
  color: #FFF !important;
  background-color: #F56C6C !important;
  border-color: #F56C6C !important;
}
.signVisibleClass ::v-deep .el-drawer__header{
  margin-bottom: 0 !important;
}
.signVisibleClass ::v-deep .el-drawer__header>:first-child{
  // display: none;
  opacity: 0;
}
.signVisibleClass ::v-deep .el-drawer__open .el-drawer.btt{
  height: 50% !important;
}
.descriptionsClass{
  position: relative;
  width: 500px;
  margin: 10px auto;
  .logo-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    span{
      font-size: 19px;
    }
    .logo-img {
      height: 80px;
      width: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .desTitle{
    color: #000;
    background: #a8a9ad;
    text-align: center;
    padding: 12px 10px;
    font-size: 20px;
    font-weight: 400;
    border: none;
    box-sizing: border-box;
  }
  .labelT{
    word-wrap: break-word;
  }
  .page-content{
    border-top: none;
    box-sizing: border-box;
    background: #fff;
  }
}
.descriptionsClass ::v-deep .el-descriptions-row{
  width: 100%;
  font-size: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.descriptionsClass ::v-deep tbody{
  font-size: 18px !important;
}
.descriptionsClass ::v-deep .el-descriptions-item__cell{
  border-top: none;
  background: none;
  color: #000;
  font-size: 19px;
  font-weight: 400;
  padding: 7px 10px;
}
.descriptionsClass ::v-deep .el-descriptions-item__content{
  text-align: center;
  border-right: none !important;
}
.descriptionsClass ::v-deep .el-descriptions-item__cell.is-bordered-label{
  width: 200px;
  max-width: 200px;
  border-left: none !important;
  border-right: none !important;
  word-break: break-all;
}

.loadingClass{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.3);
  z-index: 999;
  min-height: 100vh;
}
.loadingClass:before{
  content: "";
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: spin 1s linear infinite;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #62b0ff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
