<template>
  <el-card class="box-card">
    <div style="margin-bottom: 15px; font-weight: bold; color: #be8130">
      {{ $t('navbar.trackingStop') }}
    </div>
    <!--  表格数据  -->
    <el-table
      :data="trackingStopMonitorVOS"
      :header-cell-style="{ color: 'black' }"
      height="375px"
      stripe
      style="width: 100%"
    >
      <el-table-column :label="$t('navbar.siteName')" align="center" prop="siteName" />
      <el-table-column
        :label="$t('collectionCenter.owningTransitCenter1')"
        align="center"
        prop="department"
      >
        <template #default="scope">
          <div>{{ scope.row.department || '--' }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="停更1天" prop="stopDays1">
        <template #default="scope">
          <div v-if="scope.row.siteId === null" class="fontClsBold03">
            {{ scope.row.stopDays1 || '--' }}
          </div>
          <div v-else>{{ scope.row.stopDays1 || '--' }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="停更3天" prop="stopDays3">
        <template #default="scope">
          <div v-if="scope.row.siteId === null" class="fontClsBold03">
            {{ scope.row.stopDays3 || '--' }}
          </div>
          <div v-else>{{ scope.row.stopDays3 || '--' }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="停更7天" prop="stopDays7">
        <template #default="scope">
          <div v-if="scope.row.siteId === null" class="fontClsBold03">
            {{ scope.row.stopDays7 || '--' }}
          </div>
          <div v-else>{{ scope.row.stopDays7 || '--' }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="停更15天" prop="stopDays15">
        <template #default="scope">
          <div v-if="scope.row.siteId === null" class="fontClsBold03">
            {{ scope.row.stopDays15 || '--' }}
          </div>
          <div v-else>{{ scope.row.stopDays15 || '--' }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="停更30天" prop="stopDays30">
        <template #default="scope">
          <div v-if="scope.row.siteId === null" class="fontClsBold03">
            {{ scope.row.stopDays30 || '--' }}
          </div>
          <div v-else>{{ scope.row.stopDays30 || '--' }}</div>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  name: 'TrackStopMonitoring',
  props: {
    trackingStopMonitorVOS: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
};
</script>

<style scoped></style>
