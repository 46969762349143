<template>
  <div>
    <el-button
      v-permit:remove="'btn:finance:costManagement:costsFee:batchDel'"
      type="danger" size="small"
      style="margin-right: 10px;" @click="removeFeeType"
    >{{ $t('receivablePayable.Delete') }}</el-button>

    <el-dialog
      :title="$t('receivablePayable.DeleteTheFee')"
      :visible.sync="isBool"
      width="500px"
      :loading="loading"
    >
      <div>
        <!--注意：会删除所选费用类型的所有数据-->
        <p style="color: red;margin-bottom: 20px;">{{
          $t('receivablePayable.NoteAllDataSelectedChargeDeleted')
        }}
        </p>

        <el-form
          ref="ruleForm"
          :model="ruleForm" :rules="rules"
          label-position="top" size="small"
          class="demo-ruleForm"
        >
          <el-form-item :label="$t('basicData.ExpenseType')" prop="feeTypeCode" style="width: 100%;">
            <el-select
              v-model="ruleForm.feeTypeCode"
              filterable
              clearable
              :placeholder="$t('operationCenter.PleaseSelect')"
              style="width: 100%;"
            >
              <el-option
                v-for="item in feeOptionList"
                :key="item.code"
                :label="item.displayName"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="isBool = false">{{ $t('GlobalSetObj.cancel') }}</el-button>
        <el-button size="small" type="primary" @click="submitForm">{{ $t('GlobalSetObj.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: '',

  props: {
    // 提交函数
    asyncSubmit: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      feeOptionList: [],
      ruleForm: {
        feeTypeCode: ''
      },
      isBool: false,
      loading: false,
      rules: {
        feeTypeCode: [
          { required: true, message: this.$t('receivablePayable.TheChargeTypeCannotBeEmpty'), trigger: ['blur', 'change'] }
        ]
      }
    };
  },

  watch: {
    'isBool': {
      handler(val) {
        if (val) {
          this.$refs.ruleForm?.resetFields();
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    // 弹出删除窗口
    removeFeeType() {
      this.isBool = true;

      this.feeOptionList = this.$store.state.feesPayable.feeTypeOption;
    },
    // 批量删除
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$confirm(this.$i18n.t('receivablePayable.ConfirmDeletionAllDataForSelectedExpenseType'), this.$i18n.t('basicData.tips'), { // '确认删除所选费用类型的所有数据？' 提示
            confirmButtonText: this.$i18n.t('basicData.determine'), // '确定',
            cancelButtonText: this.$i18n.t('basicData.Cancel'), // '取消',
            type: 'warning'
          }).then(() => {
            this.loading = true;
            return this.asyncSubmit(this.ruleForm.feeTypeCode);
          }).then(() => {
            this.isBool = false;
          }).catch(err => {
            console.error(err);
          }).finally(() => {
            this.loading = false;
          });
        }
      });
    }
  }
};
</script>
