<template>
  <el-card class="box-card">
    <div style="margin-bottom: 15px; font-weight: bold">网点SLA达成</div>
    <div class="Liying">
      <div>{{ $t('basicData.shouldDelivered') }}: {{ onDataV3.shouldDeliveredTotal || 0 }}</div>
      <div>{{ $t('operationTips.singedNumber') }}: {{ onDataV3.deliveredTotal || 0 }}</div>
      <div>{{ $t('navbar.unSignCount') }}: {{ onDataV3.unsignedTotal || 0 }}</div>
    </div>
    <div style="height: 340px; overflow-y: auto; overflow-x: hidden">
      <el-row :gutter="10">
        <el-col v-for="(item, index) in siteSlaListV3" :key="index" :span="24">
          <el-col :span="3">
            <div style="text-align: right">{{ item.siteName }}</div>
          </el-col>
          <el-col :span="21">
            <el-progress
              :percentage="item.signRate"
              :stroke-width="20"
              :text-inside="true"
              status="warning"
              style="margin-bottom: 15px"
            />
          </el-col>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'OntimeSignData',
  props: {
    onDataV3: {
      type: Object,
      default: () => {
        return {};
      }
    },
    siteSlaListV3: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
};
</script>

<style scoped></style>
