import i18n from '@/lang';

// 成本审核表格头部配置
export const TABLE_AUDITS_HEADER = [
  // 账单批次号
  { prop: 'billBatchNumber', label: i18n.t('receivablePayable.BillingBatchNumber'), width: 170, align: 'left', highlight: false, disabled: false },
  // 账单状态
  { prop: 'billStatusValue', label: i18n.t('receivablePayable.BillingStatus'), width: 160, align: 'left', highlight: false, disabled: false },
  // 成本主体
  { prop: 'providerName', label: i18n.t('receivablePayable.costumer'), width: 160, align: 'left', highlight: false, disabled: false },
  // 业务类型
  { prop: 'businessType', label: i18n.t('collectionCenter.businessT'), width: 160, align: 'left', highlight: true, disabled: false },
  // 订单数量
  { prop: 'orderQty', label: i18n.t('receivablePayable.TheNumberOfOrders'), width: 160, align: 'left', highlight: true, disabled: false },
  // 费用条数
  { prop: 'feeQty', label: i18n.t('receivablePayable.TheNumberOfFees'), width: 160, align: 'left', highlight: false, disabled: false },
  // 计费总重量（kg）
  { prop: 'chargeWeight', label: i18n.t('receivablePayable.TotalBillableWeight'), width: 160, align: 'left', highlight: false, disabled: false },
  // 应付总金额
  { prop: 'totalReceivable', label: i18n.t('receivablePayable.TheTotalAmountDue'), width: 160, align: 'left', highlight: false, disabled: false },
  // 生成人员
  { prop: 'createUserName', label: i18n.t('receivablePayable.BuildThePerson'), width: 160, align: 'left', highlight: false, disabled: false },
  // 生成时间
  { prop: 'createTime', label: i18n.t('receivablePayable.BuildTime'), width: 160, align: 'left', highlight: false, disabled: false },
  // 审核人员
  { prop: 'auditUserName', label: i18n.t('receivablePayable.Auditors'), width: 160, align: 'left', highlight: false, disabled: false },
  // 审核时间
  { prop: 'auditTime', label: i18n.t('receivablePayable.ReviewTime'), width: 160, align: 'left', highlight: false, disabled: false },
  // 审核原因
  { prop: 'auditRemark', label: i18n.t('receivablePayable.AuditReason'), width: 160, align: 'left', highlight: false, disabled: false }
];

// 成本核付表格头部配置
export const TABLE_APPROVAL_HEADER = [
  // 付款单号、对账批次号
  { prop: 'payableNumber', label: i18n.t('receivablePayable.PaymentSlipNumber') + '/' + i18n.t('receivablePayable.ReconciliationBatchNumber'), width: 220, align: 'left', highlight: false, disabled: false },
  // 付款状态
  { prop: 'payableStatusStr', label: i18n.t('receivablePayable.PaymentStatus'), width: 120, align: 'left', highlight: false, disabled: false },
  // 成本主体
  { prop: 'providerName', label: i18n.t('receivablePayable.costumer'), width: 160, align: 'left', highlight: false, disabled: false },
  // 业务类型
  { prop: 'businessType', label: i18n.t('collectionCenter.businessT'), width: 100, align: 'left', highlight: true, disabled: false },
  // 订单数量
  { prop: 'orderQty', label: i18n.t('receivablePayable.TheNumberOfOrders'), width: 100, align: 'left', highlight: true, disabled: false },
  // 费用条数
  { prop: 'feeQty', label: i18n.t('receivablePayable.TheNumberOfFees'), width: 100, align: 'left', highlight: false, disabled: false },
  // 计费总重量（kg）
  { prop: 'chargeWeight', label: i18n.t('receivablePayable.TotalBillableWeight'), width: 140, align: 'left', highlight: false, disabled: false },
  // 票单金额
  { prop: 'billFee', label: i18n.t('receivablePayable.TicketAmount'), width: 140, align: 'left', highlight: false, disabled: false },
  // 实际付款金额
  { prop: 'actualFee', label: i18n.t('receivablePayable.ActualAmountPaid'), width: 140, align: 'left', highlight: false, disabled: false },
  // 核收人
  { prop: 'acceptUserName', label: i18n.t('receivablePayable.payee'), width: 160, align: 'left', highlight: false, disabled: false },
  // 核收时间
  { prop: 'acceptTime', label: i18n.t('receivablePayable.CollectionTime'), width: 160, align: 'left', highlight: false, disabled: false },
  // 生成人员
  { prop: 'createUserName', label: i18n.t('receivablePayable.BuildThePerson'), width: 100, align: 'left', highlight: false, disabled: false },
  // 生成时间
  { prop: 'createTime', label: i18n.t('receivablePayable.BuildTime'), width: 160, align: 'left', highlight: false, disabled: false },
  // 审核人员
  { prop: 'auditUserName', label: i18n.t('receivablePayable.Auditors'), width: 100, align: 'left', highlight: false, disabled: false },
  // 审核时间
  { prop: 'auditTime', label: i18n.t('receivablePayable.ReviewTime'), width: 160, align: 'left', highlight: false, disabled: false },
  // 文件
  { prop: 'attachmentUrlArr', label: i18n.t('Resource.file'), width: 160, align: 'left', highlight: false, disabled: false },
  // 备注
  { prop: 'remark', label: i18n.t('receivablePayable.remark'), width: 160, align: 'left', highlight: false, disabled: false }
];

// 付款状态
export const PAYABLE_STATUS = Object.freeze({
  0: {
    label: i18n.t('receivablePayable.PendingPayment1'), // 待核付
    value: 0
  },
  1: {
    label: i18n.t('receivablePayable.Paid1'), // 已核付
    value: 1
  },
  2: {
    label: i18n.t('receivablePayable.PartialPayment'), // 部分核付
    value: 2
  }
});

