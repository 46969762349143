// @ts-check

import { cloneDeep } from 'lodash-es';
import { checkIntervalsOverlap } from '@/views/finance/utils.js';

import i18n from '@/lang';

export const ROWS = {
  1: {
    0: {},
    1: {}
  },
  2: {
    0: {},
    1: {}
  },
  3: {
    0: {},
    1: {}
  }
};

// 快递类型
export const EXPRESS_TYPE = {

  2: {
    name: i18n.t('basicData.OrdinaryCourier'), // 普快
    value: 2
  },
  1: {
    name: i18n.t('basicData.express'), // 特快
    value: 1
  },
  3: {
    name: i18n.t('basicData.postal'), // 邮政
    value: 3
  }
};

// 货物类型
export const CARGO_TYPE = {
  0: {
    name: i18n.t('customerManagements.normal'), // 正常
    value: 0
  },
  1: {
    name: i18n.t('placeOrder.big01'), // 大件
    value: 1
  }
};

// 普通价格详情
export const VALIDATE_PRICE_OBJ = {
  businessType: '',
  feeTypeCode: '', // 费用类型
  billingType: '', // 计费类型
  startWeight: '', // 开始重量
  endWeight: '', // 结束重量
  beginCount: '', // 开始数量
  endCount: '', // 结束数量
  offerDetailType: '', // 报价类型
  price: '', // 价格
  startCases: '', // 箱数开始
  endCases: '', // 箱数结束
  overExtraPrice: '', // 备货揽收超出价
  overWeightExtraPrice: '', // 超重加价
  expressType: 0, // 快递类型 2:普快 1 特快
  cargoType: 0, // 货物类型 0：正常 1：大件
  partitionAffiliateId: 0 // 分区
};

// 其他价格详情
export const VALIDATE_OTHER_PRICE_OBJ = {
  feeTypeCode: '', // '费用类型',
  taxRateOne: '', // 税率1
  taxRate: '', // 税率
  price: '', // 价格
  weight: '', // 重量
  specialRules: '', // 特殊规则
  expressType: 0, // 快递类型 2:普快 1 特快
  cargoType: 0, // 货物类型 0：正常 1：大件
  partitionAffiliateId: 0 // 分区
};

// 提交校验
export const { fromValidate } = (() => {
  // 普通规则遍历
  /**
   *
   * @param {Object} row
   * @param {0|1|2} businessType 业务类型
   * @param {0|1} collectionCategory 业务类型
   */
  const comumFor = (row, businessType, collectionCategory) => {
    // console.log('row', row);

    // 快递
    for (const row1 in row) {
      // 获取类型
      for (const row2 in row[row1]) {
        // console.log('row2',);
        // 分区
        for (const key in row[row1][row2]) {
          // console.log('row2', row[row1][row2][key]);

          const [isBool, obj] = comumCheck(row[row1][row2][key], businessType, collectionCategory);
          if (!isBool) {
            return [isBool, obj];
          }
        }
      }
    }

    return [true, {}];
  };

  /**
   * 规则校验
   * @param {Object} arr
   * @param {0|1|2} businessType 业务类型
   * @param {0|1} collectionCategory 揽收类别
   * @returns {[boolean, Object]}
   */
  const comumCheck = (arr, businessType, collectionCategory) => {
    for (const key of arr) {
      const { feeTypeCode, billingType, collectType, startCases, endCases, startWeight, endWeight, price, overWeightExtraPrice, overExtraPrice, expressType, cargoType, partitionAffiliateId: partitionId } = key;

      // console.log(key);

      // 费用类型校验
      if (!feeTypeCode) {
        return [false, { expressType, cargoType, partitionId, name: i18n.t('basicData.ExpenseType') }];
      }

      // 计费类型校验
      // 揽收类型为备货揽收，不用校验计费类型collectionCategory = 1
      if (!collectionCategory && !billingType) {
        return [false, { expressType, cargoType, partitionId, name: i18n.t('basicData.chargeType') }];
      }

      // 揽收类型
      {
        // 业务类型-揽收- + 揽收类别-普通揽收
        const isBool = businessType === 1 && collectionCategory === 0;

        if (isBool && !collectType) {
          return [false, { expressType, cargoType, partitionId, name: i18n.t('newOrder.CollectionType') }];
        }
      }

      // 箱数 开始+结束校验
      {
        const isBool = collectionCategory === 1;
        const startVal = Number.isNaN(Number(startCases));
        const endVal = Number.isNaN(Number(endCases));
        if (isBool) {
          // 箱数开始没有输入
          if (startCases === '') {
            return [false, { expressType, cargoType, partitionId, name: i18n.t('basicData.BoxCountStartsNotIncluded') }];
          }
          // 箱数开始输入的非数字
          if (startVal) {
            return [false, { expressType, cargoType, partitionId, name: i18n.t('basicData.BoxCountStartsNotIncluded') }];
          }

          // 箱数结束没有输入
          if (!endCases) {
            return [false, { expressType, cargoType, partitionId, name: i18n.t('basicData.EndOfCaseCountIncluded') }];
          }
          // 箱数结束输入的非数字
          if (endVal) {
            return [false, { expressType, cargoType, partitionId, name: i18n.t('basicData.EndOfCaseCountIncluded') }];
          }
        }
      }

      // 开始 结束校验
      {
        const isBool = collectionCategory !== 1;
        const startVal = Number.isNaN(Number(startWeight));
        const endVal = Number.isNaN(Number(endWeight));
        if (isBool) {
          // 开始没有输入
          if (startWeight === '') {
            return [false, { expressType, cargoType, partitionId, name: i18n.t('basicData.Begin') }];
          }
          // 开始输入的非数字
          if (startVal) {
            return [false, { expressType, cargoType, partitionId, name: i18n.t('basicData.Begin') }];
          }

          // 结束没有输入
          if (!endWeight) {
            return [false, { expressType, cargoType, partitionId, name: i18n.t('basicData.end') }];
          }
          // 结束输入的非数字
          if (endVal) {
            return [false, { expressType, cargoType, partitionId, name: i18n.t('basicData.end') }];
          }
        }
      }

      // 价格 校验
      {
        const isBool = Number.isNaN(Number(price));
        if (!price || isBool) {
          return [false, { expressType, cargoType, partitionId, name: i18n.t('basicData.Price') }];
        }
      }

      // 超出价格校验（输入了才校验）
      {
        const isCollectionCategory = collectionCategory === 1;
        /** @type {string | number} */
        let val = 0;

        if (isCollectionCategory) {
          val = overExtraPrice;
        } else {
          val = overWeightExtraPrice;
        }

        const isBool = Number.isNaN(Number(val));
        if ((val !== '') && isBool) {
          return [false, { expressType, cargoType, partitionId, name: i18n.t('basicData.ExceedingThePrice') }];
        }
      }
    }

    // 开始 结束区间 重叠 校验
    // {
    //   const list = cloneDeep(arr).map(item => {
    //     const { startWeight, endWeight, startCases, endCases, collectionCategory } = item;
    //     const arr = [];
    //     if (collectionCategory === 1) {
    //       arr.push(startCases, endCases);
    //     } else {
    //       arr.push(startWeight, endWeight);
    //     }
    //     return arr;
    //   }).sort((a, b) => {
    //     return a[0] - b[0];
    //   });
    //   const isBool = checkIntervalsOverlap(list);
    //   if (isBool) {
    //     const { expressType, cargoType, partitionId } = arr[0];
    //     return [false, { expressType, cargoType, partitionId, name: i18n.t('basicData.区间不能重叠') }];
    //   }
    // }

    return [true, {}];
  };

  /**
   * 普通规则错误提示
   * @param {number} expressType
   * @param {number} cargoType
   * @param {number} partitionId
   * @param {string} name
   * @param {*} partitionArr
   */
  const comumErrorTips = (expressType, cargoType, partitionId, name, partitionArr) => {
    const expressName = EXPRESS_TYPE[expressType]?.name ?? '';
    const cargoTypeName = CARGO_TYPE[cargoType]?.name ?? '';
    let partitionStr = '';
    // console.log(partitionId, 'partitionArr', partitionArr);

    const row = partitionArr.find(item => {
      return partitionId === item.id;
    });
    if (row) {
      partitionStr = row.partitionIdentifier;
    }

    return `${expressName}-${cargoTypeName}-${partitionStr}-${name} ${i18n.t('basicData.CheckFail')}`;

    // const expressName = EXPRESS_TYPE[expressType]?.name ?? "";
    // const expressName = EXPRESS_TYPE[expressType]?.name ?? "";
  };

  // 其他规则
  const otherCheck = () => {

  };

  /**
   * 提交校验
   * @param {Object} tableDataObj
   * @param {Object} formData
   * @param {any[]} subregionList 所有分区
   * @returns {[boolean, string]} 是否通过校验
   */
  // const fromValidate = (collectFeesList, feeTypeList) => {
  const fromValidate = (tableDataObj, formData, subregionList) => {
    const { collectFeesList, feeTypeList } = tableDataObj;
    const { businessType, collectionCategory } = formData;

    // 普通规则校验
    {
      const [isBool, obj] = comumFor(collectFeesList, businessType, collectionCategory);

      // console.log(obj, 'obj');

      // 校验不通过
      if (!isBool) {
        const { expressType, cargoType, partitionId, name } = obj;
        const str = comumErrorTips(expressType, cargoType, partitionId, name, subregionList);
        return [false, str];
      }
    }

    // 其他规则校验
    // otherCheck(feeTypeList);
    return [true, ''];
  };

  return {
    fromValidate
  };
})();
