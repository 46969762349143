<template>
  <div class="app-container">
    <div ref="searchForm">
      <el-card class="mb5">
        <el-form
          ref="form" :model="queryForm"
          label-position="top" size="small"
          class="searchContainer"
        >
          <el-row :gutter="10">
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <!--账单日期  :range-separator="$t('operationCenter.to')"-->
                <el-form-item :label="$t('receivablePayable.BillingDate')">
                  <el-date-picker
                    v-model="queryForm.params.payableTimes"
                    style="width: 100%"
                    range-separator="-"
                    type="daterange"
                    :start-placeholder="$t('operationCenter.startDate')"
                    :end-placeholder="$t('operationCenter.endDate')"
                    value-format="yyyy-MM-dd"
                  />
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <!--收款状态-->
                <el-form-item :label="$t('receivablePayable.ReceiptStatus')">
                  <el-select
                    v-model="queryForm.params.receiptStatus"
                    filterable
                    multiple
                    :placeholder="$t('operationCenter.PleaseSelect')"
                    clearable
                    style="width: 100%;"
                  >
                    <!--待核收-->
                    <el-option key="1" :label="$t('receivablePayable.ToBeVerified')" :value="0" />
                    <!--已核收-->
                    <el-option key="2" :label="$t('basicData.Received')" :value="1" />
                    <!--部分核收-->
                    <el-option key="3" :label="$t('receivablePayable.PartialResurvision')" :value="2" />
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <!--应收主体-->
                <el-form-item :label="$t('basicData.businessPrincipal')">
                  <el-select
                    v-model="queryForm.params.providerId"
                    filterable
                    :placeholder="$t('operationCenter.Only10AreDisplayed')"
                    :remote-method="getProviderPage"
                    remote
                    clearable
                    style="width: 100%;"
                    @focus="getProviderPage('')"
                  >
                    <el-option
                      v-for="item in providerList"
                      :key="item.id"
                      :label="item.providerName + '（' + item.code + '）'"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <!--客户-->
                <el-form-item :label="$t('GlobalSetObj.client')">
                  <CustomerSelectCmp style="width: 100%" @change="val => queryForm.params.customerId = val" />
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <!--账单批次号-->
                <el-form-item :label="$t('receivablePayable.BillingBatchNumber')" label-width="">
                  <template slot="label">
                    <el-select
                      v-model="typeNumber"
                      filterable
                      :placeholder="$t('operationCenter.PleaseSelect')"
                      clearable
                      style="width: 100%;"
                    >
                      <!--收款单号-->
                      <el-option key="0" :label="$t('basicData.ReceiptNumber')" :value="1" />
                      <!--账单批次号-->
                      <el-option key="1" :label="$t('receivablePayable.BillingBatchNumber')" :value="2" />
                    </el-select>
                  </template>
                  <InputTextareaCmp
                    ref="inputTextareaRef"
                    :val="searchNum"
                    :max-length="200"
                    @input="(_, val) => searchNum = val"
                  />
                  <!-- <el-input v-model="searchNum" clearable /> -->
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>

        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="searchResources()"
        >
          {{ $t('operationCenter.Procurar') }}
        </el-button>
        <!--重置-->
        <el-button
          icon="el-icon-refresh"
          size="small"
          @click="onReset"
        >{{ $t('basicData.reset') }}
        </el-button>
      </el-card>
    </div>

    <el-card>
      <div class="row mb10">
        <div style="display: flex">
          <!--核收-->
          <el-button
            v-permit:remove="'btn:receivableManagementSite:billCollection:approved'"
            size="small"
            type="primary"
            @click="operationBtn"
          >{{ $t('receivablePayable.receivables') }}</el-button>
          <!--自定义栏目-->
          <CustomStatusBar
            :columns-data="TABLE_HEADER"
            :params="searchParams"
            :save-params="customParams"
            @updateColumns="updateColumns"
          />
        </div>
        <div>
          <el-button
            v-permit:remove="'btn:receivableManagementSite:billCollection:exportDetail'"
            icon="el-icon-download"
            size="small"
            @click="onExport"
          >{{ $t('basicData.账单导出') }}</el-button>
          <!-- <el-button
            v-permit:remove="'btn:receivableManagementSite:billCollection:export'"
            icon="el-icon-download"
            size="small"
            @click="onExportDetail"
          >{{ $t('basicData.账单明细导出') }}</el-button> -->
        </div>
      </div>

      <div class="pending">
        <el-table
          ref="elTable"
          :key="tableKey"
          :data="tableData"
          border
          class="table-height"
          :height="tableHeightRow.height"
          row-key="idCopy"
          :tree-props="{ children: 'siteBill', hasChildren: true }"
          @header-dragend="headerWidthChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection" width="55" align="center"
            :selectable="selectable"
          />
          <!--收款批次号-->
          <el-table-column
            prop="receiptNumber"
            :label="$t('receivablePayable.ReceiptBatchNumber')"
            width="240"
            :show-overflow-tooltip="true"
          >
            <template v-slot="scope">
              <span v-if="scope.row.isParent === true" class="batchNumber" @click="showBillBatchNumber(scope.row)">{{ scope.row.receiptNumber }}</span>
              <span v-else>{{ scope.row.billBatchNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(item, ind) in currentColumns"
            :key="ind"
            :prop="item.prop"
            :label="item.label"
            :align="item.align"
            :min-width="item.width"
            :show-overflow-tooltip="true"
          >
            <template v-slot="scope">
              <div v-if="item.prop === 'receiptBillName'">
                <span v-if="scope.row.isParent === true">{{ scope.row.receiptBillName }}</span>
                <span v-else>{{ scope.row.billName }}</span>
              </div>
              <div v-else-if="item.prop === 'receiptStatus'">
                <!--待收款-->
                <span v-if="scope.row.receiptStatus === 0">{{ $t('receivablePayable.PendingPayment') }}</span>
                <!--已收款-->
                <span v-if="scope.row.receiptStatus === 1">{{ $t('receivablePayable.Received') }}</span>
                <!--部分收款-->
                <span v-if="scope.row.receiptStatus === 2">{{ $t('receivablePayable.PartialCollection') }}</span>
              </div>
              <div v-else-if="item.prop === 'billFee'">
                <span v-if="scope.row.isParent === true">{{ scope.row.billFee }}</span>
                <span v-else>{{ scope.row.totalReceivable }}</span>
              </div>
              <div v-else-if="item.prop === 'remark'">
                <div class="remarkStyle" @click="showImg($event)" v-html="scope.row.remark" />
              </div>
              <div v-else>{{ scope.row[item.prop] }}</div>
            </template>
          </el-table-column>
          <!--操作-->
          <el-table-column
            :label="$t('basicData.operate')"
            align="center"
            width="140"
            fixed="right"
            :show-overflow-tooltip="true"
          >
            <template v-slot="scope">
              <div v-if="scope.row.receiptStatus === 0">
                <el-button
                  v-permit:remove="'btn:receivableManagementSite:billCollection:recall'"
                  type="danger"
                  size="mini"
                  @click="recall(scope.row)"
                >{{ $t('receivablePayable.withdraw') }}</el-button> <!--撤回-->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页工具 -->
        <div class="pagination">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryForm.pageNumber"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size.sync="queryForm.pageSize"
            :total="total"
            background
            style="margin: 10px 0"
            @current-change="getExaminePage"
            @size-change="getExaminePage"
          />
        </div>
      </div>
    </el-card>

    <!--  对账批次详情  -->
    <el-drawer
      :title="$t('receivablePayable.ReconcileBatchDetails') + '-' + batchNumber"
      :append-to-body="true"
      :visible.sync="showDetail"
      direction="ltr"
      custom-class="drawerClass"
      :label="$t('receivablePayable.ReconcileBatchDetails')"
      size="80%"
    >
      <div>
        <batch-number-detail
          :key="batchNumber"
          :batch-number="batchNumber"
          :bill-batch-data="billBatchData"
          @closeDetail="closeDetail"
        />
      </div>
    </el-drawer>
    <!--收款-->
    <el-dialog
      :title="$t('basicData.acceptanceCheck')"
      :visible.sync="showCollection"
      width="80%"
    >
      <div>
        <collection-detail
          :key="collectionKey"
          :recharge-data="selectData[0]"
          :account-option="accountOption"
          @collectionUpdate="collectionUpdate"
        />
      </div>
    </el-dialog>
    <div class="imgs">
      <el-image-viewer v-if="imageViewerObj.showViewer" :on-close="closeViewer" :url-list="imageViewerObj.imgArr" />
    </div>
  </div>
</template>

<script>
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import batchNumberDetail from './cmp/batchNumberDetail';
import {
  apiProviderPage,
  apiSiteReceiptExport,
  // apiSiteReceiptExportDetailed,
  apiSiteReceiptGetPage
} from '@/api/finance/basicData';
import { accountPage } from '@/api/finance/partition';
import { apiSiteBillCancellationOfReceipt } from '@/api/finance/receivables';
import CustomerSelectCmp from '@/components/CustomerSelectCmp/CustomerSelectCmp.vue';
import { cloneDeep } from 'lodash-es';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { goResourceTaskList } from '@/utils/goExportList.js';
import { TABLE_HEADER } from './utils';
import InputTextareaCmp from '@/views/finance/cmp/Query/InputTextareaCmp.vue';

const PARAM = {
  receiptNumber: '', // 收款单号
  billBatchNumber: '', // 账单批次号
  providerId: '', // 业务主体
  receiptStatus: [], // 收款状态
  customerId: '', // 客户
  payableTimes: [] // 账单日期
};

export default {
  name: 'BillCollection',
  components: {
    batchNumberDetail,
    CustomerSelectCmp,
    InputTextareaCmp,
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer'),
    'collection-detail': () => import('./cmp/collectionDetail'),
    CustomStatusBar: () => import('@/components/customStatusBar/index.vue')
  },

  mixins: [estimateTableHeight],

  data() {
    return {
      TABLE_HEADER,
      queryForm: {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 10,
        params: {
          ...PARAM
        }
      },
      createUser: [],
      showDetail: false,
      tableData: [],
      total: 0,
      tableKey: new Date().getTime(),
      batchNumber: '',
      billBatchData: {},
      providerList: [],
      showReceivableManage: false,
      selectData: [],
      rowSelectFlag: false,
      showCollection: false,
      collectionKey: new Date().getTime(),
      accountOption: [],
      imageViewerObj: {
        imgArr: [],
        showViewer: false
      },
      typeNumber: 1,
      searchNum: '',
      currentColumns: [],
      searchParams: { // 自定义栏目搜索参数
        type: 0,
        menuEnum: 'companyToSiteBillCollection'
      },
      customParams: {
        templateField: '',
        type: 0,
        userId: '',
        templateName: '应收账单核收',
        menuEnum: 'companyToSiteBillCollection'
      }
    };
  },
  created() {
    this.createUser = getUserIdAndNameObj();
    this.getExaminePage();
    this.getAccountOption();
  },
  mounted() {
    this.bus.$on('pendingData', () => {
      this.getExaminePage();
    });
  },
  methods: {
    // 更新自定义栏目columns
    updateColumns(list) {
      this.$nextTick(() => {
        this.currentColumns = cloneDeep(list);
        this.tableKey = new Date().getTime();
      });

      // console.log(list, '列表呈现的columns');
    },

    // 图片点击放大
    showImg(e) {
      if (e.target.tagName === 'IMG') {
        this.$set(this.imageViewerObj, 'showViewer', true);
        this.$set(this.imageViewerObj, 'imgArr', [e.target.src]);
      }
    },
    // 关闭查看图片
    closeViewer() {
      this.$set(this.imageViewerObj, 'showViewer', false);
    },
    // 子级禁止选择
    selectable(row) {
      return row.isParent;
    },
    // 更新页面
    collectionUpdate(type) {
      if (type === 2) {
        this.getExaminePage();
      }
      this.showCollection = false;
    },
    // 获取账户
    getAccountOption() {
      const param = {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 1000000,
        params: {
          status: 1
        }
      };
      accountPage(param, false).then(res => {
        if (res.code === 200) {
          this.accountOption = res.data.records;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 核收
    recall(data) {
      const param = {
        receiptNumbers: [data.receiptNumber]
      };
      this.$confirm(this.$i18n.t('receivablePayable.AreYouSureYouWantToWithdraw'), this.$i18n.t('basicData.tips'), { // '确定要撤回?'
        confirmButtonText: this.$i18n.t('basicData.determine'), // '确定',
        cancelButtonText: this.$i18n.t('basicData.Cancel'), // '取消',
        type: 'warning'
      }).then(() => {
        apiSiteBillCancellationOfReceipt(param).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
            this.getExaminePage();
          }
        });
      });
    },
    // 表头宽度变化
    headerWidthChange() {
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
      });
    },
    // 关闭详情
    closeDetail() {
      this.showDetail = false;
    },
    // 获取业务主体
    getProviderPage(name = '') {
      const param = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          providerName: name
        }
      };
      apiProviderPage(param, false).then(res => {
        if (res.code === 200) {
          this.providerList = res.data.records;
        }
      });
    },

    getQueryData() {
      const { pageNumber, pageSize, params: { providerId, receiptStatus, customerId }} = this.queryForm;

      const param = {
        orderDirection: 'DESC',
        pageNumber,
        pageSize,
        params: {
          receiptNumbers: [], // 收款单号
          billBatchNumbers: [], // 对账单批次号
          providerId, // 业务主体
          receiptStatus, // 收款状态
          customerId // 客户
        }
      };

      // 收款单号 / 账单批次号
      if (this.searchNum.length >= 1) {
        const val = this.$refs.inputTextareaRef.getValue();
        if (this.typeNumber === 1) {
          param.params.receiptNumbers = val;
        } else {
          param.params.billBatchNumbers = val;
        }
      }

      // if (this.searchNum) {
      //   if (this.typeNumber === 1) {
      //     param.params.receiptNumber = this.searchNum;
      //     param.params.billBatchNumber = '';
      //   } else {
      //     param.params.billBatchNumber = this.searchNum;
      //     param.params.receiptNumber = '';
      //   }
      // } else {
      //   param.params.receiptNumber = '';
      //   param.params.billBatchNumber = '';
      // }
      return param;
    },

    // 获取列表数据
    getExaminePage() {
      const randomString = (e, t) => {
        // e选取字符长度; t字符串
        e = e || 4;
        const a = t.length;
        let n = '';
        for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
        return n;
      };
      function repeat(receiptNumber, random) {
        const randomStr = randomString(4, receiptNumber);
        if (!random.includes(randomStr)) {
          random.push(randomStr);
          return randomStr;
        } else {
          repeat(receiptNumber, random);
        }
      }
      function recurrence(table) {
        const randomArr = [];
        for (let i = 0; i < table.length; i++) {
          const randomId = repeat(table[i].receiptNumber, randomArr);
          table[i]['idCopy'] = table[i].id + randomId;
          if (table[i].siteBill && table[i].siteBill.length > 0) {
            recurrence(table[i].siteBill);
          }
        }
        return table;
      }

      const param = this.getQueryData();

      // if (this.searchNum) {
      //   if (this.typeNumber === 1) {
      //     param.params.receiptNumber = this.searchNum;
      //     param.params.billBatchNumber = '';
      //   } else {
      //     param.params.billBatchNumber = this.searchNum;
      //     param.params.receiptNumber = '';
      //   }
      // } else {
      //   param.params.receiptNumber = '';
      //   param.params.billBatchNumber = '';
      // }
      apiSiteReceiptGetPage(param).then(res => {
        if (res.code === 200) {
          res.data.records.map(item => {
            for (const key in item) {
              if (key === 'acceptTime' || key === 'auditTime') {
                if (item[key]) {
                  item[key] = item[key].replace('T', ' ');
                }
              }
            }
          });
          this.tableData = recurrence(res.data.records).map(item => {
            const { acceptUserId, createUser, auditUserId } = item;
            const obj = {
              ...item,
              siteName: '', // 主收款单网点名称不显示
              acceptUserName: this.createUser[acceptUserId] || '', // 核收人
              createUserName: this.createUser[createUser] || '', // 生成人员
              auditUserName: this.createUser[auditUserId] || '' // 审核人员
            };

            return Object.freeze(obj);
          });
          this.total = res.data.total;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 列表数据勾选
    handleSelectionChange(arr) {
      if (this.rowSelectFlag) return;
      this.selectData = arr;
    },
    // 获取对账批次详情
    showBillBatchNumber(data) {
      this.batchNumber = data.receiptNumber;
      this.billBatchData = data;
      this.showDetail = true;
    },
    // 搜索
    searchResources() {
      this.queryForm.pageNumber = 1;
      this.getExaminePage();
    },
    // 重置
    onReset() {
      this.queryForm.params = {
        ...PARAM
      };
      this.$refs.inputTextareaRef.clear();

      this.searchResources();
    },
    // 操作
    operationBtn() {
      if (this.selectData.length === 0) {
        this.$message.warning(this.$i18n.t('receivablePayable.SelectAtLeastOnePieceOfData')); // 最少要选择一条数据
        return;
      }
      if (this.selectData.length > 1) {
        this.$message.warning(this.$i18n.t('receivablePayable.OnlyOnePieceOfDataCanBeSelected')); // 只能选择一条数据
        return;
      }
      this.collectionKey = new Date().getTime();
      this.showCollection = true;
      return false;
    },

    // 账单导出
    onExport() {
      const { params } = this.getQueryData();
      params.ids = this.selectData.map(item => {
        return item.id;
      });

      apiSiteReceiptExport(params).then(res => {
        // goExportList(this);
        goResourceTaskList();
      }).catch(err => {
        console.error(err);
      });
    }

    // 账单明细导出
    // onExportDetail() {
    //   const { params } = this.getQueryData();
    //   params.ids = this.selectData.map(item => {
    //     return item.id;
    //   });
    //
    //   apiSiteReceiptExportDetailed(params).then(res => {
    //     goExportList(this);
    //   }).catch(err => {
    //     console.error(err);
    //   });
    // }
  }
};
</script>

<style scoped>
.batchNumber {
  color: #007efd;
  cursor: pointer;
}
</style>
<style>
.columnTree .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  border-color: #aaa;
}
.columnTree .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #aaa;
}
.remarkStyle p {
  padding: 0!important;
  margin: 0!important;
}
.pending .el-table tr.background {
  background-color: #FF0000!important;
  color: #FFF;
}
.pending .el-table tr.background:hover td {
  background-color: #FF0000!important;
  color: #FFF;
}
.selectInput .el-form-item__label .el-input__inner {
  border: 0;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
