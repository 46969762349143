var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        { attrs: { inline: false, "label-position": "top", size: "small" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.problemPieceType"),
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                          },
                          model: {
                            value: _vm.queryForm.params.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "type", $$v)
                            },
                            expression: "queryForm.params.type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("collectionCenter.lanshou"),
                              value: 0,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("collectionCenter.dispatch"),
                              value: 1,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("changepwd.collectReason") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                          },
                          model: {
                            value:
                              _vm.queryForm.params.reasonClassificationCode,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "reasonClassificationCode",
                                $$v
                              )
                            },
                            expression:
                              "queryForm.params.reasonClassificationCode",
                          },
                        },
                        _vm._l(
                          _vm.problemReasonTypeList,
                          function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: item.dictDescribe,
                                value: item.dictCode,
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("changepwd.collectLevel") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                          },
                          model: {
                            value: _vm.queryForm.params.level,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "level", $$v)
                            },
                            expression: "queryForm.params.level",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("changepwd.oneLeave"),
                              value: 1,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("changepwd.twoLeave"),
                              value: 2,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.status") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                          },
                          model: {
                            value: _vm.queryForm.params.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "status", $$v)
                            },
                            expression: "queryForm.params.status",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.enable"),
                              value: 0,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.stop"),
                              value: 1,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.problemReason") } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.queryForm.params.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm.params, "value", $$v)
                          },
                          expression: "queryForm.params.value",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 15 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-search",
                        size: "small",
                        type: "primary",
                      },
                      on: { click: _vm.enterSearch },
                    },
                    [_vm._v(_vm._s(_vm.$t("newOrder.search")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-refresh-left",
                        size: "small",
                        type: "info",
                      },
                      on: { click: _vm.resetForm },
                    },
                    [_vm._v(_vm._s(_vm.$t("newOrder.reset")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "btn:problemConfiguration:addProblem",
                          expression: "'btn:problemConfiguration:addProblem'",
                          arg: "remove",
                        },
                      ],
                      staticStyle: { "margin-bottom": "15px" },
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.addProblem },
                    },
                    [_vm._v(_vm._s(_vm.$t("changepwd.addProblem")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "header-cell-style": { background: "#f2f2f2", color: "black" },
            height: 620,
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.serial"),
              align: "center",
              type: "index",
              width: "70",
            },
          }),
          _vm._l(_vm.tableHeaderList, function (item) {
            return _c("el-table-column", {
              key: item.id,
              attrs: {
                label: item.label,
                "min-width": item.cloWidth,
                prop: item.prop,
                "show-overflow-tooltip": false,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        item.prop === "status"
                          ? _c("div", [
                              scope.row.status === 1
                                ? _c(
                                    "div",
                                    { staticStyle: { color: "#f56c6c" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("GlobalSetObj.stop")) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { staticStyle: { color: "#409eff" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("GlobalSetObj.enable"))
                                      ),
                                    ]
                                  ),
                            ])
                          : item.prop === "zhValue"
                          ? _c("div", [
                              _c("div", { staticClass: "limit-lines" }, [
                                _vm._v(_vm._s(scope.row.zhValue)),
                              ]),
                            ])
                          : item.prop === "enValue"
                          ? _c("div", [
                              _c("div", { staticClass: "limit-lines" }, [
                                _vm._v(_vm._s(scope.row.enValue)),
                              ]),
                            ])
                          : item.prop === "puValue"
                          ? _c("div", [
                              _c("div", { staticClass: "limit-lines" }, [
                                _vm._v(_vm._s(scope.row.puValue)),
                              ]),
                            ])
                          : item.prop === "remark"
                          ? _c("div", [
                              _c("div", { staticClass: "limit-lines" }, [
                                _vm._v(_vm._s(scope.row.remark)),
                              ]),
                            ])
                          : _c("div", { staticClass: "limit-lines" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row[item.prop]
                                      ? "" + scope.row[item.prop]
                                      : "--"
                                  ) +
                                  " "
                              ),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("newOrder.operation"),
              align: "center",
              fixed: "right",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          "align-items": "center",
                        },
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              content: _vm.$t("GlobalSetObj.edit"),
                              effect: "dark",
                              placement: "top",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit textOperator",
                              on: {
                                click: function ($event) {
                                  return _vm.editMessage(scope.row)
                                },
                              },
                            }),
                          ]
                        ),
                        scope.row.status === 1
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  content: _vm.$t("GlobalSetObj.enable"),
                                  effect: "dark",
                                  placement: "top",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "el-icon-circle-check textOperator",
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateStatus(scope.row)
                                    },
                                  },
                                }),
                              ]
                            )
                          : _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  content: _vm.$t("GlobalSetObj.stop"),
                                  effect: "dark",
                                  placement: "top",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "el-icon-remove-outline textOperator1",
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateStatus(scope.row)
                                    },
                                  },
                                }),
                              ]
                            ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        staticStyle: { margin: "10px 0", float: "right" },
        attrs: {
          "current-page": _vm.queryForm.pageNumber,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [10, 20, 50, 100],
          total: _vm.queryForm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.dialogTitle,
            visible: _vm.addCusTimeDialog,
            top: "30px",
            width: "750px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addCusTimeDialog = $event
            },
            close: _vm.handleCloseDialog,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-position": "top",
                "label-width": "auto",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    height: "650px",
                    padding: "0 10px",
                    overflow: "auto",
                    width: "100%",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "collectionCenter.problemPieceType"
                                ),
                                prop: "category",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { disabled: _vm.isDisable },
                                  model: {
                                    value: _vm.ruleForm.category,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "category", $$v)
                                    },
                                    expression: "ruleForm.category",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("changepwd.putongProblem"),
                                      value: 0,
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("changepwd.teshuProblem"),
                                      value: 1,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "collectionCenter.problemPieceType"
                                ),
                                prop: "type",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: _vm.isDisable,
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                  },
                                  model: {
                                    value: _vm.ruleForm.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "type", $$v)
                                    },
                                    expression: "ruleForm.type",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("collectionCenter.lanshou"),
                                      value: 0,
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t(
                                        "collectionCenter.dispatch"
                                      ),
                                      value: 1,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("changepwd.collectReason"),
                                prop: "reasonClassificationCode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: _vm.isDisable,
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                  },
                                  model: {
                                    value:
                                      _vm.ruleForm.reasonClassificationCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "reasonClassificationCode",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.reasonClassificationCode",
                                  },
                                },
                                _vm._l(
                                  _vm.problemReasonTypeList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.dictDescribe,
                                        value: item.dictCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("changepwd.numCode"),
                                prop: "numCode",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  disabled: _vm.isDisable,
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseInput"
                                  ),
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.ruleForm.numCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "numCode", $$v)
                                  },
                                  expression: "ruleForm.numCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("changepwd.collectLevel"),
                                prop: "level",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: _vm.isDisable,
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                  },
                                  on: { change: _vm.getoneLevalproblemList },
                                  model: {
                                    value: _vm.ruleForm.level,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "level", $$v)
                                    },
                                    expression: "ruleForm.level",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("changepwd.oneLeave"),
                                      value: 1,
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("changepwd.twoLeave"),
                                      value: 2,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.ruleForm.level === 2
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("changepwd.parentCode"),
                                    prop: "parentCode",
                                  },
                                },
                                [
                                  _vm.isDisable
                                    ? _c(
                                        "div",
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "100%" },
                                            attrs: { disabled: _vm.isDisable },
                                            model: {
                                              value: _vm.parentValue,
                                              callback: function ($$v) {
                                                _vm.parentValue = $$v
                                              },
                                              expression: "parentValue",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                disabled: _vm.isDisable,
                                                placeholder: _vm.$t(
                                                  "GlobalSetObj.pleaseChoose"
                                                ),
                                                filterable: "",
                                              },
                                              model: {
                                                value: _vm.ruleForm.parentCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.ruleForm,
                                                    "parentCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "ruleForm.parentCode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.oneLevalproblemList,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.code,
                                                  attrs: {
                                                    label: item.value,
                                                    value: item.code,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("changepwd.problemCode"),
                                prop: "code",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  disabled: _vm.isDisable,
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseInput"
                                  ),
                                  clearable: "",
                                  maxlength: "50",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.ruleForm.code,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "code", $$v)
                                  },
                                  expression: "ruleForm.code",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.status"),
                                prop: "status",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                  },
                                  model: {
                                    value: _vm.ruleForm.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "status", $$v)
                                    },
                                    expression: "ruleForm.status",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("GlobalSetObj.enable"),
                                      value: 0,
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("GlobalSetObj.stop"),
                                      value: 1,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.sort"),
                                prop: "sort",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseInput"
                                  ),
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.ruleForm.sort,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "sort", $$v)
                                  },
                                  expression: "ruleForm.sort",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("changepwd.problemReasonZH"),
                                prop: "zhValue",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseInput"
                                  ),
                                  clearable: "",
                                  maxlength: "200",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.ruleForm.zhValue,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "zhValue", $$v)
                                  },
                                  expression: "ruleForm.zhValue",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("changepwd.problemReasonEN"),
                                prop: "enValue",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseInput"
                                  ),
                                  clearable: "",
                                  maxlength: "200",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.ruleForm.enValue,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "enValue", $$v)
                                  },
                                  expression: "ruleForm.enValue",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("changepwd.problemReasonPU"),
                                prop: "puValue",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseInput"
                                  ),
                                  clearable: "",
                                  maxlength: "200",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.ruleForm.puValue,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "puValue", $$v)
                                  },
                                  expression: "ruleForm.puValue",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("changepwd.problemReasonEa"),
                                prop: "esValue",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseInput"
                                  ),
                                  clearable: "",
                                  maxlength: "200",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.ruleForm.esValue,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "esValue", $$v)
                                  },
                                  expression: "ruleForm.esValue",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.remark"),
                                prop: "remark",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseInput"
                                  ),
                                  rows: 3,
                                  clearable: "",
                                  maxlength: "500",
                                  "show-word-limit": "",
                                  type: "textarea",
                                },
                                model: {
                                  value: _vm.ruleForm.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "remark", $$v)
                                  },
                                  expression: "ruleForm.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.cusSubmit(1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.cusSubmit(2)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }