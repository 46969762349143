var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { staticClass: "box-card" }, [
    _c("div", { staticClass: "fontClsBold02" }, [
      _vm._v(_vm._s(_vm.$t("changepwd.opeartData"))),
    ]),
    _c("div", { staticClass: "operationData" }, [
      _c("div", { staticClass: "operationData_item" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/kanban/arriveNumber.png"),
            alt: "",
          },
        }),
        _c("div", { staticStyle: { "margin-left": "10px" } }, [
          _c("div", [
            _vm._v(_vm._s(_vm.$t("customerManagements.NumberArrived"))),
          ]),
          _c("div", { staticClass: "number" }, [_vm._v(_vm._s(_vm.unloading))]),
        ]),
      ]),
      _c("div", { staticClass: "operationData_item" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/kanban/senderNumber.png"),
            alt: "",
          },
        }),
        _c("div", { staticStyle: { "margin-left": "10px" } }, [
          _c("div", [_vm._v(_vm._s(_vm.$t("navbar.shipmentTicketCount")))]),
          _c("div", { staticClass: "number" }, [_vm._v(_vm._s(_vm.loadding))]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }