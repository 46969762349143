var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("object", {
      attrs: {
        data: _vm.htmlUrl,
        type: "text/html",
        width: "100%",
        height: "600px",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }