<template>
  <div>
    <div
      v-permit:remove="'btn:finance:basicData:quotationManagement:edit'"
      style="margin-bottom: 10px"
    >
      <!--下载模板-->
      <el-button size="small" type="primary" @click="downloadTemplate(1)">{{
        $t('basicData.DownloadTheTemplate')
      }}
      </el-button>
    </div>
    <div
      v-permit:remove="'btn:finance:basicData:quotationManagement:edit'"
      style="margin-bottom: 10px"
    >
      <!--批量导入-->
      <upload-excel
        :but-name="BulkImport"
        :on-success="importLogistics"
      />
    </div>
    <div v-if="importType === 1">
      <!--普通规则下载报价-->
      <el-button size="small" type="primary" @click="downloadTemplate(2)">{{
        $t('basicData.DownloadTheQuote')
      }}
      </el-button>
    </div>
    <div v-else-if="importType===2">
      <!--其它规则下载报价-->
      <el-button size="small" type="primary" @click="downloadTemplate(3)">{{
        $t('basicData.DownloadTheQuote')
      }}
      </el-button>
    </div>
    <el-dialog
      :append-to-body="true"
      :title="$t('basicData.tips')"
      :visible.sync="dialogVisible"
      width="400"
    >
      <div>
        <div v-for="(item, ind) in errData" :key="ind" style="color:#f00;margin-bottom: 8px;">{{ item }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue';

const ExcelJs = require('exceljs');
import { cloneDeep } from 'lodash-es';
import { saveAs } from 'file-saver';

export default {
  name: 'ImportDownload',
  components: {
    'upload-excel': () => import('@/components/UploadExcel/index')
  },
  props: {
    tableData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    tableDataObj: {
      type: Object,
      default: () => {
        return {};
      }
    },
    otherRulesRawData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    expenseTypeList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    miscellaneousList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    importType: {
      type: Number,
      default: 1 // 1: 普通规则 2: 其他规则
    }
  },
  data() {
    return {
      BulkImport: this.$t('basicData.BulkImport'), // 批量导入
      MultiPartitionImport: this.$t('basicData.MultiPartitionImport'), // 批量导入
      errData: [],
      dialogVisible: false
    };
  },
  methods: {
    // 导出数据处理
    exportData() {
      const collectFeesList = [];
      for (const k in this.tableDataObj) {
        if (this.tableDataObj[k]) {
          for (const k1 in this.tableDataObj[k]) {
            for (const k2 in this.tableDataObj[k][k1]) {
              if (this.tableDataObj[k][k1][k2]) {
                this.tableDataObj[k][k1][k2].map((item) => {
                  collectFeesList.push(item);
                });
              }
            }
            // if (this.tableDataObj[k][k1]) {
            //   this.tableDataObj[k][k1].map((item) => {
            //     collectFeesList.push(item);
            //   });
            // }
          }
        }
      }
      const collectFeesArr = [];
      collectFeesList.map(item => {
        let zipcode = '';
        this.tableData.subregionList.map(list => {
          if (item.partitionAffiliateId === list.id) {
            zipcode = list.postalCode;
          }
        });
        const obj = {
          ...item,
          name: this.tableData.formData.name,
          offerType: this.tableData.formData.offerType === 1
            ? this.$t('basicData.StandardPrice') : this.tableData.formData.offerType === 2
              ? this.$t('basicData.BusinessEntityPrice') : this.tableData.formData.offerType === 3 ? this.$t('basicData.SitePrice') : this.$t('basicData.SpecifyCustomerPrice'),
          providerInfoName: this.tableData.formData.providerInfoName,
          siteName: this.tableData.formData.siteName,
          partitionName: this.tableData.formData.partitionName,
          zipcode: zipcode
        };
        collectFeesArr.push(obj);
      });
      return {
        collectFeesArr: collectFeesArr
      };
    },
    // 下载模板\下载报价
    downloadTemplate(type) {
      const that = this;
      if (that.tableData.subregionList.length === 0) {
        that.$message.warning(that.$t('basicData.PleaseChooseAPartitionSchemeFirst'));
        return;
      }
      // type： 1: 下载模板 2: 下载报价 3:下载其它规则报价
      // importType：下载报价 1: 普通规则 2: 其他规则

      async function ddd() {
        const wb = new ExcelJs.Workbook();
        if (that.importType === 1) {
          // 普通规则模板
          const Sheet1 = wb.addWorksheet('Sheet1');
          const Sheet2 = wb.addWorksheet('Sheet2');
          const Sheet3 = wb.addWorksheet('Sheet3');
          const Sheet4 = wb.addWorksheet('Sheet4');
          const Sheet5 = wb.addWorksheet('Sheet5');
          const Sheet6 = wb.addWorksheet('Sheet6');
          const Sheet1_data = [];
          if (type === 1) {
            // 下载模板栏目
            let columns = [
              {
                header: '*' + that.$t('basicData.PartitionName'), // 分区
                key: 'partitionAffiliateId',
                width: 20
              },
              {
                header: '*' + that.$t('basicData.ExpenseType'), // 费用类型
                key: 'feeTypeCode',
                width: 20
              },
              {
                header: '*' + that.$t('basicData.chargeType'), // 计费类型
                key: 'billingType',
                width: 20
              },
              {
                header: '*' + that.$t('basicData.Begin'), // 开始
                key: 'startWeight',
                width: 30
              },
              {
                header: '*' + that.$t('basicData.end'), // 结束
                key: 'endWeight',
                width: 30
              },
              {
                header: '*' + that.$t('basicData.Price'), // 价格
                key: 'price',
                width: 30
              },
              {
                header: that.$t('basicData.ExceedingThePrice'), // 超出价格
                key: 'overWeightExtraPrice',
                width: 30
              }
            ];
            if (that.tableData.formData.businessType === 1) {
              if (that.tableData.formData.collectionCategory === 0) {
                const arrObj = {
                  header: '*' + that.$t('newOrder.CollectionType'), // 揽收类型
                  key: 'collectType',
                  width: 20
                };
                columns.splice(3, 0, arrObj);
                console.log(columns, '普通揽收');
              } else {
                columns.splice(2);
                const columnsNew = [
                  {
                    header: '*' + that.$t('basicData.QuoteType'), // 报价类型
                    key: 'offerDetailType',
                    width: 20
                  },
                  {
                    header: '*' + that.$t('basicData.BoxCountStartsNotIncluded'), // 箱数开始(不包含)
                    key: 'startCases',
                    width: 20
                  },
                  {
                    header: '*' + that.$t('basicData.EndOfCaseCountIncluded'), // 箱数结束(包含)
                    key: 'endCases',
                    width: 20
                  },
                  {
                    header: '*' + that.$t('basicData.Price'), // 价格
                    key: 'price',
                    width: 20
                  },
                  {
                    header: that.$t('basicData.ExceedingThePrice'), // 价格
                    key: 'overExtraPrice',
                    width: 20
                  }
                ];
                columns = [...columns, ...columnsNew];
                console.log(columns, '备货揽收');
              }
            }
            Sheet1.columns = columns;
          } else {
            // 下载报价栏目
            const collectFeesArr = that.exportData().collectFeesArr;
            let columns = [
              {
                header: that.$t('basicData.TheNameOfTheQuoteProposal'), // 报价方案名称
                key: 'name',
                width: 30
              },
              {
                header: that.$t('basicData.QuoteType'), // 报价类型
                key: 'offerType',
                width: 20
              },
              {
                header: that.$t('basicData.BusinessPrincipal'), // 业务主体
                key: 'providerInfoName',
                width: 30
              },
              {
                header: that.$t('basicData.Outlets'), // 网点
                key: 'siteName',
                width: 20
              },
              {
                header: that.$t('basicData.PartitionSchemeName'), // 分区方案
                key: 'partitionName',
                width: 30
              },
              {
                header: that.$t('basicData.PartitionName'), // 分区
                key: 'partitionIdentifier',
                width: 20
              },
              {
                header: that.$t('basicData.zipCode'), // 分区邮编
                key: 'zipcode',
                width: 20
              },
              {
                header: '*' + that.$t('basicData.ExpenseType'), // 费用类型
                key: 'feeTypeCode',
                width: 20
              },
              {
                header: '*' + that.$t('basicData.chargeType'), // 计费类型
                key: 'billingType',
                width: 20
              },
              {
                header: '*' + that.$t('basicData.Begin'), // 开始
                key: 'startWeight',
                width: 30
              },
              {
                header: '*' + that.$t('basicData.end'), // 结束
                key: 'endWeight',
                width: 30
              },
              {
                header: '*' + that.$t('basicData.Price'), // 价格
                key: 'price',
                width: 30
              },
              {
                header: that.$t('basicData.ExceedingThePrice'), // 超出价格
                key: 'overWeightExtraPrice',
                width: 30
              }
            ];
            const arrObj = {
              header: '*' + that.$t('newOrder.CollectionType'), // 揽收类型
              key: 'collectType',
              width: 20
            };
            if (that.tableData.formData.businessType === 1) {
              if (that.tableData.formData.collectionCategory === 0) {
                columns.splice(9, 0, arrObj);
              } else {
                columns.splice(8);
                const columnsNew = [
                  {
                    header: '*' + that.$t('basicData.QuoteType'), // 报价类型
                    key: 'offerDetailType',
                    width: 20
                  },
                  {
                    header: '*' + that.$t('basicData.BoxCountStartsNotIncluded'), // 箱数开始(不包含)
                    key: 'startCases',
                    width: 20
                  },
                  {
                    header: '*' + that.$t('basicData.EndOfCaseCountIncluded'), // 箱数结束(包含)
                    key: 'endCases',
                    width: 20
                  },
                  {
                    header: '*' + that.$t('basicData.Price'), // 价格
                    key: 'price',
                    width: 20
                  },
                  {
                    header: that.$t('basicData.ExceedingThePrice'), // 超出价格
                    key: 'overExtraPrice',
                    width: 20
                  }
                ];
                columns = [...columns, ...columnsNew];
              }
            }
            Sheet1.columns = columns;
            collectFeesArr.map((item) => {
              that.expenseTypeList.map(list => {
                if (item.feeTypeCode === list.code) {
                  item.feeTypeCode = list.displayName;
                }
              });
              if (item.billingType === 1) {
                item.billingType = that.$t('newOperationCenter.Weight');
              } else {
                item.billingType = that.$t('collectionCenter.ballot');
              }
              if (that.tableData.formData.businessType === 1) {
                if (item.collectType === 1) {
                  item.collectType = that.$t('collectionCenter.dropOff');
                } else {
                  item.collectType = that.$t('collectionCenter.doorPickUp');
                }

                if (item.offerDetailType === 1) {
                  item.offerDetailType = that.$t('basicData.minimumStandardBilling');
                } else {
                  item.offerDetailType = that.$t('basicData.tieredPricing');
                }
              }
              Sheet1_data.push({
                ...item
              });
            });
            Sheet1.addRows(Sheet1_data);
          }
          Sheet1.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
          Sheet1.getRow(1).height = 20;
          Sheet2.columns = [
            {
              header: that.$t('basicData.PartitionName'), // 分区
              key: 'partitionIdentifier',
              width: 20
            },
            {
              header: that.$t('basicData.PartitionName') + '(ID)', // 分区
              key: 'partitionAffiliateId',
              width: 20
            }
          ];
          const Sheet2_data = [];
          that.tableData.subregionList.map((item) => {
            Sheet2_data.push({
              partitionAffiliateId: item.id,
              partitionIdentifier: item.partitionIdentifier + '->' + item.id
            });
          });
          Sheet2.addRows(Sheet2_data);
          Sheet3.columns = [
            {
              header: that.$t('basicData.ExpenseType') + '(' + that.$t('ChannelManage.name') + ')', // 费用类型
              key: 'feeTypeName',
              width: 20
            },
            {
              header: that.$t('basicData.ExpenseType') + '(code)', // 费用类型
              key: 'feeTypeCode',
              width: 20
            }
          ];
          const Sheet3_data = [];
          that.expenseTypeList.map((item) => {
            Sheet3_data.push({
              feeTypeCode: item.code,
              feeTypeName: item.displayName + '->' + item.code
            });
          });
          Sheet3.addRows(Sheet3_data);
          Sheet4.columns = [
            {
              header: that.$t('basicData.chargeType') + '(' + that.$t('ChannelManage.name') + ')', // 计费类型
              key: 'chargeType',
              width: 20
            },
            {
              header: that.$t('basicData.chargeType') + '(id)', // 计费类型
              key: 'billingType',
              width: 20
            }
          ];
          const Sheet4_data = [
            {
              chargeType: that.$t('newOperationCenter.Weight') + '->' + 1,
              billingType: 1
            }, // '重量'
            {
              chargeType: that.$t('collectionCenter.ballot') + '->' + 2,
              billingType: 2
            } // '票数'
          ];
          Sheet4.addRows(Sheet4_data);
          Sheet5.columns = [
            {
              header: that.$t('newOrder.CollectionType'), // 揽收类型
              key: 'collectType',
              width: 20
            }
          ];
          const Sheet5_data = [
            { collectType: that.$t('collectionCenter.dropOff') + '->' + 1 }, // 上门交件
            { collectType: that.$t('collectionCenter.doorPickUp') + '->' + 2 } // 上门揽收
          ];
          Sheet5.addRows(Sheet5_data);
          Sheet6.columns = [
            {
              header: that.$t('basicData.QuoteType'), // 报价类型
              key: 'offerDetailType',
              width: 20
            }
          ];
          const Sheet6_data = [
            { offerDetailType: that.$t('basicData.minimumStandardBilling') + '->' + 1 }, // 最低标准计费
            { offerDetailType: that.$t('basicData.tieredPricing') + '->' + 0 } // 阶梯计费
          ];
          Sheet6.addRows(Sheet6_data);
          if (type === 1) {
            new Array(10000).fill(0).forEach((_, idx) => {
              const row = idx + 2;
              Sheet1.getCell(row, 1).dataValidation = {
                type: 'list',
                formulae: [`=Sheet2!$A$2:$A${Sheet2_data.length + 1}`]
              };
              Sheet1.getCell(row, 2).dataValidation = {
                type: 'list',
                formulae: [`=Sheet3!$A$2:$A${Sheet3_data.length + 1}`]
              };
              Sheet1.getCell(row, 3).dataValidation = {
                type: 'list',
                formulae: [`=Sheet6!$A$2:$A${Sheet6_data.length + 1}`]
              };
              if (that.tableData.formData.collectionCategory !== 1) {
                Sheet1.getCell(row, 3).dataValidation = {
                  type: 'list',
                  formulae: [`=Sheet4!$A$2:$A${Sheet4_data.length + 1}`]
                };
                if (that.tableData.formData.businessType === 1) {
                  Sheet1.getCell(row, 4).dataValidation = {
                    type: 'list',
                    formulae: [`=Sheet5!$A$2:$A${Sheet5_data.length + 1}`]
                  };
                }
              }
            });
          }
        } else {
          // 其他规则模板
          const Sheet1 = wb.addWorksheet('Sheet1');
          const columns = [
            {
              header: '*' + that.$t('basicData.PartitionName'), // 分区
              key: 'partitionIdentifier',
              width: 20
            },
            {
              header: '*' + that.$t('basicData.ExpenseType'), // 费用类型
              key: 'feeTypeCode',
              width: 20
            },
            {
              header: that.$t('basicData.PercentageInsurancePremium'), // 保险费比例
              key: 'taxRate',
              width: 20
            },
            {
              header: that.$t('basicData.MinimumPremiumStandards'), // 保险费最低标准
              key: 'price',
              width: 20
            },
            {
              header: that.$t('basicData.WhetherToAddICMS'), // 是否添加ICMS
              key: 'isICMS',
              width: 20
            },
            {
              // header: that.$t('basicData.WhetherToAddISS'), // 是否添加ISS
              header: that.$t('basicData.isAddIssn1'), // 是否添加ISS
              key: 'isISS',
              width: 20
            },
            {
              // header: that.$t('basicData.WhetherToAddISS'), // 是否添加ISS
              header: that.$t('basicData.isAddIssn'), // 是否添加ISSn
              key: 'isISSn',
              width: 20
            },
            {
              header: that.$t('basicData.GRISTaxRates'), // GRIS税率
              key: 'taxRate4',
              width: 20
            },
            {
              header: that.$t('basicData.minimumStandard'), // GRIS最低标准
              key: 'price4',
              width: 20
            },
            {
              header: that.$t('basicData.riskManagementTaxRate'), // 风险管理费税率
              key: 'taxRate1',
              width: 20
            },
            {
              header: that.$t('basicData.PalletHandlingChargesAreOverweight') + '(KG)', // 托盘操作处理费超重(KG)
              key: 'weight1',
              width: 30
            },
            {
              header: that.$t('basicData.PalletHandlingHandlingFee') + '(BRL/' + that.$t('basicData.ticket') + ')', // 托盘操作处理费(BRL/票)
              key: 'price1',
              width: 30
            },
            {
              header: that.$t('basicData.OverweightHandlingFeeForOverweight') + '(KG)', // 超重处理费超重(KG)
              key: 'weight',
              width: 20
            },
            {
              header: that.$t('basicData.OverweightHandlingFee1') + '(BRL)', // 超重处理费(BRL)
              key: 'price2',
              width: 20
            },
            {
              header: that.$t('basicData.adValoremRatio'), // 从价税比例
              key: 'taxRate3',
              width: 20
            },
            {
              header: that.$t('basicData.adValoremTaxFloor'), // 从价税最低标准
              key: 'price3',
              width: 20
            },
            {
              header: that.$t('basicData.roadTollKG'), // 过路费/KG
              key: 'weight111',
              width: 30
            },
            {
              header: that.$t('basicData.roadTollBRL'), // 过路费（BRL）
              key: 'price111',
              width: 30
            },
            {
              header: that.$t('basicData.IssueFeeBRL'), // 发行费（BRL）
              key: 'price222',
              width: 30
            }
          ];
          if (type === 1) {
            Sheet1.columns = columns;
          } else if (type === 3) {
            // 下载其它规则报价新增字段
            const newColumns = [
              {
                header: that.$t('basicData.TheNameOfTheQuoteProposal'), // 报价方案名称
                key: 'name',
                width: 30
              },
              {
                header: that.$t('basicData.QuoteType'), // 报价类型
                key: 'offerType',
                width: 20
              },
              {
                header: that.$t('basicData.BusinessPrincipal'), // 业务主体
                key: 'providerInfoName',
                width: 30
              },
              {
                header: that.$t('basicData.Outlets'), // 网点
                key: 'siteName',
                width: 20
              },
              {
                header: that.$t('basicData.PartitionSchemeName'), // 分区方案
                key: 'partitionName',
                width: 30
              },
              {
                header: that.$t('basicData.zipCode'), // 分区邮编
                key: 'zipcode',
                width: 20
              }
            ];
            Sheet1.columns = [
              ...newColumns,
              ...columns
            ];
            // 下载报价栏目参数
            const collectFeesArr = that.otherRulesRawData;
            let Sheet1_data = [];
            // 费用类型通过对象来存储
            const miscellaneousListObj = {};
            that.miscellaneousList.forEach(item => {
              miscellaneousListObj[item.code] = item.displayName + '->' + item.code;
            });
            const { name, offerType, providerInfoName, siteName, partitionName } = that.tableData.formData;
            // 报价类型对象
            const offerTypeObj = {
              1: that.$t('basicData.StandardPrice'),
              2: that.$t('basicData.BusinessEntityPrice'),
              3: that.$t('basicData.SitePrice'),
              4: that.$t('basicData.SpecifyCustomerPrice')
            };
            // 筛选出分区对应的邮编
            const subregionObj = {};
            that.tableData.subregionList.forEach(list => {
              subregionObj[list.partitionIdentifier] = list.postalCode;
            });
            Sheet1_data = collectFeesArr.map((item) => {
              const data = { name, providerInfoName, siteName, partitionName };
              data.offerType = offerTypeObj[offerType];
              data.zipcode = subregionObj[item.partitionIdentifier];
              // 获取费用类型
              data.feeTypeCode = miscellaneousListObj[item.feeTypeCode];
              // 分区方案名称
              data.partitionIdentifier = item.partitionIdentifier;
              // 转换成需要的值
              if (item.feeTypeCode === 'FY005') {
                data.taxRate = item.taxRate;
                data.price = item.price;
              } else if (item.feeTypeCode === 'FY006') {
                data.taxRate1 = item.taxRate;
              } else if (item.feeTypeCode === 'FY009') {
                data.weight1 = item.weight;
                data.price1 = item.price;
              } else if (item.feeTypeCode === 'FY011') {
                data.weight = item.weight;
                data.price2 = item.price;
              } else if (item.feeTypeCode === 'FY013') {
                data.taxRate3 = item.taxRate;
                data.price3 = item.price;
              } else if (item.feeTypeCode === 'FY014') {
                data.taxRate4 = item.taxRate;
                data.price4 = item.price;
              } else if (item.feeTypeCode === 'FY015') {
                data.weight111 = item.weight;
                data.price111 = item.price;
              } else if (item.feeTypeCode === 'FY016') {
                data.price222 = item.price;
              }
              // 是否添加ICMS/ISS
              if (item.feeTypeCode === 'FY007') {
                data.isISS = that.$t('GlobalSetObj.yes') + '->' + 1;
              } else {
                data.isISS = that.$t('GlobalSetObj.No') + '->' + 0;
              }
              // 是否添加ISS税（n/（1-税率）*税率
              if (item.feeTypeCode === 'FY017') {
                data.isISSn = that.$t('GlobalSetObj.yes') + '->' + 1;
              } else {
                data.isISSn = that.$t('GlobalSetObj.No') + '->' + 0;
              }
              // 是否添加ISSn
              if (item.feeTypeCode === 'FY017') {
                data.isISSn = that.$t('GlobalSetObj.yes') + '->' + 1;
              } else {
                data.isISSn = that.$t('GlobalSetObj.No') + '->' + 0;
              }
              if (item.feeTypeCode === 'FY008') {
                data.isICMS = that.$t('GlobalSetObj.yes') + '->' + 1;
              } else {
                data.isICMS = that.$t('GlobalSetObj.No') + '->' + 0;
              }
              return data;
            });
            Sheet1.addRows(Sheet1_data);
          }
          const Sheet2 = wb.addWorksheet('Sheet2');
          Sheet2.columns = [
            {
              header: that.$t('basicData.PartitionName') + '(' + that.$t('ChannelManage.name') + ')', // 分区
              key: 'partitionIdentifier',
              width: 20
            },
            {
              header: that.$t('basicData.PartitionName') + '(ID)', // 分区
              key: 'partitionAffiliateId',
              width: 20
            }
          ];
          const Sheet2_data = [];
          that.tableData.subregionList.map((item) => {
            Sheet2_data.push({
              partitionAffiliateId: item.id,
              partitionIdentifier: item.partitionIdentifier + '->' + item.id
            });
          });
          Sheet2.addRows(Sheet2_data);
          const Sheet3 = wb.addWorksheet('Sheet3');
          Sheet3.columns = [
            {
              header: that.$t('basicData.ExpenseType') + '(' + that.$t('ChannelManage.name') + ')', // 费用类型
              key: 'feeTypeName',
              width: 20
            },
            {
              header: that.$t('basicData.ExpenseType') + '(code)', // 费用类型
              key: 'feeTypeCode',
              width: 20
            }
          ];
          const Sheet3_data = [];
          that.miscellaneousList.map((item) => {
            Sheet3_data.push({
              feeTypeCode: item.code,
              feeTypeName: item.displayName + '->' + item.code
            });
          });
          Sheet3.addRows(Sheet3_data);
          const Sheet4 = wb.addWorksheet('Sheet4');
          Sheet4.columns = [
            {
              header: that.$t('GlobalSetObj.yes'), // 是否添加ICMS/ISS
              key: 'isAdd',
              width: 20
            }
          ];
          const Sheet4_data = [
            {
              isAdd: that.$t('GlobalSetObj.yes') + '->' + 1
            },
            {
              isAdd: that.$t('GlobalSetObj.No') + '->' + 0
            }
          ];
          Sheet4.addRows(Sheet4_data);

          new Array(10000).fill(0).forEach((_, idx) => {
            const row = idx + 2;
            if (type === 1) {
              Sheet1.getCell(row, 1).dataValidation = {
                type: 'list',
                formulae: [`=Sheet2!$A$2:$A${Sheet2_data.length + 1}`]
              };
              Sheet1.getCell(row, 2).dataValidation = {
                type: 'list',
                formulae: [`=Sheet3!$A$2:$A${Sheet3_data.length + 1}`]
              };
              Sheet1.getCell(row, 5).dataValidation = {
                type: 'list',
                formulae: [`=Sheet4!$A$2:$A${Sheet4_data.length + 1}`]
              };
              Sheet1.getCell(row, 6).dataValidation = {
                type: 'list',
                formulae: [`=Sheet4!$A$2:$A${Sheet4_data.length + 1}`]
              };
            }
          });
        }

        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        // let url = blob;
        const date = new Date().getTime();
        let saveName; // = type === 2 ? that.tableData.name + date + '.xlsx' : that.$t('basicData.PartitionImportTemplate') + '.xlsx';
        if (that.importType === 1) {
          if (type === 1) {
            saveName = that.$t('basicData.ImportQuotationTemplatesInBulk') + '.xlsx'; // 批量导入报价模板
          } else {
            saveName = that.tableData.formData.name + date + '.xlsx'; // 揽收费
          }
        } else {
          if (type === 1) {
            saveName = that.$t('basicData.ImportOtherQuoteTemplatesBulk') + '.xlsx'; // 批量导入其他报价模板
          } else {
            saveName = that.tableData.formData.name + date + '.xlsx'; // 揽收费
          }
        }
        saveAs(blob, saveName);
      }

      ddd();
    },
    // 批量导入报价
    importLogistics(arr) {
      const header = arr[0].header;
      this.errData = [];
      const allData = [];
      const arrData = [];
      const newAllData = [];
      // const collectFeesList = [];
      const feeTypeList = [];
      const sortArr = (a, b) => {
        return a - b;
      };
      if (this.importType === 1) { // 正常规则
        let required = [
          '*' + this.$t('basicData.PartitionName'), // '*分区名称',
          '*' + this.$t('basicData.ExpenseType'), // 费用类型
          '*' + this.$t('basicData.chargeType'), // 计费类型
          '*' + this.$t('basicData.Begin'), // 开始
          '*' + this.$t('basicData.end'), // 结束
          '*' + this.$t('basicData.Price') // 价格
        ];
        let required2 = [
          '*' + this.$t('basicData.PartitionName'), // '*分区名称',
          '*' + this.$t('basicData.ExpenseType'), // 费用类型
          '*' + this.$t('basicData.chargeType'), // 计费类型
          '*' + this.$t('basicData.Price') // 价格
        ];
        let headObj = [
          'partitionAffiliateId', // 分区名称
          'feeTypeCode', // 费用类型
          'billingType', // 计费类型
          'startWeight', // 开始
          'endWeight', // 结束
          'price', // 价格
          'overWeightExtraPrice' // 超出价格
        ];
        if (this.tableData.formData.businessType === 1) {
          required = [
            '*' + this.$t('basicData.PartitionName'), // '*分区名称',
            '*' + this.$t('basicData.ExpenseType'), // 费用类型
            '*' + this.$t('basicData.chargeType'), // 计费类型
            '*' + this.$t('newOrder.CollectionType'), // 揽收类型
            '*' + this.$t('basicData.Begin'), // 开始
            '*' + this.$t('basicData.end'), // 结束
            '*' + this.$t('basicData.Price') // 价格
          ];
          headObj = [
            'partitionAffiliateId', // 分区名称
            'feeTypeCode', // 费用类型
            'billingType', // 计费类型
            'collectType', // 揽收类型
            'startWeight', // 开始
            'endWeight', // 结束
            'price', // 价格
            'overWeightExtraPrice' // 超出价格
          ];
          if (this.tableData.formData.collectionCategory === 1) {
            required = [
              '*' + this.$t('basicData.PartitionName'), // '*分区名称',
              '*' + this.$t('basicData.ExpenseType'), // 费用类型
              '*' + this.$t('basicData.QuoteType'), // 报价类型
              '*' + this.$t('basicData.BoxCountStartsNotIncluded'), // 箱数开始
              '*' + this.$t('basicData.EndOfCaseCountIncluded'), // 结束
              '*' + this.$t('basicData.Price'), // 价格
              '*' + this.$t('basicData.ExceedingThePrice') // 超重价格
            ];
            required2 = [
              '*' + this.$t('basicData.PartitionName'), // '*分区名称',
              '*' + this.$t('basicData.ExpenseType'), // 费用类型
              '*' + this.$t('basicData.Price') // 价格
            ];
            headObj = [
              'partitionAffiliateId', // 分区名称
              'feeTypeCode', // 费用类型
              'offerDetailType', // 报价类型
              'startCases', // 开始
              'endCases', // 结束
              'price', // 价格
              'overExtraPrice' // 超出价格
            ];
          }
        }
        arr[0].results.map((item, ind) => {
          const keyArr = [];
          const data = {};
          header.map(list => {
            data[list] = (item[list] !== '' && item[list] !== null && item[list] !== undefined) ? item[list] : '';
          });
          allData.push(data);
          Object.keys(item).forEach((key) => {
            keyArr.push(key);
          });
          if (item.billingType === 1) {
            required.map(list => {
              if (!keyArr.includes(list)) {
                const text = this.$t('basicData.row') + (ind + 1) + this.$t('basicData.line') + ',' + list + this.$t('basicData.CannotBeEmpty');
                this.errData.push(text);
              }
            });
          } else {
            required2.map(list => {
              if (!keyArr.includes(list)) {
                const text = this.$t('basicData.row') + (ind + 1) + this.$t('basicData.line') + ',' + list + this.$t('basicData.CannotBeEmpty');
                this.errData.push(text);
              }
            });
          }
        });
        allData.map((item) => {
          const data = {};
          Object.keys(item).forEach((key, i) => {
            if (item['*' + this.$t('basicData.chargeType')] === 2) {
              if ((key === this.$t('basicData.ExceedingThePrice')) || (key === '*' + this.$t('basicData.Begin')) || (key === '*' + this.$t('basicData.end'))) {
                data[headObj[i]] = '';
              } else {
                if (headObj[i] === 'partitionAffiliateId' || headObj[i] === 'feeTypeCode') {
                  data[headObj[i]] = item[key] ? item[key].split('->')[1] : '';
                  if (headObj[i] === 'feeTypeCode') {
                    this.expenseTypeList.map(dataItem => {
                      if (data[headObj[i]] === dataItem.code) {
                        data['specialRules'] = dataItem.specialRules ? dataItem.specialRules : '';
                      }
                    });
                  }
                } else if (headObj[i] === 'billingType' || headObj[i] === 'collectType') {
                  data[headObj[i]] = item[key] ? parseInt(item[key].split('->')[1]) : '';
                } else {
                  data[headObj[i]] = item[key];
                }
              }
            } else {
              if (headObj[i] === 'partitionAffiliateId' || headObj[i] === 'feeTypeCode' || headObj[i] === 'offerDetailType') {
                data[headObj[i]] = item[key] ? item[key].split('->')[1] : '';
                if (headObj[i] === 'feeTypeCode') {
                  this.expenseTypeList.map(dataItem => {
                    if (data[headObj[i]] === dataItem.code) {
                      data['specialRules'] = dataItem.specialRules ? dataItem.specialRules : '';
                    }
                  });
                }
                if (headObj[i] === 'offerDetailType') {
                  data[headObj[i]] = item[key] ? Number(item[key].split('->')[1]) : '';
                }
              } else if (headObj[i] === 'billingType' || headObj[i] === 'collectType') {
                data[headObj[i]] = item[key] ? parseInt(item[key].split('->')[1]) : '';
              } else {
                data[headObj[i]] = item[key];
              }
            }
            data['expressType'] = this.tableData.formData.expressType; // 快递类型 1: 特快 2: 普快
          });
          arrData.push(data);
        });

        const rowPrefix = this.$t('basicData.row');
        const lineSuffix = this.$t('basicData.line');
        arrData.map((item, ind) => {
          // 包裹开始数量不能大于包裹结束数量
          let start;
          if (item.startWeight !== undefined && item.startWeight !== null) {
            start = parseFloat(item.startWeight); // 如果startWeight存在，无论其值如何，都使用它
          } else {
            start = Number(item.startCases); // 如果startWeight不存在，使用startCases
          }
          let end;
          if (item.endWeight !== undefined && item.endWeight !== null) {
            end = parseFloat(item.endWeight);
          } else {
            end = Number(item.endCases);
          }
          if (start > end || isNaN(start) || isNaN(end)) {
            this.errData.push(`${rowPrefix}${ind + 1}${lineSuffix},${this.$t('basicData.TheStartValueCannotBeGreaterThanTheEndValue')}`);
          }

          // arrData.map((list, i) => {
          //   if (ind !== i) {
          //     if (list.partitionAffiliateId === item.partitionAffiliateId) {
          //       const b1 = list.feeTypeCode;
          //       const b2 = list.billingType;
          //       const index = [ind + 1, i + 1].sort(sortArr).join(',');
          //       if (b1 === a1) {
          //         if (a2 !== b2) {
          //           if (!collectFeesList.includes(index)) {
          //             collectFeesList.push(index);
          //             this.errData.push(this.$t('basicData.row') + index +
          //               this.$t('basicData.line') + this.$t('basicData.ThereCantBeDifferentBillingTypesForTheSameChargeType'));
          //             // '费用类型相同计费类型不能不相同';);
          //           }
          //         }
          //       }
          //     }
          //   }
          // });
        });
      } else {
        // 其他规则
        const required = [
          '*' + this.$t('basicData.PartitionName'), // '*分区名称',
          '*' + this.$t('basicData.ExpenseType') // 费用类型
        ];
        const headObj = [
          'partitionAffiliateId', // 分区名称
          'feeTypeCode', // 费用类型
          'taxRate2', // 保险费比例
          'price2', // 保险费最低标准
          'isICMS', // 是否添加ICMS
          'isISSn', // 是否添加ISS税（n/（1-税率）*税率）
          'isISS', // 是否添加ISS（n*税率）
          'taxRate', // GRIS税率
          'price', // GRIS最低标准
          'taxRate3', // 风险管理税率
          'weight', // 托盘操作处理费超重
          'price4', // 托盘操作处理费
          'weight1', // 超重处理费超重（KG）
          'price5', // 超重处理费（BRL）
          'taxRate1', // 从价税比例
          'price1', // 从价费最低标准
          'weight2', // 过路费（KG）
          'price6', // 过路费（BRl）
          'price3' // 发行费（BRL）
          // 'taxRate1', // GRIS税率
          // 'weight111',
          // 'price111',
          // 'price222'
        ];
        arr[0].results.map((item, ind) => {
          const keyArr = [];
          const data = {};
          header.map(key => {
            data[key] = (item[key] !== '' && item[key] !== null && item[key] !== undefined) ? item[key] : '';
          });
          allData.push(data);
          Object.keys(item).forEach((key) => {
            keyArr.push(key);
          });
          required.map(list => {
            if (!keyArr.includes(list)) {
              const text = this.$t('basicData.row') + (ind + 1) + this.$t('basicData.line') + ',' + list + this.$t('basicData.CannotBeEmpty');
              this.errData.push(text);
            }
          });
        });
        console.log(allData, '----------------原始数据------------', this.miscellaneousList);

        const newArrData = cloneDeep(allData);
        newArrData.map(item => {
          const data = {};
          Object.keys(item).forEach((key, i) => {
            const headKey = headObj[i];
            // console.log(key, i, headKey, item[key]);
            // 通用属性处理，快递类型赋值，拆分并赋值到对应字段
            data['expressType'] = this.tableData.formData.expressType; // 快递类型 1: 特快 2: 普快 3 邮政
            // 处理分区、费用类型、是否添加ICMS、是否添加ISS税（n/（1-税率）*税率），拆分并赋值到对应字段
            if (['partitionAffiliateId', 'feeTypeCode', 'isICMS', 'isISSn'].includes(headKey)) {
              data[headObj[i]] = item[key] ? item[key].split('->')[1] : '';
              if (headKey === 'feeTypeCode') {
                const matchedItem = this.miscellaneousList.find(dataItem =>
                  data[headKey] === dataItem.code
                );
                if (matchedItem) {
                  data.specialRules = matchedItem.specialRules ?? '';
                }
              }
            } else {
              data[headObj[i]] = item[key] || item[key] === 0 ? item[key] : '';
            }
          });
          // newAllData.push(data);
          // console.log(data, '赋值后数据');
          if (data.specialRules !== 2 && data.specialRules !== 3) {
            newAllData.push(data);
          } else {
            if ((data.specialRules === 2 && data.isISSn === '1') || (data.specialRules === 3 && data.isICMS === '1')) {
              newAllData.push(data);
            }
          }
        });
        console.log(newAllData, '----------------更新后------------');
        // return;
        // arrData.map(item => {
        //   // 判断特殊规则类型
        //   if (item.specialRules === 0) {
        //     if (item.feeTypeCode === 'FY013') {
        //       item.price = item.price3;
        //       item.taxRate = item.taxRate3;
        //     } else if (item.feeTypeCode === 'FY014') {
        //       item.price = item.price4;
        //       item.taxRate = item.taxRate4;
        //     }
        //   } else if (item.specialRules === 1) {
        //     if (item.feeTypeCode === 'FY006') {
        //       item.taxRate = item.taxRate1;
        //     }
        //   } else if (item.specialRules === 4) {
        //     if (item.feeTypeCode === 'FY009') {
        //       item.price = item.price1;
        //       item.weight = item.weight1;
        //     } else if (item.feeTypeCode === 'FY011') {
        //       item.price = item.price2;
        //     }
        //   } else if (item.specialRules === 5) {
        //     item.price = item.price111;
        //     item.weight = item.weight111;
        //   } else if (item.specialRules === 6) {
        //     item.price = item.price222;
        //   }
        //   delete item.price111;
        //   delete item.weight111;
        //   delete item.price222;
        //   delete item.taxRate1;
        //   delete item.taxRate3;
        //   delete item.price1;
        //   delete item.price2;
        //   delete item.price3;
        //   delete item.weight1;
        //   delete item.isICMS;
        //   delete item.isISS;
        //   delete item.isISSn;
        //   delete item.taxRate4;
        //   delete item.price4;
        // });

        // 常量定义
        const SPECIAL_RULES = {
          DEFAULT: 0,
          TYPE1: 1,
          TYPE4: 4,
          TYPE5: 5,
          TYPE6: 6
        };

        const FEE_TYPE = {
          FY013: 'FY013',
          FY014: 'FY014',
          FY006: 'FY006',
          FY009: 'FY009',
          FY011: 'FY011'
        };
        newAllData.forEach(item => {
          // 强制类型转换避免字符串数值问题
          const ruleType = Number(item.specialRules);

          // 规则逻辑
          const ruleStrategies = {
            [SPECIAL_RULES.DEFAULT]: () => {
              if (item.feeTypeCode === FEE_TYPE.FY013) {
                return { price: item.price1, taxRate: item.taxRate1 };
              }
              if (item.feeTypeCode === FEE_TYPE.FY014) {
                return { price: item.price, taxRate: item.taxRate };
              }
              return null;
            },
            [SPECIAL_RULES.TYPE1]: () => {
              if (item.feeTypeCode === FEE_TYPE.FY006) {
                return { taxRate: item.taxRate3 };
              }
              return null;
            },
            [SPECIAL_RULES.TYPE4]: () => {
              if (item.feeTypeCode === FEE_TYPE.FY009) {
                return { price: item.price4, weight: item.weight };
              }
              if (item.feeTypeCode === FEE_TYPE.FY011) {
                return { price: item.price4 };
              }
              return null;
            },
            [SPECIAL_RULES.TYPE5]: () => ({
              price: item.price2,
              weight: item.taxRate2
            }),
            [SPECIAL_RULES.TYPE6]: () => ({
              price: item.taxRate3
            })
          };
          // 合并属性
          const updates = ruleStrategies[ruleType]?.() || {};
          // if (ruleType === 0 && item.feeTypeCode === FEE_TYPE.FY014) {
          //   console.log(updates, 'updates');
          //   console.log(ruleType, 'ruleType', ruleStrategies);
          // }
          Object.assign(item, updates);

          const propsToRemove = [
            'price111', 'weight111', 'price222', 'taxRate1',
            'taxRate3', 'price1', 'price2', 'price3', 'weight1',
            'isICMS', 'isISS', 'isISSn', 'taxRate4', 'price4'
          ];

          propsToRemove.forEach(prop => {
            if (Object.prototype.hasOwnProperty.call(item, prop)) {
              Vue.delete(item, prop); // 使用Vue确保响应式更新
            }
          });
        });

        const jsonArrData = cloneDeep(newAllData);
        const seen = new Set();
        const idMap = new Map();

        // 预处理建立分区映射
        newAllData.forEach((item, index) => {
          const key = item.partitionAffiliateId;
          if (!idMap.has(key)) idMap.set(key, new Map());
          const feeMap = idMap.get(key);

          if (feeMap.has(item.feeTypeCode)) {
            const prevIndex = feeMap.get(item.feeTypeCode);
            const linePair = [prevIndex + 1, index + 1].sort().join(',');
            if (!seen.has(linePair)) {
              seen.add(linePair);
              this.errData.push(
                `${this.$t('basicData.row')}${linePair}` +
                  `${this.$t('basicData.line')}` +
                  this.$t('basicData.TheCostTypeTheSamePartitionCannotBeTheSame')
              );
            }
          } else {
            feeMap.set(item.feeTypeCode, index);
          }
        });

        newAllData.map((item, index) => {
          jsonArrData.map((list, ind) => {
            if (index !== ind) {
              if (item.partitionAffiliateId === list.partitionAffiliateId) {
                if (item.feeTypeCode === list.feeTypeCode) {
                  const arrIndex = [index + 1, ind + 1].sort(sortArr).join(',');
                  if (!feeTypeList.includes(arrIndex)) {
                    feeTypeList.push(arrIndex);
                    this.errData.push(this.$t('basicData.row') + arrIndex +
                      this.$t('basicData.line') + this.$t('basicData.TheCostTypeTheSamePartitionCannotBeTheSame'));
                    // '分区相同费用类型不能相同';);
                  }
                }
              }
            }
          });
        });
      }

      console.log(arrData, '普通规则');
      console.log(newAllData, '其他规则');
      console.log(this.errData, 'errData');
      const newFeeData = this.importType === 1 ? arrData : newAllData;
      if (this.errData.length > 0) {
        this.dialogVisible = true;
      } else {
        this.$emit('importPartition', newFeeData, this.importType);
      }
    }
  }
};
</script>

<style scoped>

</style>
