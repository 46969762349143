<!-- 费用详情 -->
<template>
  <el-drawer
    :title="$t('receivablePayable.FeeDetails')"
    :append-to-body="true"
    :visible.sync="isVisible"
    custom-class="drawerClass"
    direction="ltr"
    size="80%"
  >
    <div>
      <div class="details">
        <!-- 基本信息 -->
        <BasicForm :add-batch-fee="addBatchFee" />

        <!-- 费用明细 :is-allow-edit="addBatchFee.allowEdit"-->
        <el-card :header="$t('receivablePayable.FeeBreakdown')">
          <FeeTable
            ref="detailsTableRef"
            :add-type="2"
            :business-type="addBatchFee.businessType"
            :list="addBatchFeeList"
          />
        </el-card>
      </div>
      <!-- 按钮 -->
      <div class="btn">
        <el-button
          v-permit:remove="'btn:finance:costManagement:costsFee:Edit'"
          type="primary" size="small"
          @click="submit"
        >{{ $t('basicData.Save') }}</el-button>
        <!--关闭-->
        <el-button size="small" @click="onClose">{{ $t('orderCenterCont.ShutDown') }}</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import {
  getCostSiteExpenseInfo,
  getAddCostSiteExpenseFee,
  getDeleteCostSiteExpenseFee
} from '@/api/finance/costManagement.js';

export default {
  name: '',

  components: {
    BasicForm: () => import('./basicForm.vue'),
    FeeTable: () => import('./feeTable.vue')
  },

  props: {
    // xx详情数据
    row: {
      type: Object,
      default: () => {
        return {};
      }
    },

    // 外部搜索条件
    queryForm: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  data() {
    return {
      isVisible: false,
      key: 1,

      addBatchFee: {}, // 里面的内容后续补充
      addBatchFeeList: [] // 费用详情
    };
  },

  methods: {
    // 父元素调用
    open() {
      this.isVisible = true;
      this.addBatchFeeList = [];
      setTimeout(() => {
        this.getDetails();
      }, 100);
    },

    // 获取详情
    getDetails() {
      const { id } = this.row;
      getCostSiteExpenseInfo(id).then(res => {
        if (res.code === 200) {
          console.log(res, '===res');

          this.$set(this, 'addBatchFee', res.data);
          this.addBatchFeeList = res.data.expenseSubList.map(item => ({
            ...item,
            isEdit: false
          }));
        }
      });
    },

    // 提交
    submit() {
      const obj = this.$refs.detailsTableRef.submitEdit();
      console.log(obj, '===obj');
      if (obj.valid) return;
      if (obj.arr.length === 0) {
        this.$message.warning(this.$i18n.t('receivablePayable.AddAtLeastOneCharge'));
        return;
      }
      const param = {
        id: this.row.id,
        expenseSubList: obj.arr
      };
      getAddCostSiteExpenseFee(param).then(res => {
        if (res.code !== 200) return;
        this.$message.success(this.$i18n.t('GlobalSetObj.SaveSuccessfully')); // 保存成功
        this.isVisible = false;
        this.$emit('close', true);
      }).catch(err => {
        console.error(err);
      });
    },

    onClose(isBool = false) {
      this.isVisible = false;
      this.$emit('close', isBool);
    }
  }
};
</script>

<style scoped>
.details {
  width: 100%;
  height: calc(100vh - 120px);
  overflow-y: scroll;
}

.btn {
  position: sticky;
  left: 0;
  bottom: 5px;
  background-color: #fff;
  width: 100%;
  z-index: 10000;
  border-top: 1px solid #EBEEF5;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
