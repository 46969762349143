import i18n from '@/lang';
// import { Message } from 'element-ui';
// 邮编不能为空
const notNull = i18n.t('customerManagements.notNull'); // 不能为空
const zipCode = i18n.t('GlobalSetObj.zipCode'); // 邮编
const IncorrectFormatting = i18n.t('customerManagements.IncorrectFormatting'); // 格式不正确
const intersect = i18n.t('basicData.intersect'); // 行邮编存在交集
const partition = i18n.t('operationCenter.partition'); // 分区
// const success = i18n.t('operationCenter.ProcessingSucceeded'); // 处理成功
// const error = i18n.t('operationCenter.processingFailure'); // 处理失败

// 校验同分区下的邮编是否重复
export const checkZipCode = (list) => {
  const errArr = [];

  // 同 收发件州 合并处理
  const map = setState(list);

  // 没有重叠邮编
  if (map.size === 0) {
    return errArr;
  }

  // 同分区下
  map.forEach(arr => {
    // 邮编二次处理
    checkOverlap(arr, errArr);
  });
  return errArr;
};

// 重叠校验
export const checkOverlap = (arr, errArr) => {
  const list = arr.map(item => {
    item.postalCode = setRowData(item.postalCode);
    return item;
  });

  // 第1行与第2、3、4行比较，然后第2行与3、4比较
  for (let i = 0; i < list.length; i++) {
    // 邮编的每一项与其它行的每一项进行对比
    list[i].postalCode.forEach(item => {
      for (let j = i + 1; j < list.length; j++) {
        list[j].postalCode.forEach(key => {
          const maxStart = [item[0], key[0]];
          const minEnd = [item[1], key[1]];
          if (Math.max(...maxStart) <= Math.min(...minEnd)) {
            // const str = `分区【${list[i].partitionIdentifier}】邮编${item.join('~')}与分区【${list[j].partitionIdentifier}】邮编${key.join('~')}`;
            const str = `${partition}【${list[i].partitionIdentifier}】${zipCode}${item.join('~')} And ${partition}【${list[j].partitionIdentifier}】${zipCode}${key.join('~')} ${intersect}`;

            errArr.push(str);
          }
        });
      }
    });
  }
  // return errArr;
};

// 同 收发件州 合并处理
const setState = (list) => {
  const map = new Map();
  // 将所有的数据按照 收发件人州 进行分组
  list.forEach(item => {
    const { state } = item;
    // 存在
    if (map.has(state)) {
      const a = map.get(state);
      a.push(item);
      map.set(state, a);
    } else {
      map.set(state, [item]);
    }
  });
  map.forEach(item => {
    if (item.length === 1) {
      map.delete(item[0].state);
    }
  });
  return map;
};

const setRowData = (val) => {
  let str = '';
  // 邮编长度
  if (val.length) {
    str = val.trim();
  }

  // 替换 所有中文逗号为英文逗号
  const arr = str.replace(/，/ig, ',').split(',').map(item => {
    return item.trim();
  }).map(item => {
    return item.split('~');
  }).map(item => {
    if (item.length === 1) {
      return [item[0], item[0]];
    }
    return item;
  });
  return arr;
};

// 同行比较分区
export const rowComparison = (value) => {
  const arr = [true, ''];
  const list = setRowData(value).map(item => {
    return item.map(key => Number(key));
  });
  if (list.length === 1) {
    return arr;
  }

  // 邮编的每一项与其它行的每一项进行对比
  for (let i = 0; i < list.length; i++) {
    for (let j = i + 1; j < list.length; j++) {
      const maxStart = [list[i][0], list[j][0]];
      const minEnd = [list[i][1], list[j][1]];
      if (Math.max(...maxStart) <= Math.min(...minEnd)) {
        arr[0] = false;
        arr[1] = intersect;
        break;
      }
    }
    i++;
    if (arr[0] === false) {
      break;
    }
  }
  return arr;
};

const reg = /[A-Z \-]*[0-9]+/g; // 至少存在一个数字

// 邮编格式校验
export const postalCodeFormat = (value) => {
  const arr = [true, ''];

  // 必填
  if (!value) {
    // return [false, '邮编不能为空'];
    arr[0] = false;
    arr[1] = zipCode + notNull;
    return arr;
  }

  reg.lastIndex = 0;
  if (!reg.test(value)) {
    arr[0] = false;
    arr[1] = zipCode + IncorrectFormatting;
    return arr;
  }

  // 替换 所有中文逗号为英文逗号
  const list = value.replace(/，/ig, ',').split(',').map(item => {
    return item.trim();
  });
  for (const item of list) {
    // 长度为了0
    if (item.length === 0) {
      arr[0] = false;
      arr[1] = zipCode + IncorrectFormatting + 1;
      // return false;
      break;
    }

    // 如果是单独的数字
    if (!item.includes('~')) {
      const isNum = isFinite(item);
      if (!isNum) {
        arr[0] = false;
        arr[1] = zipCode + IncorrectFormatting + 12;
        break;
      }
    }

    // 如果是区间[1~2]
    if (item.includes('~')) {
      const list = item.split('~').map(item => {
        return item.trim();
      });

      // 不能出现 100~1000~10000
      if (list.length > 2) {
        arr[0] = false;
        arr[1] = zipCode + IncorrectFormatting;
        break;
      }

      // 不能出现 "~100" "~" "100~"
      if (list[0] === '' || list[1] === '') {
        arr[0] = false;
        arr[1] = zipCode + IncorrectFormatting;
        break;
      }

      // 左右非数字
      if (!isFinite(list[0]) || !isFinite(list[1])) {
        arr[0] = false;
        arr[1] = zipCode + IncorrectFormatting;
        break;
      }

      // 不能出现 10~9 10~10，前提是都为纯数字
      if (Number(list[0]) > Number(list[1])) {
        arr[0] = false;
        arr[1] = zipCode + IncorrectFormatting;
        break;
      }
    }
  }

  // return isBool;
  return arr;
};

/**
 * 批量发送数据
 * @param id 报价id
 * @param dataArray 目标数据数组
 * @param batchSize 每批次数量
 * @param fn 回调函数
 * @param type 1 报价规则 2 其他规则
 */
export const sendBatchData = async(id, dataArray, batchSize, fn, type = null) => {
  const chunks = splitData(dataArray, batchSize);
  const totalChunks = chunks.length;
  let successCount = 0;
  const data = [];
  console.log(totalChunks);
  // 顺序处理每个分块
  for (let i = 0; i < chunks.length; i++) {
    try {
      const params = {
        offerPriceId: id,
        partitionAffiliateIdList: chunks[i].map(item => item.id)
      };
      const res = await fn(params);
      // const { code, data } = res;
      if (res.code === 200) {
        data.push(...res.data);
        successCount++;
        // 显示进度
        // Message.info(`${i18n.t('newOrder.processing')}: ${Math.round((i + 1) / totalChunks * 100)}%`);
      }
    } catch (error) {
      console.error(`第 ${i + 1} ${type === 1 ? '报价详情' : '其他规则详情'}:${i18n.t('operationCenterRoute.processingFailure')}`, error);
      // Message.error(`第 ${i + 1} 批次导入失败`);
    }
  }

  const result = {
    successCount,
    allSuccessful: successCount === totalChunks,
    data
  };

  // 显示最终结果
  // if (result.allSuccessful) {
  //   Message.success(success);
  // } else {
  //   Message.error(error);
  // }
  // 请求全部返回结果
  return Promise.resolve(result);
};

/**
 * 拆分数据数组
 * @param dataArray 目标数据
 * @param batchSize 每批次数量
 * @returns {*[]}
 */
export const splitData = (dataArray, batchSize) => {
  const batches = [];
  for (let i = 0; i < dataArray.length; i += batchSize) {
    batches.push(dataArray.slice(i, i + batchSize));
  }
  return batches;
};
