import { cloneDeep } from 'lodash-es';
import { apiPayableSiteExpenseGetPage } from '@/api/finance/receivables';
import { apiProviderPage } from '@/api/finance/basicData';
import { apiCommonSite } from '@/api/logisticsOrders';
import { getUserPage } from '@/api/user';
import { feePage } from '@/api/finance/partition';
import { collectDriverQueryCourierNew } from '@/api/logisticsOrders';
import store from '@/store';

/**
 * 获取列表数据
 * @param {{}} query 搜索字段
 * @returns {Promise<any[]>} 返回结果
   */
export const asyncGetList = (query) => {
  const queryForm = cloneDeep(query);

  queryForm.params.ids = [];

  return apiPayableSiteExpenseGetPage(queryForm).then(res => {
    return res;
  }).catch(err => {
    console.error(err);
  });
};

/**
 * 获取主体、网点、操作人、费用类型的下拉数据
 * @returns {Promise<any[]>} 返回结果
 */
export const initGetData = (() => {
  const providerList = 'providerList'; // 应付主体选项
  const outletsList = 'outletsList'; // 获取站点
  // const operatorList = 'operatorList'; // 操作人

  // 从缓存中提前数据
  const getStorageData = (key) => {
    if (store.state.feesPayable[key] && store.state.feesPayable[key].length > 0) {
      return store.state.feesPayable[key];
    }
    return undefined;
  };

  /**
   * 获取主体数据
   * @returns
   */
  const providerParam = {
    pageSize: 9999999,
    pageNumber: 1,
    params: {
      state: 1
    }
  };
  const getProviderPage = () => {
    const arr = getStorageData(providerList);
    if (arr) {
      return Promise.resolve(arr);
    }

    return apiProviderPage(providerParam, false).then(res => {
      if (res.code === 200) {
        const { records } = res.data;
        // console.log(records, '主体');

        store.commit('feesPayable/SET_PROVIDER_LIST', records);
        return records;
      }
      throw new Error('获取主体数据失败');
    }).catch(err => {
      console.error(err);
      return [];
    });
  };

  /**
   * 获取网点数据
   * @returns
   */
  const siteParams = {
    pageNumber: 1,
    pageSize: 9999999,
    params: {
      siteStatus: undefined,
      name: ''
    }
  };
  const getSitePageList = () => {
    const arr = getStorageData(outletsList);
    if (arr) {
      return Promise.resolve(arr);
    }
    return apiCommonSite(siteParams, false).then(res => {
      if (res.code === 200) {
        const { records } = res.data;
        // console.log(records, '网点');

        const list = records.map((item) => {
          return {
            id: item.id,
            name: item.name
          };
        });

        store.commit('feesPayable/SET_OUTLETS_LIST', list);
        return records;
      }
      throw new Error('获取网点数据失败');
    }).catch(err => {
      console.error(err);
      return [];
    });
  };

  /**
   * 获取操作人
   */
  const operatorParams = { // user + pm_user
    pageSize: 9999999,
    pageNumber: 1,
    params: {
      realName: '',
      accountState: 1
    }
  };
  const newOperatorParams = { // pm_user
    pageSize: 9999999,
    pageNumber: 1,
    params: {
      deliveryManName: ''
    }
  };
  const getOperatorList = (type) => {
    // const arr = getStorageData(operatorList);
    // if (arr) {
    //   return Promise.resolve(arr);
    // }

    // AJD操作人数据来源-供应商员工数据
    const api = type === 3 ? collectDriverQueryCourierNew : getUserPage;
    const obj = type === 3 ? newOperatorParams : operatorParams;
    return api(obj, false).then((response) => {
      if (response.code === 200) {
        const records = type === 3 ? response.data : response.data.records;
        // const { records } = response.data;
        // console.log(records, '操作人', response.data);

        store.commit('feesPayable/SET_OPERATOR_LIST', records);
        return records;
      }
      throw new Error('获取操作人数据失败');
    }).catch(err => {
      console.error(err);
      return [];
    });
  };

  /**
   * 获取费用类型
   * @param {*} type 业务类型 1 安俊 2 第三方 3 AJD 4 DSP
   * @returns
   */
  const feeParams = {
    orderDirection: 'DESC',
    pageNumber: 1,
    pageSize: 10000,
    params: {
      state: 1,
      attribute: 2, // 1 应收，2 应付
      businessType: ''
    }
  };
  const getExpenseType = (type) => {
    feeParams.params.businessType = type;

    return feePage(feeParams, false).then(res => {
      if (res.code === 200) {
        const { records } = res.data;
        // console.log(records, '费用类型');

        store.commit('feesPayable/SET_FEE_TYPE_OPTION', records);
        return records;
      }
      throw new Error('获取费用类型数据失败');
    }).catch(err => {
      console.error(err);
      return [];
    });
  };

  // 初始化数据
  return (obj) => {
    const arr = [getProviderPage(), getSitePageList(), getOperatorList(obj.payableType), getExpenseType(obj.payableType)];
    return Promise.allSettled(arr).then(res => {
      console.log(res, 'initGetData-缓存数据');
      const list = [];
      res.forEach((item) => {
        if (item.status === 'fulfilled') {
          list.push(item.value);
        } else {
          list.push([]);
        }
      });
      return list;
    });
  };
})();
