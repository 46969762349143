<template>
  <div class="app-container">
    <el-card class="searchContainer mb5">
      <!-- 搜索栏 -->
      <el-form
        ref="form"
        :model="fromParam"
        label-width="auto"
        label-position="top"
        size="small"
      >
        <el-row :gutter="10">
          <!--州-->
          <el-col :span="6">
            <el-form-item :label="$t('orderDetails.State')" class="selectInner">
              <el-select
                v-model="fromParam.state"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                style="width: 100%;"
              >
                <el-option
                  v-for="item in stateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
                  <span style="float: left">{{ item.label }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.name }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!--规则名称-->
          <el-col :span="6">
            <el-form-item :label="$t('GlobalSetObj.RuleName')">
              <el-input
                v-model="fromParam.ruleName"
                class="input-with-select"
                clearable
                :placeholder="$t('GlobalSetObj.pleaseInput')"
              />
            </el-form-item>
          </el-col>
          <!--业务类型 -->
          <el-col :span="6">
            <el-form-item :label="$t('collectionCenter.businessT')">
              <el-select
                v-model="fromParam.businessType"
                filterable
                :placeholder="$t('operationCenter.PleaseSelect')"
                clearable
                style="width: 100%;"
              >
                <el-option
                  v-for="item in claimNode"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 索赔类型 -->
          <el-col :span="6">
            <el-form-item :label="$t('compensationManagement.ClaimType')">
              <el-select
                v-model="fromParam.claimType"
                filterable
                :placeholder="$t('operationCenter.PleaseSelect')"
                clearable
                style="width: 100%;"
              >
                <el-option key="1" :label="$t('compensationManagement.TimeoutNotClosedLoop')" value="1" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-button
        type="primary"
        size="small"
        icon="el-icon-search"
        @click="onQuery"
      >{{ $t('operationCenter.Procurar') }}</el-button>
      <el-button size="small" icon="el-icon-refresh-left" @click="onReset">
        {{ $t('operationCenter.reset') }}
      </el-button>
    </el-card>

    <el-card>
      <div class="mb10">
        <!-- 添加 -->
        <el-button
          v-permit:remove="'but:compensationManagement:compensationRuleList:add'"
          type="primary"
          size="small"
          @click="add"
        >{{ $t('GlobalSetObj.append') }}</el-button>
        <!-- 导出 -->
        <el-button
          v-permit:remove="'but:compensationManagement:compensationRuleList:export'"
          type="info"
          size="small"
          @click="onExport"
        >{{ $t('CenterForSorting.export') }}</el-button>
      </div>

      <el-table
        :data="tableData"
        :height="tableHeightRow.height"
        class="table-height"
        border
        style="width: 100%"
      >
        <!--序号-->
        <el-table-column :label="$t('operationCenter.ind')" width="60px">
          <template v-slot="scope">
            <span>{{ (pageNumber - 1) * pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="item in RULE_TABLE"
          :key="item.prop"
          :align="item.fixed"
          :label="item.label"
          :min-width="item.width"
          :prop="item.prop"
          :show-overflow-tooltip="true"
        >
          <template v-slot="scope">
            <!-- 规则名称-->
            <span v-if="item.prop === 'ruleName'">
              <el-link type="primary" @click="onOpen(scope.row)">{{ scope.row.ruleName }}</el-link>
            </span>
            <!-- 业务类型-->
            <span v-else-if="item.prop === 'businessType'">
              {{ claimNode[scope.row.businessType] && claimNode[scope.row.businessType].name || '' }}
            </span>
            <!-- 索赔类型-->
            <span v-else-if="item.prop === 'claimType'">
              {{ CLAIMS_TYPE[scope.row.claimType].name || '' }}
            </span>
            <!-- 状态 -->
            <span v-else-if="item.prop === 'ruleState'">
              <el-tag v-if="scope.row.ruleState === 0" type="primary">{{ $t('GlobalSetObj.enable') }}</el-tag>
              <el-tag v-else type="warning">{{ $t('GlobalSetObj.disable') }}</el-tag>
            </span>
            <span v-else>
              {{ scope.row[item.prop] || '' }}
            </span>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column
          fixed="right"
          :label="$t('GlobalSetObj.operate')"
          width="150"
        >
          <template v-slot="scope">
            <div>
              <!--确定禁用吗？-->
              <el-popconfirm
                v-if="scope.row.ruleState === 0"
                v-permit:remove="'but:compensationManagement:compensationRuleList:add'"
                :title="$t('basicData.AreYouSureDisabled')"
                @confirm="edit(scope.row, 1)"
              >
                <!--禁用-->
                <el-button
                  slot="reference" class="ml" type="warning"
                  style="margin-right: 10px;"
                  size="mini"
                >{{ $t('basicData.disable') }}</el-button>
              </el-popconfirm>
              <!--确定启用吗？-->
              <!--              <el-popconfirm-->
              <!--                v-if="scope.row.state === 1"-->
              <!--                v-permit:remove="'but:finance:basicData:partition:edit'"-->
              <!--                :title="$t('basicData.AreYouSureEnabled')"-->
              <!--                @confirm="edit(scope.row, 0)"-->
              <!--              >-->
              <!--                <el-button-->
              <!--                  slot="reference" class="ml" type="primary"-->
              <!--                  size="mini"-->
              <!--                >{{ $t('basicData.enable') }}</el-button>-->
              <!--              </el-popconfirm>-->
              <!--确定删除吗？-->
              <el-popconfirm
                v-permit:remove="'but:compensationManagement:compensationRuleList:del'"
                :title="$t('basicData.AreYouSureDelete')"
                @confirm="edit(scope.row, 2)"
              >
                <!--删除-->
                <el-button
                  slot="reference" class="ml" type="danger"
                  size="mini"
                >{{ $t('basicData.Delete') }}</el-button>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getCurrentTableData"
          @size-change="getSizeTableData"
        />
      </div>
    </el-card>

    <!-- 新增/详情 -->
    <el-drawer
      :title="headTitle"
      direction="ltr"
      :visible.sync="detailsVisible"
      :destroy-on-close="true"
      size="40%"
      @close="onEditClose"
    >
      <Details
        :id="id" :state-list="stateList" :row="dataRow"
        @close="onEditClose"
      />
    </el-drawer>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import {
  apiClaimRuleConfigPage,
  apiClaimRuleConfigDelete,
  apiClaimRuleConfigDisable,
  apiClaimRuleConfigExport
} from '@/api/compensationManagement/rule.js';
import { claimNode, CLAIMS_TYPE, RULE_TABLE } from '../content.js';
import i18n from '@/lang/index.js';
import { apiAreaPage } from '@/api/finance/basicData';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight';
import { goResourceTaskList } from '@/utils/goExportList';

const PARAM = {
  state: '', // 州
  businessType: '', // 业务类型
  claimType: '', // 索赔类型
  ruleName: '' // 规则名称
};

const title = i18n.t('compensationManagement.addingClaimRules');

export default {
  name: 'CompensationRuleList',

  components: {
    Details: () => import('./cmp/clearDetail.vue')
  },

  mixins: [estimateTableHeight],

  data() {
    return {
      claimNode,
      CLAIMS_TYPE,
      RULE_TABLE,
      fromParam: {
        ...PARAM
      },
      pageNumber: 1,
      pageSize: 10,
      tableData: [], // 列表数据
      total: 0, // 总条数
      id: '', // 规则ID
      dataRow: {}, // 当前行数据
      detailsVisible: false,
      headTitle: title,
      stateList: [] // 州数据
    };
  },

  created() {
    this.asyncApiAreaPage();
    this.getTableData();
  },

  methods: {
    // 搜索
    onQuery() {
      this.searchList();
    },

    // 重置
    onReset() {
      this.fromParam = {
        ...PARAM
      };

      this.searchList();
    },
    // 查询
    searchList() {
      this.pageNumber = 1;
      // this.pageSize = 10;
      this.getTableData();
    },

    /**
     * 切换页码
     * @param val
     */
    getCurrentTableData(val) {
      this.pageNumber = val;
      this.getTableData();
    },

    /**
     * 切换每页条数
     * @param val
     */
    getSizeTableData(val) {
      this.pageSize = val;
      this.pageNumber = 1;
      this.getTableData();
    },

    // 获取列表数据
    getTableData() {
      const obj = cloneDeep(this.fromParam);

      apiClaimRuleConfigPage({
        params: obj,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      }).then(res => {
        if (res.code === 200) {
          const { total, records } = res.data;
          this.total = total;
          this.tableData = records || [];
        }
      }).catch(err => {
        console.error(err);
      });
    },

    // 新增
    add() {
      this.detailsVisible = true;
    },

    // 导出
    onExport() {
      apiClaimRuleConfigExport({
        ...this.fromParam
      }).then(res => {
        if (res.code === 200) {
          goResourceTaskList();
        }
      });
    },

    /**
     * 编辑操作
     * @param row 当前行数据
     * @param type 1 禁用 0 启用 2 删除
     */
    edit(row, type) {
      const { id } = row;
      let apiPromise;
      switch (type) {
        case 1: // 禁用
          apiPromise = apiClaimRuleConfigDisable({ id, ruleState: type });
          break;
        case 2: // 删除
          apiPromise = apiClaimRuleConfigDelete(id);
          break;
        default: // 未知操作类型，不做处理
          return;
      }
      apiPromise.then(res => {
        if (res.code === 200) {
          this.showSuccessMessageAndReload();
        }
      }).catch(err => {
        console.error(err);
      });
    },

    /**
     * 显示成功消息并重新加载表格数据
     */
    showSuccessMessageAndReload() {
      this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
      this.getTableData();
    },

    /**
     * 查看详情
     * @param row 当前行数据
     */
    onOpen(row) {
      const { id, ruleCode } = row;
      this.headTitle = this.$t('compensationManagement.detailsOfClaimRules') + '-' + ruleCode;
      this.dataRow = row;
      this.id = id;
      this.detailsVisible = true;
    },

    // 关闭弹窗
    onEditClose(isBool = false) {
      this.id = '';
      this.headTitle = title;
      this.dataRow = {};
      this.detailsVisible = false;

      console.log(isBool);
      // 刷新列表数据
      if (isBool) {
        this.getTableData();
      }
    },

    // 获取州数据
    asyncApiAreaPage() {
      apiAreaPage({
        pageNumber: 1,
        pageSize: 10000,
        params: {
          level: 1
        }
      }).then(res => {
        const { records } = res.data;
        this.stateList = (records || []).map(item => {
          const { id, name, twoCode } = item;
          const obj = {
            label: twoCode,
            value: id,
            name
          };
          return Object.freeze(obj);
        });
      }).catch(err => {
        console.error(err);
      });
    }
  }
};
</script>

<style scoped lang="scss">

</style>
