<template>
  <div class="app-container">
    <div class="searchContainer">
      <el-button
        v-permit:remove="'btn:finance:accountsPayableReview:export'" size="small" type="info"
        @click="operationBtn"
      >
        {{ $t('receivablePayable.Export') }} <!--导出-->
      </el-button>

      <!--查询-->
      <el-button
        icon="el-icon-search" size="small" type="primary"
        @click="showSearch = true"
      >{{ $t('receivablePayable.Search') }}
      </el-button>
    </div>
    <el-divider />

    <!--费用明细-->
    <TableCmp
      ref="tableCmpRef"
      :query-form="queryForm"
      :table-header="TABLE_HEADER"
      @selection-change="handleSelectionChange"
    />

    <!--  查询  -->
    <el-drawer
      :append-to-body="true"
      :title="$t('receivablePayable.Search')"
      :visible.sync="showSearch"
      custom-class="drawerClass"
      direction="rtl"
      size="50%"
    >
      <search-form
        :is-read-only="true"
        :bill-batch-number="batchNumber"
        :type="type"
        @search="searchFun"
        @close="showSearch = false"
      />
    </el-drawer>
  </div>
</template>

<script>
import { SEARCH_PARAMS, TABLE_HEADER } from './enums.js';
import TableCmp from './TableCmp';
import SearchForm from './searchForm.vue';
import { getExportCostSiteExpense } from '@/api/finance/costManagement';
import { goResourceTaskList } from '@/utils/goExportList';
export default {
  name: '',

  components: {
    TableCmp,
    SearchForm
  },

  props: {
    batchNumber: {
      type: String,
      default: ''
    },
    billBatchData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // 1 审核 2 核付
    type: {
      type: [String, Number],
      default: ''
    }
  },

  data() {
    return {
      TABLE_HEADER,
      selectionList: [],
      showSearch: false,
      queryForm: {
        pageNumber: 1,
        pageSize: 100,
        params: {
          ...SEARCH_PARAMS
        }
      }
    };
  },

  computed: {
    // 获取多选选中的值(只含id)
    selectIdArr() {
      const arr = this.selectionList;
      if (arr.length === 0) {
        return [];
      }

      return arr.map(item => {
        return item.id;
      });
    }
  },

  created() {
    if (this.type === 1) {
      this.queryForm.params.billBatchNumber = this.batchNumber;
      this.queryForm.params.payableNumber = '';
    } else {
      this.queryForm.params.payableNumber = this.batchNumber;
      this.queryForm.params.billBatchNumber = '';
    }

    this.$nextTick(() => {
      this.$refs.tableCmpRef.onSearch();
    });
  },

  mounted() {},

  methods: {
    // 导出
    operationBtn() {
      const prop = [];
      const label = [];
      TABLE_HEADER.forEach(item => {
        prop.push(item.prop);
        label.push(item.label);
      });
      const param = {
        ...this.queryForm.params,
        fieldList: prop,
        headList: label,
        ids: this.selectIdArr
      };
      getExportCostSiteExpense(param).then(res => {
        if (res.code === 200) {
          return goResourceTaskList();
        }
      }).then(res => {
        console.log(res, '是否跳转');
        if (res) {
          this.$emit('closeDetail');
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 查询
    searchFun(obj) {
      Object.assign(this.queryForm.params, { ...obj });
      this.showSearch = false;
      this.$refs.tableCmpRef.onSearch();
    },
    // 勾选
    handleSelectionChange(arr) {
      this.selectionList = arr;
    }
  }
};
</script>

<style scoped>
.searchContainer {
  display: flex;
  justify-content: space-between;
}
</style>
