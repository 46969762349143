var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box-card" },
    [
      _c(
        "el-form",
        {
          ref: "addBatchFee",
          attrs: { model: _vm.addBatchFee, "label-width": "0", size: "small" },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.addBatchFee.addBatchFeeList, border: "" } },
            [
              _c(
                "el-table-column",
                {
                  attrs: { width: "55", align: "center", fixed: "left" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          !scope.row.isAccept
                            ? _c(
                                "el-popconfirm",
                                {
                                  directives: [
                                    {
                                      name: "permit",
                                      rawName: "v-permit:remove",
                                      value:
                                        "btn:finance:costManagement:costsFee:Del",
                                      expression:
                                        "'btn:finance:costManagement:costsFee:Del'",
                                      arg: "remove",
                                    },
                                  ],
                                  attrs: {
                                    title: _vm.$t("basicData.AreYouSureDelete"),
                                  },
                                  on: {
                                    confirm: function ($event) {
                                      return _vm.deleteCost(scope)
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass: "el-icon-delete deleteStyle",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("span", {
                      staticClass: "el-icon-plus addStyle",
                      on: {
                        click: function ($event) {
                          return _vm.addCost()
                        },
                      },
                    }),
                  ]),
                ],
                2
              ),
              _vm.addType === 1
                ? _c(
                    "el-table-column",
                    {
                      attrs: { prop: "businessType", width: "180" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "formStyle" },
                                  [
                                    scope.row.isEdit === false
                                      ? _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.PAYABLE_TYPE[
                                                scope.row.businessType
                                              ].label
                                            )
                                          ),
                                        ])
                                      : _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "addBatchFeeList." +
                                                scope.$index +
                                                ".businessType",
                                              rules: {
                                                required: true,
                                                message: _vm.$t(
                                                  "basicData.CannotBeEmpty"
                                                ),
                                                trigger: ["blur", "change"],
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  filterable: "",
                                                  clearable: "",
                                                  placeholder: _vm.$t(
                                                    "operationCenter.PleaseSelect"
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.changeBusinessType(
                                                      scope
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.businessType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "businessType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.businessType",
                                                },
                                              },
                                              _vm._l(
                                                _vm.PAYABLE_TYPE,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.id,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2817136534
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#f00",
                                "margin-right": "5px",
                              },
                            },
                            [_vm._v("*")]
                          ),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("collectionCenter.businessT"))
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.addType === 1
                ? _c(
                    "el-table-column",
                    {
                      attrs: { prop: "site", width: "180" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "formStyle" },
                                  [
                                    scope.row.isEdit === false
                                      ? _c("div", [
                                          _vm._v(_vm._s(scope.row.siteName)),
                                        ])
                                      : _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "addBatchFeeList." +
                                                scope.$index +
                                                ".siteId",
                                              rules: {
                                                required: true,
                                                message: _vm.$t(
                                                  "basicData.CannotBeEmpty"
                                                ),
                                                trigger: ["blur", "change"],
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  filterable: "",
                                                  clearable: "",
                                                  remote: "",
                                                  placeholder: _vm.$t(
                                                    "operationCenter.PleaseSelect"
                                                  ),
                                                },
                                                model: {
                                                  value: scope.row.siteId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "siteId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.siteId",
                                                },
                                              },
                                              _vm._l(
                                                _vm.siteOption,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.id,
                                                    attrs: {
                                                      label: item.name,
                                                      value: item.id,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4272616014
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#f00",
                                "margin-right": "5px",
                              },
                            },
                            [_vm._v("*")]
                          ),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("receivablePayable.costOutlets"))
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "el-table-column",
                {
                  attrs: { prop: "feeName", width: "180" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "formStyle" },
                            [
                              scope.row.isEdit === false
                                ? _c("div", [_vm._v(_vm._s(scope.row.feeName))])
                                : _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "addBatchFeeList." +
                                          scope.$index +
                                          ".feeCode",
                                        rules: {
                                          required: true,
                                          message: _vm.$t(
                                            "basicData.CannotBeEmpty"
                                          ),
                                          trigger: ["blur", "change"],
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            filterable: "",
                                            clearable: "",
                                            placeholder: _vm.$t(
                                              "operationCenter.PleaseSelect"
                                            ),
                                          },
                                          on: {
                                            focus: function ($event) {
                                              return _vm.feeTypeFocus(scope)
                                            },
                                            change: function ($event) {
                                              return _vm.addChangeFee(scope)
                                            },
                                          },
                                          model: {
                                            value: scope.row.feeCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "feeCode",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.feeCode",
                                          },
                                        },
                                        _vm._l(
                                          _vm.feeOptionList,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                disabled: item.disabled,
                                                label: item.displayName,
                                                value: item.code,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticStyle: { color: "#f00", "margin-right": "5px" },
                        },
                        [_vm._v("*")]
                      ),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("basicData.ExpenseType"))),
                      ]),
                    ]),
                  ]),
                ],
                2
              ),
              _vm.addType === 2
                ? [
                    _c("el-table-column", {
                      attrs: {
                        prop: "isGenBill",
                        label: _vm.$t(
                          "receivablePayable.WhetherToGenerateAStatement"
                        ),
                        width: "180",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.isGenBill
                                        ? _vm.$t("GlobalSetObj.yes")
                                        : _vm.$t("GlobalSetObj.No")
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1466494823
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "isAccept",
                        label: _vm.$t("receivablePayable.approvedOrNot"),
                        width: "100",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.isAccept
                                        ? _vm.$t("GlobalSetObj.yes")
                                        : _vm.$t("GlobalSetObj.No")
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3148801440
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "chargeWeight",
                        label: _vm.$t("receivablePayable.BillableWeight"),
                        width: "100",
                      },
                    }),
                  ]
                : _vm._e(),
              _c(
                "el-table-column",
                {
                  attrs: { prop: "fee", width: "140" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "formStyle" },
                            [
                              scope.row.isEdit === false
                                ? _c("div", [_vm._v(_vm._s(scope.row.fee))])
                                : _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "addBatchFeeList." +
                                          scope.$index +
                                          ".fee",
                                        rules: {
                                          required: true,
                                          suffix: 2,
                                          negative: "-?",
                                          validator: _vm.priceType,
                                          trigger: ["blur", "change"],
                                        },
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: scope.row.fee,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "fee", $$v)
                                          },
                                          expression: "scope.row.fee",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticStyle: { color: "#f00", "margin-right": "5px" },
                        },
                        [_vm._v("*")]
                      ),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("receivablePayable.AmountDue"))),
                      ]),
                    ]),
                  ]),
                ],
                2
              ),
              _c(
                "el-table-column",
                {
                  attrs: { prop: "operateTime", width: "220" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "formStyle" },
                            [
                              scope.row.isEdit === false
                                ? _c("div", [
                                    _vm._v(_vm._s(scope.row.operateTime)),
                                  ])
                                : _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "addBatchFeeList." +
                                          scope.$index +
                                          ".operateTime",
                                        rules: {
                                          required: true,
                                          message: _vm.$t(
                                            "basicData.CannotBeEmpty"
                                          ),
                                          trigger: ["blur", "change"],
                                        },
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "datetime",
                                          "value-format": "yyyy-MM-dd HH:mm:ss",
                                          placeholder: _vm.$t(
                                            "basicData.SelectADatetime"
                                          ),
                                        },
                                        model: {
                                          value: scope.row.operateTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "operateTime",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.operateTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticStyle: { color: "#f00", "margin-right": "5px" },
                        },
                        [_vm._v("*")]
                      ),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("newOrder.BuildTime"))),
                      ]),
                    ]),
                  ]),
                ],
                2
              ),
              _vm.addType === 1
                ? _c(
                    "el-table-column",
                    {
                      attrs: { prop: "flatMethod", width: "180" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.isEdit === false
                                  ? _c("div", [
                                      _vm._v(_vm._s(scope.row.flatMethod)),
                                    ])
                                  : _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "addBatchFeeList." +
                                            scope.$index +
                                            ".flatMethod",
                                          rules: {
                                            required: true,
                                            message: _vm.$t(
                                              "basicData.CannotBeEmpty"
                                            ),
                                            trigger: ["blur", "change"],
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              filterable: "",
                                              clearable: "",
                                              placeholder: _vm.$t(
                                                "operationCenter.PleaseSelect"
                                              ),
                                            },
                                            model: {
                                              value: scope.row.flatMethod,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "flatMethod",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.flatMethod",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              key: "0",
                                              attrs: {
                                                label: _vm.$t(
                                                  "receivablePayable.SpreadEquallyByWeight"
                                                ),
                                                value: "1",
                                              },
                                            }),
                                            _c("el-option", {
                                              key: "1",
                                              attrs: {
                                                label: _vm.$t(
                                                  "receivablePayable.SplitEquallyByTheNumberOfVotes"
                                                ),
                                                value: "2",
                                              },
                                            }),
                                            _c("el-option", {
                                              key: "2",
                                              attrs: {
                                                label: _vm.$t(
                                                  "receivablePayable.SpecifyTheAmount"
                                                ),
                                                value: "3",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3310892134
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#f00",
                                "margin-right": "5px",
                              },
                            },
                            [_vm._v("*")]
                          ),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("receivablePayable.FlatSharingMethod")
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.addType === 2
                ? [
                    _c("el-table-column", {
                      attrs: {
                        prop: "create_user",
                        label: _vm.$t("receivablePayable.EnterThePerson"),
                        width: "100",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "formStyle" }, [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.createUser[scope.row.createUser]
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        868531676
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "operator",
                        label: _vm.$t("basicData.Operator"),
                        width: "180",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "formStyle" }, [
                                  _c("div", [
                                    _vm._v(_vm._s(scope.row.operatorName)),
                                  ]),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2887677181
                      ),
                    }),
                  ]
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  prop: "remark",
                  label: _vm.$t("receivablePayable.remark"),
                  "min-width": "160",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isEdit === false
                          ? _c("div", [_vm._v(_vm._s(scope.row.remark))])
                          : _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "addBatchFeeList." +
                                    scope.$index +
                                    ".remark",
                                  rules: {
                                    required: false,
                                    message: _vm.$t("basicData.CannotBeEmpty"),
                                    trigger: ["blur", "change"],
                                  },
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseInputContent"
                                    ),
                                    type: "textarea",
                                    maxlength: "200",
                                    autosize: { minRows: 1, maxRows: 3 },
                                  },
                                  model: {
                                    value: scope.row.remark,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "remark", $$v)
                                    },
                                    expression: "scope.row.remark",
                                  },
                                }),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }