const GlobalSetObj = {
  TheOrderBeingCancelled: {
    zh: '订单取消中',
    en: 'The order is being cancelled',
    pu: 'Cancelando'
  },
  AreYouSureYouWantToCancelTheseWaybills: {
    zh: '确定取消这些运单吗？',
    en: 'Are you sure you want to cancel these waybills?',
    pu: 'Confirma a cancelar este pedidos?'
  },
  NoNewsYet: {
    zh: '暂无消息',
    en: 'No news yet',
    pu: 'Ainda não há novidades'
  },
  OneClickHasBeenRead: {
    zh: '一键已读',
    en: 'One click has been read',
    pu: 'Um clique foi lido'
  },
  fileUploadedSuccessfully: {
    zh: '文件上传成功，导入失败的订单数据可在【系统管理->资源导出列表】中下载',
    en: 'The file is uploaded successfully, and the order data that fails to be imported can be downloaded in [System Management -> Resource Export List].',
    pu: 'O arquivo é carregado com êxito e os dados do pedido que não podem ser importados podem ser baixados em [System Management -> Resource Export List].'
  },
  LogInAgain: {
    zh: '您已登出，可以取消停留在此页面上，或者再次登录',
    en: 'You are logged out. You can cancel staying on this page or log in again',
    pu: 'Você se rexistra. Você pode cancelar ficar nessa página ou entrar novamente'
  },
  dashboard: {
    zh: '首页',
    en: 'dashboard',
    pu: 'Casa'
  },
  add: {
    zh: '新增',
    en: 'add',
    pu: 'Novo'
  },
  EditTime: {
    zh: '编辑时间',
    en: 'Edit time',
    pu: 'Hora de edição'
  },
  edit: {
    zh: '编辑',
    en: 'Edit',
    pu: 'Editar'
  },
  Download: {
    zh: '下载',
    en: 'Download',
    pu: 'Baixar'
  },
  View: {
    zh: '查看',
    en: 'View',
    pu: 'Ver'
  },
  ReplicationSucceeded: {
    zh: '复制成功',
    en: 'Replication succeeded',
    pu: 'Replicação bem sucedida'
  },
  pleaseInput: {
    zh: '请输入',
    en: 'Please enter the content',
    pu: 'Por favor digitir'
  },
  pleaseChoose: {
    zh: '请选择',
    en: 'Please select',
    pu: 'Favor selecionar'
  },
  CollapseQuery: {
    zh: '收起查询',
    en: 'Collapse the Query button',
    pu: 'Recolher o botão Consulta'
  },
  ExpandQuery: {
    zh: '展开查询',
    en: 'Expand the Query button',
    pu: 'Expanda o botão Consulta'
  },
  search: {
    zh: '查询',
    en: '',
    pu: 'Consultar'
  },
  reset: {
    zh: '重 置',
    en: 'reset',
    pu: 'repor'
  },
  CreatePeople: {
    zh: '创建人',
    en: 'Create People',
    pu: 'Criar Pessoas'
  },
  CreationTime: {
    zh: '创建时间',
    en: 'Creation time',
    pu: 'Tempo de criação'
  },
  ModificationSuccessful: {
    zh: '修改成功',
    en: 'Modification successful',
    pu: 'Modificação bem sucedida'
  },
  DeleteSuccessful: {
    zh: '删除成功',
    en: 'Delete successful',
    pu: 'Excluir bem-sucedido'
  },
  SaveSuccessfully: {
    zh: '保存成功',
    en: 'Save successfully',
    pu: 'Salvar com sucesso'
  },
  TheOperationSucceeded: {
    zh: '操作成功',
    en: 'The operation succeeded',
    pu: 'A operação foi bem-sucedida'
  },
  required: {
    zh: '必填项不能为空',
    en: '',
    pu: 'Os campos obrigatórios não podem ser deixados em branco'
  },
  to50characters: {
    zh: '请输入2~50位的字符串',
    en: '',
    pu: 'Digite de 2 a 50 caracteres'
  },
  status: {
    zh: '状态',
    en: '',
    pu: 'Status'
  },
  SecretKey: {
    zh: '密钥',
    en: 'status',
    pu: 'Chave'
  },
  NIV: {
    zh: '车签号',
    en: '',
    pu: 'NIV'
  },
  remark: {
    zh: '备注',
    en: 'remark',
    pu: 'Comentário'
  },
  enable: {
    zh: '启用',
    en: 'enable',
    pu: 'Habilitar'
  },
  disable: {
    zh: '禁用',
    en: 'disable',
    pu: 'desabilitar'
  },
  supplier: {
    zh: '供应商',
    en: '',
    pu: 'Fornecedor'
  },
  supplierAbbreviation: {
    zh: '供应商简称',
    en: '',
    pu: 'Nome Fantasia'
  },
  businessEntity: {
    zh: '业务主体',
    en: '',
    pu: 'Nome razão social'
  },
  supplierCode: {
    zh: '供应商编码',
    en: '',
    pu: 'Código de Fornecedor'
  },
  serviceTypes: {
    zh: '服务类型',
    en: '',
    pu: 'Tipo de Serviço'
  },
  ContactName: {
    zh: '联系人',
    en: '',
    pu: 'Nome do contacto'
  },
  contactNumber: {
    zh: '联系电话',
    en: '',
    pu: 'Tel. de contato'
  },
  Updater: {
    zh: '更新人',
    en: '',
    pu: 'Actualizador'
  },
  UpdateTime: {
    zh: '更新时间',
    en: '',
    pu: 'Data actualizada'
  },
  newSupplier: {
    zh: '新建供应商',
    en: '',
    pu: 'Fornecedor Novo'
  },
  editSupplier: {
    zh: '编辑供应商',
    en: 'edit supplier',
    pu: 'editar fornecedor'
  },
  ServiceTypeNotSurpass20: {
    zh: '服务类型不能超过20条',
    en: 'Service type should not surpass 20',
    pu: 'O tipo de serviço não deve ultrapassar 20'
  },
  bagIsSealed: {
    zh: '请封袋后再操作',
    en: 'Please operate after the bag is sealed',
    pu: 'Opere depois que a bolsa estiver selada'
  },
  postal: {
    zh: '邮政',
    en: 'postal',
    pu: 'Postal'
  },
  Canal: {
    zh: '默认渠道',
    en: '',
    pu: 'Canal padrão'
  },
  localTrain: {
    zh: '普快',
    en: '',
    pu: 'Expresso Comum'
  },
  create: {
    zh: '新建',
    en: 'create',
    pu: 'Criar'
  },
  express: {
    zh: '特快',
    en: 'express',
    pu: 'expresso'
  },
  Delete: {
    zh: '删除',
    en: 'Delete',
    pu: 'Excluir'
  },
  Cancelar: {
    zh: '取 消',
    en: '',
    pu: 'Cancelar'
  },
  Salve: {
    zh: '保 存',
    en: '',
    pu: 'Salvar'
  },
  serialNumber: {
    zh: '编号',
    en: '',
    pu: 'Número de código'
  },
  RuleName: {
    zh: '规则名称',
    en: '',
    pu: 'Nome de regra'
  },
  RuleType: {
    zh: '规则类型',
    en: '',
    pu: 'Tipo de regra'
  },
  gridNum: {
    zh: '格口',
    en: '',
    pu: 'Grade'
  },
  editor: {
    zh: '最新修改人',
    en: '',
    pu: 'Modificador mais recente'
  },
  newRule: {
    zh: '新建规则',
    en: '',
    pu: 'Regra nova'
  },
  ordinary: {
    zh: '普通',
    en: '',
    pu: 'normal'
  },
  Especial: {
    zh: '特殊',
    en: '',
    pu: 'Especial'
  },
  logisticsChannel: {
    zh: '物流渠道',
    en: '',
    pu: 'Canal Logístico'
  },
  PleaseSelecioneCanal: {
    zh: '请选择渠道',
    en: '',
    pu: 'Selecione o canal'
  },
  unselected: {
    zh: '未选',
    en: '',
    pu: 'Não selecionado'
  },
  selected: {
    zh: '已选',
    en: '',
    pu: 'Selecionado'
  },
  pleaseInputSuppler: {
    zh: '请输入供应商简称',
    en: '',
    pu: 'Insira a abreviação de Fornecedor'
  },
  pleaseInputContent: {
    zh: '请输入内容',
    en: '',
    pu: 'Insira o conteúdo'
  },
  client: {
    zh: '客户',
    en: 'client',
    pu: 'Cliente'
  },
  selectCanal: {
    zh: '请选择渠道',
    en: '',
    pu: 'Selecione o canal'
  },
  confirm: {
    zh: '确 定',
    en: 'determine',
    pu: 'Determinar'
  },
  otherIf: {
    zh: '其他分区条件',
    en: '',
    pu: 'Outras condiões de partição'
  },
  zipCode: {
    zh: '邮编',
    en: '',
    pu: 'Código postal'
  },
  DestinationPostcode: {
    zh: '目的地邮编',
    en: 'Destination postcode',
    pu: 'Código postal de destino'
  },
  weight: {
    zh: '重量(KG)',
    en: '',
    pu: 'Peso(KG)'
  },
  scannerTip: {
    zh: '扫描成功后，该袋号转为中转仓收货状态;',
    en: '',
    pu: 'Após varredura bem-sucedida, o número do saco foi transferido para status do coletado em armazém de trânsito.'
  },
  beforeScannerTip: {
    zh: '扫描前请先切换成 [EN] 输入法',
    en: '',
    pu: 'Antes de escaneamento, troque em entrada [EN].'
  },
  ScanningEntry: {
    zh: '扫描收货',
    en: '',
    pu: 'Escaneado e coletado'
  },
  bagNumber: {
    zh: '袋号 ',
    en: '',
    pu: 'Número do saca'
  },
  scannerOrinput: {
    zh: '扫描或者输入袋号',
    en: '',
    pu: 'Escanear ou Inserir o número do saca'
  },
  EnterparcelNumber: {
    zh: '扫描或者输入包裹号',
    en: '',
    pu: 'Escaneie ou digite o número da parcela'
  },
  ConfirmationAndStorage: {
    zh: '确认&入库',
    en: '',
    pu: 'Confirmar& Armazenar'
  },
  CurrentBagNumber: {
    zh: '当前袋号',
    en: '',
    pu: 'Número de saca corrente'
  },
  CancelStorage: {
    zh: '取消入库',
    en: '',
    pu: 'Cancelar armazenar'
  },
  package: {
    zh: '包裹数',
    en: '',
    pu: 'Quantidade dos pacotes'
  },
  ScannerRecords: {
    zh: '扫描入库记录',
    en: '',
    pu: 'Escanear o registo'
  },
  InStorage: {
    zh: '已入库:',
    en: '',
    pu: 'Foi armazenado:'
  },
  operator: {
    zh: '操作人',
    en: 'operator',
    pu: 'Operador'
  },
  PackageScanning: {
    zh: '包裹扫描成功，将自动关联系统生成的袋号；',
    en: '',
    pu: 'O pacote está escaneado bem-sucedido e irá gerado automaticamente o número de saca por sistema'
  },
  ScanBagging: {
    zh: '扫描装袋',
    en: '',
    pu: 'Escaneamento e ensacamento'
  },
  waybillNumber: {
    zh: '运单号',
    en: 'waybillNumber',
    pu: 'Número de waybill'
  },
  ScanOrwaybillNumber: {
    zh: '扫描或者输入运单号',
    en: '',
    pu: 'Escanear ou inserir o número de rastreio'
  },
  ConfirmAndBag: {
    zh: '确认&装袋',
    en: '',
    pu: 'Confirmar&ensacar'
  },
  envelope: {
    zh: '封袋',
    en: '',
    pu: 'Selar a saca'
  },
  CurrentBagging: {
    zh: '当前装袋',
    en: '',
    pu: 'Ensacamento atual'
  },
  RemoveOutBag: {
    zh: '移除出袋',
    en: '',
    pu: 'Remover a saca'
  },
  serial: {
    zh: '序号',
    en: '',
    pu: 'No.'
  },
  operate: {
    zh: '操作',
    en: 'operate',
    pu: 'Operar'
  },
  BaggingRecord: {
    zh: '装袋记录',
    en: '',
    pu: 'Registo de ensacamento'
  },
  operationTime: {
    zh: '操作时间',
    en: 'Operation time',
    pu: 'Hora da operação'
  },
  PrintBagNumber: {
    zh: '打印袋号',
    en: '',
    pu: 'Imprimir número da saca'
  },
  ShippingList: {
    zh: '打印Shipping List',
    en: 'Print the Shipping List',
    pu: 'Imprimir lista de remessas'
  },
  PackageScanningRemove: {
    zh: '包裹扫描成功，将自动和原袋号解除关联，支持重新扫描装袋；',
    en: '',
    pu: 'O pacote está escaneado bem-sucedido, irá automaticamente desassociado do número de saca original e será suportado o escaneamento novo para o ensacamento'
  },
  ScanToRemove: {
    zh: '扫描移除出袋',
    en: '',
    pu: 'Escanear para remover a saca'
  },
  CurrentlyRemoving: {
    zh: '当前移除包裹',
    en: '',
    pu: 'Remover o pacote atual'
  },
  restTheBag: {
    zh: '袋内剩余包裹',
    en: '',
    pu: 'Os pacotes restante na saca'
  },
  channel: {
    zh: '渠道商',
    en: '',
    pu: 'Fornecedor do canal'
  },
  all: {
    zh: '全部',
    en: 'all',
    pu: 'Tudo'
  },
  LargePacketNumber: {
    zh: '大包号',
    en: '',
    pu: 'Número de saca'
  },
  licensePlateNumber: {
    zh: '车牌号',
    en: '',
    pu: 'Número da placa'
  },
  GeneratelargePackages: {
    zh: '生成大包',
    en: '',
    pu: 'Gerar a saca'
  },
  DistributionVehicle: {
    zh: '分配车辆',
    en: '',
    pu: 'Distribuição de veículos'
  },
  BagSealer: {
    zh: '封袋人',
    en: '',
    pu: 'Selador'
  },
  BagSealingTime: {
    zh: '封袋时间',
    en: '',
    pu: 'Data de selagem'
  },
  DeclarationStatus: {
    zh: '申报状态',
    en: '',
    pu: 'Estado de declaração'
  },
  undeclared: {
    zh: '未申报',
    en: '',
    pu: 'Não declarado'
  },
  declared: {
    zh: '已申报',
    en: '',
    pu: 'Já declarado'
  },
  FailureTodeclare: {
    zh: '申报失败',
    en: '',
    pu: 'Falha na declaração'
  },
  SupplierBagNumber: {
    zh: '供应商袋号',
    en: '',
    pu: 'Número de saca de fornecedor'
  },
  applicant: {
    zh: '申报人',
    en: '',
    pu: 'Declarante'
  },
  DeclarationTime: {
    zh: '申报时间',
    en: '',
    pu: 'Data de declaração'
  },
  ServiceDeclaration: {
    zh: '服务商申报',
    en: '',
    pu: 'Declaração do fornecedor'
  },
  AutomaticWarehousing: {
    zh: '扫描成功后，该袋号内包裹将自动出库;',
    en: '',
    pu: 'Depois da escaneamento bem-sucedida, os pacotes na saca serão liberadas do armazém automaticamente.'
  },
  ScanOut: {
    zh: '扫描出库',
    en: '',
    pu: 'Saída de escaneamento'
  },
  ConfirmAndRelease: {
    zh: '确定&出库',
    en: '',
    pu: 'Determinar&armazenar'
  },
  determine: {
    zh: '确定',
    en: 'determine',
    pu: 'Determinar'
  },
  cancel: {
    zh: '取消',
    en: '',
    pu: 'Cancelar'
  },
  closeSuccessfully: {
    zh: '关闭成功',
    en: '',
    pu: 'Fechado com sucesso'
  },
  CancellationDelivery: {
    zh: '取消出库',
    en: '',
    pu: 'Cancelar armazenar'
  },
  ScanOutRecord: {
    zh: '扫描出库记录',
    en: '',
    pu: 'Registros de saída de escaneamento'
  },
  OutStorage: {
    zh: '已出库',
    en: '',
    pu: 'Já saiu do armazém'
  },
  AreYouSureDelete: {
    zh: '确定删除吗？',
    en: 'Are you sure to delete it',
    pu: 'Você tem certeza de excluí-lo?'
  },
  AreYouSureResetPassword: {
    zh: '确定要重置密码吗？',
    en: 'Are you sure you want to reset your password?',
    pu: 'Tem certeza de que deseja redefinir sua senha?'
  },
  ResetPasswordSuccessfully: {
    zh: '重置密码成功！',
    en: 'Reset password successfully!',
    pu: 'Redefina a senha com sucesso!'
  },
  HideTheColumn: {
    zh: '取消勾选即可在列表页隐藏该列。',
    en: 'Uncheck to hide the column in the list page',
    pu: 'Desmarcar para ocultar a coluna na página da lista'
  },
  TrackSearch: {
    zh: '轨迹查询',
    en: 'Track Search',
    pu: 'Pesquisa de rastreio'
  },
  PendingNumber: {
    zh: '待取号',
    en: 'Number to be requested',
    pu: 'Número a ser solicitado'
  },
  problemReason: {
    zh: '问题件原因',
    en: '',
    pu: 'Causa do pedido problemático'
  },
  problemRemark: {
    zh: '问题备注',
    en: 'Problem Notes',
    pu: 'Notas de problema'
  },
  CheckForm: {
    zh: '请勾选表格中的数据',
    en: '',
    pu: 'Assinale os dados na tabela'
  },
  serviceLogistics: {
    zh: '服务商单号',
    en: 'Logistic sheets of the service providers',
    pu: 'N° de rastreio de fornecedor'
  },
  RegistrationNumber: {
    zh: '籍号',
    en: 'Box number',
    pu: 'Número da caixa'
  },
  Link: {
    zh: '链接',
    en: 'Link',
    pu: 'Link'
  },
  TakeNumber: {
    zh: '取号',
    en: 'Requests a number',
    pu: 'Solicita um número'
  },
  SubordinateSupplier: {
    zh: '所属供应商',
    en: '',
    pu: 'Canal de serviço'
  },
  alternate: {
    zh: '换单人',
    en: 'People who change orders',
    pu: 'Pessoas que mudam de pedidos'
  },
  exchangeTime: {
    zh: '换单时间',
    en: 'Time to change order',
    pu: 'Tempo de muda de pedido'
  },
  OrderPrinted: {
    zh: '待打单',
    en: 'Orders to be printed',
    pu: 'Pedidos a imprimir'
  },
  SuccessfulExchange: {
    zh: '换单成功',
    en: '',
    pu: 'Muda de pedidos sucedida'
  },
  ReplaceOrder: {
    zh: '换单订单',
    en: 'Replacement order',
    pu: 'Ordem de troca'
  },
  DefiniteNumber: {
    zh: '确定对已选中的待取号订单，进行取号？',
    en: '',
    pu: 'Confirmar a solicitar o número para o pedido selecionado'
  },
  NumberTaken: {
    zh: '已取号订单将自动过滤。',
    en: '',
    pu: 'Os números que foram solicitados serão automaticamente filtradas'
  },
  PrintedSheet: {
    zh: '打印面单',
    en: '',
    pu: 'Imprimir etiquetas'
  },
  ScanNumber: {
    zh: '扫描单号',
    en: '',
    pu: 'Código de barra'
  },
  PleaseInputScanNumber: {
    zh: '请输入或者扫描单号',
    en: '',
    pu: 'Escanear o número de pedido'
  },
  AutomaticPrintingTip: {
    zh: '请扫描包裹原物流面单，系统将自动打印需替换的新物流单',
    en: '',
    pu: 'Escanear a etiqueta original de pedido e o sistema imprimirá automaticamente a nova etiqueta substituído.'
  },
  beenPrinted: {
    zh: '已打印面单',
    en: '',
    pu: 'Concluído o impressão das etiquetas'
  },
  PrintFailure: {
    zh: '打印失败',
    en: '',
    pu: 'Falha de impressão'
  },
  setDefaultPrinter: {
    zh: '请打开打印插件,并且设置默认打印机',
    en: '',
    pu: 'Favor abrir o plugin de impressão e configurar a impressora padrão'
  },
  yes: {
    zh: '是',
    en: 'yes',
    pu: 'Sim'
  },
  No: {
    zh: '否',
    en: 'No',
    pu: 'Não'
  },
  ThereIsNo: {
    zh: '无',
    en: 'There is no',
    pu: 'Nada'
  },
  SubjectPayment: {
    zh: '付款主体',
    en: '',
    pu: 'Pagador'
  },
  close: {
    zh: '关 闭',
    en: '',
    pu: 'Fechar'
  },
  DataExport: {
    zh: '数据导出',
    en: 'Data export',
    pu: 'Exportação de dados'
  },
  IfThereAre: {
    zh: '当前筛选条件下有',
    en: 'If there are',
    pu: 'Se houver'
  },
  ThereAre: {
    zh: '当前筛选条件下有',
    en: 'There are',
    pu: 'Existem'
  },
  dataRecordsCurrentFilterCondition: {
    zh: '条数据',
    en: 'data records in the current filter condition',
    pu: 'registros de faturas emitidas com os critérios de filtragem atuais.'
  },
  dataItemsInTheCurrentFilter: {
    zh: '条数据，超出单次导出数据上限500000条，请重新设置筛选条件。',
    en: 'data items in the current filter conditions, but the maximum number of data exported at a time is 500,000, set the filter conditions again.',
    pu: 'itens de dados nas condições de filtro atuais, mas o número máximo de dados exportados por vez for 500.000, defina as condições de filtro novamente.'
  },
  MinWeight: {
    zh: '最小重量',
    en: '',
    pu: 'Peso mínimo'
  },
  MaxWeight: {
    zh: '最大重量',
    en: '',
    pu: 'Peso máximo'
  },
  RuleDetails: {
    zh: '规则详情',
    en: '',
    pu: 'Detalhes das regras'
  },
  inputRuleName: {
    zh: '请输入规则名',
    en: '',
    pu: 'Insira o nome da regra'
  },
  selectGrid: {
    zh: '请选择格口',
    en: '',
    pu: 'Selecione a rede'
  },
  inputMinWeight: {
    zh: '请输入最小重量',
    en: '',
    pu: 'Favor inserir um peso mínimo'
  },
  inputMaxWeight: {
    zh: '请输入最大重量',
    en: '',
    pu: 'Favor inserir o peso máximo'
  },
  NoOrderFound: {
    zh: '未找到订单',
    en: '',
    pu: 'Pedido não encontrado'
  },
  exceedWeight: {
    zh: '超出渠道最高下单重量',
    en: '',
    pu: 'Exceder o peso máximo do canal'
  },
  ExceedOrderVariance: {
    zh: '超出客户下单差异',
    en: '',
    pu: 'Exceder os desvios de pedidos dos clientes'
  },
  latticeDuplication: {
    zh: '格口重复,请重新选择',
    en: '',
    pu: 'Rede duplicada, favor selecionar novamente'
  },
  Englishcomma: {
    zh: '请输入8位数字邮编,多个以英文逗号分隔',
    en: '',
    pu: 'Insira o código postal com 8 dígitos, e separe os códigos por vírgulas'
  },
  PostcodeDuplication: {
    zh: '邮编重复,请重新输入',
    en: '',
    pu: 'Código Postal duplicado, favor inserir novamente'
  },
  EditingRules: {
    zh: '编辑规则',
    en: '',
    pu: 'Editar a regra'
  },
  CorrectMaxWeight: {
    zh: '请输入正确的最大重量',
    en: '',
    pu: 'Favor inserir o peso máximo correto'
  },
  weightRangesIntersect: {
    zh: '重量范围存在交集',
    en: '',
    pu: 'Existe interseção de faixas de peso'
  },
  selectLargeBag: {
    zh: '请选择大包',
    en: '',
    pu: 'Favor selecionar a saca'
  },
  BagDuplication: {
    zh: '袋号重复',
    en: '',
    pu: 'Duplicação do número da saca'
  },
  StayCurrentPage: {
    zh: '存在已装袋未封袋数据，是否确认退出装袋？点击确定已装袋未封袋数据将自动封袋后退出装袋，点击取消留在当前页面不退出',
    en: '',
    pu: 'Há dados ensacados e não selados, a saída está confirmada? Clique em Confirmar para confirmar que os dados não selados serão automaticamente selados e saído da selagem, clique em Cancelar para permanecer na página atual sem sair'
  },
  tips: {
    zh: '提示',
    en: 'tips',
    pu: 'Dicas'
  },
  sealTheBag: {
    zh: '是否确定进行封袋操作?',
    en: '',
    pu: 'Tem certeza de selar a saca? '
  },
  inputWaybillNumber: {
    zh: '请输入运单号',
    en: '',
    pu: 'Favor inserir o código de remessa'
  },
  waybillNumberBagged: {
    zh: '运单号已装袋',
    en: '',
    pu: 'o código de remessa ensacada'
  },
  channelsInconsistent: {
    zh: '服务商渠道不一致',
    en: '',
    pu: 'Fornecedores de canal diferentes'
  },
  mergelargepackages: {
    zh: '确定生成总单吗？',
    en: '',
    pu: 'Tem certeza de que precisa gerar uma lista geral?'
  },
  largeArrive: {
    zh: '一但生成总单袋内包裹将不能增减，已生成总单袋号将过滤。',
    en: '',
    pu: 'Uma vez que uma lista geral é gerada, os pacotes na saca não poderão aumentar ou diminuir, e o número da saca gerada será filtrado'
  },
  sameDistributor: {
    zh: '仅同系统服务商、派送路线袋子支持生成总单',
    en: '',
    pu: 'Somente suporte de provedor de serviços do sistema e rota de entrega, o pedido total pode ser gerado'
  },
  selectVehicleNumber: {
    zh: '请选择运输该袋包裹的车辆车牌号？',
    en: '',
    pu: 'Por favor, selecione o número da placa do veículo que transporta essa saca?'
  },
  pleaseSelectLicenseNumber: {
    zh: '请选择车牌号',
    en: '',
    pu: 'Por favor, selecione um número de placa do veículo'
  },
  ReselectBagNumber: {
    zh: '当前袋号已有大包号,请重新选择袋号',
    en: '',
    pu: 'A saca atual já tem um número, favor selecionar um novo número de saca'
  },
  RemovedSuccessfully: {
    zh: '移除成功',
    en: '',
    pu: 'Retirada bem sucedida'
  },
  WrappedInsideTheBag: {
    zh: '袋内包裹',
    en: 'Wrapped inside the bag',
    pu: 'Embrulhado dentro do saco'
  },
  LargePackageInside: {
    zh: '大包内包裹',
    en: 'Large package inside',
    pu: 'Pacote grande dentro'
  },
  MoveOut: {
    zh: '移出',
    en: '',
    pu: 'Deslocação'
  },
  MaintenancePocket: {
    zh: '维持原袋',
    en: '',
    pu: 'Manter saca original'
  },
  LastOperatingTime: {
    zh: '最近操作时间',
    en: '',
    pu: 'Hora recente de operação '
  },
  NearestOperator: {
    zh: '最近操作人',
    en: '',
    pu: 'Operador recente'
  },
  WarehouseInbound: {
    zh: '入库称重',
    en: '',
    pu: 'armazenar e pesar'
  },
  WarehouseOutbound: {
    zh: '仓库出库',
    en: 'Warehouse outbound',
    pu: 'Saída de armazém'
  },
  ParcelSorting: {
    zh: '包裹分拣',
    en: '',
    pu: 'Classificação de pacotes'
  },
  bagNumberDuplication: {
    zh: '袋号重复',
    en: '',
    pu: 'Duplicação do número da saca'
  },
  PleaseBagNumber: {
    zh: '请输入袋号',
    en: '',
    pu: 'Digite o número da saca'
  },
  TotalOrderDetails: {
    zh: '总单详情',
    en: 'total order details',
    pu: 'Três listas de isenção'
  },
  totalSingleList: {
    zh: '总单列表',
    en: '',
    pu: 'Lista Geral'
  },
  totalSingNumber: {
    zh: '总单号',
    en: '',
    pu: 'Número de lista geral'
  },
  enterTrackingNumber: {
    zh: '扫描/输入总单号',
    en: 'Scan/enter the tracking number',
    pu: 'Digitalizar/inserir o número de rastreamento'
  },
  totalSingleState: {
    zh: '总单状态',
    en: '',
    pu: 'Status da lista geral'
  },
  CharacterPermission: {
    zh: '字符权限',
    en: '',
    pu: 'Permissões de caráter'
  },
  generatedTotalSingle: {
    zh: '生成总单',
    en: '',
    pu: 'Gerar um lista geral'
  },
  leastParcel: {
    zh: '最少还需包裹',
    en: '',
    pu: 'Número mínimo de pacotes necessários'
  },
  prcelExcess: {
    zh: '选中包裹超出1000上限',
    en: '',
    pu: 'Pacotes selecionadas excedem o limite de 1000'
  },
  areYouSureGeneratedTotalSingle: {
    zh: '确定生成总单吗？',
    en: '',
    pu: 'Tem certeza de que precisa gerar uma lista geral?'
  },
  beenGenerated: {
    zh: '一但生成总单袋内包裹将不能增减，已生成总单袋号将过滤。',
    en: '',
    pu: 'Uma vez que uma lista geral é gerada, os pacotes na saca não poderão aumentar ou diminuir, e o número da saca gerada será filtrado'
  },
  sameSystem: {
    zh: '仅同一系统服务商的袋子支持合并为总单',
    en: '',
    pu: 'Somente as sacas do mesmo servidor de sistema são aceitas para consolidação em uma lista geral'
  },
  BrazilianPostLocal: {
    zh: '巴西邮政本地',
    en: '',
    pu: 'Correios do Brasil Local'
  },
  sensitiveInfoPermission: {
    zh: '无查看订单敏感信息权限',
    en: '',
    pu: 'Nenhuma permissão para visualizar informações confidenciais do pedido'
  },
  completionInformation: {
    zh: '点击查看完整信息',
    en: '',
    pu: 'Clique para obter informações sobre a conclusão'
  },
  characterPermission: {
    zh: '字符权限',
    en: '',
    pu: 'Permissões de caracteres'
  },
  DeclarationNumber: {
    zh: '申报号',
    en: '',
    pu: 'Número da declaração'
  },
  PartialDeclaration: {
    zh: '部分申报',
    en: '',
    pu: 'Declaração parcial'
  },
  NoDeclaration: {
    zh: '无需申报',
    en: '',
    pu: 'Nenhuma declaração necessária'
  },
  QuantitySetting: {
    zh: '分配申报数量设置',
    en: '',
    pu: 'Definição de opções de quantidade de declaração'
  },
  mindeclaredQuantity: {
    zh: '设置巴西邮政每个批次最小申报数量',
    en: '',
    pu: 'Definir o número mínimo de declarações por lote para os Correios do Brasil'
  },
  BrazilianPostGenerter: {
    zh: '巴西邮政达到这个最小申报数才能生成总单',
    en: '',
    pu: 'Esse número mínimo de declarações é necessário para que os Correios do Brasil gerem um PLP'
  },
  PLPDeclaration: {
    zh: '总单内包裹会按这个数量分批自动PLP申报',
    en: '',
    pu: 'Pacotes serão automaticamente declaradas como PLP em lotes por essa quantidade'
  },
  SuccessfulDeclaration: {
    zh: '申报成功',
    en: '',
    pu: 'Declaração com sucesso'
  },
  BagNumbeDetail: {
    zh: '袋号明细',
    en: '',
    pu: 'Detalhamento do número da saca'
  },
  ManualDeclaration: {
    zh: '手动申报',
    en: '',
    pu: 'Declaração manual'
  },
  ImmediateDeclaration: {
    zh: '确定立即申报吗?',
    en: '',
    pu: 'Tem certeza de que deseja declarar imediatamente?'
  },
  AutomaticFiltration: {
    zh: '已申报、未申报运单将自动过滤。',
    en: '',
    pu: 'As waybills declaradas e não declaradas serão filtradas automaticamente.'
  },
  plpNo: {
    zh: 'PLP号',
    en: '',
    pu: 'No. PLP '
  },
  SelectedPackage: {
    zh: '已选中包裹',
    en: '',
    pu: 'Pacotes selecionados'
  },
  TotalParcelVolume: {
    zh: '包裹总量最小不小于1',
    en: '',
    pu: 'Total mínimo de pacotes não inferior a 1'
  },
  pleaseSelectBagNumber: {
    zh: '请选择袋号',
    en: '',
    pu: 'Selecione o número da saca'
  },
  Over1000: {
    zh: '选中包裹超出1000上限',
    en: '',
    pu: 'Pacotes selecionadas excedem o limite de 1000'
  },
  StillNeedParcel: {
    zh: '最少还需包裹:',
    en: '',
    pu: 'Número mínimo de pacotes necessários:'
  },
  PrintList: {
    zh: '打印清单',
    en: '',
    pu: 'Imprimir lista'
  },
  minDeclaredValue: {
    zh: '请输入最小申报值',
    en: '',
    pu: 'Digite o valor mínimo declarado'
  },
  maxDeclaredValue: {
    zh: '请输入最大申报值',
    en: '',
    pu: 'Digite o valor máximo declarado'
  },
  minValueMoreThanMaxValue: {
    zh: '最小值不能大于最大值',
    en: '',
    pu: 'O valor mínimo não pode ser maior que o valor máximo'
  },
  DeclaredQuantity: {
    zh: '申报数量',
    en: '',
    pu: 'Número de declarações'
  },
  ValueSet: {
    zh: '范围包含设置的数值，比如，设置为100~500，',
    en: '',
    pu: 'O intervalo contém o valor definido, por exemplo, definido como 100~500, '
  },
  Actual: {
    zh: '实际为[100,500]',
    en: '',
    pu: 'na verdade [100,500]'
  },
  greaterThan0: {
    zh: '请输入大于0的正整数',
    en: '',
    pu: 'Digite um número inteiro positivo maior que 0'
  },
  greaterThan99: {
    zh: '超出最大值99999999',
    en: '',
    pu: 'Excede o valor máximo de 999999999'
  },
  half: {
    zh: '最大值的二分之一需大于等于最小值',
    en: '',
    pu: 'Metade do valor máximo é maior ou igual ao valor mínimo'
  },
  MobilePhone: {
    zh: '手机',
    en: '',
    pu: 'Celular'
  },
  hasNumberTaken: {
    zh: '所选订单已取号',
    en: '',
    pu: 'o número do pedido do provedor de serviços foi obtido'
  },
  Pleasesealthebag: {
    zh: '存在已装袋但未封袋数据,请封袋!',
    en: '',
    pu: 'Há dados ensacados, mas não selados, por favor, sele a saca'
  },
  TotalNumberParcels: {
    zh: '选中袋号内总包裹数需大于等于',
    en: '',
    pu: 'O número total de pacotes na saca selecionada deve ser maior ou igual a'
  },
  bagged: {
    zh: '运单号已经装袋',
    en: '',
    pu: 'Número da logística já ensacado'
  },
  ParcelExcess: {
    zh: '选中包裹超出',
    en: '',
    pu: 'O pacote selecionado ultrapassa o limite'
  },
  sureToPrint: {
    zh: '总单下部分运单还未成功申报,申报清单内运单不全，确定打印吗？',
    en: '',
    pu: 'Parte da carta de porte mestre não foi declarada com sucesso, carta de porte incompleta na lista de declaração,tem certeza de que vai imprimir?'
  },
  DetailOfwaybill: {
    zh: '运单明细',
    en: '',
    pu: 'Detalhes da carta de porte'
  },
  ConfirmReceipt: {
    zh: '确认&收货',
    en: '',
    pu: 'Confirmação e coleta'
  },
  open: {
    zh: '2、开启',
    en: '',
    pu: '2、Abrir'
  },
  plugin: {
    zh: '插件',
    en: '',
    pu: 'plug-in'
  },
  WeightInformation: {
    zh: ',先扫描运单号，再将包裹放在称重设备上获取重量信息，称重成功包裹将更新为转运入库状态;',
    en: '',
    pu: ',Primeiro,escanear o número da carta de porte， em seguida, colocar pacote no equipamento de pesagem para obter  informação sobre o peso.A pesagem bem sucedida do pacote será actualizada com estado do trânsito para entrada de armazem'
  },
  WeightCorrectInformation: {
    zh: ',扫描运单号,将包裹放在称重设备上获取重量信息;包裹重量更新为新的重量;',
    en: '',
    pu: ',escanear o número da carta de porte,e colocar pacote no equipamento de pesagem para obter  informação sobre o peso. peso do pacote será actualizado'
  },
  tow: {
    zh: '2、扫描运单号，扫描成功运单号将自动更新为转运入库状态；如称重数据有误，点击',
    en: '',
    pu: '2.Escaneie No. da carta de porte,que digitalizado com sucesso será automaticamente atualizado para status de transferência para armazenamento; se dados de pesagem incorretos, clique '
  },
  tow1: {
    zh: '此处',
    en: '',
    pu: 'aqui '
  },
  tow2: {
    zh: '1、为减少异常，扫描前请切换成[EN]输入法;',
    en: '',
    pu: '1、Para reduzir as exceções, mude para método de entrada [EN] antes de digitalizar;'
  },
  ScanningWaybill: {
    zh: '扫描运单',
    en: '',
    pu: 'escaneie carta de porte'
  },
  parcelWeight: {
    zh: '包裹重量(kg)',
    en: 'parcel weight(kg)',
    pu: 'peso da parcela(kg)'
  },
  WeightCorrection: {
    zh: '重量校正',
    en: '',
    pu: 'correção do peso'
  },
  DeviceSetup: {
    zh: '设备设置',
    en: '',
    pu: 'configuração do dispositivo'
  },
  PleaseEnsure: {
    zh: '请确保',
    en: '',
    pu: 'verifique se'
  },
  OnState: {
    zh: '为开启状态;',
    en: '',
    pu: ' está ativado;'
  },
  deviceNotConnected: {
    zh: '未连接称重设备',
    en: '',
    pu: 'equipamento de pesagem não conectado'
  },
  EquipmentMatching: {
    zh: '请确保设置中的设备与实际使用设备匹配;',
    en: '',
    pu: 'verifique se dispositivo nas configurações corresponde ao dispositivo real em uso;'
  },
  CustomerWeight: {
    zh: '客户重量(kg)',
    en: 'Customer weight',
    pu: 'Peso declarado(kg)'
  },
  Comprimento: {
    zh: '长*宽*高(cm)',
    en: '',
    pu: 'Comprimento*Largura*Altura (cm)'
  },
  equipamento: {
    zh: '称重设备品牌',
    en: '',
    pu: 'Marca do equipamento de pesagem'
  },
  IngestionSucceeded: {
    zh: '入库成功',
    en: 'Ingestion succeeded',
    pu: 'Entrada sucedido'
  },
  openPlugin: {
    zh: '插件未启动，请打开插件',
    en: '',
    pu: 'plug-in não foi iniciado, abra o plug-in'
  },
  inicializando: {
    zh: '设备初始化中...',
    en: '',
    pu: 'O dispositivo está inicializando....'
  },
  sucesso: {
    zh: '设备初始化成功',
    en: '',
    pu: 'Dispositivo inicializado com sucesso'
  },
  InitiationFailure: {
    zh: '电子秤初始化失败，请检查电子秤或插件',
    en: '',
    pu: 'Falha na inicialização da balança eletrônica, verifique balança eletrônica ou plug-in'
  },
  AnomalyReduction: {
    zh: '2、扫描运单号，扫描成功运单号包裹重量更新为新的重量；为减少异常，扫描前请切换成[EN]输入法;',
    en: '',
    pu: '2.Apois No. da carta de porte digitalizado com sucesso, peso da encomenda atualizado para o novo peso; para reduzir as exceções, mude para método de entrada [EN] antes de digitalizar'
  },
  CurrentParcel: {
    zh: '当前包裹重量(kg)',
    en: '',
    pu: 'Peso atual da encomenda(kg)'
  },
  PreCheckWeight: {
    zh: '校验前重量(kg)',
    en: '',
    pu: 'Peso antes da correção(kg)'
  },
  WeightCorrectionRecord: {
    zh: '重量校正记录',
    en: '',
    pu: 'Registro de correção de peso'
  },
  corrected: {
    zh: '已校正: ',
    en: '',
    pu: 'corrigido: '
  },
  SetDevice: {
    zh: '设置设备',
    en: '',
    pu: 'configurar dispositivo'
  },
  SelectEquipment: {
    zh: '请选择设备品牌',
    en: '',
    pu: 'selecione marca do dispositivo'
  },
  CurrentWeight: {
    zh: '当前重量(kg)',
    en: '',
    pu: 'peso atual (kg)'
  },
  SortingCondition: {
    zh: '分拣条件',
    en: '',
    pu: 'Condição de classificação'
  },
  CorrectSuccessfully: {
    zh: '校正成功',
    en: '',
    pu: 'correção bem-sucedida'
  },
  CustomerOrderValue: {
    zh: '客户下单价值',
    en: '',
    pu: 'Valor do pedido do cliente'
  },
  NetworkName: {
    zh: '操作更新为已揽收状态的网点名',
    en: '',
    pu: 'nome de ponto de operação atualizada para status coletado'
  },
  SortingCompleted: {
    zh: '系统分配的派送网点', // '分拣完成后系统分配的派送网点',
    en: '',
    pu: 'Pontos de entrega atribuídos pelo sistema'
  },
  DeliveToStorage: {
    zh: '操作包裹签收的网点',
    en: '',
    pu: 'Ponto que opera a entrega'
  },
  sealBagNumber: {
    zh: '包裹在揽收时封袋袋号',
    en: '',
    pu: 'No.do saco selado de pacote coleta'
  },
  TransitWarehouse: {
    zh: '包裹在中转仓装袋袋号',
    en: '',
    pu: 'No. da bolsa no armazém de trânsito'
  },
  ParcelLength: {
    zh: '客户下单时填的包裹长度',
    en: '',
    pu: 'Comprimento da pacote preenchida pelo cliente ao fazer pedido'
  },
  ParcelWidth: {
    zh: '客户下单时填的包裹宽度',
    en: '',
    pu: 'Largura da pacote preenchida pelo cliente ao fazer pedido'
  },
  ParcelHeight: {
    zh: '客户下单时填的包裹高度',
    en: '',
    pu: 'Altura da pacote preenchida pelo cliente ao fazer pedido'
  },
  pedidoCliente: {
    zh: '请输入运单号或客户单号',
    en: '',
    pu: 'Digite No. da carta de porte ou No. do pedido do cliente'
  },
  CustomerOrderweight: {
    zh: '客户下单重量',
    en: '',
    pu: 'peso do pedido do cliente'
  },
  coleta: {
    zh: '揽收网点称重',
    en: '',
    pu: 'pesagem da ponto de coleta'
  },
  preEntrega: {
    zh: '预派送网点',
    en: '',
    pu: 'Ponto previsto de entrega'
  },
  entrega: {
    zh: '实际派送网点',
    en: '',
    pu: 'Ponto atual de entrega'
  },
  comprimento: {
    zh: '下单长(cm)',
    en: '',
    pu: 'comprimento do pedido(cm)'
  },
  largura: {
    zh: '下单宽(cm)',
    en: '',
    pu: 'largura do pedido (cm)'
  },
  altura: {
    zh: '下单高(cm)',
    en: '',
    pu: 'altura do pedido (cm)'
  },
  pesoTotal: {
    zh: '包裹总重量(kg)',
    en: '',
    pu: 'peso total de pacote(kg)'
  },
  pesoTotal1: {
    zh: '包裹总重量',
    en: '',
    pu: 'peso total de pacote'
  },
  ParcelUnweighed: {
    zh: '包裹未称重',
    en: '',
    pu: 'pacote não pesado'
  },
  NoAuthority: {
    zh: '无权限，请联系管理员开通重量校正页权限',
    en: '',
    pu: 'em permissão, contacte administrador para abrir a permissão da página de correção de peso'
  },
  cannotNegative: {
    zh: '包裹重量不能为负数',
    en: '',
    pu: 'peso de pacote não pode ser negativo'
  },
  ParcelLimit: {
    zh: '超出袋子能装袋的最大包裹数限制:1000;',
    en: '',
    pu: 'máximo número limitado de pacotes que podem ser ensacadas é:1000;'
  },
  ScanRecord: {
    zh: '扫描收货记录',
    en: '',
    pu: 'Registro de coleta escaneada'
  },
  ReceivedGoods: {
    zh: '已收货',
    en: '',
    pu: 'Coletado'
  },
  LWH: {
    zh: '长,宽,高超出最大范围170cm',
    en: '',
    pu: 'L,W,H excedem o alcance máximo de 170 cm'
  },
  LWHSum: {
    zh: '长+宽+高需<=240',
    en: '',
    pu: 'L+W+H tem de ser <=240'
  },
  maximo30kg: {
    zh: '包裹超出最大范围30kg',
    en: '',
    pu: 'pacote fora do alcance máximo 30kg'
  },
  maximo500kg: {
    zh: '包裹超出最大范围500kg',
    en: '',
    pu: 'pacote fora do alcance máximo 500kg'
  },
  minimo30kg: {
    zh: '总重需小于等于30kg，请封袋后换新袋子重试',
    en: '',
    pu: 'peso total deve ser menor ou igual a 30 kg. Por favor, tente novamente com saca nova depois de saca selada. '
  },
  minimo500kg: {
    zh: '总重需小于等于500kg，请封袋后换新袋子重试',
    en: '',
    pu: 'peso total deve ser menor ou igual a 500 kg. Por favor, tente novamente com saca nova depois de saca selada. '
  },
  sorterWeighsBagging: {
    zh: '请用入库称重或者分拣机称重后再装袋',
    en: '',
    pu: ' Por favor,  Pesagem de armazenamento ou por sorter antes de ensacar'
  },
  beenDistributed: {
    zh: '订单已非待分配状态，无需分配',
    en: '',
    pu: 'O pedido não está mais pendente e não precisa ser alocado'
  },
  PleaseConnectTheWeighingDeviceUSBPortToTheComputer: {
    zh: '请让称重设备USB接口连接到电脑；',
    en: 'Please connect the weighing device USB port to the computer;',
    pu: 'Conecte a porta USB do dispositivo de pesagem ao computador;'
  },
  DeliveryPoint: {
    zh: '派送网点',
    en: '',
    pu: 'Ponto de entrega'
  },
  NetworkPlanned: {
    zh: '计划用于派送的网点',
    en: '',
    pu: 'ponto planejado para entrega'
  },
  declaradossucesso: {
    zh: '没有需要申报的数据',
    en: '',
    pu: 'Não há dados a declarar'
  },
  originalBag: {
    zh: '原袋补货',
    en: '',
    pu: 'reabastecimento de saco original'
  },
  PleaseValue: {
    zh: '请输入6~8位数值',
    en: '',
    pu: 'Insira um valor de 6 a 8 dígitos'
  },
  EntryBagNumber: {
    zh: '扫描录入袋号',
    en: '',
    pu: 'Digitalize e digite o número da saca'
  },
  BagAvailable: {
    zh: '袋号可用',
    en: '',
    pu: 'número disponível de saca'
  },
  ShiftOut: {
    zh: '移除请前往移除出袋',
    en: '',
    pu: 'remover, por favor, vá para remover saca'
  },
  Date: {
    zh: '日期',
    en: '',
    pu: 'Data'
  },
  sysAlive: {
    zh: '日活-系统',
    en: '',
    pu: 'Usuários ativos diários-sistema'
  },
  orderNumber: {
    zh: '物流订单数',
    en: '',
    pu: 'Número de pedidos de logística'
  },
  waybillAlive: {
    zh: '日活-物流订单',
    en: '',
    pu: 'Usuários ativos diários - pedidos de logística'
  },
  changeOrderNumber: {
    zh: '换单订单数',
    en: '',
    pu: 'Número de ordens de alteração de etiqueta'
  },
  changeOrderAlive: {
    zh: '日活-换单',
    en: '',
    pu: 'Usuários ativos diários-alteração de etiqueta'
  },
  takeNoSuccessRate: {
    zh: '取号成功率',
    en: '',
    pu: 'Pega o número com sucesso'
  },
  scanSuccessRate: {
    zh: '扫描成功率',
    en: '',
    pu: 'Taxa de sucesso do escaneamento'
  },
  printSuccessRate: {
    zh: '打单成功率',
    en: '',
    pu: 'Taxa de sucesso da imprimissão de etiqueta'
  },
  FunctionalStatistics: {
    zh: '功能统计',
    en: '',
    pu: 'Estatísticas de funções'
  },
  ReportManagement: {
    zh: '报表管理',
    en: '',
    pu: 'Gerenciamento de relatórios'
  },
  ThisRouteNumber: {
    zh: '该路线号将在面单上展示，用于提示包裹由中转仓到网点的路线。',
    en: 'This route number will be displayed on the face sheet to indicate the route of the package from the transit warehouse to the outlet.',
    pu: 'Este número de rota será exibido na folha de rosto para indicar a rota do pacote do armazém de trânsito para a tomada.'
  },
  AssociatedOutlets: {
    zh: '关联网点',
    en: 'Associated outlets',
    pu: 'Lojas associadas'
  },
  OutletsThatShareTheRouteNumber: {
    zh: '共用该路线号的网点。',
    en: 'Outlets that share the route number.',
    pu: 'Lojas que compartilham o número da rota.'
  },
  PleaseEnter68Characters: {
    zh: '请输入6~8个字符',
    en: 'Please enter 6~8 characters',
    pu: 'Por favor, insira 6 ~ 8 caracteres'
  },
  SelectSites: {
    zh: '选择网点',
    en: 'Select Sites',
    pu: 'Selecionar Sites'
  },
  Selected: {
    zh: '选中',
    en: 'Selected',
    pu: 'Selecionado'
  },
  CancelAll: {
    zh: '全部取消',
    en: 'Cancel all',
    pu: 'Cancelar tudo'
  },
  SelectAll: {
    zh: '全部选中',
    en: 'Select all',
    pu: 'Selecionar tudo'
  },
  route: {
    zh: '路线',
    en: 'route',
    pu: 'Rota'
  },
  Outlets: {
    zh: '网点',
    en: 'Outlets',
    pu: 'Ponto de rede'
  },
  AssociationStatus: {
    zh: '关联状态',
    en: 'Association status',
    pu: 'Estado de  ligação'
  },
  relational: {
    zh: '关联',
    en: '',
    pu: 'Ligado'
  },
  Untie: {
    zh: '解绑',
    en: '',
    pu: 'Sem ligação'
  },
  CityState: {
    zh: '城市州',
    en: 'City State',
    pu: 'Estado e Cidade '
  },
  RouteNumber: {
    zh: '路线号',
    en: 'Route number',
    pu: 'Número de rota'
  },
  NotAssociated: {
    zh: '未关联',
    en: '',
    pu: 'Não ligado'
  },
  Associated: {
    zh: '已关联',
    en: '',
    pu: 'Ligado'
  },
  BindTheRoute: {
    zh: '绑定路线',
    en: 'Bind the route',
    pu: 'Rota vinculada'
  },
  PleaseSelectRoute: {
    zh: '请选择路线',
    en: 'Please select a route',
    pu: 'Selecione a rota por favor'
  },
  UntieRoute: {
    zh: '解绑路线',
    en: 'Untie route',
    pu: 'Rota desvinculada'
  },
  unbindingRoute: {
    zh: '确定解绑路线吗？',
    en: '',
    pu: 'Tem certeza sobre rota desvinculada?'
  },
  thereAre: {
    zh: '有',
    en: 'There are',
    pu: 'Existem'
  },
  deliveryOutlets: {
    zh: '个派送网点未关联路线',
    en: '',
    pu: 'pontos de entrega que não estão ligado rota'
  },
  PointStatus: {
    zh: '网点状态',
    en: 'Point status',
    pu: 'Estado de ponto'
  },
  OnlyTheFirst10: {
    zh: '只显示前10条路线，可搜索',
    en: '',
    pu: 'Mostrar apenas 10 rotas, pesquisáveis'
  },
  AreYouSureAssociate: {
    zh: '确定要关联选择的路线吗？',
    en: 'Are you sure you want to associate the route you selected?',
    pu: 'Tem certeza de que deseja ligar rota selecionada?'
  },
  DeliveryRoutes: {
    zh: '派送路线',
    en: '',
    pu: 'Rota de entrega'
  },
  newRouter: {
    zh: '新建路线',
    en: '',
    pu: 'Nova rota'
  },
  editRouter: {
    zh: '修改路线',
    en: '',
    pu: 'Modificar rota'
  },
  dotZipCode: {
    zh: '网点邮编',
    en: '',
    pu: 'Código postal do Ponto'
  },
  dotStop: {
    zh: '网点已停用,请启用之后在关联',
    en: '',
    pu: 'Ponto foi desativado, por favor active-o e depois ligue-o'
  },
  TransferOrSupplier: {
    zh: '中转仓到派送网点或派送网点的供应商的中转仓的路线号',
    en: '',
    pu: 'No.da rota de armazém de trânsito para ponto de entrega ou No.da rota de armazém de trânsito do fornecedor de ponto de entrega'
  },
  DeliverySupplier: {
    zh: '派送网点供应商',
    en: '',
    pu: 'Fornecedor de ponto de entrega'
  },
  routesInconsistent: {
    zh: '派送路线不一致，请选择路线号相同的包裹装袋',
    en: '',
    pu: 'Rota de entrega não for a mesma,  seleccione o mesmo número de rota para ensacar'
  },
  SupplierInconsistent: {
    zh: '网点供应商不一致，请选择供应商相同的包裹装袋',
    en: '',
    pu: 'Fornecedor não for o mesmo, escolher o mesmo fornecedor para ensacar'
  },
  DifferentRoute: {
    zh: '路线号不同，请选择路线号相同袋号重试',
    en: '',
    pu: 'Número de rota diferente, seleccione o mesmo número de rota e tente novamente'
  },
  pushType: {
    zh: '推送类型',
    en: '',
    pu: 'Tipo do push'
  },
  Receptor: {
    zh: '接收方',
    en: '',
    pu: 'Receptor'
  },
  taskCreationTime: {
    zh: '任务创建时间',
    en: '',
    pu: 'Tempo de criação da tarefa'
  },
  pushStatus: {
    zh: '推送状态',
    en: '',
    pu: 'Status do push'
  },
  PushManual: {
    zh: '手动推送',
    en: '',
    pu: 'Push manual'
  },
  pushSuccess: {
    zh: '推送成功时间',
    en: '',
    pu: 'Tempo de sucesso do push'
  },
  filtration: {
    zh: '成功和推送中任务将直接过滤',
    en: '',
    pu: 'As tarefas em success e push serão filtradas diretamente'
  },
  Falha: {
    zh: '推送失败',
    en: '',
    pu: 'Falha de impulso'
  },
  pushing: {
    zh: '推送中',
    en: '',
    pu: 'pushing'
  },
  pushSucess: {
    zh: '推送成功',
    en: '',
    pu: 'sucesso de push'
  },
  selectOrderNumber: {
    zh: '请选中单号',
    en: '',
    pu: 'Selecione número do pedido'
  },
  NonePushFailed: {
    zh: '无推送失败数据',
    en: '',
    pu: 'Nenhum dados de push falhado'
  },
  ServiceProviderChannels: {
    zh: '服务商渠道',
    en: '',
    pu: 'Canais de provedor de serviços'
  },
  pleaseSelectChannel: {
    zh: '请选择服务商渠道',
    en: '',
    pu: 'Selecione canal de provedor de serviços'
  },
  selectChannelTip: {
    zh: '如供应商使用自己的系统请联系IT对接供应商系统，并添加供应商独有渠道；如供应商使用安骏系统，请选择ANJUN_COMPANY',
    en: '',
    pu: 'Se o fornecedor usar seu próprio sistema, entre em contato com a IT para acoplar o sistema do fornecedor e adicionar o canal exclusivo do fornecedor; se o fornecedor usar o sistema ANJUN, selecione ANJUN_COMPANY.'
  },
  Carregado: {
    zh: '已装车',
    en: '',
    pu: 'Carregado'
  },
  NoCarregado: {
    zh: '未装车',
    en: '',
    pu: 'Não carregado'
  },
  Descarregado: {
    zh: '已卸车',
    en: '',
    pu: 'Descarregado'
  },
  LoadCarTime: {
    zh: '装车时间',
    en: '',
    pu: 'Hora de carga'
  },
  unCarTime: {
    zh: '卸车时间',
    en: '',
    pu: 'Tempo de descarregamento'
  },
  Carregador: {
    zh: '装车人',
    en: '',
    pu: 'Carregador'
  },
  Descarregador: {
    zh: '卸车人',
    en: '',
    pu: 'Descarregador'
  },
  InvoicingStatus: {
    zh: '开票状态',
    en: 'Invoicing status',
    pu: 'estado da fatura'
  },
  Invoiced: {
    zh: '已开票',
    en: 'Invoiced',
    pu: 'fatura emitida'
  },
  CancelInvoicing: {
    zh: '取消开票',
    en: 'Cancel invoicing',
    pu: 'cancelar a emissão da fatura'
  },
  Canceled: {
    zh: '已取消',
    en: 'Canceled',
    pu: 'Cancelado'
  },
  Closed: {
    zh: '已关闭',
    en: '',
    pu: 'Fechado'
  },
  paid: {
    zh: '已支付',
    en: '',
    pu: 'Pago'
  },
  Close: {
    zh: '关闭',
    en: '',
    pu: 'Fechar'
  },
  deliveryFailure: {
    zh: '投递失败',
    en: '',
    pu: 'Falha na entrega'
  },
  NoNeedInvoice: {
    zh: '无需开票',
    en: '',
    pu: 'Não é necessário faturamento'
  },
  Rejected: {
    zh: '已拒绝',
    en: 'Rejected',
    pu: 'recusado'
  },
  ToBeInvoiced: {
    zh: '待开票',
    en: 'To be invoiced',
    pu: 'A faturar'
  },
  LogisticsInvoiceInvoicingTime: {
    zh: '物流发票开票时间',
    en: 'Logistics invoice invoicing time',
    pu: 'O tempo da emissão de CT-e'
  },
  to: {
    zh: '至',
    en: 'to',
    pu: 'Para'
  },
  startDate: {
    zh: '开始日期',
    en: 'Start date',
    pu: 'Data de início'
  },
  endDate: {
    zh: '结束日期',
    en: 'End date',
    pu: 'Data final'
  },
  DownloadTheInvoice: {
    zh: '下载发票',
    en: 'Download the invoice',
    pu: 'download a fatura'
  },
  CancelTheInvoice: {
    zh: '取消发票',
    en: 'Cancel the invoice',
    pu: 'cancelar a fatura'
  },
  PrintTheChecklist: {
    zh: '打印清单',
    en: 'Print the checklist',
    pu: 'imprimir a lista'
  },
  LogisticsInvoiceNumber: {
    zh: '物流发票号',
    en: 'Logistics invoice number',
    pu: 'O número da CT-e'
  },
  LogisticsInvoiceKey: {
    zh: '物流发票密钥',
    en: 'Logistics invoice key',
    pu: 'O chave do acesso da CT-e'
  },
  InvoicingTime: {
    zh: '开票时间',
    en: 'Invoicing time',
    pu: 'O tempo de emitir a fatura'
  },
  ScopeOfTransportation: {
    zh: '运输范围',
    en: 'Scope of transportation',
    pu: 'Escopo do transporte'
  },
  CommodityInvoices: {
    zh: '商品发票',
    en: 'Commodity invoices',
    pu: 'Faturas de mercadorias'
  },
  CommodityInvoiceKey: {
    zh: '商品发票密钥',
    en: 'Commodity invoice key',
    pu: 'Chave da fatura da mercadoria'
  },
  PickUpBagNumber: {
    zh: '揽收袋号',
    en: 'Pick up bag number',
    pu: 'Retirar o número da sacola'
  },
  TimeOfOrder: {
    zh: '下单时间',
    en: 'Time of order',
    pu: 'Hora do pedido'
  },
  InvoiceKey: {
    zh: '生成物流发票接口返回的密钥,为64位',
    en: 'The key returned by the Generate Logistics Invoice API is 64 bits',
    pu: 'A chave retornada pela interface de geração da CT-e é composta por 64 caracteres'
  },
  LogisticsInvoiceGenerationTime: {
    zh: '物流发票生成时间',
    en: 'Logistics invoice generation time',
    pu: 'O tempo de geração da CT-e'
  },
  LogisticsInvoiceInvoicingScope: {
    zh: '物流发票开票范围包含的起止城市',
    en: 'The starting and ending cities that are included in the logistics invoice invoicing scope',
    pu: 'O cidade de partida e chegada precisa de ser incluido quando emitir a CT-e'
  },
  productInvoiceKeyCorresponding: {
    zh: '客户下单生产的商品发票对应的商品发票密钥',
    en: 'The product invoice key corresponding to the product invoice produced by the customer',
    pu: 'A chave da NF-e correspondente ao pedido on-line do cliente'
  },
  CommodityInvoiceActivationTime: {
    zh: '商品发票开通时间',
    en: 'Commodity invoice activation time',
    pu: 'O tempo da emissão de NF-e'
  },
  Selecionadas: {
    zh: '已选中',
    en: 'downloadable invoices selected',
    pu: 'Selecionadas'
  },
  waybillsWereSelected: {
    zh: '个运单',
    en: 'waybills were selected',
    pu: 'guias de transporte'
  },
  paraDownload: {
    zh: '条可下载发票',
    en: 'downloadable invoices selected',
    pu: 'faturas disponíveis para download.'
  },
  RejectedAndCanceledInvoices: {
    zh: '失败和已取消的发票将不支持下载',
    en: 'Rejected and canceled invoices will not be supported for download',
    pu: 'Faturas rejeitadas e canceladas não serão disponibilizadas para download.'
  },
  PleaseSelectAnInvoice: {
    zh: '请选择发票',
    en: 'Please select an invoice',
    pu: 'Selecione a fatrua por favor'
  },
  NoDownloadableInvoices: {
    zh: '无可下载发票',
    en: 'No downloadable invoices',
    pu: 'Sem fatura para download'
  },
  sureCancelInvoice: {
    zh: '确定取消发票吗？',
    en: 'Are you sure you want to cancel your invoice?',
    pu: 'Confirma a cancelar uma fatura?'
  },
  invoicingTimeExceeds24: {
    zh: '开票时间超过24小时不能取消的将直接过滤。',
    en: 'If the invoicing time exceeds 24 hours and cannot be canceled, it will be filtered directly.',
    pu: 'Faturas que excedem 24 horas desde o momento da emissão e não podem ser canceladas serão filtradas diretamente.'
  },
  SelectedInvoicesMoreThan24: {
    zh: '发票生成时间已超过24小时，不可取消',
    en: 'The invoice has been generated for more than 24 hours and cannot be cancelled',
    pu: 'As faturas selecionadas estão com mais 24 horas desde o momento da emissão e não podem ser canceladas'
  },
  ReasonForFailure: {
    zh: '失败原因',
    en: 'Reason for failure',
    pu: 'Motivo do fracasso'
  },
  invoicedDataFilter: {
    zh: '当前筛选条件下的开票数据',
    en: 'invoiced data under the current filter conditions',
    pu: 'dados faturados nas condições de filtro atuais'
  },
  RejectedAndCanceled: {
    zh: '已拒绝和已取消的发票不支持导出',
    en: 'Rejected and canceled invoices do not support export',
    pu: 'As faturas recusadas e canceladas não podem ser exportadas'
  },
  interval: {
    zh: '区间',
    en: 'interval',
    pu: 'intervalo'
  },
  HarvestRange: {
    zh: '揽收区间',
    en: 'Harvest range',
    pu: 'Faixa de colheita'
  },
  DeliverySection: {
    zh: '派送区间',
    en: 'Delivery section',
    pu: 'Seção de entrega'
  },
  DateRange: {
    zh: '日期范围',
    en: 'Date range',
    pu: 'Intervalo de datas'
  },
  Charge: {
    zh: '揽收费',
    en: 'Charge',
    pu: 'Acusação'
  },
  DeliveryFee: {
    zh: '派送费',
    en: 'Taxa de entrega',
    pu: 'Delivery fee'
  },
  freight: {
    zh: '运费',
    en: 'freight',
    pu: 'frete'
  },
  rate: {
    zh: '税率',
    en: 'rate',
    pu: 'taxa'
  },
  ShippingCostsIncludeTax: {
    zh: '含税运费',
    en: 'Shipping costs include tax',
    pu: 'Os custos de envio incluem impostos'
  },
  Rebuild: {
    zh: '重新生成',
    en: 'Rebuild',
    pu: 'Reconstruir'
  },
  TheInvoiceAmount: {
    zh: '发票金额',
    en: 'The invoice amount',
    pu: 'O valor da fatura'
  },
  maximumNumber5000: {
    zh: '单个清单打印数量上限为5000',
    en: 'The maximum number of prints for a single manifest is 5000',
    pu: 'O número máximo de impressões para um único manifesto é 5000'
  },
  TheInvoiceSerialNumber: {
    zh: '发票序列号',
    en: '',
    pu: 'O número de série da fatura'
  },
  NationalRegistrationNumber: {
    zh: '州注册号',
    en: '',
    pu: 'IE'
    // pu: 'Número de registro nacional'
  },
  userPhone: {
    zh: '电话',
    en: 'Phone',
    pu: 'Telefone'
  },
  DestinationCompany: {
    zh: '终点公司',
    en: '',
    pu: 'Empresa terminal'
  },
  RouteEndAddress: {
    zh: '路线终点地址',
    en: '',
    pu: 'Endereço terminal da rota'
  },
  SelecioneDestinationCompany: {
    zh: '请选择终点公司',
    en: '',
    pu: 'Selecione empresa terminal'
  },
  TransportCompany: {
    zh: '运输公司',
    en: '',
    pu: 'Empresa de transporte'
  },
  SignatureDrawing: {
    zh: '签收图',
    en: '',
    pu: 'Diagrama de recebimento'
  },
  ColetaMinuta: {
    zh: '揽收minuta',
    en: '',
    pu: 'Coleta minuta'
  },
  Entregaminuta: {
    zh: '派送minuta',
    en: '',
    pu: 'Entrega minuta'
  },
  clickDown: {
    zh: '点击下载',
    en: '',
    pu: 'Clique para fazer o download'
  },
  GeneratedSuccessfully: {
    zh: '生成成功',
    en: '',
    pu: 'Gerar o sucesso'
  },
  BuildFailure: {
    zh: '生成失败',
    en: '',
    pu: 'Falha na geração'
  },
  finished: {
    zh: '已完结',
    en: '',
    pu: 'Concluída'
  },
  ToBeGenerated: {
    zh: '待生成',
    en: '',
    pu: 'A serem gerados'
  },
  digiteCNPJ: {
    zh: '请输入14位数值CNPJ',
    en: '',
    pu: 'Digite o CNPJ com 14 dígitos'
  },
  nationalHighway: {
    zh: '国家公路货运承运人登记处',
    en: '',
    pu: 'Registro Nacional de Transportadora Rodoviária de Cargas'
  },
  pathwayState: {
    zh: '途径州',
    en: '',
    pu: 'Estados ao longo do caminho'
  },
  valorIE: {
    zh: '请输入数值IE',
    en: '',
    pu: 'digite o valor IE'
  },
  RNTRC: {
    zh: '请输入国家公路货运承运人登记处',
    en: '',
    pu: 'Digite o RNTRC'
  },
  diferentesMDFE: {
    zh: '车牌号不同，不能生成同一MDFE。',
    en: '',
    pu: 'Números de placas diferentes não podem gerar o mesmo MDFE.'
  },
  gerarMDFE: {
    zh: '确定生成MDFE吗?',
    en: '',
    pu: 'Tem a certeza de que está a gerar MDFE?'
  },
  GeraMDFE: {
    zh: '生成MDFE',
    en: '',
    pu: 'Gera MDFE'
  },
  bemSucedido: {
    zh: '提交成功, 等待生成预计花费3-5分钟。',
    en: '',
    pu: 'O envio foi bem-sucedido, espera-se que a geração demore de 3 a 5 minutos.'
  },
  Enviepacotes: {
    zh: '跨域运输包裹',
    en: '',
    pu: 'Envie pacotes entre regiões'
  },
  Quantidade: {
    zh: 'CT-e数',
    en: '',
    pu: 'Quantidade de CT-e'
  },
  verificarEstado: {
    zh: '提交成功, 可以在MDFE页面查看生成状态。',
    en: '',
    pu: 'O envio foi bem-sucedido, pode verificar o estado gerado na página MDFE.'
  },
  FechaMDFE: {
    zh: '关闭MDFE',
    en: '',
    pu: 'Fecha MDFE'
  },
  fechado: {
    zh: '袋子未卸车,确认关闭吗?',
    en: '',
    pu: 'o saco não foi descarregado, tem a certeza que está fechado?'
  },
  driver: {
    zh: '司机',
    en: '',
    pu: 'Motorista'
  },
  append: {
    zh: '添加',
    en: '',
    pu: 'adicionar'
  },
  MDFESuccess: {
    zh: 'MDFE生成提交成功。',
    en: '',
    pu: 'Geração de MDFE enviada com sucesso.'
  },
  closeMDFE: {
    zh: '关闭MDFE',
    en: '',
    pu: 'Fecha MDFE'
  },
  descarregado: {
    zh: '袋子未卸车,确认关闭吗?',
    en: '',
    pu: 'o saco não foi descarregado, tem a certeza que está fechado?'
  },
  NoPermission: {
    zh: '无权限，请联系管理员添加权限',
    en: '',
    pu: 'Sem permissões, entre em contato com o administrador para adicionar permissões'
  },
  Terminar: {
    zh: '完结',
    en: '',
    pu: 'Terminar'
  },
  download: {
    zh: '下载',
    en: '',
    pu: 'Download'
  },
  PleaseEnter120Characters: {
    zh: '请输入1~20个字符',
    en: 'Please enter 1~20 characters',
    pu: 'Por favor, insira 1~20 caracteres'
  },
  PleaseEnter8Characters: {
    zh: '请输入6~8个字符',
    en: 'Please enter 6~8 characters',
    pu: 'Por favor, insira 6 ~ 8 caracteres'
  },
  ListUnloadingPoints: {
    zh: '卸货点清单',
    en: 'List of unloading points',
    pu: 'Lista de pontos de descarga'
  },
  PleaseSelectVehicle: {
    zh: '请选择车辆',
    en: 'Please select a vehicle',
    pu: 'Selecione um veículo'
  },
  PleaseSelectCarNumber: {
    zh: '请选择车签号',
    en: 'Please select your car number',
    pu: 'Por favor, selecione o número do seu carro'
  },
  sort: {
    zh: '排序',
    en: '',
    pu: 'Ordenar'
  },
  stop: {
    zh: '停用',
    en: '',
    pu: 'Desativação'
  },
  Name: {
    zh: '名称',
    en: '',
    pu: 'Nome'
  },
  newdescarga: {
    zh: '新建卸车点',
    en: '',
    pu: 'Novo ponto de descarga'
  },
  fixmodificado: {
    zh: '修改卸车点',
    en: '',
    pu: 'Ponto de descarga modificado'
  },
  MultiplexAddress: {
    zh: '复用地址',
    en: '',
    pu: 'Endereço de reutilização'
  },
  UnloadingRollCall: {
    zh: '卸车点名',
    en: '',
    pu: 'Nome do ponto de descarga'
  },
  Estado: {
    zh: '州',
    en: '',
    pu: 'Estado'
  },
  Cidade: {
    zh: '所属城市',
    en: '',
    pu: 'Cidade'
  },
  Zona: {
    zh: '所属区',
    en: '',
    pu: 'Zona'
  },
  Bairro: {
    zh: '所属街道',
    en: '',
    pu: 'Bairro'
  },
  Endereo: {
    zh: '地址',
    en: '',
    pu: 'Endereço'
  },
  carregamento: {
    zh: '共用该卸货点的网点',
    en: '',
    pu: 'Pontos de venda que compartilham esse ponto de carregamento'
  },
  PersonInCharge: {
    zh: '责任人',
    en: '',
    pu: 'Pessoa responsável'
  },
  Selecionename: {
    zh: '选择派送网点名',
    en: '',
    pu: 'Selecione o nome do ponto de entrega'
  },
  UnloadingPoint: {
    zh: '卸货点',
    en: '',
    pu: 'Ponto de descarga'
  },
  nomePoint: {
    zh: '卸货点名/网点名/仓库名',
    en: '',
    pu: 'Nome do ponto de descarga/Nome da rede/Nome do armazém'
  },
  other: {
    zh: '其他',
    en: '',
    pu: 'outro'
  },
  Differentpoints: {
    zh: '卸货点不同包裹不能一起装袋。',
    en: '',
    pu: 'Pacotes diferentes no ponto de descarga não podem ser ensacados juntos.'
  },
  Differentoutlets: {
    zh: '派送网点不同包裹不能一起装袋。',
    en: '',
    pu: 'Pacotes diferentes no ponto de entrega não podem ser ensacados juntos.'
  },
  AssociatedUnloadingPoint: {
    zh: '路线关联派送网点有关联卸货点的展示卸货点，无卸货点的展示派送网点',
    en: '',
    pu: 'Saídas de entrega associadas à rota, exibindo pontos de descarga se houver pontos de descarga associados, exibindo saídas de entrega se não houver pontos de descarga'
  },
  CompanyInformation: {
    zh: '公司信息',
    en: '',
    pu: 'Informações da empresa'
  },
  CompanyAddress: {
    zh: '公司地址',
    en: '',
    pu: 'Endereço da empresa'
  },
  ContactName1: {
    zh: '联系人名',
    en: '',
    pu: 'Nome do responsável'
  },
  Contactinformation: {
    zh: '联系方式',
    en: '',
    pu: 'Detalhes de contato'
  },
  ContactPhone: {
    zh: '联系人手机号',
    en: '',
    pu: 'Celular'
  },
  ContactTel: {
    zh: '联系人电话',
    en: '',
    pu: 'Telefone'
  },
  Bankinformation: {
    zh: '银行信息',
    en: '',
    pu: 'Informações bancárias'
  },
  bank: {
    zh: '银行',
    en: '',
    pu: 'Bancos'
  },
  bankAccount: {
    zh: '银行账号',
    en: '',
    pu: 'N° da conta'
  },
  BankManager: {
    zh: '银行客户经理',
    en: '',
    pu: 'Agência'
  },
  ResponsiblePerson: {
    zh: '责任人名',
    en: '',
    pu: 'Nome da pessoa responsável'
  },
  CPFCNPJ: {
    zh: '责任人CPF/CNPJ',
    en: '',
    pu: 'CPF/CNPJ da pessoa responsável'
  },
  Insira11: {
    zh: '请输入11位数值',
    en: '',
    pu: 'Insira um valor de 11 dígitos'
  },
  Insira14: {
    zh: '请输入14位数值',
    en: '',
    pu: 'Insira um valor de 14 dígitos'
  },
  Insira24: {
    zh: '请输入1~24位数值',
    en: '',
    pu: 'Insira um valor de 1 a 24 dígitos'
  },
  Insira16: {
    zh: '请输入16位数值',
    en: '',
    pu: 'Insira um valor de 16 dígitos'
  },
  formatoError: {
    zh: '格式错误,请输入11位数值',
    en: '',
    pu: 'Erro de formato, insira um valor de 11 dígitos'
  },
  inputContactName: {
    zh: '请输入联系人名',
    en: '',
    pu: 'Por favor insira o nome do contato'
  },
  orderStatus: {
    zh: '下单状态',
    en: '',
    pu: 'Status do pedido'
  },
  succeed: {
    zh: '成功',
    en: '',
    pu: 'Sucesso'
  },
  Lose: {
    zh: '失败',
    en: '',
    pu: 'Falha'
  },
  signedPhoto: {
    zh: '签收签名',
    en: '',
    pu: 'Assinatura'
  },
  signedNamePhoto: {
    zh: '签收照片',
    en: '',
    pu: 'Foto da assinatura'
  },
  InsuranceAgreement: {
    zh: '保险协议',
    en: 'Insurance contract',
    pu: 'Contrato de seguro'
  },
  SupplierManagement: {
    zh: '供应商管理',
    en: 'Supplier management',
    pu: 'Gestão de fornecedores'
  },
  ChineseRoleName: {
    zh: '中文角色名称',
    en: 'Chinese role name',
    pu: 'Nome da função chinesa'
  },
  PortugueseCharacterNames: {
    zh: '葡语角色名称',
    en: 'Portuguese character names',
    pu: 'Nomes de caracteres em português'
  },
  RoleStatus: {
    zh: '角色状态',
    en: 'Role status',
    pu: 'Status da função'
  },
  AccountRole: {
    zh: '账号角色',
    en: 'Account Character',
    pu: 'Papel da conta'
  },
  TheBusinessPrincipalCannotEmpty: {
    zh: '业务主体不能为空',
    en: 'The business subject cannot be empty.',
    pu: 'O objeto de negócios não pode estar vazio.'
  },
  TheAccountRoleCannotEmpty: {
    zh: '账号角色不能为空',
    en: "Account character can't be empty.",
    pu: 'Papel da conta não pode estar vazia.'
  },
  GoToAccount: {
    zh: '进入账号',
    en: 'Access to the account',
    pu: 'Acesso à conta'
  },
  SetupSucceeded: {
    zh: '设置成功',
    en: 'Setup succeeded',
    pu: 'Configuração bem sucedida'
  },
  MDFENumber: {
    zh: 'MDFE编号',
    en: '',
    pu: 'Número de MDFE'
  },
  state: {
    zh: '州',
    en: '',
    pu: 'Estado'
  },
  CreateService: {
    zh: '创建服务',
    en: '',
    pu: 'Cria um serviço'
  },
  ClosingTime: {
    zh: '关闭时间',
    en: '',
    pu: 'Horário de fechamento'
  },
  shutter: {
    zh: '关闭人',
    en: '',
    pu: 'Pessoa de fechamento'
  },
  Digite1: {
    zh: '请输入大于等于1的整数。',
    en: '',
    pu: 'Digite um número inteiro maior ou igual a 1'
  },
  setAutoClseTime: {
    zh: '设置MDFE的自动关闭时间',
    en: '',
    pu: 'Defina o tempo de fechamento automático do MDFE'
  },
  autoClseTime: {
    zh: '自动关闭时间',
    en: '',
    pu: 'Tempo de fechamento automático'
  },
  MDFEuningDot: {
    zh: '该MDFE包含的卸货点州',
    en: '',
    pu: 'Estados com pontos de descarga incluídos neste MDFE'
  },
  Nonetworkmanagement: {
    zh: '无网点管理权限，请联系管理员处理。',
    en: '',
    pu: 'Sem direitos de gerenciamento do site, entre em contato com o administrador'
  },
  selectTheRoute: {
    zh: '请按途径州顺序先后选择途径州',
    en: '',
    pu: 'Selecione os estados da rota na ordem em que estão listados'
  },
  notBeDragged: {
    zh: '出发地不可拖动',
    en: '',
    pu: 'O ponto de partida não é arrastável'
  },
  Settings: {
    zh: '设置',
    en: '',
    pu: 'Configuração'
  },
  EditUnloadPoint: {
    zh: '编辑卸货点',
    en: '',
    pu: 'Editar ponto de descarga'
  },
  EditingDot: {
    zh: '编辑网点',
    en: '',
    pu: 'Editar ponto'
  },
  MessageTime: {
    zh: '留言时间',
    en: 'Message Time',
    pu: 'Tempo da mensagem'
  },
  VendorName: {
    zh: '供应商名称',
    en: 'Vendor name',
    pu: 'Nome de Fornecedor'
  },
  MessageContent: {
    zh: '留言内容',
    en: 'Message',
    pu: 'Mensagem'
  },
  CommunicationContent: {
    zh: '沟通内容',
    en: 'Communication content',
    pu: 'Conteúdo da comunicação'
  },
  recipientComplement: {
    zh: '收件人地址补充',
    en: '',
    pu: 'Complemento do endereço do destinatário'
  },
  sendderComplement: {
    zh: '发件人地址补充',
    en: '',
    pu: 'Complemento do endereço do remetente'
  },
  ManualPush: {
    zh: '手动推送',
    en: 'Manual push',
    pu: 'Empurrão manual'
  },
  CurrencyCode: {
    zh: '货币代码',
    en: 'Currency Code',
    pu: 'Código da moeda'
  },
  ChineseName: {
    zh: '中文名称',
    en: 'Chinese name',
    pu: 'Nome chinês'
  },
  DefaultExchangeRate: {
    zh: '默认汇率',
    en: 'Default exchange rate',
    pu: 'Taxa de câmbio padrão'
  },
  LatestModifiedBy: {
    zh: '最新修改人',
    en: 'Latest modifier',
    pu: 'Último modificador'
  },
  LastModified: {
    zh: '最新修改时间',
    en: 'Latest revision time',
    pu: 'Hora da última revisão'
  },
  CharactersCanOnlyBe: {
    zh: '字符长度只能为',
    en: 'Character length can only be',
    pu: 'O comprimento do caractere pode ser de apenas'
  },
  digitsLong: {
    zh: '位',
    en: 'bits',
    pu: 'bits'
  },
  MustBeLessThan: {
    zh: '必须小于',
    en: 'Must be less than',
    pu: 'Deve ser menor a'
  },
  TypeRecipient: {
    zh: '签收人类型',
    en: '',
    pu: 'Tipo do destinatário'
  },
  RecipientDOC: {
    zh: '签收人DOC',
    en: '',
    pu: 'DOC do destinatário'
  },
  SigningTime: {
    zh: '签收时间',
    en: '',
    pu: 'Hora da assinatura'
  },
  SignerName: {
    zh: '签收人人名',
    en: '',
    pu: 'Nome do destinatário'
  },
  Myself: {
    zh: '本人',
    en: '',
    pu: 'Eu mesmo'
  },
  NotMyself: {
    zh: '非本人',
    en: '',
    pu: 'Não eu mesmo'
  },
  see: {
    zh: '查看',
    en: '',
    pu: 'Ver'
  },
  packageVlaue: {
    zh: '包裹价值(BRL)',
    en: '',
    pu: 'Valor do Pacote (BRL)'
  },
  kaipiaojine: {
    zh: '开票金额(BRL)',
    en: '',
    pu: 'Valor da Fatura (BRL)'
  },
  xiaoshou: {
    zh: '销售订单号',
    en: '',
    pu: 'Número do pedido de venda'
  },
  xiadanzhongliang: {
    zh: '下单重量',
    en: '',
    pu: 'Peso do pedido'
  },
  shijijiazhi: {
    zh: '包裹商品发票实际价值',
    en: '',
    pu: 'Valor real das faturas de produtos de pacote'
  },
  qinashouInfo: {
    zh: '签收信息',
    en: '',
    pu: 'Informações de assinatura'
  },
  fuwushang: {
    zh: '服务商',
    en: '',
    pu: 'Fornecedor'
  },
  jushou: {
    zh: '包裹被拒收,投递失败后是否返回卖家',
    en: '',
    pu: 'Se o pacote é devolvido ao vendedor após ser rejeitado ou falha na entrega'
  },
  wentijian: {
    zh: '问题件原因',
    en: '',
    pu: 'Motivo do problema'
  },
  BasecodeTip: {
    zh: '预派送网点对应的Basecode',
    en: '',
    pu: 'Basecode correspondente aos pontos de pré-entrega'
  },
  closingTime: {
    zh: '封车时间',
    en: 'Closing time',
    pu: 'Hora de fechamento'
  },
  personInChargeOfClosing: {
    zh: '封车人',
    en: 'Person in charge of closing',
    pu: 'Responsável pelo fechamento'
  },
  BagNumberList: {
    zh: '袋号清单',
    en: 'Bag number list',
    pu: 'Lista de números de bolsas '
  },
  successfuConnection: {
    zh: '连接成功',
    en: '',
    pu: 'Conexão bem-sucedida'
  },
  Type: {
    zh: '类型',
    en: 'Type',
    pu: 'Tipo'
  },
  details: {
    zh: '详情',
    en: 'details',
    pu: 'Detalhes'
  },
  GridSlogan: {
    zh: '格口号',
    en: 'Grid slogan',
    pu: 'Slogan de grade'
  },
  Istransportationcompany: {
    zh: '是否为运输公司',
    en: '',
    pu: 'Se for a empresa de transporte'
  },
  ProcessingStatus: {
    zh: '处理状态',
    en: 'Processing status',
    pu: 'Status do processamento'
  },
  CancellationOutletDelivery: {
    zh: '取消-网点派送',
    en: 'Cancellation - Outlet delivery',
    pu: 'Cancelamento - Entrega em outlet'
  },
  TheOperationSucceededTime: {
    zh: '操作成功时间',
    en: 'The time to success of the operation',
    pu: 'A hora do sucesso da operação'
  },
  RetryManually: {
    zh: '手动重试',
    en: 'Retry manually',
    pu: 'Repetir manualmente'
  },
  CancelOrderRetry: {
    zh: '取消订单重试',
    en: 'Cancel the order and retry',
    pu: 'Cancelar o pedido e tentar novamente'
  },
  cancelOrderSure: {
    zh: '确定取消订单吗？',
    en: 'Are you sure you want to cancel your order?',
    pu: 'Tem a certeza de que pretende cancelar a sua encomenda?'
  },
  FailureLink: {
    zh: '失败环节',
    en: 'Failure link',
    pu: 'Link de falha'
  },
  anjun: {
    zh: '安骏',
    en: 'Anjun',
    pu: 'Anjun'
  },
  DeliveryOutlets: {
    zh: '派送网点',
    en: '',
    pu: 'Ponto de entrega'
  },
  SortingIf: {
    zh: '分拣条件',
    en: '',
    pu: 'condição de classificação'
  },
  provideSiteName: {
    zh: '供应商 > 网点',
    en: '',
    pu: 'Fornecedor > outlet'
  },
  Withdrawn: {
    zh: '退件中',
    en: 'Withdrawn',
    pu: 'Em processo de devolução'
  },
  TheReturnComplete: {
    zh: '退件签收',
    en: 'The return is complete',
    pu: 'Recebido de devolução'
  },
  ReverseTrackingNumber: {
    zh: '逆向单号',
    en: 'Reverse tracking number',
    pu: 'Nº de reverso'
  },
  SupplierWhoPlacedOrder: {
    zh: '下单供应商',
    en: 'Supplier who placed the order',
    pu: 'Fornecedor original de entrega'
  },
  OrderOutlets: {
    zh: '下单网点',
    en: 'Order outlets',
    pu: 'Ponto original  de entrega'
  },
  AssociateForwardTrackingNumber: {
    zh: '关联正向运单号',
    en: 'Associate a forward tracking number',
    pu: 'Liga com o pedido original'
  },
  AssociateTrackingNumber: {
    zh: '关联正向服务商单号',
    en: 'Associate the tracking number of the forward service provider',
    pu: 'Vincular o número de fornecedor de direto'
  },
  TheTimeWhenTheReverseOrderWasCreated: {
    zh: '逆向订单创建时间',
    en: 'The time when the reverse order was created',
    pu: 'Tempo de criação de pedido reverso'
  },
  TheForwardOrderCreationTime: {
    zh: '正向运单创建时间',
    en: 'The forward order creation time',
    pu: 'Tempo de criação de pedido original'
  },
  TheReasonForTheReturn: {
    zh: '退件原因',
    en: 'The reason for the return',
    pu: 'Motivo de reversa'
  },
  Only10AreDisplayed: {
    zh: '只显示10条，可搜索',
    en: 'Only 10 are displayed and can be searched',
    pu: 'Mostrar apenas 10 mensagens, pesquisáveis'
  },
  weigh: {
    zh: '称重',
    en: 'weigh',
    pu: 'pesar'
  },
  NumberPreDeliveryOutlet: {
    zh: '预派送网点编号',
    en: 'The number of the pre-delivery outlet',
    pu: 'O número da tomada de pré-entrega'
  },
  ModifyPreDeliveryLocation: {
    zh: '修改预派送网点',
    en: 'Modify a pre-delivery location',
    pu: 'Modificar um local de pré-entrega'
  },
  NumberPackagesReceivedAdvance: {
    zh: '预接收包裹数',
    en: 'Number of packages received in advance',
    pu: 'Volume de pacotes a ser recebidos'
  },
  PleaseScanTrackingNumber: {
    zh: '请扫描运单号',
    en: 'Please scan the tracking number',
    pu: 'Bipe o Nº de rastreio, por favor'
  },
  TheNumberInboundScans: {
    zh: '入库扫描数',
    en: 'The number of inbound scans',
    pu: 'Volume de bipe de inbound'
  },
  TheNumberPackagesDelivered: {
    zh: '待派送包裹数',
    en: 'The number of packages to be delivered',
    pu: 'Volume de pacotes a ser entregados'
  },
  TheNumberPackagesDelivery: {
    zh: '派件中包裹数',
    en: 'The number of packages in the delivery',
    pu: 'Volume de pacotes estão entregando'
  },
  NumberPackagesSigned: {
    zh: '签收包裹数',
    en: 'Number of packages signed',
    pu: 'Volume de pacotes entregados'
  },
  NumberDeliveryFailures: {
    zh: '投递失败数',
    en: 'Number of delivery failures',
    pu: 'Qtd. de pacotes falhados ao entregar'
  },
  NumberAbnormalPackages: {
    zh: '异常包裹数',
    en: 'Number of abnormal packages',
    pu: 'Volume de anormal'
  },
  NumberPackagesReturned: {
    zh: '待退回包裹数',
    en: 'Number of packages to be returned',
    pu: 'Volume de pacote a ser reversos'
  },
  sum: {
    zh: '合计',
    en: '',
    pu: 'Somar o total'
  },
  scannerRate: {
    zh: '扫描率',
    en: '',
    pu: 'Taxa de bipe'
  },
  dotScanRate: {
    zh: '网点扫描率',
    en: '',
    pu: 'Taxa de bipe de ponto'
  },
  queried200pieces: {
    zh: '每次最多查询200条数据',
    en: 'Up to 200 pieces of data can be queried at a time',
    pu: 'Até 200 dados podem ser consultados de cada vez'
  },
  update: {
    zh: '更新',
    en: 'update',
    pu: 'Atualização'
  },
  Print: {
    zh: '打印',
    en: '',
    pu: 'Imprimir'
  },
  totalWeightKG: {
    zh: '总重量（KG）',
    en: 'Total Weight (KG)',
    pu: 'Peso Total (KG)'
  },
  TheInboundOrderNumber: {
    zh: '入库单号',
    en: 'The inbound order number',
    pu: 'Codigo de entrada (codigo que foi escaneado na entrada de armazém)'
  },
  system: {
    zh: '系统',
    en: '',
    pu: 'Sistemas'
  },
  Collect: {
    zh: '揽收',
    en: '',
    pu: 'Coleta'
  },
  Acquiesce: {
    zh: '默认',
    en: '',
    pu: 'Configuração padrão'
  },
  TheDateTransferWarehouse: {
    zh: '转运出库日期',
    en: 'The date of the transfer out of the warehouse',
    pu: 'Data de Saída do Armazém de Transferência'
  },
  FranchiseeNameDo: {
    zh: '加盟商名称',
    en: 'Franchisee name',
    pu: 'Nome de Parceiro'
  },
  TheTotalNumberOutboundVotes: {
    zh: '出库总票数',
    en: 'The total number of outbound votes',
    pu: 'Quantidade Total de Pacotes Saídos'
  },
  AmountOfReceipt: {
    zh: '签收量',
    en: 'Amount of receipt',
    pu: 'quantidade de entrega'
  },
  SignOffRate: {
    zh: '签收率',
    en: 'Sign-off rate',
    pu: 'percentagem de entrega'
  },
  ReasonableInvestmentRate: {
    zh: '妥投率',
    en: 'Reasonable investment rate',
    pu: 'Percentagem de entrega'
  },
  NumberOfUnvalidatedVotes: {
    zh: '未妥投票数',
    en: 'Number of unvalidated votes',
    pu: 'Quantidade de não entregado'
  },
  DateDimension: {
    zh: '日期维度',
    en: 'Date dimension',
    pu: 'Dimensão de Data'
  },
  DotBack: {
    zh: '网点退回',
    en: '',
    pu: 'Devolvido pelo Ponto'
  },
  ReturnToStorage: {
    zh: '退仓到件',
    en: '',
    pu: 'Devolvido e armazenado'
  },
  zhengxiang: {
    zh: '正向签收',
    en: '',
    pu: 'Entrega'
  },
  nixiang: {
    zh: '逆向签收',
    en: '',
    pu: 'Entrega de reverso'
  },
  ReturnTime: {
    zh: '退件时间',
    en: 'Return time',
    pu: 'Horário de retorno'
  },
  moduan: {
    zh: '末端已揽收',
    en: '',
    pu: 'Objeto coletado em LM'
  },
  preProvider: {
    zh: '预派送供应商',
    en: '',
    pu: 'Fornecedor previsto de entrega'
  },
  Provider: {
    zh: '实际派送供应商',
    en: '',
    pu: 'Fornecedor atual de entrega'
  },
  TheNameOfTheDevice: {
    zh: '设备名称',
    en: 'The name of the device',
    pu: 'Nome de equipamento'
  },
  DeviceNumber: {
    zh: '设备编号',
    en: 'Device number',
    pu: 'nº de equipamento'
  },
  TheTransitWarehouseWhereLocated: {
    zh: '所在中转仓',
    en: 'The transit warehouse where it is located',
    pu: 'Armazém de transferência pertencentes a'
  },
  DeviceStatus: {
    zh: '设备状态',
    en: 'Device status',
    pu: 'statis de equipamento'
  },
  AddNewDevice: {
    zh: '新增设备',
    en: 'Add a new device',
    pu: 'addcionar equipamento'
  },
  deviceName: {
    zh: '设备名称',
    en: '',
    pu: 'Nome de equipamento'
  },
  not3000str: {
    zh: '文本内容不能超过3000个字符串!',
    en: '',
    pu: 'O texto não pode ficar superior a 3000 carácteres!'
  },
  notInputImage: {
    zh: '内容不能为图片等其他格式,请重新输入!',
    en: '',
    pu: 'O conteúdo não pode ser imagem ou outro formato, re-insira, por favor!'
  },
  fixaddress: {
    zh: '修改收件地址',
    en: '',
    pu: 'Alterar o endereço da entrega'
  },
  fixTotal: {
    zh: '修改总数',
    en: '',
    pu: 'Totalidade de modificações'
  },
  failTotal: {
    zh: '修改失败',
    en: '',
    pu: 'Falha na modificação'
  },
  notFix: {
    zh: '以下状态的单号不可进行修改',
    en: '',
    pu: 'Os números de pedido com os seguintes estados não podem ser modificados'
  },
  infeatSite: {
    zh: '实际入库网点',
    en: '',
    pu: 'Ponto atual de inbound'
  },
  entradaProvider: {
    zh: '实际入库供应商',
    en: '',
    pu: 'Fornecedor de entrada real'
  },
  storageOutlets: {
    zh: '实际入库网点',
    en: '',
    pu: 'Ponto atual de inbound'
  },
  senderTime: {
    zh: '发件时间',
    en: '',
    pu: 'Hora do saída'
  },
  nextStation: {
    zh: '下一站',
    en: '',
    pu: 'Próxima paragem'
  },
  sealNumber: {
    zh: '封签号',
    en: '',
    pu: 'Número de lacre'
  },
  sealVehicleTime: {
    zh: '封车时间',
    en: '',
    pu: 'Tempo de Fechamento do veículo'
  },
  unsealVehicleTime: {
    zh: '解封车时间',
    en: '',
    pu: 'Tempo de Desbloquear o veículo'
  },
  isArrive: {
    zh: '是否到达',
    en: '',
    pu: 'Chega ou não'
  },
  selfSupport: {
    zh: '自营',
    en: '',
    pu: 'Empresa própria'
  },
  accountType: {
    zh: '账号类型',
    en: '',
    pu: 'Tipo da conta'
  },
  three: {
    zh: '第三方',
    en: '',
    pu: 'Terceira parte'
  },
  ortherChannl: {
    zh: '其他渠道',
    en: '',
    pu: 'Outros canais'
  },
  swept: {
    zh: '已扫',
    en: '',
    pu: 'Já bipado'
  },
  Upload: {
    zh: '上传',
    en: '',
    pu: 'Upload'
  },
  notDataUpload: {
    zh: '暂无数据上传',
    en: '',
    pu: 'Não há dados para carregar'
  },
  scannerBig: {
    zh: '扫描包裹/大包号',
    en: '',
    pu: 'Bipar Nº de pacote / Saca'
  },
  notrepeatOperation: {
    zh: '请勿重复操作',
    en: '',
    pu: 'Não repita a operação'
  },
  OperationState: {
    zh: '操作人州',
    en: '',
    pu: 'Estado de operador'
  },
  OperationSite: {
    zh: '操作人网点',
    en: '',
    pu: 'Ponto de operador'
  },
  changePasswordMonth: {
    zh: '您的账号已超过1个月未修改密码，为了您的账号安全，请先修改密码！',
    en: '',
    pu: 'Sua conta não alterou a senha por mais de um mês. Altere a senha primeiro para a segurança da sua conta!'
  },
  bagNumberLimit: {
    zh: '袋号只能输入6-8位数字',
    en: '',
    pu: 'O nº de saca só pode conter 6-8 dígitos'
  },
  bagNumberLimitNew: {
    zh: '袋号只能输入6-12位',
    en: '',
    pu: 'O nº de saca só pode conter 6-12 dígitos'
  },
  arrive: {
    zh: '到件',
    en: '',
    pu: 'Entrada'
  },
  sender: {
    zh: '发件',
    en: '',
    pu: 'Saída'
  },
  selectTime: {
    zh: '请选择时间',
    en: '',
    pu: 'Selecione um horário'
  },
  storageOutletsState: {
    zh: '实际入库网点州',
    en: '',
    pu: 'Estado do ponto entrada real'
  },
  oneTransitInboundTime: {
    zh: '一级分拨到件时间',
    en: '',
    pu: 'Horário de entrada do centro de distribuição nível 01'
  },
  oneshipmentTime: {
    zh: '一级分拨发件时间',
    en: '',
    pu: 'Horário de saída do centro de distribuição nível 01'
  },
  twoTransitInboundTime: {
    zh: '二级分拨到件时间',
    en: '',
    pu: 'Horário de entrada do centro de distribuição nível 02'
  },
  threeTransitInboundTime: {
    zh: '三级分拨到件时间',
    en: '',
    pu: 'Horário de entrada do centro de distribuição nível 03'
  },
  twoshipmentTime: {
    zh: '二级分拨发件时间',
    en: '',
    pu: 'Horário de saída do centro de distribuição nível 02'
  },
  threeshipmentTime: {
    zh: '三级分拨发件时间',
    en: '',
    pu: 'Horário de saída do centro de distribuição nível 03'
  },
  ActualDistributionCenter: {
    zh: '实际到件分拨中心',
    en: '',
    pu: 'Chegada ao centro de distribuição'
  },
  ExportSuccessful1: {
    zh: '导出成功',
    en: 'Export successful',
    pu: 'Exportação bem-sucedida'
  },
  signType: {
    zh: '签收类型',
    en: '',
    pu: 'Tipo de entregue'
  },
  mutilSign: {
    zh: '多次签收',
    en: '',
    pu: 'Assinação o pacote várias vezes'
  },
  liyingsongdaCenter: {
    zh: '理应送达分拨中心',
    en: '',
    pu: 'Centro de distribuição que deve ser entregue'
  },
  shijidaodaCenter: {
    zh: '实际到件分拨中心',
    en: '',
    pu: 'Centro de distribuição que entrada atual'
  },
  register: {
    zh: '登记',
    en: '',
    pu: 'register'
  },
  noPrintedData: {
    zh: '暂无打印面单数据',
    en: '',
    pu: 'Não há dados de etiquetas para imprimir'
  },
  ExportingList: {
    zh: '导出列表信息',
    en: '',
    pu: 'Exportar informações da lista'
  },
  ExportReceipt: {
    zh: '导出签收信息',
    en: '',
    pu: 'Exportar informações de recebimento'
  },
  ExportSubPackage: {
    zh: '导出子包信息',
    en: '',
    pu: 'Exportar informações de subembalagem'
  },
  ExportSecondLatestTrack: {
    zh: '次新轨迹导出',
    en: '',
    pu: 'Exportar trajectórias secundárias'
  },
  notMore10w: {
    zh: '单次导出不可超过10万条数据',
    en: '',
    pu: 'Cada exportação não pode exceder 100,000 registos'
  },
  notMore10wTip: {
    zh: '请输入正确的单号',
    en: '',
    pu: 'Por favor, insira o número de referência correto'
  },
  contactEmail: {
    zh: '联系人邮箱',
    en: '',
    pu: 'Correio de contato'
  },
  doYouWantToContinue: {
    zh: '此操作不可撤销，是否继续',
    en: '',
    pu: 'A operação é irrevesével, se continuar?'
  },
  scannerNumber: {
    zh: '扫描运单号',
    en: '',
    pu: 'Escaneie o número de rastreamento'
  },
  processSettlement: {
    zh: '理赔中',
    en: '',
    pu: 'Em processo de reclamação'
  },
  ClaimsCompleted: {
    zh: '理赔完成',
    en: '',
    pu: 'Reclamação concluída'
  },
  failRecord: {
    zh: '失败记录',
    en: '',
    pu: 'Registro de falha'
  },
  ModificationTime: {
    zh: '修改时间',
    en: '',
    pu: 'Tempo de modificação'
  },
  packageStatus: {
    zh: '包裹状态',
    en: '',
    pu: 'Status do pacote'
  },
  provider: {
    zh: '服务商',
    en: '',
    pu: 'Parceiro'
  },
  statusFenjian: {
    zh: '状态分拣',
    en: '',
    pu: 'Classificação de estado'
  },
  ThreeSortingFailures: {
    zh: '三次分拣失败',
    en: '',
    pu: 'Falha na terceira classificação'
  },
  zipCodeError: {
    zh: '邮编输入不正确',
    en: '',
    pu: 'Entrada incorreta do código postal'
  },
  newChildRule: {
    zh: '新增子规则',
    en: '',
    pu: 'Adiciona  sub-regra'
  },
  autoPrint: {
    zh: '自动打印',
    en: '',
    pu: 'Impressão automática'
  },
  mualPrint: {
    zh: '手动打印',
    en: '',
    pu: 'Impressão manual'
  },
  transportCenter: {
    zh: '上级转运中心',
    en: '',
    pu: 'Centro de trânsito superior'
  },
  sysNameInfo: {
    zh: '系统消息',
    en: '',
    pu: 'Informação de sistema'
  },
  notTip: {
    zh: '不再显示',
    en: '',
    pu: 'Não estou mais exibindo'
  },
  updateTip: {
    zh: '更新提示',
    en: '',
    pu: 'Dica de renovação'
  },
  info: {
    zh: '通知',
    en: '',
    pu: 'Notificações'
  },
  sysMessage: {
    zh: '系统消息',
    en: '',
    pu: 'Informação de sistema'
  },
  settlementType: {
    zh: '结算类型',
    en: '',
    pu: 'Tipo de liquidação'
  },
  formatFile: {
    zh: '请上传图片格式的文件(jpg、jpeg、png、gif)',
    en: '',
    pu: 'Faca um upload de documento na forma de foto(jpg, jpeg, png, gif)'
  },
  failUpload: {
    zh: '上传失败',
    en: '',
    pu: 'Falha no upload'
  },
  UploadPictures: {
    zh: '上传图片',
    en: 'Upload pictures',
    pu: 'Tirar foto'
  },
  beforeOperation: {
    zh: '操作前',
    en: '',
    pu: 'Pré-operacional'
  },
  afterOperation: {
    zh: '操作后',
    en: '',
    pu: 'Pós-operacional'
  },
  requirement: {
    zh: '需求类型',
    en: '',
    pu: 'Tipo de requisito'
  },
  MaterialRequisition: {
    zh: '物料申领',
    en: '',
    pu: 'Requisição de material'
  },
  ComplaintsSuggestions: {
    zh: '投诉建议',
    en: '',
    pu: 'Reclamações e sugestões'
  },
  developmentRequirements: {
    zh: '系统开发需求',
    en: '',
    pu: 'Requisitos de desenvolver sistema'
  },
  messageTime: {
    zh: '留言时间',
    en: '',
    pu: 'Tempo da mensagem'
  },
  newMessageTime: {
    zh: '最新留言时间',
    en: '',
    pu: 'Hora da última mensagem'
  },
  newReplyTime: {
    zh: '最新回复时间',
    en: '',
    pu: 'Tempo de resposta mais recente'
  },
  Ongoing: {
    zh: '进行中',
    en: '',
    pu: 'Em direção a'
  },
  Message: {
    zh: '留言内容',
    en: '',
    pu: 'Mensagem'
  },
  Reply: {
    zh: '回复',
    en: '',
    pu: 'Retornar'
  },
  HasReply: {
    zh: '是否有最新回复',
    en: '',
    pu: 'Existe uma resposta atualizada'
  },
  userName: {
    zh: '账号名称',
    en: '',
    pu: 'Nome da conta'
  },
  Operator: {
    zh: '处理人',
    en: '',
    pu: 'processadores'
  },
  confirmClose: {
    zh: '关闭后无法再进行回复，确定关闭吗?',
    en: '',
    pu: 'Não consigo mais responder depois de fechá-la. Tem certeza de que deseja fechá-la?'
  },
  Confirm: {
    zh: '确定',
    en: '',
    pu: 'Definir'
  },
  OnlyAreDisplayed: {
    zh: '可搜索',
    en: '',
    pu: 'Pesquisável'
  },
  Total: {
    zh: '总计',
    en: '',
    pu: 'Total'
  },
  moreDataDetail: {
    zh: '更多数据请导出全部明细',
    en: '',
    pu: 'Mais dados disponíveis, exporte todos os detalhes'
  },
  collectSealVehicleTime: {
    zh: '揽收封车时间',
    en: '',
    pu: 'Tempo de coleta de carga'
  },
  firstUnsealVehicleTime: {
    zh: 'HUB1解封车时间',
    en: '',
    pu: 'Tempo de desbloqueio do HUB1'
  },
  transportDuration: {
    zh: '运输时长(H)',
    en: '',
    pu: 'Duração do transporte (H)'
  },
  firstSealVehicleTime: {
    zh: 'HUB1封车时间',
    en: '',
    pu: 'Tempo de bloqueio do HUB1'
  },
  secondUnsealVehicleTime: {
    zh: 'HUB2解封车时间',
    en: '',
    pu: 'Tempo de desbloqueio do HUB2'
  },
  secondSealVehicleTime: {
    zh: 'HUB2封车时间',
    en: '',
    pu: 'Tempo de bloqueio do HUB2'
  },
  thirdUnsealVehicleTime: {
    zh: 'HUB3解封车时间',
    en: '',
    pu: 'Tempo de desbloqueio do HUB3'
  },
  thirdSealVehicleTime: {
    zh: 'HUB3封车时间',
    en: '',
    pu: 'Tempo de bloqueio do HUB3'
  },
  siteUnsealVehicleTime: {
    zh: '网点解封车时间',
    en: '',
    pu: 'Tempo de desbloqueio do ponto'
  },
  tip_firstTransportDuration: {
    zh: 'HUB1解封车时间减去揽收封车时间',
    en: '',
    pu: 'Tempo de desbloqueio do HUB1 menos tempo de bloqueio de coleta'
  },
  tip_secondTransportDuration: {
    zh: 'HUB2解封车时间减去HUB1封车时间',
    en: '',
    pu: 'Tempo de desbloqueio do HUB2 menos tempo de bloqueio do HUB1'
  },
  tip_thirdTransportDuration: {
    zh: 'HUB3解封车时间减去HUB2封车时间',
    en: '',
    pu: 'Tempo de desbloqueio do HUB3 menos tempo de bloqueio do HUB2'
  },
  tip_transportDuration: {
    zh: '网点解封车时间减去HUB3封车时间',
    en: '',
    pu: 'Tempo de desbloqueio do ponto menos tempo de bloqueio do HUB3'
  },
  downloadImportTemplate: {
    zh: '下载导入模板',
    en: '',
    pu: 'Baixar modelo de importação'
  },
  confirmClosure: {
    zh: '确认关闭',
    en: '',
    pu: 'Confirmar fechamento'
  },
  submittedSuccessfully: {
    zh: '提交成功',
    en: '',
    pu: 'Enviado com sucesso'
  },
  endTimeMustBeLarger: {
    zh: '不能小于开始时间',
    en: '',
    pu: 'Não pode ser menor que o tempo de início'
  },
  CannotExceedTheEndTime: {
    zh: '不能大于结束时间',
    en: 'Cannot exceed the end time',
    pu: 'Não pode ser maior que o tempo final'
  },
  TheStateOperatorOutlet: {
    zh: '操作人网点所属州',
    en: '',
    pu: 'Estado ao qual pertence o ponto de operação'
  },
  LoadMore: {
    zh: '加载更多',
    en: '',
    pu: 'Carregar mais'
  },
  LatestTrajectoryState: {
    zh: '最新轨迹州',
    en: '',
    pu: 'Último estado de rastreamento'
  },
  UpdateDistance: {
    zh: '更新距离',
    en: 'Update distance',
    pu: 'Atualizar Distância'
  },
  departurePointSite: {
    zh: '出发地网点',
    en: '',
    pu: 'Ponto de Partida'
  },
  departurePointBranchAddress: {
    zh: '出发地网点地址',
    en: 'Departure point branch address',
    pu: 'Endereço do Ponto de Partida'
  },
  DepartureLatitudeAndLongitude: {
    zh: '出发地经纬度',
    en: 'Departure latitude and longitude',
    pu: 'Latitude e Longitude do Ponto de Partida'
  },
  destinationPointSite: {
    zh: '目的地网点',
    en: 'Destination station',
    pu: 'Ponto de Destino'
  },
  destinationPointBranchAddress: {
    zh: '目的地网点地址',
    en: 'Destination branch address',
    pu: 'Endereço do Ponto de Destino'
  },
  DestinationLatitudeAndLongitude: {
    zh: '目的地经纬度',
    en: 'Destination latitude and longitude',
    pu: 'Latitude e Longitude do Ponto de Destino'
  },
  StartingStation: {
    zh: '始发网点',
    en: 'Starting station',
    pu: 'Ponto de Origem'
  },
  DistanceDestination: {
    zh: '始发地-目的地距离',
    en: 'Distance from origin to destination',
    pu: 'Distância entre o Ponto de Origem e o Destino'
  },
  RouteNumber1: {
    zh: '路线编号',
    en: 'Route number',
    pu: 'Número da Rota'
  },
  StartingStation1: {
    zh: '始发地网点',
    en: 'Starting station',
    pu: 'Ponto de Origem'
  },
  StartingStationBranchAddress: {
    zh: '始发地网点地址',
    en: 'Starting station branch address',
    pu: 'Endereço do Ponto de Origem'
  },
  StartingStationBranchLatitudeAndLongitude: {
    zh: '始发地网点经纬度',
    en: 'Starting station branch latitude and longitude',
    pu: 'Latitude e Longitude do Ponto de Origem'
  },
  ImportRouteReference: {
    zh: '导入路线参照',
    en: 'Import route reference',
    pu: 'Importar referência de rota'
  },
  ThisTemplateCannotBeChanged: {
    zh: '以上为一个路线例子，所有都必须填，从第6行开始填写数据，不可更改此模板',
    en: 'The above is an example of a route, all must be filled in, starting from the 6th line, and this template cannot be changed',
    pu: 'Este é um exemplo de rota; todos os campos devem ser preenchidos. Comece a inserir os dados na linha 6, sem modificar o modelo'
  },
  confirmToUpdateTheSelectedRouteDistance: {
    zh: '确定要更新已选路线距离？',
    en: 'Confirm to update the selected route distance',
    pu: 'Confirma atualizar a distância da rota selecionada?'
  },
  DestinationStation: {
    zh: '目的网点',
    en: 'Destination station',
    pu: 'Ponto de Destino'
  },
  Kilometers: {
    zh: '公里数',
    en: 'Kilometers',
    pu: 'Quilômetros'
  },
  TransportationFee: {
    zh: '运输费',
    en: 'Transportation fee',
    pu: 'Taxa de transporte'
  },
  WeightVolumeMultiplePackages: {
    zh: '多件包裹重量体积',
    en: 'Weight and volume of multiple packages',
    pu: 'Peso e volume de pacotes'
  },
  TotalNumberOfPackages: {
    zh: '包裹总件数',
    en: 'Total number of packages',
    pu: 'Número total de pacotes'
  },
  packageVolume: {
    zh: '包裹体积',
    en: 'Package volume',
    pu: 'Volume do pacote'
  },
  SingleWeight: {
    zh: '单件重量',
    en: 'Single weight',
    pu: 'Peso unitário'
  },
  SingleVolume: {
    zh: '单件体积',
    en: 'Single volume',
    pu: 'Volume unitário'
  },
  AddOtherSpecifications: {
    zh: '添加其他规格',
    en: 'Add other specifications',
    pu: 'Adicionar outras especificações'
  },
  PackageTotalWeight: {
    zh: '包裹总体积',
    en: 'Package total weight',
    pu: 'Volume total do pacotes'
  },
  AddDetailedWeightAndVolume: {
    zh: '补充详细重量体积',
    en: 'Add detailed weight and volume',
    pu: 'Adicionar detalhes de peso e volume'
  },
  financialProcessing: {
    zh: '财务处理中',
    en: '',
    pu: 'Processo financeiro em andamento'
  },
  financialClosedLoop: {
    zh: '财务闭环',
    en: '',
    pu: 'Ciclo financeiro fechado'
  },
  bankAccount1: {
    zh: '银行账户',
    en: 'bank account',
    pu: 'Conta bancária'
  },
  settlementAccount: {
    zh: '结算账号',
    en: 'settlement account',
    pu: 'Conta de pagamento'
  },
  businessCode: {
    zh: '商家编码',
    en: '',
    pu: 'Código do Comerciante'
  },
  businessName: {
    zh: '商家名称',
    en: '',
    pu: 'Nome da Loja'
  },
  signRelation: {
    zh: '签收人关系',
    en: '',
    pu: 'Relação do Destinatário'
  },
  DIr: {
    zh: 'DIR文件',
    en: '',
    pu: 'Arquivo DIR'
  },
  exportDIR: {
    zh: '导出DIR文件',
    en: '',
    pu: 'Exporta Arquivo DIR'
  },
  toWillyNumber: {
    zh: '查看物流订单',
    en: '',
    pu: 'Ver Pedido Logístico'
  },
  PleaseEnterTheWaybillNumber: {
    zh: '请输入运单号，多个运单号换行输入',
    en: 'Please enter the waybill number, multiple waybill numbers should be entered with line breaks',
    pu: 'Insira o número da encomenda, insira vários números de encomenda com uma quebra de linha'
  },
  OperationDesk: {
    zh: '操作台',
    en: "Operator's Console",
    pu: 'Mesa de - comando'
  },
  pushMode: {
    zh: '推送方式',
    en: '',
    pu: 'Método de Envio'
  },
  sms: {
    zh: 'SMS短信',
    en: '',
    pu: 'SMS'
  },
  IReturned: {
    zh: '我返回的',
    en: 'I returned',
    pu: 'Retornou'
  },
  IRecycled: {
    zh: '我回收的',
    en: 'I recycled',
    pu: 'Reciclou'
  },
  ReceivingDetails: {
    zh: '领用明细',
    en: 'Receiving Details',
    pu: 'Detalhes da Recebimento'
  },
  AdjustmentDetails: {
    zh: '调整明细',
    en: 'Adjustment Details',
    pu: 'Detalhes da Ajuste'
  },
  OutflowDetails: {
    zh: '流出明细',
    en: 'Outflow Details',
    pu: 'Detalhes da Saída'
  },
  InflowDetails: {
    zh: '流入明细',
    en: 'Inflow Details',
    pu: 'Detalhes da Entrada'
  },
  WaybillNumber: {
    zh: '运单编号',
    en: 'Waybill Number',
    pu: 'Número da Encomenda'
  },
  Confirmed: {
    zh: '已确认',
    en: 'Confirmed',
    pu: 'Confirmado'
  },
  Unconfirmed: {
    zh: '未确认',
    en: 'Unconfirmed',
    pu: 'Não Confirmado'
  },
  Printed: {
    zh: '已打印',
    en: 'Printed',
    pu: 'Impresso'
  },
  NotPrinted: {
    zh: '未打印',
    en: 'Not Printed',
    pu: 'Não Impresso'
  },
  RecyclingConfirmation: {
    zh: '回收确认',
    en: 'Recycling Confirmation',
    pu: 'Confirmação de Reciclagem'
  },
  PrintWaybill: {
    zh: '打印运单',
    en: 'Print waybill',
    pu: 'Imprimir Encomenda'
  },
  ReturnWaybillNumber: {
    zh: '返回运单号',
    en: 'Return waybill number',
    pu: 'Número da Encomenda'
  },
  ReturningBranch: {
    zh: '返回网点',
    en: 'Returning branch',
    pu: 'Ponto de Retorno'
  },
  ReceivingBranch: {
    zh: '接收网点',
    en: 'Receiving branch',
    pu: 'Ponto de Recebimento'
  },
  ExpectedReturnQuantity: {
    zh: '应返回数量',
    en: 'Expected return quantity',
    pu: 'Quantidade Esperada'
  },
  ConfirmReturnQuantity: {
    zh: '确认返回数量',
    en: 'Confirm return quantity',
    pu: 'Confirma a quantidade de retorno'
  },
  RecyclingStatus: {
    zh: '回收状态',
    en: 'Recycling status',
    pu: 'Status de Reciclagem'
  },
  Returner: {
    zh: '返回人',
    en: 'Returner',
    pu: 'Retornador'
  },
  ReturnDate: {
    zh: '返回时间',
    en: 'Return time',
    pu: 'Data de Retorno'
  },
  WaybillPrintingStatus: {
    zh: '运单打印状态',
    en: 'Waybill printing status',
    pu: 'Status de Impressão da Encomenda'
  },
  ReceivingBill: {
    zh: '领用单据',
    en: 'Receiving bill',
    pu: 'Nota de Recebimento'
  },
  ReceivingBranchDe: {
    zh: '领用网点',
    en: 'Receiving branch',
    pu: 'Ponto de Recebimento'
  },
  ReceivingQuantity: {
    zh: '领用数量',
    en: 'Receiving quantity',
    pu: 'Quantidade Recebida'
  },
  ReceivingReasons: {
    zh: '领用事由',
    en: 'Receiving reasons',
    pu: 'Motivo da Recebimento'
  },
  Receiver: {
    zh: '领用人',
    en: 'Receiver',
    pu: 'Recebedor'
  },
  Giver: {
    zh: '发放人',
    en: 'Giver',
    pu: 'Remetente'
  },
  GivingDate: {
    zh: '发放日期',
    en: 'Giving date',
    pu: 'Data de Emissão'
  },
  DocumentNumber: {
    zh: '单据号',
    en: 'Document number',
    pu: 'Número do Documento'
  },
  AdjustedBranch: {
    zh: '被调整网点',
    en: 'Adjusted branch',
    pu: 'Ponto de Recebimento'
  },
  AdjustedQuantity: {
    zh: '调整数量',
    en: 'Adjusted quantity',
    pu: 'Quantidade Ajustada'
  },
  AdjustmentReasons: {
    zh: '调整原因',
    en: 'Adjustment reasons',
    pu: 'Motivo da Ajuste'
  },
  AdjustmentPerson: {
    zh: '调整人',
    en: 'Adjustment person',
    pu: 'Ajustador'
  },
  AdjustmentDate: {
    zh: '调整日期',
    en: 'Adjustment date',
    pu: 'Data de Ajuste'
  },
  OutflowBranch: {
    zh: '流出网点',
    en: 'Outflow branch',
    pu: 'Ponto de Saída'
  },
  OutflowQuantity: {
    zh: '流出数量',
    en: 'Outflow quantity',
    pu: 'Quantidade de Saída'
  },
  OutflowTime: {
    zh: '流出时间',
    en: 'Outflow time',
    pu: 'Data de Saída'
  },
  InflowBranch: {
    zh: '流入网点',
    en: 'Inflow branch',
    pu: 'Ponto de Entrada'
  },
  InflowTime: {
    zh: '流入时间',
    en: 'Inflow time',
    pu: 'Data de Entrada'
  },
  InflowQuantity: {
    zh: '流入数量',
    en: 'Inflow quantity',
    pu: 'Quantidade de Entrada'
  },
  PleaseConfirmWhetherToDelete: {
    zh: '删除后将不能恢复，请确认是否删除？',
    en: 'After deletion, it will not be recoverable. Please confirm whether to delete?',
    pu: 'Após a exclusão, não será possível recuperá-la. Confirma a exclusão?'
  },
  PleaseConfirmWhetherTheReceivedQuantity: {
    zh: '请确认是否收到的数量与登记的一致',
    en: 'Please confirm whether the received quantity is consistent with the registered one',
    pu: 'Confirma se a quantidade recebida é igual à registrada'
  },
  ExceedsMaximumLimit: {
    zh: '超过最大限制数',
    en: 'Exceeds maximum limit',
    pu: 'Excede o limite máximo'
  },
  BelowMinimumLimit: {
    zh: '低于最小限制数',
    en: 'Below minimum limit',
    pu: 'Abaixo do limite mínimo'
  },
  UrgeRecycling: {
    zh: '催回收',
    en: 'Urge recycling',
    pu: 'Avisar Reciclagem'
  },
  TotalBalanceQuantity: {
    zh: '结余总数量',
    en: 'Total balance quantity',
    pu: 'Quantidade Total'
  },
  MinimumLimit: {
    zh: '最小限制数',
    en: 'Minimum limit',
    pu: 'Limite Mínimo'
  },
  MaximumLimit: {
    zh: '最大限制数',
    en: 'Maximum limit',
    pu: 'Limite Máximo'
  },
  RecycledQuantity: {
    zh: '已回收数量',
    en: 'Recycled quantity',
    pu: 'Quantidade Reciclada'
  },
  RecyclingQuantity: {
    zh: '回收途中数量',
    en: 'Recycling quantity',
    pu: 'Quantidade de Reciclagem'
  },
  ReturnedQuantity: {
    zh: '已返回数量',
    en: '',
    pu: 'Número devolvido'
  },
  QuantityOnTheWayBack: {
    zh: '返回途中数量',
    en: 'Quantity on the way back',
    pu: 'Quantidade de Retorno'
  },
  AbnormalAdjustmentQuantity: {
    zh: '异常调整数量',
    en: 'Abnormal adjustment quantity',
    pu: 'Quantidade de Ajuste Anormal'
  },
  EnableStatus: {
    zh: '启用状态',
    en: 'Enable status',
    pu: 'Estado de ativação'
  },
  Pending: {
    zh: '待处理',
    en: 'Pending',
    pu: 'Pendente'
  },
  LatestReplyContent: {
    zh: '最新回复内容',
    en: 'Latest reply content',
    pu: 'Conteúdo da última resposta'
  },
  Deadline: {
    zh: '截止时间',
    en: 'Deadline',
    pu: 'Data de vencimento'
  },
  LatestProcessor: {
    zh: '最新处理人',
    en: 'Latest processor',
    pu: 'Processador mais recente'
  },
  LatestProcessingTime: {
    zh: '最新处理时间',
    en: 'Latest processing time',
    pu: 'Hora do processamento mais recente'
  },
  blameless: {
    zh: '无责',
    en: 'blameless',
    pu: 'Acareação válida'
  },
  ImportResults: {
    zh: '导入结果',
    en: 'Import results',
    pu: 'Importar resultados'
  },
  totalOptions: {
    zh: '共选择',
    en: 'total options',
    pu: 'total de opções'
  },
  MessageContentAndFileCannotBeEmpty: {
    zh: '消息内容和上传文件不能同时为空',
    en: 'Both the message content and the uploaded file cannot be empty',
    pu: 'O conteúdo da mensagem e o ficheiro carregado não podem estar ambos vazios'
  },
  CanBeEnteredMultiple2000: {
    zh: '可输入多个，最多2000，多个运单号换行输入',
    en: 'Can be entered multiple, up to 2000, multiple waybill numbers can be entered with line breaks',
    pu: 'Pode ser inserido vários, até 2000, vários números de encomenda podem ser inseridos com uma quebra de linha'
  },
  SortingMachineSignboard: {
    zh: '分拣机看板',
    en: 'Sorting machine signboard',
    pu: 'Painel de Classificação'
  },
  Average: {
    zh: '平均值',
    en: 'Average',
    pu: 'Média'
  },
  DataStatisticsTime: {
    zh: '数据统计时间',
    en: 'Data statistics time',
    pu: 'Tempo de Estatística'
  },
  No1: {
    zh: '号',
    en: 'No.',
    pu: 'Nº'
  },
  big01: {
    zh: '大件',
    en: '',
    pu: 'Carga grande'
  },
  Monday: {
    zh: '周一',
    en: 'Monday',
    pu: 'Segunda-feira'
  },
  Tuesday: {
    zh: '周二',
    en: 'Tuesday',
    pu: 'Terça-feira'
  },
  Wednesday: {
    zh: '周三',
    en: 'Wednesday',
    pu: 'Quarta-feira'
  },
  Thursday: {
    zh: '周四',
    en: 'Thursday',
    pu: 'Quinta-feira'
  },
  Friday: {
    zh: '周五',
    en: 'Friday',
    pu: 'Sexta-feira'
  },
  Saturday: {
    zh: '周六',
    en: 'Saturday',
    pu: 'Sábado'
  },
  Sunday: {
    zh: '周日',
    en: 'Sunday',
    pu: 'Domingo'
  },
  BusinessModule: {
    zh: '业务模块',
    en: 'Business module',
    pu: 'Módulo de negócios'
  },
  acceptCarriageSwitch: {
    zh: '承运更换',
    en: '',
    pu: 'substituição da transportadora'
  },
  collectuploadTime: {
    zh: '揽收装车时间',
    en: '',
    pu: 'Hora de Carregamento da Coleta'
  },
  trajectoryGenerationTime: {
    zh: '轨迹产生时间',
    en: 'Trajectory generation time',
    pu: 'Hora de geração de trajetória'
  },
  warehouseRetentionItem: {
    zh: '留仓件',
    en: 'Warehouse Retention Item',
    pu: 'Item Retido no Armazém'
  }
};
export { GlobalSetObj };
