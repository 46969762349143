var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "mb5" },
        [
          _c(
            "el-form",
            {
              ref: "queryForm",
              staticClass: "searchContainer",
              attrs: {
                model: _vm.queryForm,
                "label-position": "top",
                size: "mini",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "basicData.TheNameOfTheQuoteProposal"
                            ),
                            prop: "name",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("orderDetails.pleaseInput"),
                              clearable: "",
                            },
                            model: {
                              value: _vm.queryForm.params.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryForm.params, "name", $$v)
                              },
                              expression: "queryForm.params.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.businessPrincipal"),
                            prop: "providerInfoId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                remote: "",
                                "reserve-keyword": "",
                                "remote-method": _vm.getProviderPage,
                                placeholder: _vm.$t(
                                  "spareNumberPoo.Only10AreDisplayed"
                                ),
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.getProviderPage()
                                },
                                change: function ($event) {
                                  return _vm.providerInfoChange()
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.providerInfoId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "providerInfoId",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.providerInfoId",
                              },
                            },
                            _vm._l(_vm.providerList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label:
                                    item.providerName + "（" + item.code + "）",
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.Outlets"),
                            prop: "siteId",
                          },
                        },
                        [
                          _c("QuerySiteCmp", {
                            attrs: {
                              val: _vm.queryForm.params.siteId,
                              "provider-id":
                                _vm.queryForm.params.providerInfoId,
                            },
                            on: {
                              change: function (val) {
                                return (_vm.queryForm.params.siteId = val)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.businessT"),
                            prop: "businessType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                              },
                              model: {
                                value: _vm.queryForm.params.businessType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "businessType",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.businessType",
                              },
                            },
                            [
                              _c("el-option", {
                                key: "0",
                                attrs: {
                                  label: _vm.$t("collectionCenter.tudo"),
                                  value: null,
                                },
                              }),
                              _c("el-option", {
                                key: "1",
                                attrs: {
                                  label: _vm.$t(
                                    "collectionCenter.expressDelivery"
                                  ),
                                  value: 0,
                                },
                              }),
                              _c("el-option", {
                                key: "2",
                                attrs: {
                                  label: _vm.$t("collectionCenter.lanshou"),
                                  value: 1,
                                },
                              }),
                              _c("el-option", {
                                key: "3",
                                attrs: {
                                  label: _vm.$t("collectionCenter.delivery"),
                                  value: 2,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.UsageStatus"),
                            prop: "offerPriceStatus",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                clearable: "",
                                placeholder: _vm.$t("basicData.pleaseChoose"),
                              },
                              model: {
                                value: _vm.queryForm.params.offerPriceStatus,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "offerPriceStatus",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.offerPriceStatus",
                              },
                            },
                            [
                              _c("el-option", {
                                key: 0,
                                attrs: {
                                  label: _vm.$t("basicData.all"),
                                  value: "",
                                },
                              }),
                              _c("el-option", {
                                key: 1,
                                attrs: {
                                  label: _vm.$t("basicData.NotUsed"),
                                  value: "2",
                                },
                              }),
                              _c("el-option", {
                                key: 2,
                                attrs: {
                                  label: _vm.$t("basicData.inUse"),
                                  value: "1",
                                },
                              }),
                              _c("el-option", {
                                key: 3,
                                attrs: {
                                  label: _vm.$t("basicData.Expired"),
                                  value: "0",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.QuoteType"),
                            prop: "offerType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t("basicData.PleaseSelect"),
                                clearable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.offerType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "offerType",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.offerType",
                              },
                            },
                            [
                              _c("el-option", {
                                key: 0,
                                attrs: {
                                  label: _vm.$t("basicData.all"),
                                  value: "",
                                },
                              }),
                              _vm._l(_vm.quoteTypeList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("GlobalSetObj.client") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t("basicData.PleaseSelect"),
                                filterable: "",
                                clearable: "",
                                remote: "",
                                "reserve-keyword": "",
                                "remote-method": _vm.getCustomerOption,
                              },
                              on: {
                                clear: function ($event) {
                                  return _vm.getCustomerOption("")
                                },
                              },
                              model: {
                                value: _vm.selectedCustomer,
                                callback: function ($$v) {
                                  _vm.selectedCustomer = $$v
                                },
                                expression: "selectedCustomer",
                              },
                            },
                            _vm._l(_vm.customerOption, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label:
                                    item.customerAlias +
                                    "（" +
                                    item.username +
                                    "）",
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { type: "flex", justify: "left" } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c(
                  "div",
                  { staticStyle: { "margin-top": "10px" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-search",
                          size: "mini",
                        },
                        on: { click: _vm.getDataSearch },
                      },
                      [_vm._v(_vm._s(_vm.$t("basicData.Search")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-refresh", size: "mini" },
                        on: { click: _vm.queryFormReset },
                      },
                      [_vm._v(_vm._s(_vm.$t("basicData.reset")) + " ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        [
          _c(
            "div",
            { staticClass: "mb10" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit:remove",
                      value: "btn:finance:basicData:quotationManagement:add",
                      expression:
                        "'btn:finance:basicData:quotationManagement:add'",
                      arg: "remove",
                    },
                  ],
                  attrs: {
                    size: "mini",
                    type: "primary",
                    icon: "el-icon-plus",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handlerOperation(1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("basicData.Add")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "elTable",
              staticClass: "table-height",
              attrs: {
                data: _vm.tableData,
                border: "",
                height: _vm.tableHeightRow.height,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("basicData.serialNumber"),
                  width: "60px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.queryForm.pageNumber - 1) *
                                _vm.queryForm.pageSize +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: _vm.$t("basicData.TheNameOfTheQuoteProposal"),
                  align: "left",
                  width: "160",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top-end",
                              "popper-class": "copy",
                              trigger: "hover",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "black",
                                  "font-size": "12px",
                                },
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.copy(scope.row.name)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("orderDetails.copy")) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "WaybillNumber",
                                attrs: { slot: "reference" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerOperation(2, scope.row)
                                  },
                                },
                                slot: "reference",
                              },
                              [_vm._v(" " + _vm._s(scope.row.name) + " ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "partitionName",
                  label: _vm.$t("basicData.PartitionSchemeName"),
                  align: "left",
                  width: "160",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "WaybillNumber",
                            on: {
                              click: function ($event) {
                                return _vm.handlerOperation(3, scope.row)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(scope.row.partitionName) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "providerInfoName",
                  label: _vm.$t("basicData.businessPrincipal"),
                  align: "center",
                  width: "140",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "siteName",
                  label: _vm.$t("basicData.Outlets"),
                  align: "center",
                  width: "140",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "customerName",
                  label: _vm.$t("GlobalSetObj.client"),
                  align: "center",
                  width: "140",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "offerType",
                  label: _vm.$t("basicData.QuoteType"),
                  align: "center",
                  width: "140",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.offerType === 1
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$t("basicData.StandardPrice"))),
                            ])
                          : scope.row.offerType === 2
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("basicData.BusinessEntityPrice"))
                              ),
                            ])
                          : scope.row.offerType === 3
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$t("basicData.SitePrice"))),
                            ])
                          : scope.row.offerType === 4
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("basicData.SpecifyCustomerPrice"))
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "code",
                  label: _vm.$t("collectionCenter.businessT"),
                  align: "center",
                  width: "160",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          scope.row.businessType === 0
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("collectionCenter.expressDelivery")
                                  )
                                ),
                              ])
                            : scope.row.businessType === 1
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("collectionCenter.lanshou"))
                                ),
                              ])
                            : scope.row.businessType === 2
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("collectionCenter.delivery"))
                                ),
                              ])
                            : scope.row.businessType === 3
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("collectionCenter.expressDelivery")
                                  ) +
                                    "、" +
                                    _vm._s(_vm.$t("collectionCenter.lanshou"))
                                ),
                              ])
                            : scope.row.businessType === 4
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("collectionCenter.expressDelivery")
                                  ) +
                                    "、" +
                                    _vm._s(_vm.$t("collectionCenter.delivery"))
                                ),
                              ])
                            : scope.row.businessType === 5
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("collectionCenter.lanshou")) +
                                    "、" +
                                    _vm._s(_vm.$t("collectionCenter.delivery"))
                                ),
                              ])
                            : scope.row.businessType === 6
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("collectionCenter.expressDelivery")
                                  ) +
                                    "、" +
                                    _vm._s(_vm.$t("collectionCenter.lanshou")) +
                                    "、" +
                                    _vm._s(_vm.$t("collectionCenter.delivery"))
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "offerPriceStatus",
                  label: _vm.$t("basicData.UsageStatus"),
                  align: "center",
                  width: "140",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          scope.row.offerPriceStatus === 3
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("basicData.disable"))),
                              ])
                            : _vm._e(),
                          scope.row.offerPriceStatus === 2
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("basicData.NotUsed"))),
                              ])
                            : _vm._e(),
                          scope.row.offerPriceStatus === 1
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("basicData.inUse"))),
                              ])
                            : _vm._e(),
                          scope.row.offerPriceStatus === 0
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("basicData.Expired"))),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "startTime",
                  label: _vm.$t("basicData.TheValidityPeriodBegins"),
                  align: "center",
                  width: "160",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "endTime",
                  label: _vm.$t("basicData.EndOfValidityPeriod"),
                  align: "center",
                  width: "160",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createUserStr",
                  label: _vm.$t("basicData.CreatePeople"),
                  align: "center",
                  width: "120",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: _vm.$t("basicData.CreationTime"),
                  align: "center",
                  width: "160",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "updateUserStr",
                  label: _vm.$t("basicData.LastModifier"),
                  align: "center",
                  width: "120",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "updateTime",
                  label: _vm.$t("basicData.LastModified"),
                  align: "center",
                  width: "160",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("basicData.operate"),
                  fixed: "right",
                  align: "center",
                  width: "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            scope.row.status === true
                              ? _c(
                                  "el-popconfirm",
                                  {
                                    directives: [
                                      {
                                        name: "permit",
                                        rawName: "v-permit",
                                        value:
                                          "but:finance:basicData:accountManagement:edit",
                                        expression:
                                          "'but:finance:basicData:accountManagement:edit'",
                                      },
                                    ],
                                    attrs: {
                                      title: _vm.$t(
                                        "basicData.AreYouSureDisabled"
                                      ),
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.asyncPartitionEdit(
                                          scope.row,
                                          0
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "ml",
                                        attrs: {
                                          slot: "reference",
                                          type: "danger",
                                          size: "mini",
                                        },
                                        slot: "reference",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("basicData.disable"))
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            scope.row.status === false
                              ? _c(
                                  "el-popconfirm",
                                  {
                                    directives: [
                                      {
                                        name: "permit",
                                        rawName: "v-permit",
                                        value:
                                          "btn:finance:basicData:quotationManagement:edit",
                                        expression:
                                          "'btn:finance:basicData:quotationManagement:edit'",
                                      },
                                    ],
                                    attrs: {
                                      title: _vm.$t(
                                        "basicData.AreYouSureEnabled"
                                      ),
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.asyncPartitionEdit(
                                          scope.row,
                                          1
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "ml",
                                        attrs: {
                                          slot: "reference",
                                          type: "success",
                                          size: "mini",
                                        },
                                        slot: "reference",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("basicData.enable"))
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                staticStyle: { margin: "10px 0" },
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  "current-page": _vm.queryForm.pageNumber,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.queryForm.pageSize,
                  total: _vm.total,
                  background: "",
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "current-change": _vm.getData,
                  "size-change": _vm.getData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.drawerObj.visible,
            "with-header": false,
            direction: "ltr",
            size: "90%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawerObj, "visible", $event)
            },
          },
        },
        [
          _c("detail-log", {
            key: _vm.editKey,
            attrs: {
              "edit-data": _vm.drawerObj.editData,
              "operation-type": _vm.drawerObj.typeIndex,
            },
            on: { saveChange: _vm.updatePage },
          }),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.showPartitionDetail,
            "with-header": false,
            direction: "ltr",
            size: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPartitionDetail = $event
            },
          },
        },
        [
          _c("details-cmp", {
            key: _vm.editKeyPartition,
            staticStyle: { height: "100%" },
            attrs: {
              "type-index": 3,
              "edit-data": _vm.drawerObj.editData,
              "state-list": _vm.stateList,
            },
            on: { updatePage: _vm.updatePage },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }