// import request from '@/utils/request';
import axios from 'axios';
const baseURL = process.env.VUE_APP_FINANCE;

// 上传发票
export const apiSitePayableUpdateSitePayFile = (data) => {
  return axios({
    method: 'POST',
    url: '/sitePayable/updateSitePayFile',
    data,
    baseURL
  });
};
