<template> <!-- 生成对账单 -->
  <el-dialog
    :title="$t('receivablePayable.GenerateBillsPayable')"
    width="600px"
    :visible.sync="isShow"
  >
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      size="small"
      label-width="100px"
      class="demo-ruleForm"
      label-position="top"
    >
      <!-- 选择账单日期 -->
      <el-form-item :label="$t('receivablePayable.SelectBillingDate')" prop="TimeCollection">
        <el-date-picker
          v-model="ruleForm.TimeCollection"
          style="width: 100%"
          type="daterange"
          range-separator="-"
          :start-placeholder="$t('operationCenter.startDate')"
          :end-placeholder="$t('operationCenter.endDate')"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <!--取 消-->
      <el-button size="small" @click="onClose">{{ $t('basicData.Cancel') }}</el-button>
      <!--确 定-->
      <el-button size="small" type="primary" @click="submitForm">{{ $t('basicData.determine') }}</el-button>
    </div>

  </el-dialog>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import { getCreateCostSiteExpenseBill } from '@/api/finance/costManagement.js';

export default {
  name: '',

  props: {
    // 多选
    selectData: {
      type: Array,
      default: () => {
        return [];
      }
    },

    // 搜索
    queryForm: {
      type: Object,
      default: () => {
        return {};
      }
    },

    // 后续处理函数
    cb: {
      type: Function,
      default: () => {
        return () => {};
      }
    }
  },

  data() {
    return {
      isShow: false,
      ruleForm: {
        TimeCollection: []
      },

      rules: {
        TimeCollection: [
          { required: true, message: this.$t('trajectory.DatesCannotBeEmpty'), trigger: 'change' }
        ]
      }
    };
  },

  watch: {
    'isShow': {
      handler(val) {
        if (val) {
          this.$refs.ruleForm?.resetFields();
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    // 打开弹窗
    open() {
      this.isShow = true;
    },
    // 关闭弹窗
    onClose() {
      this.isShow = false;
    },
    // 确定
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.setData().then(row => {
            console.log(row);
            if (row) {
              // 生成对账单
              getCreateCostSiteExpenseBill(row).then(res => {
                this.cb(res.data);
                this.onClose();
              }).catch(err => {
                console.error(err);
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    /**
     * 设置数据
     * @returns {Promise<T>|null}
     */
    async setData() {
      const ids = [];
      const paramForm = cloneDeep(this.queryForm.params);
      // 未勾选 验证主体和业务类型是否为空
      if (this.selectData.length === 0) {
        if (!paramForm.businessType && !paramForm.providerId) {
          this.$message.warning(this.$i18n.t('receivablePayable.subjectAndBusinessTypeCannotBeEmpty'));
          return Promise.resolve(null);
        }
      } else {
        // 勾选 验证主体和业务类型是否一致
        const propsToCheck = ['businessType', 'providerName'];
        const flag = this.selectData.every(obj => {
          return propsToCheck.every(prop => obj[prop] === this.selectData[0][prop]);
        });
        console.log(flag);
        if (flag) {
          this.selectData.forEach(item => {
            ids.push(item.id);
          });
        } else {
          this.$message.warning(this.$i18n.t('receivablePayable.subjectAndBusinessTypeMustBeTheSame'));
          return Promise.resolve(null);
        }
      }

      const tips = this.selectData.length > 0
        ? this.$i18n.t('receivablePayable.DetermineTheStatementToBeGeneratedForTheSelectedData')
        : this.$i18n.t('receivablePayable.UncheckedDataWillBeGivenToQueryAllTheDataToGenerateStatementsDetermineTheGenerationOf');

      return this.$confirm(tips, this.$i18n.t('basicData.tips'), {
        confirmButtonText: this.$i18n.t('basicData.determine'),
        cancelButtonText: this.$i18n.t('basicData.Cancel'),
        type: 'warning'
      }).then(() => {
        const { TimeCollection } = this.ruleForm;

        paramForm.nameStartTime = TimeCollection[0];
        paramForm.nameEndTime = TimeCollection[1];
        console.log(paramForm, ids);
        return {
          ...paramForm,
          ids: ids
        };
      }).catch(() => {
        console.log('取消');
        return null;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
