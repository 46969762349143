<template>
  <div class="app-container">
    <el-card shadow="never" style="margin-bottom: 15px">
      <el-form :inline="false" label-position="top" size="small">
        <el-row :gutter="20">
          <!-- 网点编号-->
          <el-col :span="4">
            <el-form-item :label="$t('collectionCenter.siteNum')">
              <el-input
                v-model="queryForm.params.siteCode"
                :placeholder="$t('GlobalSetObj.pleaseInput')"
                clearable
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <!--   网点名称   -->
            <el-form-item :label="$t('collectionCenter.siteName')">
              <el-input
                v-model="queryForm.params.siteName"
                :placeholder="$t('GlobalSetObj.pleaseInput')"
                clearable
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
          <!-- 状态 -->
          <el-col :span="4">
            <el-form-item :label="$t('GlobalSetObj.status')">
              <el-select
                v-model="queryForm.params.enableStatus"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                style="width: 100%"
              >
                <el-option :label="$t('GlobalSetObj.enable')" :value="1" />
                <el-option :label="$t('GlobalSetObj.stop')" :value="0" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <div style="margin-top: 27px">
              <!--搜索-->
              <el-button
                size="small" type="primary" @click="enterSearch"
              >{{ $t('newOrder.search') }}
              </el-button>
              <!--批量配置-->
              <el-button
                size="small" type="primary" @click="batchConfiguration"
              >{{ $t('home.batchConfiguration') }}
              </el-button>
              <!--重置-->
              <el-button
                size="small" type="info" @click="resetSearchForm"
              >{{ $t('newOrder.reset') }}
              </el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <!--  表格数据  -->
    <el-row :gutter="10">
      <el-col :span="5" style="height: 620px; overflow-y: auto">
        <div class="treeClass">
          <el-input
            v-model="filterText"
            :placeholder="$t('home.inputFiller')"
            clearable
            style="margin-bottom: 15px"
            suffix-icon="el-icon-search"
          />

          <el-tree
            ref="treeRef"
            :data="treeData"
            :filter-node-method="filterNode"
            :highlight-current="ishighlight"
            :props="defaultProps"
            class="filter-tree"
            @node-click="handleNodeClick"
          />
        </div>
      </el-col>
      <el-col :span="19">
        <el-table
          :data="tableData"
          :header-cell-style="{ background: '#f2f2f2', color: 'black' }"
          :height="620"
          border
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column fixed="left" type="selection" width="55" />
          <el-table-column
            v-for="item in tableHeaderList"
            :key="item.id"
            :align="item.center"
            :label="item.label"
            :min-width="item.cloWidth"
            :prop="item.prop"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              <div>{{ scope.row[item.prop] }}</div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('GlobalSetObj.status')" align="center" min-width="150">
            <template slot-scope="scope">
              <div style="display: flex; justify-content: center; align-items: center">
                <el-switch
                  v-model="scope.row.enableStatusBoolen"
                  active-color="#13ce66"
                  disabled
                  inactive-color="#ff4949"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('newOrder.operation')"
            align="center"
            fixed="right"
            width="100"
          >
            <template slot-scope="scope">
              <div style="display: flex; justify-content: center; align-items: center">
                <el-tooltip
                  :content="$t('GlobalSetObj.edit')"
                  class="item"
                  effect="dark"
                  placement="top"
                >
                  <i class="el-icon-edit textOperator" @click="editData(scope.row)" />
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0; float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!-- 配置信息 -->
    <el-drawer
      :title="$t('home.configInfo')"
      :visible.sync="editVisible"
      center
      direction="ltr"
      size="900px"
      @close="closeDrawer"
    >
      <div style="padding: 0 20px; height: calc(100vh - 80px); overflow: auto">
        <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          :rules="rules"
          class="demo-ruleForm"
          label-position="top"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item :label="$t('collectionCenter.siteNum')" prop="siteCode">
                <el-input v-model="ruleForm.siteCode" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('collectionCenter.siteName')" prop="siteName">
                <el-input v-model="ruleForm.siteName" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('basicData.TheTypeOutlet')" prop="siteType">
                <el-input v-model="ruleForm.siteType" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="$t('collectionCenter.owningTransitCenter1')"
                prop="transferCenterName"
              >
                <el-input v-model="ruleForm.transferCenterName" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('home.initNumber')" prop="initialcount">
                <el-input
                  v-model="ruleForm.initialcount"
                  :maxlength="7"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                  show-word-limit
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('home.minlimitcount')" prop="minlimitcount">
                <el-input
                  v-model="ruleForm.minlimitcount"
                  :maxlength="7"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                  show-word-limit
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('home.maxLimitcount')" prop="maxLimitcount">
                <el-input
                  v-model="ruleForm.maxLimitcount"
                  :maxlength="7"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                  show-word-limit
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('GlobalSetObj.status')" prop="enablestatus">
                <el-switch
                  v-model="ruleForm.enablestatus"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24" style="display: flex; justify-content: flex-end">
              <el-form-item>
                <el-button
                  type="primary" @click="submitForm(1)"
                >{{ $t('collectionCenter.submit') }}
                </el-button>
                <el-button @click="resetForm">{{ $t('customerManagements.reset') }}</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-drawer>
    <!--  批量配置 -->
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      :title="$t('home.batchConfiguration')"
      :visible.sync="butachConfigDialog"
      width="700px"
      @close="closeDialog"
    >
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        class="demo-ruleForm"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('home.initNumber')" prop="initialcount">
              <el-input
                v-model="ruleForm.initialcount"
                :maxlength="7"
                :placeholder="$t('GlobalSetObj.pleaseInput')"
                clearable
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('home.minlimitcount')" prop="minlimitcount">
              <el-input
                v-model="ruleForm.minlimitcount"
                :maxlength="7"
                :placeholder="$t('GlobalSetObj.pleaseInput')"
                clearable
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('home.maxLimitcount')" prop="maxLimitcount">
              <el-input
                v-model="ruleForm.maxLimitcount"
                :maxlength="7"
                :placeholder="$t('GlobalSetObj.pleaseInput')"
                clearable
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('GlobalSetObj.status')" prop="enablestatus">
              <el-switch
                v-model="ruleForm.enablestatus"
                active-color="#13ce66"
                inactive-color="#ff4949"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24" style="display: flex; justify-content: flex-end">
            <el-form-item>
              <el-button
                type="primary" @click="submitForm(2)"
              >{{ $t('collectionCenter.submit') }}
              </el-button>
              <el-button @click="resetForm01">{{ $t('customerManagements.reset') }}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { bagUsagebatchConfig, bagUsageConfig, bagUsageConfigPage } from '@/api/newExpressAPI';
import { getSiteTree } from '@/api/lanshou';

export default {
  name: 'SignLatLonMonitoring',
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        params: {
          siteCode: '',
          siteName: '',
          enableStatus: '',
          siteId: ''
        }
      },
      tableData: [],
      ruleForm: {
        siteCode: '',
        siteName: '',
        siteType: '',
        transferCenterName: '',
        enablestatus: true,
        id: '',
        initialcount: '', // 初始数量
        minlimitcount: '', // 最小限制数
        maxLimitcount: '' // 最大限制数
      },
      rules: {
        initialcount: [
          { required: true, message: this.$t('collectionCenter.pleaseInput'), trigger: 'change' },
          { validator: this.validatePositiveInteger, trigger: ['blur', 'change'] }
        ],
        minlimitcount: [
          { required: true, message: this.$t('collectionCenter.pleaseInput'), trigger: 'change' },
          { validator: this.validatePositiveInteger, trigger: ['blur', 'change'] }
        ],
        maxLimitcount: [
          { required: true, message: this.$t('collectionCenter.pleaseInput'), trigger: 'change' },
          { validator: this.validatePositiveInteger, trigger: ['blur', 'change'] }
        ]
      },
      tableHeaderList: [
        // 网点编号
        {
          id: 1,
          label: this.$t('collectionCenter.siteNum'),
          prop: 'siteCode',
          cloWidth: '150',
          center: 'center'
        },
        // 网点名称
        {
          id: 2,
          label: this.$t('collectionCenter.siteName'),
          prop: 'siteName',
          cloWidth: '160',
          center: 'center'
        },
        // 网点类型
        {
          id: 3,
          label: this.$t('basicData.TheTypeOutlet'),
          prop: 'siteType',
          cloWidth: '160',
          center: 'center'
        },
        // 所属转运中心
        {
          id: 4,
          label: this.$t('collectionCenter.owningTransitCenter1'),
          prop: 'transferCenterName',
          cloWidth: '150',
          center: 'center'
        },
        // 初始数量
        {
          id: 5,
          label: this.$t('home.initNumber'),
          prop: 'initialCount',
          cloWidth: '140',
          center: 'center'
        },
        // 结余总数量
        {
          id: 6,
          label: this.$t('home.balanceCount'),
          prop: 'balanceCount',
          cloWidth: '140',
          center: 'center'
        },
        // 最小限制数
        {
          id: 7,
          label: this.$t('GlobalSetObj.MinimumLimit'),
          prop: 'minLimitCount',
          cloWidth: '140',
          center: 'center'
        },
        // 最大限制数
        {
          id: 8,
          label: this.$t('GlobalSetObj.MaximumLimit'),
          prop: 'maxLimitCount',
          cloWidth: '140',
          center: 'center'
        }
      ],
      StateList: [], // 用户列表
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      filterText: '',
      multipleSelection: [],
      treeData: [],
      ishighlight: true,
      editVisible: false,
      editDataObj: {},
      butachConfigDialog: false
    };
  },
  computed: {},
  watch: {
    filterText(val) {
      this.$refs.treeRef.filter(val);
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getSearch();
    this.getsiteTree();
  },
  methods: {
    // 自定义验证正整数的方法
    validatePositiveInteger(rule, value, callback) {
      const reg = /^[0-9]\d*$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t('collectionCenter.pleaseEnterInteger')));
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 点击树节点
    handleNodeClick(data) {
      console.log(data, 'data');
      this.ishighlight = true;
      this.queryForm.params.siteId = data.id;
      this.getSearch();
    },
    // 点击批量配置
    batchConfiguration() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning(this.$t('collectionCenter.checkMsg'));
        return;
      }
      this.ruleForm = {
        enablestatus: true,
        initialcount: '', // 初始数量
        minlimitcount: '', // 最小限制数
        maxLimitcount: '' // 最大限制数
      };
      this.butachConfigDialog = true;
      this.$refs.ruleFormRef.resetFields();
    },
    // 点击批量重置
    resetForm01() {
      this.ruleForm = {
        enablestatus: true,
        initialcount: '', // 初始数量
        minlimitcount: '', // 最小限制数
        maxLimitcount: '' // 最大限制数
      };
      this.$refs.ruleFormRef.resetFields();
    },
    // 查询
    getSearch() {
      const data = {
        pageSize: this.queryForm.pageSize,
        pageNumber: this.queryForm.pageNumber,
        params: {
          siteCode: this.queryForm.params.siteCode,
          siteName: this.queryForm.params.siteName,
          enableStatus: this.queryForm.params.enableStatus,
          siteId: this.queryForm.params.siteId || null
        }
      };
      console.log(data, 'data');
      bagUsageConfigPage(data).then((res) => {
        if (res.code === 200) {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.forEach((item) => {
            item.enableStatusBoolen = item.enableStatus === 1;
            if (item.initialCount === null) {
              item.initialCount = '--';
            }
            if (item.balanceCount === null) {
              item.balanceCount = '--';
            }
            if (item.minLimitCount === null) {
              item.minLimitCount = '--';
            }
            if (item.maxLimitCount === null) {
              item.maxLimitCount = '--';
            }
          });
        }
      });
    },
    enterSearch() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 重置
    resetSearchForm() {
      this.queryForm.pageNumber = 1;
      this.queryForm.params = {
        siteCode: '',
        siteName: '',
        enableStatus: '',
        siteId: ''
      };
      this.ishighlight = false;
      this.getSearch();
    },
    // 编辑
    editData(row) {
      console.log(row, 'editData');
      this.editDataObj = row;
      this.ruleForm = {
        siteCode: row.siteCode,
        siteName: row.siteName,
        siteType: row.siteType,
        transferCenterName: row.transferCenterName,
        enablestatus: row.enableStatus === 1,
        id: row.id,
        initialcount: row.initialCount === '--' ? '' : row.initialCount, // 初始数量
        minlimitcount: row.minLimitCount === '--' ? '' : row.minLimitCount, // 最小限制数
        maxLimitcount: row.maxLimitCount === '--' ? '' : row.maxLimitCount // 最大限制数
      };
      this.editVisible = true;
    },
    submitForm(val) {
      this.$refs['ruleFormRef'].validate((valid) => {
        if (valid) {
          const data = {
            id: this.ruleForm.id,
            initialCount: this.ruleForm.initialcount,
            minLimitCount: this.ruleForm.minlimitcount,
            maxLimitCount: this.ruleForm.maxLimitcount,
            enableStatus: this.ruleForm.enablestatus ? 1 : 0
          };
          console.log(data, 'data');
          if (Number(this.ruleForm.maxLimitcount) <= Number(this.ruleForm.minlimitcount)) {
            this.$message.error(this.$t('home.tip01'));
            return;
          }
          if (val === 1) {
            bagUsageConfig(data).then((res) => {
              if (res.code === 200) {
                this.$message.success(this.$t('GlobalSetObj.ModificationSuccessful'));
                this.closeDrawer();
                this.getSearch();
              }
            });
          } else {
            data.ids = [];
            this.multipleSelection.forEach((item) => {
              data.ids.push(item.id);
            });
            bagUsagebatchConfig(data).then((res) => {
              if (res.code === 200) {
                this.$message.success(this.$t('GlobalSetObj.ModificationSuccessful'));
                this.closeDialog();
                this.getSearch();
              }
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.ruleForm = {
        siteCode: this.editDataObj.siteCode,
        siteName: this.editDataObj.siteName,
        siteType: this.editDataObj.siteType,
        transferCenterName: this.editDataObj.transferCenterName,
        enablestatus: this.editDataObj.enableStatus === 1,
        id: this.editDataObj.id,
        initialcount: this.editDataObj.initialCount === '--' ? '' : this.editDataObj.initialCount, // 初始数量
        minlimitcount:
          this.editDataObj.minLimitCount === '--' ? '' : this.editDataObj.minLimitCount, // 最小限制数
        maxLimitcount: this.editDataObj.maxLimitCount === '--' ? '' : this.editDataObj.maxLimitCount // 最大限制数
      };
      this.$refs['ruleFormRef'].resetFields();
    },
    closeDrawer() {
      this.resetForm();
      this.editVisible = false;
    },
    closeDialog() {
      this.resetForm();
      this.butachConfigDialog = false;
    },
    // 获取所有网点
    getsiteTree() {
      const data = {};
      getSiteTree(data).then((res) => {
        if (res.code === 200) {
          this.treeData = res.data;
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    }
  }
};
</script>

<style scoped>
@import './index.scss';

>>> .el-card__body {
  padding: 10px 10px 5px 10px;
}
</style>
