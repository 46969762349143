import { debounce } from 'lodash-es';

export default {
  data() {
    return {
      windowWidth: 0,
      windowHeight: 0
    };
  },

  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    handleResize: debounce(function() {
      // 业务逻辑
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      console.log('窗口尺寸变化:', this.windowWidth, this.windowHeight);
    }, 300)
  }
};
