<template> <!-- 用户 虚拟列表 -->

  <ElSelectV2
    v-model="value"
    :multiple="multiple"
    :clearable="clearable"
    :multiple-limit="multipleLimit"
    :loading="loading"
    :options="list"
    filterable
    :placeholder="$t('operationCenter.PleaseSelect')"
    @change="onChange"
  />
</template>

<script>
import ElSelectV2 from 'el-select-v2';
import { getUserPage } from '@/api/user';

const param = {
  pageSize: 999999,
  pageNumber: 1,
  params: {
    realName: '',
    accountState: 1 // 1启用 0停用 不填就是全部
  }
};

const QueryUserV2Cmp = 'QueryUserV2Cmp';

export default {
  name: '',

  components: {
    ElSelectV2
  },

  props: {
    // 是否多选
    multiple: {
      type: Boolean,
      default: false
    },

    // 是否可以清空选项
    clearable: {
      type: Boolean,
      default: true
    },

    // 多选时用户最多可以选择的项目数，为 0 则不限制
    multipleLimit: {
      type: Number,
      default: 0
    },

    // id集合 用于回显
    val: {
      type: [Array, String, Number],
      default: () => []
    },

    // 是否显示被禁用人员
    accountState: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      loading: false,
      list: [],
      value: []
    };
  },

  watch: {
    val: {
      immediate: true,
      handler(val) {
        if (Array.isArray(val)) {
          this.value = [...val];
        } else if (typeof val === 'number') {
          this.value = val;
        } else {
          this.value = val || '';
        }
      }
    }

  },

  created() {
    const list = window.sessionStorage.getItem(QueryUserV2Cmp);
    if (list) {
      this.list = JSON.parse(list).map(item => {
        return Object.freeze(item);
      });
      return;
    }
    setTimeout(() => {
      this.asyncGetUserPage();
    }, 100);
  },

  methods: {
    // 获取用户信息（含职务）
    asyncGetUserPage() {
      this.loading = true;

      param.params.accountState = this.accountState;

      getUserPage(param, false).then(res => {
        const { code, data } = res;
        if (code !== 200) { return; }

        const list = (data.records || []).map(item => {
          const { realName, id, organizationName, username } = item;
          return Object.freeze({
            value: id,
            label: `${realName}(${username})`,
            organizationName // 部门
          });
        });

        window.sessionStorage.setItem(QueryUserV2Cmp, JSON.stringify(list));

        this.list = list;
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    onChange(val) {
      this.$emit('change', val);
    },

    onInput(val) {

    }
  }

};
</script>

<style lang="scss" scoped>
</style>
