<!--添加费用-->
<template>
  <el-drawer
    :append-to-body="true"
    :title="$t('receivablePayable.addingCosts')"
    :visible.sync="isVisible"
    custom-class="drawerClass"
    direction="ltr"
    size="80%"
  >
    <div class="content-box">
      <div class="details">
        <el-card :header="$t('basicData.订单信息')" class="mb5">
          <div>
            <span class="label">
              <span style="color: #f56c6c">*</span>
              {{ $t('userManage.time') + '：' }}</span>
            <el-date-picker
              v-model="dateRange"
              size="small"
              type="daterange"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              range-separator="-"
              :start-placeholder="$t('GlobalSetObj.startDate')"
              :end-placeholder="$t('GlobalSetObj.endDate')"
            />
          </div>
        </el-card>
        <el-card :header="$t('receivablePayable.FeeBreakdown')">
          <!-- 费用明细 -->
          <FeeTable
            ref="detailsTableRef"
            :add-type="1"
            :list="addBatchFeeList"
          />
        </el-card>
      </div>
      <!-- 按钮 -->
      <div class="btn">
        <!--保存-->
        <el-button
          v-permit:remove="'btn:finance:costManagement:costsFee:Edit'"
          type="primary"
          size="small"
          @click="submit"
        >{{ $t('basicData.Save') }}</el-button>
        <!--关闭-->
        <el-button size="small" @click="onClose">{{ $t('orderCenterCont.ShutDown') }}</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { getBatchAddCostSiteExpense } from '@/api/finance/costManagement.js';
export default {
  name: '',

  components: {
    FeeTable: () => import('@/views/finance/costManagement/costsFee/cmp/costBreakdown/feeTable.vue')
  },

  props: {
    // 查询表单
    queryForm: {
      type: Object,
      default() {
        return {};
      }
    },
    // 已选择数据
    selectArr: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      isVisible: false,
      addBatchFeeList: [], // 费用明细数据列表
      dateRange: [],
      pickerMinDate: null,
      pickerRange: 30 * 24 * 60 * 60 * 1000 // 可选择日期范围 为30天
    };
  },

  computed: {
    pickerOptions() {
      const { pickerMinDate, pickerRange } = this;
      return {
        onPick: ({ minDate }) => {
          if (minDate && pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            return (time.getTime() > (pickerMinDate + pickerRange)) || (time.getTime() < (pickerMinDate - pickerRange));
          }
          return false;
        }
      };
    }
  },

  created() {

  },

  methods: {
    // 打开抽屉组件
    open() {
      this.isVisible = true;
      this.dateRange = [];
      this.addBatchFeeList = [];
    },
    // 关闭抽屉组件
    onClose() {
      this.isVisible = false;
    },

    // 保存费用数据
    submit() {
      // 时间必填
      if (!this.dateRange || this.dateRange.length !== 2) {
        this.$message({ type: 'warning', message: `${this.$t('operationCenter.PleaseSelect')}${this.$t('userManage.time')}` });
        return false;
      }
      // 费用明细必填
      const obj = this.$refs.detailsTableRef.submitEdit();
      console.log(obj, '批量添加费用');
      if (obj.valid) return;
      if (obj.arr.length === 0) {
        this.$message.warning(this.$i18n.t('receivablePayable.AddAtLeastOneCharge'));
        return;
      }
      const params = {
        startDate: this.dateRange[0],
        endDate: this.dateRange[1],
        expenseSubList: obj.arr
      };
      getBatchAddCostSiteExpense(params).then((res) => {
        if (res.code !== 200) return;
        this.$message.success(this.$i18n.t('GlobalSetObj.SaveSuccessfully')); // 保存成功
        this.onClose(true);
      });
    }
  }
};
</script>

<style scoped>
.details {
  width: 100%;
  height: calc(100vh - 120px);
  overflow-y: scroll;
}
.details /deep/ .el-form-item {
  margin-bottom: 0;
}
.label {
  font-weight: bold;
  font-size: 14px;
  color: #606266;
}
.btn {
  position: sticky;
  left: 0;
  bottom: 5px;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #eeefee;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20000;
}
</style>
