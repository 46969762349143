import i18n from '@/lang';
import { omit, cloneDeep, isEmpty } from 'lodash-es';

// 账单类型
export const PAYABLE_TYPE = Object.freeze({
  1: {
    name: i18n.t('receivablePayable.AnjunCottage'), // 安骏小屋
    value: 1
  },
  2: {
    name: i18n.t('receivablePayable.ThirdPartyCollection'), // 第三方揽收
    value: 2
  },
  3: {
    name: i18n.t('receivablePayable.AJDDispatch'), // AJD派送
    value: 3
  },
  4: {
    name: i18n.t('receivablePayable.DSPDispatch'), // DSP派送
    value: 4
  },
  5: {
    name: i18n.t('compensationManagement.Transportation_trunk_branch'), // 运输(干/支线)
    value: 5
  }
});

export const TABLE_HEADER = [
  // 业务单号
  { prop: 'waybillNumber', label: i18n.t('receivablePayable.TicketNumber'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 条形码
  { prop: 'orderNumber', label: i18n.t('collectionCenter.orderNumber'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 应付主体
  { prop: 'providerName', label: i18n.t('basicData.PayablePrincipal'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 应付网点
  { prop: 'siteName', label: i18n.t('basicData.PayableOutlets'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 业务类型
  { prop: 'businessType', label: i18n.t('collectionCenter.businessT'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 揽收类型
  { prop: 'collectType', label: i18n.t('newOrder.CollectionType'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 派送模式
  { prop: 'operationMode', label: i18n.t('newOrder.OperationMode'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 揽收人
  { prop: 'collectorsName', label: i18n.t('basicData.揽收操作人'), width: 180, fixed: 'left', highlight: true, disabled: false },
  // 派送人
  { prop: 'dispatcherName', label: i18n.t('basicData.派送操作人'), width: 180, fixed: 'left', highlight: true, disabled: false },
  // 备注
  { prop: 'remark', label: i18n.t('basicData.remark'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 计费重量
  { prop: 'chargeWeight', label: i18n.t('receivablePayable.BillableWeight'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 应付总费用
  { prop: 'totalFee', label: i18n.t('basicData.TotalFeesPayable'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 订单状态
  { prop: 'status', label: i18n.t('collectionCenter.OrderStatus'), width: '', fixed: 'left', highlight: false, disabled: false },
  // 所属分区
  { prop: 'partitionName', label: i18n.t('basicData.PartitionName'), width: 160, fixed: 'left', highlight: false, disabled: false },
  // 收件人城市
  { prop: 'receiveCity', label: i18n.t('collectionCenter.receiveCity'), width: 160, fixed: 'left', highlight: false, disabled: false },
  // 收件人邮编
  { prop: 'receiveZipcode', label: i18n.t('collectionCenter.Recipientzc'), width: 160, fixed: 'left', highlight: false, disabled: false },
  // 收件人州
  { prop: 'receiveState', label: i18n.t('newOrder.RecipientState'), width: '', fixed: 'left', highlight: false, disabled: false },
  // 发件人州
  { prop: 'senderState', label: i18n.t('newOrder.SenderState'), width: '', fixed: 'left', highlight: false, disabled: false },
  // 客户
  { prop: 'customerAlias', label: i18n.t('GlobalSetObj.client'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 来源
  { prop: 'feeSource', label: i18n.t('receivablePayable.source'), width: 110, fixed: 'left', highlight: false, disabled: false },
  // 网点类型
  { prop: 'siteType', label: i18n.t('basicData.TheTypeOutlet'), width: 110, fixed: 'left', highlight: false, disabled: false }
];

const whitelist = ['numberField', 'timeField'];

/**
 * 所有搜索条件是否都为空
 * @param {*} obj
 * @returns {boolean}
 */
export const isSearchAllEmpty = (obj) => {
  const newObj = cloneDeep(obj);
  // 删除白名单字段
  const newObj2 = omit(newObj, whitelist);

  // 空字符串 空数组 null undefined
  return Object.values(newObj2).every(item => {
    return isEmpty(item);
  });
};

/**
 * 请求参数处理
 * @param {{}} query 请求参数
 * @returns {} 返回的数据已进行深拷贝处理，可以随意改动
 */
export const getQuery = (query) => {
  const queryForm = cloneDeep(query);
  // if (param.params.siteType !== 1 && param.params.businessType === 2) {
  //   // 网点类型不为安骏小屋，且业务类型为揽收 ===> 展示揽收方式
  // } else {
  //   param.params.collectType = '';
  //   this.queryForm.params.collectType = '';
  // }

  // 派送员
  if (!Array.isArray(queryForm.params.dispatcherList)) {
    queryForm.params.dispatcherList = [];
  }

  // 揽收人
  if (!Array.isArray(queryForm.params.collectorIdList)) {
    queryForm.params.collectorIdList = [];
  }
  return queryForm;
};

