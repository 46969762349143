<template>
  <el-card class="box-card">
    <div style="margin-bottom: 15px">
      <span style="margin-bottom: 15px; font-weight: bold; color: #009743">遗失统计</span>
      <span>(全部丢失/部分丢失/全部被盗/部分被盗)</span>
    </div>
    <!--  表格数据  -->
    <el-table
      :data="lostStatisticsVOS"
      :header-cell-style="{ color: 'black' }"
      height="375px"
      stripe
      style="width: 100%"
    >
      <el-table-column :label="$t('navbar.siteName')" align="center" prop="siteName" />
      <el-table-column align="center" label="遗失票数" prop="lostCount">
        <template #default="scope">
          <div v-if="scope.row.siteId === null" class="fontClsBold">
            {{ scope.row.lostCount || '--' }}
          </div>
          <div v-else>{{ scope.row.lostCount || '--' }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="遗失率" prop="lostRate">
        <template #default="scope">
          <div v-if="scope.row.siteId === null" class="fontClsBold">
            {{ scope.row.lostRate || '--' }}
          </div>
          <div v-else>{{ scope.row.lostRate || '--' }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="当月累计遗失票数" prop="lostCountMonth">
        <template #default="scope">
          <div v-if="scope.row.siteId === null" class="fontClsBold">
            {{ scope.row.lostCountMonth || '--' }}
          </div>
          <div v-else>{{ scope.row.lostCountMonth || '--' }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="月遗失率" prop="lostRateMonth">
        <template #default="scope">
          <div v-if="scope.row.siteId === null" class="fontClsBold">
            {{ scope.row.lostRateMonth || '--' }}
          </div>
          <div v-else>{{ scope.row.lostRateMonth || '--' }}</div>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  name: 'LossStatistics',
  props: {
    lostStatisticsVOS: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
};
</script>

<style scoped></style>
