// 操作平台
const operatingPlatform = {
  PleaseScan: {
    zh: '请扫描',
    en: 'Please scan',
    pu: 'Favor de escanear'
  },
  Upload: {
    zh: '上传',
    en: '',
    pu: 'Upload'
  },
  deleteSure: {
    zh: '确定删除吗？',
    en: '',
    pu: 'Tem certeza de apagar?'
  },
  PackageExist: {
    zh: '当前包裹号重复',
    en: '',
    pu: 'Duplicação o número do pacote atual'
  },
  nullMsg: {
    zh: '暂无数据',
    en: '',
    pu: 'Sem dados'
  },
  empty: {
    zh: '清空',
    en: 'empty',
    pu: 'Limpar'
  },
  confirm: {
    zh: '确认',
    en: '',
    pu: 'confirmar'
  },
  unsigned: {
    zh: '未签名!',
    en: '',
    pu: 'Não assinado'
  },
  NoDataWasQueried: {
    zh: '未查询到数据',
    en: 'No data was queried',
    pu: 'Nenhum dado foi consultado'
  },
  notNull: {
    zh: '不能为空',
    en: 'Cannot be empty',
    pu: 'Não pode estar vazio'
  },
  waybillNumber: {
    zh: '运单号',
    en: 'waybillNumber',
    pu: 'número de waybill'
  },
  scanTime: {
    zh: '扫描时间',
    en: '',
    pu: 'Tempo de varredura'
  },
  operation: {
    zh: '操作',
    en: 'operation',
    pu: 'Operar'
  },
  Delete: {
    zh: '删除',
    en: 'Delete',
    pu: 'Excluir'
  },
  pleaseInput: {
    zh: '请输入',
    en: 'Please enter the content',
    pu: 'por favor digitar'
  },
  inputNumbers: {
    zh: '请输入 6 ~ 8 数字',
    en: 'Please input numbers from 6~8',
    pu: 'Insira números de 6 a 8'
  },
  inputNumbers612: {
    zh: '请输入 6 ~ 12位字母或数字',
    en: 'Please enter 6 to 12 letters or numbers',
    pu: 'Por favor, insira de 6 a 12 letras ou números'
  },
  Weight: {
    zh: '重量：',
    en: 'Weight：',
    pu: 'Peso：'
  },
  scanWeight: {
    zh: '称重重量',
    en: 'Weight',
    pu: 'peso de pesagem'
  },
  noData: {
    zh: '无数据',
    en: '',
    pu: 'Não há dados disponíveis'
  },
  outOfWarehouseScan: {
    zh: '网点揽收出库扫描：',
    en: '',
    pu: 'Coleta e escaneamento quando fora do armazém no Ponto.：'
  },
  waybillNumRepeat: {
    zh: '当前运单号重复',
    en: '',
    pu: 'Duplicar o número de rastreamento atual'
  },
  nullSubmit: {
    zh: '无数据提交',
    en: '',
    pu: 'Sem apresentação de dados'
  },
  warehousingScan: {
    zh: '网点揽收入库扫描：',
    en: '',
    pu: 'Coleta e aescaneamento quando entrada do armazém no Ponto.：'
  },
  inputWeight: {
    zh: '输入重量：',
    en: '',
    pu: 'Digite o peso.：'
  },
  inputNumberHint: {
    zh: '请填写最大重量不能超过500kg的数字，可保留3位有效小数',
    en: '',
    pu: 'Por favor, preencha o valor do peso máximo que não pode exceder 500 kg, podendo manter 3 casas decimais válidas'
  },
  dispatchScan: {
    zh: '派件扫描：',
    en: '',
    pu: 'escaneamento ao ser entregue：'
  },
  dispatchwarehouseScan: {
    zh: '网点派件入库扫描：',
    en: '',
    pu: 'Entrada para escaneamento no ponto de entrega.：'
  },
  signForScan: {
    zh: '签收扫描：',
    en: '',
    pu: 'Escaneado ao assinar.：'
  },
  signature: {
    zh: '签名',
    en: '',
    pu: 'Assinatura'
  },
  dispatch: {
    zh: '派件',
    en: '',
    pu: 'Distribuição'
  },
  signFor: {
    zh: '签收',
    en: '',
    pu: 'Assinatura'
  },
  collectWarehousing: {
    zh: '网点揽收入库',
    en: '',
    pu: 'Entrada do armazém na coleta no Ponto'
  },
  collectOutOfWarehouse: {
    zh: '网点揽收出库',
    en: '',
    pu: 'Fora do armazém na coleta no Ponto'
  },
  dispatchWarehousing: {
    zh: '网点派件入库',
    en: '',
    pu: 'Distribuição de entrada no Ponto'
  },
  pleaseInputRightWeight: {
    zh: '请输入正确的重量',
    en: '',
    pu: 'favor inserir o peso correto'
  },
  AreYouSureSubmit: {
    zh: '是否确认上传?',
    en: '',
    pu: 'O upload é confirmado?'
  },
  tips: {
    zh: '提示',
    en: 'tips',
    pu: 'Dica'
  },
  cancel: {
    zh: '取消',
    en: 'cancel',
    pu: 'cancelar'
  },
  pleaseCheckContent: {
    zh: '请检查扫描框是否有内容',
    en: '',
    pu: 'favor marcar a caixa de verificação para conteúdo'
  },
  weighingMethod: {
    zh: '称重方式：',
    en: 'Weighing method：',
    pu: 'Método de peso：'
  },
  SerialPort: {
    zh: '串口',
    en: 'Serial port',
    pu: 'Porto série'
  },
  Other: {
    zh: '其它',
    en: 'other',
    pu: 'outros'
  },
  weighingEquipmentBrand: {
    zh: '称重设备品牌：',
    en: '',
    pu: 'Marcas de equipamentos de pesagem：'
  },
  plugInBeingConnected: {
    zh: '正在连接插件',
    en: 'Plug-in being connected',
    pu: 'lug-in sendo conectado'
  },
  pluginIsNotStarted: {
    zh: '插件未启动，请打开插件',
    en: 'Plugin is not started, please open the plugin',
    pu: 'O plug-in não foi iniciado, abra o plug-in'
  },
  DeviceInitializationInProgress: {
    zh: '设备初始化中',
    en: 'Device initialization in progress',
    pu: 'Inicialização do dispositivo em andamento'
  },
  DeviceInitializationSuccessful: {
    zh: '设备初始化成功',
    en: 'Device initialization successful',
    pu: 'Inicialização do dispositivo bem-sucedida'
  },
  ElectronicScaleInitializationFailed: {
    zh: '电子秤初始化失败，请检查电子秤或插件',
    en: 'Electronic scale initialization failed, please check the electronic scale or plug-in',
    pu: 'Falha na inicialização da balança eletrônica, verifique a balança eletrônica ou o plug-in'
  },
  bagOrPackageRepeat: {
    zh: '当前袋号/运单号重复',
    en: '',
    pu: 'duplicar o número atual da sacola/waybill'
  },
  joinOrNot: {
    zh: ' 不属于该袋号，是否加入到扫描列表？',
    en: '',
    pu: ' não pertence ao número da bolsa, ele é adicionado à lista de varredura?'
  },
  preventContinuousScan: {
    zh: '不能连续扫描两个袋号',
    en: '',
    pu: 'não é possível escanear dois números de sacola consecutivamente'
  },
  notSubmitEmptyBag: {
    zh: '不能提交空袋号',
    en: '',
    pu: 'não é possível enviar números de bolsas vazias'
  },
  notDelete: {
    zh: '当前袋号下还存在运单号，不允许删除',
    en: '',
    pu: 'O número da carta de porte ainda existe sob o número da bolsa atual e não pode ser excluído'
  },
  yes: {
    zh: '是',
    en: '',
    pu: 'Sim'
  },
  no: {
    zh: '否',
    en: '',
    pu: 'Não'
  },
  scanLargePackage: {
    zh: '请先扫描袋号',
    en: '',
    pu: 'Por favor, digitalizar primeiro o número da saca'
  },
  receivingType: {
    zh: '收件类型：',
    en: '',
    pu: 'tipo de recibo：'
  },
  recipient: {
    zh: '收件人：',
    en: 'recipient：',
    pu: 'Destinatário：'
  },
  receivingT: {
    zh: '收件类型',
    en: '',
    pu: 'Tipo do destinatário'
  },
  addressee: {
    zh: '收件人',
    en: 'recipient',
    pu: 'destinatário'
  },
  file: {
    zh: '文件：',
    en: 'file：',
    pu: 'arquivo：'
  },
  PleaseFillInTheRequiredFields: {
    zh: '请填写必填项',
    en: '',
    pu: 'Favor preencher os campos obrigatórios'
  },
  pleaseScanNumber: {
    zh: '请先扫描单号',
    en: '',
    pu: 'Primeiro, leia o número do pedido'
  },
  inPerson: {
    zh: '本人',
    en: '',
    pu: 'Próprio'
  },
  notOneself: {
    zh: '非本人',
    en: '',
    pu: 'Outra pessoa'
  },
  salesmanInfo: {
    zh: '业务员信息',
    en: '',
    pu: 'Informação do entregador'
  },
  deliverer: {
    zh: '派送人：',
    en: '',
    pu: 'Entregador：'
  },
  belongToSite: {
    zh: '所属网点：',
    en: 'belonging point：',
    pu: 'Pontos de Rede afiliados：'
  },
  receivingInfo: {
    zh: '收件信息',
    en: '',
    pu: 'Informação do destinatário'
  },
  waybillNum: {
    zh: '运单号：',
    en: 'waybillNumber：',
    pu: 'número de waybill：'
  },
  signTime: {
    zh: '签收时间：',
    en: '',
    pu: 'Hora de entrega：'
  },
  Comprimento: {
    zh: '长*宽*高 (cm)',
    en: '',
    pu: 'Comprimento*Largura*Altura (cm)'
  },
  long: {
    zh: '长',
    en: '',
    pu: 'ancião'
  },
  width: {
    zh: '宽',
    en: '',
    pu: 'sobrenome Kuan'
  },
  height: {
    zh: '高',
    en: '',
    pu: 'honorífico'
  },
  volumeWeight: {
    zh: '体积重',
    en: '',
    pu: 'peso volumétrico'
  },
  operator: {
    zh: '操作人',
    en: 'operator',
    pu: 'Operador'
  },
  racerDevices: {
    zh: '快手设备',
    en: '',
    pu: 'Equipamento do piloto'
  },
  please200: {
    zh: '多个袋号换行输入，最大输入两百个袋号',
    en: 'Please input multiple bag numbers separated by line breaks. The maximum input is 200 bag numbers.',
    pu: 'Por favor, insira vários números de saco separados por quebras de linha. O máximo de entrada é de 200 números de saco.'
  },
  assemblyCompleted: {
    zh: '已装配',
    en: '',
    pu: 'Já carregado'
  },
  carregamento: {
    zh: '装配完结',
    en: '',
    pu: 'Carregamento concluído'
  },
  exportBagData: {
    zh: '导出装袋数据',
    en: '',
    pu: 'Exportar dados da sacola'
  },
  exportWaybillDetails: {
    zh: '导出运单详情',
    en: '',
    pu: 'Exportar detalhes da nota fiscal'
  },
  viewUpdate: {
    zh: '查看/更新',
    en: '',
    pu: 'Visualizar/Atualizar'
  },
  scanOrEnterWaybillToAdd: {
    zh: '扫描或输入运单进行添加运单',
    en: '',
    pu: 'Digitalizar ou digitar a nota fiscal para adicionar'
  },
  weightKG: {
    zh: '重量（KG）',
    en: '',
    pu: 'Peso (KG)'
  },
  emptyBagCannotBeSubmitted: {
    zh: '空袋无法提交',
    en: '',
    pu: 'Sacola vazia não pode ser enviada'
  },
  bagSealedSuccessfully: {
    zh: '封袋成功',
    en: '',
    pu: 'Sacola selada com sucesso'
  },
  waybillNumberNotFound: {
    zh: '未查询到该运单号',
    en: '',
    pu: 'Número da nota fiscal não encontrado'
  },
  serviceProviderInconsistency: {
    zh: '服务商不一致',
    en: 'Service provider inconsistency',
    pu: 'Inconsistência do provedor de serviço'
  },
  deliveryPointInconsistency: {
    zh: '派送网点不一致',
    en: 'Delivery point inconsistency',
    pu: 'Inconsistência do ponto de entrega'
  },
  bagNumberReplacement: {
    zh: '袋号替换',
    en: '',
    pu: 'Troca de saca'
  },
  currentBagNumber: {
    zh: '当前袋号',
    en: '',
    pu: 'Número atual da saca'
  },
  enterTheCurrentBagNumber: {
    zh: '输入当前袋号',
    en: '',
    pu: 'Digite o número atual da bag'
  },
  newBagNumber: {
    zh: '新袋号',
    en: '',
    pu: 'Novo número da saca'
  },
  enterTheNewBagNumber: {
    zh: '输入新袋号',
    en: '',
    pu: 'Digite o novo número da bag'
  },
  BagNumberLimit: {
    zh: '袋号必须大于6位数小于8位数',
    en: '',
    pu: 'Campo precisa ser maior que 6 e menor que 8 dígitos numéricos'
  },
  BagNumberLimitNew: {
    zh: '袋号只能输入6-12位',
    en: '',
    pu: 'O nº de saca só pode conter 6-12 dígitos'
  },
  swap: {
    zh: '替换',
    en: '',
    pu: 'Trocar saca'
  }
};
export {
  operatingPlatform
};
