<template>
  <div class="app-container">
    <el-card class="box-card">
      <el-form
        class="queryFormClass"
        label-position="top"
        size="small"
      >
        <el-row :gutter="10">
          <el-col :span="6">
            <!-- 导入文件时间 -->
            <el-form-item :label="$t('receivablePayable.导入文件时间')">
              <el-date-picker
                v-model="queryForm.params.importTimes"
                type="datetimerange"
                :picker-options="pickerOptions"
                :start-placeholder="$t('GlobalSetObj.startDate')"
                :end-placeholder="$t('GlobalSetObj.endDate')"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <!-- 模块 -->
            <el-form-item :label="$t('collectionCenter.module')">
              <el-select
                v-model="queryForm.params.model" clearable :placeholder="$t('GlobalSetObj.pleaseChoose')"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in MODULE_OBJ"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <!-- 导入状态 -->
            <el-form-item :label="$t('receivablePayable.导入状态')">
              <el-select
                v-model="queryForm.params.status" clearable :placeholder="$t('GlobalSetObj.pleaseChoose')"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in IMPORT_STATUS_OBJ"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-row type="flex" justify="start">
        <el-col :span="8">
          <div>
            <!--查询-->
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="onSearch"
            >{{ $t('basicData.Search') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh"
              size="mini"
              @click="onReset"
            >{{ $t('basicData.reset') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <el-card>
      <el-table
        ref="elTable"
        :data="tableData"
        border
        class="table-height"
        :height="tableHeightRow.height"
      >
        <!--序号-->
        <el-table-column :label="$t('basicData.serialNumber')" width="80px" align="center">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="modelStr" :label="$t('collectionCenter.module')" min-width="120">
          <template slot-scope="scope">
            <!-- <el-button size="mini">{{ scope.row.modelStr }}</el-button> -->
            <el-tag type="info">{{ scope.row.modelStr }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" :label="$t('receivablePayable.导入文件时间')" width="180" />
        <el-table-column prop="operateModuleStr" :label="$t('receivablePayable.操作功能')" width="180" />
        <el-table-column prop="createUserStr" :label="$t('receivablePayable.导入人')" width="120" />
        <el-table-column prop="importSucessTime" :label="$t('receivablePayable.导入时间')" width="180" />
        <el-table-column prop="statusStr" :label="$t('receivablePayable.导入状态')" width="140">
          <template slot-scope="scope">
            <el-link v-if="scope.row.status === IMPORT_STATUS_OBJ['0'].value" :underline="false" type="info">{{ scope.row.statusStr }}</el-link>
            <el-link v-else-if="scope.row.status === IMPORT_STATUS_OBJ['1'].value" :underline="false" type="success">{{ scope.row.statusStr }}</el-link>
            <el-link v-else-if="scope.row.status === IMPORT_STATUS_OBJ['2'].value" :underline="false" type="danger">{{ scope.row.statusStr }}</el-link>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('collectionCenter.ReasonForFailure')">
          <template slot-scope="scope">
            <FailureDescriptionCmp :row="scope.row" />
            <!-- <TesdtCmp :row="scope.row" /> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="asyncGetTableData"
          @size-change="asyncGetTableData"
        />
      </div>
    </el-card>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { setDisabledDate } from '@/views/finance/basicData/PriceInquiryLog/utils.js';
import { apiFaFinancialImportExcelLogPage } from '@/api/finance/fileUploadManagement';
import { MODULE_OBJ, OPERATION_OBJ, IMPORT_STATUS_OBJ } from './utils';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import FailureDescriptionCmp from './cmp/FailureDescriptionCmp.vue';

const now = dayjs(); // 当前时间
// 获取一天前的日期
const startOfDay = dayjs().subtract(1, 'day').format('YYYY-MM-DD') + ' 00:00:00'; // 一天的开始
const endOfDay = now.endOf('day').format('YYYY-MM-DD HH:mm:ss'); // 一天的开始

export default {
  name: '',

  components: {
    FailureDescriptionCmp
  },

  mixins: [estimateTableHeight],

  data() {
    return {
      MODULE_OBJ,
      IMPORT_STATUS_OBJ,
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          importTimes: '',
          status: '',
          model: ''
        }
      },
      total: 0,
      tableData: [],

      options: [],

      choiceDate0: '',

      pickerOptions: {
        // 设置不能选择的日期
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate0 = minDate.getTime();
          if (maxDate) {
            this.choiceDate0 = '';
          }
        },
        disabledDate: (time) => {
          return setDisabledDate(time, this.choiceDate0, 14);
        }
      }
    };
  },

  created() {
    // 用户列表
    this.userIdAndNameObj = getUserIdAndNameObj();

    // 初始化时间
    this.queryForm.params.importTimes = [startOfDay, endOfDay];
    this.asyncGetTableData();
  },

  methods: {
    onSearch() {
      this.queryForm.pageNumber = 1;
      this.asyncGetTableData();
    },

    // 重置
    onReset() {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = 10;
      this.queryForm.params.importTimes = [startOfDay, endOfDay];
      this.queryForm.params.status = '';
      this.queryForm.params.model = '';
    },

    getQueryData() {
      const queryForm = this.queryForm;

      const obj = {
        ...queryForm,
        params: {
          ...queryForm.params
        }
      };

      // 时间处理
      if (!Array.isArray(obj.params.importTimes)) {
        obj.params.importTimes = [];
      }

      return obj;
    },

    // 获取列表
    asyncGetTableData() {
      const row = this.getQueryData();
      apiFaFinancialImportExcelLogPage(row).then(res => {
        const { total, records } = res.data;

        this.total = total;
        this.tableData = (records || []).map(item => {
          const { id, model, importTime, operateModule, createUser, importSucessTime, status, failureDescription, createTime } = item;

          const modelStr = MODULE_OBJ[model]?.name || '';
          const statusStr = IMPORT_STATUS_OBJ[status]?.name || '';
          const operateModuleStr = OPERATION_OBJ[operateModule]?.name || '';

          const createUserStr = this.userIdAndNameObj[createUser] || '';

          const obj = {
            id,
            model, // 模块
            modelStr, // 模块
            importTime, // 导入文件时间
            operateModule, // 操作功能
            operateModuleStr, // 操作功能
            createUser, // 导入人
            createUserStr, // 导入人
            importSucessTime, // 导入时间
            status, // 导入状态
            statusStr, // 导入状态
            failureDescription, // 失败原因
            createTime
          };

          return Object.freeze(obj);
        });
      }).catch(err => {
        console.error(err);
      });
    }
  }

};
</script>

<style lang="scss" scoped>
.box-card {
  margin-bottom: 10px;
}
</style>
