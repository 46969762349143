import i18n from '@/lang';

// 搜索字段
export const SEARCH_PARAMS = {
  providerId: '', // 主体id
  siteId: '', // 网点id
  startTime: '', // 业务开始时间
  endTime: '', // 业务结束时间
  timeField: 'operate_time', // 查询时间类型 入库时间:input_time 出库时间:out_time 业务时间:operate_time
  numberField: 'waybill_number', // waybill_number业务单号 customer_number客户单号 order_number条形码（揽收单号/订单号）
  numberList: [], // 单号
  billBatchNumber: '', // 批次号
  payableNumber: '', // 账单号
  feeCode: '', // 费用编码
  feeName: '', // 费用类型
  businessType: '', // 业务类型 1 安骏 2 第三方 3 AJD派送 4 DSP派送 5运输干支线
  collectType: null // 揽收方式 2上门揽收 1上门交件
};

// 业务搜索 单号
export const NUMBER_FIELD = {
  'waybill_number': {
    value: 'waybill_number', // 业务单号
    label: i18n.t('receivablePayable.TicketNumber')
  },
  'customer_number': {
    value: 'customer_number', // 客户单号
    label: i18n.t('receivablePayable.customerNumber')
  }
};

/**
 * 成本费用表格头部配置
 * @type {[{prop: string, label: VueI18n.TranslateResult, width: number, fixed: string, highlight: boolean, disabled: boolean},{prop: string, label: VueI18n.TranslateResult, width: number, fixed: string, highlight: boolean, disabled: boolean},{prop: string, label: VueI18n.TranslateResult, width: number, fixed: string, highlight: boolean, disabled: boolean},{prop: string, label: VueI18n.TranslateResult, width: number, fixed: string, highlight: boolean, disabled: boolean},{prop: string, label: VueI18n.TranslateResult, width: number, fixed: string, highlight: boolean, disabled: boolean},null,null]}
 */
export const TABLE_HEADER = [
  // 业务单号
  { prop: 'waybillNumber', label: i18n.t('receivablePayable.TicketNumber'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 客户单号
  { prop: 'customerNumber', label: i18n.t('receivablePayable.customerNumber'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 成本主体
  { prop: 'providerName', label: i18n.t('receivablePayable.costumer'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 成本网点
  { prop: 'siteName', label: i18n.t('receivablePayable.costOutlets'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 业务类型
  { prop: 'businessType', label: i18n.t('collectionCenter.businessT'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 揽收方式
  { prop: 'collectType', label: i18n.t('collectionCenter.lanshouTypee'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 计费重量
  { prop: 'chargeWeight', label: i18n.t('receivablePayable.BillableWeight'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 成本总费用
  { prop: 'totalFee', label: i18n.t('receivablePayable.totalCost'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 备注
  { prop: 'remark', label: i18n.t('receivablePayable.remark'), width: 200, fixed: 'left', highlight: false, disabled: false }
];

