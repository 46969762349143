var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "listQueryBox" }, [
    _c(
      "div",
      { staticStyle: { "margin-bottom": "20px" } },
      [
        _c(
          "el-form",
          { staticClass: "selectClass", attrs: { "label-width": "120px" } },
          [
            _c(
              "el-row",
              {
                staticClass: "listQueryTime",
                staticStyle: { "flex-wrap": "wrap" },
                attrs: { type: "flex", justify: "start" },
              },
              [
                _c(
                  "el-col",
                  { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "col2" },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "selectTimeClass autoWidth",
                                attrs: {
                                  placeholder: _vm.$t(
                                    "collectionCenter.pleaseChoose"
                                  ),
                                },
                                on: { change: _vm.changeTime },
                                model: {
                                  value: _vm.selectTime,
                                  callback: function ($$v) {
                                    _vm.selectTime = $$v
                                  },
                                  expression: "selectTime",
                                },
                              },
                              [
                                _c("template", { slot: "prefix" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (
                                          _vm.timeList.find(function (item) {
                                            return item.value === _vm.selectTime
                                          }) || {}
                                        ).time
                                      ) +
                                      " "
                                  ),
                                ]),
                                _vm._l(_vm.timeList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.time,
                                      value: item.value,
                                    },
                                  })
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "timeSelectClass",
                            staticStyle: { width: "auto" },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "delMl",
                                attrs: { "label-width": "0px" },
                              },
                              [
                                _c("el-date-picker", {
                                  ref: "dateRef",
                                  attrs: {
                                    clearable: false,
                                    type: "daterange",
                                    "value-format": "yyyy-MM-dd",
                                    "default-time": ["00:00:00", "23:59:59"],
                                    "range-separator": _vm.$t(
                                      "collectionCenter.to"
                                    ),
                                    "start-placeholder": _vm.$t(
                                      "collectionCenter.StartTime"
                                    ),
                                    "end-placeholder": _vm.$t(
                                      "collectionCenter.EndTime"
                                    ),
                                  },
                                  on: { change: _vm.dateChange },
                                  model: {
                                    value: _vm.timeValue,
                                    callback: function ($$v) {
                                      _vm.timeValue = $$v
                                    },
                                    expression: "timeValue",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    staticClass: "textareaBox",
                    staticStyle: { width: "auto" },
                    attrs: { span: 8 },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("collectionCenter.customerNumber"),
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            clearable: "",
                            type: "textarea",
                            autosize: "",
                            placeholder: _vm.$t("collectionCenter.BatchQuery"),
                          },
                          on: { blur: _vm.filter },
                          nativeOn: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.customerNumberEnter($event)
                            },
                          },
                          model: {
                            value: _vm.queryForm.params.customerNumber,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "customerNumber",
                                $$v
                              )
                            },
                            expression: "queryForm.params.customerNumber",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    staticClass: "textareaBox",
                    staticStyle: { width: "auto" },
                    attrs: { span: 8 },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("collectionCenter.mainWaybillNumber"),
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            clearable: "",
                            type: "textarea",
                            autosize: "",
                            placeholder: _vm.$t("collectionCenter.BatchQuery"),
                          },
                          on: { blur: _vm.filter },
                          nativeOn: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.masterWaybillNumberEnter($event)
                            },
                          },
                          model: {
                            value: _vm.queryForm.params.masterWaybillNumber,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "masterWaybillNumber",
                                $$v
                              )
                            },
                            expression: "queryForm.params.masterWaybillNumber",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("collectionCenter.orderStatus"),
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseChoose"
                              ),
                              clearable: "",
                              filterable: "",
                            },
                            model: {
                              value: _vm.queryForm.params.orderState,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "orderState",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.orderState",
                            },
                          },
                          _vm._l(_vm.orderTypeList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: item.collectStatusName,
                                value: item.collectStatus,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label:
                            _vm.$t("ClaimsManagement.PaymentMethod") + "：",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseChoose"
                              ),
                              clearable: "",
                            },
                            model: {
                              value: _vm.queryForm.params.payType,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryForm.params, "payType", $$v)
                              },
                              expression: "queryForm.params.payType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: {
                                label: _vm.$t("collectionCenter.tudo"),
                                value: 2,
                              },
                            }),
                            _c("el-option", {
                              attrs: { label: "OnLine", value: 0 },
                            }),
                            _c("el-option", {
                              attrs: { label: "OffLine", value: 1 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticStyle: { width: "auto" }, attrs: { span: 7 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("collectionCenter.lanshouType"),
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseChoose"
                              ),
                            },
                            model: {
                              value: _vm.queryForm.params.pickupType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "pickupType",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.pickupType",
                            },
                          },
                          [
                            _c("el-option", {
                              key: "0",
                              attrs: {
                                label: _vm.$t("collectionCenter.tudo"),
                                value: null,
                              },
                            }),
                            _c("el-option", {
                              key: "1",
                              attrs: {
                                label: _vm.$t("collectionCenter.dropOff"),
                                value: 1,
                              },
                            }),
                            _c("el-option", {
                              key: "2",
                              attrs: {
                                label: _vm.$t("collectionCenter.doorPickUp"),
                                value: 2,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    staticClass: "textareaBox",
                    staticStyle: { width: "auto" },
                    attrs: { span: 8 },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("collectionCenter.licensePlateNum"),
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            autosize: "",
                            placeholder: _vm.$t("collectionCenter.BatchQuery"),
                          },
                          on: { blur: _vm.ft },
                          nativeOn: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.BlockNewline($event)
                            },
                          },
                          model: {
                            value: _vm.queryForm.params.vehicleNumber,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "vehicleNumber",
                                $$v
                              )
                            },
                            expression: "queryForm.params.vehicleNumber",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    staticClass: "textareaBox",
                    staticStyle: { width: "auto" },
                    attrs: { span: 8 },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("orderCenterCont.TimeoutOrNot") + "：",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseChoose"
                              ),
                            },
                            model: {
                              value: _vm.queryForm.params.timeoutStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "timeoutStatus",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.timeoutStatus",
                            },
                          },
                          [
                            _c("el-option", {
                              key: "0",
                              attrs: {
                                label: _vm.$t("collectionCenter.tudo"),
                                value: 0,
                              },
                            }),
                            _c("el-option", {
                              key: "1",
                              attrs: {
                                label: _vm.$t("GlobalSetObj.yes"),
                                value: 1,
                              },
                            }),
                            _c("el-option", {
                              key: "2",
                              attrs: {
                                label: _vm.$t("GlobalSetObj.No"),
                                value: 2,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c(
              "el-col",
              { attrs: { span: 12 } },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value:
                          "btn:collection-center:order:stockUp-receiveOrders:find",
                        expression:
                          "'btn:collection-center:order:stockUp-receiveOrders:find'",
                        arg: "remove",
                      },
                    ],
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-search",
                    },
                    on: { click: _vm.searchClick },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value:
                          "btn:collection-center:order:stockUp-receiveOrders:reset",
                        expression:
                          "'btn:collection-center:order:stockUp-receiveOrders:reset'",
                        arg: "remove",
                      },
                    ],
                    attrs: { icon: "el-icon-refresh", size: "mini" },
                    on: { click: _vm.resetClick },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value:
                          "btn:collection-center:order:stockUp-receiveOrders:export",
                        expression:
                          "'btn:collection-center:order:stockUp-receiveOrders:export'",
                        arg: "remove",
                      },
                    ],
                    class:
                      _vm.idList.length <= 0 && _vm.isClick
                        ? "onlyClickOnce10"
                        : "",
                    attrs: { icon: "el-icon-upload2", size: "mini" },
                    on: { click: _vm.download },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.export")))]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value:
                          "btn:collection-center:order:stockUp-receiveOrders:exportAll",
                        expression:
                          "'btn:collection-center:order:stockUp-receiveOrders:exportAll'",
                        arg: "remove",
                      },
                    ],
                    class: _vm.isClickAll ? "onlyClickOnce10" : "",
                    attrs: { icon: "el-icon-upload", size: "mini" },
                    on: { click: _vm.downloadAll },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("el-divider"),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "el-table",
          {
            ref: "tableRef",
            attrs: {
              border: "",
              "show-summary": "",
              "summary-method": _vm.getSummaries,
              data: _vm.tableDate,
              "max-height": 600,
            },
            on: { select: _vm.batchSelect, "select-all": _vm.batchSelectAll },
          },
          [
            _c("el-table-column", {
              attrs: { type: "selection", width: "55" },
            }),
            _c("el-table-column", {
              attrs: {
                label: _vm.$t("collectionCenter.ind"),
                type: "index",
                width: "60",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            (_vm.queryForm.pageNumber - 1) *
                              _vm.queryForm.pageSize +
                              scope.$index +
                              1
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createTime",
                label: _vm.$t("collectionCenter.orderD"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "expectPickupTime",
                label: _vm.$t("collectionCenter.ExpectedCollectionTime"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "timedOut",
                label: _vm.$t("orderCenterCont.TimeoutOrNot"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "customerNumber",
                label: _vm.$t("collectionCenter.customerNum"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "masterWaybillNumber",
                label: _vm.$t("collectionCenter.mainWaybillNum"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "pickupTypeDesc",
                label: _vm.$t("collectionCenter.lanshouTypee"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "senderCompany",
                label: _vm.$t("collectionCenter.SenderCompany"),
                align: "center",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "senderName",
                label: _vm.$t("collectionCenter.addresser"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "senderPostalCode",
                label: _vm.$t("collectionCenter.senderZipCode"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "senderAddress",
                label: _vm.$t("collectionCenter.SenderAddress"),
                align: "center",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "senderPhone",
                label: _vm.$t("collectionCenter.senderMobile"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "payTypeName",
                label: _vm.$t("ClaimsManagement.PaymentMethod"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "receiveName",
                label: _vm.$t("collectionCenter.recipientS"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "receivePostalCode",
                label: _vm.$t("collectionCenter.Recipientzc"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "receiveAddress",
                label: _vm.$t("collectionCenter.ReceivingAddress"),
                align: "center",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "receivePhone",
                label: _vm.$t("collectionCenter.RecipientPhoneNumber"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "count",
                label: _vm.$t("collectionCenter.estimatedCollectedQuantity"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "actualCount",
                label: _vm.$t("collectionCenter.actualCollectedQuantity"),
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          class:
                            scope.row.actualCount < scope.row.count
                              ? "redFont"
                              : "",
                        },
                        [_vm._v(_vm._s(scope.row.actualCount))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "weight",
                label: _vm.$t("collectionCenter.predictWeight"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "actualWeight",
                label: _vm.$t("collectionCenter.actualCollectedWeight"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "collectTime",
                label: _vm.$t("newOrder.pickUpDate"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "orderStateName",
                label: _vm.$t("collectionCenter.OrderStatus"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "vehicleNumber",
                label: _vm.$t("collectionCenter.licensePlateNumber"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "nfeInvoiceValue",
                label: _vm.$t("collectionCenter.allDeclaredValue"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "nfeInvoiceType",
                label: _vm.$t("collectionCenter.commodityInvoiceType"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "nfeInvoiceKey",
                label: _vm.$t("collectionCenter.commodityInvoiceKey"),
                align: "center",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "logisticsInvoiceType",
                label: _vm.$t("collectionCenter.LogisticsInvoiceType"),
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "logisticsInvoiceSecretKey",
                label: _vm.$t("collectionCenter.LogisticsInvoiceKey"),
                align: "center",
              },
            }),
            _vm.isDetail
              ? _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("collectionCenter.operation"),
                    fixed: "right",
                    width: "100",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.cellClick(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("collectionCenter.detail"))
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    43953860
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "right" },
      [
        _c("el-pagination", {
          staticStyle: { margin: "10px 0" },
          attrs: {
            layout: "total, sizes, prev, pager, next, jumper",
            "current-page": _vm.queryForm.pageNumber,
            "page-sizes": [10, 20, 50, 100, 500, 1000],
            "page-size": _vm.queryForm.pageSize,
            total: _vm.total,
            background: "",
          },
          on: {
            "update:currentPage": function ($event) {
              return _vm.$set(_vm.queryForm, "pageNumber", $event)
            },
            "update:current-page": function ($event) {
              return _vm.$set(_vm.queryForm, "pageNumber", $event)
            },
            "update:pageSize": function ($event) {
              return _vm.$set(_vm.queryForm, "pageSize", $event)
            },
            "update:page-size": function ($event) {
              return _vm.$set(_vm.queryForm, "pageSize", $event)
            },
            "current-change": _vm.getListPage,
            "size-change": _vm.getPageSize,
          },
        }),
      ],
      1
    ),
    _vm.drawerIsOpen
      ? _c(
          "div",
          { staticClass: "drawerOpen" },
          [
            _c(
              "el-dialog",
              {
                attrs: { title: "", visible: _vm.drawerIsOpen, width: "80%" },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawerIsOpen = $event
                  },
                },
              },
              [
                _c("div", [
                  _c("div", { staticClass: "msgClass" }, [
                    _c("div", { staticClass: "msgTitle" }),
                    _c("div", { staticClass: "msgContent" }, [
                      _c("div", { staticClass: "l" }, [
                        _vm._v(
                          _vm._s(_vm.$t("collectionCenter.basicInformation"))
                        ),
                      ]),
                      _c("div", { staticClass: "r" }, [
                        _c("div", { staticClass: "msgRow" }, [
                          _c("div", [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.customerNum"))
                              ),
                            ]),
                            _c("span", { staticClass: "code" }, [
                              _vm._v(_vm._s(_vm.detailList.customerNumber)),
                            ]),
                          ]),
                          _c("div", [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("collectionCenter.mainWaybillNum")
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "code" }, [
                              _vm._v(
                                _vm._s(_vm.detailList.masterWaybillNumber)
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "msgRow" }, [
                          _c("div", [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("collectionCenter.verificationCode")
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "code" }, [
                              _vm._v(_vm._s(_vm.detailList.pickUpCode)),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "msgRow" }, [
                          _c("div", [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.quantity"))
                              ),
                            ]),
                            _c("span", { staticClass: "code" }, [
                              _vm._v(_vm._s(_vm.detailList.count)),
                            ]),
                          ]),
                          _c("div", [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.predictWeight"))
                              ),
                            ]),
                            _c("span", { staticClass: "code" }, [
                              _vm._v(_vm._s(_vm.detailList.weight)),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "msgContent" }, [
                      _c("div", { staticClass: "l" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("collectionCenter.collectingInformation")
                          )
                        ),
                      ]),
                      _c("div", { staticClass: "r" }, [
                        _c("div", { staticClass: "msgRow" }, [
                          _c("div", [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.lanshouPeople"))
                              ),
                            ]),
                            _c("span", { staticClass: "code" }, [
                              _vm._v(_vm._s(_vm.detailList.senderName)),
                            ]),
                          ]),
                          _c("div", [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.lanshouPhone"))
                              ),
                            ]),
                            _c("span", { staticClass: "code" }, [
                              _vm._v(_vm._s(_vm.detailList.senderPhone)),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "msgRow" }, [
                          _c("div", [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("collectionCenter.lanshouAddress")
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "code" }, [
                              _vm._v(_vm._s(_vm.detailList.senderAddress)),
                            ]),
                          ]),
                          _c("div", [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("collectionCenter.lanshouZipcode")
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "code" }, [
                              _vm._v(_vm._s(_vm.detailList.senderPostalCode)),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "msgContent" }, [
                      _c("div", { staticClass: "l" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("collectionCenter.recipientInformation")
                          )
                        ),
                      ]),
                      _c("div", { staticClass: "r" }, [
                        _c("div", { staticClass: "msgRow" }, [
                          _c("div", [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.receivePeople"))
                              ),
                            ]),
                            _c("span", { staticClass: "code" }, [
                              _vm._v(_vm._s(_vm.detailList.receiveName)),
                            ]),
                          ]),
                          _c("div", [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.receivePhone"))
                              ),
                            ]),
                            _c("span", { staticClass: "code" }, [
                              _vm._v(_vm._s(_vm.detailList.receivePhone)),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "msgRow" }, [
                          _c("div", [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("collectionCenter.receiveAddress")
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "code" }, [
                              _vm._v(_vm._s(_vm.detailList.receiveAddress)),
                            ]),
                          ]),
                          _c("div", [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("collectionCenter.receiveZipcode")
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "code" }, [
                              _vm._v(_vm._s(_vm.detailList.receivePostalCode)),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "msgTitle" }, [
                      _vm._v(_vm._s(_vm.$t("collectionCenter.listingQuery"))),
                    ]),
                    _c("div", { staticClass: "msgContent" }, [
                      _c("div", { staticClass: "l" }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.pickUpTotal"))),
                      ]),
                      _c("div", { staticClass: "r" }, [
                        _c("div", { staticClass: "msgRow" }, [
                          _c("div", [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "collectionCenter.actualReceivedNumber"
                                  )
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "code" }, [
                              _vm._v(_vm._s(_vm.detailList.actualCount)),
                            ]),
                          ]),
                          _c("div", [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(_vm.$t("collectionCenter.OrderStatus"))
                              ),
                            ]),
                            _c("span", { staticClass: "code" }, [
                              _vm._v(_vm._s(_vm.detailList.orderStateName)),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            border: "",
                            data: _vm.detailTableDate,
                            "max-height": 400,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("collectionCenter.ind"),
                              type: "index",
                              width: "60",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            (_vm.queryDetailForm.pageNumber -
                                              1) *
                                              _vm.queryDetailForm.pageSize +
                                              scope.$index +
                                              1
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2379197816
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "customerSubNumber",
                              label: _vm.$t("collectionCenter.scanOrderNumber"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "weighingWeight",
                              label: _vm.$t("collectionCenter.actualWeight"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("operatingPlatform.volumeWeight"),
                              align: "center",
                              prop: "volumeWeight",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "subOrderStateName",
                              label: _vm.$t("collectionCenter.operationType"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectTime",
                              label: _vm.$t("collectionCenter.lanshouT"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectUserName",
                              label: _vm.$t("collectionCenter.LanShouPeople"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectSiteName",
                              label: _vm.$t("collectionCenter.collectionDot"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectSignImg",
                              label: _vm.$t(
                                "collectionCenter.collectionSignaturePhoto"
                              ),
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: scope.row.collectSignImg,
                                              expression:
                                                "scope.row.collectSignImg",
                                            },
                                          ],
                                          staticStyle: { color: "#551a8b" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openUrl(
                                                scope.row.collectSignImg
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("collectionCenter.View")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2011181026
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectPhoto",
                              label: _vm.$t("collectionCenter.collectPhoto"),
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: scope.row.collectPhoto,
                                              expression:
                                                "scope.row.collectPhoto",
                                            },
                                          ],
                                          staticStyle: { color: "#551a8b" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openUrl(
                                                scope.row.collectPhoto
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("collectionCenter.View")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3143022366
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "loadUserName",
                              label: _vm.$t("collectionCenter.loader"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "loadTime",
                              label: _vm.$t("collectionCenter.loadTime"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "loadSiteName",
                              label: _vm.$t("collectionCenter.loadSiteIdList"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "loadSignImg",
                              label: _vm.$t("collectionCenter.loadSign"),
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            title: _vm.$t(
                                              "collectionCenter.loadSign"
                                            ),
                                            content: "",
                                            placement: "left-start",
                                            trigger: "hover",
                                            width: "300",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: scope.row.loadSignImg,
                                                expression:
                                                  "scope.row.loadSignImg",
                                              },
                                            ],
                                            staticStyle: {
                                              width: "26px",
                                              height: "36px",
                                            },
                                            attrs: {
                                              slot: "reference",
                                              src: scope.row.loadSignImg,
                                              alt: "",
                                            },
                                            slot: "reference",
                                          }),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !scope.row.loadSignImg,
                                                  expression:
                                                    "!scope.row.loadSignImg",
                                                },
                                              ],
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "collectionCenter.Null"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          scope.row.loadSignImg
                                            ? _c("img", {
                                                staticStyle: {
                                                  width: "100%",
                                                  height: "100%",
                                                },
                                                attrs: {
                                                  src: scope.row.loadSignImg,
                                                  alt: "",
                                                },
                                              })
                                            : _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "collectionCenter.NoContentYet"
                                                    )
                                                  )
                                                ),
                                              ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2812213438
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "unloadUserName",
                              label: _vm.$t("collectionCenter.unloader"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "unloadTime",
                              label: _vm.$t("collectionCenter.unloadTime"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "unloadSiteName",
                              label: _vm.$t("collectionCenter.unloadDot"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "unloadSignImg",
                              label: _vm.$t("collectionCenter.unLoadSign"),
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            title: _vm.$t(
                                              "collectionCenter.unLoadSign"
                                            ),
                                            content: "",
                                            placement: "left-start",
                                            trigger: "hover",
                                            width: "300",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: scope.row.unloadSignImg,
                                                expression:
                                                  "scope.row.unloadSignImg",
                                              },
                                            ],
                                            staticStyle: {
                                              width: "26px",
                                              height: "36px",
                                            },
                                            attrs: {
                                              slot: "reference",
                                              src: scope.row.unloadSignImg,
                                              alt: "",
                                            },
                                            slot: "reference",
                                          }),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    !scope.row.unloadSignImg,
                                                  expression:
                                                    "!scope.row.unloadSignImg",
                                                },
                                              ],
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "collectionCenter.Null"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          scope.row.unloadSignImg
                                            ? _c("img", {
                                                staticStyle: {
                                                  width: "100%",
                                                  height: "100%",
                                                },
                                                attrs: {
                                                  src: scope.row.unloadSignImg,
                                                  alt: "",
                                                },
                                              })
                                            : _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "collectionCenter.NoContentYet"
                                                    )
                                                  )
                                                ),
                                              ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4250299614
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "dispatchUserName",
                              label: _vm.$t("collectionCenter.signer"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "dispatchSignSiteName",
                              label: _vm.$t("collectionCenter.deliveryDot"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "dispatchTime",
                              label: _vm.$t("collectionCenter.signT"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "dispatchSignImg",
                              label: _vm.$t(
                                "collectionCenter.signForSignaturePhoto"
                              ),
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: scope.row.dispatchSignImg,
                                              expression:
                                                "scope.row.dispatchSignImg",
                                            },
                                          ],
                                          staticStyle: { color: "#551a8b" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openUrl(
                                                scope.row.dispatchSignImg
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("collectionCenter.View")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2232940780
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "itemValue",
                              label: _vm.$t(
                                "collectionCenter.suborderDeclaredValue"
                              ),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "itemCnName",
                              label: _vm.$t("collectionCenter.tradeName"),
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.itemCnName ||
                                              scope.row.itemName
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1286949232
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "weight",
                              label: _vm.$t("collectionCenter.ForecastWeight"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "quantity",
                              label: _vm.$t(
                                "newOperationCenter.NumberPackages"
                              ),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "actualWeight",
                              label: _vm.$t("collectionCenter.ActualWeight"),
                              align: "center",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-pagination", {
                        staticStyle: { margin: "10px 0" },
                        attrs: {
                          layout: "total, sizes, prev, pager, next, jumper",
                          "current-page": _vm.queryDetailForm.pageNumber,
                          "page-sizes": [10, 20, 50, 100],
                          "page-size": _vm.queryDetailForm.pageSize,
                          total: _vm.detailTotal,
                          background: "",
                        },
                        on: {
                          "update:currentPage": function ($event) {
                            return _vm.$set(
                              _vm.queryDetailForm,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:current-page": function ($event) {
                            return _vm.$set(
                              _vm.queryDetailForm,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:pageSize": function ($event) {
                            return _vm.$set(
                              _vm.queryDetailForm,
                              "pageSize",
                              $event
                            )
                          },
                          "update:page-size": function ($event) {
                            return _vm.$set(
                              _vm.queryDetailForm,
                              "pageSize",
                              $event
                            )
                          },
                          "current-change": _vm.getDetailPage,
                          "size-change": _vm.searchDetailPage,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }