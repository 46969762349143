<template>
  <div class="app-container">
    <el-form :inline="false" label-position="top" size="small">
      <el-row :gutter="20">
        <!-- 网点编号-->
        <el-col :span="4">
          <el-form-item :label="$t('collectionCenter.siteNum')">
            <el-input
              v-model="queryForm.params.siteCode"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              clearable
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--   网点名称   -->
          <el-form-item :label="$t('collectionCenter.siteName')">
            <el-input
              v-model="queryForm.params.siteName"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              clearable
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <!--  状态   -->
          <el-form-item label="">
            <div style="margin-top: 27px">
              <el-radio-group v-model="queryForm.params.limitCountCondition">
                <el-radio :label="0">{{ $t('home.limitMaxNumber') }}</el-radio>
                <el-radio :label="1">{{ $t('home.limitMinxNumber') }}</el-radio>
              </el-radio-group>
              <el-radio-group
                v-model="queryForm.params.justSearchCondition"
                style="margin-left: 20px"
              >
                <el-radio :label="0">{{ $t('home.justSearchConditionCenter') }}</el-radio>
                <el-radio :label="1">{{ $t('home.batchConfigurationSite') }}</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="15" style="margin-bottom: 15px">
          <!--搜索-->
          <el-button
            size="small" type="primary" @click="enterSearch"
          >{{ $t('collectionCenter.search') }}
          </el-button>
          <!--返回登记-->
          <el-button
            size="small"
            type="primary"
            @click="returnToRegistration($t('home.returnToRegistration'))"
          >{{ $t('home.returnToRegistration') }}
          </el-button>
          <!--回收登记-->
          <el-button
            v-permit:remove="'btn:recyclingManagement:huiRegistration'"
            size="small"
            type="primary"
            @click="returnToRegistration($t('home.huiRegistration'))"
          >{{ $t('home.huiRegistration') }}
          </el-button>
          <!--催回收-->
          <el-button
            v-permit:remove="'btn:recyclingManagement:urgeRecovery'"
            size="small"
            type="primary"
            @click="urgeRecovery"
          >{{ $t('home.urgeRecovery') }}
          </el-button>
          <!--异常调整-->
          <el-button
            v-permit:remove="'btn:recyclingManagement:clickException'"
            size="small"
            type="primary"
            @click="clickException"
          >{{ $t('home.clickException') }}
          </el-button>
          <!--重置-->
          <el-button
            size="small" type="info" @click="resetForm()"
          >{{ $t('newOrder.reset') }}
          </el-button>
        </el-col>
      </el-row>
    </el-form>
    <!--  表格数据  -->
    <el-table
      ref="multipleTable"
      :data="tableData"
      :header-cell-style="{ background: '#f2f2f2', color: 'black' }"
      border
      height="650px"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column
        v-for="item in tableHeaderList"
        :key="item.id"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
        :show-overflow-tooltip="false"
      >
        <template slot-scope="scope">
          <div>{{ scope.row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('GlobalSetObj.status')" align="center" min-width="150">
        <template slot-scope="scope">
          <div style="display: flex; justify-content: center; align-items: center">
            <el-switch
              v-model="scope.row.enableStatusBoolen"
              active-color="#13ce66"
              disabled
              inactive-color="#ff4949"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0; float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!-- 回收登记 -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="dialogTitle"
      :visible.sync="addDialog"
      width="500px"
      @close="handleDialog"
    >
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        class="demo-ruleForm"
        label-width="auto"
        @submit.native.prevent
      >
        <el-form-item :label="$t('home.homeCount')" prop="registerCount" style="margin: 20px 0">
          <el-input
            v-model="ruleForm.registerCount"
            :maxlength="7"
            :placeholder="$t('GlobalSetObj.pleaseInput')"
            clearable
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="formSubmit(1)">{{ $t('GlobalSetObj.cancel') }}</el-button>
        <el-button
          size="small" type="primary" @click="formSubmit(2)"
        >{{ $t('GlobalSetObj.confirm') }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 异常调整 -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('home.clickException')"
      :visible.sync="exceptionDialog"
      width="500px"
      @close="handleDialog01"
    >
      <el-form
        ref="exceptionRuleForm"
        :model="exceptionRuleForm"
        :rules="exceptionRules"
        class="demo-ruleForm"
        label-width="auto"
        @submit.native.prevent
      >
        <el-form-item
          :label="$t('GlobalSetObj.AdjustedQuantity')"
          prop="adjustCount"
          style="margin: 20px 0"
        >
          <el-input
            v-model="exceptionRuleForm.adjustCount"
            :maxlength="7"
            :placeholder="$t('GlobalSetObj.pleaseInput')"
            clearable
            show-word-limit
          />
        </el-form-item>
        <el-form-item
          :label="$t('GlobalSetObj.AdjustmentReasons')"
          prop="adjustReason"
          style="margin: 20px 0"
        >
          <el-input
            v-model="exceptionRuleForm.adjustReason"
            :placeholder="$t('GlobalSetObj.pleaseInput')"
            :rows="3"
            clearable
            maxlength="250"
            show-word-limit
            type="textarea"
          />
        </el-form-item>
        <div style="color: red">{{ $t('home.tip02') }}</div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small" @click="exceptionFormSubmit(1)"
        >{{ $t('GlobalSetObj.cancel') }}
        </el-button>
        <el-button
          size="small" type="primary" @click="exceptionFormSubmit(2)"
        >{{ $t('GlobalSetObj.confirm') }}
        </el-button>
      </div>
    </el-dialog>
    <!-- Tip -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('GlobalSetObj.tips')"
      :visible.sync="tipDialog"
      width="550px"
    >
      <div>
        <div class="flx-between">
          <div>
            <span>{{ $t('home.operationTotal') }}：</span>
            <span>{{ resultObj.total }}</span>
          </div>
          <div>
            <span>{{ $t('operationCenter.NumberOfSuccesses') }}：</span>
            <span>{{ resultObj.success }}</span>
          </div>
          <div>
            <span>{{ $t('operationCenter.NumberOfFailures') }}：</span>
            <span style="color: red">{{ resultObj.fail }}</span>
          </div>
        </div>
        <div v-if="resultObj.failList.length" style="height: 200px; overflow-y: auto">
          <div style="margin-top: 20px; margin-bottom: 8px">
            {{ $t('GlobalSetObj.ReasonForFailure') }}:
          </div>
          <div v-for="(item, index) in resultObj.failList" :key="index">
            <span>{{ index + 1 }}. </span>
            <span>{{ item.siteName }}: </span>
            <span>{{ item.reason }}</span>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button
          size="small" type="primary" @click="tipDialog = false"
        >{{ $t('GlobalSetObj.Close') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  abnormalAdjust,
  bagRecyclePage,
  recycleRegister,
  returnRegister,
  urgeRegister
} from '@/api/newExpressAPI';

export default {
  name: 'RequestMessageList',
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      timeValue: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        params: {
          siteCode: this.$store.state.user.siteCode, // 网点编号
          siteName: this.$store.state.user.dotName, // 网点名称
          limitCountCondition: null, // 是否确认
          justSearchCondition: null
        }
      },
      tableData: [],
      tableHeaderList: [
        // 网点编号
        { id: 1, label: this.$t('collectionCenter.siteNum'), prop: 'siteCode', cloWidth: '150' },
        // 网点名称
        { id: 2, label: this.$t('collectionCenter.siteName'), prop: 'siteName', cloWidth: '150' },
        // 网点类型
        { id: 3, label: this.$t('home.siteType'), prop: 'siteType', cloWidth: '150' },
        // 所属转运中心
        {
          id: 4,
          label: this.$t('collectionCenter.owningTransitCenter1'),
          prop: 'transferCenterName',
          cloWidth: '150'
        },
        // 结余总数量
        { id: 5, label: this.$t('home.balanceCount'), prop: 'balanceCount', cloWidth: '100' },
        // 最小限制数
        { id: 6, label: this.$t('home.minlimitcount'), prop: 'minLimitCount', cloWidth: '150' },
        // 最大限制数
        { id: 7, label: this.$t('home.maxLimitcount'), prop: 'maxLimitCount', cloWidth: '150' },
        // 回收途中数量
        {
          id: 8,
          label: this.$t('GlobalSetObj.RecyclingQuantity'),
          prop: 'recyclingCount',
          cloWidth: '150'
        },
        // 返回途中数量
        {
          id: 9,
          label: this.$t('GlobalSetObj.QuantityOnTheWayBack'),
          prop: 'returningCount',
          cloWidth: '160'
        }
      ],
      userList: [], // 用户列表
      customerMerchant: [],
      addDialog: false,
      ruleForm: {
        registerCount: ''
      },
      rules: {
        registerCount: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'change' },
          { validator: this.validatePositiveInteger, trigger: ['blur', 'change'] }
        ]
      },
      exceptionRuleForm: {
        adjustCount: '',
        adjustReason: ''
      },
      exceptionRules: {
        adjustReason: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'change' }
        ],
        adjustCount: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'change' },
          { validator: this.validateInteger, trigger: ['blur', 'change'] }
        ]
      },
      exceptionDialog: false,
      selectTable: [],
      dialogTitle: '',
      tipDialog: false,
      resultObj: {
        failList: []
      }
    };
  },
  computed: {},
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getSearch();
  },
  methods: {
    // 自定义验证正整数的方法
    validatePositiveInteger(rule, value, callback) {
      const reg = /^[0-9]*$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t('collectionCenter.pleaseEnterInteger')));
      }
    },
    // 自定义验证整数的方法（包括正整数、负整数和 0）
    validateInteger(rule, value, callback) {
      const reg = /^-?[0-9]+$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t('collectionCenter.pleaseEnterInteger')));
      }
    },
    handleSelectionChange(val) {
      this.selectTable = val;
    },
    // 返回登记
    returnToRegistration(title) {
      if (this.selectTable.length === 0) {
        this.$message.warning(this.$t('collectionCenter.pleaseSelectData'));
      } else {
        this.dialogTitle = title;
        this.addDialog = true;
      }
    },
    urgeRecovery() {
      if (this.selectTable.length === 0) {
        this.$message.warning(this.$t('collectionCenter.pleaseSelectData'));
      } else {
        const arr = [];
        this.selectTable.map((item) => {
          arr.push(item.siteId);
        });
        const data = {
          siteIdList: arr
        };
        urgeRegister(data).then((res) => {
          if (res.code === 200) {
            this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
            this.getSearch();
          }
        });
      }
    },
    // 点击确定
    formSubmit(val) {
      if (val === 1) {
        this.addDialog = false;
      } else {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            const arr = [];
            if (this.dialogTitle === this.$t('home.returnToRegistration')) {
              this.selectTable.forEach((item) => {
                const obj = {
                  id: item.id,
                  returnSiteId: item.returnSiteId,
                  receiveSiteId: item.receiveSiteId
                };
                if (this.$store.getters.siteId === item.siteId) {
                  obj.returnSiteId = item.siteId;
                  obj.receiveSiteId = item.transferCenterId;
                } else {
                  obj.returnSiteId = item.transferCenterId;
                  obj.receiveSiteId = item.siteId;
                }
                arr.push(obj);
              });
              const data = {
                registerCount: this.ruleForm.registerCount,
                registerList: arr
              };
              this.addDialog = false;
              const loading = this.$loading({
                fullscreen: true,
                lock: true,
                spinner: 'el-icon-loading',
                text: 'loading...',
                background: 'rgba(250, 250, 250, 0.6)'
              });
              returnRegister(data)
                .then((res) => {
                  loading.close();
                  if (res.code === 200) {
                    if (res.data) {
                      this.resultObj = res.data;
                      this.tipDialog = true;
                    }
                    this.getSearch();
                  }
                })
                .catch(() => {
                  loading.close();
                });
            } else {
              this.selectTable.forEach((item) => {
                const obj = {
                  id: item.id,
                  returnSiteId: item.siteId,
                  receiveSiteId: item.transferCenterId
                };
                arr.push(obj);
              });
              const data = {
                registerCount: this.ruleForm.registerCount,
                registerList: arr
              };
              this.addDialog = false;
              recycleRegister(data).then((res) => {
                if (res.code === 200) {
                  if (res.data) {
                    this.resultObj = res.data;
                    this.tipDialog = true;
                  }
                  // this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
                  this.getSearch();
                }
              });
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    // 点击异常调整
    clickException() {
      if (this.selectTable.length === 0) {
        this.$message.warning(this.$t('collectionCenter.pleaseSelectData'));
      } else {
        this.exceptionDialog = true;
      }
    },
    exceptionFormSubmit(val) {
      if (val === 1) {
        this.exceptionDialog = false;
      } else {
        this.$refs['exceptionRuleForm'].validate((valid) => {
          if (valid) {
            const arr = [];
            this.selectTable.forEach((item) => {
              arr.push(item.siteId);
            });
            const data = {
              siteIdList: arr,
              adjustCount: this.exceptionRuleForm.adjustCount,
              adjustReason: this.exceptionRuleForm.adjustReason
            };
            abnormalAdjust(data).then((res) => {
              if (res.code === 200) {
                this.exceptionDialog = false;
                this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
                this.getSearch();
              }
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    enterSearch() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.pageNumber = 1;
      this.timeValue = [];
      this.queryForm.params = {
        siteCode: this.$store.state.user.siteCode, // 网点编号
        siteName: this.$store.state.user.dotName, // 网点名称
        limitCountCondition: null,
        justSearchCondition: null
      };
      this.getSearch();
    },
    // 查询
    getSearch() {
      const data = {
        pageSize: this.queryForm.pageSize,
        pageNumber: this.queryForm.pageNumber,
        params: {
          siteCode: this.queryForm.params.siteCode,
          siteName: this.queryForm.params.siteName,
          limitCountCondition: this.queryForm.params.limitCountCondition,
          justSearchCondition: this.queryForm.params.justSearchCondition
        }
      };
      bagRecyclePage(data).then((res) => {
        if (res.code === 200) {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.forEach((item) => {
            item.enableStatusBoolen = item.enableStatus === 1;
            if (item.balanceCount === null) {
              item.balanceCount = '--';
            }
            if (item.minLimitCount === null) {
              item.minLimitCount = '--';
            }
            if (item.maxLimitCount === null) {
              item.maxLimitCount = '--';
            }
            if (item.recyclingCount === null) {
              item.recyclingCount = '--';
            }
            if (item.returningCount === null) {
              item.returningCount = '--';
            }
          });
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    },
    handleDialog() {
      this.ruleForm = {
        registerCount: ''
      };
      this.$refs.multipleTable.clearSelection();
      this.$refs.ruleForm.resetFields();
    },
    handleDialog01() {
      this.exceptionRuleForm = {
        adjustCount: '',
        adjustReason: ''
      };
      this.$refs.multipleTable.clearSelection();
      this.$refs.exceptionRuleForm.resetFields();
    }
  }
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
