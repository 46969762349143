// 登录、注册、忘记密码
const navbar = {
  siteName: {
    zh: '网点名称',
    en: '',
    pu: 'Nome do ponto de rede'
  },
  alertLogoutMessage: {
    zh: 'Do you still have open bags, do you want to close them automatically?',
    en: 'Do you still have open bags, do you want to close them automatically?',
    pu: 'Você ainda tem saca abertas, deseja fechar elas automaticamente ?'
  },
  promisseType: {
    zh: '权限类型',
    en: '',
    pu: 'Tipo de permissão'
  },
  anjunAccount: {
    zh: '安骏账号',
    en: '',
    pu: 'Conta de Anjun'
  },
  stateAccount: {
    zh: '州级账号',
    en: '',
    pu: 'Conta do Estado'
  },
  providerAccount: {
    zh: '服务商账号',
    en: '',
    pu: 'Conta de parceiro'
  },
  accountPosword: {
    zh: '账号密码',
    en: '',
    pu: 'Senha de conta'
  },
  opretaSite: {
    zh: '操作网点',
    en: '',
    pu: 'Pontos de operação'
  },
  selectNIG: {
    zh: '选择车签号',
    en: '',
    pu: 'Nº de escaneamento do veículo'
  },
  seeImage: {
    zh: '查看图片',
    en: '',
    pu: 'Verificar foto'
  },
  sumIsUnload: {
    zh: '是否已卸车',
    en: '',
    pu: 'Já descarregado?'
  },
  unLoadSiteName: {
    zh: '实际卸车网点',
    en: '',
    pu: 'Ponto real de descarregamento'
  },
  loadSign: {
    zh: '装车签名',
    en: '',
    pu: 'Assinatura de carregamento'
  },
  unLoadSign: {
    zh: '卸车签名',
    en: '',
    pu: 'Assinatura de descarregamento'
  },
  loadImage: {
    zh: '装卸车图片',
    en: '',
    pu: 'Imagem de carregamento/descarregamento'
  },
  vehicleDriver: {
    zh: '车辆驾驶人',
    en: '',
    pu: 'Motorista do veículo'
  },
  unLoadBagNumber: {
    zh: '卸车袋数',
    en: '',
    pu: 'Qtd. de sacas descarregadas'
  },
  errorUnLoadBagNumber: {
    zh: '异常卸车袋数',
    en: '',
    pu: 'Qtd. de sacas descarregadas com anomalia'
  },
  notUnLoadBagNumber: {
    zh: '未卸车袋数',
    en: '',
    pu: 'Qtd. de sacas não descarregadas'
  },
  errorUnLoadPackageNumber: {
    zh: '异常卸车包裹数',
    en: '',
    pu: 'Qtd. de pacotes descarregados com anomalia'
  },
  notUnLoadPackageNumber: {
    zh: '未卸车包裹数',
    en: '',
    pu: 'Qtd. de pacotes não descarregados'
  },
  unloadTicketCount: {
    zh: '卸车票数',
    en: '',
    pu: 'Qtd. de Pacotes Descarregados'
  },
  arrivalTicketCount: {
    zh: '到件票数',
    en: '',
    pu: 'Qtd. de pacotes recebidos'
  },
  difference: {
    zh: '差异',
    en: '',
    pu: 'Discrepância'
  },
  twentyFourHours: {
    zh: '24小时滞留',
    en: '',
    pu: 'Pacotes Retidos por 24 Horas'
  },
  fortyEightHours: {
    zh: '48小时滞留',
    en: '',
    pu: 'Pacotes Retidos por 48 Horas'
  },
  seventyTwoHours: {
    zh: '72小时滞留',
    en: '',
    pu: 'Pacotes Retidos por 72 Horas'
  },
  sevenDays: {
    zh: '7天滞留',
    en: '',
    pu: 'Pacotes Retidos por 7 Dias'
  },
  fifteenDays: {
    zh: '15天滞留',
    en: '',
    pu: 'Pacotes Retidos por 15 Dias'
  },
  durationTime: {
    zh: '时长(h)',
    en: '',
    pu: 'Duração (h)'
  },
  unloadOrArrivalTime: {
    zh: '卸车/到件时间',
    en: '',
    pu: 'Tempo de Descarregamento/Chegada'
  },
  unloadOrArrivalTime1: {
    zh: '装车/发件时间',
    en: '',
    pu: 'Tempo de carregamento/envio'
  },
  shipmentTicketCount: {
    zh: '发件票数',
    en: '',
    pu: 'Qtd. de envio'
  },
  reverseShipping: {
    zh: '逆向派件',
    en: '',
    pu: 'Entrega de reverso'
  },
  reverseSigning: {
    zh: '逆向签收',
    en: '',
    pu: 'Recebido de reverso'
  },
  ReturnToSender: {
    zh: '退仓发件',
    en: '',
    pu: 'Envio na Armazém de devolução'
  },
  estimatedCollectLoadingDuration: {
    zh: '预计揽收装车时长(H)',
    en: '',
    pu: 'Duração estimado para carregamento de coleta'
  },
  estimatedCollectLoadingTime: {
    zh: '预计揽收装车时间',
    en: '',
    pu: 'Tempo Estimado de Coleta e Carregamento'
  },
  actualCollectLoadingTime: {
    zh: '实际揽收装车时间',
    en: '',
    pu: 'Tempo Real de Coleta e Carregamento'
  },
  moreThenTime: {
    zh: '超时时长(H)',
    en: '',
    pu: 'Tempo Excedido(H)'
  },
  estimatedHub1TransitHours: {
    zh: '预计HUB1中转时长(H)',
    en: '',
    pu: 'Duração Estimada de Trânsito no HUB1(H)'
  },
  estimatedFirstOutTime1: {
    zh: '预计一级分拨到件时间',
    en: '',
    pu: 'Tempo Real de Chegada no CD de nível 1'
  },
  estimatedFirstOutTime: {
    zh: '预计一级分拨到件时间',
    en: '',
    pu: 'Tempo Real de Chegada no CD de nível 1'
  },
  actualFirstOutTime: {
    zh: '实际一级分拨到件时间',
    en: '',
    pu: 'Tempo Estimado de Chegada no CD de nível 1'
  },
  trunkTransportDuration: {
    zh: '干线运输时长(H)',
    en: '',
    pu: 'Duração do Transporte de Rota Primária Linha Haul(H)'
  },
  estimatedSecondInTime: {
    zh: '预计二级分拨到件时间',
    en: '',
    pu: 'Tempo Real de Envio do CD de nível 2'
  },
  actualSecondInTime: {
    zh: '实际二级分拨到件时间',
    en: '',
    pu: 'Tempo Real de Chegada no CD de nível 2'
  },
  feederTransportDuration: {
    zh: '支线运输时长(H)',
    en: '',
    pu: 'Duração de rota secundária(H)'
  },
  estimatedSiteInTime: {
    zh: '预计网点入库时间',
    en: '',
    pu: 'Tempo Estimada de Entrada no Ponto'
  },
  actualSiteInTime: {
    zh: '实际网点入库时间',
    en: '',
    pu: 'Tempo Real de Entrada no Ponto'
  },
  estimatedDispatchDuration: {
    zh: '预计派送时长(H)',
    en: '',
    pu: 'Duração Estimada de Entrega (H)'
  },
  actualDeliveredTime: {
    zh: '实际签收时间',
    en: '',
    pu: 'Tempo Real de Assinatura'
  },
  estimatedDeliveredTime: {
    zh: '预计送达时间',
    en: '',
    pu: 'Horário estimado de entrega'
  },
  estimatedDeliveryTransportHours: {
    zh: '揽收运输时时长(H)',
    en: '',
    pu: 'Duração do Transporte da Coleta (H)'
  },
  actualFirstInTime: {
    zh: '实际一级分拨到件时间',
    en: '',
    pu: 'Tempo Estimado de Chegada no CD de nível 1'
  },
  estimatedFirstOutTime2: {
    zh: '预计一级分拨发件时间',
    en: '',
    pu: 'Tempo Estimado de Envio do CD de nível 1'
  },
  actualFirstOutTime1: {
    zh: '实际一级分拨发件时间',
    en: '',
    pu: 'Tempo Real de Envio do CD de nível 1'
  },
  estimatedHub2TransitHours: {
    zh: '实际一级分拨发件时间',
    en: '',
    pu: 'Tempo Real de Envio do CD de nível 1'
  },
  estimatedHub2TransitHours1: {
    zh: '预计HUB2中转时长(H)',
    en: '',
    pu: 'Duração Estimada de Trânsito no HUB2 (H)'
  },
  estimatedSecondOutTime: {
    zh: '预计二级分拨发件时间',
    en: '',
    pu: 'Tempo Estimado de Envio do Centro de Distribuição Secundário'
  },
  actualSecondOutTime: {
    zh: '实际二级分拨发件时间',
    en: '',
    pu: 'Tempo Real de Envio do CD de nível 2'
  },
  shijisiteruku: {
    zh: '实际网点入库时间',
    en: '',
    pu: 'Tempo Real de Entrada no Ponto'
  },
  transferType: {
    zh: '中转类型',
    en: '',
    pu: 'Tipo de trânsito'
  },
  cityType: {
    zh: '城镇类型',
    en: '',
    pu: 'Tipo de cidade'
  },
  orderImport: {
    zh: '批量导入',
    en: '',
    pu: 'Importar em lotes'
  },
  export: {
    zh: '导出',
    en: '',
    pu: 'Exportar'
  },
  duration: {
    zh: '时长',
    en: '',
    pu: 'Duração'
  },
  tDuration: {
    zh: '运输时长(H)',
    en: '',
    pu: 'Duração de transportar(H)'
  },
  tDuration1: {
    zh: '运输时长',
    en: '',
    pu: 'Duração de transportar'
  },
  durationTransit: {
    zh: '中转时长',
    en: '',
    pu: 'Duração de trânsito'
  },
  yujierjidaojian: {
    zh: '预计二级到件时间',
    en: '',
    pu: 'Tempo Estimada de Chegada de Nível 2'
  },
  yujierjifajian: {
    zh: '预计二级发件时间',
    en: '',
    pu: 'Tempo Estimada de Envio de Nível 2'
  },
  shijierjidaojian: {
    zh: '实际二级到件时间',
    en: '',
    pu: 'Tempo Real de Chegada de Nível 2'
  },
  shijierjifajian: {
    zh: '实际二级发件时间',
    en: '',
    pu: 'Tempo Real de Chegada de Nível 2'
  },
  shijiarrived: {
    zh: '实际送达时间',
    en: '',
    pu: 'Tempo Real de Entrega'
  },
  startDot: {
    zh: '起点',
    en: '',
    pu: 'Ponto de partida'
  },
  endDot: {
    zh: '终点',
    en: '',
    pu: 'Ponto de chegada'
  },
  newTranslateTime: {
    zh: '新增运输时长',
    en: '',
    pu: 'Adicionar duração do transporte'
  },
  inputdayu1: {
    zh: '请输入大于零的数字, 最多取1位小数',
    en: '',
    pu: 'Por favor, insira um número maior que zero, com no máximo 1 casa decimal'
  },
  inputdayuzuoduo1: {
    zh: '请输入数字, 最多取1位小数',
    en: '',
    pu: 'Por favor, insira um número, com no máximo 1 casa decimal'
  },
  editTranslateTime: {
    zh: '编辑运输时长',
    en: '',
    pu: 'Editar duração do transporte'
  },
  lanshoucheshichang: {
    zh: '揽收装车时长',
    en: '',
    pu: 'Tempo de coleta e carregamento'
  },
  yijicenterTime: {
    zh: '一级分拣中心中转时长',
    en: '',
    pu: 'Tempo de trânsito no centro de triagem de 1º nível'
  },
  erjicenterTime: {
    zh: '二级分拣中心中转时长',
    en: '',
    pu: 'Tempo de trânsito no centro de triagem de 2º nível'
  },
  siteTime: {
    zh: '网点派送时长',
    en: '',
    pu: 'Tempo de entrega na unidade'
  },
  newCenterTime: {
    zh: '新增中转时长',
    en: '',
    pu: 'Adicionar duração de transbordo'
  },
  editCenterTime: {
    zh: '编辑中转时长',
    en: '',
    pu: 'Editar duração de transbordo'
  },
  bigbusinessType: {
    zh: '大件快递',
    en: '',
    pu: 'Carga grande'
  },
  outTime: {
    zh: '超时时间(H)',
    en: '',
    pu: 'Tempo de expiração(H)'
  },
  businessType: {
    zh: '业务类型',
    en: '',
    pu: 'Tipo de negócio'
  },
  zhengchengExpress: {
    zh: '正常快递',
    en: '',
    pu: 'Entrega Expresso Normal'
  },
  providerInfo: {
    zh: '服务商返回信息',
    en: '',
    pu: 'Informações de Retorno do Prestador de Serviços'
  },
  providerOrderStatus: {
    zh: '订单状态',
    en: '',
    pu: 'Estado do pedido'
  },
  providerName: {
    zh: '服务商名称',
    en: '',
    pu: 'Tipo de negócio'
  },
  applyForProvider: {
    zh: '申请服务商单号',
    en: '',
    pu: 'Solicitar número do pedido do paceiro'
  },
  reallocate: {
    zh: '重新分配服务商',
    en: '',
    pu: 'Reatribuir prestador de serviço'
  },
  downloadTheMenu: {
    zh: '下载服务商标签',
    en: '',
    pu: 'Baixar etiqueta do prestador de serviço'
  },
  senderIe: {
    zh: '发件人州注册号',
    en: '',
    pu: 'IE'
  },
  receiveIe: {
    zh: '收件人州注册号',
    en: '',
    pu: 'IE'
  },
  waitGetNumber: {
    zh: '等待取号',
    en: '',
    pu: 'Aguardando um número'
  },
  getNumberSuccess: {
    zh: '取号成功',
    en: '',
    pu: 'Número obtido com sucesso'
  },
  getNumbering: {
    zh: '取号中',
    en: '',
    pu: 'Tome o número em'
  },
  getNumberFail: {
    zh: '取号失败',
    en: '',
    pu: 'Falha ao obter número'
  },
  pleaseSelectProvider: {
    zh: '请选择服务商',
    en: '',
    pu: 'Por favor, selecione o parceiro'
  },
  tip01: {
    zh: '暂无需要下载的服务商标签',
    en: '',
    pu: 'Sem etiquetas de parceiro para baixar no momento'
  },
  tip02: {
    zh: '当前状态不支持申请服务商单号',
    en: '',
    pu: 'O status atual não suporta solicitação de número de parceiro'
  },
  tip03: {
    zh: '确定要对选中单号进行申请服务商单号吗?',
    en: '',
    pu: 'Confirme solicitar o número do parceiro para o(s) número(s) selecionado(s)?'
  },
  tip04: {
    zh: '当前状态不支持重新分配服务商',
    en: '',
    pu: 'O status atual não suporta atribuição do novo parceiro'
  },
  orderDistruProvider: {
    zh: '订单分配新的服务商',
    en: '',
    pu: 'Pedido atribuído a um novo parceiro'
  },
  tip08: {
    zh: '确定要对选中单号分配新的服务商单吗?',
    en: '',
    pu: 'Confirmar a atribuição de um novo número de parceiro ao pedido selecionado?'
  },
  szAnjun: {
    zh: '深圳安骏',
    en: '',
    pu: 'Shenzhen Anjun'
  },
  problemIntercept: {
    zh: '释放问题件拦截',
    en: '',
    pu: 'Liberar Interceptação de Pacotes com Problema'
  },
  release10day: {
    zh: '释放10天限制',
    en: '',
    pu: 'Liberação da restrição de 10 dias'
  },
  wentijian01: {
    zh: '问题件原因1',
    en: '',
    pu: 'Motivo do problema 1'
  },
  wentijian02: {
    zh: '问题件原因2',
    en: '',
    pu: 'Motivo do problema 2'
  },
  wentijian03: {
    zh: '问题件原因3',
    en: '',
    pu: 'Motivo do problema 3'
  },
  lastTime: {
    zh: '最新登记时间',
    en: '',
    pu: 'Último horário de registro'
  },
  firstTime: {
    zh: '首次登记时间',
    en: '',
    pu: 'Horário de primeiro registro'
  },
  interceptStatusName: {
    zh: '是否拦截中',
    en: '',
    pu: 'Está em intercepção?'
  },
  residueTimes: {
    zh: '剩余投递时间(天)',
    en: '',
    pu: 'Tempo restante de entrega (dias)'
  },
  residueCount: {
    zh: '剩余投递次数',
    en: '',
    pu: 'Tentativas de entrega restantes'
  },
  latestRegisterSite: {
    zh: '最新登记网点',
    en: '',
    pu: 'Último ponto registrado'
  },
  latestRegistrant: {
    zh: '最新登记人',
    en: '',
    pu: 'Última pessoa registrada'
  },
  tip20: {
    zh: '确定要对选中单号解除本地客户问题件拦截吗?',
    en: '',
    pu: 'Confirma a liberação da interceptação de pacotes de problemas do cliente local para os números selecionados?'
  },
  tip21: {
    zh: '确定要对选中单号释放10天限制吗?',
    en: '',
    pu: 'Confirma a liberação da restrição de 10 dias para os números selecionados?'
  },
  latestReleaseTime: {
    zh: '最新拦截释放时间',
    en: '',
    pu: 'Último horário de liberação da intercepção'
  },
  actuallySignLoc: {
    zh: '实际签收经纬度',
    en: '',
    pu: 'Latitude e longitude de assinatura reais'
  },
  receiveSignLoc: {
    zh: '收件人签收经纬度',
    en: '',
    pu: 'Latitude e longitude de assinatura do destinatário'
  },
  signDistance: {
    zh: '与收件地址直线距离(米)',
    en: '',
    pu: 'Distância em linha reta até o endereço do destinatário (metros)'
  },
  siteSignLoc: {
    zh: '网点经纬度',
    en: '',
    pu: 'Latitude e longitude do ponto'
  },
  siteDistance: {
    zh: '与网点直线距离(米)',
    en: '',
    pu: 'Distância em linha reta até o ponto (metros)'
  },
  signCofigRef: {
    zh: '签收限制配置',
    en: '',
    pu: 'Configuração de Restrição de Assinatura'
  },
  electronicContactLimitRef: {
    zh: '限制解除',
    en: '',
    pu: 'Restrição Removida'
  },
  restrictionState: {
    zh: '限制的州',
    en: '',
    pu: 'Estados Restritos'
  },
  restrictionSiteId: {
    zh: '限制的网点',
    en: '',
    pu: 'Pontos Restritos'
  },
  dainziweilan: {
    zh: '强制电子围栏',
    en: '',
    pu: 'Cerca Eletrônica Obrigatória'
  },
  qiangzhiyanzhengma: {
    zh: '强制验证码',
    en: '',
    pu: 'Código de Verificação Obrigatório'
  },
  deliveryDistance: {
    zh: '派送距离(M)',
    en: '',
    pu: 'Distância de Entrega(M)'
  },
  smsTemplateId: {
    zh: '短信模板',
    en: '',
    pu: 'Modelo de SMS'
  },
  smsPushNode: {
    zh: '短信推送节点',
    en: '',
    pu: 'Ponto de Envio de SMS'
  },
  orderCreate: {
    zh: '订单创建',
    en: '',
    pu: 'Criação de pedido'
  },
  ruksaomiao: {
    zh: '网点入库扫描',
    en: '',
    pu: 'Bipagem de entrada do ponto'
  },
  renwufenpei: {
    zh: '任务分配',
    en: '',
    pu: 'Distribuição de tarefa'
  },
  dialogTitlecreate: {
    zh: '创建经纬度限制',
    en: '',
    pu: 'Criar Restrição de Coordenadas Geográficas'
  },
  dialogTitleedit: {
    zh: '修改经纬度限制',
    en: '',
    pu: 'Modificar Restrição de Coordenadas Geográficas'
  },
  rescissionAccount: {
    zh: '解除的账号',
    en: '',
    pu: 'Conta Desbloqueada'
  },
  siteIdtohave: {
    zh: '账号所属网点',
    en: '',
    pu: 'Ponto de Atendimento do Conta'
  },
  rescissionType: {
    zh: '解除限制的类型',
    en: '',
    pu: 'Tipos de restrições levantadas'
  },
  signDianzi: {
    zh: '签收电子围栏',
    en: '',
    pu: 'Assinatura com Cerca Eletrônica'
  },
  smsCode: {
    zh: '短信验证码',
    en: '',
    pu: 'Código de Verificação por SMS'
  },
  xinzengzhanghaoxianzhi: {
    zh: '新增解除限制',
    en: '',
    pu: 'Nova versão das restrições'
  },
  jiechuTime: {
    zh: '解除时间',
    en: '',
    pu: 'Tempo de Remoção'
  },
  jiechuaccountName: {
    zh: '解除账号',
    en: '',
    pu: 'Conta Removida'
  },
  rescissionTypeName: {
    zh: '解除限制类型',
    en: '',
    pu: 'Tipo de Remoção de Restrição'
  },
  cargoOrderStatus: {
    zh: '母单状态',
    en: '',
    pu: 'Status do pedido principal'
  },
  senderState: {
    zh: '发件人所属州',
    en: '',
    pu: 'Estado do remetente'
  },
  receiveState: {
    zh: '收件人所属州',
    en: '',
    pu: 'Estado do destinatário'
  },
  anjunOrderNumber: {
    zh: '安骏订单号',
    en: '',
    pu: 'Número do pedido da ANJUN'
  },
  totalNumber: {
    zh: '总件数',
    en: '',
    pu: 'Quantidade total de itens'
  },
  totalCreateOrderWeight: {
    zh: '下单总重量(kg)',
    en: '',
    pu: 'Peso total do pedido(kg)'
  },
  totalParcelDeclaredVolume: {
    zh: '下单总体积(m³)',
    en: '',
    pu: 'Volume total do pedido(m³)'
  },
  transportWeight: {
    zh: '中转复重(kg)',
    en: '',
    pu: 'Repesagem em trânsito (kg)'
  },
  transportVolume: {
    zh: '中转测量体积(m³)',
    en: '',
    pu: 'Volume medido no trânsito(m³)'
  },
  newwaybillStatusName: {
    zh: '最新状态',
    en: '',
    pu: 'Último Status'
  },
  lastTrackTime: {
    zh: '最新轨迹操作时间',
    en: '',
    pu: 'Última Hora da Operação de Rastreamento'
  },
  objLength: {
    zh: '长宽高(cm)',
    en: '',
    pu: 'Comprimento, Largura e Altura (cm)'
  },
  willNumberInfo: {
    zh: '运单基本信息',
    en: '',
    pu: 'Informações Básicas de MAWB'
  },
  childrenTableData: {
    zh: '子件信息',
    en: '',
    pu: 'Informações do Subpacote'
  },
  gong: {
    zh: '共',
    en: '',
    pu: 'Total de'
  },
  jian: {
    zh: '件',
    en: '',
    pu: 'itens'
  },
  qiangzhiqinashou: {
    zh: '强制提供签收照片',
    en: '',
    pu: 'Fornecimento obrigatório de fotografias assinadas'
  },
  isDeliverImgName: {
    zh: '是否强制照片',
    en: '',
    pu: 'Se as fotografias são obrigatórias ou não'
  },
  jiechuwaybillNumber: {
    zh: '解除的运单号',
    en: '',
    pu: 'Número da carta de porte descarregada'
  },
  jiechuResult: {
    zh: '解除结果',
    en: '',
    pu: 'Resultados do despedimento'
  },
  jiechuWillybNumber: {
    zh: '解除的运单号',
    en: '',
    pu: 'Número da carta de porte descarregada'
  },
  jiechuzhanghao: {
    zh: '解除账号',
    en: '',
    pu: 'Cancelar conta'
  },
  jiechuWillybNumber01: {
    zh: '解除运单号',
    en: '',
    pu: 'Libertar o número da carta de porte'
  },
  gongjiechushu: {
    zh: '共解除数',
    en: '',
    pu: 'Total levantado'
  },
  jieSuccessNum: {
    zh: '解除成功数',
    en: '',
    pu: 'Número de sucessos'
  },
  jiechufailTotal: {
    zh: '解除失败数',
    en: '',
    pu: 'Número de falhas de elevação'
  },
  jiechufailWillNumber: {
    zh: '解除失败运单号',
    en: '',
    pu: 'Libertar número de carta de porte falhado'
  },
  RestrictedAccounts: {
    zh: '限制的账号',
    en: 'Restricted accounts',
    pu: 'Contas sujeitas a restrições'
  },
  tip0305: {
    zh: '提示: 此列表记录运单状态与轨迹内容不同步的异常数据',
    en: '',
    pu: 'Aviso: Essa lista registra os dados anormais de discrepância entre o status da lista de envio e o conteúdo da trajetória'
  },
  unSignCount: {
    zh: '未签收票数',
    en: '',
    pu: 'Qtd. Total de Pacotes Não Assinados'
  },
  trackingStop: {
    zh: '轨迹停更监控',
    en: '',
    pu: 'Monitorização da Paragem da Rastreio'
  },
  DownloadAllTags: {
    zh: '下载全部标签',
    en: 'Download all tags',
    pu: 'Descarregar todos os separadores'
  }
};
export { navbar };
