<template>
  <div>
    <el-form
      ref="recalculate"
      :model="recalculateForm"
      :rules="recalculateRules"
      class="demo-ruleForm"
      label-position="top"
      size="small"
    >
      <!--业务时间-->
      <el-form-item :label="$t('basicData.BusinessTime') + '：'" prop="queryDate">
        <el-date-picker
          v-model="recalculateForm.queryDate"
          style="width: 100%"
          type="datetimerange"
          :picker-options="dateLimit"
          :default-time="['00:00:00', '23:59:59']"
          :range-separator="$t('operationCenter.to')"
          :start-placeholder="$t('operationCenter.startDate')"
          :end-placeholder="$t('operationCenter.endDate')"
          value-format="yyyy-MM-dd HH:mm:ss"
        />
      </el-form-item>
      <!--装车员-->
      <el-form-item :label="$t('GlobalSetObj.Carregador')+'：'" prop="dispatcherList">
        <el-select
          v-model="recalculateForm.dispatcherList"
          :placeholder="$t('operationCenter.Only10AreDisplayed')"
          clearable
          filterable
          multiple
          size="small"
          style="width: 100%;"
        >
          <el-option
            v-for="item in deliveryList"
            :key="item.id"
            :label="item.username + '（' + item.realName + '）'"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button size="small" type="primary" @click="saveReCalculate">{{ $t('basicData.determine') }}</el-button>
      <el-button size="small" @click="isShow()">{{ $t('basicData.Cancel') }}</el-button>
    </span>
  </div>
</template>

<script>
import { getUserPage } from '@/api/user';

export default {
  name: 'Recalculate',
  data() {
    return {
      recalculateForm: {
        queryDate: [], // 业务时间
        dispatcherList: []
      },
      deliveryList: [],
      textVal: '', // 输入框内容
      pickerMinDate: '', // 日期限制
      pickerRange: 6 * 24 * 60 * 60 * 1000, // 可选择日期范围 为7天
      recalculateRules: {
        queryDate: [
          { required: true, message: this.$t('operationCenter.PleaseSelect'), trigger: ['blur', 'change'] }
        ],
        dispatcherList: [
          { required: true, message: this.$t('operationCenter.PleaseSelect'), trigger: ['blur', 'change'] }
        ]
      }
    };
  },
  computed: {
    /**
     * 日期限制处理
     *  默认情况下限制7天
     *  如果输入了 业务单号 或 客户单号 或 批次号，则不限制时间
     */
    dateLimit() {
      const { textVal, pickerMinDate, pickerRange } = this;

      // 默认限制15天
      return {
        // 设置不能选择的日期
        onPick: ({ minDate }) => {
          // 输入了内容
          if (textVal) {
            // 取消限制
            this.pickerMinDate = null;
            return;
          }

          if (minDate && pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          // 输入了内容
          if (textVal) {
            // 取消时间限制
            return false;
          }

          if (this.pickerMinDate) {
            return (time.getTime() > (pickerMinDate + pickerRange)) || (time.getTime() < (pickerMinDate - pickerRange));
          }
          return false;
        }
      };
    }
  },
  created() {
    this.getLoadUser();
  },
  methods: {
    // 获取装车人
    getLoadUser() {
      const query = {
        pageNumber: 1,
        pageSize: 9999999,
        params: {
          accountState: '1',
          roleId: '163'
        }
      };
      getUserPage(query, false).then(res => {
        this.deliveryList = res.data.records;
      });
    },
    // 关闭弹窗
    isShow() {
      this.$emit('saveReCalculate');
    },
    // 确定重算
    saveReCalculate() {
      this.$refs.recalculate.validate(valid => {
        if (!valid) {
          return;
        }
        this.$emit('saveReCalculate', this.recalculateForm);
      });
    }
  }
};
</script>

<style scoped>

</style>
