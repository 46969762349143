<template>
  <!-- 页面名称 -->
  <div class="msgBox">
    <div style="display: flex;justify-content: space-between;align-items: center">
      <el-button
        icon="el-icon-upload2"
        size="mini"
        @click="download"
      >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出 -->
      <div style="margin-right: 70px;display: flex;justify-content: space-between;align-items: center">
        <!-- 更新时间 -->
        <span v-show="updateTime">{{ $t('GlobalSetObj.UpdateTime') }}：{{ updateTime }}</span>
        <div class="dateBox">
          <span :class="{'d1': type == 4}" @click="get2Data(4)">{{ $t('collectionCenter.beforeYesterday') }}</span><!-- 前天 -->
          <span :class="{'d1': type == 0}" @click="get2Data(0)">{{ $t('collectionCenter.yesterday') }}</span><!-- 昨日 -->
          <span :class="{'d1': type == 1}" @click="get2Data(1)">{{ $t('collectionCenter.sameDay') }}</span><!-- 当天 -->
          <span :class="{'d1': type == 2}" @click="get2Data(2)">{{ $t('collectionCenter.nearlyAWeek') }}</span><!-- 近一周 -->
          <span :class="{'d1': type == 3}" @click="get2Data(3)">{{ $t('collectionCenter.sameMonth') }}</span><!-- 当月 -->
        </div>
      </div>
    </div>
    <el-divider />
    <el-table
      ref="ref"
      :data="tableDate"
      :max-height="600"
      border
    >
      <el-table-column
        :label="$t('collectionCenter.ind')" align="center" type="index"
        width="60"
      ><!-- 序号 -->
        <template slot-scope="scope">
          <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <!-- 稽查批次号 -->
      <el-table-column
        :label="$t('collectionCenter.auditBatchNumber')" prop="batchNumber" align="center"
        width="100"
      />
      <!-- 稽查袋号 -->
      <el-table-column :label="$t('collectionCenter.inspectBag')" prop="auditBagNumber" align="center" />
      <!-- 实际袋号 -->
      <el-table-column :label="$t('collectionCenter.actualBag')" prop="actualBagNumber" align="center" />
      <!-- 包裹单号 -->
      <el-table-column :label="$t('collectionCenter.parcelOddNumber')" prop="scanNumber" align="center" />
      <!-- 扫描单号 -->
      <el-table-column :label="$t('collectionCenter.ScanNumber')" prop="actualScanNumber" align="center" />
      <!-- 订单状态 -->
      <el-table-column :label="$t('collectionCenter.OrderStatus')" prop="collectStatusName" align="center" />
      <!-- 稽查结果 -->
      <el-table-column :label="$t('collectionCenter.auditResult')" prop="auditStatusName" align="center" />
      <!-- 处理结果 -->
      <el-table-column :label="$t('collectionCenter.processingResult')" prop="auditResult" align="center" />
      <!-- 备注 -->
      <el-table-column :label="$t('collectionCenter.Note')" prop="remark" align="center" />
      <!-- 稽查网点 -->
      <el-table-column :label="$t('collectionCenter.auditNetwork')" prop="auditSiteName" align="center" />
      <!-- 揽收网点 -->
      <el-table-column :label="$t('collectionCenter.LanShouSite')" prop="collectSiteName" align="center" />
      <!-- 揽收人 -->
      <el-table-column :label="$t('collectionCenter.LanShouPeople')" prop="collectUserName" align="center" />
      <!-- 揽收时间 -->
      <el-table-column :label="$t('collectionCenter.lanshouT')" prop="collectTime" align="center" />
      <!-- 卸车人 -->
      <el-table-column :label="$t('collectionCenter.unloader')" prop="unloadUserName" align="center" />
      <!-- 卸车网点 -->
      <el-table-column :label="$t('collectionCenter.unloadDot')" prop="unloadSiteName" align="center" />
      <!-- 卸车时间 -->
      <el-table-column :label="$t('collectionCenter.unloadTime')" prop="unloadTime" align="center" />
      <!-- 装车网点 -->
      <el-table-column :label="$t('collectionCenter.loadSiteIdList')" prop="loadSiteName" align="center" />
      <!-- 装车人 -->
      <el-table-column :label="$t('collectionCenter.loader')" prop="loadUserName" align="center" />
      <!-- 装车时间 -->
      <el-table-column :label="$t('collectionCenter.loadTime')" prop="loadTime" align="center" />
      <!-- 签收时间 -->
      <el-table-column :label="$t('collectionCenter.signT')" prop="signerTime" align="center" />
    </el-table>
    <div class="right">
      <el-pagination
        :current-page.sync="queryForm.pageNumber"
        :page-size.sync="queryForm.pageSize"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0"
        @current-change="getNumPage"
        @size-change="getPageSize"
      />
    </div>
  </div>
</template>

<script>
import {
  getAuditDetailPage,
  getAuditDetailExport
} from '@/api/lanshou';
import dayjs from 'dayjs';
export default {
  props: ['inForm'],
  data() {
    return {
      // 装车信息统计列表
      queryForm: this.inForm.queryForm,
      tableDate: [],
      total: 0,
      updateTime: this.inForm.updateTime,
      type: this.inForm.dateType
    };
  },
  mounted() {
    this.queryForm.pageNumber = 1;
    this.queryForm.pageSize = 10;
    this.getNumPage();
  },
  methods: {
    get2Data(type) {
      this.type = type;
      const d = new Date();
      const day0 = dayjs(d).subtract(1, 'day').format('YYYY-MM-DD');
      const day1 = dayjs(d).format('YYYY-MM-DD');
      const day2 = dayjs(d).subtract(6, 'day').format('YYYY-MM-DD');
      const day3 = dayjs(d).startOf('month').format('YYYY-MM-DD');
      const day4 = dayjs(d).subtract(2, 'day').format('YYYY-MM-DD');
      const day5 = dayjs(d).startOf('week').format('YYYY-MM-DD');
      let date = {};
      if (type === 4) {
        date = {
          startTime: day4,
          endTime: day4
        };
      }
      if (type === 0) {
        date = {
          startTime: day0,
          endTime: day0
        };
      }
      if (type === 1) {
        date = {
          startTime: day1,
          endTime: day1
        };
      }
      if (type === 2) {
        date = {
          startTime: day5,
          endTime: day1
        };
      }
      if (type === 3) {
        date = {
          startTime: day3,
          endTime: day1
        };
      }
      this.queryForm = JSON.parse(JSON.stringify(this.inForm.queryForm));
      this.queryForm.params.startTime = date.startTime;
      this.queryForm.params.endTime = date.endTime;
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = 10;
      getAuditDetailPage(this.queryForm).then(res => {
        if (res.code === 200) {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      }).catch();
    },
    getNumPage() {
      getAuditDetailPage(this.queryForm).then(res => {
        if (res.code === 200) {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      }).catch();
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      getAuditDetailPage(this.queryForm).then(res => {
        if (res.code === 200) {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      }).catch();
    },
    download() {
      getAuditDetailExport(this.queryForm.params).then(res => {
        if (res.code === 200) {
          this.exportThis();
        }
      });
    },
    exportThis() {
      const h = this.$createElement;
      this.$msgbox({
        // '提示'
        title: this.$t('collectionCenter.tips'),
        message: h('p', null, [
          // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
          h('span', this.$t('collectionCenter.exportChangeTip')),
          // '请勿重复导出！'
          h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
        ]),
        showCancelButton: true,
        // '立即前往'
        confirmButtonText: this.$t('collectionCenter.Immediately'),
        // '关闭'
        cancelButtonText: this.$t('collectionCenter.close')
      }).then(action => {
        if (action === 'confirm') {
          this.$router.push({ path: '/resource/exportTaskList' });
        }
      }).catch(() => {
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.msgBox ::v-deep .el-table--border{
  color: #606266 !important;
  border-color: #ebeef5 !important;
  border-bottom-color: #ebeef5 !important;
  border-right-color: #ebeef5 !important;
}
.dateBox{
  display: flex; justify-content: flex-start; align-items: center;
  margin-left: 5px;
  span{display: inline-block;border: 1px solid #5EBC7F;padding: 0 15px;box-sizing: border-box;cursor: pointer; text-align: center;color: #5EBC7F;}
  span:hover, span.d1:hover{background: #51d96b;color: #fff;}
  span.d1:hover{border: 1px solid #51D96B;box-sizing: border-box;}
  span.d1{background: linear-gradient(90deg, #51D96B, transparent);box-sizing: border-box;color: #009C37}
}
</style>
