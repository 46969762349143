<!--我回收的-->
<template>
  <div>
    <tableCmp
      ref="tableCmp"
      :column-list="columnList"
      :is-select-btn="isSelectBtn"
      :table-data="tableData"
      :table-type="2"
      @selectionChange="selectionChange"
    />
    <el-pagination
      :current-page.sync="pageNumber"
      :page-size.sync="pageSize"
      :page-sizes="[10, 50, 100, 200, 400]"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 20px 0; text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('GlobalSetObj.ConfirmReturnQuantity')"
      :visible.sync="showChangeOutlets"
      width="500px"
      @close="clearData"
    >
      <div style="padding: 40px">
        <!--确认返回数量-->
        <el-form :key="showChangeOutlets" ref="ruleFormRef" :model="formQuantity">
          <el-form-item
            :label="$t('GlobalSetObj.ConfirmReturnQuantity')"
            :rules="[{ required: true, validator: validateQuantity, trigger: ['blur', 'change'] }]"
            prop="quantity"
          >
            <el-input
              v-model="formQuantity.quantity"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button
          size="small" type="primary" @click="confirmReturnQuantity"
        >{{ $t('customerManagements.submit') }}
        </el-button>
        <el-button size="small" @click="resetForm">{{ $t('customerManagements.reset') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  apiGetBagRecycleDetailBatchConfirm,
  apiGetBagRecycleDetailConfirm,
  apiGetBagRecycleDetailExportMyList,
  apiGetBagRecycleDetailMyList
} from '@/api/newExpressAPI';
import { goResourceTaskList } from '@/utils/goExportList';

export default {
  name: 'MyRecycling',
  components: {
    tableCmp: () => import('./tableCmp.vue')
  },
  props: {
    queryForm: {
      type: Object,
      default() {
        return {
          returnWaybillNumberList: [], // 运单号
          startTime: '', // 起始日期
          endTime: '', // 结束日期
          siteCode: this.$store.state.user.siteCode, // 网点编号
          siteName: this.$store.state.user.dotName, // 网点名称
          recycleStatus: null, // 是否确认
          waybillPrintStatus: null // 是否打印
        };
      }
    }
  },
  data() {
    const validateQuantity = (rule, value, callback) => {
      const reg = /^[0-9]\d*$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t('home.inputNumberdayu0')));
      }
    };
    return {
      total: 0,
      pageNumber: 1,
      pageSize: 10,
      isSelectBtn: true,
      tableData: [],
      selectArr: [],
      selectData: null,
      showChangeOutlets: false,
      formQuantity: {
        quantity: ''
      },
      validateQuantity: validateQuantity,
      columnList: [
        {
          label: this.$t('GlobalSetObj.waybillNumber'),
          prop: 'waybillNumber',
          minWidth: '180px',
          align: 'center'
        }, // 运单号
        {
          label: this.$t('GlobalSetObj.DocumentNumber'),
          prop: 'returnWaybillNumber',
          minWidth: '180px',
          align: 'center'
        }, // 单据号
        {
          label: this.$t('GlobalSetObj.ReturningBranch'),
          prop: 'returnSiteName',
          minWidth: '180px',
          align: 'center'
        }, // 返回网点
        {
          label: this.$t('home.vehicleNumber'),
          prop: 'vehicleNumber',
          minWidth: '180px',
          align: 'center'
        }, // 末端车牌号
        {
          label: this.$t('GlobalSetObj.ReceivingBranch'),
          prop: 'receiveSiteName',
          minWidth: '180px',
          align: 'center'
        }, // 接收网点
        {
          label: this.$t('GlobalSetObj.ExpectedReturnQuantity'),
          prop: 'shouldReturnCount',
          minWidth: '180px',
          align: 'center'
        }, // 应返回数量
        {
          label: this.$t('GlobalSetObj.ConfirmReturnQuantity'),
          prop: 'confirmReturnCount',
          minWidth: '180px',
          align: 'center'
        }, // 确认返回数量
        {
          label: this.$t('customerManagements.varianceNumber'),
          prop: 'differenceCount',
          minWidth: '180px',
          align: 'center'
        }, // 差异数量
        {
          label: this.$t('GlobalSetObj.RecyclingStatus'),
          prop: 'recycleStatus',
          minWidth: '180px',
          align: 'center'
        }, // 回收状态
        {
          label: this.$t('newOrder.waybillStatus'),
          prop: 'waybillStatusName',
          minWidth: '180px',
          align: 'center'
        }, // 运单状态
        {
          label: this.$t('GlobalSetObj.Returner'),
          prop: 'returnMan',
          minWidth: '180px',
          align: 'center'
        }, // 返回人
        {
          label: this.$t('GlobalSetObj.ReturnDate'),
          prop: 'returnTime',
          minWidth: '180px',
          align: 'center'
        }, // 返回时间
        {
          label: this.$t('collectionCenter.verifyPerson'),
          prop: 'confirmMan',
          minWidth: '180px',
          align: 'center'
        }, // 确认人
        {
          label: this.$t('collectionCenter.verifyTime'),
          prop: 'confirmTime',
          minWidth: '180px',
          align: 'center'
        } // 确认时间
      ]
    };
  },
  created() {
    this.getTableData(this.queryForm);
  },
  methods: {
    // 列表操作
    selectionChange(data, type) {
      // type = delete: 删除 selected: 列表数据勾选 recyclingConfirmation: 回收确认
      if (type === 'recyclingConfirmation') {
        console.log(data, 'recyclingConfirmation');
        this.selectData = data;
        this.showChangeOutlets = true;
      } else if (type === 'selected') {
        this.selectArr = data;
      }
    },
    // 获取列表数据
    getTableData(param) {
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        params: {
          type: 'recycle',
          ...param
        }
      };
      console.log(params, '===params');
      apiGetBagRecycleDetailMyList(params).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 表格布局更新
    doLayout() {
      this.$refs.tableCmp.$refs.wovenBagManagementTable.doLayout();
    },
    // 每页条数
    handleSizeChange(val) {
      this.pageNumber = 1;
      this.pageSize = val;
      this.getTableData(this.queryForm);
    },
    // 当前页
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getTableData(this.queryForm);
    },
    // 导出
    exportData(param) {
      console.log(param, '===param');
      const params = {
        type: 'recycle',
        ...param
      };
      apiGetBagRecycleDetailExportMyList(params).then((res) => {
        if (res.code === 200) {
          goResourceTaskList();
        }
      });
    },
    // 关闭弹窗
    clearData() {
      this.formQuantity.quantity = '';
      this.showChangeOutlets = false;
    },
    // 单个确定回收数量
    confirmReturnQuantity() {
      this.$refs['ruleFormRef'].validate((valid) => {
        if (valid) {
          console.log(this.selectData, ' this.selectData');
          const params = {
            id: this.selectData.id,
            confirmReturnCount: this.formQuantity.quantity
          };
          if (this.formQuantity.quantity > this.selectData.shouldReturnCount) {
            this.$message.error(this.$t('home.tip03'));
            return;
          }
          apiGetBagRecycleDetailConfirm(params).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: this.$t('GlobalSetObj.TheOperationSucceeded') // '操作成功'
              });
              this.clearData();
              this.$refs.tableCmp.clearSelection();
              this.getTableData(this.queryForm);
            }
          });
        }
      });
    },
    // 重置
    resetForm() {
      this.formQuantity.quantity = '';
    },
    // 批量回收确认
    batchConfirmReturnQuantity() {
      if (this.selectArr.length === 0) {
        this.$message({
          type: 'warning',
          message: this.$t('collectionCenter.checkMsg') // '请选择数据'
        });
      } else {
        const recylerStatus = [];
        const param = {
          ids: []
        };
        this.selectArr.map((item) => {
          if (item.recycleStatus === 0) {
            param.ids.push(item.id);
          }
        });
        if (param.ids.length === 0) {
          this.$message.error('不可重复回收确认');
          return;
        }
        console.log(this.selectArr, '===this.selectArr');
        console.log(recylerStatus, 'recylerStatus');
        this.$confirm(
          this.$t('GlobalSetObj.PleaseConfirmWhetherTheReceivedQuantity'),
          this.$t('basicData.tips'),
          {
            // '请确认是否收到的数量与登记的一致'
            confirmButtonText: this.$t('collectionCenter.confirm'), // '确定'
            cancelButtonText: this.$t('collectionCenter.cancel'), // '取消'
            type: 'warning'
          }
        ).then(() => {
          apiGetBagRecycleDetailBatchConfirm(param).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: this.$t('GlobalSetObj.TheOperationSucceeded') // '操作成功'
              });
              this.$refs.tableCmp.clearSelection();
              this.getTableData(this.queryForm);
            }
          });
        });
      }
    }
  }
};
</script>

<style scoped></style>
