var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c(
      "div",
      { staticClass: "formClass" },
      [
        _c(
          "el-form",
          {
            ref: "queryFormCope",
            staticClass: "queryFormClass",
            attrs: {
              model: _vm.formData,
              rules: _vm.rules,
              size: "small",
              "label-width": _vm.$i18n.locale === "zh" ? "120px" : "",
              "label-position": _vm.$i18n.locale !== "zh" ? "top" : "right",
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _vm.operationType === 2
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("basicData.FeeCode"),
                              prop: "code",
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.formData.code))])]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.ExpenseName"),
                          prop: "name",
                          rules: {
                            required: true,
                            message: _vm.$t("basicData.CannotBeEmpty"),
                            trigger: ["blur", "change"],
                          },
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: _vm.$t("basicData.PleaseEnter"),
                            maxlength: 20,
                            "show-word-limit": "",
                          },
                          model: {
                            value: _vm.formData.name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData,
                                "name",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formData.name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.ExpenseName") + "(PU)",
                          prop: "namePu",
                          rules: {
                            required: true,
                            message: _vm.$t("basicData.CannotBeEmpty"),
                            trigger: ["blur", "change"],
                          },
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: _vm.$t("basicData.PleaseEnter"),
                          },
                          model: {
                            value: _vm.formData.namePu,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData,
                                "namePu",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formData.namePu",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("collectionCenter.businessT"),
                          prop: "businessType",
                          rules: {
                            required: true,
                            message: _vm.$t("basicData.CannotBeEmpty"),
                            trigger: ["blur", "change"],
                          },
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            },
                            model: {
                              value: _vm.formData.businessType,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "businessType", $$v)
                              },
                              expression: "formData.businessType",
                            },
                          },
                          _vm._l(_vm.BUSINESS_TYPE_TWO, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.accountType"),
                          prop: "accountType",
                          rules: {
                            required: true,
                            message: _vm.$t("basicData.CannotBeEmpty"),
                            trigger: ["blur", "change"],
                          },
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                              disabled: _vm.operationType === 2,
                            },
                            model: {
                              value: _vm.formData.accountType,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "accountType", $$v)
                              },
                              expression: "formData.accountType",
                            },
                          },
                          _vm._l(_vm.SUBJECT_TYPE, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.status"),
                          prop: "state",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.formData.state,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "state", $$v)
                              },
                              expression: "formData.state",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: false } }, [
                              _vm._v(_vm._s(_vm.$t("basicData.disable"))),
                            ]),
                            _c("el-radio-button", { attrs: { label: true } }, [
                              _vm._v(_vm._s(_vm.$t("basicData.enable"))),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.remark"),
                          prop: "remark",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: 4,
                            maxlength: 200,
                            "show-word-limit": "",
                            placeholder: _vm.$t("basicData.PleaseEnter"),
                          },
                          model: {
                            value: _vm.formData.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "remark", $$v)
                            },
                            expression: "formData.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.formData.accountType !== 2 &&
                _vm.formData.businessType === 3
                  ? [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("basicData.taxFeeType"),
                                prop: "taxationType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  on: { change: _vm.taxationTypeChange },
                                  model: {
                                    value: _vm.formData.taxationType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "taxationType",
                                        $$v
                                      )
                                    },
                                    expression: "formData.taxationType",
                                  },
                                },
                                _vm._l(_vm.TAX_TYPE, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.formData.taxationType === 3
                        ? [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("basicData.basicRate"),
                                      prop: "taxBase",
                                    },
                                  },
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "GlobalSetObj.pleaseInput"
                                        ),
                                        precision: 2,
                                        min: 0,
                                        controls: false,
                                        max: 999999999,
                                      },
                                      model: {
                                        value: _vm.formData.taxBase,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formData, "taxBase", $$v)
                                        },
                                        expression: "formData.taxBase",
                                      },
                                    }),
                                    _c("span", [_vm._v("%")]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "basicData.detailedCalculation"
                                      ),
                                      required: true,
                                      prop: "ajdDetailsList",
                                    },
                                  },
                                  [
                                    _c("table", { staticClass: "tax-table" }, [
                                      _c("thead", [
                                        _c("tr", [
                                          _c("th", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "basicData.amountStartInclusive"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("th", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "basicData.amountEndExclusive"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("th", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "basicData.exemptionPercentage"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("th", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "basicData.deductedAmount"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("th", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("GlobalSetObj.operate")
                                              )
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.formData.ajdDetailsList,
                                          function (item, index) {
                                            return _c("tr", { key: index }, [
                                              _c(
                                                "td",
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "tax-table-input",
                                                      attrs: {
                                                        prop:
                                                          "ajdDetailsList." +
                                                          index +
                                                          ".startFee",
                                                        rules: {
                                                          required: true,
                                                          trigger: [
                                                            "blur",
                                                            "change",
                                                          ],
                                                          package: item.endFee,
                                                          validator:
                                                            _vm.validatePrice,
                                                          negative1: 1,
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("el-input-number", {
                                                        attrs: {
                                                          placeholder: _vm.$t(
                                                            "GlobalSetObj.pleaseInput"
                                                          ),
                                                          precision: 2,
                                                          min: 0,
                                                          controls: false,
                                                          max: 999999999,
                                                        },
                                                        model: {
                                                          value: item.startFee,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "startFee",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.startFee",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "tax-table-input",
                                                      attrs: {
                                                        prop:
                                                          "ajdDetailsList." +
                                                          index +
                                                          ".endFee",
                                                        rules: {
                                                          required: true,
                                                          trigger: [
                                                            "blur",
                                                            "change",
                                                          ],
                                                          package:
                                                            item.startFee,
                                                          validator:
                                                            _vm.validatePrice,
                                                          negative1: 2,
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("el-input-number", {
                                                        attrs: {
                                                          placeholder: _vm.$t(
                                                            "GlobalSetObj.pleaseInput"
                                                          ),
                                                          precision: 2,
                                                          min: 0,
                                                          controls: false,
                                                          max: 999999999,
                                                        },
                                                        model: {
                                                          value: item.endFee,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "endFee",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.endFee",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "tax-table-input",
                                                      attrs: {
                                                        prop:
                                                          "ajdDetailsList." +
                                                          index +
                                                          ".reduction",
                                                        rules: {
                                                          required: true,
                                                          message: _vm.$t(
                                                            "GlobalSetObj.pleaseInput"
                                                          ),
                                                          trigger: [
                                                            "blur",
                                                            "change",
                                                          ],
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("el-input-number", {
                                                        attrs: {
                                                          placeholder: _vm.$t(
                                                            "GlobalSetObj.pleaseInput"
                                                          ),
                                                          precision: 2,
                                                          min: 0,
                                                          controls: false,
                                                          max: 999999999,
                                                        },
                                                        model: {
                                                          value: item.reduction,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "reduction",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.reduction",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "tax-table-input",
                                                      attrs: {
                                                        prop:
                                                          "ajdDetailsList." +
                                                          index +
                                                          ".deductionFee",
                                                        rules: {
                                                          required: true,
                                                          message: _vm.$t(
                                                            "GlobalSetObj.pleaseInput"
                                                          ),
                                                          trigger: [
                                                            "blur",
                                                            "change",
                                                          ],
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("el-input-number", {
                                                        attrs: {
                                                          placeholder: _vm.$t(
                                                            "GlobalSetObj.pleaseInput"
                                                          ),
                                                          precision: 2,
                                                          min: 0,
                                                          controls: false,
                                                          max: 999999999,
                                                        },
                                                        model: {
                                                          value:
                                                            item.deductionFee,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "deductionFee",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.deductionFee",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  index !== 0
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "danger",
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.delAjdDetailsList(
                                                                index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "GlobalSetObj.Delete"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "primary",
                                              },
                                              on: {
                                                click: _vm.addAjdDetailsList,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("GlobalSetObj.add")
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm.formData.taxationType === 2 ||
                      _vm.formData.taxationType === 1
                        ? [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "basicData.detailedCalculation"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 0 } },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 9 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { prop: "taxBase" } },
                                              [
                                                _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "GlobalSetObj.pleaseInput"
                                                    ),
                                                    precision: 2,
                                                    min: 0,
                                                    controls: false,
                                                    max: 999999999,
                                                  },
                                                  model: {
                                                    value: _vm.formData.taxBase,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "taxBase",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.taxBase",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("el-col", { attrs: { span: 3 } }, [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "5px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "basicData.taxableBasePercentage"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 9 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { prop: "reduction" } },
                                              [
                                                _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "GlobalSetObj.pleaseInput"
                                                    ),
                                                    precision: 2,
                                                    min: 0,
                                                    controls: false,
                                                    max: 999999999,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formData.reduction,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "reduction",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.reduction",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("el-col", { attrs: { span: 3 } }, [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "5px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "basicData.exemptionRate"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "permit",
            rawName: "v-permit:remove",
            value: "btn:finance:basicData:payable:feesType-editAdd",
            expression: "'btn:finance:basicData:payable:feesType-editAdd'",
            arg: "remove",
          },
        ],
        staticStyle: {
          "text-align": "right",
          padding: "10px",
          "border-top": "1px solid #ccc",
        },
      },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "small" },
            on: {
              click: function ($event) {
                return _vm.saveFormData(1)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("basicData.Save")))]
        ),
        _c(
          "el-button",
          {
            attrs: { size: "small" },
            on: {
              click: function ($event) {
                return _vm.saveFormData(2)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }