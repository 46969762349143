var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "form-container" },
      [
        _c(
          "el-form",
          {
            key: _vm.rechargeData.id,
            ref: "rechargeData",
            attrs: {
              rules: _vm.recharge,
              model: _vm.formData,
              "label-width": "auto",
              size: "small",
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 10 } },
              [
                _c("el-col", { attrs: { span: 12 } }, [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("receivablePayable.costumer") + "：",
                            prop: "providerName",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.formData.providerName)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.$t("receivablePayable.TicketAmount") + "：",
                            prop: "billFee",
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.formData.billFee))])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.$t("receivablePayable.PaymentTime") + "：",
                            prop: "acceptTime",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "date",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              placeholder: _vm.$t("basicData.SelectADate"),
                            },
                            model: {
                              value: _vm.formData.acceptTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "acceptTime", $$v)
                              },
                              expression: "formData.acceptTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.PaymentMethod") + "：",
                            prop: "payableMethod",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t("basicData.PleaseSelect"),
                              },
                              model: {
                                value: _vm.formData.payableMethod,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "payableMethod", $$v)
                                },
                                expression: "formData.payableMethod",
                              },
                            },
                            _vm._l(_vm.paymentMethod, function (item) {
                              return _c("el-option", {
                                key: item.code,
                                attrs: { label: item.name, value: item.code },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.Currency") + "：",
                            prop: "currencyCode",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { filterable: "", clearable: "" },
                              model: {
                                value: _vm.formData.currencyCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "currencyCode", $$v)
                                },
                                expression: "formData.currencyCode",
                              },
                            },
                            _vm._l(_vm.currencyList, function (item, ind) {
                              return _c("el-option", {
                                key: ind,
                                attrs: {
                                  label:
                                    item.chineseName + "(" + item.code + ")",
                                  value: item.code,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.TicketNumber") + "：",
                            prop: "ticketNumber",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            model: {
                              value: _vm.formData.ticketNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "ticketNumber", $$v)
                              },
                              expression: "formData.ticketNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.ReceiptNumber") + "：",
                            prop: "payableNumber",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.formData.payableNumber)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.$t(
                                "receivablePayable.HistoricalPayoutAmount"
                              ) + "：",
                            prop: "actualFee",
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.actualFee))])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.account") + "：",
                            prop: "accountAlias",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { filterable: "", clearable: "" },
                              model: {
                                value: _vm.formData.accountAlias,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "accountAlias", $$v)
                                },
                                expression: "formData.accountAlias",
                              },
                            },
                            _vm._l(_vm.accountOption, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.accountAlias,
                                  value: item.accountNumber,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.$t(
                                "receivablePayable.TheAmountOfThePayment"
                              ) + "：",
                            prop: "actualFee",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            model: {
                              value: _vm.formData.actualFee,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "actualFee", $$v)
                              },
                              expression: "formData.actualFee",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          key: _vm.rateKey,
                          attrs: {
                            label: _vm.$t("basicData.exchangeRate") + "：",
                            prop: "exchangeRate",
                            rules: {
                              required:
                                _vm.formData.currencyCode.toUpperCase() !==
                                "BRL",
                              suffix: 5,
                              negative: "",
                              validator: _vm.priceType,
                              trigger: ["blur", "change"],
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            model: {
                              value: _vm.formData.exchangeRate,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "exchangeRate", $$v)
                              },
                              expression: "formData.exchangeRate",
                            },
                          }),
                          _vm.adopt && _vm.exchangeRate !== 0
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    left: "0",
                                    top: "30px",
                                    color: "#c98f06ff",
                                  },
                                },
                                [
                                  _vm.allEqual
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-right": "5px",
                                          },
                                        },
                                        [_vm._v("≈")]
                                      )
                                    : _vm._e(),
                                  _vm._v(
                                    _vm._s(_vm.exchangeRate.toFixed(2)) + "BRL "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 24 } }, [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.remark") + "：",
                            prop: "remark",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", rows: 6 },
                            model: {
                              value: _vm.formData.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "remark", $$v)
                              },
                              expression: "formData.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 24 } }, [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.annex") + "：",
                            prop: "attachmentUrl",
                          },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "uploadFile",
                              staticClass: "upload-demo",
                              attrs: {
                                drag: "",
                                action: "",
                                multiple: "",
                                "http-request": _vm.enclosureUpload,
                                "before-upload": _vm.beforeAvatarUpload,
                                "on-remove": _vm.handleRemove,
                                "file-list": _vm.fileList,
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-upload" }),
                              _c("div", { staticClass: "el-upload__text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "basicData.DragTheFileHereOrClickUpload"
                                    )
                                  )
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "basicData.UploadTheAttachmentFormat"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 24 } }, [
                  _c(
                    "div",
                    { staticClass: "grid-content bg-purple" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.$t("receivablePayable.PartialDisbursement") +
                              "：",
                            prop: "isPartialPayment",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.formData.isPartialPayment,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "isPartialPayment",
                                    $$v
                                  )
                                },
                                expression: "formData.isPartialPayment",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: 1 } }, [
                                _vm._v(_vm._s(_vm.$t("orderCenterCont.yes"))),
                              ]),
                              _c("el-radio-button", { attrs: { label: 0 } }, [
                                _vm._v(_vm._s(_vm.$t("orderCenterCont.No"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "el-button",
          {
            on: {
              click: function ($event) {
                return _vm.editCreditLimit(1)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
        ),
        _c(
          "el-button",
          {
            directives: [
              {
                name: "permit",
                rawName: "v-permit:remove",
                value: "btn:finance:costManagement:costApproval:Edit",
                expression: "'btn:finance:costManagement:costApproval:Edit'",
                arg: "remove",
              },
            ],
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.editCreditLimit(2)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("basicData.determine")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }