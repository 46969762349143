/**
 * 应付费用模块
 */

const state = {
  providerList: [], // 应付主体
  outletsList: [], // 站点/网点
  operatorList: [], // 操作人
  feeTypeOption: [] // 费用类型
};

const mutations = {
  // 更新应付主体
  SET_PROVIDER_LIST(state, providerList) {
    state.providerList = providerList;
  },

  // 更新网点
  SET_OUTLETS_LIST(state, outletsList) {
    state.outletsList = outletsList;
  },

  // 更新操作人
  SET_OPERATOR_LIST(state, operatorList) {
    state.operatorList = operatorList;
  },

  // 更新费用类型
  SET_FEE_TYPE_OPTION(state, feeTypeOption) {
    state.feeTypeOption = feeTypeOption;
  }
};

const actions = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters: {},
  modules: {}
};
