var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("receivablePayable.GenerateBillsPayable"),
        width: "600px",
        visible: _vm.isShow,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isShow = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "100px",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("receivablePayable.SelectBillingDate"),
                prop: "TimeCollection",
              },
            },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "start-placeholder": _vm.$t("operationCenter.startDate"),
                  "end-placeholder": _vm.$t("operationCenter.endDate"),
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.ruleForm.TimeCollection,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "TimeCollection", $$v)
                  },
                  expression: "ruleForm.TimeCollection",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.onClose } },
            [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submitForm },
            },
            [_vm._v(_vm._s(_vm.$t("basicData.determine")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }