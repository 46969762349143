<template> <!-- 备注 -->
  <div>
    <el-button
      v-permit:remove="permission"
      size="small" style="margin-right: 10px;"
      @click="open"
    >{{ $t('receivablePayable.remark') }}</el-button>

    <el-dialog
      :append-to-body="true"
      :title="$t('receivablePayable.AddANote')"
      :visible.sync="isBool"
      width="500px"
      :loading="loading"
    >
      <el-form
        ref="ruleForm" :model="ruleForm" :rules="rules"
        label-width="10px" class="demo-ruleForm"
      >
        <el-form-item label="" prop="remarks">
          <el-input
            v-model="ruleForm.remarks"
            type="textarea"
            :placeholder="$t('GlobalSetObj.pleaseInput')"
            :autosize="{ minRows: 5, maxRows: 10}"
          />
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <!--取 消-->
        <el-button @click="isBool = false">{{ $t('basicData.Cancel') }}</el-button>

        <!--确 定-->
        <el-button type="primary" @click="submitForm('ruleForm')">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',

  props: {
    // 提交函数
    asyncSumbit: {
      type: Function,
      required: true
    },

    // 账单类型
    payableType: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      isBool: false,
      ruleForm: {
        remarks: ''
      },
      loading: false,

      rules: {
        remarks: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'blur' }
        ]
      },
      permission: '' // 权限
    };
  },

  activated() {
    this.getPermission();
  },
  created() {
    this.getPermission();
  },

  methods: {
    open() {
      this.isBool = true;
      this.ruleForm.remarks = '';
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        }

        this.onDetermine();
      });
    },

    // 确定
    onDetermine() {
      this.loading = true;
      this.asyncSumbit(this.ruleForm.remarks).then(() => {
        this.isBool = false;
      }).finally(() => {
        this.loading = false;
      });
    },

    getPermission() {
      switch (this.payableType) {
        case 1: // 安俊
          this.permission = 'btn:finance:payableManagement:hut:remarks';
          break;
        case 2: // 第三方
          this.permission = 'btn:finance:payableManagement:threeCollect:remarks';
          break;
        case 3: // AJD
          this.permission = 'btn:finance:payableManagement:ajd:remarks';
          break;
        case 4: // DSP
          this.permission = 'btn:finance:payableManagement:dsp:remarks';
          break;
        default: // 运输干支线
          this.permission = 'btn:finance:payableManagement:transportation:remarks';
          break;
      }
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
