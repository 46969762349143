var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "query-container" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "form-container",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            size: "small",
            "label-position": _vm.$i18n.locale !== "zh" ? "top" : "right",
            "label-width": _vm.$i18n.locale !== "zh" ? "auto" : "120px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("compensationManagement.rulesEncoding") },
            },
            [
              _c("el-input", {
                attrs: {
                  disabled: "",
                  placeholder: _vm.$t("receivablePayable.SystemGenerated"),
                },
                model: {
                  value: _vm.ruleForm.ruleCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "ruleCode", $$v)
                  },
                  expression: "ruleForm.ruleCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("GlobalSetObj.RuleName"),
                prop: "ruleName",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  maxlength: "100",
                  placeholder: _vm.$t("operationCenter.PleaseEnterTheContent"),
                  clearable: "",
                },
                model: {
                  value: _vm.ruleForm.ruleName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "ruleName", $$v)
                  },
                  expression: "ruleForm.ruleName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("collectionCenter.businessT"),
                prop: "businessType",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    placeholder: _vm.$t("operationCenter.PleaseSelect"),
                    clearable: "",
                  },
                  on: { change: _vm.handleBusinessTypeChange },
                  model: {
                    value: _vm.ruleForm.businessType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "businessType", $$v)
                    },
                    expression: "ruleForm.businessType",
                  },
                },
                [
                  _c("el-option", {
                    key: "5",
                    attrs: {
                      label: _vm.$t("compensationManagement.AJDDispatch"),
                      value: 5,
                    },
                  }),
                  _c("el-option", {
                    key: "6",
                    attrs: {
                      label: _vm.$t("compensationManagement.DSPDispatch"),
                      value: 6,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("compensationManagement.ClaimType"),
                prop: "claimType",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    placeholder: _vm.$t("operationCenter.PleaseSelect"),
                    clearable: "",
                  },
                  model: {
                    value: _vm.ruleForm.claimType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "claimType", $$v)
                    },
                    expression: "ruleForm.claimType",
                  },
                },
                [
                  _c("el-option", {
                    key: "1",
                    attrs: {
                      label: _vm.$t(
                        "compensationManagement.TimeoutNotClosedLoop"
                      ),
                      value: 1,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("compensationManagement.computeNode") } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    placeholder: _vm.$t("operationCenter.PleaseSelect"),
                    clearable: "",
                    disabled: "",
                  },
                  model: {
                    value: _vm.ruleForm.calculationNode,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "calculationNode", $$v)
                    },
                    expression: "ruleForm.calculationNode",
                  },
                },
                [
                  _c("el-option", {
                    key: "1",
                    attrs: {
                      label: _vm.$t(
                        "compensationManagement.byTimeOfFirstEntry"
                      ),
                      value: "1",
                    },
                  }),
                  _c("el-option", {
                    key: "2",
                    attrs: {
                      label: _vm.$t(
                        "compensationManagement.deliveryTaskCreationTime"
                      ),
                      value: "2",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("compensationManagement.calculationPeriod"),
                prop: "calculationPeriod",
              },
            },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    clearable: "",
                    placeholder: _vm.$t(
                      "operationCenter.PleaseEnterTheContent"
                    ),
                  },
                  model: {
                    value: _vm.ruleForm.calculationPeriod,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "calculationPeriod", $$v)
                    },
                    expression: "ruleForm.calculationPeriod",
                  },
                },
                [
                  _c("template", { slot: "append" }, [
                    _vm._v(_vm._s(_vm.$t("orderCenterCont.days"))),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("compensationManagement.commencementDate"),
                prop: "date",
              },
            },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "start-placeholder": _vm.$t("GlobalSetObj.startDate"),
                  "end-placeholder": _vm.$t("GlobalSetObj.endDate"),
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.ruleForm.date,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "date", $$v)
                  },
                  expression: "ruleForm.date",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("orderDetails.State"), prop: "state" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                    clearable: "",
                    filterable: "",
                    multiple: "",
                  },
                  on: { change: _vm.handleStateChange },
                  model: {
                    value: _vm.ruleForm.state,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "state", $$v)
                    },
                    expression: "ruleForm.state",
                  },
                },
                [
                  _c("el-option", {
                    key: "all",
                    attrs: { label: _vm.$t("GlobalSetObj.all"), value: "ALL" },
                  }),
                  _vm._l(_vm.stateList, function (item) {
                    return _c(
                      "el-option",
                      {
                        key: item.value,
                        attrs: { label: item.label, value: item.label },
                      },
                      [
                        _c("span", { staticStyle: { float: "left" } }, [
                          _vm._v(_vm._s(item.label)),
                        ]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              float: "right",
                              color: "#8492a6",
                              "font-size": "13px",
                            },
                          },
                          [_vm._v(_vm._s(item.name))]
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.onClose } },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.determine")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }