var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kanban_content" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "margin-bottom": "10px" },
                },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        model: _vm.queryForm,
                        "label-position": "top",
                        size: "small",
                      },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("newOrder.createTime") } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  clearable: true,
                                  "default-time": ["00:00:00", "23:59:59"],
                                  format:
                                    _vm.$i18n.locale == "zh"
                                      ? _vm.zhDate
                                      : _vm.ptDate,
                                  placeholder: _vm.$t(
                                    "newOrder.SelectDatetime"
                                  ),
                                  "range-separator": "~",
                                  type: "datetimerange",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                },
                                model: {
                                  value: _vm.timeValue,
                                  callback: function ($$v) {
                                    _vm.timeValue = $$v
                                  },
                                  expression: "timeValue",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("basicData.StateOfOutlet"),
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                    clearable: "",
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.queryForm.state,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryForm, "state", $$v)
                                    },
                                    expression: "queryForm.state",
                                  },
                                },
                                _vm._l(_vm.StateList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.label,
                                      value: item.label,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "collectionCenter.transferCenter"
                                ),
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                    clearable: "",
                                    "collapse-tags": "",
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.queryForm.transferId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryForm, "transferId", $$v)
                                    },
                                    expression: "queryForm.transferId",
                                  },
                                },
                                _vm._l(_vm.TransferList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("collectionCenter.siteName"),
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                    clearable: "",
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.queryForm.siteId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryForm, "siteId", $$v)
                                    },
                                    expression: "queryForm.siteId",
                                  },
                                },
                                _vm._l(_vm.siteList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-top": "28px" },
                              attrs: { label: "" },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-search",
                                    type: "primary",
                                  },
                                  on: { click: _vm.getTransferKanBan },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("newOrder.search")) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-refresh-left",
                                    type: "info",
                                  },
                                  on: { click: _vm.resetForm },
                                },
                                [_vm._v(_vm._s(_vm.$t("newOrder.reset")) + " ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 7 } },
            [
              _c("operationData", {
                attrs: {
                  loadding: _vm.transferKanBanV3.loading,
                  unloading: _vm.transferKanBanV3.unload,
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { staticStyle: { position: "relative" }, attrs: { span: 17 } },
            [
              _c("allocateRetentionData", {
                attrs: { "center-data": _vm.retainedPiece },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticStyle: { "margin-top": "10px", position: "relative" },
              attrs: { span: 18 },
            },
            [
              _c("slaNumber", {
                key: _vm.keyValue,
                attrs: { "site-sla-rate-list": _vm.siteSlaRateList },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { staticStyle: { "margin-top": "10px" }, attrs: { span: 6 } },
            [_c("onTimeShow", { attrs: { "on-data-v3": _vm.onDataV3 } })],
            1
          ),
          _c(
            "el-col",
            { staticStyle: { "margin-top": "10px" }, attrs: { span: 12 } },
            [
              _c("ontimeSignData", {
                attrs: {
                  "on-data-v3": _vm.onDataV3,
                  "site-sla-list-v3": _vm.siteSlaListV3,
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { staticStyle: { "margin-top": "10px" }, attrs: { span: 12 } },
            [
              _c("networkRetentionData", {
                attrs: { "stay-tracking-v-o-s": _vm.stayTrackingVOS },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { staticStyle: { "margin-top": "10px" }, attrs: { span: 24 } },
            [
              _c("networkTimeValidity", {
                key: _vm.keyValue,
                attrs: { "site-aging-v-o-s": _vm.siteAgingVOS },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("falseSignature", {
                key: _vm.keyValue,
                staticStyle: { "margin-top": "10px" },
                attrs: {
                  "false-signatures-vo": _vm.falseSignaturesVo,
                  "false-signatures-vo-list": _vm.falseSignaturesVoList,
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { staticStyle: { "margin-top": "10px" }, attrs: { span: 24 } },
            [
              _c("trackStopMonitoring", {
                attrs: {
                  "tracking-stop-monitor-v-o-s": _vm.trackingStopMonitorVOS,
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { staticStyle: { "margin-top": "10px" }, attrs: { span: 12 } },
            [
              _c("lossStatistics", {
                attrs: { "lost-statistics-v-o-s": _vm.lostStatisticsVOS },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { staticStyle: { "margin-top": "10px" }, attrs: { span: 12 } },
            [
              _c("damageStatistics", {
                attrs: { "worn-statistics-v-o-s": _vm.wornStatisticsVOS },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }