<template>
  <StatisticiansTipsCmp :arr="arr" />
</template>

<script>
import StatisticiansTipsCmp from '@/components/StatisticiansTipsCmp/index.vue';
import Big from 'big.js';

export default {
  name: '',

  components: {
    StatisticiansTipsCmp
  },

  props: {
    // 列表数据
    tableData: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      arr: [
        {
          name: this.$t('ClaimsManagement.TotalNumberOfVotes'), // 总票数
          value: 0,
          suffix: ';'
        },
        {
          name: this.$t('orderCenterCont.TotalCost'), // 总费用
          value: 0,
          suffix: ';'
        },
        {
          name: this.$t('orderCenterCont.AverageCostPerTicket'), // 平均每票费用
          value: 0,
          suffix: ''
        }
      ]
    };
  },

  watch: {
    'tableData': {
      handler(val) {
        // 总票数
        // const len = val.length;
        // 订单总数量
        const orderQty = val.reduce((acc, item) => {
          return new Big(acc).plus(item.orderQty || 0);
        }, Big(0)).toNumber();
        if (orderQty === 0) {
          this.reset();
          return;
        }
        this.arr[0].value = orderQty;

        // 总费用
        const total = this.getSum();
        this.arr[1].value = total;

        // 平均每票费用
        this.arr[2].value = this.getAverage(total, orderQty);
      },
      immediate: true
    }
  },

  created() {

  },

  methods: {
    // 重置
    reset() {
      this.arr.forEach(item => {
        item.value = 0;
      });
    },

    // 总费用统计
    getSum() {
      return this.tableData.reduce((acc, item) => {
        return new Big(acc).plus(item.totalReceivable || 0);
      }, Big(0)).toNumber();
    },

    // 平均每票费用
    getAverage(val, len) {
      if (len === 0) {
        return 0;
      }
      return Big(val).div(len).round(2).toNumber();
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
