<template>
  <div class="app-container">
    <div ref="searchForm" class="searchForm mb5">
      <el-card class="box-card">
        <el-form
          ref="form" :model="queryForm" label-width="auto"
          size="small"
          label-position="top"
        >
          <div class="searchContainer">
            <el-row :gutter="20">
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--付款单号-->
                  <el-form-item :label="$t('receivablePayable.PaymentSlipNumber')" label-width="120px">
                    <template slot="label">
                      <el-select
                        v-model="typeNumber"
                        filterable
                        :placeholder="$t('operationCenter.PleaseSelect')"
                        clearable
                        style="width: 100%; height:  28px;"
                      >
                        <!--付款单号-->
                        <el-option :label="$t('receivablePayable.PaymentSlipNumber')" :value="1" />
                        <!--账单批次号-->
                        <el-option :label="$t('receivablePayable.BillingBatchNumber')" :value="2" />
                      </el-select>
                    </template>
                    <el-input v-model="searchNum" :placeholder="$t('operationCenter.PleaseEnterTheContent')" />
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--付款状态-->
                  <el-form-item :label="$t('receivablePayable.PaymentStatus')">
                    <el-select
                      v-model="queryForm.params.payableStatus"
                      filterable
                      multiple
                      :placeholder="$t('operationCenter.PleaseSelect')"
                      clearable
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="item in PAYABLE_STATUS" :key="item.value" :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--成本主体-->
                  <el-form-item :label="$t('receivablePayable.costumer')">
                    <el-select
                      v-model="queryForm.params.providerId"
                      filterable
                      :placeholder="$t('operationCenter.PleaseSelect')"
                      clearable
                      style="width: 100%;"
                      @change="providerPageChange()"
                    >
                      <el-option
                        v-for="item in providerList"
                        :key="item.id"
                        :label="item.providerBody + '（' + item.providerName + '）(' + item.code + ')'"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <!--              <el-col :span="6">-->
              <!--                <div class="grid-content bg-purple">-->
              <!--                  &lt;!&ndash;网点&ndash;&gt;-->
              <!--                  <el-form-item :label="$t('receivablePayable.costOutlets') + '：'">-->
              <!--                    <QuerySiteCmp-->
              <!--                      :val="queryForm.params.siteId"-->
              <!--                      :clearable="true"-->
              <!--                      :provider-id="queryForm.params.providerId"-->
              <!--                      @change="val => queryForm.params.siteId = val"-->
              <!--                    />-->
              <!--                  </el-form-item>-->
              <!--                </div>-->
              <!--              </el-col>-->
              <el-col :span="6">
                <!--业务类型-->
                <div class="grid-content bg-purple">
                  <el-form-item :label="$t('collectionCenter.businessT')">
                    <el-select
                      v-model="queryForm.params.businessType"
                      filterable
                      :placeholder="$t('operationCenter.PleaseSelect')"
                      clearable
                      style="width: 100%;"
                    >
                      <!--全部-->
                      <el-option key="0" :label="$t('basicData.all')" :value="null" />
                      <el-option
                        v-for="item in BUSINESS_TYPE_TWO"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-form>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="searchResources"
        >{{ $t('operationCenter.Procurar') }}</el-button>

        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reset"
        >{{ $t('operationCenter.reset') }}</el-button>
      </el-card>
    </div>

    <el-card class="box-card">
      <div class="action-table mb10">
        <el-row :gutter="10" type="flex" class="btnLeft">
          <el-button
            v-permit:remove="'btn:finance:costManagement:costApproval:Edit'"
            size="small"
            type="primary"
            :disabled="!selectData.length"
            @click="operationBtn"
          >{{ $t('receivablePayable.Verification') }}</el-button>

          <!-- 费用统计 -->
          <TableStatisticiansCmp :table-data="tableData" />
        </el-row>

        <div class="btnRight" />
      </div>

      <el-table
        ref="elTable"
        :key="tableKey"
        class="table-height"
        :data="tableData"
        :height="tableHeightRow.height"
        border
        row-key="idCopy"
        :tree-props="{ children: 'meetSiteBill', hasChildren: true }"
        @header-dragend="headerWidthChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
          :selectable="selectable"
        />
        <el-table-column
          v-for="item in TABLE_APPROVAL_HEADER"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :align="item.align"
          :min-width="item.width"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <!--账单批次号-->
            <span v-if="item.prop === 'payableNumber'">
              <span v-if="scope.row.isParent" class="batchNumber" @click="showBillBatchNumber(scope.row)">{{ scope.row.payableNumber }}</span>
              <span v-else>{{ scope.row.billBatchNumber }}</span>
            </span>
            <div v-else-if="item.prop === 'businessType'">
              <span>{{ BUSINESS_TYPE_TWO[scope.row.businessType].label }}</span>
            </div>
            <div v-else-if="item.prop === 'billFee'">
              <span v-if="scope.row.isParent">{{ scope.row.billFee }}</span>
              <span v-else>{{ scope.row.totalReceivable }}</span>
            </div>
            <div v-else-if="item.prop === 'createTime'">
              <span>{{ scope.row.createTime ? scope.row.createTime.replace('T', ' ') : '' }}</span>
            </div>
            <div v-else-if="item.prop === 'auditTime'">
              <span>{{ scope.row.auditTime ? scope.row.auditTime.replace('T', ' ') : '' }}</span>
            </div>

            <!-- 文件 -->
            <div v-else-if="item.prop === 'attachmentUrlArr'" style="display: flex;flex-wrap: wrap;">
              <el-link
                v-for="(key, index) of scope.row.attachmentUrlArr" :key="key" type="success"
                :href="key" target="_blank" style="margin-right: 10px;"
              >file{{ index + 1 }}</el-link>
            </div>

            <div v-else>{{ scope.row[item.prop] }}</div>
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column
          :label="$t('basicData.operate')"
          align="center"
          width="140"
          fixed="right"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>
              <el-button
                v-if="[0,2].includes(scope.row.payableStatus) && $store.getters.button_permissions.includes('btn:finance:costManagement:costApproval:revoke')"
                type="danger"
                size="mini"
                @click="recall(scope.row)"
              >{{ $t('receivablePayable.withdraw') }}</el-button> <!--撤回-->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getExamineCurrentPage"
          @size-change="getExamineSizePage"
        />
      </div>
    </el-card>

    <!--  对账批次详情  -->
    <el-drawer
      :title="$t('receivablePayable.ReconcileBatchDetails') + '-' + batchNumber"
      :append-to-body="true"
      :visible.sync="showDetail"
      direction="ltr"
      custom-class="drawerClass"
      :label="$t('receivablePayable.ReconcileBatchDetails')"
      size="80%"
    >
      <batch-number-detail
        :key="batchNumberKey"
        :batch-number="batchNumber"
        :bill-batch-data="billBatchData"
        :type="2"
        @closeDetail="closeDetail"
      />
    </el-drawer>

    <!--付款-->
    <el-dialog
      :title="$t('receivablePayable.Verification')"
      :visible.sync="showCollection"
      :custom-class="'dialogClass'"
      top="10vh"
      width="80%"
    >
      <collection-detail
        :key="collectionKey"
        :recharge-data="selectData[0]"
        :account-option="accountOption"
        @collectionUpdate="collectionUpdate"
      />
    </el-dialog>
  </div>
</template>

<script>
import { getSiteExpenseBillPage, getSiteCostWithdraw } from '@/api/finance/costManagement';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { apiProviderPage } from '@/api/finance/basicData';
import { accountPage } from '@/api/finance/partition';
import { cloneDeep } from 'lodash-es';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { BUSINESS_TYPE_TWO } from '@/views/finance/utils.js';
import { PAYABLE_STATUS } from '@/views/finance/costManagement/enums.js';
import { TABLE_APPROVAL_HEADER } from '@/views/finance/costManagement/enums.js';
// import QuerySiteCmp from '@/views/finance/cmp/Query/QuerySiteCmp.vue';

export default {
  name: 'CostApproval',

  components: {
    // QuerySiteCmp,
    BatchNumberDetail: () => import('../components/batchDetail.vue'),
    CollectionDetail: () => import('./cmp/collectionDetail.vue'),
    TableStatisticiansCmp: () => import('../components/tableStatisticiansCmp.vue')
  },

  mixins: [estimateTableHeight],
  data() {
    return {
      TABLE_APPROVAL_HEADER,
      BUSINESS_TYPE_TWO, // 业务类型
      PAYABLE_STATUS, // 付款状态
      queryForm: {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 10,
        params: {
          payableStatus: [0], // 付款状态
          providerId: '', // 业务主体
          // siteId: '', // 网点
          businessType: null
        }
      },
      typeNumber: 1,
      searchNum: '', // 多行输入框
      showDetail: false,
      tableData: [],
      total: 0,
      providerList: [],
      selectData: [],
      // 详情
      tableKey: new Date().getTime(),
      batchNumberKey: new Date().getTime(),
      batchNumber: '',
      billBatchData: {},
      showCollection: false,
      collectionKey: new Date().getTime(),
      accountOption: []
    };
  },
  computed: {
  },
  created() {
    this.createUser = getUserIdAndNameObj();
    this.getAccountOption();
    this.getProviderPage();
    this.getExaminePage();
  },
  mounted() {
    this.bus.$on('pendingData', () => {
      this.getExaminePage();
    });
  },
  methods: {
    // 业务主体选项变化
    providerPageChange() {
    //   this.queryForm.params.siteId = '';
    },
    // 子级禁止选择
    selectable(row) {
      return row.isParent;
    },
    // 更新页面
    collectionUpdate(type) {
      if (type === 2) {
        this.getExaminePage();
      }
      this.showCollection = false;
    },
    // 获取账户
    getAccountOption() {
      const param = {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 1000000,
        params: {
          status: 1
        }
      };
      accountPage(param, false).then(res => {
        if (res.code === 200) {
          this.accountOption = res.data.records;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 撤回
    recall(data) {
      const param = {
        payableNumbers: [data.payableNumber]
      };
      this.$confirm(this.$i18n.t('receivablePayable.AreYouSureYouWantToWithdraw'), this.$i18n.t('basicData.tips'), { // '确定要撤回?'
        confirmButtonText: this.$i18n.t('basicData.determine'), // '确定',
        cancelButtonText: this.$i18n.t('basicData.Cancel'), // '取消',
        type: 'warning'
      }).then(() => {
        getSiteCostWithdraw(param).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
            this.getExaminePage();
          }
        });
      });
    },
    // 表头宽度变化
    headerWidthChange() {
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
      });
    },
    // 关闭详情
    closeDetail() {
      this.showDetail = false;
    },
    // 获取主体
    getProviderPage() {
      const param = {
        pageSize: 9999999,
        pageNumber: 1,
        params: {
          state: 1
        }
      };
      apiProviderPage(param, false).then(res => {
        if (res.code === 200) {
          this.providerList = res.data.records;
        }
      });
    },
    // 获取列表数据
    getExaminePage() {
      const param = this.getQueryData();

      const randomString = (e, t) => {
        // e选取字符长度; t字符串
        e = e || 4;
        const a = t.length;
        let n = '';
        for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
        return n;
      };

      function repeat(payableNumber, random) {
        const randomStr = randomString(4, payableNumber);
        if (!random.includes(randomStr)) {
          random.push(randomStr);
          return randomStr;
        } else {
          repeat(payableNumber, random);
        }
      }

      function recurrence(table) {
        const randomArr = [];
        for (let i = 0; i < table.length; i++) {
          const randomId = repeat(table[i].payableNumber, randomArr);
          table[i]['idCopy'] = table[i].id + randomId;
          if (table[i].meetSiteBill && table[i].meetSiteBill.length > 0) {
            recurrence(table[i].meetSiteBill);
          }
        }
        return table;
      }

      getSiteExpenseBillPage(param).then(res => {
        if (res.code === 200) {
          res.data.records.map(item => {
            for (const key in item) {
              // 文件处理
              if (key === 'attachmentUrl') {
                if (item[key]) {
                  item['attachmentUrlArr'] = item[key].split(',');
                } else {
                  item['attachmentUrlArr'] = [];
                }
              }
            }
          });
          this.tableData = recurrence(res.data.records).map(item => {
            const { payableStatus } = item;
            delete item.requestPaymentStatus;

            const obj = {
              ...item,
              payableStatusStr: ''
            };
            obj.totalReceivable = obj.billFee;
            // 付款状态
            obj.payableStatusStr = PAYABLE_STATUS[payableStatus]?.label ?? '';

            return Object.freeze(obj);
          });
          this.total = res.data.total;
        }
      }).catch(err => {
        console.error(err);
      });
    },

    // 请求参数处理
    getQueryData() {
      const queryForm = cloneDeep(this.queryForm);
      const { params } = queryForm;

      const param = {
        orderDirection: 'DESC',
        pageNumber: queryForm.pageNumber,
        pageSize: queryForm.pageSize,
        params: {
          businessType: params.businessType, // 业务类型
          providerId: params.providerId, // 主体
          payableStatus: params.payableStatus // 付款状态
        }
      };

      // 付款单号 / 账单批次号
      if (this.typeNumber === 1) {
        param.params.payableNumber = this.searchNum;
      } else {
        param.params.billBatchNumber = this.searchNum;
      }
      return param;
    },

    // 列表数据勾选
    handleSelectionChange(arr) {
      this.selectData = arr;
    },
    // 获取对账批次详情
    showBillBatchNumber(data) {
      this.batchNumber = data.payableNumber;
      this.billBatchData = data;
      this.showDetail = true;
      this.batchNumberKey = new Date().getTime();
    },
    // 翻页
    getExamineCurrentPage(val) {
      this.queryForm.pageNumber = val;
      this.getExaminePage();
    },
    // 每页显示数量变化
    getExamineSizePage(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getExaminePage();
    },
    // 搜索
    searchResources() {
      this.queryForm.pageNumber = 1;
      this.getExaminePage();
    },
    // 重置
    reset() {
      this.queryForm.pageNumber = 1;
      this.queryForm.params = {
        payableStatus: [], // 付款状态
        providerId: '', // 业务主体
        // siteId: '', // 网点id
        businessType: null
      };
      this.searchNum = '';
      this.typeNumber = 1;
      this.getExaminePage();
    },
    // 操作
    operationBtn() {
      const len = this.selectData.length;
      if (len === 0) {
        this.$message.warning(this.$i18n.t('receivablePayable.SelectAtLeastOnePieceOfData')); // 最少要选择一条数据
        return;
      }
      if (len > 1) {
        this.$message.warning(this.$i18n.t('receivablePayable.OnlyOnePieceOfDataCanBeSelected')); // 最少要选择一条数据
        return;
      }
      this.collectionKey = new Date().getTime();
      this.showCollection = true;
    }

    // 导出
    // onExport() {
    //   const { selectData } = this;
    //   const { params } = this.getQueryData();
    //
    //   // 指定导出
    //   if (selectData.length > 0) {
    //     params.ids = selectData.map(item => item.id);
    //   }
    //
    //   apiSitePayableExport(params).then(res => {
    //     goResourceTaskList();
    //   }).then(res => {
    //   }).catch(err => {
    //     console.error(err);
    //   });
    // },
    //
  }
};
</script>

<style scoped lang="scss">
.batchNumber {
  color: #007efd;
  cursor: pointer;
}

.action-table {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;

  .btnLeft {
    flex-wrap: wrap;
    align-items: center;
  }

  .btnRight {
    display: flex;
    flex-wrap: nowrap;
  }
}
</style>
