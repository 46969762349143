<template>
  <div class="app-container">
    <el-card class="mb5 searchContainer">
      <el-form
        ref="queryForm"
        class="queryFormClass"
        :model="queryForm"
        label-position="top"
        size="mini"
      >
        <el-row :gutter="10">
          <el-col :span="6">
            <!--分区方案名称-->
            <el-form-item :label="$t('basicData.PartitionSchemeName')" prop="name">
              <el-input v-model="queryForm.params.name" :placeholder="$t('orderDetails.pleaseInput')" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--备注内容-->
            <el-form-item :label="$t('basicData.RemarkContent')" prop="remark">
              <el-input v-model="queryForm.params.remark" :placeholder="$t('orderDetails.pleaseInput')" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--业务类型-->
            <el-form-item :label="$t('collectionCenter.businessT')" prop="businessType">
              <el-select v-model="queryForm.params.businessType" :placeholder="$t('GlobalSetObj.pleaseChoose')" style="width: 100%">
                <!--全部-->
                <el-option key="0" :label="$t('collectionCenter.tudo')" :value="null" />
                <!--快递-->
                <el-option key="1" :label="$t('collectionCenter.expressDelivery')" :value="0" />
                <!--揽收-->
                <el-option key="2" :label="$t('collectionCenter.lanshou')" :value="1" />
                <!--派件-->
                <el-option key="3" :label="$t('collectionCenter.delivery')" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="left">
        <el-col :span="8">
          <div>
            <!--查询-->
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="getDataSearch"
            >{{ $t('basicData.Search') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh"
              size="mini"
              @click="queryFormReset"
            >{{ $t('basicData.reset') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <el-card>
      <div class="mb10">
        <!--添加-->
        <el-button
          v-permit:remove="'but:finance:basicData:partition:add'"
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="handlerOperation(1)"
        >{{ $t('basicData.Add') }}
        </el-button>
      </div>

      <el-table
        :data="tableData"
        border
        class="table-height"
        :height="tableHeightRow.height"
      >
        <!--序号-->
        <el-table-column
          :label="$t('basicData.serialNumber')" type="index" width="60"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!--分区方案名称-->
        <el-table-column :label="$t('basicData.PartitionSchemeName')" prop="name" width="160">
          <template slot-scope="scope">
            <div style="color: #409eff;cursor: pointer;" @click="handlerOperation(2, scope.row)">{{ scope.row.name }}</div>
          </template>
        </el-table-column>
        <!--分区状态-->
        <el-table-column :label="$t('basicData.PartitionStatus')" width="80">
          <template slot-scope="scope">
            <!--启用-->
            <el-tag v-if="scope.row.state === 1">{{ $t('basicData.enable') }}</el-tag>
            <!--禁用-->
            <el-tag v-else-if="scope.row.state === 0" type="warning">{{ $t('basicData.disable') }}</el-tag>
            <!--未定义-->
            <el-tag v-else type="info">{{ $t('basicData.Undefined') }}</el-tag>
          </template>
        </el-table-column>
        <!--业务类型-->
        <el-table-column :label="$t('collectionCenter.businessT')" width="100">
          <template slot-scope="scope">
            <!--快递-->
            <span v-if="scope.row.businessType === 0">{{ $t('collectionCenter.expressDelivery') }}</span>
            <!--揽收-->
            <span v-else-if="scope.row.businessType === 1">{{ $t('collectionCenter.lanshou') }}</span>
            <!--派件-->
            <span v-else-if="scope.row.businessType === 2">{{ $t('collectionCenter.delivery') }}</span>
          </template>
        </el-table-column>
        <!--备注内容-->
        <el-table-column :label="$t('basicData.RemarkContent')" prop="remark" />
        <!--创建人-->
        <el-table-column :label="$t('basicData.CreatePeople')" prop="createUserStr" width="80" />
        <!--创建时间-->
        <el-table-column :label="$t('basicData.CreationTime')" prop="createTime" width="160" />
        <!--最后修改人-->
        <el-table-column :label="$t('basicData.LastModifier')" prop="updateUserStr" width="95" />
        <!--最后修改时间-->
        <el-table-column :label="$t('basicData.LastModified')" prop="updateTime" width="160" />
        <!--操作-->
        <el-table-column :label="$t('basicData.operate')" fixed="right" width="160">
          <template slot-scope="scope">
            <div>
              <!--确定禁用吗？-->
              <el-popconfirm
                v-if="scope.row.state === 1"
                v-permit:remove="'but:finance:basicData:partition:edit'"
                :title="$t('basicData.AreYouSureDisabled')"
                @confirm="asyncPartitionEdit(scope.row, 0)"
              >
                <!--禁用-->
                <el-button
                  slot="reference" type="warning"
                  size="mini"
                >{{ $t('basicData.disable') }}</el-button>
              </el-popconfirm>
              <!--确定启用吗？-->
              <el-popconfirm
                v-if="scope.row.state === 0"
                v-permit:remove="'but:finance:basicData:partition:edit'"
                :title="$t('basicData.AreYouSureEnabled')"
                @confirm="asyncPartitionEdit(scope.row, 1)"
              >
                <!--启用-->
                <el-button
                  slot="reference" type="primary"
                  size="mini"
                >{{ $t('basicData.enable') }}</el-button>
              </el-popconfirm>
              <!--确定删除吗？-->
              <el-popconfirm
                v-permit:remove="'but:finance:basicData:partition:delete'"
                :title="$t('basicData.AreYouSureDelete')"
                @confirm="asyncPartitionEdit(scope.row, 2)"
              >
                <!--删除-->
                <el-button
                  slot="reference" class="ml" type="danger"
                  size="mini"
                >{{ $t('basicData.Delete') }}</el-button>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getData"
          @size-change="getData"
        />
      </div>
    </el-card>

    <!-- 分区报价 -->
    <el-drawer
      :visible.sync="drawerObj.visible"
      :with-header="false"
      direction="ltr"
      size="60%"
    >
      <details-cmp
        :key="editKey"
        :type-index="typeIndex"
        :edit-data="editData"
        :state-list="stateList"
        style="height: 100%;"
        @updatePage="updatePage"
      />
    </el-drawer>

  </div>
</template>

<script>
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { getUserName } from '@/utils/asyncTools';
import {
  apiPartitionPage,
  apiPartitionChangeState,
  apiPartitionDel
} from '@/api/finance/partition';
import { apiAreaPage } from '@/api/finance/basicData';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';

// 州请求参数
const stateQuery = {
  pageNumber: 1,
  pageSize: 9999999,
  params: {
    level: 1,
    parentId: ''
  }
};

export default {
  name: 'Partition',
  components: {
    'details-cmp': () => import('./cmp/DetailsCmp.vue')
  },

  mixins: [estimateTableHeight],

  data() {
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          name: '',
          remark: '',
          businessType: null
        }
      },
      tableData: [],
      total: 0,
      // 弹窗
      drawerObj: {
        visible: false
      },
      editData: {
        name: '',
        remark: '',
        id: '',
        tableData: []
      },
      typeIndex: 1,
      editKey: new Date().getTime(),
      userIdAndNameObj: null,
      stateList: [] // 所有州
    };
  },
  // activated() {
  //   if (this.$route.params.partitionName) {
  //     this.queryForm.params.name = this.$route.params.partitionName;
  //     this.getData();
  //     setTimeout(() => {
  //       this.handlerOperation(2, this.tableData[0]);
  //     }, 1500);
  //   }
  // },
  created() {
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.getData();

    // 获取州
    setTimeout(() => {
      this.asyncApiAreaPage();
    }, 500);
  },

  methods: {
    // 获取列表数据
    getData() {
      apiPartitionPage(this.queryForm).then(res => {
        const { code, data } = res;
        if (code !== 200) {
          return;
        }
        this.tableData = data.records.map(item => {
          const updateUserStr = getUserName(item.updateUser, this.userIdAndNameObj);
          const createUserStr = getUserName(item.createUser, this.userIdAndNameObj);
          return {
            ...item,
            updateUserStr,
            createUserStr
          };
        });
        this.total = data.total;
      }).catch(err => {
        console.error(err);
      });
    },
    // 搜索
    getDataSearch() {
      this.queryForm.pageNumber = 1;
      this.getData();
    },
    // 重置
    queryFormReset() {
      this.queryForm.params.name = '';
      this.queryForm.params.remark = '';
    },

    // 启用禁用 分区
    asyncPartitionEdit(row, type) {
      const { id } = row;
      if (type === 2) {
        apiPartitionDel(row.id).then(res => {
          const { code } = res;
          if (code !== 200) {
            return;
          }
          this.$message({
            message: this.$i18n.t('basicData.TheOperationSucceeded'), // '操作成功',
            type: 'success'
          });
          this.getData();
        });
      } else {
        const obj = {
          id,
          state: type
        };

        apiPartitionChangeState(obj).then(res => {
          const { code } = res;
          if (code !== 200) {
            return;
          }
          this.$message({
            message: this.$i18n.t('basicData.TheOperationSucceeded'), // '操作成功',
            type: 'success'
          });
          this.getData();
        }).catch(err => {
          console.error(err);
        });
      }
    },

    // 添加 编辑 查看
    handlerOperation(typeIndex, row) {
      this.typeIndex = typeIndex;
      if (row) {
        // 编辑
        this.editData = {
          name: row.name,
          remark: row.remark,
          id: row.id
        };
      } else {
        this.editData = {
          name: '',
          remark: '',
          id: ''
        };
      }
      this.drawerObj.visible = true;
      this.editKey = new Date().getTime();
    },

    // 关闭弹窗
    updatePage(isBool) {
      this.drawerObj.visible = false;
      isBool && this.getData();
    },

    // 获取所有州
    asyncApiAreaPage() {
      apiAreaPage(stateQuery).then(res => {
        const { records } = res.data;
        this.stateList = (records || []).map(item => {
          const { id, name, twoCode } = item;
          const obj = {
            label: twoCode,
            value: id,
            name
          };
          return Object.freeze(obj);
        });
      }).catch(err => {
        console.error(err);
      });
    }

  }
};
</script>

<style scoped>
.ml {
  margin-left: 10px;
}
</style>

