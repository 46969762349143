<template>
  <div>
    <div class="app-container" style="height: calc(100vh - 130px);overflow-y: auto;">
      <el-form
        ref="form"
        :model="queryForm"
        size="small"
        :label-position="$i18n.locale !== 'zh' ? 'top' : 'right'"
        :label-width="$i18n.locale !== 'zh' ? 'auto' : '160px'"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--应收主体-->
              <el-form-item :label="$t('basicData.businessPrincipal')">
                <QueryProviderCmp :val="queryForm.providerId" @change="val => queryForm.providerId = val" />
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--网点-->
              <el-form-item :label="$t('basicData.Outlets')">
                <QuerySiteCmp
                  :val="queryForm.siteId"
                  :provider-id="queryForm.providerId"
                  @change="val => queryForm.siteId = val"
                />
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--客户-->
              <el-form-item :label="$t('basicData.CustomerName')">
                <QueryCustomerCmp :val="queryForm.customerId" @change="val => queryForm.customerId = val" />
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--客户编号-->
              <el-form-item :label="$t('basicData.CustomerCode')">
                <QueryCustomerCodeCmp :val="queryForm.customerCodeId" @change="val => queryForm.customerCodeId = val" />
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <!--商户-->
            <el-form-item :label="$t('collectionCenter.CommercialOwner')">
              <el-input
                v-model="queryForm.merchantName"
                :placeholder="$t('GlobalSetObj.pleaseInput')"
                clearable
                style="width:100%"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-form-item>
                <template slot="label">
                  <el-select v-model="queryForm.timeField" :placeholder="$t('operationCenter.PleaseSelect')">
                    <!--业务时间-->
                    <el-option :label="$t('basicData.BusinessTime')" value="operate_time" />
                    <!-- 客户出仓时间 -->
                    <el-option :label="$t('newOrder.clientExitTime')" value="customer_send_time" />
                  </el-select>
                </template>
                <!--查询时间-->
                <el-date-picker
                  v-model="queryDate"
                  :default-time="['00:00:00', '23:59:59']"
                  :end-placeholder="$t('operationCenter.endDate')"
                  :picker-options="dateLimit"
                  :range-separator="$t('operationCenter.to')"
                  :start-placeholder="$t('operationCenter.startDate')"
                  style="width: 100%"
                  type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                />
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-form-item>
                <template slot="label">
                  <el-select v-model="queryForm.numberField" :placeholder="$t('operationCenter.PleaseSelect')">
                    <!--业务单号-->
                    <el-option key="1" :label="$t('receivablePayable.TicketNumber')" value="waybill_number" />
                    <!--客户单号-->
                    <el-option key="2" :label="$t('receivablePayable.CustomerTrackingNumber')" value="customer_number" />
                  </el-select>
                </template>
                <el-input
                  v-model="numberList"
                  :placeholder="$t('newOrder.PreciseSearch').replace('200', NUMBERLIST_MAX_LENGTH + '')"
                  :rows="5"
                  type="textarea"
                />
              </el-form-item>

            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--是否已支付-->
              <el-form-item :label="$t('receivablePayable.WhetherItHasBeenPaid')">
                <el-select
                  v-model="queryForm.isAccept"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  style="width: 100%;"
                >
                  <!--全部-->
                  <el-option key="0" :label="$t('basicData.all')" :value="null" />
                  <!--是-->
                  <el-option key="1" :label="$t('receivablePayable.Paid')" :value="true" />
                  <!--否-->
                  <el-option key="2" :label="$t('receivablePayable.NonPayment')" :value="false" />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--支付方式-->
              <el-form-item :label="$t('ClaimsManagement.PaymentMethod')">
                <el-select
                  v-model="queryForm.payType"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  style="width: 100%;"
                >
                  <!--全部-->
                  <el-option key="0" :label="$t('basicData.all')" :value="null" />
                  <!-- OnLine -->
                  <el-option key="1" :value="0" label="OnLine" />
                  <!-- OffLine -->
                  <el-option key="2" :value="1" label="OffLine" />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--是否生成对账单-->
              <el-form-item :label="$t('receivablePayable.WhetherToGenerateAStatement')">
                <el-select
                  v-model="queryForm.isGenBill"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  style="width: 100%;"
                >
                  <!--全部-->
                  <el-option key="0" :label="$t('basicData.all')" :value="null" />
                  <!--是 已生成-->
                  <el-option key="1" :label="$t('receivablePayable.Generated')" :value="true" />
                  <!--否 未生成-->
                  <el-option key="2" :label="$t('receivablePayable.NotGenerated')" :value="false" />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--业务类型-->
              <el-form-item :label="$t('collectionCenter.businessT')">
                <el-select
                  v-model="queryForm.businessType"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  style="width: 100%;"
                  @change="changeBusinessType(queryForm.businessType)"
                >
                  <!--全部-->
                  <el-option key="0" :label="$t('basicData.all')" :value="null" />
                  <!--揽收订单-->
                  <el-option :label="$t('collectionCenter.lanshou')" :value="1" />
                  <!--快递订单-->
                  <el-option :label="$t('collectionCenter.expressDelivery')" :value="0" />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--揽收方式-->
              <el-form-item :label="$t('collectionCenter.lanshouTypee')">
                <el-select
                  v-model="queryForm.collectType"
                  :disabled="queryForm.businessType !== 1"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  style="width: 100%;"
                >
                  <!--全部-->
                  <el-option key="0" :label="$t('basicData.all')" :value="null" />
                  <el-option
                    v-for="item in COLLECT_TYPE"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                  <!--上门揽收-->
                  <!--                  <el-option key="1" :label="$t('basicData.OrdinaryPickUp')" :value="0" />-->
                  <!--上门交件-->
                  <!--                  <el-option key="2" :label="$t('basicData.StockingAndPickup')" :value="1" />-->
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--揽收类型-->
              <el-form-item :label="$t('newOrder.CollectionType')">
                <el-select
                  v-model="queryForm.collectionCategory"
                  :disabled="queryForm.businessType !== 1"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  style="width: 100%;"
                  @change="changePush"
                >
                  <!--全部-->
                  <el-option key="0" :label="$t('basicData.all')" :value="null" />
                  <el-option
                    v-for="item in COLLECTION_CATEGORY_TYPE"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                  <!--普通揽收-->
                  <!--                  <el-option key="1" :label="$t('basicData.OrdinaryPickUp')" :value="0" />-->
                  <!--备货揽收-->
                  <!--                  <el-option key="2" :label="$t('basicData.StockingAndPickup')" :value="1" />-->
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--明细业务类型-->
              <el-form-item :label="$t('basicData.明细业务类型')">
                <el-select
                  v-model="queryForm.subBusinessType"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  style="width: 100%;"
                >
                  <!--全部-->
                  <el-option :label="$t('basicData.all')" :value="null" />
                  <!--揽收订单-->
                  <el-option :label="$t('basicData.CollectionSystemBusiness')" :value="1" />
                  <!--快递订单-->
                  <el-option :label="`${$t('basicData.CourierSystemBusiness')}`" :value="0" />
                  <!--派送-->
                  <el-option :label="`${$t('collectionCenter.dispatch')}`" :value="2" />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--货物类型-->
              <el-form-item :label="$t('placeOrder.cargoType')">
                <el-select
                  v-model="queryForm.cargoType"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  style="width: 100%;"
                >
                  <el-option
                    v-for="(item, ind) in CARGO_TYPE"
                    :key="ind"
                    :label="item.name"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col v-if="selectKey === 1" :span="24">
            <div class="grid-content bg-purple">
              <!--批次号-->
              <el-form-item :label="$t('basicData.BatchNo')" prop="billBatchNumber">
                <el-input v-model="queryForm.billBatchNumber" :readonly="isReadOnly" />
              </el-form-item>
            </div>
          </el-col>
          <el-col v-if="selectKey === 2" :span="24">
            <div class="grid-content bg-purple">
              <!--收款单号-->
              <el-form-item :label="$t('basicData.ReceiptNumber')" prop="receiptNumber">
                <el-input v-model="queryForm.receiptNumber" :readonly="isReadOnly" />
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--费用类型-->
              <el-form-item :label="$t('basicData.ExpenseType')" prop="feeName">
                <el-select
                  v-model="queryForm.feeCode"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  remote
                  style="width: 100%;"
                >
                  <el-option
                    v-for="item in feeTypeOption"
                    :key="item.id"
                    :label="item.displayName"
                    :value="item.code"
                  />
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--订单状态-->
              <el-form-item :label="$t('collectionCenter.OrderStatus')">
                <el-select
                  v-model="queryForm.status"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  style="width: 100%;"
                >
                  <el-option
                    v-for="(item, ind) in orderStatusList"
                    :key="ind"
                    :label="item.label || item.collectStatusName"
                    :value="item.code || item.collectStatus"
                  />
                </el-select>
              </el-form-item>
            </div>
          </el-col>

          <el-col :span="24">
            <!-- 经办人 -->
            <el-form-item :label="$t('basicData.Handler')">
              <AgentCmp :disabled="isJurisdiction" :val="queryForm.handlerId" @change="agentChange" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <!-- 收件人州 -->
            <el-form-item :label="$t('newOrder.RecipientState')">
              <el-input v-model.trim="queryForm.receiveState" :placeholder="$t('GlobalSetObj.pleaseInput')" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!-- 发件人州 -->
            <el-form-item :label="$t('newOrder.SenderState')">
              <el-input v-model.trim="queryForm.senderState" :placeholder="$t('GlobalSetObj.pleaseInput')" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div style="text-align: center;border-top: 1px solid #ccc;padding-top: 10px;">
      <!--查 询-->
      <el-button size="small" type="primary" @click="searchForm">{{ $t('basicData.Search') }}</el-button>
      <!--重 置-->
      <el-button size="small" type="success" @click="onReset">{{ $t('basicData.reset') }}</el-button>
      <!--取 消-->
      <el-button size="small" @click="onClose">{{ $t('basicData.Cancel') }}</el-button>
    </div>
  </div>
</template>

<script>
import { feePage } from '@/api/finance/partition';
import { getIsAuth } from '@/views/finance/utils.js';
import { getTextareaVal } from '@/views/finance/basicData/PriceInquiryLog/utils.js';
import { cloneDeep } from 'lodash-es';
import AgentCmp from './AgentCmp.vue';
import QueryProviderCmp from '@/views/finance/cmp/Query/QueryProviderCmp.vue';
import QuerySiteCmp from '@/views/finance/cmp/Query/QuerySiteCmp.vue';
import { CARGO_TYPE } from '@/views/finance/basicData/quotationManagement/cmp/utils';
import { NUMBERLIST_MAX_LENGTH } from './utils';
import { WAYBILL_STATUS, ORDER_TYPE_LIST, BH_ORDER_TYPE_LIST } from '@/views/finance/utils.js';
import { QUERY_PARAMS } from '@/views/finance/receivableManagementSite/aRGenerationNew/utils';
import { COLLECT_TYPE, COLLECTION_CATEGORY_TYPE } from '@/views/finance/enums.js';
import { beforeSearch } from '@/views/finance/payableManage/feesPayable/enums';

export default {
  name: '',

  components: {
    QuerySiteCmp,
    QueryProviderCmp,
    AgentCmp,
    QueryCustomerCmp: () => import('@/views/finance/cmp/Query/QueryCustomerCmp.vue'),
    QueryCustomerCodeCmp: () => import('@/views/finance/cmp/Query/QueryCustomerCodeCmp.vue')
  },

  props: {
    // 只读
    isReadOnly: {
      type: Boolean,
      default: false
    },
    // 条件类型 1: 批次号 2: 对账单
    selectKey: {
      type: Number,
      default: 1
    },
    // 批次号/对账单
    billBatchNumber: {
      type: String,
      default: ''
    },
    // 经办人id
    handlerId: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      WAYBILL_STATUS, // 快递状态
      ORDER_TYPE_LIST, // 揽收订单状态
      BH_ORDER_TYPE_LIST, // 备货揽收订单状态
      CARGO_TYPE, // 货物类型枚举值
      NUMBERLIST_MAX_LENGTH,
      COLLECT_TYPE, // 揽收方式枚举值
      COLLECTION_CATEGORY_TYPE, // 揽收类型枚举值
      feeTypeOption: [], // 费用类型选项
      queryDate: [], // 查询日期区间
      numberList: '', // 业务单号 或 客户单号
      queryForm: {
        ...QUERY_PARAMS,
        handlerId: this.handlerId
      },
      // 日期限制
      pickerMinDate: '',
      pickerRange: 15 * 24 * 60 * 60 * 1000 // 可选择日期范围 为15天
    };
  },

  computed: {
    // 是否存在 仅查看当前经办人 权限
    isJurisdiction() {
      return getIsAuth('btn:aRGeneration:onlyAgent');
    },

    // 订单状态
    orderStatusList() {
      const { businessType, collectionCategory } = this.queryForm;
      // 业务类型为揽收 且 揽收类别为备货揽收时，显示备货揽收订单状态
      if (businessType === 1 && collectionCategory === 1) {
        return this.BH_ORDER_TYPE_LIST;
      }
      if (businessType === 1 && collectionCategory === 0) {
        return this.ORDER_TYPE_LIST;
      }
      if (businessType === 0) {
        return this.WAYBILL_STATUS;
      }
      return [];
    },

    /**
     * 日期限制处理
     *    默认情况下限制15天
     *    如果输入了 业务单号 或 客户单号 或 批次号，则不限制时间
     */
    dateLimit() {
      const { numberList, pickerMinDate, pickerRange } = this;
      const { billBatchNumber, receiptNumber } = this.queryForm;

      // 默认限制15天
      return {
        // 设置不能选择的日期
        onPick: ({ minDate }) => {
          // 输入了内容
          if (numberList || billBatchNumber || receiptNumber) {
            // 取消限制
            this.pickerMinDate = null;
            return;
          }

          if (minDate && pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          // 输入了内容
          if (numberList || billBatchNumber || receiptNumber) {
            // 取消时间限制
            return false;
          }

          if (this.pickerMinDate) {
            return (time.getTime() > (pickerMinDate + pickerRange)) || (time.getTime() < (pickerMinDate - pickerRange));
          }
          return false;
        }
      };
    }
  },
  created() {
    // 根据selectKey的值，决定是赋值给billBatchNumber还是receiptNumber
    if (this.selectKey) {
      this.queryForm[this.selectKey === 1 ? 'billBatchNumber' : 'receiptNumber'] = this.billBatchNumber;
    }

    // 获取费用类型
    this.getExpenseType();
  },

  methods: {
    // 揽收类型change
    changePush() {
      this.queryForm.status = '';
      this.$forceUpdate();
    },
    // 业务类型变化
    changeBusinessType(data) {
      this.queryForm.status = '';
      if (data !== 1) {
        this.queryForm.collectionCategory = null;
      }
      this.$forceUpdate();
    },
    // 获取费用类型
    getExpenseType() {
      const param = {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 100000,
        params: {
          state: 1, // 状态（1启用，0禁用）
          attribute: 1 // 1 应收费用 2 应付费用
        }
      };
      feePage(param, false).then(res => {
        if (res.code === 200) {
          this.feeTypeOption = res.data.records;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 取消
    onClose() {
      this.$emit('close');
    },
    // 重置
    onReset() {
      this.numberList = '';
      this.queryDate = [];
      this.queryForm = {
        ...QUERY_PARAMS,
        handlerId: this.handlerId
      };
      if (!this.selectKey) return;
      this.queryForm[this.selectKey === 1 ? 'billBatchNumber' : 'receiptNumber'] = this.billBatchNumber;
    },
    // 搜索
    searchForm() {
      const params = cloneDeep(this.queryForm);

      if (this.queryDate && this.queryDate.length > 1) {
        const start = new Date(this.queryDate[0]).getTime();
        const end = new Date(this.queryDate[1]).getTime();
        if (start > end) {
          this.$message.warning(this.$i18n.t('receivablePayable.TheStartTimeCannotBeGreaterThanTheEndTime')); // 起始时间不能大于结束时间
          return;
        }
        params.startTime = this.queryDate[0];
        params.endTime = this.queryDate[1];
      } else {
        params.startTime = '';
        params.endTime = '';
      }
      // 字段截取
      params.numberList = getTextareaVal(this.numberList, NUMBERLIST_MAX_LENGTH);

      // 校验时间和单号二选一
      {
        const isBool = beforeSearch(params);
        if (!isBool) {
          this.$message.warning(this.$t('receivablePayable.BusinessTimeOrOrderNumberCannotBeEmpty'));
          return;
        }
      }

      this.$emit('search', params);
    },

    // 经办人
    agentChange(val) {
      this.queryForm.handlerId = val;
      // 清空客户
      this.queryForm.customerId = '';
    }
  }
};
</script>

<style scoped>
.tips {
  margin: 0;
}
</style>
