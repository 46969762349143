import $i18n from '../../../../lang/index';

export const headerList = [
  { id: 1, label: $i18n.t('GlobalSetObj.TimeOfOrder'), prop: 'orderCreateTime', cloWidth: 140, center: 'center' },
  { id: 3, label: $i18n.t('collectionCenter.CustomerName'), prop: 'customerName', cloWidth: 160, center: 'center' },
  { id: 6, label: $i18n.t('newOrder.orderNumber'), prop: 'customerNumber', cloWidth: 180, center: 'center' },
  { id: 7, label: $i18n.t('navbar.anjunOrderNumber'), prop: 'orderNumber', cloWidth: 180, center: 'center' },
  { id: 8, label: $i18n.t('navbar.totalNumber'), prop: 'totalNumber', cloWidth: 180, center: 'center' },
  { id: 9, label: $i18n.t('navbar.cargoOrderStatus'), prop: 'waybillStatusName', cloWidth: 160, center: 'center' },
  { id: 9, label: $i18n.t('home.PaymentCertificate'), prop: 'paymentVoucher', cloWidth: 160, center: 'center' },
  {
    id: 10,
    label: $i18n.t('navbar.totalCreateOrderWeight'),
    prop: 'totalCreateOrderWeight',
    cloWidth: 160,
    center: 'center'
  },
  {
    id: 11,
    label: $i18n.t('navbar.totalParcelDeclaredVolume'),
    prop: 'totalParcelDeclaredVolume',
    cloWidth: 160,
    center: 'center'
  },
  { id: 12, label: $i18n.t('navbar.transportWeight'), prop: 'transportWeight', cloWidth: 160, center: 'center' },
  { id: 13, label: $i18n.t('navbar.transportVolume'), prop: 'transportVolume', cloWidth: 160, center: 'center' }
];
export const waybillStatusList = [
  // { label: $i18n.t('newOrder.all'), code: 0 }, // 待分配
  // { label: $i18n.t('newOrder.ToBeAssigned'), code: 1 }, // 待分配
  { label: $i18n.t('newOrder.ToBeCollected'), code: 2 }, // 待揽收
  { label: $i18n.t('newOrder.Collected'), code: 3 }, // 已揽收
  // { label: $i18n.t('newOrder.CollectTheRevenuePool'), code: 4 }, // 揽收入库
  // { label: $i18n.t('newOrder.CollectTakeOutWarehouse'), code: 5 }, // 揽收出库
  // { label: $i18n.t('newOrder.Collected'), code: 19 }, // 末端已揽收   ===>   已揽收
  { label: $i18n.t('newOrder.TransshipmentIntoStorage'), code: 6 }, // 转运入库
  { label: $i18n.t('newOrder.TransferOutWarehouse'), code: 7 }, // 转运出库
  { label: $i18n.t('newOrder.DeliveryOutletsIntoStorage'), code: 8 }, // 派送网点入库
  { label: $i18n.t('newOrder.Delivering'), code: 9 }, // 派送中
  { label: $i18n.t('newOrder.Signed'), code: 10 }, // 已签收
  { label: $i18n.t('newOrder.ProblemPiece'), code: 11 }, // 问题件
  { label: $i18n.t('GlobalSetObj.Canceled'), code: 13 }, // 已取消
  // // { label: $i18n.t('GlobalSetObj.deliveryFailure'), code: 14 }, // 投递失败
  { label: $i18n.t('GlobalSetObj.DotBack'), code: 17 }, // 网点退回
  { label: $i18n.t('GlobalSetObj.ReturnToStorage'), code: 18 }, // 退仓入库
  { label: $i18n.t('GlobalSetObj.Withdrawn'), code: 15 }, // 退件中
  { label: $i18n.t('GlobalSetObj.TheReturnComplete'), code: 16 }, // 退件完成
  { label: $i18n.t('GlobalSetObj.mutilSign'), code: 20 }, // 多次签收
  { label: $i18n.t('GlobalSetObj.financialProcessing'), code: 21 }, // 财务处理中
  { label: $i18n.t('GlobalSetObj.financialClosedLoop'), code: 22 } // 财务闭环
];

export const waybillStatusObj = {
  // 1: { label: $i18n.t('newOrder.ToBeAssigned'), code: 1 }, // 待分配
  2: { label: $i18n.t('newOrder.ToBeCollected'), code: 2 }, // 待揽收
  3: { label: $i18n.t('newOrder.Collected'), code: 3 }, // 已揽收
  // 4: { label: $i18n.t('newOrder.CollectTheRevenuePool'), code: 4 }, // 揽收入库
  // 5: { label: $i18n.t('newOrder.CollectTakeOutWarehouse'), code: 5 }, // 揽收出库
  19: { label: $i18n.t('newOrder.Collected'), code: 19 }, // 末端已揽收   ===>  已揽收
  6: { label: $i18n.t('newOrder.TransshipmentIntoStorage'), code: 6 }, // 转运入库
  7: { label: $i18n.t('newOrder.TransferOutWarehouse'), code: 7 }, // 转运出库
  8: { label: $i18n.t('newOrder.DeliveryOutletsIntoStorage'), code: 8 }, // 派送网点入库
  9: { label: $i18n.t('newOrder.Delivering'), code: 9 }, // 派送中
  10: { label: $i18n.t('newOrder.Signed'), code: 10 }, // 已签收
  11: { label: $i18n.t('newOrder.ProblemPiece'), code: 11 }, // 问题件
  12: { label: $i18n.t('collectionCenter.unclaimed'), code: 12 }, // 待取件
  13: { label: $i18n.t('GlobalSetObj.Canceled'), code: 13 }, // 已取消
  17: { label: $i18n.t('GlobalSetObj.DotBack'), code: 17 }, // 网点退回
  18: { label: $i18n.t('GlobalSetObj.ReturnToStorage'), code: 18 }, // 退仓入库
  15: { label: $i18n.t('GlobalSetObj.Withdrawn'), code: 15 }, // 退件中
  16: { label: $i18n.t('GlobalSetObj.TheReturnComplete'), code: 16 }, // 退件完成
  20: { label: $i18n.t('GlobalSetObj.mutilSign'), code: 20 }, // 多次签收
  21: { label: $i18n.t('GlobalSetObj.financialProcessing'), code: 21 }, // 财务处理中
  22: { label: $i18n.t('GlobalSetObj.financialClosedLoop'), code: 22 }, // 财务闭环
  200: { label: '', code: 200 } // 问题件
};
