<template>
  <div class="detailContainer">
    <el-timeline>
      <el-timeline-item
        v-for="(item, index) in tableData" :key="index" :timestamp="item.createTime"
        placement="top"
      >
        <el-card>
          <el-form :model="item" class="operationData">
            <!--操作ip-->
            <el-form-item
              prop="ip"
              :label="$t('basicData.OperationalIP') + '：'"
            >
              <div>{{ item.ip }}</div>
            </el-form-item>
            <!--操作人姓名-->
            <el-form-item
              prop="operatorName"
              :label="$t('basicData.OperatorName') + '：'"
            >
              <div>{{ item.operatorName }}</div>
            </el-form-item>
            <!--操作时间-->
            <el-form-item
              prop="createTime"
              :label="$t('basicData.OperationTime') + '：'"
            >
              <div>{{ item.createTime }}</div>
            </el-form-item>
            <!--操作内容-->
            <el-form-item
              prop="operationContent"
              :label="$t('basicData.ActionContent') + '：'"
            >
              <div v-for="(list, ind) in item.operationContent.split('\n')" :key="ind">
                {{ list }}
                <!--下载报价-->
                <span
                  v-if="item.extra && ind === (item.operationContent.split('\n').length - 1)"
                  style="color: #409eff;cursor: pointer;"
                  @click="downloadQuote(item.extra)"
                >{{ $t('basicData.DownloadTheQuote') }}</span>
              </div>
            </el-form-item>
          </el-form>
        </el-card>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { apiOfferPriceOperateLog } from '@/api/finance/basicData';
import { feePage } from '@/api/finance/partition';
import { saveAs } from 'file-saver';
const ExcelJs = require('exceljs');

export default {
  name: 'OperationLog',
  props: {
    editData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      tableData: [],
      createUser: [],
      expenseTypeList: []
    };
  },
  created() {
    this.createUser = getUserIdAndNameObj();
    this.getLogTable();
    this.getExpenseTypeList();
  },
  methods: {
    // 获取费用类型
    getExpenseTypeList() {
      const param = {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 100000,
        params: {
          state: 1
        }
      };
      feePage(param, false).then(res => {
        if (res.code === 200) {
          this.expenseTypeList = res.data.records;
        }
      });
    },
    // 下载报价
    downloadQuote(data) {
      const dataArr = JSON.parse(data);
      const that = this;
      async function ddd() {
        const wb = new ExcelJs.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1'); // 揽收费
        const Sheet1_data = [];
        Sheet1.columns = [
          {
            header: that.$t('basicData.TypeOfCourier'), // 分区名称
            key: 'expressTypeName',
            width: 20
          },
          {
            header: that.$t('basicData.PartitionName'), // 分区名称
            key: 'partitionIdentifier',
            width: 20
          },
          {
            header: that.$t('basicData.ExpenseType'), // 费用类型
            key: 'feeTypeCode',
            width: 20
          },
          {
            header: that.$t('basicData.chargeType'), // 计费类型
            key: 'billingType',
            width: 20
          },
          {
            header: that.$t('basicData.Begin'), // 开始
            key: 'startWeight',
            width: 30
          },
          {
            header: that.$t('basicData.end'), // 结束
            key: 'endWeight',
            width: 30
          },
          {
            header: that.$t('basicData.Price'), // 价格
            key: 'price',
            width: 30
          },
          {
            header: that.$t('basicData.ExceedingThePrice'), // 超出价格
            key: 'overWeightExtraPrice',
            width: 30
          }
        ];
        dataArr.map((item) => {
          that.expenseTypeList.map(list => {
            if (item.feeTypeCode === list.code) {
              item.feeTypeCode = list.displayName;
            }
          });
          Sheet1_data.push({
            expressTypeName: item.expressTypeName,
            partitionIdentifier: item.partitionIdentifier,
            expressType: item.expressType === 1 ? that.$t('basicData.express') : that.$t('basicData.OrdinaryCourier'),
            feeTypeCode: item.feeTypeCode,
            billingType: item.billingType === 1 ? that.$t('newOperationCenter.Weight') : that.$t('collectionCenter.ballot'),
            startWeight: item.startWeight,
            endWeight: item.endWeight,
            price: item.price,
            overWeightExtraPrice: item.overWeightExtraPrice
          });
        });
        Sheet1.addRows(Sheet1_data);
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const saveName = that.$t('basicData.QuoteDetails') + '.xlsx'; // 报价详情
        saveAs(blob, saveName);
      }
      ddd();
    },
    // 获取操作日志
    getLogTable() {
      const dateData = (property, bol) => {
        return function(a, b) {
          const value1 = a[property];
          const value2 = b[property];
          if (bol) {
            // 升序
            return Date.parse(value1) - Date.parse(value2);
          } else {
            // 降序
            return Date.parse(value2) - Date.parse(value1);
          }
        };
      };
      apiOfferPriceOperateLog(this.editData.id).then(res => {
        if (res.code === 200) {
          res.data.map(item => {
            item.createTime = item.createTime.replace(/T|Z/g, ' ');
          });
          res.data.sort(dateData('createTime', false));
          this.tableData = res.data;
        }
      });
    }
  }
};
</script>

<style scoped>
.detailContainer {
  height: calc(100vh - 40px);
  overflow-y: auto;
  padding: 20px;
}
.el-timeline{
  padding: 0;
}
.el-form-item{
  margin-bottom: 0;
}
.operationData .el-form-item__label,
.operationData .el-form-item__content{
  line-height: 26px!important;
}
pre {
  white-space: pre-wrap;      /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /*Opera 7 */
  word-wrap:break-word;       /* Internet Explorer 5.5+ */
  margin: 0;
  font-family: inherit;
  max-width: 600px;
}
</style>
