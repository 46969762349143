import i18n from '@/lang';

// zh-CN 应收核收表头配置信息
export const TABLE_HEADER = [
  // 收款账单
  { prop: 'receiptBillName', label: i18n.t('receivablePayable.IncomingBills'), width: 160, align: 'left', highlight: false, disabled: false },
  // 账单日期
  { prop: 'billTime', label: i18n.t('receivablePayable.BillingDate'), width: 180, align: 'left', highlight: false, disabled: false },
  // 收款状态
  { prop: 'receiptStatus', label: i18n.t('receivablePayable.ReceiptStatus'), width: 160, align: 'left', highlight: false, disabled: false },
  // 客户
  { prop: 'customerName', label: i18n.t('GlobalSetObj.client'), width: 160, align: 'left', highlight: false, disabled: false },
  // 网点名称
  { prop: 'siteName', label: i18n.t('basicData.siteName'), width: 160, align: 'left', highlight: false, disabled: false },
  // 订单数量
  { prop: 'orderQty', label: i18n.t('receivablePayable.TheNumberOfOrders'), width: 160, align: 'left', highlight: true, disabled: false },
  // 费用条数
  { prop: 'feeQty', label: i18n.t('receivablePayable.TheNumberOfFees'), width: 160, align: 'left', highlight: false, disabled: false },
  // 计费总重量（kg）
  { prop: 'chargeWeight', label: i18n.t('receivablePayable.TotalBillableWeight'), width: 160, align: 'left', highlight: false, disabled: false },
  // 票单金额
  { prop: 'billFee', label: i18n.t('receivablePayable.TicketAmount'), width: 160, align: 'left', highlight: false, disabled: false },
  // 实际收款金额
  { prop: 'actualFee', label: i18n.t('receivablePayable.TheActualAmountReceived'), width: 160, align: 'left', highlight: false, disabled: false },
  // 核收人
  { prop: 'acceptUserName', label: i18n.t('receivablePayable.payee'), width: 160, align: 'left', highlight: false, disabled: false },
  // 核收时间
  { prop: 'acceptOperateTime', label: i18n.t('receivablePayable.CollectionTime'), width: 160, align: 'left', highlight: false, disabled: false },
  // 交易时间
  { prop: 'acceptTime', label: i18n.t('basicData.TradingHours'), width: 160, align: 'left', highlight: false, disabled: false },
  // 生成人员
  { prop: 'createUserName', label: i18n.t('receivablePayable.BuildThePerson'), width: 160, align: 'left', highlight: false, disabled: false },
  // 生成时间
  { prop: 'createTime', label: i18n.t('receivablePayable.BuildTime'), width: 160, align: 'left', highlight: false, disabled: false },
  // 审核人员
  { prop: 'auditUserName', label: i18n.t('receivablePayable.Auditors'), width: 160, align: 'left', highlight: false, disabled: false },
  // 审核时间
  { prop: 'auditTimeString', label: i18n.t('receivablePayable.ReviewTime'), width: 160, align: 'left', highlight: false, disabled: false },
  // 审核备注
  { prop: 'auditRemark', label: i18n.t('basicData.审核备注'), width: 160, align: 'left', highlight: false, disabled: false },
  // 备注
  { prop: 'remark', label: i18n.t('receivablePayable.remark'), width: 160, align: 'left', highlight: false, disabled: false }
];

// 应收核收详情表头配置信息
export const DETAIL_TABLE_HEADER = [
  // 业务主体
  { prop: 'providerName', label: i18n.t('basicData.BusinessPrincipal'), width: 120, fixed: 'left', highlight: false, disabled: false },
  // 网点
  { prop: 'siteName', label: i18n.t('basicData.Outlets'), width: 120, fixed: 'left', highlight: false, disabled: false },
  // 所属分区
  { prop: 'partitionName', label: i18n.t('basicData.PartitionName'), width: 160, fixed: 'left', highlight: false, disabled: false },
  // 收件人城市
  { prop: 'receiveCity', label: i18n.t('collectionCenter.receiveCity'), width: 160, fixed: 'left', highlight: false, disabled: false },
  // 收件人邮编
  { prop: 'receiveZipcode', label: i18n.t('collectionCenter.Recipientzc'), width: 160, fixed: 'left', highlight: false, disabled: false },
  // 业务单号
  { prop: 'waybillNumber', label: i18n.t('receivablePayable.TicketNumber'), width: 160, fixed: 'left', highlight: false, disabled: false },
  // 客户单号
  { prop: 'customerNumber', label: i18n.t('receivablePayable.CustomerTrackingNumber'), width: 160, fixed: 'left', highlight: false, disabled: false },
  // 收件人州
  { prop: 'receiveState', label: i18n.t('newOrder.RecipientState'), width: '', fixed: 'left', highlight: false, disabled: false },
  // 发件人州
  { prop: 'senderState', label: i18n.t('newOrder.SenderState'), width: '', fixed: 'left', highlight: false, disabled: false },
  // 订单状态
  { prop: 'status', label: i18n.t('collectionCenter.OrderStatus'), width: '', fixed: 'left', highlight: false, disabled: false },
  // 客户名称
  { prop: 'customerAlias', label: i18n.t('basicData.CustomerName'), width: 160, fixed: 'left', highlight: false, disabled: false },
  // 客户编号
  { prop: 'customerId', label: i18n.t('basicData.CustomerCode'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 计费重量
  { prop: 'chargeWeight', label: i18n.t('receivablePayable.BillableWeight'), width: 120, fixed: 'left', highlight: true, disabled: false },
  // 应收费用
  { prop: 'totalFee', label: i18n.t('basicData.FeesApply'), width: 120, fixed: 'left', highlight: true, disabled: false },
  // 业务类型
  { prop: 'businessType', label: i18n.t('collectionCenter.businessT'), width: 110, fixed: 'left', highlight: false, disabled: false },
  // 货物类型
  { prop: 'cargoType', label: i18n.t('placeOrder.cargoType'), width: 110, fixed: 'left', highlight: false, disabled: false },
  // 揽收方式
  { prop: 'collectType', label: i18n.t('collectionCenter.lanshouTypee'), width: 110, fixed: 'left', highlight: false, disabled: false },
  // 备注
  { prop: 'remark', label: i18n.t('basicData.remark'), width: 160, fixed: 'left', highlight: false, disabled: false }
];
