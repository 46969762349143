import i18n from '@/lang';
import { omit, cloneDeep, isEmpty } from 'lodash-es';
import { Message } from 'element-ui';

// 搜索字段
export const SEARCH_PARAMS = {
  providerId: '', // 应付主体id
  siteId: '', // 网点id
  customerId: '', // 客户id
  customerCodeId: '', // 客户编码id
  startTime: '', // 业务开始时间
  endTime: '', // 业务结束时间
  timeField: 'operate_time', // 查询时间类型 入库时间:input_time 出库时间:out_time 业务时间:operate_time
  numberField: 'waybill_number', // waybill_number业务单号 customer_number客户单号 order_number条形码（揽收单号/订单号）
  numberList: [], // 单号
  collectorIdList: [], // 揽收人
  dispatcherList: [], // 派送人
  billBatchNumber: '', // 批次号
  payableNumber: '', // 收款单号
  isGenBill: null, // 是否生成对账单
  isPaid: null, // 是否已支付
  feeCode: '', // 费用编码
  feeName: '', // 费用类型
  status: '', // 订单状态
  senderState: '', // 发件人州
  businessType: '', // 业务类型 2 揽收 3 派送
  collectType: null, // 揽收方式 2上门揽收 1上门交件
  payableType: '', // 账单类型  1安骏小屋，2第三方揽收，3AJD派送, 4DSP派送, 5运输干支线
  siteType: '',
  operationMode: '', // 派送模式 0:AJD(自营) 1:DSP(第三方)
  receiveState: '', // 收件人州
  loadUserId: '', // 装车人
  tagNumber: '', // 车签号
  vehicleNumber: '', // 车牌号
  merchantCode: '', // 商户编码
  merchantId: '', // 商户ID
  vehicleType: '', // 车型
  startingSite: '', // 始发网点
  destinationSite: '', // 目的网点
  orderBusinessType: '', // 订单业务类型
  routeType: '' // 线路类型
};

// 搜索
export const queryStore = {
  pageNumber: 1,
  pageSize: 100,
  params: {
    ...SEARCH_PARAMS
  }
};

/**
   * 搜索前的条件判断
   * @param {*} params 搜索参数
   * @returns {boolean} 可以返回 true
   */
export const beforeSearch = (params) => {
  // 单号不为空
  {
    const { numberList, payableNumber, billBatchNumber } = params;
    if (numberList && numberList.length > 0 || payableNumber || billBatchNumber) {
      return true;
    }
  }

  // 业务时间不可为空
  {
    const { startTime, endTime } = params;
    if (startTime && endTime) {
      return true;
    }
  }
  return false;
};

/**
 * 搜索为空提示
 * @param {*} params
 * @returns {boolean} 为空返回 true
 */
export const searchEmpty = (params) => {
  const isBool = beforeSearch(params);
  if (!isBool) {
    Message.warning(i18n.t('receivablePayable.businessTimeOrOrderNumberCannotBeEmpty'));
    return true;
  }
  return false;
};

/**
 * 所有搜索条件是否都为空
 * @param {*} obj
 * @returns {boolean}
 */
const whitelist = ['numberField', 'timeField'];

export const isSearchAllEmpty = (obj) => {
  const newObj = cloneDeep(obj);
  // 删除白名单字段
  const newObj2 = omit(newObj, whitelist);

  // 空字符串 空数组 null undefined
  return Object.values(newObj2).every(item => {
    return isEmpty(item);
  });
};

// 揽收业务搜索 单号 搜索项
export const NUMBER_FIELD = {
  'waybill_number': {
    value: 'waybill_number', // 业务单号
    label: i18n.t('receivablePayable.TicketNumber')
  },
  'order_number': {
    value: 'order_number', // 揽收单号
    label: i18n.t('receivablePayable.CollectionTrackingNumber')
  }
};

// 派送业务搜索 单号 搜索项
export const DELIVERY = {
  'waybill_number': {
    value: 'waybill_number', // 业务单号
    label: i18n.t('receivablePayable.TicketNumber')
  },
  'order_number': {
    value: 'order_number', // 订单号
    label: i18n.t('CenterForSorting.orderNumber')
  },
  'customer_number': {
    value: 'customer_number', // 客户单号
    label: i18n.t('collectionCenter.customerNum')
  }
};

//  账单类型 1安骏小屋，2第三方揽收，3AJD派送, 4DSP派送, 5运输干支线
export const PAYABLE_TYPE = {
  1: {
    value: 1,
    label: i18n.t('receivablePayable.AnjunCottage')
  },
  2: {
    value: 2,
    label: i18n.t('receivablePayable.ThirdPartyCollection')
  },
  3: {
    value: 3,
    label: i18n.t('receivablePayable.AJDDispatch')
  },
  4: {
    value: 4,
    label: i18n.t('receivablePayable.DSPDispatch')
  },
  5: {
    value: 5,
    label: i18n.t('receivablePayable.Transportation_trunk_branch')
  }
};

// 数据来源 0:系统生成 1:手工添加 3:快录生成
export const FEE_SOURCE = Object.freeze({
  0: {
    name: i18n.t('receivablePayable.SystemGenerated'), // 系统生成
    value: 0
  },
  1: {
    name: i18n.t('receivablePayable.ManualAddition'), // 手工添加
    value: 1
  },
  3: {
    name: i18n.t('receivablePayable.QuickRecordGeneration'), // 快录生成
    value: 3
  },
  default: {
    name: i18n.t('basicData.other'), // 其他
    value: ''
  }
});

// 派送table字段
export const P_TABLE_HEADER = [
  // 业务单号
  { prop: 'waybillNumber', label: i18n.t('receivablePayable.TicketNumber'), width: 180, fixed: 'left' },
  // 订单号
  { prop: 'orderNumber', label: i18n.t('collectionCenter.orderNumber'), width: 180, fixed: 'left' },
  // 客户单号
  { prop: 'customerNumber', label: i18n.t('receivablePayable.customerNumber'), width: 180, fixed: 'left' },
  // 应付主体
  { prop: 'providerName', label: i18n.t('basicData.PayablePrincipal'), width: 180, fixed: 'left' },
  // 应付网点
  { prop: 'siteName', label: i18n.t('basicData.PayableOutlets'), width: 180, fixed: 'left' },
  // 客户名称
  { prop: 'customerAlias', label: i18n.t('basicData.CustomerName'), width: 180, fixed: 'left' },
  // 客户编号
  { prop: 'customerId', label: i18n.t('basicData.CustomerCode'), width: 180, fixed: 'left' },
  // 订单状态
  { prop: 'status', label: i18n.t('orderCenterCont.OrderStatus'), width: 160, fixed: 'left' },
  // 派送操作人
  { prop: 'dispatcherName', label: i18n.t('basicData.派送操作人'), width: 160, fixed: 'left' },
  // 收件人州
  { prop: 'receiveState', label: i18n.t('newOrder.RecipientState'), fixed: 'left' },
  // 备注
  { prop: 'remark', label: i18n.t('basicData.remark'), width: 200, fixed: 'left' },
  // 计费重量
  { prop: 'chargeWeight', label: i18n.t('receivablePayable.BillableWeight'), width: 160, fixed: 'left' },
  // 应付总费用
  { prop: 'totalFee', label: i18n.t('basicData.TotalFeesPayable'), width: 160, fixed: 'left' },
  // 分区名称
  { prop: 'partitionName', label: i18n.t('collectionCenter.partitionName'), width: 160, fixed: 'left' },
  // 收件人城市
  { prop: 'receiveCity', label: i18n.t('newOrder.RecipientCity'), width: 160, fixed: 'left' },
  // 收件人邮编
  { prop: 'receiveZipcode', label: i18n.t('receivablePayable.RecipientzipCode'), width: 160, fixed: 'left' },
  // 来源
  { prop: 'feeSource', label: i18n.t('receivablePayable.source'), width: 160, fixed: 'left' },
  // 结算类型
  { prop: 'settlementType', label: i18n.t('receivablePayable.settlementType'), width: 160, fixed: 'left' },
  // 派送车辆类型
  { prop: 'deliveryVehicleType', label: i18n.t('receivablePayable.deliveryVehicleType'), width: 280, fixed: 'left' },
  // 账号类型
  { prop: 'customerType', label: i18n.t('receivablePayable.accountType'), width: 160, fixed: 'left' }
];

// 揽收table字段
export const L_TABLE_HEADER = [
  // 业务单号
  { prop: 'waybillNumber', label: i18n.t('receivablePayable.TicketNumber'), width: 180, fixed: 'left' },
  // 揽收单号
  { prop: 'orderNumber', label: i18n.t('receivablePayable.CollectionTrackingNumber'), width: 180, fixed: 'left' },
  // 应付主体
  { prop: 'providerName', label: i18n.t('basicData.PayablePrincipal'), width: 180, fixed: 'left' },
  // 应付网点
  { prop: 'siteName', label: i18n.t('basicData.PayableOutlets'), width: 180, fixed: 'left' },
  // 客户名称
  { prop: 'customerAlias', label: i18n.t('basicData.CustomerName'), width: 180, fixed: 'left' },
  // 客户编号
  { prop: 'customerId', label: i18n.t('basicData.CustomerCode'), width: 180, fixed: 'left' },
  // 订单状态
  { prop: 'status', label: i18n.t('orderCenterCont.OrderStatus'), width: 160, fixed: 'left' },
  // 揽收方式
  { prop: 'collectType', label: i18n.t('collectionCenter.lanshouTypee'), width: 180, fixed: 'left' },
  // 揽收人
  { prop: 'collectorsName', label: i18n.t('basicData.揽收操作人'), width: 180, fixed: 'left' },
  // 发件人州
  { prop: 'senderState', label: i18n.t('newOrder.SenderState'), fixed: 'left' },
  // 来源
  { prop: 'feeSource', label: i18n.t('receivablePayable.source'), width: 110, fixed: 'left' },
  // 备注
  { prop: 'remark', label: i18n.t('basicData.remark'), width: 180, fixed: 'left' },
  // 计费重量
  { prop: 'chargeWeight', label: i18n.t('receivablePayable.BillableWeight'), width: 160, fixed: 'left' },
  // 应付总费用
  { prop: 'totalFee', label: i18n.t('basicData.TotalFeesPayable'), width: 160, fixed: 'left' },
  // 车签号
  { prop: 'tagNumber', label: i18n.t('GlobalSetObj.NIV'), width: 160, fixed: 'left' },
  // 车牌号
  { prop: 'vehicleNumber', label: i18n.t('GlobalSetObj.licensePlateNumber'), width: 160, fixed: 'left' },
  // 商家编码
  { prop: 'merchantCode', label: `${i18n.t('collectionCenter.MerchantNum')}(${i18n.t('GlobalSetObj.businessName')})`, width: 160, fixed: 'left' }
];

// 运输干支线表头
export const TF_TABLE_HEADER = [
  // 运单号
  { prop: 'waybillNumber', label: i18n.t('basicData.WaybillNumber'), width: 180, fixed: 'left' },
  // 应付主体
  { prop: 'providerName', label: i18n.t('basicData.PayablePrincipal'), width: 180, fixed: 'left' },
  // 应付网点
  { prop: 'siteName', label: i18n.t('basicData.PayableOutlets'), width: 180, fixed: 'left' },
  // 路线类型
  { prop: 'routeType', label: i18n.t('basicData.typeOfRoute'), width: 140, align: 'left' },
  // 始发网点
  { prop: 'startingSiteName', label: i18n.t('GlobalSetObj.StartingStation'), width: 180, fixed: 'left' },
  // 目的网点
  { prop: 'destinationSiteName', label: i18n.t('GlobalSetObj.DestinationStation'), width: 180, fixed: 'left' },
  // 装车人
  { prop: 'loadUserName', label: i18n.t('GlobalSetObj.Carregador'), width: 180, fixed: 'left' },
  // 公里数（KM）
  { prop: 'kilometers', label: i18n.t('GlobalSetObj.Kilometers') + '(KM)', width: 160, fixed: 'left' },
  // 车型
  { prop: 'vehicleType', label: i18n.t('vehicle.modelLabel'), width: 160, fixed: 'left' },
  // 车签号
  { prop: 'tagNumber', label: i18n.t('GlobalSetObj.NIV'), width: 200, fixed: 'left' },
  // 计费重量
  { prop: 'chargeWeight', label: i18n.t('receivablePayable.BillableWeight'), width: 160, fixed: 'left' },
  // 应付总费用
  { prop: 'totalFee', label: i18n.t('basicData.TotalFeesPayable'), width: 160, fixed: 'left' }
];

// 快录
export const QUERY_FEE_PARAM = {
  id: null,
  waybillNumber: '', // 业务单号
  businessType: '', // 业务类型
  customerId: '', // 客户id
  customerAlias: '', // 客户别称
  siteId: '', // 网点id
  siteName: '',
  providerId: '', // 业务主体id
  providerName: '',
  remark: '', // 备注
  collectType: '', // 揽收类型
  chargeWeight: '', // 计费重量
  operationMode: '', // 运营模式
  orderBusinessType: '' // 订单业务类型
};
