var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "msgBox" },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            "align-items": "center",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-upload2", size: "mini" },
              on: { click: _vm.download },
            },
            [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
          ),
          _c(
            "div",
            {
              staticStyle: {
                "margin-right": "70px",
                display: "flex",
                "justify-content": "space-between",
                "align-items": "center",
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.updateTime,
                      expression: "updateTime",
                    },
                  ],
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("GlobalSetObj.UpdateTime")) +
                      "：" +
                      _vm._s(_vm.updateTime)
                  ),
                ]
              ),
              _c("div", { staticClass: "dateBox" }, [
                _c(
                  "span",
                  {
                    class: { d1: _vm.type == 4 },
                    on: {
                      click: function ($event) {
                        return _vm.get2Data(4)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.beforeYesterday")))]
                ),
                _c(
                  "span",
                  {
                    class: { d1: _vm.type == 0 },
                    on: {
                      click: function ($event) {
                        return _vm.get2Data(0)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.yesterday")))]
                ),
                _c(
                  "span",
                  {
                    class: { d1: _vm.type == 1 },
                    on: {
                      click: function ($event) {
                        return _vm.get2Data(1)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.sameDay")))]
                ),
                _c(
                  "span",
                  {
                    class: { d1: _vm.type == 2 },
                    on: {
                      click: function ($event) {
                        return _vm.get2Data(2)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.nearlyAWeek")))]
                ),
                _c(
                  "span",
                  {
                    class: { d1: _vm.type == 3 },
                    on: {
                      click: function ($event) {
                        return _vm.get2Data(3)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.sameMonth")))]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-table",
        {
          ref: "ref",
          attrs: { data: _vm.tableDate, "max-height": 600, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.ind"),
              align: "center",
              type: "index",
              width: "60",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (_vm.queryForm.pageNumber - 1) *
                            _vm.queryForm.pageSize +
                            scope.$index +
                            1
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.auditBatchNumber"),
              prop: "batchNumber",
              align: "center",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.inspectBag"),
              prop: "auditBagNumber",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.actualBag"),
              prop: "actualBagNumber",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.parcelOddNumber"),
              prop: "scanNumber",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.ScanNumber"),
              prop: "actualScanNumber",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.OrderStatus"),
              prop: "collectStatusName",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.auditResult"),
              prop: "auditStatusName",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.processingResult"),
              prop: "auditResult",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.Note"),
              prop: "remark",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.auditNetwork"),
              prop: "auditSiteName",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.LanShouSite"),
              prop: "collectSiteName",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.LanShouPeople"),
              prop: "collectUserName",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.lanshouT"),
              prop: "collectTime",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.unloader"),
              prop: "unloadUserName",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.unloadDot"),
              prop: "unloadSiteName",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.unloadTime"),
              prop: "unloadTime",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.loadSiteIdList"),
              prop: "loadSiteName",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.loader"),
              prop: "loadUserName",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.loadTime"),
              prop: "loadTime",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.signT"),
              prop: "signerTime",
              align: "center",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-pagination", {
            staticStyle: { margin: "10px 0" },
            attrs: {
              "current-page": _vm.queryForm.pageNumber,
              "page-size": _vm.queryForm.pageSize,
              "page-sizes": [10, 20, 50, 100, 500, 1000],
              total: _vm.total,
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "current-change": _vm.getNumPage,
              "size-change": _vm.getPageSize,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }