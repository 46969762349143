var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-content" },
    [
      _c(
        "el-card",
        { staticClass: "searchContainer mb5" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.params,
                "label-width": "auto",
                "label-position": "top",
                size: "small",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "selectInner", attrs: { label: "" } },
                        [
                          _c(
                            "template",
                            { slot: "label" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "180px",
                                    height: "28px",
                                  },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                    size: "mini",
                                  },
                                  on: { change: _vm.onChange },
                                  model: {
                                    value: _vm.type,
                                    callback: function ($$v) {
                                      _vm.type = $$v
                                    },
                                    expression: "type",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t(
                                        "compensationManagement.BillNumber"
                                      ),
                                      value: "claimsBillNumberList",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t(
                                        "compensationManagement.ClaimNumber"
                                      ),
                                      value: "claimsNumberList",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("el-input", {
                            staticClass: "input-with-select",
                            attrs: {
                              clearable: "",
                              placeholder:
                                _vm.$t("collectionCenter.BatchQuery") +
                                "，" +
                                _vm.$t(
                                  "compensationManagement.Maximum500Orders"
                                ),
                              autosize: { minRows: 1, maxRows: 5 },
                              type: "textarea",
                            },
                            model: {
                              value: _vm.params.val1,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "val1", $$v)
                              },
                              expression: "params.val1",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "compensationManagement.TimeOfPayment"
                            ),
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { display: "block", width: "100%" },
                            attrs: {
                              type: "datetimerange",
                              "start-placeholder": _vm.$t(
                                "GlobalSetObj.startDate"
                              ),
                              "end-placeholder": _vm.$t("GlobalSetObj.endDate"),
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              "default-time": ["09:00:00", "18:00:00"],
                            },
                            model: {
                              value: _vm.params.patyedTimeList,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "patyedTimeList", $$v)
                              },
                              expression: "params.patyedTimeList",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("compensationManagement.ClaimType"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                placeholder: _vm.$t(
                                  "operationCenter.PleaseSelect"
                                ),
                                clearable: "",
                              },
                              model: {
                                value: _vm.params.claimsType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.params, "claimsType", $$v)
                                },
                                expression: "params.claimsType",
                              },
                            },
                            _vm._l(_vm.CLAIMS_TYPE, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.name, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "compensationManagement.PaymentStatus"
                            ),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                              },
                              model: {
                                value: _vm.params.payedStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.params, "payedStatus", $$v)
                                },
                                expression: "params.payedStatus",
                              },
                            },
                            _vm._l(_vm.PAYSTATUS_STATUS, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.name, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small", icon: "el-icon-search" },
              on: { click: _vm.onQuery },
            },
            [_vm._v(_vm._s(_vm.$t("operationCenter.Procurar")))]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", icon: "el-icon-refresh-left" },
              on: { click: _vm.onReset },
            },
            [_vm._v(" " + _vm._s(_vm.$t("operationCenter.reset")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "el-card",
        [
          _c(
            "el-table",
            {
              staticClass: "table-height",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                height: _vm.tableHeightRow.height,
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: _vm.$t("operationCenter.ind"), width: "60px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.pageNumber - 1) * _vm.pageSize +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("compensationManagement.BillNumber"),
                  prop: "claimsBillNumber",
                  width: "140",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("compensationManagement.ClaimNumber"),
                  prop: "claimsNumber",
                  width: "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.onOpen(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.claimsNumber))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("compensationManagement.ClaimType"),
                  prop: "claimsType",
                  width: "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.CLAIMS_TYPE[scope.row.claimsType] &&
                                _vm.CLAIMS_TYPE[scope.row.claimsType].name) ||
                                ""
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("compensationManagement.ClaimAmount"),
                  prop: "claimAmount",
                  width: "110",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("compensationManagement.AmountActuallyPaid"),
                  prop: "actualClaimAmount",
                  width: "110",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("compensationManagement.PaymentStatus"),
                  prop: "name",
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.tag
                          ? _c(
                              "el-tag",
                              {
                                key: scope.row.tag.value,
                                attrs: {
                                  type: scope.row.tag.type,
                                  size: "mini",
                                },
                              },
                              [_vm._v(" " + _vm._s(scope.row.tag.name) + " ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("compensationManagement.TimeOfPayment"),
                  prop: "payedTime",
                  width: "170",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("compensationManagement.PaymentMethod"),
                  prop: "payedTypeStr",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("compensationManagement.PaymentAccountNumber"),
                  prop: "payAccount",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("customerManagements.enclosure"),
                  prop: "attachmentUrl",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.attachmentUrl, function (item) {
                        return _c(
                          "el-link",
                          {
                            key: item.url,
                            attrs: {
                              type: "primary",
                              href: item.url,
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      })
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.remark"),
                  prop: "remarks",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: _vm.$t("GlobalSetObj.operate"),
                  width: "210",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.payedStatus === _vm.PAYSTATUS_STATUS[2].value
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  margin: "5px",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.asyncApiClaimBillDeduction(
                                          scope.row.id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "compensationManagement.BillCredit"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        scope.row.payedStatus === _vm.PAYSTATUS_STATUS[2].value
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  margin: "5px",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.cancelPayment(scope.row.id)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "compensationManagement.CancelPayment"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                staticStyle: { margin: "10px 0" },
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  "current-page": _vm.pageNumber,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.pageSize,
                  total: _vm.total,
                  background: "",
                },
                on: {
                  "update:currentPage": function ($event) {
                    _vm.pageNumber = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.pageNumber = $event
                  },
                  "update:pageSize": function ($event) {
                    _vm.pageSize = $event
                  },
                  "update:page-size": function ($event) {
                    _vm.pageSize = $event
                  },
                  "current-change": _vm.getCurrentTableData,
                  "size-change": _vm.getSizeTableData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showBillingDate,
            "close-on-click-modal": false,
            "append-to-body": true,
            width: "500px",
            title: _vm.$t("receivablePayable.BillingDate"),
          },
          on: {
            "update:visible": function ($event) {
              _vm.showBillingDate = $event
            },
            close: _vm.closeMessage,
          },
        },
        [
          _c(
            "div",
            { staticClass: "billing-date" },
            [
              _c(
                "el-form",
                {
                  ref: "formDate",
                  attrs: { model: _vm.formDate, size: "small" },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("receivablePayable.BillingDate"),
                        prop: "billTime",
                        rules: {
                          required: true,
                          message: _vm.$t("collectionCenter.PleaseSelectTime"),
                          trigger: ["blur", "change"],
                        },
                      },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "start-placeholder": _vm.$t("GlobalSetObj.startDate"),
                          "end-placeholder": _vm.$t("GlobalSetObj.endDate"),
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        model: {
                          value: _vm.formDate.billTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.formDate, "billTime", $$v)
                          },
                          expression: "formDate.billTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "footer", staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.closeMessage } },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onConfirm },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.editTitle,
            direction: "ltr",
            visible: _vm.detailsVisible,
            "destroy-on-close": true,
            "before-close": _vm.onEditClose,
            size: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailsVisible = $event
            },
          },
        },
        [
          _c("Details", {
            attrs: { id: _vm.claimsId },
            on: { close: _vm.onEditClose },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }