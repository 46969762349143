<template>
  <div class="app-container">
    <el-form
      label-width="auto"
      label-position="right"
      size="mini"
    >
      <el-row :gutter="10">
        <el-col :span="8">
          <!--网点名称-->
          <el-form-item :label="$t('basicData.siteName') + '：'">
            <span>{{ queryForm.name }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!--未结算总金额-->
          <el-form-item :label="$t('basicData.UnsettledTotalAmount') + '：'">
            <span>{{ queryForm.unsettledAmount }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!--已收总金额-->
          <el-form-item :label="$t('basicData.TotalAmountReceived') + '：'">
            <span>{{ queryForm.receivedTotalAmount }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="8">
          <!--业务时间-->
          <el-form-item :label="$t('basicData.BusinessTime') + '：'">
            <el-date-picker
              v-model="queryDate"
              style="width: 100%"
              type="datetimerange"
              :range-separator="$t('operationCenter.to')"
              :start-placeholder="$t('operationCenter.startDate')"
              :end-placeholder="$t('operationCenter.endDate')"
              value-format="yyyy-MM-dd HH:mm:ss"
              @change="getDetailTableData()"
            />
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <div style="text-align: right">
            <!--导出账户流水-->
            <el-button
              v-permit:remove="'but:finance:basicData:network:ExportAccountRollover'"
              size="mini"
              type="primary"
              @click="exportAccount()"
            >{{ $t('basicData.ExportAccountRollover') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <div>
      <el-table
        ref="elTable"
        :data="tableData"
        border
        row-key="id"
        :tree-props="{ children: 'siteReceipts' }"
      >
        <!--业务日期-->
        <el-table-column
          prop="acceptTime"
          :label="$t('orderCenterCont.DateBusiness')"
          align="left"
          width="240"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.acceptTime }}</span>
          </template>
        </el-table-column>
        <!--批次号-->
        <el-table-column
          prop="receiptNumber"
          :label="$t('basicData.BatchNo')"
          width="240"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.isParent" class="batchNumber" @click="showBillBatchNumber(scope.row)">{{ scope.row.receiptNumber }}</span>
            <span v-else>{{ scope.row.receiptNumber }}</span>
          </template>
        </el-table-column>
        <!--交款金额-->
        <el-table-column
          prop="actualFeeBrl"
          :label="$t('basicData.PaymentAmount') + '(BRL)'"
          align="center"
          width="180"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.actualFeeBrl }}</div>
          </template>
        </el-table-column>

        <!-- 核收时间 -->
        <el-table-column
          prop="acceptOperateTime"
          :label="$t('receivablePayable.CollectionTime')"
          align="left"
          width="160"
          :show-overflow-tooltip="true"
        />

        <!--票单金额-->
        <el-table-column
          prop="billFee"
          :label="$t('basicData.TicketAmount') + '(BRL)'"
          align="center"
          width="180"
          :show-overflow-tooltip="true"
        />
        <!--原币种交款金额-->
        <el-table-column
          prop="actualFee"
          :label="$t('basicData.amountPaymentOriginal')"
          align="center"
          width="120"
          :show-overflow-tooltip="true"
        />
        <!--币种-->
        <el-table-column
          prop="currencyName"
          :label="$t('basicData.Currency')"
          align="center"
          width="120"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.currencyName }}({{ scope.row.currencyCode }})</div>
          </template>
        </el-table-column>
        <!--汇率-->
        <el-table-column
          prop="exchangeRate"
          :label="$t('basicData.exchangeRate')"
          align="center"
          width="120"
          :show-overflow-tooltip="true"
        />
        <!--走账户-->
        <el-table-column
          prop="accountAlias"
          :label="$t('basicData.GoAccount')"
          align="center"
          width="180"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.accountAlias }}</div>
          </template>
        </el-table-column>
        <!--付款方式-->
        <el-table-column
          prop="receiptMethod"
          :label="$t('basicData.PaymentMethod')"
          align="center"
          width="180"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.receiptMethod === 'cash'">{{ $t('basicData.cash') }}</div>
            <div v-if="scope.row.receiptMethod === 'transfer'">{{ $t('basicData.transferable') }}</div>
            <div v-if="scope.row.receiptMethod === 'network'">{{ $t('basicData.online') }}</div>
            <div v-if="scope.row.receiptMethod === 'sterilisation'">{{ $t('basicData.Elimination') }}</div>
            <div v-if="scope.row.receiptMethod === 'other'">{{ $t('basicData.other') }}</div>
          </template>
        </el-table-column>
        <!--附件-->
        <el-table-column
          prop="attachmentUrl"
          :label="$t('basicData.annex')"
          align="center"
          width="200"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-link
              v-if="scope.row.attachmentUrl && scope.row.attachmentUrl.length"
              type="info"
              @click="getImgSrc(scope.row.attachmentUrl)"
            >{{ $t('basicData.View') }}<!--查看--></el-link>
          </template>
        </el-table-column>
        <!--备注-->
        <el-table-column
          prop="remark"
          :label="$t('receivablePayable.remark')"
          align="left"
          min-width="200"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div class="remarkStyle" @click="showImg($event)" v-html="scope.row.remark" />
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="getTableForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="getTableForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getDetailTable"
          @size-change="getDetailTable"
        />
      </div>
    </div>
    <div class="imgs">
      <el-image-viewer v-if="imageViewerObj.showViewer" :on-close="closeViewer" :url-list="imageViewerObj.imgArr" />
    </div>
    <!--账户详情-->
    <el-drawer
      :title="$t('basicData.AccountDetails')"
      :append-to-body="true"
      :visible.sync="showDetailList"
      direction="ltr"
      size="90%"
    >
      <div>
        <batch-number-detail
          :key="detailKeyList"
          :detail-row="detailRow"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { apiSiteReceiptGetPage } from '@/api/finance/basicData';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { MediaTypeEnum } from '@/enums/mediaTypeEnum';
import { apiSiteAccountExportExportOfBillFlow } from '@/api/finance/contasDeClientes';
import { goResourceTaskList } from '@/utils/goExportList.js';

export default {
  name: 'AccountDetails',
  components: {
    ElImageViewer,
    'batch-number-detail': () => import('./batchNumberDetail')
  },
  props: {
    queryForm: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      queryDate: [],
      tableData: [],
      total: 0,
      imageViewerObj: {
        imgArr: [],
        showViewer: false
      },
      showDetailList: false,
      detailKeyList: new Date().getTime(),
      tableDetailData: [],
      // batchNumber: '',

      // 详情
      detailRow: {
        batchNumber: '', // 批次号
        siteId: '', // 业务主体
        name: '' // 网点名称
      },

      getTableForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          siteId: this.queryForm.id, // 业务主体
          acceptStartTime: '',
          acceptEndTime: '',
          isAccept: 1 // 收款状态
        }
      }
    };
  },
  created() {
    this.getDetailTable();
  },
  methods: {
    // 导出账户流水
    exportAccount() {
      const param = {
        ids: [this.queryForm.id],
        startTime: '',
        endTime: '',
        isSiteDetailsRequest: true
      };
      if (this.queryDate && this.queryDate.length > 0) {
        param.startTime = this.queryDate[0];
        param.endTime = this.queryDate[1];
      }
      apiSiteAccountExportExportOfBillFlow(param).then(res => {
        if (res.code === 200) {
          return goResourceTaskList();
        }
      }).then(res => {
        if (res) {
          this.$emit('closeDetail');
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 获取账单详情
    showBillBatchNumber(row) {
      // this.batchNumber = row.receiptNumber;
      const { receiptNumber } = row;
      const { name, id } = this.queryForm;
      this.detailRow.batchNumber = receiptNumber;
      this.detailRow.siteId = id;
      this.detailRow.name = name;

      this.detailKeyList = new Date().getTime();
      this.showDetailList = true;
    },
    // 图片点击放大
    showImg(e) {
      if (e.target.tagName === 'IMG') {
        this.$set(this.imageViewerObj, 'showViewer', true);
        this.$set(this.imageViewerObj, 'imgArr', [e.target.src]);

        // this.imgPreview.img = e.target.src;
        // this.imgPreview.show = true;
      }
    },
    // 关闭查看图片
    closeViewer() {
      this.$set(this.imageViewerObj, 'showViewer', false);
    },
    // 图片/PDF 查看
    getImgSrc(url) {
      // 图片
      if (url) {
        const urlList = url.split(',');
        let isBool = [];
        const urlArr = [];
        urlList.map((list) => {
          if (list) {
            const suffix = list.substring(list.lastIndexOf('.') + 1);
            if (suffix !== 'pdf' && suffix !== 'PDF') {
              urlArr.push(list);
            }
            isBool = MediaTypeEnum.typeImg.filter((item) => {
              return list.endsWith(item);
            });
          }
          if (isBool.length === 0) {
            window.open(list);
          }
        });
        if (urlArr.length !== 0) {
          this.imageViewerObj.imgArr = urlArr;
          this.imageViewerObj.showViewer = true;
        }
      }
    },
    getDetailTableData() {
      this.getTableForm.pageNumber = 1;
      this.getDetailTable();
    },
    // 获取详情
    getDetailTable() {
      if (this.queryDate && this.queryDate.length > 0) {
        this.getTableForm.params.acceptStartTime = this.queryDate[0];
        this.getTableForm.params.acceptEndTime = this.queryDate[1];
      } else {
        this.getTableForm.params.acceptStartTime = '';
        this.getTableForm.params.acceptEndTime = '';
      }
      apiSiteReceiptGetPage(this.getTableForm).then(res => {
        if (res.code === 200) {
          res.data.records.map(item => {
            for (const key in item) {
              if (key === 'acceptTime' || key === 'auditTime') {
                if (item[key]) {
                  item[key] = item[key].replace('T', ' ');
                }
              }
            }
          });
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      }).catch(err => {
        console.error(err);
      });
    }
  }
};
</script>

<style scoped>
.batchNumber {
  color: #007efd;
  cursor: pointer;
}
</style>
<style>
.remarkStyle p {
  padding: 0!important;
  margin: 0!important;
}
</style>
