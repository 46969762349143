<template>
  <div>
    <el-row :gutter="20" class="mt-20">
      <el-col :span="24">
        <el-card class="box-card" style="position: relative">
          <span class="clearfix">{{ $t('newOrder.SortingCenterOperationData') }}</span>
          <div class="time-query-class">
            <el-select
              v-model="timeKey" class="autoWidth" size="mini"
              :placeholder="$t('collectionCenter.pleaseChoose')"
              @change="getData"
            >
              <template slot="prefix">
                {{ (timeList.find(item => item.value === timeKey) || {}).label }}
              </template>
              <el-option
                v-for="item in timeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <el-date-picker
              v-model="timeValue"
              :clearable="false"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :start-placeholder="$t('collectionCenter.StartTime')"
              :end-placeholder="$t('collectionCenter.EndTime')"
              style="margin-left: 5px;"
              size="mini"
              @change="dateChange"
            />
          </div>
          <!--<el-row v-if="segmentMetrics&&segmentMetrics.length" :gutter="20" style="margin-top: 20px">
            <el-col
              v-for="(item, index) in segmentMetrics" :key="index" :span="4"
              :md="6"
              :sm="8"
              :lg="4"
            >
              <div class="segment-item">
                <div class="segment-title">{{ item.operateSiteName }}</div>
                <div style="display: flex;justify-content: center">
                  <div class="segment-item-box">
                    <p class="segment-item-box-title"> {{$t('GlobalSetObj.arrive')}}</p>
                    <div class="segment-value">{{ item.leaveCount|formatNumber }}</div>
                  </div>
                  <div class="segment-item-box">
                    <p class="segment-item-box-title">{{$t('GlobalSetObj.sender')}}</p>
                    <div class="segment-subvalue">{{ item.arriveCount|formatNumber }}</div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>-->
          <div v-if="segmentMetrics&&segmentMetrics.length" style="margin-top: 20px">
            <!-- 一级分拨 -->
            <span style="font-weight: bold;font-size: 16px">{{ $t('placeOrder.firstLevelAllocation') }}</span>
            <el-row class="allocate-class">
              <el-col
                v-for="(item, index) in segmentMetrics1" :key="index" :span="6"
                class="allocate-col-class"
              >
                <!-- 早班 / 中班 / 晚班 -->
                <div style="text-align: center; margin: 10px auto;font-weight: bold;font-size: 15px">{{ item.operateSiteName }}</div>
                <el-row style="margin-bottom: 10px">
                  <el-col :span="8">
                    <!-- 到件 -->
                    <div style="text-align: center;font-weight: bold;font-size: 15px">{{ $t('GlobalSetObj.arrive') }}</div>
                    <div style="text-align: center;color: #38bd38;font-size: 13px;font-weight: bold">{{ item.arriveCount }}</div>
                  </el-col>
                  <el-col :span="8">
                    <!-- 分拣 -->
                    <div style="text-align: center;font-weight: bold;font-size: 15px">{{ $t('placeOrder.sort') }}</div>
                    <div style="text-align: center;color: #04a2d7;font-size: 13px;font-weight: bold">{{ item.sortingCount }}</div>
                  </el-col>
                  <el-col :span="8">
                    <!-- 发件 -->
                    <div style="text-align: center;font-weight: bold;font-size: 15px">{{ $t('GlobalSetObj.sender') }}</div>
                    <div style="text-align: center;color: #f2bf23;font-size: 13px;font-weight: bold">{{ item.leaveCount }}</div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <!-- 二级、三级分拨 -->
            <span style="font-weight: bold;font-size: 16px">{{ $t('placeOrder.secondThreeLevelAllocate') }}</span>
            <el-row class="allocate-class" type="flex" justify="start">
              <el-col
                v-for="(item, index) in segmentMetrics2" :key="index" :span="4"
                class="allocate-col-class"
              >
                <div style="text-align: center; margin: 10px auto;font-weight: bold;font-size: 15px">{{ item.operateSiteName }}</div>
                <el-row style="margin-bottom: 10px;flex-wrap: wrap;" type="flex" justify="space-around">
                  <el-col :span="8">
                    <!-- 到件 -->
                    <div style="text-align: center;font-weight: bold;font-size: 15px">{{ $t('GlobalSetObj.arrive') }}</div>
                    <div style="text-align: center;color: #38bd38;font-size: 13px;font-weight: bold">{{ item.arriveCount }}</div>
                  </el-col>
                  <el-col :span="8">
                    <!-- 发件 -->
                    <div style="text-align: center;font-weight: bold;font-size: 15px">{{ $t('GlobalSetObj.sender') }}</div>
                    <div style="text-align: center;color: #f2bf23;font-size: 13px;font-weight: bold">{{ item.leaveCount }}</div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
          <el-empty v-else :image-size="100" />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { waybillDashboardTransferOperateData } from '@/api/orderApi';
import dayjs from 'dayjs';

export default {
  filters: {
    // 数字格式化函数
    formatNumber(num, showPositiveSign = false) {
      // 如果不是数字，直接返回原值
      if (isNaN(num) || num === null) return num;

      // 将数字转换为字符串
      let numStr = Number(num).toString();

      // 处理负数
      const isNegative = numStr.startsWith('-');
      if (isNegative) {
        numStr = numStr.slice(1);
      }

      // 分离整数部分和小数部分
      const parts = numStr.split('.');
      let integerPart = parts[0];
      const decimalPart = parts[1];

      // 添加千分位逗号
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // 重组数字
      let result = integerPart;
      if (decimalPart) {
        result += '.' + decimalPart;
      }

      // 添加正负号
      if (isNegative) {
        result = '-' + result;
      } else if (showPositiveSign && num > 0) {
        result = '+' + result;
      }

      return result;
    }
  },
  props: {
    orderType: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      segmentMetrics: [],
      segmentMetrics1: [],
      segmentMetrics2: [],
      timeKey: 0,
      timeList: [
        // 0：默认全部 1：一级，2：二级，3：三级分拨
        { value: 0, label: this.$t('GlobalSetObj.Acquiesce') },
        { value: 1, label: this.$t('GlobalSetObj.oneTransitInboundTime') },
        { value: 2, label: this.$t('GlobalSetObj.twoTransitInboundTime') },
        { value: 3, label: this.$t('GlobalSetObj.threeTransitInboundTime') }
      ],
      timeValue: []
    };
  },
  watch: {
    orderType() {
      this.getData();
    }
  },
  created() {
    this.timeKey = 0;
    const d = new Date();
    const day = dayjs(d).format('YYYY-MM-DD');
    this.timeValue = [day + ' 00:00:00', day + ' 23:59:59'];
    this.getData();
  },
  methods: {
    /**
     * @description 获取数据
     */
    getData() {
      const form = {
        startTime: this.timeValue[0],
        endTime: this.timeValue[1],
        level: this.timeKey,
        orderType: this.orderType
      };
      waybillDashboardTransferOperateData(form)
        .then((res) => {
          if (res.code===200) {
            this.segmentMetrics = res.data;
            const segmentMetrics1 = [];
            res.data.forEach(arr => {
              if (arr.siteLevel === 1) {
                segmentMetrics1.push(arr);
              }
            });
            const segmentMetrics2 = [];
            res.data.forEach(arr => {
              if (arr.siteLevel === 2 || arr.siteLevel === 3) {
                segmentMetrics2.push(arr);
              }
            });
            this.segmentMetrics1 = segmentMetrics1;
            this.segmentMetrics2 = segmentMetrics2;
          }
        });
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        return;
      }
      this.timeValue = [val[0], val[1]];
      this.getData();
    }
  }
};
</script>

<style lang="scss" scoped>
.autoWidth ::v-deep .el-input__inner{
  padding-left: 0 !important;
  text-align: center;
}
.autoWidth ::v-deep .el-input__prefix{ // 下拉框自适应占位
  position: relative;
  left: 0;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  line-height: 28px;
  height: 28px;
  padding: 0 25px;
  padding-left: 15px;
  box-sizing: border-box;
  visibility: hidden;
  white-space: nowrap;
}
.autoWidth ::v-deep .el-input__suffix{
  height: 28px;
}
.autoWidth ::v-deep input {
  position: absolute;
}
.time-query-class{
  position: absolute;
  right: 20px;
  top: 20px;
}
.allocate-class{
  margin-bottom: 20px;
  flex-wrap: wrap;
  .allocate-col-class{
    background: #f5faf6;
    border-radius: 3px;
    margin-right: 10px;
    margin-top: 10px;
  }
}
.clearfix{
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #1C1C1C;
  line-height: 22px;
  margin-bottom: 20px;
}
.segment-item{
  background: #F4FAF6;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 10px;
  .segment-title{
    font-family: PingFang SC;
    font-weight: bold;
    font-size: 14px;
    color: #1C1C1C;
    line-height: 20px;
    text-align: center;
  }
  .segment-item-box{
    width: 50%;
    text-align: center;
    .segment-item-box-title{
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 14px;
      color: #1C1C1C;
      line-height: 20px;
    }
    .segment-value{
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 14px;
      color: #009C37;
      line-height: 20px;
    }
    .segment-subvalue{
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 14px;
      color: #FFA700;
      line-height: 20px;
    }
  }
}
</style>
