import request from '@/utils/request';
import { getToken } from '@/utils/auth';

const baseURL = process.env.VUE_APP_BASE_API2; // /api/v2/collection

const requestCollectOrder = process.env.VUE_APP_BASE_APICollectOrder;

const orderURL = process.env.VUE_APP_BASE_API1; // /api/v2/order

const baseAdminUrl = process.env.VUE_APP_BASE_API; // /api/express/v1

const baseUserURL = process.env.VUE_APP_USER;

const baseURLAdmin = process.env.VUE_APP_ADMIN; // /admin

/**
 * 上门交件查询————全部导出
 */
export const collectListExportAll = (data) => {
  return request({
    method: 'POST',
    url: '/collectList/exportAll',
    data,
    baseURL: baseURLAdmin
  });
};

/**
 * 揽收营运数据————稽查环节明细——全部导出
 */
export const getAuditDetailExport = (data) => {
  return request({
    url: `/operationDashboardData/auditDetailExport`,
    method: 'post',
    data
  });
};
/**
 * 揽收营运数据————稽查环节明细
 */
export const getAuditDetailPage = (data) => {
  return request({
    url: `/operationDashboardData/getAuditDetailPage`,
    method: 'post',
    data
  });
};

/**
 * 揽收营运数据————到达***转运中心明细——全部导出
 */
export const getTransAnomalyExport = (data) => {
  return request({
    url: `/operationDashboardDetail/transitAbnormalDetailExport`,
    method: 'post',
    data
  });
};
/**
 * 揽收营运数据————到达***转运中心明细
 */
export const getTransAnomalyPage = (data) => {
  return request({
    url: `/operationDashboardDetail/getTransitAbnormalDetailPage`,
    method: 'post',
    data
  });
};

/**
 * 提交签名前获取签名区数据————单次扫描 / 大包裹揽收
 */
export const pcCollectionCheck = (data) => {
  return request({
    url: `collectOrderScan/scanInfo/pcCollectionCheck`,
    method: 'post',
    data
  });
};

/**
 * 揽收营运数据————袋子详细信息列表——导出
 */
export const transitOrderDetailExportAll = (data) => {
  return request({
    url: `/operationDashboardDetail/transitOrderDetail/exportAll`,
    method: 'post',
    data
  });
};

/**
 * 揽收营运数据————装袋信息统计列表——导出
 */
export const transitBagDetailExportAll = (data) => {
  return request({
    url: `/operationDashboardDetail/transitBagDetail/exportAll`,
    method: 'post',
    data
  });
};

/**
 * 揽收营运数据————装车信息统计列表——导出
 */
export const transitDetailExportAll = (data) => {
  return request({
    url: `/operationDashboardDetail/transitDetail/exportAll`,
    method: 'post',
    data
  });
};

/**
 * 揽收营运数据————袋子详细信息列表
 */
export const getTransitOrderDetailPage = (data) => {
  return request({
    url: `/operationDashboardDetail/getTransitOrderDetailPage`,
    method: 'post',
    data
  });
};

/**
 * 揽收营运数据————装袋信息统计列表
 */
export const getTransitBagDetailPage = (data) => {
  return request({
    url: `/operationDashboardDetail/getTransitBagDetailPage`,
    method: 'post',
    data
  });
};

/**
 * 揽收营运数据————装车信息统计列表
 */
export const getTransitDetailPage = (data) => {
  return request({
    url: `/operationDashboardDetail/getTransitDetailPage`,
    method: 'post',
    data
  });
};

/**
 * MD滞留————全部导出
 */
export const mdStayExportAll = (data) => {
  return request({
    url: `/collectMdStayReport/mdStayExportAll`,
    method: 'POST',
    data
  });
};

/**
 * MD滞留——明细数据
 */
export const getMdStayOrderDataPage = (data) => {
  return request({
    url: `/collectMdStayReport/getMdStayOrderDataPage`,
    method: 'POST',
    data
  });
};
// 准时率汇总
export const getPunctualSummaryData = (data) => {
  return request({
    url: `/operationDashboardData/getPunctualSummaryData`,
    method: 'POST',
    data
  });
};
// 准时率明细
export const getPunctualOrderDataPage = (data) => {
  return request({
    url: `/operationDashboardData/getPunctualOrderDataPage`,
    method: 'POST',
    data
  });
};
// 以上丢失二级汇总
export const getSuspectedLostSummaryData = (data) => {
  return request({
    url: `/operationDashboardData/getSuspectedLostSummaryData`,
    method: 'POST',
    data
  });
};
// 疑似丢失明细
export const getSuspectedLostOrderDataPage = (data) => {
  return request({
    url: `/operationDashboardData/getSuspectedLostOrderDataPage`,
    method: 'POST',
    data
  });
};
// 导出滞留明细
export const mdStayExportDetail = (data) => {
  return request({
    url: `/collectMdStayReport/mdStayOrderDetailExport`,
    method: 'POST',
    data
  });
};

/**
 * MD滞留汇总数据——第er个表的数据
 */
export const getMdStayDetailDataPage = (data) => {
  return request({
    url: `/collectMdStayReport/getMdStayDetailDataPage`,
    method: 'POST',
    data
  });
};

/**
 * MD滞留汇总数据——第一个表的数据
 */
export const getMdStaySummaryData = (data) => {
  return request({
    url: `/collectMdStayReport/getMdStaySummaryData`,
    method: 'POST',
    data
  });
};

/**
 * 揽收营运数据
 */
export const dashboard = (data) => {
  return request({
    url: `/operation/dashboard`,
    method: 'post',
    data
  });
};

/**
 * 装车扫描————下一站——模糊查询
 */
export const siteFuzzyQuery = (data) => {
  return request({
    url: `/site/queryPage?name=${data}`,
    method: 'get'
  });
};

/**
 * 转运中心车辆确认————车辆数据——模糊查询
 */
export const vehicleNumberFuzzyQuery = (data) => {
  return request({
    url: `/collectVehicle/queryVehiclePage?vehicleNumber=${data}`,
    method: 'get'
  });
};

/**
 * 转运中心车辆确认查询————确认
 */
export const unloadVehicleManualConfirm = (data) => {
  return request({
    url: `/transitVehicleScanConfirm/unloadVehicleManualConfirm/${data}`,
    method: 'GET'
  });
};

/**
 * 转运中心车辆确认查询————查看图片
 */
export const queryUnloadImages = (data) => {
  return request({
    url: `/transitVehicleScanConfirm/queryUnloadImages?id=${data}`,
    method: 'GET'
  });
};

/**
 * 揽收时效配置————查询大区
 */
export const getBigArea = (data) => {
  return request({
    url: `/collectAgeingConfiguration/getBigArea?areaName=${data}`,
    method: 'GET'
  });
};

/**
 * 揽收时效配置————获取时效类型
 */
export const getAgeingType = () => {
  return request({
    url: `/collectAgeingConfiguration/getAgeingType`,
    method: 'GET'
  });
};

/**
 * 转运中心车辆确认查询————删除
 */
export const unloadVehicleCancleDel = (data) => {
  return request({
    url: `/transitVehicleScanConfirm/unloadVehicleCancle/${data}`,
    method: 'GET'
  });
};

/**
 * 转运中心车辆确认查询————导出
 */
export const transitVehicleScanConfirmExport = (data) => {
  return request({
    url: `/transitVehicleScanConfirm/export`,
    method: 'POST',
    data
  });
};

/**
 * 转运中心车辆确认查询————分页
 */
export const transitVehicleScanConfirmPage = (data) => {
  return request({
    url: `/transitVehicleScanConfirm/page`,
    method: 'POST',
    data
  });
};

/**
 * 转运中心车辆确认————提交
 */
export const unloadVehicleConfirm = (data) => {
  return request({
    url: `/transitVehicleScanConfirm/unloadVehicleConfirm`,
    method: 'post',
    data
  });
};

/**
 * 转运中心车辆确认————车辆数据
 */
export const queryVehicleValList = (data) => {
  return request({
    url: `/bag/unloadVehicleNumber?vehicleId=${data}`,
    method: 'get'
  });
};

/**
 * 转运中心车辆确认————车辆列表——查全部
 */
export const queryVehicleList = (data) => {
  return request({
    url: `/collectVehicle/queryVehicleList/${data}`,
    method: 'get'
  });
};

/**
 * 卸车扫描————提交签名图
 */
export const updateUnloadSignImg = (data) => {
  return request({
    url: `/bag/updateUnloadSignImg`,
    method: 'post',
    data
  });
};

/**
 * 卸车扫描————卸车签收图片面板信息查询
 */
export const queryUnloadBagCountAndSiteByBagNumber = (data) => {
  return request({
    url: `/bag/queryUnloadBagCountAndSiteByBagNumber`,
    method: 'post',
    data
  });
};

/**
 * 车辆管理————驾驶人模糊查询
 */
export const queryAllLimitName = (data) => {
  return request({
    url: `/queryAllLimitName`,
    method: 'POST',
    data
  });
};

/**
 * 装车确认扫描————车辆扫描确认取消接口
 */
export const confirmAndCancelVehicle = (data) => {
  return request({
    url: `/bag/confirmAndCancelVehicle`,
    method: 'POST',
    data
  });
};

/**
 * 装车确认扫描————车辆扫描
 */
export const loadVehicleScan = (data) => {
  return request({
    url: `/bag/checkVehicleNumber?vehicleId=${data}`,
    method: 'get'
  });
};

/**
 * 揽收信息确认查询————删除
 */
export const scanConfirmDel = (data) => {
  return request({
    url: `/scanConfirm/cancel/${data}`,
    method: 'get'
  });
};

/**
 * 揽收信息确认查询————装车数量确认——确认
 */
export const scanConfirm = (data) => {
  return request({
    url: `/scanConfirm/confirm`,
    method: 'POST',
    data
  });
};

/**
 * 揽收信息确认查询————装车数量确认——数据
 */
export const confirmLoadVehicleRecord = (data) => {
  return request({
    url: `/bag/confirmLoadVehicleRecord`,
    method: 'POST',
    data
  });
};

/**
 * 揽收信息确认查询————导出
 */
export const scanConfirmExport = (data) => {
  return request({
    url: `/scanConfirm/export`,
    method: 'POST',
    data
  });
};

/**
 * 揽收信息确认查询————分页
 */
export const scanConfirmPage = (data) => {
  return request({
    url: `/scanConfirm/page`,
    method: 'POST',
    data
  });
};

/**
 * 揽收节点异常数据监控报表————导出
 */
export const collectAbnormalExport = (data) => {
  return request({
    url: `/collectAbnormal/export`,
    method: 'post',
    data
  });
};

/**
 * 揽收节点异常数据监控报表————分页
 */
export const collectAbnormalPage = (data) => {
  return request({
    url: `/collectAbnormal/page`,
    method: 'post',
    data
  });
};

/**
 * 揽收时效配置——修改
 */
export const collectionTimeUpdate = (data) => {
  return request({
    url: `/collectAgeingConfiguration/update`,
    method: 'POST',
    data
  });
};

/**
 * 揽收时效配置——新增
 */
export const collectionTimeAdd = (data) => {
  return request({
    url: `/collectAgeingConfiguration/add`,
    method: 'POST',
    data
  });
};

/**
 * 揽收时效配置——删除
 */
export const collectionTimeDel = (data) => {
  return request({
    url: `/collectAgeingConfiguration/delete/${data}`,
    method: 'GET'
  });
};

/**
 * 揽收时效配置——全部导出
 */
export const collectionTimeExport = (data) => {
  return request({
    url: '/collectAgeingConfiguration/export',
    method: 'POST',
    data
  });
};

/**
 * 揽收时效配置——分页
 */
export const collectionTimePage = (data) => {
  return request({
    url: '/collectAgeingConfiguration/pageQuery',
    method: 'POST',
    data
  });
};

/**
 * 备货揽收扫描————备货揽收扫描提交
 */
export const stockCollectScanSubmit = (data) => {
  return request({
    url: `/stock/scan/stockCollectScanSubmit`,
    method: 'post',
    data,
    baseURL: baseURL
  });
};

/**
 * 备货揽收扫描————获取签名模版信息
 */
export const getSignTemplateInfo = (data) => {
  return request({
    url: `/stock/scan/getSignTemplateInfo`,
    method: 'post',
    data,
    baseURL: baseURL
  });
};

/**
 * 备货揽收扫描————删除备货揽收扫描数据
 */
export const deleteStockScanData = (data) => {
  return request({
    url: `/stock/scan/deleteStockScanData`,
    method: 'post',
    data,
    baseURL: baseURL
  });
};

/**
 * 备货揽收扫描————备货揽收扫描数据
 */
export const stockCollectScanData = (data) => {
  return request({
    url: `/stock/scan/stockCollectScanData`,
    method: 'post',
    data,
    baseURL: baseURL
  });
};

/**
 * 备货揽收扫描————获取当前用户备货揽收扫描数据
 */
export const getCurrentUserStockScanData = () => {
  return request({
    url: `/stock/scan/getCurrentUserStockScanData/1`, // 扫描来源 0:pda 1:pc
    method: 'get',
    baseURL: baseURL
  });
};

/**
 * 装车扫描——签名模板
 */
export const queryBagCountAndSiteByBagNumber = (data) => {
  return request({
    url: '/bag/queryBagCountAndSiteByBagNumber',
    method: 'POST',
    data
  });
};

/**
 * 装车卸车数据监控——查询装车签名图片
 */
export const queryLoadSignImg = (data) => {
  return request({
    url: '/tagNumberDatail/sum/queryLoadSignImg',
    method: 'POST',
    data
  });
};

/**
 * 揽收KPI监控报表————明细导出
 */
export const kpiMonitoringDetailExportAll = (data) => {
  return request({
    url: `/kpiMonitoring/detailExportAll`,
    method: 'post',
    data
  });
};

/**
 * 揽收KPI监控报表————汇总导出
 */
export const kpiMonitoringExportAll = (data) => {
  return request({
    url: `/kpiMonitoring/exportAll`,
    method: 'post',
    data
  });
};

/**
 * 揽收KPI监控报表————明细查询
 */
export const kpiMonitoringDetail = (data) => {
  return request({
    url: `/kpiMonitoring/queryDetailPage`,
    method: 'post',
    data
  });
};

/**
 * 揽收KPI监控报表————查询所有大区和区域
 */
export const kpiMonitoringArea = (data) => {
  return request({
    url: `/kpiMonitoring/queryAll?type=${data}`,
    method: 'get',
    isLoading: false
  });
};

/**
 * 揽收KPI监控报表————汇总分页
 */
export const kpiMonitoringPage = (data) => {
  return request({
    url: `/kpiMonitoring/page`,
    method: 'post',
    data
  });
};

/**
 * 下单日志查询————全部导出
 */
export const collectOrderLogExportAll = (data) => {
  return request({
    url: `/collectOrderLog/exportAll`,
    method: 'post',
    data
  });
};

/**
 * 下单日志查询————导出
 */
export const collectOrderLogExport = (data) => {
  return request({
    url: `/collectOrderLog/export`,
    method: 'post',
    data
  });
};

/**
 * 下单日志查询————分页
 */
export const collectOrderLogPage = (data) => {
  return request({
    url: `/collectOrderLog/page`,
    method: 'post',
    data
  });
};

/**
 * 卸车扫描————应卸车
 */
export const collectShouldUnload = (data) => {
  return request({
    url: `/collectTagNumberDetail/loadedVehicle/data?vehicleId=${data}`,
    method: 'get'
  });
};

/**
 * 揽收送仓数据监控——明细——导出
 */
export const collectMonitoringDetailExportAll = (data) => {
  return request({
    url: '/collectWarehouseData/detailExportAll',
    method: 'post',
    data
  });
};

/**
 * 揽收送仓数据监控——汇总——导出
 */
export const collectMonitoringExportAll = (data) => {
  return request({
    url: '/collectWarehouseData/totalExportAll',
    method: 'post',
    data
  });
};

/**
 * 揽收送仓数据监控——明细分页
 */
export const collectMonitoringDetail = (data) => {
  return request({
    url: '/collectWarehouseData/detailPage',
    method: 'post',
    data
  });
};

/**
 * 揽收送仓数据监控——汇总分页
 */
export const collectMonitoringPage = (data) => {
  return request({
    url: '/collectWarehouseData/page',
    method: 'post',
    data
  });
};

/**
 * 揽收时效报表——全部导出
 */
export const queryAgeingExportAll = (data) => {
  return request({
    url: '/collectOrderAgeing/exportAll',
    method: 'post',
    data
  });
};
/**
 * 揽收时效报表——分页
 */
export const queryAgeingPage = (data) => {
  return request({
    url: '/collectOrderAgeing/queryAgeingPage',
    method: 'post',
    data
  });
};
/**
 * 下一站
 */
export const siteSort = () => {
  return request({
    url: '/site/queryAllSort',
    method: 'get',
    isLoading: false
  });
};
/**
 * 袋号查询————打印
 */
export const collectNumberPrint = (data) => {
  return request({
    url: `/collect/collectNumberPrintLabel`,
    method: 'post',
    data,
    baseURL
  });
};

/**
 * 袋号查询————导出
 */
export const collectNumberExport = (data) => {
  return request({
    url: `/collectNumber/export`,
    method: 'post',
    data
  });
};

/**
 * 袋号查询————分页查询
 */
export const collectNumberPage = (data) => {
  return request({
    url: `/collectNumber/page`,
    method: 'post',
    data
  });
};

/**
 * 稽包查询——稽查人
 */
export const queryRealName = (data) => {
  return request({
    url: `/queryPage?realName=${data}`,
    method: 'get',
    baseURL: baseUserURL
  });
};
/**
 * 稽包查询——站点
 */
export const queryWholeSite = (data) => {
  return request({
    url: `/site/queryPage?name=${data}`,
    method: 'get'
  });
};
/**
 * 稽包查询——修改
 */
export const transhipmentAuditEdit = (data) => {
  return request({
    url: `/transhipmentAudit/edit`,
    data,
    method: 'post'
  });
};

/**
 * 稽包查询——明细——导出
 */
export const transhipmentAuditDetailExportAll = (data) => {
  return request({
    url: `/transhipmentAudit/detailExportAll`,
    data,
    method: 'post'
  });
};

/**
 * 稽包查询——汇总——稽查导出
 */
export const transhipmentAuditexportAll = (data) => {
  return request({
    url: `/transhipmentAudit/exportAll`,
    data,
    method: 'post'
  });
};

/**
 * 稽包查询——明细——查询
 */
export const transhipmentAuditDetailPage = (data) => {
  return request({
    url: `/transhipmentAudit/detailPage`,
    data,
    method: 'post'
  });
};

/**
 * 稽包查询——汇总——查询
 */
export const transhipmentAuditPage = (data) => {
  return request({
    url: `/transhipmentAudit/page`,
    data,
    method: 'post'
  });
};

/**
 * 稽包扫描——稽查上传确认接口
 */
export const pcInspectSubmitResult = (data) => {
  return request({
    url: `/collect/pcInspectSubmitResult`,
    method: 'post',
    data,
    baseURL
  });
};

/**
 * 稽包扫描——稽查上传接口
 */
export const pcSealABagSubmit = (data) => {
  return request({
    url: `/collect/pcInspectSubmit`,
    method: 'post',
    data,
    baseURL
  });
};

/**
 * 稽包扫描——稽查删除扫描记录
 */
export const deletedScan = (data) => {
  return request({
    url: `/collectOrderScan/scanInfo/deletedScan/${data}`,
    method: 'DELETE'
  });
};

/**
 * 稽包扫描——稽查查询扫描记录
 */
export const queryScanLog = (data) => {
  return request({
    url: `/collectOrderScan/scanInfo/queryScanLogData?operationType=${data.operationType}&sourceType=${data.sourceType}&timeDay=${data.timeDay}`,
    method: 'get'
  });
};

/**
 * 稽包扫描——稽查扫描
 */
export const inspectOrderScan = (data) => {
  return request({
    url: `/collectOrderScan/scanInfo/inspectOrderScan/${data.number}/1`,
    data,
    method: 'get'
  });
};

/**
 * Single Scan--删除
 */
export const deleteRedisPackage = (data) => {
  return request({
    url: `/collectOrderScan/scanInfo/singleScandeletedScan/${data.orderId}/${data.scanId}`,
    method: 'delete'
  });
};

/**
 * Single Scan
 */
// export const onceScanLog = (type1, type2) => {
//   return request({
//     url: `/scanInfo/queryScanLog/${type1}/${type2}`,
//     method: 'get'
//   });
// };

/**
 * Single Scan--填写的袋号校验————成功后调用封袋提交接口
 */
export const pcBagBarcodeScan = (data) => {
  return request({
    url: `/collectOrderScan/scanInfo/pcBagBarcodeScan/${data}/12`,
    method: 'get'
  });
};

/**
 * Single Scan--封袋
 */
export const sealerBagNoBR = (data) => {
  return request({
    url: `/collect/pcSingleScanBagSubmit`,
    method: 'post',
    data,
    baseURL
  });
};

/**
 * Single Scan--单次扫描揽收提交——签名
 */
export const signPushNoBR = (data) => {
  return request({
    url: `/collect/pcSingleScanCollectSubmit`,
    method: 'post',
    data,
    baseURL
  });
};

/**
 * Single Scan--扫描
 */
export const directScan = (data) => {
  return request({
    url: `/collectOrderScan/scanInfo/pcCollectionBarcodeScan`,
    method: 'POST',
    data
  });
};

/**
 * 揽收码查询——截单揽收码查询
 */
export const cutOffCodePage = (data) => {
  return request({
    url: `/collectOrder/cutOffCodePage`,
    data,
    method: 'post'
  });
};

/**
 * 退件查询--打印面单
 */
export const printout = (data) => {
  return request({
    url: `/scan/printout`,
    method: 'post',
    data,
    baseURL
  });
};

/**
 * 退件查询--打印详情
 */
export const waybillReturnPrintList = (id) => {
  return request({
    url: `/waybillReturn/printList?id=${id}`,
    method: 'get'
  });
};

/**
 * 退件查询--全部导出
 */
export const waybillReturnExportAll = (data) => {
  return request({
    url: '/waybillReturn/exportAll',
    method: 'POST',
    data
  });
};

/**
 * 退件查询--导出
 */
export const waybillReturnExport = (data) => {
  return request({
    url: '/waybillReturn/export',
    method: 'POST',
    data
  });
};

/**
 * 退件查询--分页
 */
export const waybillReturnPage = (data) => {
  return request({
    url: '/waybillReturn/page',
    method: 'POST',
    data
  });
};

/**
 * 车辆管理——全部导出
 */
export const vehicleAllExport = (data) => {
  return request({
    url: '/collectVehicle/toExportAll',
    method: 'POST',
    data
  });
};

/**
 * 车辆管理——导出
 */
export const vehicleExport = (data) => {
  return request({
    url: '/collectVehicle/export',
    method: 'POST',
    data
  });
};

/**
 * 揽收订单报表--明细--导出
 */
export const collectCreateReportDetailExport = (data) => {
  return request({
    url: '/collectCreateReportDetail/export',
    method: 'POST',
    data
  });
};

/**
 * 揽收订单报表--明细分页
 */
export const collectCreateReportDetailPage = (data) => {
  return request({
    url: '/collectCreateReportDetail/page',
    method: 'POST',
    data
  });
};

/**
 * 揽收订单报表--汇总--导出
 */
export const collectCreateReportExport = (data) => {
  return request({
    url: '/collectCreateReport/export',
    method: 'POST',
    data
  });
};

/**
 * 揽收订单报表--汇总分页
 */
export const collectCreateReportPage = (data) => {
  return request({
    url: '/collectCreateReport/page',
    method: 'POST',
    data
  });
};

/**
 * 异常件--导出
 */
export const collectProblemExport = (data) => {
  return request({
    url: '/collectProblem/export',
    method: 'POST',
    data
  });
};

/**
 * 快递退件--上传
 */
export const expressReturnUpload = (data) => {
  return request({
    url: '/logistics/order/deliver/return',
    method: 'POST',
    data,
    baseURL: orderURL
  });
};

/**
 * 快递退件--删除
 */
export const expressReturnDel = (id) => {
  return request({
    url: `/collection/expressReturnScan/deleted?scanId=${id}`,
    method: 'get',
    baseURL
  });
};

/**
 * 快递退件--退件扫描
 */
export const expressReturnScan = (data) => {
  return request({
    url: '/logistics/order/deliver/return/scan',
    method: 'POST',
    data,
    baseURL: orderURL
  });
};

/**
 * 上门交件--揽收人--根据登录用户网点类型查询员工数据
 */
export const queryPageByUser = () => {
  return request({
    url: '/queryPageByUser',
    method: 'get',
    baseURL: baseUserURL
  });
};

/**
 * 上门交件--揽收人--根据登录用户网点类型查询员工数据 ---- 查10个
 */
export const query10User = (data) => {
  return request({
    url: `/queryPage?realName=${data}`,
    method: 'get',
    baseURL: baseUserURL
  });
};

/**
 * 上门交件--网点--根据用户网点类型查询网点
 */
export const getSiteByUser = () => {
  return request({
    url: '/site/getSiteByUser',
    method: 'get'
  });
};

/**
 * 揽收看板--揽收日报-异常单量导出
 */
export const kanbanOrderExportAll = (data) => {
  return request({
    url: '/excel/collectDailyReport/abnormalOrderExport',
    method: 'POST',
    data
  });
};

/**
 * 揽收看板--揽收日报-剩余商家导出
 */
export const kanbanMerchantExportAll = (data) => {
  return request({
    url: '/excel/collectDailyReport/remainingMerchantExport',
    method: 'POST',
    data
  });
};

/**
 * 揽收看板--揽收日报
 */
export const queryKanban = (data) => {
  return request({
    url: '/collectDailyReport/getDailyData',
    method: 'POST',
    data
  });
};

/**
 * 装车卸车数据监控——装卸车图片
 */
export const queryLoadAndUnLoadImg = (data) => {
  return request({
    url: '/tagNumberDatail/sum/queryLoadAndUnLoadImg',
    method: 'POST',
    data
  });
};

/**
 * 揽收商家查询————导出
 */
export const merchantClassExportAll = (data) => {
  return request({
    url: '/collectOrder/merchantClassExportAll',
    method: 'post',
    data
  });
};

/**
 * 揽收商家查询————商家分类查询
 */
export const merchantPage = (data) => {
  return request({
    url: '/collectOrder/merchantPage',
    method: 'post',
    data
  });
};

/**
 * 卸车扫描————(袋号) 大包号/运单号删除
 */
export const deletedVehicleUnloadedBagNumber = (data) => {
  return request({
    url: `/bag/deletedVehicleUnloadedBagNumber?bagNumber=${data.bagNumber}&vehicleId=${data.vehicleId}`,
    method: 'get'
  });
};

/**
 * 卸车扫描————袋号扫描
 */
export const checkVehicleUnloadedBagNumber = (data) => {
  return request({
    // 旧的
    // url: `/collection/checkVehicleUnloadedBagNumber?bagNumber=${data.bagNumber}&vehicleId=${data.vehicleId}`,
    // method: 'GET',
    // baseURL

    // 新的
    url: `/bag/checkUnloadInfo?bagNumber=${data.bagNumber}&vehicleId=${data.vehicleId}`,
    method: 'GET'
  });
};

/**
 * 卸车扫描————卸车结束
 */
export const CheckUnloadedVehicle = (data) => {
  return request({
    url: '/unloadedVehicle',
    method: 'post',
    data,
    baseURL
  });
};

/**
 * 卸车扫描————车签号校验
 */
export const checkUnloadedVehicleScan = (data) => {
  return request({
    url: `/collection/checkUnloadedVehicleScan?carTag=${data.carTag}&vehicleId=${data.vehicleId}`,
    method: 'get',
    baseURL
  });
};

/**
 * 装车扫描————装车获取扫描袋号数据
 */
export const BagScanRecord = (data) => {
  return request({
    url: `/bag/bagScanRecord?vehicleId=${data}`,
    method: 'get'
  });
};

/**
 * 装车扫描————装车结束
 */
export const loadedVehicle = (data) => {
  return request({
    url: '/loadedVehicle',
    method: 'post',
    data,
    baseURL
  });
};

/**
 * 装车扫描————袋号删除
 */
export const deletedVehicleLoadedBagNumber = (data) => {
  return request({
    url: `/bag/deletedVehicleLoadedBagNumber?bagNumber=${data.bagNumber}&vehicleId=${data.vehicleId}`,
    method: 'get'
  });
};

/**
 * 装车扫描————袋号扫描
 */
export const checkVehicleLoadedBagNumber = (data) => {
  return request({
    url: `/bag/checkVehicleLoadedBagNumber`,
    method: 'post',
    data
  });
};

/**
 * 卸车扫描————车辆扫描
 */
export const unloadCheckVehicle = (data) => {
  return request({
    url: `/bag/unloadCheckVehicle?vehicleId=${data}`,
    method: 'get'
  });
};

/**
 * 装/卸车扫描————车辆扫描
 */
export const checkVehicle = (data) => {
  return request({
    url: `/bag/checkVehicle?vehicleId=${data.vehicleId}`,
    method: 'get'
  });
};

/**
 * 网点管理——转运中心
 */
export const getTransitCenter = () => {
  return request({
    url: '/site/getTransitCenter',
    method: 'get',
    isLoading: false
  });
};

/**
 * 装车卸车数据监控——票数差异详情
 */
export const voteDiffPage = (data) => {
  return request({
    url: '/tagNumberDatail/sum/loadAndUnLoadOrderDiscrepancyPage',
    method: 'POST',
    data
  });
};

/**
 * 装车卸车数据监控——袋子差异详情
 */
export const bagDiffPage = (data) => {
  return request({
    url: '/tagNumberDatail/sum/loadAndUnLoadBagDiscrepancyPage',
    method: 'POST',
    data
  });
};

/**
 * 装车卸车数据监控——袋子差异详情导出
 */
export const bagDiffPageExport = (data) => {
  return request({
    url: '/tagNumberDatail/sum/loadAndUnLoadBagDiscrepancyPageExport',
    method: 'POST',
    data
  });
};

/**
 * 装车卸车数据监控——票数——明细全部导出
 */
export const carVoteDetailExportAll = (data) => {
  return request({
    url: '/tagNumberDatail/sum/loadAndUnLoadOrderPageExport',
    method: 'POST',
    data
  });
};

/**
 * 装车卸车数据监控——袋数——明细全部导出
 */
export const carDetailExportAll = (data) => {
  return request({
    url: '/tagNumberDatail/sum/loadAndUnLoadBagPageExport',
    method: 'POST',
    data
  });
};

/**
 * 装车卸车数据监控————汇总全部导出
 */
export const carSummaryExportAll = (data) => {
  return request({
    url: '/tagNumberDatail/sum/exportAll',
    method: 'POST',
    data
  });
};

/**
 * 装车卸车数据监控——票数——明细分页
 */
export const collectVehicleVotePage = (data) => {
  return request({
    url: '/tagNumberDatail/sum/loadAndUnLoadOrderPage',
    method: 'POST',
    data
  });
};

/**
 * 装车卸车数据监控——袋数——明细分页
 */
export const collectVehicleScanPage = (data) => {
  return request({
    url: '/tagNumberDatail/sum/loadAndUnLoadBagPage',
    method: 'POST',
    data
  });
};

/**
 * 装车卸车数据监控————所有网点数据
 */
export const queryAllSite = (data) => {
  return request({
    url: '/site/queryAllSite',
    method: 'get',
    data,
    isLoading: false
  });
};

/**
 * 装车卸车数据监控————所有员工数据
 */
export const queryAllUser = (data) => {
  return request({
    url: '/queryAllUser',
    method: 'get',
    data,
    isLoading: false,
    baseURL: baseUserURL
  });
};

/**
 * 装车卸车数据监控————汇总分页
 */
export const carSummaryPage = (data) => {
  return request({
    url: '/tagNumberDatail/sum/page',
    method: 'post',
    data
  });
};

/**
 * 仓对仓揽收订单————全部导出
 */
export const boxExportAll = (data) => {
  return request({
    url: '/box/exportAll',
    method: 'post',
    data
  });
};

/**
 * 仓对仓揽收订单————查看详情
 */
export const boxGoodsDetail = (data) => {
  return request({
    url: '/box/boxGoodsDetail/' + data,
    method: 'GET'
  });
};

/**
 * 仓对仓揽收订单————分页
 */
export const boxPage = (data) => {
  return request({
    url: '/box/boxPage',
    method: 'post',
    data
  });
};
/**
 * 揽收签收————提交——包裹数
 */
export const templateData = (data) => {
  return request({
    url: '/bag/queryCountByBagNumber',
    data,
    method: 'post',
    headers: { 'token': getToken() }
  });
};

/**
 * 揽收签收————签收提交
 */
export const warehouseSign = (data) => {
  return request({
    url: '/collect/warehouseSignPc',
    data,
    method: 'post',
    baseURL,
    headers: { 'token': getToken() }
  });
};

/**
 * 揽收签收————删除大包号数据
 */
export const deletedBagNumber = (data) => {
  return request({
    url: '/collect/deletedBagNumber',
    data,
    method: 'post',
    baseURL,
    headers: { 'token': getToken() }
  });
};

/**
 * @description 揽收扫描——删除大包号数据
 * @param scanId
 * @returns {*}
 */
export const deletedUnForecastOrderScan = (scanId) => {
  return request({
    url: `/collect/deleted/unForecastOrderScan/${scanId}`,
    method: 'get',
    baseURL,
    headers: { 'token': getToken() }
  });
};
/**
 * 揽收签收————校验大包号
 */
export const receiveSignCheckBagNumber = (data) => {
  return request({
    url: '/collect/checkBagNumber',
    data,
    method: 'post',
    baseURL,
    headers: { 'token': getToken() }
  });
};
/**
 * @description 揽收扫描——校验大包号
 * @param data
 * @returns {*}
 */
export const collectUnForecastOrderScan = (data) => {
  return request({
    url: '/collect/unForecastOrderScan',
    data,
    method: 'post',
    baseURL,
    headers: { 'token': getToken() }
  });
};

/**
 * @description 非预报订单签名提交
 * @param data
 * @returns {*}
 */
export const collectSignCommit = (data) => {
  return request({
    url: '/collect/signCommit',
    data,
    method: 'post',
    baseURL,
    headers: { 'token': getToken() }
  });
};
/**
 * 网点管理————拖拽排序
 */
export const sortSiteTree = (data) => {
  return request({
    url: '/site/sortSiteTree',
    method: 'post',
    data,
    isLoading: false
  });
};

/**
 * 巴西时间
 */
export const getFormatServerTime = () => {
  // return request.get('/app/manage/getFormatServerTime');
  return request({
    url: '/app/manage/getFormatServerTime',
    method: 'get',
    isLoading: false
  });
};

/**
 * 揽收数据处理——部分查询导出功能
 */
export const sectionQueryExport = (data) => {
  return request.post('/collection/order/sectionQueryExport', data);
};

/**
 * 揽收数据处理——全部查询导出功能
 */
export const orderExportAll = (data) => {
  return request.post('/collection/order/exportAll', data);
};

/**
 * 揽收数据处理——数据导入
 */
export const importData = (data) => {
  return request.post('/collection/order/importData', data);
};

/**
 * 揽收数据处理——分页——自定义查询
 */
export const queryOrderErrorPage = (data) => {
  // return request.post('/collection/order/queryOrderErrorPage', data);
  return request({
    url: '/collection/order/queryOrderErrorPage',
    data,
    method: 'post'
  });
};

/**
 * 揽收数据处理——分页——固定查询
 */
export const queryOrderErrorAllPage = (data) => {
  // return request.post('/collection/order/queryOrderErrorAllPage', data);
  return request({
    url: '/collection/order/queryOrderErrorAllPage',
    data,
    method: 'post'
  });
};

/**
 * 揽收数据处理——保存栏位权限
 */
export const userExtendSaveData = (data) => {
  return request({
    url: '/userExtend/saveData',
    data,
    method: 'post',
    isLoading: false
  });
};

/**
 * 揽收数据处理——查询栏位权限
 */
export const userExtendQueryExtend = (data) => {
  return request.post('/userExtend/queryExtend', data);
};

/**
 * 报价维护——寄件区域-查询分区邮编-新
 */
export const zipCodeQueryData = (data) => {
  return request.post('/zipCodeDivisionArea/queryData', data);
};
/**
 * 获取短信管理接收手机
 */
export const getMessageSenderPhone = (id) => {
  // return request.get('/messageCustomer/getMessageSenderPhone/' + id);
  return request({
    url: '/messageCustomer/getMessageSenderPhone/' + id,
    method: 'get',
    isLoading: false
  });
};

/**
 * 揽收订单——查询用户/揽收人
 */
export const scanUserList = (data) => {
  return request({
    method: 'post',
    url: '/queryAll',
    data,
    isLoading: false,
    baseURL: baseUserURL
  });
};

/**
 * 揽收日报表——根据站点id查询用户
 */
export const queryByUserId = (id) => {
  return request.get('/user/queryBySiteId/' + id);
};

/**
 * 订单揽收监控报表——商户号查询
 */
export const queryAllMerchantCodeByOrder = (data) => {
  return request.post('/collectOrder/queryAllMerchantCodeByOrder', data);
};

/**
 * 备货揽收订单查看详情——明文
 */
export const stockOrderVO = (data) => {
  return request.get('/stockOrder/stockOrderVO2/' + data);
};

/**
 * 揽收订单查看寄件人详情——明文
 */
export const CollectOrderVO = (data) => {
  return request.get('/collectOrder/collectOrderVO/' + data);
};

/**
 * 寄件区域——分区邮编编辑
 */
export const zipCodeDivisionAreaEdit = (data) => {
  return request.put('/zipCodeDivisionArea/edit', data);
};

/**
 * 寄件区域——分区邮编新增
 */
export const zipCodeDivisionAreaAdd = (data) => {
  return request.post('/zipCodeDivisionArea/add', data);
};

/**
 * 寄件区域——分页分区邮编
 */
export const zipCodeDivisionAreaPage = (data) => {
  return request.post('/zipCodeDivisionArea/page', data);
};

/**
 * 报价维护——删除
 */
export const delCollectCostCalculation = (data) => {
  return request.post('/collectCostCalculation/delete', data);
};

/**
 * 报价维护——试算
 */
export const trial = (data) => {
  return request.post('/collectCostCalculation/trial', data);
};

/**
 * 报价维护——修改
 */
export const collectCostCalculationEdit = (data) => {
  return request.put('/collectCostCalculation/edit' + data.id, data);
};

/**
 * 报价维护——分页查询
 */
export const collectCostCalculationPage = (data) => {
  return request.post('/collectCostCalculation/page', data);
};

/**
 * 报价维护——新增
 */
export const collectCostCalculation = (data) => {
  return request.post('/collectCostCalculation/add', data);
};

/**
 * 报价维护——查询分区邮编
 */
export const zipCodeTreeStructure = (data) => {
  return request.post('/zipCodeDivision/zipCodeTreeStructure', data);
  // return request({
  //   method: 'POST',
  //   url: '/zipCodeDivision/zipCodeTreeStructure',
  //   headers: { 'token': getToken() }
  // });
};

/**
 * 轨迹查询——查询轨迹
 */
export const trackQuery = (data) => {
  return request.post('/collect/track/query', data);
};

/**
 * 账单推送——将修改后的数据入库
 */
export const billUploadExcelMsg = (data) => {
  return request.post('/bill/uploadExcelMsg', data);
};

/**
 * 账单推送——解析excel内容
 */
export const billImport = (data) => {
  // return request.post('/bill/import', data);
  return request({
    method: 'POST',
    url: '/bill/import',
    data
    // headers: { 'content-type': 'multipart/form-data; boundary=something' }
  });
};

/**
 * 账单推送——揽收运费账单批量推送
 */
export const billOrderSuppPush = (data) => {
  return request({
    method: 'POST',
    url: '/billOrder/suppPush',
    data,
    baseURL,
    headers: { 'token': getToken() }
  });
};

/**
 * 账单推送——批量导出
 */
export const billBatchExport = (data) => {
  return request.post('/bill/batchExport', data);
};

/**
 * 账单推送——导出
 */
export const billExport = (data) => {
  return request.post('/bill/export', data);
};

/**
 * 账单推送——分页查询
 */
export const billPage = (data) => {
  return request.post('/bill/page', data);
};

/**
 * 邮编分区——导出
 */
export const zipCodeDivisionExport = (data) => {
  return request.post('/zipCodeDivision/export', data);
};

/**
 * 邮编分区——删除
 */
export const zipCodeDivisionDel = (data) => {
  return request.delete('/zipCodeDivision/delete/' + data);
};

/**
 * 邮编分区——修改
 */
export const zipCodeDivisionEdit = (data) => {
  return request.put('/zipCodeDivision/edit', data);
};

/**
 * 邮编分区——新增
 */
export const zipCodeDivisionAdd = (data) => {
  return request.post('/zipCodeDivision/add', data);
};

/**
 * 邮编分区——查询所有数据(省份下拉)
 */
export const provinceQueryAll = (data) => {
  return request.post('/zipCodeDivision/queryZipCode', data);
};

/**
 * 邮编分区——分页查询
 */
export const zipCodeDivision = (data) => {
  return request.post('/zipCodeDivision/page', data);
};

/**
 * 备货订单全部导出
 */
export const stockOrderAllExport = (data) => {
  return request.post('/stockOrder/exportAll', data);
};

/**
 * 备货订单导出
 */
export const stockOrderExport = (data) => {
  return request.post('/stockOrder/export', data);
};

/**
 * 备货揽收详情分页查询
 */
export const querySubDetailedPage = (data) => {
  return request.post('/stockOrder/querySubDetailedPage', data);
};

/**
 * 备货订单分页查询
 */
export const stockOrderPage = (data) => {
  return request.post('/stockOrder/queryPage', data);
};

/**
 * 根据供应商/业务主体id获取服务商渠道信息
 */
export const getProviderChannel = (data) => {
  return request.get('/site/getChannel/' + data);
};

/**
 * 根据业务主体id返回经营模式
 */
export const getbusinessModel = (data) => {
  return request.get('/site/getbusinessModel?providerId=' + data);
};

/**
 * 查看用户的供应商 / 业务主体
 */
export const providerList = (data) => {
  return request.get('/provider/list', data);
};

/**
 * 获取网点结构树
 */
export const getSiteTree = (data) => {
  return request.post('/site/getSiteTree', data);
};

/**
 * 条件查询网点
 */
export const queryFind = (data) => {
  return request.post('/site/query/condition', data);
};

/**
 * 查看单个网点详情
 */
export const siteQuery = (data) => {
  return request.get('/site/' + data.id);
};

/**
 * 校验员工编号
 */
export const checkCodeUnique = (data) => {
  return request.get('/collectDriver/checkCodeUnique/' + data);
};

/**
 * 车签号——打印
 */
export const printTagNumber = (data) => {
  return request({
    method: 'POST',
    url: '/collectTagNumber/printTagNumber',
    data,
    baseAdminUrl,
    headers: { 'token': getToken() }
  });
};
/**
 * 车签号——生成二维码
 */
export const createTagNumber = (data) => {
  return request({
    method: 'POST',
    url: '/collectTagNumber/createTagNumber',
    data,
    baseAdminUrl,
    headers: { 'token': getToken() }
  });
};
/**
 * 车签号明细查询
 */
export const collectTagNumberDatailPage = (data) => {
  return request.post('/collectTagNumberDetail/page', data);
};
/**
 * 车签号汇总查询
 */
export const collectTagNumberPage = (data) => {
  return request.post('/collectTagNumber/page', data);
};
/**
 * 车辆信息——二维码下载
 */
export const printVehicleCodeData = (data) => {
  return request({
    method: 'POST',
    url: '/printVehicleCodeData',
    data,
    baseURL,
    headers: { 'token': getToken() }
  });
};
/**
 * 车辆信息——二维码下载
 */
export const printVehicleData = (data) => {
  return request({
    method: 'POST',
    url: '/printVehicleData',
    data,
    baseURL,
    headers: { 'token': getToken() }
  });
};
/**
 * 车辆信息——添加
 */
export const collectVehicleEdit = (data) => {
  return request.put('/collectVehicle/edit', data);
};
/**
 * 车辆信息——添加
 */
export const collectVehicleAdd = (data) => {
  return request.post('/collectVehicle/add', data);
};
/**
 * 车辆信息——分页
 */
export const collectVehiclePage = (data) => {
  return request.post('/collectVehicle/page', data);
};
/**
 * 揽收日报表——全部导出——汇总
 */
export const queryReportGatherExportAll = (data) => {
  return request.post('/siteCollectDaily/totalExportAll', data);
};

/**
 * 揽收日报表——全部导出——明细
 */
export const queryReportDetaileExportAll = (data) => {
  return request.post('/siteCollectDaily/detailExportAll', data);
};
/**
 * 揽收日报表——明细-导出
 */
export const queryReportDetailExport = (data) => {
  return request.post('/collectOrder/queryReportDetailExport', data);
};
/**
 * 揽收日报表——明细分页
 */
export const queryReportDetailePage = (data) => {
  return request.post('/siteCollectDaily/detailPage', data);
};
/**
 * 揽收日报表——汇总分页
 */
export const queryCollectDailyReportPage = (data) => {
  return request.post('siteCollectDaily/page', data);
};
/**
 * 签收时效报表统计——汇总——按单号查询分页
 */
export const queryDetailedPage = (data) => {
  return request.post('/collectCountReporte/findListOrderNumberPage', data);
};
/**
 * 签收时效报表统计—按时间—明细导出
 */
export const timePageExport = (data) => {
  return request.post('/collectCountReporteDetailed/export', data);
};
/**
 * 签收时效报表统计——明细——按单号查询分页
 */
export const findNumberPage = (data) => {
  return request.post('/collectCountReporteDetailed/findNumberPage', data);
};
/**
 * 签收时效报表统计—汇总——全部导出(时间)
 */
export const collectCountReporteExportAll = (data) => {
  return request.post('/collectCountReporte/exportAll', data);
};
/**
 * 签收时效报表统计—汇总——全部导出(单号)
 */
export const exportAllByNumber = (data) => {
  return request.post('/collectCountReporte/exportAllByNumber', data);
};
/**
 * 签收时效报表统计—按时间——明细—全部导出
 */
export const TimeMXExportAll = (data) => {
  return request.post('/collectCountReporteDetailed/exportAll', data);
};
/**
 * 签收时效报表统计—按单号——明细—全部导出
 */
export const numberExportAll = (data) => {
  return request.post('/collectCountReporteDetailed/numberExportAll', data);
};
/**
 * 签收时效报表统计—按单号—明细导出
 */
export const queryDetailedPageExport = (data) => {
  return request.post('/collectCountReporteDetailed/numberExport', data);
};
/**
 * 签收时效报表统计——汇总——明细查询
 */
export const signingTimeFindListOrderNumberPage = (data) => {
  return request.post('/collectCountReporteDetailed/queryDetailedPage', data);
};
/**
 * 签收时效报表统计——汇总——按时间查询分页
 */
export const collectCountReportePage = (data) => {
  return request.post('/collectCountReporte/queryPage', data);
};
/**
 * 商户管理——分页
 */
export const customerMerchantPage = (data) => {
  return request.post('/customerMerchant/page', data);
};
/**
 * 商户管理——新增
 */
export const customerMerchantAdd = (data) => {
  return request.post('/customerMerchant/add', data);
};
/**
 * 商户管理——修改
 */
export const customerMerchantUpdate = (data) => {
  return request.put('/customerMerchant/edit/' + data.id, data);
};
/**
 * 本地发送短信服务————本地发送短信服务
 */
export const sendSms = (data) => {
  return request.post('/messageCustomer/sendSms', data);
};
/**
 * 本地发送短信服务————修改揽收包裹总数
 */
export const updatePackageCount = (data) => {
  return request.post('/messageCustomer/updatePackageCount', data);
};
/**
 * 问题件类型
 */
export const getAllProblemType = (data) => {
  return request({
    url: '/collectProblem/getAllProblemType?problemItemType=' + data,
    method: 'get',
    isLoading: false
  });
};
/**
 * 导出订单列表
 */
export const collectOrderExportAll = (data) => {
  return request.post('/excel/collectOrder/exportAll', data);
};
/**
 * 根据站点id查询司机
 */
export const queryBySiteId = (id) => {
  return request.get('/collectDriver/queryBySiteId/' + id);
};
/**
 * 揽收单分配司机
 */
export const collectOrder = (data) => {
  return request.post('/collectOrder/batchUpdate', data);
};
/**
 * 查询可用站点
 */
export const searchSiteIdList = () => {
  return request({
    url: '/site/queryAll',
    method: 'get',
    isLoading: false
  });
};
// 查询所有站点，包括未启用的
export const searchSitequeryAllSite = () => {
  return request({
    url: '/site/queryAllSite',
    method: 'get',
    isLoading: false
  });
};
/**
 * @description 查询所有站点
 * @returns {*}
 */
export const searchQueryWholeSite = () => {
  return request({
    url: '/site/queryWholeSite',
    method: 'get',
    isLoading: false
  });
};
/**
 * 站点分页查询
 */
export const sitePage = (data) => {
  return request.post('/site/page', data);
};

/**
 * 添加站点
 */
export const siteAdd = (data) => {
  return request.post('/site/add', data);
};
/**
 * 修改站点
 */
export const siteEdit = (data) => {
  return request.put('/site/update', data);
};
/**
 * 删除站点
 */
export const siteDel = (id) => {
  return request.delete('/site/' + id);
};

/**
 * 司机分页查询
 */
export const driverPage = (data) => {
  return request.post('/collectDriver/page', data);
};
/**
 * 添加司机
 */
export const driverAdd = (data) => {
  return request.post('/collectDriver/add', data);
};
/**
 * 修改司机
 */
export const driverEdit = (data) => {
  return request.put('/collectDriver/update', data);
};
/**
 * 删除司机
 */
export const driverDel = (id) => {
  return request.delete('/collectDriver/' + id);
};
/**
 * 批量重置密码
 */
export const resetPwd = (date) => {
  return request.post('/collectDriver/resetPassword', date);
};

/**
 * 揽收订单
 */
export const collectOrderPage = (date) => {
  return request.post('/collectOrder/page', date);
};
/**
 * 取消调度司机
 */
export const cancelDriver = (data) => {
  return request({
    method: 'POST',
    url: '/collectOrder/cancellationList',
    data,
    headers: { 'token': getToken() }
  });
};
/**
 * 调度司机
 */
export const schedulingDriver = (date) => {
  return request.post('/collectOrder/batchUpdate', date);
};

/**
 * 根据站点查询司机
 */
export const collectDriverQueryBySiteId = (id) => {
  return request.get('/collectDriver/queryBySiteId/' + id);
};

/**
 * 揽收清单
 */
export const collectListPage = (date) => {
  return request.post('/collectList/page', date);
};

/**
 * 揽收清单——作废
 */
export const collectListCancellation = (date) => {
  return request.post('/collectList/cancellation', date);
};

/**
 * 揽收订单——查询所有司机
 */
export const driverAll = (date) => {
  return request.get('/collectDriver/queryAll', date);
};

/**
 * 查询袋号是否存在
 */
export const queryBagged = (date) => {
  return request.post('/collectOrder/queryBagged', date);
};
/**
 * 客户上门交件——封袋扫描——确认订单
 */
export const generateConfirmatList = (data) => {
  return request({
    method: 'POST',
    // url: '/collection/generateConfirmatList',
    url: '/collection/updateOrderBagging',
    data,
    baseURL,
    headers: { 'token': getToken() }
  });
};

/**
 * 客户上门交件——确认订单
 */
export const createConfirmedList = (data) => {
  return request({
    method: 'POST',
    url: '/generateConfirmatList',
    data,
    baseURL,
    headers: { 'token': getToken() }
  });
};

/**
 * 客户上门交件——查询客户信息
 */
export const collectQueryCustomer = (date) => {
  return request.post('/customer/collectQueryCustomer', date);
};
/**
 * 上门交件查询
 */
export const doorToDoorDeliveryqueryPage = (data) => {
  return request({
    method: 'POST',
    url: '/collectList/queryPage',
    data,
    baseURL: baseURLAdmin
  });
};
/**
 * 查询客户下的包号是否存在
 */
export const queryCustomerPackage = (data) => {
  return request({
    method: 'POST',
    url: '/queryCustomerPackage',
    data,
    baseURL: requestCollectOrder,
    headers: { 'token': getToken() }
  });
};
/**
 * 更新打印次数
 */
export const updatePrintTimes = (date) => {
  return request.post('/collectList/edit', date);
};
/**
 * 上门交件导出
 */
export const deliveryExportList = (date) => {
  return request.post('/collectList/deliveryExportList', date);
};
/**
 * 揽收清单导出
 */
export const listExport = (date) => {
  return request.post('/collectList/listExport', date);
};
/**
 * 揽收订单导出
 */
export const orderExport = (date) => {
  return request.post('/collectOrder/export', date);
};
/**
 * 揽收订单——揽收人联想查询
 */
export const queryCollectMan = (date, mixed = false) => {
  // return request.get('/collectOrder/queryCollectMan/' + date, mixed, { isLoading: false });
  return request({
    method: 'get',
    url: '/collectOrder/queryCollectMan/' + date,
    params: { mixed },
    isLoading: false
  });
};

/**
 * 揽收订单——揽收人联想查询
 */
export const apiQueryCollectManNew = (data) => {
  return request({
    method: 'get',
    url: '/fa/common/collectOrder/queryCollectManNew',
    params: { param: data },
    isLoading: false
  });
};

/**
 * 揽收订单——签收人联想查询
 */
export const queryDriver = (date) => {
  return request.get('/collectOrder/queryDriver/' + date);
};
/**
 * 司机导出
 */
export const driverExport = (date) => {
  return request.post('/collectDriver/export', date);
};
/**
 * 网点全部导出
 */
export const siteExport = (date) => {
  return request.post('/site/export', date);
};
/**
 * 仓库签收查询
 */
export const bagPageQuery = (date) => {
  return request.post('/bag/pageQuery', date);
};
/**
 * 问题件登记
 */
export const problemRegistration = (data) => {
  return request({
    method: 'POST',
    url: '/collection/problemRegistration',
    data,
    baseURL,
    headers: { 'token': getToken() }
  });
};

export const problemRecovery = (data) => {
  return request({
    method: 'POST',
    url: '/problemRecovery',
    data,
    baseURL,
    headers: { 'token': getToken() }
  });
};

/**
 * 问题件登记查询
 */
export const collectProblemPage = (data) => {
  return request.post('/collectProblem/page', data);
};
/**
 * 揽收订单——查询客户信息
 */
export const queryAllCustomer = () => {
  // return request.get('/customer/queryAll');
  return request({
    method: 'GET',
    url: '/customer/queryAll',
    headers: { 'token': getToken() },
    isLoading: false
  });
};
/**
 * 上门交件明细查询服务 ——————点击票数查看明细
 */
export const onSiteDeliveryDetailedPage = (date) => {
  return request.post('/collectOrder/onSiteDeliveryDetailed', date);
};
/**
 * 查询打印包裹明细服务
 */
export const queryPrintDetailed = (data) => {
  return request({
    method: 'POST',
    url: '/collectOrder/queryPrintDetailed',
    data,
    baseURL: baseURLAdmin
  });
};
/**
 * 打印——站点
 */
export const getSiteId = (id) => {
  return request.get('/site/' + id);
};
/**
 * 商户号查询分页
 */
export const queryAllMerchantCode = (data) => {
  return request.post('/collectOrder/queryAllMerchantCode', data);
};

/**
 * 商户号查询All
 */
export const queryMerchantCodeAll = (data) => {
  return request.post('/fa/common/queryAllMerchantCode', data);
};

// 订单揽收监控报表
/**
 * 订单汇总分页查询——按时间
 */
export const collectOrderCountQueryPage = (data) => {
  return request.post('/collectOrderCount/queryOrderCountPage', data);
};
/**
 * 订单明细——全部导出
 */
export const findListOrderDetailExportAll = (data) => {
  return request.post('/collectOrder/findListOrderDetailExportAll', data);
};

/**
 * 订单汇总——全部导出——单号
 */
export const findGatherExportAll = (data) => {
  return request.post('/collectOrderCount/findNumGatherExportAll', data);
};

/**
 * 订单汇总——全部导出——时间
 */
export const exportAll = (data) => {
  return request.post('/collectOrderCount/exportAll', data);
};

/**
 * 订单汇总导出
 */
export const collectOrderCountExport = (data) => {
  return request.post('/collectOrderCount/export', data);
};
/**
 * 订单汇总明细分页查询
 */
export const collectOrderCountQueryDetailPage = (data) => {
  return request.post('/collectOrder/findListOrderDetailPage', data);
};
/**
 * 订单明细导出
 */
export const findListOrderDetailExport = (data) => {
  return request.post('/collectOrder/findListOrderDetailExport', data);
};
/**
 * 订单单号分页查询——按单号
 */
export const findListOrderNumberPage = (data) => {
  return request.post('/collectOrder/findListOrderNumberPage', data);
};
/**
 * 订单单号导出
 */
export const listOrderNumberExport = (data) => {
  return request.post('/collectOrder/listOrderNumberExport', data);
};
/**
 * 轨迹推送分页查询
 */
export const mqMsgLogPage = (data) => {
  return request.post('/collect/track/push/page', data);
};

/**
 * 轨迹重推服务
 */
export const trackRepush = (data) => {
  return request({
    method: 'POST',
    url: '/collection/trackRepush',
    data,
    baseURL,
    headers: { 'token': getToken() }
  });
};

/**
 * 根据用户id查询站点
 */
export const userFindByUser = (userId) => {
  return request({
    method: 'GET',
    url: '/findByUser/' + userId,
    baseURL: baseUserURL
  });
};

/**
 * 上门交件扫描——删除缓存
 */
export const reidsDeleteCustomerPackage = (data) => {
  return request.post('/collectOrder/reidsDeleteCustomerPackage', data);
};

/**
 * 短信分页查询
 */
export const messageCustomerPage = (data) => {
  return request.post('/messageCustomer/page', data);
};

/**
 * 短信导出
 */
export const exportMessageList = (data) => {
  return request.post('/messageCustomer/exportMessageList', data);
};

/**
 * @description 非预报订单扫描分页查询
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const unForecastOrderPage = (data) => {
  return request.post('/unForecastOrder/page', data);
};

/**
 * @description 非预报订单扫描导出
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const unForecastOrderExportAll = (data) => {
  return request.post('/unForecastOrder/exportAll', data);
};

/**
 * @description 非预报揽收订单扫描记录查询
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const unForecastOrderScan = (data) => {
  return request.get(`/unForecastOrder/scan?userId=${data}`);
};

/**
 * @description 车辆使用统计查询列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const vehicleUsageStatisticsPage = (data) => {
  return request.post('/collectVehicle/vehicleUsageStatistics/page', data);
};

export const collectVehicleExportAll = (data) => {
  return request.post('/collectVehicle/exportAll', data);
};

/**
 * @description 查询所有网点
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const siteQueryOwnSite = (data) => {
  return request.get('/site/queryOwnSite', data);
};
// 导出全部商户
export const customerMerchantExportAll = (data) => {
  return request.post('/customerMerchant/exportAll', data);
};

/**
 * @description 大包裹揽收扫描
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const scanInfoPcCollectionScan = (data) => {
  return request.get(`/collectOrderScan/scanInfo/pcCollectionScan/${data}`);
};
/**
 * @description 大包裹揽收扫描删除扫描记录
 * @param data
 * @returns {AxiosPromise}
 */
export const scanInfoDeletedScan = (data) => {
  return request({
    url: `/collectOrderScan/scanInfo/deletedScan/${data}`,
    method: 'delete'
  });
};

/**
 * @description 大包裹揽收查询扫描记录
 * @param data
 * @returns {*}
 */
export const scanInfoQueryScanLog = () => {
  return request({
    url: '/collectOrderScan/scanInfo/queryScanLog/18/1',
    method: 'get'
  });
};

/**
 * @description 大包裹揽收签名上传
 * @param data
 * @returns {AxiosPromise}
 */
export const collectPcSmallSellerSubmit = (data) => {
  return request({
    url: '/collect/pcSmallSellerSubmit',
    method: 'post',
    data,
    baseURL
  });
};

/**
 * @description 封袋扫描，扫描接口
 * @param data
 * @returns {AxiosPromise}
 */
export const collectOrderScanScanInfoPcSealABag = (data) => {
  return request({
    url: '/collectOrderScan/scanInfo/pcSealABag',
    method: 'post',
    data
  });
};

/**
 * @description 封袋扫描，查询扫描记录
 * @returns {AxiosPromise}
 */
export const collectOrderScanScanInfoQueryScanLog = (type1, type2) => {
  return request({
    url: `/collectOrderScan/scanInfo/queryScanLog/${type1}/${type2}`,
    method: 'get'
  });
};

/**
 * @description 封袋扫描，删除扫描记录
 * @param data
 * @returns {AxiosPromise}
 */
export const collectOrderScanScanInfoDeletedScan = (data) => {
  return request({
    url: `/collectOrderScan/scanInfo/deletedScan/${data}`,
    method: 'delete'
  });
};

/**
 * @description 封袋扫描，提交
 * @returns {AxiosPromise}
 */
export const collectPcSealABagSubmit = (data) => {
  return request({
    url: '/collect/pcSealABagSubmit',
    method: 'post',
    data,
    baseURL
  });
};

/**
 * @description 封袋扫描，查询大包号
 * @param data
 * @returns {AxiosPromise}
 */
export const bagPcScanSealABagNumber = (data) => {
  return request({
    url: `/bag/pcScanSealABagNumber/${data}`,
    method: 'get'
  });
};

/**
 * @description 揽收码导出
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const collectOrderCutOffCodeExport = (data) => {
  return request({
    url: '/collectOrder/cutOffCodeExport',
    method: 'post',
    data
  });
};

/**
 * @description 获取网点星期数据
 * @param data
 * @returns {AxiosPromise}
 */
export const siteBusinessTimeGetAllBusinessWeek = () => {
  return request({
    url: '/siteBusinessTime/getAllBusinessWeek',
    method: 'get'
  });
};
// 疑似丢失汇总数据导出
export const suspectedLostSummaryExport = (data) => {
  return request({
    url: '/operationDashboardData/suspectedLostSummaryExport',
    method: 'post',
    data
  });
};
// 疑似丢失订单数据导出
export const suspectedLostOrderExport = (data) => {
  return request({
    url: '/operationDashboardData/suspectedLostOrderExport',
    method: 'post',
    data
  });
};
// 获取GLP未卸车车明细数据分页
export const getGlpNotUnloadVehicleDetailPage = (data) => {
  return request({
    url: '/operationDashboardData/getGlpNotUnloadVehicleDetailPage',
    method: 'post',
    data
  });
};
// 获取GLP未卸车车明细数据导出
export const glpNotUnloadVehicleDetailExport = (data) => {
  return request({
    url: '/operationDashboardData/glpNotUnloadVehicleDetailExport',
    method: 'post',
    data
  });
};
// 获取GLP未卸车袋明细数据分页
export const getGlpNotUnloadBagDetailPage = (data) => {
  return request({
    url: '/operationDashboardData/getGlpNotUnloadBagDetailPage',
    method: 'post',
    data
  });
};
// 获取GLP未卸车袋明细数据导出
export const glpNotUnloadBagDetailExport = (data) => {
  return request({
    url: '/operationDashboardData/glpNotUnloadBagDetailExport',
    method: 'post',
    data
  });
};
// 获取GLP未卸车订单明细数据分页
export const getGlpNotUnloadOrderDetailPage = (data) => {
  return request({
    url: '/operationDashboardData/getGlpNotUnloadOrderDetailPage',
    method: 'post',
    data
  });
};
// 获取GLP未卸车订单明细数据导出
export const glpNotUnloadOrderDetailExport = (data) => {
  return request({
    url: '/operationDashboardData/glpNotUnloadOrderDetailExport',
    method: 'post',
    data
  });
};
// 准时率汇总导出
export const punctualSummaryDataExport = (data) => {
  return request({
    url: '/operationDashboardData/punctualSummaryDataExport',
    method: 'post',
    data
  });
};
// 准时率明细导出
export const punctualOrderDataExport = (data) => {
  return request({
    url: '/operationDashboardData/punctualOrderDataExport',
    method: 'post',
    data
  });
};
