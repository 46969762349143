var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warp" },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "auto" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-descriptions",
                { attrs: { colon: false, column: 3, direction: "vertical" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.RecipientName") } },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _vm.showDetail
                          ? _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.encryptionReceiverInfo.receiveName ||
                                      "--"
                                  )
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.receiverInfo.receiveName || "--")
                                ),
                              ]
                            ),
                        _vm.receiverInfo.receiveName
                          ? _c(
                              "span",
                              {
                                staticClass: "iconEye",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerhidden("name")
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  { attrs: { placement: "top-start" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "GlobalSetObj.completionInformation"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "iconEye" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.RecipientCompany") } },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _vm.showDetail5
                          ? _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.encryptionReceiverInfo
                                        .receiveCompany || "--"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.receiverInfo.receiveCompany || "--"
                                  )
                                ),
                              ]
                            ),
                        _vm.receiverInfo.receiveCompany
                          ? _c(
                              "span",
                              {
                                staticClass: "iconEye",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerhidden("receiveCompany")
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  { attrs: { placement: "top-start" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "GlobalSetObj.completionInformation"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "iconEye" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.Recipientphone") } },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _vm.showDetail1
                          ? _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.encryptionReceiverInfo.receiveMobile ||
                                      "--"
                                  )
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.receiverInfo.receiveMobile || "--")
                                ),
                              ]
                            ),
                        _vm.receiverInfo.receiveMobile
                          ? _c(
                              "span",
                              {
                                staticClass: "iconEye",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerhidden("receivePhone")
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  { attrs: { placement: "top-start" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "GlobalSetObj.completionInformation"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "iconEye" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.Street1") } },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.receiverInfo.receiveStreet || "--")),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.doorNumber") } },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.receiverInfo.receiveHouseNumber || "--")
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.RecipientDistrict") } },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.receiverInfo.receiveArea || "--")),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.RecipientCity") } },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.receiverInfo.receiveCity || "--")),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.RecipientState") } },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.receiverInfo.receiveState || "--")),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.RecipientZipCode") } },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.receiverInfo.receiveZipcode || "--")),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { colon: false, column: 1, direction: "vertical" } },
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.recipientComplement"),
                      },
                    },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _vm.showDetail7
                          ? _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.encryptionReceiverInfo
                                        .receiveComplement || "--"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.receiverInfo.receiveComplement || "--"
                                  )
                                ),
                              ]
                            ),
                        _vm.receiverInfo.receiveComplement
                          ? _c(
                              "span",
                              {
                                staticClass: "iconEye",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerhidden(
                                      "receiveComplement"
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  { attrs: { placement: "top-start" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "GlobalSetObj.completionInformation"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "iconEye" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { colon: false, column: 3, direction: "vertical" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.RecipientCPFCNPJ") } },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _vm.showDetail4
                          ? _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.encryptionReceiverInfo.receiveTax ||
                                        "--"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.receiverInfo.receiveTax || "--")
                                ),
                              ]
                            ),
                        _vm.receiverInfo.receiveTax
                          ? _c(
                              "span",
                              {
                                staticClass: "iconEye",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerhidden("receiveTax")
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  { attrs: { placement: "top-start" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "GlobalSetObj.completionInformation"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "iconEye" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: _vm.$t(
                          "GlobalSetObj.NationalRegistrationNumber"
                        ),
                      },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.receiverInfo.receiveIeNumber || "--")
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { colon: false, column: 1, direction: "vertical" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.ReceivingAddress") } },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _vm.showDetail6
                          ? _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.encryptionReceiverInfo
                                        .receiveAddress || "--"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.receiverInfo.receiveAddress || "--"
                                  )
                                ),
                              ]
                            ),
                        _vm.receiverInfo.receiveAddress
                          ? _c(
                              "span",
                              {
                                staticClass: "iconEye",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerhidden("receiveAddress")
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  { attrs: { placement: "top-start" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "GlobalSetObj.completionInformation"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "iconEye" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { colon: false, column: 3, direction: "vertical" } },
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: { label: _vm.$t("newOrder.Recipientphonenumber") },
                    },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _vm.showDetail2
                          ? _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.encryptionReceiverInfo.receivePhone
                                      ? _vm.encryptionReceiverInfo.receivePhone
                                      : _vm.encryptionReceiverInfo
                                          .receiveMobile || "--"
                                  )
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.receiverInfo.receivePhone
                                      ? _vm.receiverInfo.receivePhone
                                      : _vm.receiverInfo.receiveMobile || "--"
                                  )
                                ),
                              ]
                            ),
                        _vm.receiverInfo.receivePhone ||
                        _vm.receiverInfo.receiveMobile
                          ? _c(
                              "span",
                              {
                                staticClass: "iconEye",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerhidden("receiveMobile")
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  { attrs: { placement: "top-start" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "GlobalSetObj.completionInformation"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "iconEye" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: _vm.$t("newOrder.RecipientEmail") } },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _vm.showDetail3
                          ? _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.encryptionReceiverInfo.receiveMail ||
                                      "--"
                                  )
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.receiverInfo.receiveMail || "--")
                                ),
                              ]
                            ),
                        _vm.receiverInfo.receiveMail
                          ? _c(
                              "span",
                              {
                                staticClass: "iconEye",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerhidden("receiveMail")
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  { attrs: { placement: "top-start" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "GlobalSetObj.completionInformation"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "iconEye" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }