<template>
  <div class="TABLE_LIST">
    <virtual-scroll
      ref="virtualScroll"
      :data="tableData"
      :item-size="62"
      key-prop="id"
      @change="(virtualList) => list = virtualList"
    >
      <el-table
        ref="table"
        border
        :data="list"
        class="table-height"
        :height="tableHeightRow.height"
        row-key="id"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <virtual-column width="50" type="selection" />
        <virtual-column :label="$t('GlobalSetObj.serial')" width="50" type="index" />
        <el-table-column
          v-for="item in tableHeader"
          :key="item.prop"
          :align="item.fixed"
          :label="item.label"
          :min-width="item.width"
          :prop="item.prop"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div
              v-if="item.prop === 'waybillNumber'"
              style="color: #409eff;cursor: pointer;"
              @click="editFee(scope.row)"
            >{{ scope.row[item.prop] }}</div>

            <div v-else-if="item.prop === 'businessType'">
              {{ BUSINESS_TYPE_TWO[scope.row[item.prop]] && BUSINESS_TYPE_TWO[scope.row[item.prop]].label || '' }}
            </div>

            <div v-else-if="item.prop === 'collectType'">{{ scope.row.collectTypeStr }}</div>

            <div v-else>{{ scope.row[item.prop] }}</div>
          </template>
        </el-table-column>

        <el-table-column
          v-for="item in currentColumnsArr"
          :key="item.key"
          :label="item.label"
          :prop="item.prop"
          :show-overflow-tooltip="true"
          :render-header="renderHeader"
        />
      </el-table>
    </virtual-scroll>

    <!-- 分页工具 -->
    <div class="pagination">
      <el-pagination
        :current-page.sync="queryForm.pageNumber"
        :page-size.sync="queryForm.pageSize"
        :page-sizes="[100, 200, 500, 1000]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0"
        @current-change="onCurrentChange"
        @size-change="onSizeChange"
      />
    </div>
  </div>
</template>

<script>
import VirtualScroll, { VirtualColumn } from 'el-table-virtual-scroll';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { COLLECT_TYPE } from '@/views/finance/enums.js';
import { BUSINESS_TYPE_TWO } from '@/views/finance/utils.js';
import { getCostSiteExpensePage } from '@/api/finance/costManagement';
import { cloneDeep } from 'lodash-es';

export default {
  components: {
    VirtualScroll,
    VirtualColumn
  },

  mixins: [estimateTableHeight],

  props: {
    // 搜索条件
    queryForm: {
      type: Object,
      required: true
    },

    // 列表需要展示的列
    tableHeader: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      BUSINESS_TYPE_TWO,
      isSearch: false,
      tableData: [], // 总数据
      list: [],
      currentColumnsArr: [], // 扩展列
      total: 0
    };
  },

  computed: {
  },

  methods: {
    // 搜索
    onSearch() {
      this.isSearch = false;
      this.queryForm.pageNumber = 1;
      this.asyncGetList();
    },

    // 刷新
    onRefresh() {
      if (this.tableData.length === 0) {
        return;
      }
      this.asyncGetList();
    },

    // 多选
    handleSelectionChange(arr) {
      console.log('勾选数据1', arr);
      this.$emit('selection-change', arr);
    },

    // 详情查看
    editFee(row) {
      this.$emit('editFee', row);
    },

    // 翻页
    onCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.asyncGetList();
    },

    // 每页显示数量变化
    onSizeChange(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.asyncGetList();
    },

    /**
     * 获取列表数据
     */
    asyncGetList() {
      const queryForm = cloneDeep(this.queryForm);

      getCostSiteExpensePage(queryForm).then(res => {
        if (res.code === 200) {
          const { data, total, columns } = res;
          this.total = total;
          if (data.length > 0) {
            const { list, currentColumnsRow } = this.setColumns(columns);
            this.currentColumnsArr = list;
            this.tableData = this.setRowData(data, currentColumnsRow);
          } else {
            this.tableData = [];
            this.$message.warning(this.$t('operatingPlatform.nullMsg'));
          }
        }
      }).catch(err => {
        console.error(err);
      });
    },
    /**
     * 设置动态列数据
     * @param arr
     * @returns {{list: *, currentColumnsRow: {}}}
     */
    setColumns(arr) {
      // console.log(arr, '动态列');
      const currentColumnsRow = {};
      const list = arr.map((item, index) => ({
        prop: item.prop,
        key: item.prop + index,
        label: item.label
      }));

      arr.forEach(el => {
        currentColumnsRow[el.prop] = '';
      });

      return { list, currentColumnsRow };
    },
    /**
     * 设置行数据
     * @param arr
     * @param currentColumnsRow
     * @returns {*}
     */
    setRowData(arr, currentColumnsRow) {
      // 将数组
      return arr.map((item, index) => {
        const {
          id, waybillNumber, customerNumber, siteId,
          providerName, siteName, businessType, collectType,
          chargeWeight, totalFee, remark
        } = item;

        // 揽收类型
        const collectTypeStr = COLLECT_TYPE[collectType]?.label || '';

        // 扩展列
        const row = {
          ...currentColumnsRow
        };
        for (const key in row) {
          // 存在
          if (item[key]) {
            row[key] = item[key];
          }
        }

        return {
          id,
          waybillNumber, // 业务单号
          customerNumber, // 客户编号
          providerName, // 主体
          siteName, // 网点
          siteId, // 网点id
          collectType, // 揽收方式
          collectTypeStr,
          chargeWeight, // 计费重量
          totalFee, // 总费用
          businessType, // 业务类型
          remark, // 备注
          ...row
        };
      });
    },

    /**
     * 重新table中el-columns，防止动态height丢失滚动条
     * @param h { createElement }
     * @param column
     * @param $index
     */
    renderHeader(h, { column, $index }) {
      let realWidth = 0;
      const span = document.createElement('span');
      span.textContent = column.label;
      document.body.appendChild(span);
      realWidth = span.offsetWidth; // 内容宽度+左右padding
      column.minWidth = realWidth + 10;
      document.body.removeChild(span);
      return h('span', column.label);
    }
  }
};
</script>

<style scoped>
/* 设置列宽超出... */
::v-deep .el-table th.el-table__cell > .cell {
  width: 100%;
  text-overflow: clip !important;
  white-space: nowrap !important;
}
</style>
