var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { staticClass: "box-card" }, [
    _c(
      "div",
      { staticStyle: { "margin-bottom": "15px", "font-weight": "bold" } },
      [_vm._v("网点SLA达成")]
    ),
    _c("div", { staticClass: "Liying" }, [
      _c("div", [
        _vm._v(
          _vm._s(_vm.$t("basicData.shouldDelivered")) +
            ": " +
            _vm._s(_vm.onDataV3.shouldDeliveredTotal || 0)
        ),
      ]),
      _c("div", [
        _vm._v(
          _vm._s(_vm.$t("operationTips.singedNumber")) +
            ": " +
            _vm._s(_vm.onDataV3.deliveredTotal || 0)
        ),
      ]),
      _c("div", [
        _vm._v(
          _vm._s(_vm.$t("navbar.unSignCount")) +
            ": " +
            _vm._s(_vm.onDataV3.unsignedTotal || 0)
        ),
      ]),
    ]),
    _c(
      "div",
      {
        staticStyle: {
          height: "340px",
          "overflow-y": "auto",
          "overflow-x": "hidden",
        },
      },
      [
        _c(
          "el-row",
          { attrs: { gutter: 10 } },
          _vm._l(_vm.siteSlaListV3, function (item, index) {
            return _c(
              "el-col",
              { key: index, attrs: { span: 24 } },
              [
                _c("el-col", { attrs: { span: 3 } }, [
                  _c("div", { staticStyle: { "text-align": "right" } }, [
                    _vm._v(_vm._s(item.siteName)),
                  ]),
                ]),
                _c(
                  "el-col",
                  { attrs: { span: 21 } },
                  [
                    _c("el-progress", {
                      staticStyle: { "margin-bottom": "15px" },
                      attrs: {
                        percentage: item.signRate,
                        "stroke-width": 20,
                        "text-inside": true,
                        status: "warning",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }