var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "warpContent" }, [
      _c("div", { staticStyle: { display: "flex" } }, [
        _c("div", [
          _c("span", [_vm._v(_vm._s(_vm.$t("newOrder.waybillNumber")) + ": ")]),
          _c("span", [_vm._v(_vm._s(_vm.rowData.waybillNumber || "--"))]),
        ]),
        _c("div", { staticStyle: { margin: "0 30px" } }, [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("GlobalSetObj.preEntrega")) + ": "),
          ]),
          _c("span", [
            _vm._v(_vm._s(_vm.rowData.preDeliverSiteIdName || "--")),
          ]),
        ]),
        _c("div", [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("orderCenterCont.deliveryPerson")) + ": "),
          ]),
          _c("span", [_vm._v(_vm._s(_vm.rowData.delivererName || "--"))]),
        ]),
      ]),
      _c("div", { staticStyle: { margin: "20px 0" } }, [
        _c("span", [
          _vm._v(_vm._s(_vm.$t("collectionCenter.ReceivingAddress")) + ": "),
        ]),
        _c("span", [_vm._v(_vm._s(_vm.rowData.receiveAddr || "--"))]),
      ]),
      _c("div", [
        _c("span", [
          _vm._v(_vm._s(_vm.$t("orderCenterCont.HouseNumber")) + ": "),
        ]),
        _c("span", [_vm._v(_vm._s(_vm.rowData.receiveHouseNumber || "--"))]),
      ]),
    ]),
    _c("div", { staticClass: "signImageCss" }, [
      _c("div", [_vm._v(_vm._s(_vm.$t("home.signedPhoto")))]),
      _vm.rowData.signedPhoto
        ? _c(
            "div",
            {
              staticClass: "demo-image__preview",
              staticStyle: { "z-index": "9999" },
            },
            _vm._l(_vm.rowData.signedPhoto, function (item1, index1) {
              return _c("el-image", {
                key: index1,
                staticStyle: { width: "100px", "margin-right": "5px" },
                attrs: {
                  "preview-src-list": [item1],
                  src: item1,
                  fit: "contain",
                },
              })
            }),
            1
          )
        : _c("div", [_vm._v("--")]),
    ]),
    _c("div", { staticClass: "signImageCss" }, [
      _c("div", [_vm._v(_vm._s(_vm.$t("home.signedNamePhoto")))]),
      _vm.rowData.signedNamePhoto
        ? _c(
            "div",
            {
              staticClass: "demo-image__preview",
              staticStyle: { "z-index": "9999" },
            },
            _vm._l(_vm.rowData.signedNamePhoto, function (item2, index2) {
              return _c("el-image", {
                key: index2,
                staticStyle: { width: "100px", "margin-right": "5px" },
                attrs: {
                  "preview-src-list": [item2],
                  src: item2,
                  fit: "contain",
                },
              })
            }),
            1
          )
        : _c("div", [_vm._v("--")]),
    ]),
    _c("div", { staticClass: "warpContent" }, [
      _c(
        "div",
        { staticStyle: { display: "flex", "align-items": "center" } },
        [
          _c("div", { staticStyle: { "margin-right": "15px" } }, [
            _vm._v(_vm._s(_vm.$t("home.appliveResult")) + ":"),
          ]),
          _c(
            "el-radio-group",
            {
              on: { change: _vm.handleAuditsState },
              model: {
                value: _vm.formInline.auditsState,
                callback: function ($$v) {
                  _vm.$set(_vm.formInline, "auditsState", $$v)
                },
                expression: "formInline.auditsState",
              },
            },
            [
              _c("el-radio", { attrs: { label: 1 } }, [
                _vm._v(_vm._s(_vm.$t("home.pass"))),
              ]),
              _vm.rowData.forceType === 1
                ? _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("home.noPass"))),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.rowData.forceType === 1
        ? _c(
            "div",
            { staticStyle: { "margin-left": "165px", "margin-top": "5px" } },
            [
              _vm.formInline.auditsState === 0
                ? _c(
                    "div",
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.formInline.photoStandard,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "photoStandard", $$v)
                            },
                            expression: "formInline.photoStandard",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("home.photoStandard")) + " ")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.formInline.signStandard,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "signStandard", $$v)
                            },
                            expression: "formInline.signStandard",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("home.signStandard")))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "flx-end", staticStyle: { "margin-top": "15px" } },
      [
        _c(
          "el-button",
          {
            staticStyle: { "margin-right": "20px" },
            attrs: { size: "small" },
            on: { click: _vm.cancelConfirm },
          },
          [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")) + " ")]
        ),
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.confirm },
          },
          [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Confirm")) + " ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }