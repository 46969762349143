var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "box-card" },
    [
      _c("div", { staticStyle: { "margin-bottom": "15px" } }, [
        _c(
          "span",
          {
            staticStyle: {
              "margin-bottom": "15px",
              "font-weight": "bold",
              color: "#009743",
            },
          },
          [_vm._v("遗失统计")]
        ),
        _c("span", [_vm._v("(全部丢失/部分丢失/全部被盗/部分被盗)")]),
      ]),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.lostStatisticsVOS,
            "header-cell-style": { color: "black" },
            height: "375px",
            stripe: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("navbar.siteName"),
              align: "center",
              prop: "siteName",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "遗失票数", prop: "lostCount" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.siteId === null
                      ? _c("div", { staticClass: "fontClsBold" }, [
                          _vm._v(
                            " " + _vm._s(scope.row.lostCount || "--") + " "
                          ),
                        ])
                      : _c("div", [
                          _vm._v(_vm._s(scope.row.lostCount || "--")),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "遗失率", prop: "lostRate" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.siteId === null
                      ? _c("div", { staticClass: "fontClsBold" }, [
                          _vm._v(
                            " " + _vm._s(scope.row.lostRate || "--") + " "
                          ),
                        ])
                      : _c("div", [_vm._v(_vm._s(scope.row.lostRate || "--"))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "当月累计遗失票数",
              prop: "lostCountMonth",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.siteId === null
                      ? _c("div", { staticClass: "fontClsBold" }, [
                          _vm._v(
                            " " + _vm._s(scope.row.lostCountMonth || "--") + " "
                          ),
                        ])
                      : _c("div", [
                          _vm._v(_vm._s(scope.row.lostCountMonth || "--")),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "月遗失率",
              prop: "lostRateMonth",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.siteId === null
                      ? _c("div", { staticClass: "fontClsBold" }, [
                          _vm._v(
                            " " + _vm._s(scope.row.lostRateMonth || "--") + " "
                          ),
                        ])
                      : _c("div", [
                          _vm._v(_vm._s(scope.row.lostRateMonth || "--")),
                        ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }