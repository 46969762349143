<template>
  <div class="kanban_content">
    <el-row :gutter="10">
      <el-col :span="24">
        <el-card class="box-card" style="margin-bottom: 10px">
          <el-form :model="queryForm" label-position="top" size="small">
            <el-col :span="6">
              <!--创建时间-->
              <el-form-item :label="$t('newOrder.createTime')">
                <el-date-picker
                  v-model="timeValue"
                  :clearable="true"
                  :default-time="['00:00:00', '23:59:59']"
                  :format="$i18n.locale == 'zh' ? zhDate : ptDate"
                  :placeholder="$t('newOrder.SelectDatetime')"
                  range-separator="~"
                  style="width: 100%"
                  type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                />
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <!--  网点所属州  -->
              <el-form-item :label="$t('basicData.StateOfOutlet')">
                <el-select
                  v-model="queryForm.state"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  clearable
                  filterable
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in StateList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <!--转运中心-->
              <el-form-item :label="$t('collectionCenter.transferCenter')">
                <el-select
                  v-model="queryForm.transferId"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  clearable
                  collapse-tags
                  filterable
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in TransferList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <!--  网点名称  -->
              <el-form-item :label="$t('collectionCenter.siteName')">
                <el-select
                  v-model="queryForm.siteId"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  clearable
                  filterable
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in siteList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="" style="margin-top: 28px">
                <!--搜索-->
                <el-button
                  icon="el-icon-search" type="primary" @click="getTransferKanBan"
                >{{ $t('newOrder.search') }}
                </el-button>
                <!--重置-->
                <el-button
                  icon="el-icon-refresh-left" type="info" @click="resetForm"
                >{{ $t('newOrder.reset') }}
                </el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-card>
      </el-col>
      <el-col :span="7">
        <operationData :loadding="transferKanBanV3.loading" :unloading="transferKanBanV3.unload" />
      </el-col>
      <el-col :span="17" style="position: relative">
        <allocateRetentionData :center-data="retainedPiece" />
      </el-col>
      <el-col :span="18" style="margin-top: 10px; position: relative">
        <slaNumber :key="keyValue" :site-sla-rate-list="siteSlaRateList" />
      </el-col>
      <el-col :span="6" style="margin-top: 10px">
        <onTimeShow :on-data-v3="onDataV3" />
      </el-col>
      <el-col :span="12" style="margin-top: 10px">
        <!--   网点SLA达成     -->
        <ontimeSignData :on-data-v3="onDataV3" :site-sla-list-v3="siteSlaListV3" />
      </el-col>
      <el-col :span="12" style="margin-top: 10px">
        <!--    网点滞留    -->
        <networkRetentionData :stay-tracking-v-o-s="stayTrackingVOS" />
      </el-col>
      <el-col :span="24" style="margin-top: 10px">
        <!--    网点时效    -->
        <networkTimeValidity :key="keyValue" :site-aging-v-o-s="siteAgingVOS" />
      </el-col>
      <el-col :span="24">
        <!--  虚假签收  -->
        <falseSignature
          :key="keyValue"
          :false-signatures-vo="falseSignaturesVo"
          :false-signatures-vo-list="falseSignaturesVoList"
          style="margin-top: 10px"
        />
      </el-col>
      <el-col :span="24" style="margin-top: 10px">
        <!--  轨迹停更监控  -->
        <trackStopMonitoring :tracking-stop-monitor-v-o-s="trackingStopMonitorVOS" />
      </el-col>
      <el-col :span="12" style="margin-top: 10px">
        <!--  遗失统计   -->
        <lossStatistics :lost-statistics-v-o-s="lostStatisticsVOS" />
      </el-col>
      <el-col :span="12" style="margin-top: 10px">
        <!-- 破损统计 -->
        <damageStatistics :worn-statistics-v-o-s="wornStatisticsVOS" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getLgAreaListData,
  getSiteTransfer,
  getTransferDashdownload,
  transferDashboardgetTransFerData
} from '@/api/orderApi';
import { getAllsite } from '@/api/logisticsOrders';
import dayjs from 'dayjs';

export default {
  name: 'Index',
  components: {
    allocateRetentionData: () => import('./component/allocateRetentionData.vue'),
    operationData: () => import('./component/operationData.vue'),
    slaNumber: () => import('./component/slaNumber.vue'),
    onTimeShow: () => import('./component/onTimeShow.vue'),
    ontimeSignData: () => import('./component/ontimeSignData.vue'),
    networkRetentionData: () => import('./component/networkRetentionData.vue'),
    falseSignature: () => import('./component/falseSignature.vue'),
    trackStopMonitoring: () => import('./component/trackStopMonitoring.vue'),
    lossStatistics: () => import('./component/lossStatistics.vue'),
    networkTimeValidity: () => import('./component/networkTimeValidity.vue'),
    damageStatistics: () => import('./component/damageStatistics.vue')
  },
  data() {
    return {
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      queryForm: {
        state: null,
        transferId: null,
        siteId: null
      },
      siteList: [],
      stateValue: '',
      stateTransfer: [],
      StateList: [],
      stateTransferValue: '',
      timeSelect: 1,
      transferKanBanV3: {},
      TransferList: [],
      retainedPiece: {}, // 滞留件
      siteSlaRateList: [],
      onDataV3: {},
      siteSlaListV3: [],
      stayTrackingVOS: [],
      falseSignaturesVo: {}, // 虚假签收
      falseSignaturesVoList: [], // 虚假签收苏
      keyValue: '',
      trackingStopMonitorVOS: [], // 轨迹停更监控
      lostStatisticsVOS: [], // 遗失统计
      siteAgingVOS: [] // 网点时效
    };
  },
  created() {
    this.getTimer();
    this.getStateList();
    this.getstateTransfer();
    this.getTransferKanBan();
    this.getAllSiteList();
  },
  methods: {
    resetForm() {
      this.getTimer();
      this.queryForm = {
        state: null,
        transferId: null,
        siteId: null
      };
      this.getTransferKanBan();
    },
    getTransferKanBan() {
      const data = {
        state: this.queryForm.state,
        transferId: this.queryForm.transferId,
        startTime: '',
        endTime: '',
        siteId: this.queryForm.siteId,
        customerType: 1
      };
      if (this.timeValue) {
        data.startTime = this.timeValue[0];
        data.endTime = this.timeValue[1];
      } else {
        this.$message.error(this.$t('GlobalSetObj.selectTime'));
        return;
      }
      transferDashboardgetTransFerData(data).then((res) => {
        if (res.code === 200) {
          this.transferKanBanV3 = res.data;
          this.retainedPiece = {
            twentyFourHours: res.data.twentyFourHours,
            fortyEightHours: res.data.fortyEightHours,
            seventyTwoHours: res.data.seventyTwoHours,
            sevenDays: res.data.sevenDays,
            fifteenDays: res.data.fifteenDays
          };
          this.siteSlaRateList = res.data.signRateList;
          this.onDataV3 = {
            enRoute: res.data.enRoute,
            inventory: res.data.inventory,
            ajdDispatchingRate: res.data.ajdDispatchingRate,
            dspDispatchingRate: res.data.dspDispatchingRate,
            shouldDeliveredTotal: res.data.shouldDeliveredTotal,
            deliveredTotal: res.data.deliveredTotal,
            unsignedTotal: res.data.unsignedTotal
          };
          this.siteSlaListV3 = res.data.siteDeliveryDataVOS;
          this.stayTrackingVOS = res.data.stayTrackingVOS;
          this.siteAgingVOS = res.data.siteAgingVOS;
          this.falseSignaturesVo = res.data.falseSignaturesVo;
          if (res.data.falseSignaturesVo.siteAndFalseSignVoList) {
            this.falseSignaturesVoList = res.data.falseSignaturesVo.siteAndFalseSignVoList;
          } else {
            this.falseSignaturesVoList = [];
          }
          this.keyValue = new Date().getTime();
          this.trackingStopMonitorVOS = res.data.trackingStopMonitorVOS;
          this.lostStatisticsVOS = res.data.lostStatisticsVOS;
          this.wornStatisticsVOS = res.data.wornStatisticsVOS;
          console.log(this.falseSignaturesVoList, 'this.falseSignaturesVo');
        }
      });
    },
    // 获取当前用户的转运中心所属州和转运中心
    getstateTransfer() {
      getSiteTransfer().then((res) => {
        if (res.code === 200) {
          this.TransferList = res.data;
        }
      });
    },
    // 获取所有网点
    getAllSiteList() {
      getAllsite().then((res) => {
        if (res.code === 200) {
          this.siteList = res.data;
        }
      });
    },
    // 州
    getStateList() {
      // level 0 州    1 城市   2 地区
      const params = {
        level: 0,
        parentId: ''
      };
      getLgAreaListData(params, false).then((res) => {
        if (res.code === 200) {
          this.StateList = res.data.map((item) => {
            return {
              id: item.id,
              label: `${item.nameEn}`
            };
          });
        }
      });
    },
    // 获取最近一星期时间
    getTimer() {
      const day = new Date();
      const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM-DD');
      const oneWeekAgo = dayjs(brazilTime).subtract(1, 'week').format('YYYY-MM-DD');
      this.timeValue = [oneWeekAgo + ' 00:00:00', today + ' 23:59:59'];
    },
    downloadNumber() {
      const id = this.value;
      getTransferDashdownload(id).then((res) => {
        if (res.code === 200) {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful14')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('customerManagements.pleaseDownLoad')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          })
            .then((action) => {
              if (action === 'confirm') {
                if (res.data.taskId) {
                  this.$router.push({ path: '/resource/exportTaskList' });
                } else {
                  this.$router.push({ path: '/resource/exportList' });
                }
              }
            })
            .catch(() => {});
        }
      });
    }
  }
};
</script>

<style scoped>
@import './index.css';

/deep/ .el-card__body {
  padding: 10px 15px !important;
}
</style>
