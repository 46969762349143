var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "", "max-height": 300 },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("newOrder.SerialNumber"),
              align: "center",
              type: "index",
              width: "70",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.$index + 1))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("orderDetails.LargeOrderNumber"),
              prop: "orderNumber",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.orderNumber))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.customerOrderNum"),
              prop: "customerNumber",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.customerNumber))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("orderDetails.TotalNumber"),
              prop: "quantity",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.quantity))])]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "top" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("orderDetails.PaymentVoucher") } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        multiple: "",
                        action: "",
                        accept: _vm.fileTypeArr.join(","),
                        "file-list": _vm.fileList,
                        "on-remove": _vm.handleRemove,
                        "before-upload": _vm.beforeUpload,
                        "http-request": _vm.enclosureUpload,
                        "on-success": _vm.uploadSuccess,
                        "on-preview": _vm.viewImage,
                        limit: 3,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "small",
                            type: "primary",
                            disabled: _vm.fileList.length >= 3,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("orderDetails.UploadVoucher")) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "template",
                        { staticClass: "el-upload__tip", slot: "tip" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("orderDetails.PleaseUploadImages")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showViewerSuccess
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": _vm.closeViewerSuccess,
              "url-list": _vm.imgArrSuccess,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }