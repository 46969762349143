import newRequest from '@/utils/new-request';

// 分页+查询
export const apiPostalCodeCorrectionPage = (data, isBool = true) => {
  return newRequest({
    method: 'POST',
    url: '/postalCodeCorrection/page',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
};

// 新增邮编/更新操作/状态变更
export const apiPostalCodeCorrectionUpdate = data => {
  return newRequest({
    method: 'POST',
    url: '/postalCodeCorrection/saveOrEdit',
    data,
    isLoading: true // 是否显示加载中参数
  });
};

