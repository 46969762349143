<template>
  <el-card class="box-card">
    <div style="margin-bottom: 15px">
      <span class="fontClsBold02" style="color: #584cb1">破损统计</span>
      <span>(全部破损/部分破损)</span>
    </div>
    <!--  表格数据  -->
    <el-table
      :data="wornStatisticsVOS"
      :header-cell-style="{ color: 'black' }"
      height="375px"
      stripe
      style="width: 100%"
    >
      <el-table-column :label="$t('navbar.siteName')" align="center" prop="siteName" />
      <el-table-column align="center" label="破损票数" prop="wornCount">
        <template #default="scope">
          <div v-if="scope.row.siteId === null" class="fontClsBold01">
            {{ scope.row.wornCount || '--' }}
          </div>
          <div v-else>{{ scope.row.wornCount || '--' }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="破损率" prop="wornRate">
        <template #default="scope">
          <div v-if="scope.row.siteId === null" class="fontClsBold01">
            {{ scope.row.wornRate || '--' }}
          </div>
          <div v-else>{{ scope.row.wornRate || '--' }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="当月累计破损票数" prop="wornCountMonth">
        <template #default="scope">
          <div v-if="scope.row.siteId === null" class="fontClsBold01">
            {{ scope.row.wornCountMonth || '--' }}
          </div>
          <div v-else>{{ scope.row.wornCountMonth || '--' }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="月破损率" prop="wornRateMonth">
        <template #default="scope">
          <div v-if="scope.row.siteId === null" class="fontClsBold01">
            {{ scope.row.wornRateMonth || '--' }}
          </div>
          <div v-else>{{ scope.row.wornRateMonth || '--' }}</div>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  name: 'LossStatistics',
  props: {
    wornStatisticsVOS: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
};
</script>

<style scoped></style>
