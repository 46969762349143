import newRequest from '@/utils/new-request';

// 索赔规则配置

// 分页
export const apiClaimRuleConfigPage = (data, isBool = true) => {
  return newRequest({
    method: 'post',
    url: '/claimRuleConfig/getPage',
    data,
    isLoading: isBool
  });
};

// 新增
export const apiClaimRuleConfigAdd = (data, isBool = true) => {
  return newRequest({
    method: 'post',
    url: '/claimRuleConfig/add',
    data,
    isLoading: isBool
  });
};

// 修改
export const apiClaimRuleConfigEdit = (data, isBool = true) => {
  return newRequest({
    method: 'post',
    url: '/claimRuleConfig/edit',
    data,
    isLoading: isBool
  });
};

// 删除
export const apiClaimRuleConfigDelete = id => {
  return newRequest.post(`/claimRuleConfig/delete/${id}`);
};

// 禁用
export const apiClaimRuleConfigDisable = data => {
  return newRequest.post(`/claimRuleConfig/disable`, data);
};

// 导出
export const apiClaimRuleConfigExport = (data, isBool = true) => {
  return newRequest({
    method: 'post',
    url: '/claimRuleConfig/export',
    data,
    isLoading: isBool
  });
};

