<template>
  <div class="app-container">
    <el-alert title="用于盘点滞留在网点的包裹以产生新的轨迹" type="success" />
    <el-form
      ref="ruleFormRef"
      :inline="true"
      :model="queryForm"
      label-position="top"
      size="large"
      @submit.native.prevent
    >
      <el-form-item :label="$t('GlobalSetObj.scannerBig')" prop="waybillBagNumber">
        <el-input
          ref="inputRef"
          v-model.trim="queryForm.waybillBagNumber"
          clearable
          placeholder="请输入或扫描单号"
          style="width: 320px"
          @keyup.enter.native="arrivedOut"
        />
      </el-form-item>
    </el-form>
    <div class="btnRow">
      <!--    已扫  -->
      <div class="header-button-ri">
        <span
          class="hasScan"
        >{{ $t('GlobalSetObj.swept') }}:<i>{{ queryForm.total }}</i></span>
      </div>
    </div>
    <!--  表格数据  -->
    <el-table
      :data="tableDataShow"
      :header-cell-style="{ background: '#f2f2f2', color: 'black' }"
      border
      style="width: 100%"
    >
      <el-table-column
        :label="$t('GlobalSetObj.serial')" align="center" prop="serial"
        width="80"
      >
        <template #default="scope">
          <i v-if="scope.row.first" class="firstOrder centerBtn">{{ 'New' }}</i>
          <div v-else class="centerBtn">{{ scope.row.serial }}</div>
        </template>
      </el-table-column>
      <el-table-column label="最新扫描运单号或者袋号" min-width="180" prop="scanWaybillNumber">
        <template #default="scope">
          <div :class="{ firstOrder: scope.row.first }">
            {{ scope.row.scanWaybillNumber || '--' }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[50, 100, 200, 400]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0; float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
import { retainedScanOrderNumber } from '@/api/automaticSorting';

const okMp3 = 'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/su.wav';
const errorMp3 =
  'https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/app/static/music/print_error.wav';
export default {
  name: 'ArrivedBox',
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      okMp3,
      errorMp3,
      queryForm: {
        pageSize: 50,
        pageNumber: 1,
        total: 0,
        waybillBagNumber: ''
      },
      tableData: [],
      tableDataShow: []
    };
  },
  activated() {
    this.$nextTick((_) => {
      this.$refs.inputRef.$refs.input.focus();
    });
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.$nextTick((_) => {
      this.$refs.inputRef.$refs.input.focus();
    });
  },
  methods: {
    // 扫描
    arrivedOut() {
      this.$refs['ruleFormRef'].validate((valid) => {
        if (valid) {
          if (this.queryForm.waybillBagNumber) {
            // const flag = this.tableData.some(item => {
            //   return item.waybillNumber === this.queryForm.waybillBagNumber;
            // });
            const flag1 = this.tableData.some((item) => {
              return item.scanWaybillNumber === this.queryForm.waybillBagNumber;
            });
            if (flag1) {
              this.$message.error(this.$t('GlobalSetObj.notrepeatOperation'));
              this.$refs.errRef.play();
              this.$nextTick((_) => {
                this.$refs.inputRef.$refs.input.select();
              });
              return;
            }
            retainedScanOrderNumber(this.queryForm.waybillBagNumber)
              .then((res) => {
                if (res.code === 200) {
                  const data = {
                    scanWaybillNumber: this.queryForm.waybillBagNumber
                  };
                  this.tableData.unshift(data);
                  this.tableData.forEach((item, index) => {
                    item.serial = index + 1;
                    if (item.serial === 1) {
                      item.first = true;
                    } else {
                      item.first = false;
                    }
                  });
                  this.queryForm.total = this.tableData.length;
                  this.queryForm.waybillBagNumber = '';
                  this.$refs.okRef.play();
                  this.$nextTick((_) => {
                    this.$refs.inputRef.$refs.input.focus();
                  });
                  if (res.data.tipsMsg) {
                    this.$message.warning(res.data.tipsMsg);
                  }
                  this.getscannedPage();
                }
              })
              .catch(() => {
                this.$refs.errRef.play();
                this.$nextTick((_) => {
                  this.$refs.inputRef.$refs.input.select();
                });
              });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 前端分页
    getscannedPage() {
      const startIndex = (this.queryForm.pageNumber - 1) * this.queryForm.pageSize;
      const endIndex = startIndex + this.queryForm.pageSize;
      this.tableDataShow = this.tableData.slice(startIndex, endIndex);
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getscannedPage();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getscannedPage();
    }
  }
};
</script>

<style scoped>
.app-container >>> .el-dialog__body {
  padding: 10px 20px;
}

.app-container >>> .el-form-item {
  margin-bottom: 0;
}

.btnRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.hasScan {
  padding: 3px 10px;
  font-weight: 600;
  margin-left: 10px;
  font-size: 16px;
}

.hasScan i {
  font-style: normal;
  font-size: 22px;
  margin-left: 10px;
  color: #409eff;
}

.centerBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.firstOrder {
  font-size: 16px;
  color: #409eff;
  font-weight: bold;
}
</style>
