<template>
  <div class="main-box">
    <el-form :inline="true">
      <el-form-item :label="$t('newOrder.pickUpDate')">
        <el-date-picker
          v-model="timeValue"
          :format="$i18n.locale=='zh'?zhDate:ptDate"
          :placeholder="$t('newOrder.SelectDatetime')"
          range-separator="~"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item label="">
        <!--搜索-->
        <el-button icon="el-icon-search" type="primary" @click="getSearchBtn()">{{ $t('newOrder.search') }}</el-button>
        <!--重置-->
        <el-button icon="el-icon-refresh-left" type="info" @click="resetForm">{{ $t('newOrder.reset') }}</el-button>
        <!--        导出-->
        <el-button @click="exportTable">{{ $t('newOrder.export') }}</el-button>
      </el-form-item>
    </el-form>
    <el-tabs v-model="activeName" @tab-click="tabClick">
      <el-tab-pane :label="$t('customerManagements.all')" :name="''" lazy>
        <statisticsTable :table-data="tableData" />
      </el-tab-pane>
      <el-tab-pane label="SHEIN" lazy name="shein">
        <statisticsTable :table-data="tableData" name="shein" />
      </el-tab-pane>
      <el-tab-pane label="Kwai" lazy name="kwai">
        <statisticsTable :table-data="tableData" name="kwai" />
      </el-tab-pane>
      <el-tab-pane :label="$t('newOrder.crossBorder')" lazy name="szanjun">
        <statisticsTable :table-data="tableData" name="szanjun" />
      </el-tab-pane>
    </el-tabs>
    <!--  分页  -->
    <el-pagination
      :current-page.sync="queryForm.pageNumber"
      :page-size.sync="queryForm.pageSize"
      :page-sizes="[10, 50, 200, 400]"
      :total="total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 20px 0;text-align: right;"
      @size-change="getSearchBtn()"
      @current-change="getSearchBtn()"
    />
  </div>
</template>
<script>
import statisticsTable from './components/statisticsTable';
import { trackStatisticalFullLinkExport, trackStatisticalFullLinkPage } from '@/api/orderApi';
import dayjs from 'dayjs';
import { goResourceTaskList } from '@/utils/goExportList.js';

export default {
  name: 'AllLinkTimeStatistics',
  components: {
    statisticsTable
  },
  data() {
    return {
      timeValue: [],
      zhDate: 'yyyy-MM-dd',
      ptDate: 'dd-MM-yyyy',
      activeName: '',
      total: 0,
      queryForm: {
        pageSize: 10,
        pageNumber: 1
      },
      tableData: []
    };
  },
  created() {
    // moment获取最近三十天开始时间和结束时间
    const beginDate = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
    const endDate = dayjs().format('YYYY-MM-DD');
    this.timeValue = [beginDate, endDate];
    this.getSearchBtn();
  },
  methods: {
    // 搜索
    getSearchBtn(pageNumber) {
      console.log(pageNumber, 'pageNumber');
      let [beginDate, endDate] = ['', ''];
      if (this.timeValue && this.timeValue.length) {
        beginDate = this.timeValue[0];
        endDate = this.timeValue[1];
      }
      const fullLinkType = this.activeName === '0' ? '' : this.activeName;
      this.tableData = [];
      if (pageNumber) {
        this.queryForm.pageNumber = pageNumber;
      }
      trackStatisticalFullLinkPage({ ...this.queryForm, params: { beginDate, endDate, fullLinkType }})
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
            this.tableData = res.data.records;
            // const createTotal = this.getKeyTotal(1, this.tableData, 'createTotal');
            // const collectTotal = this.getKeyTotal(1, this.tableData, 'collectTotal');
            // const unCompleted = this.getKeyTotal(1, this.tableData, 'unCompleted');
            // const firstDayTotal = this.getKeyTotal(1, this.tableData, 'firstDay');
            // const secondDayTotal = this.getKeyTotal(1, this.tableData, 'secondDay');
            // const thirdDayTotal = this.getKeyTotal(1, this.tableData, 'thirdDay');
            // const fourthDayTotal = this.getKeyTotal(1, this.tableData, 'fourthDay');
            // const fifthDayTotal = this.getKeyTotal(1, this.tableData, 'fifthDay');
            // const sixthDayTotal = this.getKeyTotal(1, this.tableData, 'sixthDay');
            // const seventhDayTotal = this.getKeyTotal(1, this.tableData, 'seventhDay');
            // const eighthDayTotal = this.getKeyTotal(1, this.tableData, 'eighthDay');
            // const statistics = {
            //   date: this.tableData[this.tableData.length - 1].date+' ~ '+this.tableData[0].date,
            //   customerName: null,
            //   week: '',
            //   createTotal,
            //   collectTotal,
            //   firstDay: firstDayTotal,
            //   firstDayPer: collectTotal ? Math.round(((firstDayTotal / collectTotal) * 100)) + '%' : '0%',
            //   secondDay: secondDayTotal,
            //   secondDayPer: collectTotal ? Math.round(((secondDayTotal / collectTotal) * 100)) + '%' : '0%',
            //   thirdDay: thirdDayTotal,
            //   thirdDayPer: collectTotal ? Math.round(((thirdDayTotal / collectTotal) * 100)) + '%' : '0%',
            //   fourthDay: fourthDayTotal,
            //   fourthDayPer: collectTotal ? Math.round(((fourthDayTotal / collectTotal) * 100)) + '%' : '0%',
            //   fifthDay: fifthDayTotal,
            //   fifthDayPer: collectTotal ? Math.round(((fifthDayTotal / collectTotal) * 100)) + '%' : '0%',
            //   sixthDay: sixthDayTotal,
            //   sixthDayPer: collectTotal ? Math.round(((sixthDayTotal / collectTotal) * 100)) + '%' : '0%',
            //   seventhDay: seventhDayTotal,
            //   seventhDayPer: collectTotal ? Math.round(((seventhDayTotal / collectTotal) * 100)) + '%' : '0%',
            //   eighthDay: eighthDayTotal,
            //   eighthDayPer: collectTotal ? Math.round(((eighthDayTotal / collectTotal) * 100)) + '%' : '0%',
            //   completedPer: collectTotal - unCompleted ? Math.round(((collectTotal - unCompleted) / collectTotal) * 100) + '%' : '0%',
            //   unCompleted: unCompleted
            // };
            // this.tableData.unshift(statistics);
            // console.log(statistics, 'statistics');
            this.total = res.data.total;
          }
        });
    },
    // 重置
    resetForm() {
      // moment获取最近三十天开始时间和结束时间
      const beginDate = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
      const endDate = dayjs().format('YYYY-MM-DD');
      this.timeValue = [beginDate, endDate];
    },
    /**
     * @description: tab切换
     */
    tabClick() {
      this.getSearchBtn(1);
    },
    /**
     * @description: 获取总数or总比例
     * @param type
     * @param data
     * @param key1
     * @param key2
     * @returns {number}
     */
    getKeyTotal(type, data, key1, key2) {
      // 计算总数
      if (type === 1) {
        let total = 0;
        data.forEach((item) => {
          total += item[key1];
        });
        return total;
      } else if (type === 2) {
        // 计算总比例
        let total = 0;
        data.forEach((item) => {
          total += item[key1];
        });
        return total;
      }
    },
    /**
     * @description: 导出
     */
    exportTable() {
      let [beginDate, endDate] = ['', ''];
      if (this.timeValue && this.timeValue.length) {
        beginDate = this.timeValue[0];
        endDate = this.timeValue[1];
      }
      const fullLinkType = this.activeName === '0' ? '' : this.activeName;
      trackStatisticalFullLinkExport({ beginDate, endDate, fullLinkType })
        .then((res) => {
          goResourceTaskList();
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.main-box {
  padding: 12px;
}

// scss样式穿透.el-tabs__item.is-active
::v-deep .el-tabs__item.is-active {
  color: #67C23A;
}

::v-deep .el-tabs__item:hover {
  color: #67C23A;
}

::v-deep .el-tabs__active-bar {
  background-color: #67C23A;
}

::v-deep .el-tabs__nav-wrap::after {
  background-color: #ffffff;
}

::v-deep .el-tabs__item {
  font-weight: 700;
  color: #909399;
}
</style>
