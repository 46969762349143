<!-- 操作中心 -->
<template>
  <div class="searchForm">
    <el-popover placement="bottom-start" trigger="hover">
      <div class="feet">
        <!--添加费用-->
        <el-button
          size="small" type="primary" class="feet-btn"
          @click="onBatchAddingCosts"
        >{{ $t('receivablePayable.addingCosts') }}
        </el-button>

        <!--批量修改费用-->
        <el-button
          size="small" type="primary" class="feet-btn"
          @click="isImportEdit = true"
        >{{ $t('receivablePayable.ImportModifyExpenses') }}
        </el-button>
      </div>

      <el-button
        slot="reference"
        v-permit:remove="'btn:finance:costManagement:costsFee:Edit'"
        :loading="isAddAndEdit" size="small" style="margin-right: 10px;"
      >
        {{ $t('receivablePayable.AddModifyExpenses') }}
      </el-button>
    </el-popover>

    <!-- 生成对账单 -->
    <el-button
      v-permit:remove="'btn:finance:costManagement:costsFee:statementOfAccount'"
      style="margin-right: 10px;"
      type="primary"
      size="small"
      @click="onGenerateCope"
    >{{ $t('basicData.GenerateAStatement') }}</el-button>

    <!-- 备注（含弹出窗） -->
    <RemarkButCmp :async-submit="asyncRemark" />

    <!-- 导出 -->
    <el-button
      v-permit:remove="'btn:finance:costManagement:costsFee:Export'"
      style="margin-right: 10px;"
      size="small" @click="onExport"
    >{{ $t('receivablePayable.Export') }}</el-button>

    <!-- 删除（含弹出窗）-->
    <DelButCmp :async-submit="asyncDel" />

    <!-- 自定义列栏目 :checkedColumns="checkedColumns" :customColumnId="customColumnId"-->
    <!--    <CustomStatusBar :payable-type="payableType" />-->

    <!--生成对账单弹窗-->
    <AccountsPayable
      ref="generateCopeTimeRef"
      :cb="generateReceivablesSuccess"
      :query-form="queryForm"
      :select-data="selectData"
    />

    <!-- 显示生成对账单弹窗 -->
    <AccountsPayableDialog ref="generateCopeListRef" />

    <!-- 添加费用 -->
    <AddFee
      ref="notAddRef"
      :query-form="queryForm"
      :select-data="selectArr"
      @close="onQRClose"
    />

    <!-- 费用详情 -->
    <EditFee
      ref="editFeeRef"
      :query-form="queryForm"
      :row="paramsRow"
      @close="onQRClose"
    />

    <!-- 导入修改费用 -->
    <el-dialog
      :title="$t('receivablePayable.ImportModifyExpenses')"
      :visible.sync="isImportEdit"
      width="400px"
    >
      <importEditFees @changeFeeList="changeFeeList" />
    </el-dialog>
  </div>
</template>

<script>
import {
  getCostSiteExpenseRemark,
  getBatchCostSiteExpenseFee,
  getExportCostSiteExpense
} from '@/api/finance/costManagement.js';
import { cloneDeep } from 'lodash-es';
import { goResourceTaskList } from '@/utils/goExportList.js';
import { searchEmpty } from '@/views/finance/payableManage/feesPayable/enums.js';
import { TABLE_HEADER } from '@/views/finance/costManagement/components/enums.js';
import { initGetData } from '@/views/finance/costManagement/utils.js';

export default {
  name: 'ActionCenter',

  components: {
    importEditFees: () => import('@/views/finance/costManagement/costsFee/cmp/importEditFees.vue'),
    AccountsPayable: () => import('@/views/finance/costManagement/costsFee/cmp/AccountsPayable.vue'),
    AccountsPayableDialog: () => import('@/views/finance/payableManage/feesPayable/component/AccountsPayable/dialog.vue'),
    RemarkButCmp: () => import('@/views/finance/costManagement/costsFee/cmp/Remark.vue'),
    DelButCmp: () => import('@/views/finance/costManagement/costsFee/cmp/DelButCmp.vue'),
    AddFee: () => import('@/views/finance/costManagement/costsFee/cmp/costBreakdown/addFee.vue'),
    EditFee: () => import('@/views/finance/costManagement/costsFee/cmp/costBreakdown/editFee.vue')
  },

  props: {
    // 搜索条件
    queryForm: {
      type: Object,
      default: () => {},
      required: true
    },

    // 多选
    selectData: {
      type: Array,
      default: () => [],
      required: true
    }
  },

  data() {
    return {
      isAddAndEdit: false,
      feeTypeOption: [], // 费用类型选项
      siteList: [], // 获取网点
      paramsRow: {}, // 费用详情
      isImportEdit: false // 导入修改费用
    };
  },

  computed: {
    // 获取多选选中的值(只含id)
    selectIdArr() {
      const arr = this.selectData;
      if (arr.length === 0) {
        return [];
      }

      return arr.map(item => {
        return item.id;
      });
    },

    // 获取多选选中的值
    selectArr() {
      return cloneDeep(this.selectData);
    }
  },

  activated() {
    this.initData();
  },

  created() {
    this.initData();
  },

  methods: {
    // 初始化数据
    initData() {
      this.isAddAndEdit = true;
      initGetData().then(res => {
        // this.siteList = res[0];// 网点
        // this.feeTypeOption = res[1];// 费用
      }).catch(err => console.error(err)).finally(() => {
        this.isAddAndEdit = false;
      });
    },

    // 刷新列表
    refresh() {
      this.$emit('refresh');
    },

    // 添加费用
    onBatchAddingCosts() {
      this.$refs.notAddRef.open();
    },
    // 添加费用关闭回调函数
    onQRClose() {
      this.refresh();
    },

    // 详情
    openNotQRDetail(row) {
      this.paramsRow = cloneDeep(row);
      this.$refs.editFeeRef.open();
    },

    // 导入修改费用关闭
    changeFeeList() {
      this.isImportEdit = false;
    },

    /**
     * 生成对账单
     */
    onGenerateCope() {
      // 判断搜索条件是否为空
      const isBool = searchEmpty(this.queryForm.params);
      if (isBool) return;

      this.$refs.generateCopeTimeRef.open();
    },

    /**
     * 生成对账单成功回调函数
     * @param data
     */
    generateReceivablesSuccess(data) {
      this.$refs.generateCopeListRef.open(data);
    },

    // 备注提交
    asyncRemark(str) {
      const param = {
        condition: {
          ...this.queryForm.params,
          ids: this.selectIdArr
        },
        remark: str
      };
      return getCostSiteExpenseRemark(param).then(res => {
        if (res.code === 200) {
          this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // '保存成功'
          this.refresh();
        }
      });
    },

    /**
     * 默认导出所有
     */
    onExport() {
      const isBool = searchEmpty(this.queryForm.params);
      if (isBool) return;

      const prop = [];
      const label = [];
      TABLE_HEADER.forEach(item => {
        prop.push(item.prop);
        label.push(item.label);
      });
      const param = {
        ...this.queryForm.params,
        fieldList: prop,
        headList: label,
        ids: this.selectIdArr
      };
      getExportCostSiteExpense(param).then(res => {
        if (res.code === 200) {
          goResourceTaskList();
        }
      }).catch(err => {
        console.error(err);
      });
    },

    /**
     * 根据费用类型删除费用
     * @param code 费用编号
     */
    asyncDel(code) {
      const param = {
        ...this.queryForm.params,
        feeCode: code, // 费用编号
        ids: this.selectIdArr // 多选订单数据
      };
      return getBatchCostSiteExpenseFee(param).then(res => {
        if (res.code === 200) {
          this.$message.success(this.$i18n.t('GlobalSetObj.TheOperationSucceeded')); // '操作成功'
          this.refresh();
        } else {
          this.$message.warning(res.msg);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.searchForm {
  display: flex;
  align-items: center;
}

.feet {
  display: flex;
  flex-direction: column;
  align-items: start;
  .feet-btn {
    margin-left: 0;
    margin-bottom: 10px;
  }
}
</style>
