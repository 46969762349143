<template>
  <div class="app-container">
    <el-card style="margin-bottom: 10px">
      <el-form :model="queryForm" size="small">
        <el-row :gutter="10">
          <el-col :span="8">
            <!--设备名称-->
            <el-form-item :label="$t('GlobalSetObj.TheNameOfTheDevice')" prop="deviceName">
              <el-select
                v-model="queryForm.params.deviceName"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                style="width: 100%"
                @change="getGridTableList(queryForm.params.deviceName)"
              >
                <el-option
                  v-for="(item, index) in deviceList"
                  :key="index"
                  :label="item.deviceName"
                  :value="item.deviceName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <!--格口-->
            <el-form-item :label="$t('GlobalSetObj.GridSlogan')" prop="gridNum">
              <el-select
                v-model="queryForm.params.gridNum"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                clearable
                filterable
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in gridList"
                  :key="index"
                  :label="item.gridNum"
                  :value="item.gridNum"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <div style="margin-top: 30px">
                <el-button
                  icon="el-icon-search" size="small" type="primary"
                  @click="btnSearch"
                >
                  {{ $t('GlobalSetObj.search') }}
                </el-button>
                <el-button
                  icon="el-icon-refresh" size="small" @click="resetForm"
                >{{ $t('GlobalSetObj.reset') }}
                </el-button>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card>
      <div style="margin-bottom: 10px">
        <el-button
          v-permit:remove="'but:automatic-sorting:grid-management:add'"
          icon="el-icon-plus"
          size="mini"
          type="primary"
          @click="handlerAdd"
        >{{ $t('CenterForSorting.add') }}
        </el-button>
      </div>
      <el-table :data="tableData" :max-height="600" border>
        <el-table-column :label="$t('CenterForSorting.ind')" align="center" width="60px">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!-- 格口编号 -->
        <el-table-column
          :label="$t('CenterForSorting.LatticePortNumber')"
          :show-overflow-tooltip="true"
          align="center"
          prop="gridNum"
        />
        <!--        设备名称-->
        <el-table-column
          :label="$t('GlobalSetObj.deviceName')"
          :show-overflow-tooltip="true"
          align="center"
          prop="deviceName"
        />
        <el-table-column
          :label="$t('CenterForSorting.AssociationRules')"
          :show-overflow-tooltip="true"
          align="center"
          prop="ruleName"
        />
        <!-- 关联规则 -->
        <!--        <el-table-column-->
        <!--          prop="orderCount"-->
        <!--          :label="$t('CenterForSorting.packageSum')"-->
        <!--        />&lt;!&ndash; 小包数量 &ndash;&gt;-->
        <el-table-column
          :label="$t('CenterForSorting.Note')"
          :show-overflow-tooltip="true"
          align="left"
          prop="remark"
        /><!-- 备注 -->
        <el-table-column
          :label="$t('CenterForSorting.creator')"
          prop="createUserName"
        /><!-- 创建人 -->
        <el-table-column
          :label="$t('CenterForSorting.CreationTime')"
          prop="createTime"
        /><!-- 创建时间 -->
        <el-table-column
          :label="$t('CenterForSorting.Editor')"
          prop="updateUserName"
        /><!-- 编辑人 -->
        <el-table-column
          :label="$t('CenterForSorting.EditTime')"
          prop="updateTime"
        /><!-- 编辑时间 -->
        <el-table-column
          :label="$t('CenterForSorting.operation')"
          align="center"
          fixed="right"
          prop="操作"
          width="160px"
        ><!-- 操作 -->
          <template slot-scope="scope">
            <el-tooltip
              v-permit:remove="'but:automatic-sorting:grid-management:edit'"
              :content="$t('CenterForSorting.edit')"
              class="item"
              effect="dark"
              placement="top"
            >
              <el-button size="mini" type="text" @click="handleEdit(scope.row)">
                <i
                  class="el-icon-edit-outline hoverClass"
                  style="font-size: 22px; color: #409eff"
                /><!-- 编辑 -->
              </el-button>
            </el-tooltip>
            <el-tooltip
              v-permit:remove="'but:automatic-sorting:grid-management:ClearGridData'"
              :content="$t('CenterForSorting.ClearGridData')"
              class="item"
              effect="dark"
              placement="top"
            >
              <el-popconfirm
                :title="$t('CenterForSorting.AreYouSureToClearHistoricalData')"
                @confirm="handleClear(scope.row)"
              >
                <el-button
                  slot="reference" size="mini" style="margin: 0 10px"
                  type="text"
                >
                  <i
                    class="el-icon-delete hoverClass"
                    style="font-size: 22px; color: #f56c6c"
                  /><!-- 清除格口数据 -->
                </el-button>
              </el-popconfirm>
            </el-tooltip>
            <el-tooltip
              v-if="scope.row.labelUrl"
              :content="$t('CenterForSorting.Print')"
              class="item"
              effect="dark"
              placement="top"
            >
              <el-button size="mini" type="text" @click="handlePrint(scope.row)">
                <i
                  class="el-icon-printer hoverClass"
                  style="font-size: 22px; color: #67c23a"
                /><!-- 打印 -->
              </el-button>
            </el-tooltip>
            <!--            <el-button-->
            <!--              v-permit:remove="'but:automatic-sorting:grid-management:edit'"-->
            <!--              type="success"-->
            <!--              size="mini"-->
            <!--              @click="handleEdit(scope.row)"-->
            <!--            >{{ $t('CenterForSorting.edit') }}&lt;!&ndash; 编辑 &ndash;&gt;-->
            <!--            </el-button>-->
            <!--            <el-button-->
            <!--              v-permit:remove="'but:automatic-sorting:grid-management:ClearGridData'"-->
            <!--              type="danger"-->
            <!--              size="mini"-->
            <!--              @click="handleClear(scope.row)"-->
            <!--            >{{ $t('CenterForSorting.ClearGridData') }}&lt;!&ndash; 清除格口数据 &ndash;&gt;-->
            <!--            </el-button>-->
            <!--            <el-button-->
            <!--              v-if="scope.row.labelUrl"-->
            <!--              size="mini"-->
            <!--              @click="handlePrint(scope.row)"-->
            <!--            >{{ $t('CenterForSorting.Print') }}&lt;!&ndash; 打印 &ndash;&gt;-->
            <!--            </el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          :current-page.sync="queryForm.pageNumber"
          :page-size.sync="queryForm.pageSize"
          :page-sizes="[10, 20, 30, 50, 100, 500, 1000]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          style="margin: 10px 0"
          @current-change="getData"
          @size-change="getData"
        />
      </div>
    </el-card>

    <!-- 新增 / 编辑 -->
    <el-drawer
      :show-close="false"
      :title="drawer.title"
      :visible.sync="drawer.visible"
      :wrapper-closable="false"
      direction="ltr"
      size="50%"
    >
      <add-cmp
        :device-id="drawer.deviceId"
        :grid-num="drawer.gridNum"
        :ids="drawer.ids"
        :remark="drawer.remark"
        :title="drawer.title"
        @updataData="updataData"
      />
    </el-drawer>
  </div>
</template>

<script>
import AddCmp from './cmp/AddsCmp.vue';
import { apiDeviceManagementPage, apiPage, apiSorterGridDeleteData } from '@/api/automaticSorting';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { getUserName } from '@/utils/asyncTools';

export default {
  name: 'GridManagement',
  components: {
    'add-cmp': AddCmp
  },

  data() {
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          gridNum: '',
          ruleName: '',
          deviceName: ''
          // id: '',
          // deleted: '',
          // remark: '',
          // ruleId: ''
        }
      },

      tableData: [],
      total: 0,
      deviceList: [], // 设备选项
      gridList: [], // 格口选项
      drawer: {
        title: this.$t('CenterForSorting.add'),
        visible: false,
        ids: 0,
        gridNum: undefined,
        remark: '',
        deviceId: '' // 设备ID
      }
    };
  },

  created() {
    this.getData();
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.getDeviceTableList();
    this.getGridTableList();
  },

  methods: {
    // 清楚格口数据
    handleClear(row) {
      console.log(row, '===handleClear(scope.row)');
      const params = {
        deviceId: row.deviceId,
        gridNum: row.gridNum
      };
      apiSorterGridDeleteData(params).then((res) => {
        if (res.code === 200) {
          this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
          this.getData();
        }
      });
    },
    // 重置
    resetForm() {
      this.queryForm.params.gridNum = '';
      this.queryForm.params.ruleName = '';
      this.queryForm.params.deviceName = '';
      this.queryForm.pageNumber = 1;
      this.getData();
    },
    // 获取设备列表
    getDeviceTableList() {
      const param = {
        pageSize: 99999,
        pageNumber: 1,
        params: {
          deviceIp: '',
          deviceName: '',
          deviceNumber: '',
          deviceStatus: 0,
          transitSiteId: null
        }
      };
      apiDeviceManagementPage(param).then((res) => {
        if (res.code === 200) {
          this.deviceList = res.data.records;
        }
      });
    },
    // 获取格口
    getGridTableList(val = '') {
      const param = {
        pageSize: 99999,
        pageNumber: 1,
        params: {
          deviceName: val
        }
      };
      this.gridList = [];
      this.queryForm.params.gridNum = null;
      apiPage(param, false).then((res) => {
        if (res.code === 200) {
          this.gridList = res.data.records;
        }
      });
    },
    handlePrint(down) {
      window.open(down.labelUrl);
    },

    // 分页
    getData() {
      apiPage(this.queryForm)
        .then((res) => {
          if (res.code !== 200) {
            return;
          }
          this.total = res.data.total;

          this.tableData = res.data.records.map((item) => {
            const createUserName = getUserName(item.createUser, this.userIdAndNameObj);
            const updateUserName = getUserName(item.updateUser, this.userIdAndNameObj);
            return {
              ...item,
              createUserName,
              updateUserName
            };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 点击分页
    btnSearch() {
      this.queryForm.pageNumber = 1;
      this.getData();
    },
    // 新增格口
    handlerAdd() {
      this.drawer.title = this.$t('CenterForSorting.gekouAdd'); // '新增格口'
      this.drawer.visible = true;
      this.drawer.ids = 0;
      this.drawer.gridNum = undefined;
      this.drawer.remark = '';
      this.drawer.deviceId = '';
    },

    // 编辑
    handleEdit(row) {
      const { id, gridNum, remark, deviceId } = row;
      this.drawer.title = this.$t('CenterForSorting.gekouEdit'); // '编辑格口'
      this.drawer.visible = true;
      this.drawer.ids = id;
      this.drawer.gridNum = gridNum;
      this.drawer.remark = remark;
      this.drawer.deviceId = deviceId;
    },

    // 父元素更新
    updataData(isBool) {
      console.log(1);
      this.drawer.visible = false;
      if (isBool) {
        this.getData();
      }
    }
  }
};
</script>

<style scoped>
.ml {
  margin-left: 5px;
}
</style>
