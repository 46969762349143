<template>
  <el-card class="box-card">
    <div style="margin-bottom: 15px; font-weight: bold">
      <span class="fontClsBold02">{{ $t('compensationManagement.FalseSigningForDelivery') }}</span>
      <span
        class="fontCls" style="margin: 0 20px"
      >虚假签收总票数: {{ falseSignaturesVo.falseSignaturesTotal || 0 }}</span>
      <span class="fontCls">虚假签收率: {{ falseSignaturesVo.falseSignaturesRate || 0 }}</span>
    </div>
    <div id="main02" style="height: 230px; width: 100%" />
  </el-card>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'FalseSignature',
  props: {
    falseSignaturesVo: {
      type: Object,
      default: () => {
        return {};
      }
    },
    falseSignaturesVoList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      chartDom: '',
      myChart: '',
      xAxisData: [],
      yAxisData: []
    };
  },
  mounted() {
    this.getDataEacharts();
    // 添加 resize 事件监听器
    window.addEventListener('resize', this.resizeHandler);
    this.getData();
  },
  methods: {
    // 获取数据
    getDataEacharts() {
      if (this.falseSignaturesVoList.length) {
        this.falseSignaturesVoList.forEach((item) => {
          this.xAxisData.push(item.siteName);
          this.yAxisData.push(item.falseSignaturesCount);
        });
      }
    },
    // 渲染折线图
    getData() {
      const chartDom = document.getElementById('main02');
      this.myChart = echarts.init(chartDom);
      const option = {
        tooltip: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: this.xAxisData,
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              // 设置 X 轴标签倾斜 45°
              rotate: 60
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            barWidth: '20',
            data: this.yAxisData,
            label: {
              show: true,
              position: 'top',
              color: '#009743'
            },
            itemStyle: {
              color: '#009743' // 这里设置绿色的十六进制颜色码，#008000是绿色
            }
          }
        ]
      };
      option && this.myChart.setOption(option);
    },
    resizeHandler() {
      this.myChart.resize();
    }
  }
};
</script>

<style scoped></style>
