<!-- 费用明细表 -->
<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>{{ $t('receivablePayable.FeeBreakdown') }}</span>
    </div>
    <el-form
      ref="addBatchFee" :model="addBatchFee" label-width="0"
      size="small"
    >
      <el-table :data="addBatchFee.addBatchFeeList" border>
        <el-table-column
          v-if="isAllowEdit" width="55" align="center"
          fixed="left"
        >
          <template slot="header">
            <span class="el-icon-plus addStyle" @click="addCost()" />
          </template>
          <template slot-scope="scope">
            <!--确定删除吗？ isAccept(对账单)为true不能删除 allowEdit为false不可删除-->
            <el-popconfirm
              v-if="!scope.row.isAccept"
              :title="$t('basicData.AreYouSureDelete')"
              @confirm="deleteCost(scope)"
            >
              <span slot="reference" v-permit:remove="'btn:finance:payableManagement:feesPayable:addEdit'" class="el-icon-delete deleteStyle" />
            </el-popconfirm>
          </template>
        </el-table-column>
        <!--费用类型-->
        <el-table-column prop="feeName" :label="$t('basicData.ExpenseType')" width="180">
          <template slot="header">
            <div>
              <span style="color: #f00;margin-right: 5px;">*</span>
              <span>{{ $t('basicData.ExpenseType') }}</span>
            </div>
          </template>
          <template slot-scope="scope">
            <div class="formStyle">
              <div v-if="scope.row.isEdit === false">{{ scope.row.feeName }}</div>
              <el-form-item
                v-else
                :prop="'addBatchFeeList.' + scope.$index + '.feeCode'"
                :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
              >
                <el-select
                  v-model="scope.row.feeCode"
                  filterable
                  clearable
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  style="width: 100%;"
                  @change="addChangeFee(scope)"
                >
                  <el-option
                    v-for="item in feeTypeOption"
                    :key="item.id"
                    :label="item.displayName"
                    :value="item.code"
                  />
                </el-select>
              </el-form-item>
            </div>
          </template>
        </el-table-column>
        <!--是否生成对账单-->
        <el-table-column prop="isGenBill" :label="$t('receivablePayable.WhetherToGenerateAStatement')" width="180">
          <template slot-scope="scope">
            <div>{{ scope.row.isGenBill ? $t('receivablePayable.Generated') : $t('receivablePayable.NotGenerated') }}</div>
          </template>
        </el-table-column>
        <!--是否核收-->
        <el-table-column prop="isAccept" :label="$t('receivablePayable.WhetherToCheckOrNot')" width="100">
          <template slot-scope="scope">
            <div>{{ scope.row.isAccept ? $t('basicData.Received') : $t('basicData.NotReconciled') }}</div>
          </template>
        </el-table-column>
        <!--计费重量-->
        <el-table-column prop="chargeWeight" :label="$t('receivablePayable.BillableWeight')" width="100" />
        <!--应付金额-->
        <el-table-column prop="fee" :label="$t('receivablePayable.AmountDue')" width="140">
          <template slot="header">
            <div>
              <span style="color: #f00;margin-right: 5px;">*</span>
              <span>{{ $t('receivablePayable.AmountDue') }}</span>
            </div>
          </template>
          <template slot-scope="scope">
            <div class="formStyle">
              <div v-if="scope.row.isEdit === false">{{ scope.row.fee }}</div>
              <el-form-item
                v-else
                :prop="'addBatchFeeList.' + scope.$index + '.fee'"
                :rules="{ required: true, suffix: 2, negative: '-?', validator: priceType, trigger: ['blur', 'change'] }"
              >
                <el-input v-model="scope.row.fee" />
              </el-form-item>
            </div>
          </template>
        </el-table-column>
        <!--生成时间-->
        <el-table-column prop="operateTime" :label="$t('newOrder.BuildTime')" width="220">
          <template slot="header">
            <div>
              <span style="color: #f00;margin-right: 5px;">*</span>
              <span>{{ $t('newOrder.BuildTime') }}</span>
            </div>
          </template>
          <template slot-scope="scope">
            <div class="formStyle">
              <div v-if="scope.row.isEdit === false">{{ scope.row.operateTime }}</div>
              <el-form-item
                v-else
                :prop="'addBatchFeeList.' + scope.$index + '.operateTime'"
                :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
              >
                <el-date-picker
                  v-model="scope.row.operateTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  style="width: 100%;"
                  :placeholder="$t('basicData.SelectADatetime')"
                />
              </el-form-item>
            </div>
          </template>
        </el-table-column>
        <!--录入人员-->
        <el-table-column prop="create_user" :label="$t('receivablePayable.EnterThePerson')" width="100">
          <template slot-scope="scope">
            <div class="formStyle">
              <div>{{ createUser[scope.row.createUser] }}</div>
            </div>
          </template>
        </el-table-column>
        <!--平摊方式-->
        <el-table-column
          v-if="addType === 1" prop="flatMethod" :label="$t('receivablePayable.FlatSharingMethod')"
          width="180"
        >
          <template slot="header">
            <div>
              <span style="color: #f00;margin-right: 5px;">*</span>
              <span>{{ $t('receivablePayable.FlatSharingMethod') }}</span>
            </div>
          </template>
          <template slot-scope="scope">
            <div v-if="scope.row.isEdit === false">{{ scope.row.flatMethod }}</div>
            <el-form-item
              v-else
              :prop="'addBatchFeeList.' + scope.$index + '.flatMethod'"
              :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-select
                v-model="scope.row.flatMethod"
                filterable
                clearable
                :placeholder="$t('operationCenter.PleaseSelect')"
                style="width: 100%;"
              >
                <!--按重量平摊-->
                <el-option key="0" :label="$t('receivablePayable.SpreadEquallyByWeight')" value="1" />
                <!--按票数平摊-->
                <el-option key="1" :label="$t('receivablePayable.SplitEquallyByTheNumberOfVotes')" value="2" />
                <!--指定金额-->
                <el-option key="2" :label="$t('receivablePayable.SpecifyTheAmount')" value="3" />
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <!--费用来源-->
        <el-table-column prop="feeSource" :label="$t('receivablePayable.SourceOfFees')" width="180">
          <template slot-scope="scope">
            <div>{{ FEE_SOURCE[scope.row.feeSource] && FEE_SOURCE[scope.row.feeSource].name || '' }}</div>
          </template>
        </el-table-column>
        <!--操作人-->
        <el-table-column prop="operator" :label="$t('basicData.Operator')" width="180">
          <template slot-scope="scope">
            <div class="formStyle">
              <div>{{ scope.row.operatorName }}</div>
            </div>
          </template>
        </el-table-column>
        <!--备注-->
        <el-table-column
          prop="remark"
          :label="$t('receivablePayable.remark')"
          min-width="160" :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.isEdit === false">{{ scope.row.remark }}</div>
            <el-form-item
              v-else
              :prop="'addBatchFeeList.' + scope.$index + '.remark'"
              :rules="{ required: false, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-input v-model="scope.row.remark" :placeholder="$t('GlobalSetObj.pleaseInputContent')" />
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
  </el-card>
</template>

<script>
import {
  apiPayableSiteExpenseDelete
} from '@/api/finance/receivables';
import { isSearchAllEmpty } from '@/views/finance/payableManage/feesPayable/enums.js';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { FEE_SOURCE } from '@/views/finance/payableManage/feesPayable/enums.js';

const ADD_DATA = {
  isEdit: true,
  feeName: '', // 费用类型
  businessType: '', // 业务类型
  feeCode: '', // 费用类型代码
  feeSource: 1, // 费用来源
  fee: '', // 应付金额
  isGenBill: 0, // 是否生成对账单
  isAccept: 0, // 是否核收
  isOut: 0, // 是否已出库
  billBatchNumber: '', // 对账批次号
  remark: '', // 备注
  createUser: '', // 录入人员
  operatorName: '', // 操作人
  operateTime: '', // 生成业务时间
  flatMethod: '', // 平摊方式
  chargeWeight: '' // 计费重量
};

export default {
  name: '',

  props: {
    // 1 添加 2 详情
    addType: {
      type: Number,
      required: true
    },

    // 费用详情
    list: {
      type: Array,
      required: true
    },

    // 外部搜索条件
    queryForm: {
      type: Object,
      default: () => {
        return {};
      }
    },

    // 费用类型
    feeTypeOption: {
      type: Array,
      required: true
    },

    // 费用是否可编辑
    isAllowEdit: {
      type: Boolean,
      default: true
    }
  },

  data() {
    const priceType = (rule, value, callback) => {
      const reg = new RegExp('(^' + rule.negative + '[1-9]\\d*(\\.\\d{1,' + rule.suffix + '})?$)|(^' + rule.negative + '0(\\.\\d{1,' + rule.suffix + '})?$)');
      const { required } = rule;
      if (!required) {
        // 非必填
        if (value) {
          if (!reg.test(value)) {
            this.adopt = false;
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // 格式不正确
          } else {
            this.adopt = true;
            return callback();
          }
        } else {
          return callback();
        }
      } else {
        if (value) {
          if (!reg.test(value)) {
            this.adopt = false;
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // 格式不正确
          } else {
            this.adopt = true;
            return callback();
          }
        } else {
          this.adopt = false;
          return callback(this.$i18n.t('basicData.CannotBeEmpty')); // '不能为空'
        }
      }
    };
    return {
      addBatchFee: {
        addBatchFeeList: []
      },
      priceType,
      createUser: null,
      FEE_SOURCE
    };
  },

  watch: {
    list: {
      handler(val) {
        this.addBatchFee.addBatchFeeList = val;
      },
      deep: true,
      immediate: true
    }
  },

  created() {
    this.createUser = getUserIdAndNameObj();
  },

  methods: {
    // 费用类型切换
    addChangeFee(scope) {
      const row = this.feeTypeOption.find(item => {
        return item.code === scope.row.feeCode;
      });

      if (!row) { return; }

      this.addBatchFee.addBatchFeeList[scope.$index].feeName = row.displayName;
      this.addBatchFee.addBatchFeeList[scope.$index].businessType = row.businessType;
      // this.feeTypeOption.map(item => {
      //   if (item.code === scope.row.feeCode) {
      //     this.addBatchFee.addBatchFeeList[scope.$index].feeName = item.displayName;
      //   }
      // });
    },

    // 添加费用
    addCost() {
      const data = {
        ...ADD_DATA,
        createUser: this.$store.state.user.id, // 录入人员
        operator: this.$store.state.user.id
      };
      // 新增
      if (this.addType === 1) {
        if (this.addBatchFee.addBatchFeeList.length === 0) {
          this.addBatchFee.addBatchFeeList.push(data);
        } else {
          this.$message.warning(this.$i18n.t('receivablePayable.OnlyOneChargeCanBeAdded')); // 只能添加一条费用
        }
      } else {
        this.addBatchFee.addBatchFeeList.push(data);
      }
    },

    // 添加
    submitAdd() {
      const { addBatchFeeList } = this.addBatchFee;
      const waybillNumberList = this.$refs.batchAddFeeOrderRef.getWaybillNumberList();
      const isEmptyCondition = isSearchAllEmpty(this.queryForm.params); // 搜索条件是否为空

      // 订单信息校验(如果外部搜索条件都为空)，同时订单信息列表长度为0
      {
        const len = waybillNumberList.length;
        if (isEmptyCondition && len === 0) {
          const str = this.$i18n.t('newOrder.order'); // 订单
          const str2 = this.$i18n.t('receivablePayable.AddAtLeastOneCharge'); // 最少添加一条费用
          this.$message.warning(str + str2);
          return undefined;
        }
      }

      // #region 费用校验
      {
        const len = addBatchFeeList.length;

        const str = this.$i18n.t('receivablePayable.FeeBreakdown'); // 费用明细
        const str2 = this.$i18n.t('receivablePayable.AddAtLeastOneCharge'); // '最少添加一条费用'
        const str3 = this.$i18n.t('receivablePayable.OnlyOneChargeCanBeAdded'); // '只能添加一条费用'

        if (len === 0) {
          this.$message.warning(str + str2); // '最少添加一条费用'
          return undefined;
        }

        if (len > 1) {
          this.$message.warning(str + str3); // '只能添加一条费用'
          return undefined;
        }
      }
      // #endregion

      this.$refs.addBatchFee.validate((valid) => {
        if (!valid) {
          return undefined;
        }

        const { params } = this.queryForm;

        const obj = {
          condition: params, // 外部搜索栏的搜索条件
          businessType: 0,
          isEmptyCondition: isEmptyCondition, // 搜索条件是否都为空
          orderNums: [],
          expenseSubList: addBatchFeeList
        };

        // 勾选的数据
        if (this.selectData.length > 0) {
          params.ids = this.selectData.map(item => {
            // ids.push(item.id);
            return item.id;
          });
          obj.isEmptyCondition = true; // 与后台约定如果勾选了，则给后台设置搜索标识条件为空
        }

        if (waybillNumberList.length) {
          obj.businessType = waybillNumberList[0].businessType; // 业务类型
          obj.orderNums = waybillNumberList.map(item => item.waybillNumber); // 业务单号
        }

        return obj;
      });
    },

    // 提交
    submitEdit() {
      const arr = [];
      this.$refs.addBatchFee.validate((valid) => {
        if (!valid) {
          return arr;
        }

        this.addBatchFee.addBatchFeeList.filter(item => {
          return item.isEdit;
        }).forEach(item => {
          arr.push(item);
        });
      });
      return arr;
    },

    // 删除新增
    deleteCost(scope) {
      if (scope.row.id) {
        apiPayableSiteExpenseDelete({ id: scope.row.id }).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
            this.addBatchFee.addBatchFeeList.splice(scope.$index, 1);
          }
        });
      } else {
        this.addBatchFee.addBatchFeeList.splice(scope.$index, 1);
      }
    }
  }

};
</script>

<style scoped>
.box-card {
  margin-bottom: 50px;
}
.box-card /deep/ .el-form-item {
  margin-bottom: 0;
}
</style>
