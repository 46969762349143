var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nodeTable" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.dataList, border: "", height: "350" },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("operationCenter.ind"),
              width: "60px",
              type: "index",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("ChannelManage.oddNumbers"),
              prop: "barCode",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }