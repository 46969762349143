<template>
  <div>
    <el-table
      :data="tableData" border :max-height="300"
      style="width: 100%"
    >
      <el-table-column
        :label="$t('newOrder.SerialNumber')"
        align="center"
        type="index"
        width="70"
      >
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <!--大件订单号-->
      <el-table-column
        :label="$t('orderDetails.LargeOrderNumber')"
        prop="orderNumber"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.orderNumber }}</span>
        </template>
      </el-table-column>
      <!--客户订单号-->
      <el-table-column
        :label="$t('collectionCenter.customerOrderNum')"
        prop="customerNumber"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.customerNumber }}</span>
        </template>
      </el-table-column>
      <!--总件数-->
      <el-table-column
        :label="$t('orderDetails.TotalNumber')"
        prop="quantity"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.quantity }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px;">
      <el-form label-position="top">
        <!--支付凭证-->
        <el-form-item :label="$t('orderDetails.PaymentVoucher')">
          <el-upload
            class="upload-demo"
            multiple
            action
            :accept="fileTypeArr.join(',')"
            :file-list="fileList"
            :on-remove="handleRemove"
            :before-upload="beforeUpload"
            :http-request="enclosureUpload"
            :on-success="uploadSuccess"
            :on-preview="viewImage"
            :limit="3"
          >
            <!--支付凭证-->
            <el-button
              size="small"
              type="primary"
              :disabled="fileList.length >= 3"
            >
              {{ $t('orderDetails.UploadVoucher') }}
            </el-button>
            <template slot="tip" class="el-upload__tip">
              <!--请上传图片，每张图片不能超过10MB-->
              {{ $t('orderDetails.PleaseUploadImages') }}
            </template>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
    <!-- 图片预览 -->
    <el-image-viewer v-if="showViewerSuccess" :on-close="closeViewerSuccess" :url-list="imgArrSuccess" />
  </div>
</template>
<script>
import { imgTypeArr } from '@/enums/evaluateEnums';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { uploadOos } from '@/utils/ali_oos_util';
import { addPaymentVoucherApi } from '@/api/orderApi';
export default {
  name: 'UploadVoucher',
  components: {
    ElImageViewer
  },
  props: {
    details: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      tableData: [],
      fileList: [],
      fileTypeArr: imgTypeArr, // 上传文件格式
      isUpload: true,
      imgArrSuccess: [],
      showViewerSuccess: false
    };
  },
  created() {
    this.tableData = [this.details];
    console.log(this.tableData, '=tableData');
  },
  methods: {
    // 上传校验
    beforeUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1);
      console.log(fileSuffix, '===fileSuffix');
      const whiteList = ['png', 'jpg', 'jpeg'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        // 上传文件支持'png', 'jpg', 'jpeg'等格式
        this.$message.error(this.$t('collectionCenter.uploadFileFormatJPG'));
        return false;
      }
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        // 上传文件大小不能超过 10MB
        this.$message.error(this.$t('collectionCenter.uploadNot10MB'));
        return false;
      }
    },
    // 删除
    handleRemove(file) {
      const uid = file.uid;
      this.fileList = this.fileList.filter(item => {
        return item.uid !== uid;
      });
    },
    // 上传oos
    async enclosureUpload(option) {
      this.isUpload = false;
      const file = option.file;
      const res = await uploadOos(file, 'workOrder');
      if (res.code === 200) {
        const obj = {
          uid: file.uid,
          name: file.name,
          url: res.url
        };
        console.log(obj, '===obj');
        this.fileList = [...this.fileList, obj];
      } else {
        this.$message.error(res.msg);
      }
    },
    // 上传成功
    uploadSuccess(response, file, fileList) {
      this.isUpload = true;
    },
    // 查看图片
    viewImage(file) {
      function getFileExtension(url) {
        // 使用正则表达式匹配最后一个点之后的部分作为文件扩展名
        const match = url.match(/\.([a-zA-Z0-9]+)(?:[\?#]|$)/);
        if (match) {
          return match[1].toLowerCase();
        }
        return '';
      }
      function isImage(extension) {
        // 定义常见的图片文件扩展名
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
        return imageExtensions.includes(extension);
      }
      const extension = getFileExtension(file.url);
      if (isImage(extension)) {
        this.imgArrSuccess = [file.url];
        this.showViewerSuccess = true;
      } else {
        window.open(file.url);
      }
    },
    // 关闭图片预览
    closeViewerSuccess() {
      this.showViewerSuccess = false;
    },
    // 提交
    submit() {
      if (this.isUpload === false) {
        this.$message.error(this.$t('orderDetails.PleaseWaitForTheUploadToComplete'));
        return;
      }
      if (this.fileList.length === 0) {
        this.$message.error(this.$t('orderDetails.PleaseUploadThePaymentVoucher'));
        return;
      }
      console.log(this.fileList, '===this.fileList');
      const urlArr = this.fileList.map(item => {
        return item.url;
      });
      const param = {
        orderId: this.details.id,
        voucherUrl: urlArr.join(','),
        clientOrExpress: 2
      };
      addPaymentVoucherApi(param).then(res => {
        if (res.code === 200) {
          this.$message.success(this.$t('collectionCenter.UploadSuccessful'));
          this.$emit('closeUploadVoucher');
        }
      });
    }
  }
};
</script>

<style scoped>

</style>
