var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { staticClass: "box-card" }, [
    _c(
      "div",
      { staticStyle: { "margin-bottom": "15px", "font-weight": "bold" } },
      [
        _c("span", { staticClass: "fontClsBold02" }, [
          _vm._v(
            _vm._s(_vm.$t("compensationManagement.FalseSigningForDelivery"))
          ),
        ]),
        _c(
          "span",
          { staticClass: "fontCls", staticStyle: { margin: "0 20px" } },
          [
            _vm._v(
              "虚假签收总票数: " +
                _vm._s(_vm.falseSignaturesVo.falseSignaturesTotal || 0)
            ),
          ]
        ),
        _c("span", { staticClass: "fontCls" }, [
          _vm._v(
            "虚假签收率: " +
              _vm._s(_vm.falseSignaturesVo.falseSignaturesRate || 0)
          ),
        ]),
      ]
    ),
    _c("div", {
      staticStyle: { height: "230px", width: "100%" },
      attrs: { id: "main02" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }