// 轨迹与状态同步监控

import request from '@/utils/request';

const baseURLAdmin = process.env.VUE_APP_ADMIN;
// 分页
export const apiReportTrackStateSyncMonitorPage = (data) => {
  // return request.post('/report/track/state/sync/monitor/page', data);
  return request({
    method: 'post',
    url: '/report/track/state/sync/monitor/page',
    data,
    baseURL: baseURLAdmin
  });
};

// 导出
export const apiReportTrackStateSyncMonitorExport = (data) => {
  // return request.post('/report/track/state/sync/monitor/export', data);
  return request({
    method: 'post',
    url: '/report/track/state/sync/monitor/export',
    data,
    baseURL: baseURLAdmin
  });
};
