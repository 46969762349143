<!-- 查询组件 -->
<template>
  <div class="query-container">
    <el-form
      ref="form"
      :model="params"
      class="form-container"
      size="small"
      :label-position="$i18n.locale !== 'zh' ? 'top' : 'right'"
      :label-width="$i18n.locale !== 'zh' ? 'auto' : '160px'"
    >
      <!--应付主体-->
      <el-form-item :label="$t('basicData.PayablePrincipal')">
        <QueryProviderCmp :val="params.providerId" @change="val => params.providerId = val" />
      </el-form-item>

      <!-- 网点 -->
      <el-form-item :label="$t('basicData.Outlets')">
        <QuerySiteCmp
          :val="params.siteId"
          :provider-id="params.providerId"
          @change="val => params.siteId = val"
        />
      </el-form-item>

      <template v-if="payableType !== 5">
        <!-- 客户 -->
        <el-form-item :label="$t('basicData.CustomerName')">
          <QueryCustomerCmp :val="params.customerId" @change="val => params.customerId = val" />
        </el-form-item>

        <!--客户编号-->
        <el-form-item :label="$t('basicData.CustomerCode')">
          <QueryCustomerCodeCmp :val="params.customerCodeId" @change="val => params.customerCodeId = val" />
        </el-form-item>
      </template>

      <!-- 业务时间 -->
      <el-form-item :label="$t('basicData.BusinessTime')">
        <el-date-picker
          v-model="queryDate"
          style="width: 100%"
          type="datetimerange"
          :picker-options="dateLimit"
          :default-time="['00:00:00', '23:59:59']"
          :range-separator="$t('operationCenter.to')"
          :start-placeholder="$t('operationCenter.startDate')"
          :end-placeholder="$t('operationCenter.endDate')"
          value-format="yyyy-MM-dd HH:mm:ss"
        />
      </el-form-item>

      <!-- 业务单号 / 订单号 / 客户单号 -->
      <el-form-item>
        <template slot="label">
          <el-select
            v-model="params.numberField"
            :placeholder="$t('operationCenter.PleaseSelect')"
          >
            <el-option
              v-for="item in ORDER_TYPE"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </template>
        <InputTextareaCmp
          ref="InputTextareaRef"
          :val="textVal"
          :max-length="20000"
          :autosize="{ minRows: 2, maxRows: 4}"
        />
      </el-form-item>

      <!-- 揽收操作人 -->
      <el-form-item v-if="[1,2].includes(payableType)" :label="payableType === 2 ? $t('GlobalSetObj.driver') : $t('basicData.揽收操作人')">
        <CollectorsCmp
          v-model="params.collectorIdList"
          @change="val => params.collectorIdList = val"
        />
      </el-form-item>
      <!-- 派件操作人 -->
      <el-form-item v-else-if="[3,4].includes(payableType)" :label="$t('basicData.派送操作人')">
        <SendCmp
          v-model="params.dispatcherList"
          :payable-type="payableType"
          @change="val => params.dispatcherList = val"
        />
      </el-form-item>

      <!--批次号-->
      <el-form-item v-if="selectKey === 1" :label="$t('basicData.BatchNo')">
        <el-input
          v-model="params.billBatchNumber"
          :readonly="isReadOnly"
        />
      </el-form-item>

      <!--收款单号-->
      <el-form-item v-if="selectKey === 2" :label="$t('receivablePayable.PaymentSlipNumber')">
        <el-input
          v-model="params.payableNumber"
          :readonly="isReadOnly"
        />
      </el-form-item>

      <!-- 是否已支付 -->
      <el-form-item v-if="[1,2,3,4].includes(payableType)" :label="$t('receivablePayable.WhetherItHasBeenPaid') ">
        <el-select
          v-model="params.isPaid"
          filterable
          clearable
          :placeholder="$t('operationCenter.PleaseSelect')"
          style="width: 100%;"
        >
          <el-option :label="$t('GlobalSetObj.yes')" :value="1" />
          <el-option :label="$t('GlobalSetObj.No')" :value="0" />
        </el-select>
      </el-form-item>

      <!-- 是否生成对账单 -->
      <el-form-item v-if="[1,2,3,4].includes(payableType)" :label="$t('receivablePayable.WhetherToGenerateAStatement')">
        <el-select
          v-model="params.isGenBill"
          filterable
          clearable
          :placeholder="$t('operationCenter.PleaseSelect')"
          style="width: 100%;"
        >
          <el-option :label="$t('GlobalSetObj.yes')" :value="1" />
          <el-option :label="$t('GlobalSetObj.No')" :value="0" />
        </el-select>
      </el-form-item>

      <!--业务类型-->
      <el-form-item
        v-if="[2].includes(payableType)"
        :label="$t('collectionCenter.businessT')"
      >
        <el-select
          v-model="params.orderBusinessType"
          clearable
          :placeholder="$t('basicData.PleaseSelect')"
          style="width: 100%;"
        >
          <!--0 揽收 1 快递-->
          <el-option
            v-for="item in ORDER_BUSINESS_TYPE"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <!--揽收方式-->
      <el-form-item
        v-if="[1, 2].includes(payableType)"
        :label="$t('collectionCenter.lanshouTypee')"
      >
        <el-select
          v-model="params.collectType"
          clearable
          :placeholder="$t('basicData.PleaseSelect')"
          style="width: 100%;"
        >
          <!--1 上门交件 2 上门揽收-->
          <el-option
            v-for="item in COLLECT_TYPE"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <!-- 费用类型 -->
      <el-form-item :label="$t('basicData.ExpenseType')" prop="feeName">
        <FeeCodeCmp :val="params.feeCode" :business-type="payableType" @change="val => params.feeCode = val" />
      </el-form-item>

      <!-- 订单状态 -->
      <el-form-item v-if="[1,2,3,4].includes(payableType)" :label="$t('receivablePayable.orderStatus')">
        <el-select
          v-model="params.status"
          filterable
          clearable
          :placeholder="$t('operationCenter.PleaseSelect')"
          style="width: 100%;"
        >
          <el-option
            v-for="item in ORDER_STATUS"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </el-form-item>

      <!-- 发件人州 -->
      <el-form-item v-if="[1,2].includes(payableType)" :label="$t('newOrder.SenderState')">
        <el-input
          v-model="params.senderState"
          clearable
          :placeholder="$t('GlobalSetObj.pleaseInput')"
        />
      </el-form-item>
      <!-- 收件人州 -->
      <el-form-item v-if="[3,4].includes(payableType)" :label="$t('newOrder.RecipientState')">
        <el-input
          v-model="params.receiveState"
          clearable
          :placeholder="$t('GlobalSetObj.pleaseInput')"
        />
      </el-form-item>
      <template v-if="[5].includes(payableType)">
        <!-- 装车人 -->
        <el-form-item :label="$t('GlobalSetObj.Carregador')">
          <el-select
            v-model="params.loadUserId"
            filterable
            clearable
            :placeholder="$t('operationCenter.PleaseSelect')"
            style="width: 100%;"
          >
            <el-option
              v-for="item in loadUserList"
              :key="item.id"
              :label="item.username + '（' + item.realName + '）'"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <!-- 始发网点 -->
        <el-form-item :label="$t('GlobalSetObj.StartingStation')">
          <QuerySiteCmp
            :val="params.startingSite"
            :site-status="3"
            @change="val => params.startingSite = val"
          />
        </el-form-item>
        <!-- 目的网点 -->
        <el-form-item :label="$t('GlobalSetObj.DestinationStation')">
          <QuerySiteCmp
            :val="params.destinationSite"
            :site-status="3"
            @change="val => params.destinationSite = val"
          />
        </el-form-item>
        <!-- 车型 -->
        <el-form-item :label="$t('vehicle.modelLabel')">
          <el-select
            v-model="params.vehicleType"
            filterable
            clearable
            :placeholder="$t('operationCenter.PleaseSelect')"
            style="width: 100%;"
          >
            <el-option
              v-for="item in Transport_VEHICLE_MODEL"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </template>
      <!-- 车签号 -->
      <el-form-item v-if="[2, 5].includes(payableType)" :label="$t('GlobalSetObj.NIV')">
        <el-input
          v-model="params.tagNumber"
          clearable
          :placeholder="$t('GlobalSetObj.pleaseInput')"
        />
      </el-form-item>
      <!-- 车牌号 -->
      <el-form-item v-if="[2].includes(payableType)" :label="$t('GlobalSetObj.licensePlateNumber')">
        <el-input
          v-model="params.vehicleNumber"
          clearable
          :placeholder="$t('GlobalSetObj.pleaseInput')"
        />
      </el-form-item>
      <!-- 商户号 -->
      <el-form-item v-if="[2].includes(payableType)" :label="$t('placeOrder.CommercialOwner')">
        <ComercianteCmp
          :val="params.merchantId"
          @change="onComercianteChange"
        />
      </el-form-item>
      <!-- 线路类型 -->
      <el-form-item v-if="[5].includes(payableType)" :label="$t('basicData.typeOfRoute')">
        <el-select
          v-model="params.routeType"
          filterable
          clearable
          :placeholder="$t('operationCenter.PleaseSelect')"
          style="width: 100%;"
        >
          <el-option
            v-for="item in LINE_TYPE"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>

    <div class="form-footer">
      <!--查 询-->
      <el-button
        type="primary"
        size="small"
        @click="onSearch"
      >{{ $t('basicData.Search') }}</el-button>
      <!--重 置-->
      <el-button
        type="success"
        size="small"
        @click="onReset"
      >{{ $t('basicData.reset') }}</el-button>
      <!--取 消-->
      <el-button
        size="small"
        @click="onClose"
      >{{ $t('basicData.Cancel') }}</el-button>
    </div>
  </div>
</template>

<script>
import QueryProviderCmp from '@/views/finance/cmp/Query/QueryProviderCmp.vue';
import QuerySiteCmp from '@/views/finance/cmp/Query/QuerySiteCmp.vue';
import QueryCustomerCmp from '@/views/finance/cmp/Query/QueryCustomerCmp.vue';
import QueryCustomerCodeCmp from '@/views/finance/cmp/Query/QueryCustomerCodeCmp.vue';
import InputTextareaCmp from '@/views/finance/cmp/Query/InputTextareaCmp.vue';
import CollectorsCmp from '@/views/finance/payableManage/feesPayable/component/CollectorsCmp.vue';
import SendCmp from '@/views/finance/payableManage/feesPayable/component/SendCmp.vue';
import FeeCodeCmp from '@/views/finance/payableManage/feesPayable/component/FeeCodeCmp.vue';
import ComercianteCmp from '@/views/finance/cmp/Query/ComercianteCmp.vue';
import { WAYBILL_STATUS, ORDER_TYPE_LIST, ORDER_BUSINESS_TYPE, COLLECT_TYPE, LINE_TYPE } from '@/views/finance/enums';
import {
  beforeSearch,
  DELIVERY,
  NUMBER_FIELD,
  SEARCH_PARAMS
} from '@/views/finance/payableManage/feesPayable/enums.js';
import { Transport_VEHICLE_MODEL } from '@/views/finance/utils.js';
import { getUserPage } from '@/api/user';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'QuerySearchCmp',

  components: {
    QueryProviderCmp,
    QueryCustomerCmp,
    QueryCustomerCodeCmp,
    QuerySiteCmp,
    InputTextareaCmp,
    CollectorsCmp,
    SendCmp,
    FeeCodeCmp,
    ComercianteCmp
  },

  props: {
    // 账单类型
    payableType: {
      type: [Number, String],
      default: () => null,
      required: true
    },
    // 是否只读
    isReadOnly: {
      type: Boolean,
      default: false
    },
    // 1 批次号 2 对账单
    selectKey: {
      type: [String, Number],
      default: ''
    },
    // 批次号/对账单号
    billBatchNumber: {
      type: [String, Number],
      default: ''
    }
  },

  data() {
    return {
      LINE_TYPE,
      COLLECT_TYPE,
      ORDER_BUSINESS_TYPE,
      Transport_VEHICLE_MODEL,
      params: {
        ...SEARCH_PARAMS
      },
      queryDate: [], // 业务时间
      textVal: '', // 输入框内容
      // 日期限制
      pickerMinDate: '',
      pickerRange: 15 * 24 * 60 * 60 * 1000, // 可选择日期范围 为15天
      loadUserList: [],
      ORDER_TYPE: [],
      ORDER_STATUS: []
    };
  },

  computed: {
    /**
     * 日期限制处理
     *    默认情况下限制15天
     *    如果输入了 业务单号 或 客户单号 或 批次号，则不限制时间
     */
    dateLimit() {
      const { textVal, pickerMinDate, pickerRange } = this;
      const { billBatchNumber, payableNumber } = this.params;

      // 默认限制15天
      return {
        // 设置不能选择的日期
        onPick: ({ minDate }) => {
          // 输入了内容
          if (textVal || billBatchNumber || payableNumber) {
            // 取消限制
            this.pickerMinDate = null;
            return;
          }

          if (minDate && pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          // 输入了内容
          if (textVal || billBatchNumber || payableNumber) {
            // 取消时间限制
            return false;
          }

          if (this.pickerMinDate) {
            return (time.getTime() > (pickerMinDate + pickerRange)) || (time.getTime() < (pickerMinDate - pickerRange));
          }
          return false;
        }
      };
    }
  },

  created() {
    // 订单类型
    this.ORDER_TYPE = [1, 2].includes(this.payableType) ? NUMBER_FIELD : DELIVERY;
    // 订单状态
    this.ORDER_STATUS = ([1, 2].includes(this.payableType) ? ORDER_TYPE_LIST : WAYBILL_STATUS).map(item => ({
      code: [1, 2].includes(this.payableType) ? item.collectStatus : item.code,
      label: [1, 2].includes(this.payableType) ? item.collectStatusName : item.label
    }));

    // 根据selectKey的值，决定是赋值给billBatchNumber还是payableNumber
    if (this.selectKey) {
      this.params[this.selectKey === 1 ? 'billBatchNumber' : 'payableNumber'] = this.billBatchNumber;
    }

    // 装车人
    this.getLoadUser();
  },

  methods: {
    // 获取装车人
    getLoadUser() {
      this.loading = true;
      const query = {
        pageNumber: 1,
        pageSize: 9999999,
        params: {
          accountState: '1',
          roleId: '77'
        }
      };
      getUserPage(query, false).then(res => {
        this.loadUserList = res.data.records;
      });
    },

    // 服务商变化
    onComercianteChange(val) {
      this.params.merchantId = val?.value;
      this.params.merchantCode = val?.code;
    },

    // 重置
    onReset() {
      this.params = { ...SEARCH_PARAMS };
      this.queryDate = [];
      this.textVal = '';
      this.$refs.InputTextareaRef.clear();
      // 根据selectKey的值，决定是赋值给billBatchNumber还是payableNumber
      if (this.selectKey) {
        this.params[this.selectKey === 1 ? 'billBatchNumber' : 'payableNumber'] = this.billBatchNumber;
      }
    },

    // 搜索
    onSearch() {
      const obj = cloneDeep(this.params);

      // 业务时间
      obj.startTime = this.queryDate?.length > 0 ? this.queryDate[0] : '';
      obj.endTime = this.queryDate?.length > 0 ? this.queryDate[1] : '';
      // 单号
      obj.numberList = this.$refs.InputTextareaRef.getValue();

      // 校验时间和单号二选一
      {
        const isBool = beforeSearch(obj);
        if (!isBool) {
          // this.$message.warning(this.$t('receivablePayable.搜索条件为空'));
          this.$message.warning('业务时间或单号不可为空');
          return;
        }
      }

      this.$emit('search', obj);
    },

    // 取消
    onClose() {
      this.$emit('close');
    }
  }

};
</script>

<style scoped lang="scss">
.form-footer {
  text-align: center;
}
</style>
