var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Box" }, [
    _vm.firstTab
      ? _c("div", { staticClass: "boxUrl containerData" }, [
          _c("div", { staticClass: "topBox" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("collectionCenter.dataVisual")))]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.updateTime,
                  expression: "updateTime",
                },
              ],
              staticClass: "updateTimeClass",
            },
            [
              _vm._v(
                _vm._s(_vm.$t("GlobalSetObj.UpdateTime")) +
                  "：" +
                  _vm._s(_vm.updateTime)
              ),
            ]
          ),
          _c("div", { staticClass: "dateBox" }, [
            _c(
              "span",
              {
                class: { d1: _vm.type == 4 },
                on: {
                  click: function ($event) {
                    return _vm.getData(4)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("collectionCenter.beforeYesterday")))]
            ),
            _c(
              "span",
              {
                class: { d1: _vm.type == 0 },
                on: {
                  click: function ($event) {
                    return _vm.getData(0)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("collectionCenter.yesterday")))]
            ),
            _c(
              "span",
              {
                class: { d1: _vm.type == 1 },
                on: {
                  click: function ($event) {
                    return _vm.getData(1)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("collectionCenter.sameDay")))]
            ),
            _c(
              "span",
              {
                class: { d1: _vm.type == 2 },
                on: {
                  click: function ($event) {
                    return _vm.getData(2)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("collectionCenter.nearlyAWeek")))]
            ),
            _c(
              "span",
              {
                class: { d1: _vm.type == 3 },
                on: {
                  click: function ($event) {
                    return _vm.getData(3)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("collectionCenter.sameMonth")))]
            ),
          ]),
          _c("div", { staticClass: "blueLineClass" }, [
            _c("img", { attrs: { src: _vm.transmitNode1, alt: "" } }),
            _c("img", {
              staticClass: "arrowClass",
              attrs: { src: _vm.arrow, alt: "" },
            }),
            _c("img", { attrs: { src: _vm.transmitNode2, alt: "" } }),
            _c("img", {
              staticClass: "arrowClass",
              attrs: { src: _vm.arrow, alt: "" },
            }),
            _c("img", { attrs: { src: _vm.transmitNode3, alt: "" } }),
            _c("img", {
              staticClass: "arrowClass",
              attrs: { src: _vm.arrow, alt: "" },
            }),
            _c("img", { attrs: { src: _vm.transmitNode4, alt: "" } }),
            _c("img", {
              staticClass: "arrowClass",
              attrs: { src: _vm.arrow, alt: "" },
            }),
            _c("img", { attrs: { src: _vm.transmitNode5, alt: "" } }),
            _c("img", {
              staticClass: "arrowClass",
              attrs: { src: _vm.arrow, alt: "" },
            }),
            _c("img", { attrs: { src: _vm.transmitNode6, alt: "" } }),
          ]),
          _c("div", { staticClass: "table-tab" }, [
            _c(
              "div",
              { staticClass: "tt-box" },
              [
                _c("span", { staticClass: "ttbox-top" }, [
                  _vm._v(_vm._s(_vm.$t("collectionCenter.MDLink"))),
                ]),
                _c("span", { staticStyle: { cursor: "default" } }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("collectionCenter.MDRangeTotal")) + "："
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#99bce8",
                        display: "inline !important",
                      },
                    },
                    [_vm._v(_vm._s(_vm.list1.value1))]
                  ),
                ]),
                _c("span", { staticStyle: { cursor: "default" } }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("collectionCenter.MDRetention")) + "："
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#99bce8",
                        display: "inline !important",
                      },
                    },
                    [_vm._v(_vm._s(_vm.list1.value2))]
                  ),
                ]),
                _c("div", { staticClass: "ttb-title" }, [
                  _vm._v(_vm._s(_vm.$t("collectionCenter.eachRegionMD")) + ":"),
                ]),
                _vm._l(_vm.tableData1, function (item, index) {
                  return _c("span", { key: index }, [
                    _c(
                      "span",
                      {
                        staticStyle: { cursor: "pointer", opacity: "0.6" },
                        on: {
                          click: function ($event) {
                            return _vm.routeClick(item.val1)
                          },
                        },
                      },
                      [
                        _vm._v(_vm._s(item.msg) + "："),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#99bce8",
                              display: "inline !important",
                            },
                          },
                          [_vm._v(_vm._s(item.sum))]
                        ),
                      ]
                    ),
                  ])
                }),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "tt-box" },
              [
                _c("span", { staticClass: "ttbox-top" }, [
                  _vm._v(_vm._s(_vm.$t("collectionCenter.MDToTransitCenter"))),
                ]),
                _c("span", { staticStyle: { cursor: "default" } }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("collectionCenter.MDRangeTotal")) + "："
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#99bce8",
                        display: "inline !important",
                      },
                    },
                    [_vm._v(_vm._s(_vm.list2.value1))]
                  ),
                ]),
                _c("span", { staticStyle: { cursor: "default" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("collectionCenter.actualLoadingTotal")) +
                      "："
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#99bce8",
                        display: "inline !important",
                      },
                    },
                    [_vm._v(_vm._s(_vm.list2.value2))]
                  ),
                ]),
                _c("div", { staticClass: "ttb-title" }, [
                  _vm._v(_vm._s(_vm.$t("collectionCenter.MDSendTo")) + ":"),
                ]),
                _vm._l(_vm.tableData2, function (item, index) {
                  return _c("span", { key: index }, [
                    _c(
                      "span",
                      {
                        style: item.val1
                          ? "cursor: pointer;opacity: 0.6;"
                          : "cursor: default;",
                        on: {
                          click: function ($event) {
                            return _vm.detailClick(
                              item.val1,
                              item.val2,
                              item.val3
                            )
                          },
                        },
                      },
                      [
                        _vm._v(_vm._s(item.msg) + "："),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#99bce8",
                              display: "inline !important",
                            },
                          },
                          [_vm._v(_vm._s(item.sum))]
                        ),
                      ]
                    ),
                  ])
                }),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "tt-box" },
              [
                _c("span", { staticClass: "ttbox-top" }, [
                  _vm._v(_vm._s(_vm.$t("collectionCenter.arrivexxx"))),
                ]),
                _c("span", { staticStyle: { cursor: "default" } }, [
                  _vm._v(_vm._s(_vm.$t("collectionCenter.loadTotal")) + "："),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#99bce8",
                        display: "inline !important",
                      },
                    },
                    [_vm._v(_vm._s(_vm.totalLoad))]
                  ),
                ]),
                _c("span", { staticStyle: { cursor: "default" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("collectionCenter.actualUnloadedTotal")) +
                      "："
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#99bce8",
                        display: "inline !important",
                      },
                    },
                    [_vm._v(_vm._s(_vm.totalUnload))]
                  ),
                ]),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#8fcadb",
                      "text-decoration": "underline",
                      "text-underline-offset": "2px",
                      cursor: "pointer",
                    },
                    on: {
                      click: function ($event) {
                        _vm.transAnomaly(
                          2,
                          "",
                          _vm.$t("collectionCenter.arrivexxx")
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("collectionCenter.transitCenterAnomaly"))
                    ),
                  ]
                ),
                _c("div", { staticClass: "ttb-title" }, [
                  _vm._v(
                    _vm._s(_vm.$t("collectionCenter.arriveTransitCenters")) +
                      ":"
                  ),
                ]),
                _vm._l(_vm.tableData3, function (item, index) {
                  return _c("span", { key: index }, [
                    _c(
                      "span",
                      {
                        style: item.val1
                          ? "cursor: pointer;opacity: 0.6;"
                          : "cursor: default;",
                        on: {
                          click: function ($event) {
                            return _vm.detailClick(
                              item.val1,
                              item.val2,
                              item.val3
                            )
                          },
                        },
                      },
                      [
                        _vm._v(_vm._s(item.msg) + "："),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#99bce8",
                              display: "inline !important",
                            },
                          },
                          [_vm._v(_vm._s(item.sum))]
                        ),
                      ]
                    ),
                  ])
                }),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "tt-box" },
              [
                _c("span", { staticClass: "ttbox-top" }, [
                  _vm._v(
                    _vm._s(_vm.$t("collectionCenter.transferCenterToGLP"))
                  ),
                ]),
                _c("span", { staticStyle: { cursor: "default" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("collectionCenter.actualUnloadedTotal")) +
                      "："
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#99bce8",
                        display: "inline !important",
                      },
                    },
                    [_vm._v(_vm._s(_vm.list4.value1))]
                  ),
                ]),
                _c("span", { staticStyle: { cursor: "default" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("collectionCenter.actualLoadingTotal")) + "："
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#99bce8",
                        display: "inline !important",
                      },
                    },
                    [_vm._v(_vm._s(_vm.list4.value2))]
                  ),
                ]),
                _c("div", { staticClass: "ttb-title" }, [
                  _vm._v(_vm._s(_vm.$t("collectionCenter.SendToGLP")) + ":"),
                ]),
                _vm._l(_vm.tableData4, function (item, index) {
                  return _c("span", { key: index }, [
                    _c(
                      "span",
                      {
                        style: item.val1
                          ? "cursor: pointer;opacity: 0.6;"
                          : "cursor: default;",
                        on: {
                          click: function ($event) {
                            return _vm.detailClick(
                              item.val1,
                              item.val2,
                              item.val3
                            )
                          },
                        },
                      },
                      [
                        _vm._v(_vm._s(item.msg) + "："),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#99bce8",
                              display: "inline !important",
                            },
                          },
                          [_vm._v(_vm._s(item.sum))]
                        ),
                      ]
                    ),
                  ])
                }),
              ],
              2
            ),
            _c("div", { staticClass: "tt-box" }, [
              _c("span", { staticClass: "ttbox-top" }, [
                _vm._v(_vm._s(_vm.$t("collectionCenter.GLPUnloadingProcess"))),
              ]),
              _c("span", { staticStyle: { cursor: "default" } }, [
                _vm._v(
                  _vm._s(_vm.$t("collectionCenter.shouldArrivedGLPTotal")) +
                    "："
                ),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#99bce8",
                      display: "inline !important",
                    },
                  },
                  [_vm._v(_vm._s(_vm.dataList.shouldArrivalGlpCount))]
                ),
              ]),
              _c("span", { staticStyle: { cursor: "default" } }, [
                _vm._v(
                  _vm._s(_vm.$t("collectionCenter.actuallyArrivedGLPTotal")) +
                    "："
                ),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#99bce8",
                      display: "inline !important",
                    },
                  },
                  [_vm._v(_vm._s(_vm.dataList.actualArrivalGlpCount))]
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      _vm.detailClick(
                        4,
                        1,
                        _vm.$t("collectionCenter.glpUnloadTotal")
                      )
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("collectionCenter.glpUnloadTotal")) +
                      "："
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#99bce8",
                        display: "inline !important",
                      },
                    },
                    [_vm._v(_vm._s(_vm.dataList.glpNoLoadCount))]
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "tt-box" }, [
              _c("span", { staticClass: "ttbox-top" }, [
                _vm._v(_vm._s(_vm.$t("collectionCenter.handoverToSHEIN"))),
              ]),
              _c("span", { staticStyle: { cursor: "default" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("collectionCenter.shouldSignedGrossAmount")) +
                    "： "
                ),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#99bce8",
                      display: "inline !important",
                    },
                  },
                  [_vm._v(_vm._s(_vm.leftAll.shouldSignCount))]
                ),
              ]),
              _c("span", { staticStyle: { cursor: "default" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("collectionCenter.actualSignGrossAmount")) +
                    "： "
                ),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#99bce8",
                      display: "inline !important",
                    },
                  },
                  [_vm._v(_vm._s(_vm.leftAll.actualSign))]
                ),
              ]),
              _c("span", { staticStyle: { cursor: "default" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("collectionCenter.unsignedGrossAmount")) +
                    "： "
                ),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#99bce8",
                      display: "inline !important",
                    },
                  },
                  [_vm._v(_vm._s(_vm.leftAll.unSignCount))]
                ),
              ]),
              _c("span", { staticStyle: { cursor: "default" } }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("collectionCenter.RateOfSigning")) + "： "
                ),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#99bce8",
                      display: "inline !important",
                    },
                  },
                  [_vm._v(_vm._s(_vm.leftAll.signRate) + "%")]
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      _vm.toSecondaryLose(
                        _vm.$t("collectionCenter.SuspectedLostPackage")
                      )
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("collectionCenter.abnormalParcelsTotal")) +
                      "： "
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#99bce8",
                        display: "inline !important",
                      },
                    },
                    [_vm._v(_vm._s(_vm.leftAll.suspectedLostCount))]
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "tab-2" },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "flex-wrap": "wrap" },
                  attrs: { type: "flex", justify: "start" },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 9 } },
                    [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "collectionCenter.CollectionPunctualityRate"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c(
                        "el-row",
                        {
                          staticStyle: {
                            "margin-right": "38px",
                            "margin-left": "5px",
                            display: "flex",
                            "justify-content": "space-between",
                            "align-items": "center",
                            "flex-wrap": "wrap",
                          },
                        },
                        _vm._l(_vm.tableData5, function (item, index) {
                          return _c(
                            "el-col",
                            {
                              key: index,
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { span: 8 },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "percentBox",
                                  on: {
                                    click: function ($event) {
                                      return _vm.toSecondarySummary(
                                        item.val1,
                                        item.val2
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: { src: _vm.percentBG, alt: "" },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      style:
                                        item.sum > 50
                                          ? "color: #fff"
                                          : item.sum == 50
                                          ? "color: yellow"
                                          : item.sum < 50
                                          ? "color: red"
                                          : "",
                                    },
                                    [_vm._v(" " + _vm._s(item.sum) + "% ")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-align": "center",
                                    "margin-top": "5px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toSecondarySummary(
                                        item.val1,
                                        item.val2
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      style:
                                        item.sum > 50
                                          ? "color: #fff;cursor: pointer"
                                          : item.sum == 50
                                          ? "color: yellow;cursor: pointer"
                                          : item.sum < 50
                                          ? "color: red;cursor: pointer"
                                          : "",
                                    },
                                    [_vm._v(" " + _vm._s(item.msg) + " ")]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 9 } }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "margin-right": "10px",
                          "margin-left": "22px",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("collectionCenter.auditLink")) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-left": "32px",
                          display: "flex",
                          "justify-content": "space-around",
                          "align-items": "center",
                          "flex-wrap": "wrap",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "bagTicket-box",
                            on: {
                              click: function ($event) {
                                return _vm.inspectClick(1)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "white-space": "nowrap",
                                  "font-size": "14px",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "collectionCenter.actualCheckedBagsNum"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "bt-sum" }, [
                              _vm._v(
                                _vm._s(_vm.inspectList.actualAuditBagCount)
                              ),
                            ]),
                            _c("div", { staticClass: "bt-img" }, [
                              _c("img", {
                                staticStyle: { width: "100%" },
                                attrs: { src: _vm.wavy, alt: "" },
                              }),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "bagTicket-box",
                            on: {
                              click: function ($event) {
                                return _vm.inspectClick(2)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "white-space": "nowrap",
                                  "font-size": "14px",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("collectionCenter.abnormalBagNum")
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "bt-sum",
                                staticStyle: { color: "yellow" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.inspectList.auditAbnormalBagCount)
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "bt-img" }, [
                              _c("img", {
                                staticStyle: { width: "100%" },
                                attrs: { src: _vm.wavy, alt: "" },
                              }),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "bagTicket-box",
                            on: {
                              click: function ($event) {
                                return _vm.inspectClick(3)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "white-space": "nowrap",
                                  "font-size": "14px",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("collectionCenter.actualAuditVotes")
                                  )
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "bt-sum" }, [
                              _vm._v(
                                _vm._s(_vm.inspectList.actualAuditTicketCount)
                              ),
                            ]),
                            _c("div", { staticClass: "bt-img" }, [
                              _c("img", {
                                staticStyle: { width: "100%" },
                                attrs: { src: _vm.wavy, alt: "" },
                              }),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "bagTicket-box",
                            on: {
                              click: function ($event) {
                                return _vm.inspectClick(4)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "white-space": "nowrap",
                                  "font-size": "14px",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "collectionCenter.abnormalTotalVotes"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "bt-sum",
                                staticStyle: { color: "yellow" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.inspectList.auditAbnormalTicketCount
                                  )
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "bt-img" }, [
                              _c("img", {
                                staticStyle: { width: "100%" },
                                attrs: { src: _vm.wavy, alt: "" },
                              }),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "min-width": "242px" },
                      attrs: { span: 6 },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "inline-block",
                            "margin-left": "22px",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("collectionCenter.anomalyRate")) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "abnormal-box" },
                        [
                          _c("img", {
                            attrs: { src: _vm.abnormalBG, alt: "" },
                          }),
                          _vm.anomalyRate <= 0
                            ? _c("span", { staticClass: "circle-span" }, [
                                _vm._v(_vm._s(_vm.anomalyRate)),
                              ])
                            : _c("el-progress", {
                                staticClass: "circle",
                                attrs: {
                                  type: "circle",
                                  percentage: _vm.anomalyRate,
                                  format: _vm.format,
                                  width: 188,
                                  "text-color": "yellow",
                                  color: "#36A5ED",
                                },
                              }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _c("div", { staticClass: "tabsBox" }, [
          _vm.showPunctualityCountry
            ? _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    top: "0",
                    left: "0",
                    bottom: "0",
                    right: "0",
                    "z-index": "1000",
                    background: "#fff",
                  },
                },
                [
                  _c("punctuality", {
                    key: _vm.showPunctualityCountry,
                    attrs: {
                      "time-form": _vm.timeForm,
                      "date-type": _vm.type,
                      "tab-name": _vm.tabName,
                    },
                    on: { closeWindow: _vm.closeWindow },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showSuspectedLoss
            ? _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    top: "0",
                    left: "0",
                    bottom: "0",
                    right: "0",
                    "z-index": "1000",
                    background: "#fff",
                  },
                },
                [
                  _c("suspected-loss", {
                    key: _vm.showSuspectedLoss,
                    attrs: {
                      "time-form": _vm.timeForm,
                      "date-type": _vm.type,
                      "tab-name": _vm.tabName,
                    },
                    on: { closeWindow: _vm.closeWindow },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showDetailMsg
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "tabsClose" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "small" },
                          on: { click: _vm.closeTabsClick },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("collectionCenter.close")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("detailMsg", { attrs: { form: _vm.queryFormList } }),
                ],
                1
              )
            : _vm._e(),
          _vm.showGlpNotUnloaded
            ? _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    top: "0",
                    left: "0",
                    bottom: "0",
                    right: "0",
                    "z-index": "1000",
                    background: "#fff",
                  },
                },
                [
                  _c("glp-not-unloaded", {
                    key: _vm.showGlpNotUnloaded,
                    attrs: {
                      "time-form": _vm.timeForm,
                      "date-type": _vm.type,
                      "tab-name": _vm.tabName,
                    },
                    on: { closeWindow: _vm.closeWindow },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.transAnomalyShow
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "tabsClose" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "small" },
                          on: { click: _vm.closeTaClick },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("collectionCenter.close")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("transAnomaly", {
                    attrs: {
                      "ta-form": _vm.transAnomalyForm,
                      "click-val": _vm.clickValue,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.inspectShow
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "tabsClose" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "small" },
                          on: { click: _vm.closeTaClick },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("collectionCenter.close")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("inspect", { attrs: { "in-form": _vm.inspectForm } }),
                ],
                1
              )
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }