var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-form",
            { attrs: { size: "small" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.orderCreateTime") } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      clearable: false,
                      "default-time": ["00:00:00", "23:59:59"],
                      format:
                        _vm.$i18n.locale === "zh" ? _vm.zhDate : _vm.ptDate,
                      placeholder: _vm.$t("newOrder.SelectDatetime"),
                      "picker-options": _vm.pickerOptions,
                      "range-separator": "~",
                      type: "datetimerange",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                    },
                    on: { blur: _vm.refreshDate },
                    model: {
                      value: _vm.timeValue,
                      callback: function ($$v) {
                        _vm.timeValue = $$v
                      },
                      expression: "timeValue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-search",
                    size: "small",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.searchTable(1)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("GlobalSetObj.search")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh-left", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.searchTable(2)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("operationCenter.reset")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.tableData,
                "span-method": _vm.arraySpanMethod,
                "header-cell-style": {
                  background: "#f2f2f2",
                  color: "black",
                  textAlign: "center",
                },
                "cell-class-name": _vm.cellClassName,
                border: "",
              },
            },
            [
              _vm._l(_vm.columnList, function (item, ind) {
                return _c("el-table-column", {
                  key: ind,
                  attrs: {
                    align: item.align,
                    label: item.label,
                    "min-width": item.width,
                    prop: item.prop,
                    "show-overflow-tooltip": true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            item.prop === "businessModule"
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("collectionCenter.expressDelivery")
                                    )
                                  ),
                                ])
                              : item.prop === "businessType"
                              ? _c("div", [
                                  scope.row[item.prop] === "local"
                                    ? _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("collectionCenter.local")
                                          )
                                        ),
                                      ])
                                    : scope.row[item.prop] === "crossBorder"
                                    ? _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "collectionCenter.crossBorder"
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row[item.prop] !== "" &&
                                          scope.row[item.prop] !== null &&
                                          scope.row[item.prop] !== undefined
                                          ? scope.row[item.prop]
                                          : "--"
                                      ) +
                                      " "
                                  ),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _vm._l(_vm.currentColumnList, function (item, ind) {
                return _c("el-table-column", {
                  key: ind + item.prop,
                  attrs: {
                    align: item.align,
                    label: item.label,
                    "min-width": item.width,
                    prop: item.prop,
                    "show-overflow-tooltip": true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row[item.prop] !== "" &&
                                      scope.row[item.prop] !== null &&
                                      scope.row[item.prop] !== undefined
                                      ? scope.row[item.prop]
                                      : "--"
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }