var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "box-card" },
    [
      _c("div", { staticStyle: { "margin-bottom": "15px" } }, [
        _c(
          "span",
          { staticClass: "fontClsBold02", staticStyle: { color: "#584cb1" } },
          [_vm._v("破损统计")]
        ),
        _c("span", [_vm._v("(全部破损/部分破损)")]),
      ]),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.wornStatisticsVOS,
            "header-cell-style": { color: "black" },
            height: "375px",
            stripe: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("navbar.siteName"),
              align: "center",
              prop: "siteName",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "破损票数", prop: "wornCount" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.siteId === null
                      ? _c("div", { staticClass: "fontClsBold01" }, [
                          _vm._v(
                            " " + _vm._s(scope.row.wornCount || "--") + " "
                          ),
                        ])
                      : _c("div", [
                          _vm._v(_vm._s(scope.row.wornCount || "--")),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "破损率", prop: "wornRate" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.siteId === null
                      ? _c("div", { staticClass: "fontClsBold01" }, [
                          _vm._v(
                            " " + _vm._s(scope.row.wornRate || "--") + " "
                          ),
                        ])
                      : _c("div", [_vm._v(_vm._s(scope.row.wornRate || "--"))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "当月累计破损票数",
              prop: "wornCountMonth",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.siteId === null
                      ? _c("div", { staticClass: "fontClsBold01" }, [
                          _vm._v(
                            " " + _vm._s(scope.row.wornCountMonth || "--") + " "
                          ),
                        ])
                      : _c("div", [
                          _vm._v(_vm._s(scope.row.wornCountMonth || "--")),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "月破损率",
              prop: "wornRateMonth",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.siteId === null
                      ? _c("div", { staticClass: "fontClsBold01" }, [
                          _vm._v(
                            " " + _vm._s(scope.row.wornRateMonth || "--") + " "
                          ),
                        ])
                      : _c("div", [
                          _vm._v(_vm._s(scope.row.wornRateMonth || "--")),
                        ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }