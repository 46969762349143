<!-- 费用明细表 -->
<template>
  <div class="box-card">
    <el-form
      ref="addBatchFee" :model="addBatchFee" label-width="0"
      size="small"
    >
      <el-table :data="addBatchFee.addBatchFeeList" border>
        <el-table-column width="55" align="center" fixed="left">
          <template slot="header">
            <span class="el-icon-plus addStyle" @click="addCost()" />
          </template>
          <template slot-scope="scope">
            <!--确定删除吗？ -->
            <el-popconfirm
              v-if="!scope.row.isAccept"
              v-permit:remove="'btn:finance:costManagement:costsFee:Del'"
              :title="$t('basicData.AreYouSureDelete')"
              @confirm="deleteCost(scope)"
            >
              <span slot="reference" class="el-icon-delete deleteStyle" />
            </el-popconfirm>
          </template>
        </el-table-column>
        <!--业务类型-->
        <el-table-column v-if="addType === 1" prop="businessType" width="180">
          <template slot="header">
            <div>
              <span style="color: #f00;margin-right: 5px;">*</span>
              <span>{{ $t('collectionCenter.businessT') }}</span>
            </div>
          </template>
          <template slot-scope="scope">
            <div class="formStyle">
              <div v-if="scope.row.isEdit === false">{{ PAYABLE_TYPE[scope.row.businessType].label }}</div>
              <el-form-item
                v-else
                :prop="'addBatchFeeList.' + scope.$index + '.businessType'"
                :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
              >
                <el-select
                  v-model="scope.row.businessType"
                  filterable
                  clearable
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  style="width: 100%;"
                  @change="changeBusinessType(scope)"
                >
                  <el-option
                    v-for="item in PAYABLE_TYPE"
                    :key="item.id"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </div>
          </template>
        </el-table-column>
        <!--成本网点-->
        <el-table-column v-if="addType === 1" prop="site" width="180">
          <template slot="header">
            <div>
              <span style="color: #f00;margin-right: 5px;">*</span>
              <span>{{ $t('receivablePayable.costOutlets') }}</span>
            </div>
          </template>
          <template slot-scope="scope">
            <div class="formStyle">
              <div v-if="scope.row.isEdit === false">{{ scope.row.siteName }}</div>
              <el-form-item
                v-else
                :prop="'addBatchFeeList.' + scope.$index + '.siteId'"
                :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
              >
                <el-select
                  v-model="scope.row.siteId"
                  filterable
                  clearable
                  remote
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  style="width: 100%;"
                >
                  <el-option
                    v-for="item in siteOption"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </div>
          </template>
        </el-table-column>
        <!--费用类型-->
        <el-table-column prop="feeName" width="180">
          <template slot="header">
            <div>
              <span style="color: #f00;margin-right: 5px;">*</span>
              <span>{{ $t('basicData.ExpenseType') }}</span>
            </div>
          </template>
          <template slot-scope="scope">
            <div class="formStyle">
              <div v-if="scope.row.isEdit === false">{{ scope.row.feeName }}</div>
              <el-form-item
                v-else
                :prop="'addBatchFeeList.' + scope.$index + '.feeCode'"
                :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
              >
                <el-select
                  v-model="scope.row.feeCode"
                  filterable
                  clearable
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  style="width: 100%;"
                  @focus="feeTypeFocus(scope)"
                  @change="addChangeFee(scope)"
                >
                  <el-option
                    v-for="item in feeOptionList"
                    :key="item.id"
                    :disabled="item.disabled"
                    :label="item.displayName"
                    :value="item.code"
                  />
                </el-select>
              </el-form-item>
            </div>
          </template>
        </el-table-column>
        <template v-if="addType === 2">
          <!--是否生成对账单-->
          <el-table-column prop="isGenBill" :label="$t('receivablePayable.WhetherToGenerateAStatement')" width="180">
            <template slot-scope="scope">
              <div>{{ scope.row.isGenBill ? $t('GlobalSetObj.yes') : $t('GlobalSetObj.No') }}</div>
            </template>
          </el-table-column>
          <!--是否核付-->
          <el-table-column prop="isAccept" :label="$t('receivablePayable.approvedOrNot')" width="100">
            <template slot-scope="scope">
              <div>{{ scope.row.isAccept ? $t('GlobalSetObj.yes') : $t('GlobalSetObj.No') }}</div>
            </template>
          </el-table-column>
          <!--计费重量-->
          <el-table-column prop="chargeWeight" :label="$t('receivablePayable.BillableWeight')" width="100" />
        </template>
        <!--应付金额-->
        <el-table-column prop="fee" width="140">
          <template slot="header">
            <div>
              <span style="color: #f00;margin-right: 5px;">*</span>
              <span>{{ $t('receivablePayable.AmountDue') }}</span>
            </div>
          </template>
          <template slot-scope="scope">
            <div class="formStyle">
              <div v-if="scope.row.isEdit === false">{{ scope.row.fee }}</div>
              <el-form-item
                v-else
                :prop="'addBatchFeeList.' + scope.$index + '.fee'"
                :rules="{ required: true, suffix: 2, negative: '-?', validator: priceType, trigger: ['blur', 'change'] }"
              >
                <el-input v-model="scope.row.fee" />
              </el-form-item>
            </div>
          </template>
        </el-table-column>
        <!--生成时间-->
        <el-table-column prop="operateTime" width="220">
          <template slot="header">
            <div>
              <span style="color: #f00;margin-right: 5px;">*</span>
              <span>{{ $t('newOrder.BuildTime') }}</span>
            </div>
          </template>
          <template slot-scope="scope">
            <div class="formStyle">
              <div v-if="scope.row.isEdit === false">{{ scope.row.operateTime }}</div>
              <el-form-item
                v-else
                :prop="'addBatchFeeList.' + scope.$index + '.operateTime'"
                :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
              >
                <el-date-picker
                  v-model="scope.row.operateTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  style="width: 100%;"
                  :placeholder="$t('basicData.SelectADatetime')"
                />
              </el-form-item>
            </div>
          </template>
        </el-table-column>
        <!--平摊方式-->
        <el-table-column v-if="addType === 1" prop="flatMethod" width="180">
          <template slot="header">
            <div>
              <span style="color: #f00;margin-right: 5px;">*</span>
              <span>{{ $t('receivablePayable.FlatSharingMethod') }}</span>
            </div>
          </template>
          <template slot-scope="scope">
            <div v-if="scope.row.isEdit === false">{{ scope.row.flatMethod }}</div>
            <el-form-item
              v-else
              :prop="'addBatchFeeList.' + scope.$index + '.flatMethod'"
              :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-select
                v-model="scope.row.flatMethod"
                filterable
                clearable
                :placeholder="$t('operationCenter.PleaseSelect')"
                style="width: 100%;"
              >
                <!--按重量平摊-->
                <el-option key="0" :label="$t('receivablePayable.SpreadEquallyByWeight')" value="1" />
                <!--按票数平摊-->
                <el-option key="1" :label="$t('receivablePayable.SplitEquallyByTheNumberOfVotes')" value="2" />
                <!--指定金额-->
                <el-option key="2" :label="$t('receivablePayable.SpecifyTheAmount')" value="3" />
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <template v-if="addType === 2">
          <!--录入人员-->
          <el-table-column prop="create_user" :label="$t('receivablePayable.EnterThePerson')" width="100">
            <template slot-scope="scope">
              <div class="formStyle">
                <div>{{ createUser[scope.row.createUser] }}</div>
              </div>
            </template>
          </el-table-column>
          <!--操作人-->
          <el-table-column prop="operator" :label="$t('basicData.Operator')" width="180">
            <template slot-scope="scope">
              <div class="formStyle">
                <div>{{ scope.row.operatorName }}</div>
              </div>
            </template>
          </el-table-column>
        </template>
        <!--备注-->
        <el-table-column
          prop="remark"
          :label="$t('receivablePayable.remark')"
          min-width="160" :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.isEdit === false">{{ scope.row.remark }}</div>
            <el-form-item
              v-else
              :prop="'addBatchFeeList.' + scope.$index + '.remark'"
              :rules="{ required: false, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-input
                v-model="scope.row.remark" :placeholder="$t('GlobalSetObj.pleaseInputContent')" type="textarea"
                maxlength="200" :autosize="{ minRows: 1, maxRows: 3 }"
              />
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
  </div>
</template>

<script>
import {
  getDeleteCostSiteExpenseFee
} from '@/api/finance/costManagement.js';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { PAYABLE_TYPE } from '@/views/finance/payableManage/feesPayable/enums.js';

const ADD_DATA = {
  isEdit: true, // 是否可编辑
  businessType: '', // 业务类型
  siteId: '', // 网点代码
  feeName: '', // 费用类型
  feeCode: '', // 费用类型代码
  fee: '', // 应付金额
  operateTime: '', // 生成时间
  flatMethod: '', // 平摊方式
  remark: '', // 备注
  // isGenBill: 0, // 是否生成对账单
  // isAccept: 0, // 是否核收
  // billBatchNumber: '', // 对账批次号
  createUser: '', // 录入人员
  operator: '' // 操作人
  // chargeWeight: '' // 计费重量
};

export default {
  name: '',

  props: {
    // 1 添加 2 详情
    addType: {
      type: Number,
      required: true
    },

    // 费用明细
    list: {
      type: Array,
      required: true
    },

    // 费用是否可编辑
    isAllowEdit: {
      type: Boolean,
      default: true
    },

    // 业务类型
    businessType: {
      type: [String, Number],
      default: ''
    }
  },

  data() {
    const priceType = (rule, value, callback) => {
      const reg = new RegExp('(^' + rule.negative + '[1-9]\\d*(\\.\\d{1,' + rule.suffix + '})?$)|(^' + rule.negative + '0(\\.\\d{1,' + rule.suffix + '})?$)');
      const { required } = rule;
      if (!required) {
        // 非必填
        if (value) {
          if (!reg.test(value)) {
            this.adopt = false;
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // 格式不正确
          } else {
            this.adopt = true;
            return callback();
          }
        } else {
          return callback();
        }
      } else {
        if (value) {
          if (!reg.test(value)) {
            this.adopt = false;
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // 格式不正确
          } else {
            this.adopt = true;
            return callback();
          }
        } else {
          this.adopt = false;
          return callback(this.$i18n.t('basicData.CannotBeEmpty')); // '不能为空'
        }
      }
    };
    return {
      addBatchFee: {
        addBatchFeeList: []
      },
      siteOption: [],
      feeOptionList: [],
      priceType,
      createUser: null,
      PAYABLE_TYPE
    };
  },

  watch: {
    list: {
      handler(val) {
        this.addBatchFee.addBatchFeeList = val;
      },
      deep: true,
      immediate: true
    }
  },

  created() {
    this.createUser = getUserIdAndNameObj();
    this.siteOption = this.$store.state.feesPayable.outletsList;
    this.feeOptionList = this.$store.state.feesPayable.feeTypeOption;
  },

  methods: {
    // 业务类型切换，重置费用类型
    changeBusinessType(scope) {
      this.addBatchFee.addBatchFeeList[scope.$index].feeName = '';
      this.addBatchFee.addBatchFeeList[scope.$index].feeCode = '';
    },
    // 业务类型聚焦事件，启用费用类型下拉框的禁用状态
    feeTypeFocus(scope) {
      if (this.addType === 1) {
        this.feeOptionList.forEach(item => {
          if (!scope.row.businessType) {
            this.$set(item, 'disabled', false);
          } else {
            this.$set(item, 'disabled', item.businessType !== scope.row.businessType);
          }
        });
      } else {
        this.feeOptionList.forEach(item => {
          this.$set(item, 'disabled', item.businessType !== this.businessType);
        });
      }
    },
    // 费用类型切换
    addChangeFee(scope) {
      const row = this.feeOptionList.find(item => {
        return item.code === scope.row.feeCode;
      });

      if (!row) { return; }
      this.addBatchFee.addBatchFeeList[scope.$index].feeName = row.displayName;
      this.addBatchFee.addBatchFeeList[scope.$index].businessType = row.businessType;
    },
    // 手动添加费用
    addCost() {
      const data = {
        ...ADD_DATA,
        createUser: this.$store.state.user.id, // 录入人员
        operator: this.$store.state.user.id
      };
      this.addBatchFee.addBatchFeeList.push(data);
      // 新增
      // if (this.addType === 1) {
      //   if (this.addBatchFee.addBatchFeeList.length === 0) {
      //     this.addBatchFee.addBatchFeeList.push(data);
      //   } else {
      //     this.$message.warning(this.$i18n.t('receivablePayable.OnlyOneChargeCanBeAdded')); // 只能添加一条费用
      //   }
      // } else {
      //   this.addBatchFee.addBatchFeeList.push(data);
      // }
    },

    // 提交
    submitEdit() {
      const arr = [];
      let flag = true;
      this.$refs.addBatchFee.validate((valid) => {
        if (valid) {
          this.addBatchFee.addBatchFeeList.filter(item => {
            return item.isEdit;
          }).forEach(item => {
            arr.push(item);
          });

          flag = false;
        } else {
          flag = true;
        }
      });

      return {
        valid: flag,
        arr
      };
    },

    // 费用删除事件
    deleteCost(scope) {
      if (scope.row.id) {
        getDeleteCostSiteExpenseFee({ id: scope.row.id }).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
            this.addBatchFee.addBatchFeeList.splice(scope.$index, 1);
          }
        });
      } else {
        this.addBatchFee.addBatchFeeList.splice(scope.$index, 1);
      }
    }
  }
};
</script>

<style scoped>
.box-card /deep/ .el-form-item {
  margin-bottom: 0;
}
.box-card /deep/ .el-form-item__error {
  position: static;
}
.addStyle, .deleteStyle {
  cursor: pointer;
}
.addStyle {
  color: #409eff;
}
.deleteStyle {
  color: #f56c6c;
}
</style>
