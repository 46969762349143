<template>
  <div class="warrp">
    <el-form label-width="auto">
      <el-row :gutter="20">
        <el-descriptions :colon="false" :column="3" direction="vertical">
          <!--运单号-->
          <el-descriptions-item :label="$t('newOrder.waybillNumber')">
            <div>{{ tableInfomation.waybillNumber || '--' }}</div>
          </el-descriptions-item>
          <!--创建时间-->
          <el-descriptions-item :label="$t('newOrder.createTime')">
            <div>{{ tableInfomation.createTime || '--' }}</div>
          </el-descriptions-item>
          <!--运单状态-->
          <el-descriptions-item :label="$t('newOrder.waybillStatus')">
            <div>{{ tableInfomation.waybillStatus || '--' }}</div>
          </el-descriptions-item>
          <!--服务商单号-->
          <el-descriptions-item :label="$t('GlobalSetObj.serviceLogistics')">
            <div>{{ tableInfomation.logisticsNumber || '--' }}</div>
          </el-descriptions-item>
          <!--服务商-->
          <el-descriptions-item :label="$t('GlobalSetObj.fuwushang')">
            <div>{{ tableInfomation.providerChannelName || '--' }}</div>
          </el-descriptions-item>
          <!--商户-->
          <el-descriptions-item :label="$t('placeOrder.CommercialOwner')">
            <div>{{ tableInfomation.merchantName || '--' }}</div>
          </el-descriptions-item>
          <!--客户名称-->
          <el-descriptions-item :label="$t('newOrder.CustomerName')">
            <div>{{ tableInfomation.customerName || '--' }}</div>
          </el-descriptions-item>
          <!--客户订单号-->
          <el-descriptions-item :label="$t('newOrder.orderNumber')">
            <div>{{ tableInfomation.customerNumber || '--' }}</div>
          </el-descriptions-item>
          <!--客户重量-->
          <el-descriptions-item :label="$t('GlobalSetObj.CustomerWeight')">
            <div>{{ tableInfomation.customerWeight || '--' }}</div>
          </el-descriptions-item>
          <!--是否退回-->
          <el-descriptions-item>
            <template slot="label">
              <span style="display: flex; align-items: center">
                <span>{{ $t('orderCenterCont.WhetherReturnNot') }}</span>
                <span>
                  <el-tooltip class="QuestionMark" placement="top">
                    <div slot="content">{{ $t('GlobalSetObj.jushou') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                </span>
              </span>
            </template>
            <span style="font-weight: bold">
              {{ tableInfomation.hasBack ? $t('GlobalSetObj.yes') : $t('GlobalSetObj.No') }}
            </span>
          </el-descriptions-item>
          <!--揽收类型-->
          <el-descriptions-item :label="$t('newOrder.CollectionType')">
            <div>{{ tableInfomation.collectType || '--' }}</div>
          </el-descriptions-item>
          <!--服务类型-->
          <el-descriptions-item :label="$t('newOrder.TypeService')">
            <div>{{ tableInfomation.expressType || $t('GlobalSetObj.localTrain') }}</div>
          </el-descriptions-item>
          <!--包裹类型-->
          <el-descriptions-item :label="$t('placeOrder.packageType')">
            <div>{{ tableInfomation.orderPackageType || '--' }}</div>
          </el-descriptions-item>
          <!--下单重量-->
          <el-descriptions-item>
            <template slot="label">
              <span style="display: flex; align-items: center">
                <span>{{ $t('GlobalSetObj.xiadanzhongliang') }}</span>
                <span>
                  <el-tooltip class="QuestionMark" placement="top">
                    <div slot="content">{{ $t('GlobalSetObj.coleta') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                </span>
              </span>
            </template>
            <span style="font-weight: bold">{{ tableInfomation.weight || '&#45;&#45;' }}</span>
          </el-descriptions-item>
          <!--网点称重-->
          <el-descriptions-item>
            <template slot="label">
              <span style="display: flex; align-items: center">
                <span>{{ $t('newOrder.DepotWeighing') + '(kg)' }}</span>
                <span>
                  <el-tooltip class="QuestionMark" placement="top">
                    <div slot="content">{{ $t('GlobalSetObj.coleta') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                </span>
              </span>
            </template>
            <span style="font-weight: bold">{{
              tableInfomation.outletsWeight || '&#45;&#45;'
            }}</span>
          </el-descriptions-item>
          <!--转运中心称重-->
          <el-descriptions-item :label="$t('newOrder.TransferWeighing')">
            <div>{{ tableInfomation.transportWeight || '&#45;&#45;' }}</div>
          </el-descriptions-item>
          <!--下单长-->
          <el-descriptions-item>
            <template slot="label">
              <span style="display: flex; align-items: center">
                <span>{{ $t('GlobalSetObj.comprimento') }}</span>
                <span>
                  <el-tooltip class="QuestionMark" placement="top">
                    <div slot="content">{{ $t('GlobalSetObj.ParcelLength') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                </span>
              </span>
            </template>
            <span style="font-weight: bold">{{ tableInfomation.length || '&#45;&#45;' }}</span>
          </el-descriptions-item>
          <!--下单宽-->
          <el-descriptions-item>
            <template slot="label">
              <span style="display: flex; align-items: center">
                <span>{{ $t('GlobalSetObj.largura') }}</span>
                <span>
                  <el-tooltip class="QuestionMark" placement="top">
                    <div slot="content">{{ $t('GlobalSetObj.ParcelWidth') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                </span>
              </span>
            </template>
            <span style="font-weight: bold">{{ tableInfomation.width || '&#45;&#45;' }}</span>
          </el-descriptions-item>
          <!--下单高-->
          <el-descriptions-item>
            <template slot="label">
              <span style="display: flex; align-items: center">
                <span>{{ $t('GlobalSetObj.altura') }}</span>
                <span>
                  <el-tooltip class="QuestionMark" placement="top">
                    <div slot="content">{{ $t('GlobalSetObj.ParcelHeight') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                </span>
              </span>
            </template>
            <span style="font-weight: bold">{{ tableInfomation.height || '&#45;&#45;' }}</span>
          </el-descriptions-item>
          <!--中转长-->
          <el-descriptions-item :label="$t('newOrder.length')">
            <div>{{ tableInfomation.transportLength || '--' }}</div>
          </el-descriptions-item>
          <!--中转宽-->
          <el-descriptions-item :label="$t('newOrder.width')">
            <div>{{ tableInfomation.transportWidth || '--' }}</div>
          </el-descriptions-item>
          <!--中转高-->
          <el-descriptions-item :label="$t('newOrder.height')">
            <div>{{ tableInfomation.transportHeight || '--' }}</div>
          </el-descriptions-item>
          <!--中转照片-->
          <el-descriptions-item :label="$t('newOrder.TransitPhotos')" :span="3">
            <div :style="`${$i18n.locale !== 'zh' ? 'width:50px' : 'width:60px'}`">
              <el-tooltip v-if="tableInfomation.photoUrl" class="QuestionMarkPhoto" placement="top">
                <div slot="content">
                  <img
                    :src="tableInfomation.photoUrl" alt="" class="image"
                    style="width: 300px"
                  >
                </div>
                <div style="display: flex; align-items: center; justify-content: center">
                  <span>{{ $t('GlobalSetObj.see') }}</span>
                  <svg-icon icon-class="iconEye" />
                </div>
              </el-tooltip>
              <span v-else>{{ '--' }}</span>
            </div>
          </el-descriptions-item>
          <!--预派揽收网点-->
          <el-descriptions-item :label="$t('newOrder.PreCollectionDepot')">
            <div style="width: 100%">
              <!--              <div v-if="tableInfomation.waybillStatus == `${$t('newOrder.ToBeAssigned')}`">-->
              <!--                <el-select-->
              <!--                  v-model="tableInfomation.preCollectStationName"-->
              <!--                  :disabled="tableInfomation.waybillStatus !== `${$t('newOrder.ToBeAssigned')}`"-->
              <!--                  filterable-->
              <!--                  style="width: 80%"-->
              <!--                  @change="distribution"-->
              <!--                >-->
              <!--                  <el-option-->
              <!--                    v-for="(item, i) in preCollectStationNameList"-->
              <!--                    :key="i"-->
              <!--                    :label="`${item.name}  ${item.zipCode}`"-->
              <!--                    :value="item.name"-->
              <!--                  >-->
              <!--                    <span style="float: left">{{ item.name || "- -" }}</span>-->
              <!--                    <span style="font-size: 13px;margin-left: 20px">{{ item.zipCode || "- -" }}</span>-->
              <!--                  </el-option>-->
              <!--                </el-select>-->
              <!--              </div>-->
              <div>{{ tableInfomation.preCollectStationName }}</div>
            </div>
          </el-descriptions-item>
          <!--实际揽收网点-->
          <el-descriptions-item :span="2">
            <template slot="label">
              <span style="display: flex; align-items: center">
                <span>{{ $t('newOrder.ActualCollection') }}</span>
                <span>
                  <el-tooltip class="QuestionMark" placement="top">
                    <div slot="content">{{ $t('GlobalSetObj.NetworkName') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                </span>
              </span>
            </template>
            <span style="font-weight: bold">{{
              tableInfomation.collectStationName || '&#45;&#45;'
            }}</span>
          </el-descriptions-item>
          <!--预派送网点-->
          <el-descriptions-item>
            <template slot="label">
              <span style="display: flex; align-items: center">
                <span>{{ $t('GlobalSetObj.preEntrega') }}</span>
                <span>
                  <el-tooltip class="QuestionMark" placement="top">
                    <div slot="content">{{ $t('GlobalSetObj.SortingCompleted') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                </span>
              </span>
            </template>
            <span style="font-weight: bold">{{
              tableInfomation.preDeliverStationName || '&#45;&#45;'
            }}</span>
          </el-descriptions-item>
          <!--实际派送网点-->
          <el-descriptions-item>
            <template slot="label">
              <span style="display: flex; align-items: center">
                <span>{{ $t('GlobalSetObj.entrega') }}</span>
                <span>
                  <el-tooltip class="QuestionMark" placement="top">
                    <div slot="content">{{ $t('GlobalSetObj.DeliveToStorage') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                </span>
              </span>
            </template>
            <span style="font-weight: bold">{{
              tableInfomation.deliverStationName || '&#45;&#45;'
            }}</span>
          </el-descriptions-item>
          <!--中转仓-->
          <el-descriptions-item :label="$t('newOrder.transitCenter')">
            <div>{{ tableInfomation.transitCenter || '&#45;&#45;' }}</div>
          </el-descriptions-item>
          <!--揽收袋号-->
          <el-descriptions-item>
            <template slot="label">
              <span style="display: flex; align-items: center">
                <span>{{ $t('orderCenterCont.PickUpBagNumber') }}</span>
                <span>
                  <el-tooltip class="QuestionMark" placement="top">
                    <div slot="content">{{ $t('GlobalSetObj.sealBagNumber') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                </span>
              </span>
            </template>
            <span style="font-weight: bold">{{
              tableInfomation.collectNumber || '&#45;&#45;'
            }}</span>
          </el-descriptions-item>
          <!--中转仓袋号-->
          <el-descriptions-item>
            <template slot="label">
              <span style="display: flex; align-items: center">
                <span>{{ $t('orderCenterCont.TransitBinBagNumber') }}</span>
                <span>
                  <el-tooltip class="QuestionMark" placement="top">
                    <div slot="content">{{ $t('GlobalSetObj.TransitWarehouse') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                </span>
              </span>
            </template>
            <span style="font-weight: bold">{{
              tableInfomation.transitBagNumber || '&#45;&#45;'
            }}</span>
          </el-descriptions-item>
          <!--派送路线-->
          <el-descriptions-item>
            <template slot="label">
              <span style="display: flex; align-items: center">
                <span>{{ $t('GlobalSetObj.DeliveryRoutes') }}</span>
                <span>
                  <el-tooltip class="QuestionMark" placement="top">
                    <div slot="content">{{ $t('GlobalSetObj.TransferOrSupplier') }}</div>
                    <svg-icon icon-class="QuestionMark" />
                  </el-tooltip>
                </span>
              </span>
            </template>
            <span style="font-weight: bold">{{ tableInfomation.pathNumber || '&#45;&#45;' }}</span>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :colon="false" :column="1" direction="vertical">
          <!--问题件原因-->
          <el-descriptions-item :label="$t('GlobalSetObj.problemReason')">
            <div>{{ tableInfomation.problemType || '--' }}</div>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :colon="false" :column="3" direction="vertical">
          <!--问题件原因-->
          <el-descriptions-item :label="$t('GlobalSetObj.problemRemark')">
            <div>{{ tableInfomation.problemReason || '--' }}</div>
          </el-descriptions-item>
          <!--货物类型-->
          <el-descriptions-item :label="$t('placeOrder.goodType')">
            <div>{{ tableInfomation.cargoType || '--' }}</div>
          </el-descriptions-item>
          <!--验证码-->
          <el-descriptions-item :label="$t('collectionCenter.verificationCode')">
            <div>{{ tableInfomation.signVerifyCode || '--' }}</div>
          </el-descriptions-item>
          <!--支付方式-->
          <el-descriptions-item :label="$t('placeOrder.PaymentMethod')">
            <div v-if="tableInfomation.paymentTypeName">{{ tableInfomation.paymentTypeName }}</div>
            <div v-else>--</div>
          </el-descriptions-item>
        </el-descriptions>
        <el-table :data="tableInfomation.tfkInfoList" border style="width: 100%">
          <template v-for="item in tableHeaderList">
            <el-table-column
              :key="item.id"
              :label="item.label"
              :min-width="item.cloWidth"
              :prop="item.prop"
              align="center"
            >
              <template slot-scope="scope">
                <!--dirUrl-->
                <div
                  v-if="item.prop === 'dirUrl'"
                  class="QuestionMarkPhoto"
                  @click="clickImg(scope.row.dirUrl)"
                >
                  {{ scope.row[item.prop] === '--' ? '--' : $t('customerManagements.View') }}
                </div>
                <!--dirUrl-->
                <div
                  v-else-if="item.prop === 'icmsUrl'"
                  class="QuestionMarkPhoto"
                  @click="clickImg(scope.row.icmsUrl)"
                >
                  {{ scope.row[item.prop] === '--' ? '--' : $t('customerManagements.View') }}
                </div>
                <div v-else>{{ scope.row[item.prop] }}</div>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'BasicInfo',
  props: {
    tableInfomation: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      tableData: [],
      waybillNumber: '',
      tableHeaderList: [
        // DIR 单号
        {
          id: 1,
          label: this.$t('changepwd.dirNumber'),
          prop: 'customsNumber',
          cloWidth: '180'
        },
        // 订单类型
        { id: 2, label: this.$t('changepwd.dirFile'), prop: 'dirUrl', cloWidth: '180' },
        // 包裹价值(BRL)
        {
          id: 3,
          label: this.$t('changepwd.ICMSFile'),
          prop: 'icmsUrl',
          cloWidth: '180'
        },
        {
          id: 5,
          label: this.$t('changepwd.icmsBarcode'),
          prop: 'icmsBarcode',
          cloWidth: '180'
        },
        // 开票金额(BRL)
        {
          id: 4,
          label: this.$t('changepwd.payAuthCode'),
          prop: 'payAuthCode',
          cloWidth: '180'
        }
      ]
    };
  },
  destroyed() {
    this.bus.$off('todetailBox');
  },
  created() {
    // this.preCollectStationNameList = JSON.parse(sessionStorage.getItem('netList'));
  },
  methods: {
    clickImg(dirUrl) {
      if (dirUrl && dirUrl.length > 8) window.open(dirUrl);
    }
    // 获取订单详情
    // getOrderInfo() {
    //   const id = this.tableInfomation.id;
    //   getOrderInfo(id).then((res) => {
    //     if (res.code === 200) {
    //       this.tableInfomation.waybillStatus = res.data.waybillStatus;
    //     }
    //   });
    // }
    // distribution(val) {
    //   const findObj = this.preCollectStationNameList.find(function(item) {
    //     return item.name === val;
    //   });
    //   const data = {};
    //   data.preCollectSiteId = findObj.id;
    //   data.waybillNumber = this.tableInfomation.waybillNumber;
    //   data.preCollectSiteName = findObj.name;
    //   preDistributionDot(data).then(res => {
    //     if (res.code===200) {
    //       this.$message.success(this.$t('newOrder.OperationSuccessful'));
    //       this.getOrderInfo();
    //     }
    //   });
    // }
  }
};
</script>

<style scoped>
.el-descriptions-item__cell div {
  font-weight: bold;
  font-size: 15px;
}

.warrp /deep/ .el-col {
  height: 50px;
}

.warrp /deep/ .el-descriptions-item__label {
  font-size: 13px;
  color: #909399;
}

.warrp /deep/ .el-descriptions-item__cell {
  /*height: 35px !important;*/
  padding-bottom: 2px !important;
}

.labelClass {
  font-size: 14px;
  font-weight: 700;
  margin-left: 5px;
  margin-right: 10px;
  color: #606266;
}

.QuestionMark {
  text-align: center;
  cursor: pointer;
}

.QuestionMarkPhoto {
  cursor: pointer;
  color: #409eff;
}
</style>
