<template>
  <div class="app-container">
    <el-card style="margin-bottom: 5px;">
      <el-form
        ref="queryForm"
        class="queryFormClass"
        :model="queryForm"
        :label-position="'top'"
        size="small"
      >
        <el-row :gutter="10">
          <el-col :span="7">
            <!--应收主体-->
            <el-form-item :label="$t('basicData.businessPrincipal')" prop="providerName">
              <el-input v-model="queryForm.params.providerName" :placeholder="$t('orderDetails.pleaseInput')" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!--网点名称-->
            <el-form-item :label="$t('basicData.siteName')" prop="siteName">
              <el-input v-model="queryForm.params.siteName" :placeholder="$t('orderDetails.pleaseInput')" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="left">
        <el-col :span="8">
          <div>
            <!--查询-->
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="getDataSearch"
            >{{ $t('basicData.Search') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh"
              size="mini"
              @click="queryFormReset"
            >{{ $t('basicData.reset') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <el-card>
      <div style="margin-bottom: 10px; display: flex; justify-content: flex-end;">
        <div>
          <!--导出账户信息-->
          <el-button
            v-permit:remove="'but:finance:basicData:network:ExportAccountInformation'"
            size="mini"
            type="primary"
            @click="exportAccount(1)"
          >{{ $t('basicData.ExportAccountInformation') }}
          </el-button>
          <!--导出账户流水-->
          <el-button
            v-permit:remove="'but:finance:basicData:network:ExportAccountRollover'"
            size="mini"
            type="primary"
            @click="exportAccount(2)"
          >{{ $t('basicData.ExportAccountRollover') }}
          </el-button>
        </div>
      </div>

      <el-table
        ref="elTable"
        :data="tableData"
        border
        class="table-height"
        :height="tableHeightRow.height"
      >
        <!--序号-->
        <el-table-column :label="$t('basicData.serialNumber')" width="60px" align="center">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!--网点名称-->
        <el-table-column
          prop="name"
          :label="$t('basicData.siteName')"
          align="left"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-popover
              placement="top-end"
              popper-class="copy"
              trigger="hover"
            >
              <!--复制-->
              <el-button type="text" @click="copy(scope.row.name)">{{ $t('orderDetails.copy') }}</el-button>
              <span slot="reference" style="color: #409eff;cursor: pointer;" @click="accountDetails(scope.row)">{{ scope.row.name }}</span>
            </el-popover>
          </template>
        </el-table-column>
        <!--应收主体-->
        <el-table-column
          prop="providerName"
          :label="$t('basicData.businessPrincipal')"
          align="center"
          :show-overflow-tooltip="true"
        />
        <!--主体类型-->
        <el-table-column
          prop="providerType"
          :label="$t('basicData.PrincipalType')"
          align="center"
          width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <!--自营-->
            <div v-if="Number(scope.row.providerType) === 1">{{ $t('basicData.SelfOperated') }}</div>
            <!--第三方-->
            <div v-if="Number(scope.row.providerType) === 2">{{ $t('basicData.thirdParty') }}</div>
            <!--邮政-->
            <div v-if="Number(scope.row.providerType) === 3">{{ $t('basicData.postal') }}</div>
          </template>
        </el-table-column>
        <!--累计未结算-->
        <el-table-column
          prop="unsettledAmount"
          :label="$t('basicData.AccumulatedUnsettled')"
          align="center"
          width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.unsettledAmount }}</div>
          </template>
        </el-table-column>
        <!--账单账期-->
        <el-table-column
          prop="paymentCycle"
          :label="$t('basicData.billingPeriod')"
          align="center"
          width="140"
          :show-overflow-tooltip="true"
        />
        <!--操作-->
        <el-table-column
          :label="$t('basicData.operate')"
          align="center"
          width="160"
          fixed="right"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>
              <el-button
                v-permit:remove="'but:finance:basicData:network:setUpABillingPeriod'"
                size="mini"
                type="primary"
                @click="setUpABillingPeriod(scope.row)"
              >
                <!--设置账期-->
                {{ $t('basicData.SetUpABillingPeriod') }}
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getData"
          @size-change="getData"
        />
      </div>
    </el-card>
    <!--设置账期-->
    <el-dialog
      :title="$t('basicData.SetUpABillingPeriod')"
      :visible.sync="showCollectionCycle"
      width="60%"
      :append-to-body="true"
    >
      <div>
        <el-form
          :key="showCollectionCycle"
          ref="paymentCycle"
          :model="collectionCycleData"
          :rules="rules"
          :label-width="'auto'"
        >
          <el-row :gutter="20">
            <el-col :span="24">
              <!--网点名称-->
              <div class="grid-content bg-purple">
                <el-form-item :label="$t('basicData.siteName') + '：'" prop="name">
                  <span>{{ collectionCycleData.name }}</span>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="24">
              <!--应收主体-->
              <div class="grid-content bg-purple">
                <el-form-item :label="$t('basicData.businessPrincipal') + '：'" prop="providerName">
                  <span>{{ collectionCycleData.providerName }}</span>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="24">
              <!--当前账期-->
              <div class="grid-content bg-purple">
                <el-form-item :label="$t('basicData.CurrentBillingPeriod') + '：'" prop="paymentCycle">
                  <span>{{ collectionCycleData.paymentCycle }}</span>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="24">
              <!--调整后账期-->
              <div class="grid-content bg-purple">
                <el-form-item :label="$t('basicData.AdjustTheBillingPeriod') + '：'" prop="days">
                  <el-input v-model="collectionCycleData.days" />
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--取 消-->
        <el-button @click="showCollectionCycle = false">{{ $t('basicData.Cancel') }}</el-button>
        <!--确 定-->
        <el-button type="primary" @click="saveCollectionCycle()">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog>
    <!--账户详情-->
    <el-drawer
      :title="$t('basicData.AccountDetails')"
      :append-to-body="true"
      :visible.sync="showDetail"
      direction="ltr"
      size="90%"
    >
      <div>
        <account-details :key="detailKey" :query-form="rowDetail" @closeDetail="closeDetail" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  apiGetSiteAccountPage, apiSiteAccountExportExportOfBillFlow,
  apiSiteAccountExportSiteAccountInfo,
  apiSiteAccountSetCycle
} from '@/api/finance/contasDeClientes';
import { copyData } from '@/utils/copyData';
import { getUserName } from '@/utils/asyncTools';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { goResourceTaskList } from '@/utils/goExportList.js';

export default {
  name: 'BranchAccounts',
  components: {
    'account-details': () => import('./cmp/accountDetails')
  },
  mixins: [estimateTableHeight],

  data() {
    // 正整数
    const positiveInteger = (rule, value, callback) => {
      const reg = /^(0|[1-9][0-9]*)$/;
      if (value.length > rule.length) {
        return callback(new Error(this.$i18n.t('basicData.TheLengthCannotBeGreaterThan') + rule.length)); // 长度不能大于
      }
      if (rule.required) {
        if (value) {
          if (reg.test(value) === false) {
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // '格式不正确'
          } else {
            return callback();
          }
        } else {
          return callback(new Error(this.$i18n.t('basicData.CannotBeEmpty'))); // '不能为空'
        }
      } else {
        if (value) {
          if (reg.test(value) === false) {
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // '格式不正确'
          } else {
            return callback();
          }
        } else {
          return callback();
        }
      }
    };
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          providerName: '',
          siteName: ''
        }
      },
      tableData: [],
      total: 0,
      drawerObj: {
        visible: false,
        editData: {},
        typeIndex: 1
      },
      editKey: new Date().getTime(),
      userIdAndNameObj: null,
      showCollectionCycle: false,
      collectionCycleData: {
        id: '',
        paymentCycle: '',
        siteName: '',
        providerName: '',
        days: ''
      },
      rules: {
        days: { required: true, validator: positiveInteger, length: 3, trigger: ['blur', 'change'] }
      },
      rowDetail: {},
      showDetail: false,
      detailKey: new Date().getTime()
    };
  },
  created() {
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.getData();
  },
  methods: {
    // 关闭详情弹窗
    closeDetail() {
      this.showDetail = false;
    },
    // 查询详情
    accountDetails(row) {
      this.rowDetail = row;
      this.showDetail = true;
      this.detailKey = new Date().getTime();
    },
    // 点击复制
    copy(data) {
      copyData(data);
    },
    // 搜索
    getDataSearch() {
      this.queryForm.pageNumber = 1;
      this.getData();
    },
    // 重置
    queryFormReset() {
      this.queryForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          providerName: '',
          siteName: ''
        }
      };
    },
    // 获取列表数据
    getData() {
      apiGetSiteAccountPage(this.queryForm).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.records.map(item => {
            const updateUserStr = getUserName(item.updateUser, this.userIdAndNameObj);
            const createUserStr = getUserName(item.createUser, this.userIdAndNameObj);
            return {
              ...item,
              updateUserStr,
              createUserStr
            };
          });
          this.total = res.data.total;
        }
      });
    },
    // 设置账期
    setUpABillingPeriod(row) {
      const { id, paymentCycle, name, providerName } = row;
      this.collectionCycleData = {
        id,
        paymentCycle,
        name,
        providerName,
        days: ''
      };
      this.showCollectionCycle = true;
    },
    saveCollectionCycle() {
      this.$refs.paymentCycle.validate((valid) => {
        if (valid) {
          const param = {
            id: this.collectionCycleData.id,
            paymentCycle: parseInt(this.collectionCycleData.days)
          };
          apiSiteAccountSetCycle(param).then(res => {
            if (res.code === 200) {
              this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // TheOperationSucceeded 操作成功
              this.getData();
              this.showCollectionCycle = false;
            }
          });
        }
      });
    },
    // 导出账户信息、导出账户流水
    exportAccount(type) {
      if (type === 1) {
        apiSiteAccountExportSiteAccountInfo(this.queryForm.params).then(res => {
          if (res.code === 200) {
            goResourceTaskList();
          }
        });
      } else {
        const param = {
          ...this.queryForm.params,
          ids: [],
          startTime: '',
          endTime: '',
          isSiteDetailsRequest: false
        };
        apiSiteAccountExportExportOfBillFlow(param).then(res => {
          if (res.code === 200) {
            goResourceTaskList();
          }
        });
      }
    },
    // 更新列表数据
    updatePage() {}
  }
};
</script>

<style scoped>

</style>
