<template>
  <div class="app-container">
    <div class="form-container">
      <el-form
        :key="rechargeData.id" ref="rechargeData" :rules="recharge"
        :model="formData" :label-width="'auto'" size="small"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <!--业务主体-->
              <el-form-item :label="$t('basicData.BusinessPrincipal') + '：'" prop="providerName">
                <span>{{ formData.providerName }}</span>
              </el-form-item>
            </div>
            <div class="grid-content bg-purple">
              <!--票单金额-->
              <el-form-item :label="$t('receivablePayable.TicketAmount') + '：'" prop="billFee">
                <span>{{ formData.billFee }}</span>
              </el-form-item>
            </div>
            <div class="grid-content bg-purple">
              <!--付款时间-->
              <el-form-item :label="$t('receivablePayable.PaymentTime') + '：'" prop="acceptTime">
                <el-date-picker
                  v-model="formData.acceptTime"
                  type="date"
                  style="width: 100%"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :placeholder="$t('basicData.SelectADate')"
                />
              </el-form-item>
            </div>
            <div class="grid-content bg-purple">
              <!--付款方式-->
              <el-form-item :label="$t('basicData.PaymentMethod') + '：'" prop="payableMethod">
                <el-select v-model="formData.payableMethod" :placeholder="$t('basicData.PleaseSelect')" style="width: 100%">
                  <el-option
                    v-for="item in paymentMethod"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div class="grid-content bg-purple">
              <!--币种-->
              <el-form-item
                :label="$t('basicData.Currency') + '：'"
                prop="currencyCode"
              >
                <el-select
                  v-model="formData.currencyCode"
                  filterable
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="(item, ind) in currencyList"
                    :key="ind"
                    :label="item.chineseName + '(' + item.code + ')'"
                    :value="item.code"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div class="grid-content bg-purple">
              <!--票证号-->
              <el-form-item :label="$t('basicData.TicketNumber') + '：'" prop="ticketNumber">
                <el-input v-model="formData.ticketNumber" style="width: 100%" />
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <!--付款单号-->
              <el-form-item :label="$t('receivablePayable.PaymentSlipNumber') + '：'" prop="payableNumber">
                <span>{{ formData.payableNumber }}</span>
              </el-form-item>
            </div>
            <div class="grid-content bg-purple">
              <!--历史付款金额-->
              <el-form-item :label="$t('receivablePayable.HistoricalPaymentAmounts') + '：'" prop="actualFee">
                <span>{{ actualFee }}</span>
              </el-form-item>
            </div>
            <div class="grid-content bg-purple">
              <!--账户-->
              <el-form-item :label="$t('basicData.account') + '：'" prop="accountAlias">
                <el-select
                  v-model="formData.accountAlias"
                  filterable
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="item in accountOption"
                    :key="item.id"
                    :label="item.accountAlias"
                    :value="item.accountNumber"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div class="grid-content bg-purple">
              <!--付款金额-->
              <el-form-item :label="$t('receivablePayable.TheAmountOfThePayment') + '：'" prop="actualFee">
                <el-input v-model="formData.actualFee" style="width: 100%" />
              </el-form-item>
            </div>
            <!--汇率-->
            <div class="grid-content bg-purple">
              <el-form-item
                :key="rateKey"
                :label="$t('basicData.exchangeRate') + '：'"
                prop="exchangeRate"
                :rules="{ required: formData.currencyCode.toUpperCase()!=='BRL', suffix: 5, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
              >
                <el-input v-model="formData.exchangeRate" style="width: 100%" />
                <div v-if="adopt && exchangeRate !== 0" style="position: absolute; left: 0; top: 30px; color: #c98f06ff">
                  <span v-if="allEqual" style="margin-right: 5px">≈</span>{{ exchangeRate.toFixed(2) }}BRL
                </div>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--备注-->
              <el-form-item :label="$t('basicData.remark') + '：'" prop="remark">
                <el-input v-model="formData.remark" type="textarea" :rows="6" />
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--附件-->
              <el-form-item :label="$t('basicData.annex') + '：'" prop="attachmentUrl">
                <el-upload
                  ref="uploadFile"
                  class="upload-demo"
                  drag
                  action
                  multiple
                  :http-request="enclosureUpload"
                  :before-upload="beforeAvatarUpload"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                >
                  <i class="el-icon-upload" />
                  <!--将文件拖到此处，或点击上传-->
                  <div class="el-upload__text">{{ $t('basicData.DragTheFileHereOrClickUpload') }}</div>
                  <!--仅支持上传格式为bmp，jpg，png，gif，pdf，且大小在5M以内的附件-->
                  <div slot="tip" class="el-upload__tip">{{ $t('basicData.UploadTheAttachmentFormat') }}</div>
                </el-upload>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <!--部分付款-->
              <el-form-item :label="$t('receivablePayable.PartialDisbursement') + '：'" prop="isPartialPayment">
                <el-radio-group v-model="formData.isPartialPayment" size="small">
                  <!--是-->
                  <el-radio-button :label="1">{{ $t('orderCenterCont.yes') }}</el-radio-button>
                  <!--否-->
                  <el-radio-button :label="0">{{ $t('orderCenterCont.No') }}</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="footer">
      <!--取 消-->
      <el-button @click="editCreditLimit(1)">{{ $t('basicData.Cancel') }}</el-button>
      <!--确 定-->
      <el-button type="primary" @click="editCreditLimit(2)">{{ $t('basicData.determine') }}</el-button>
    </div>
  </div>
</template>

<script>
import { uploadOosFinance } from '@/utils/ali_oos_util';
import { apiSitePayableCrediting } from '@/api/finance/receivables';
import { currencyTypePage } from '@/api/finance/partition';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'CollectionDetail',
  props: {
    rechargeData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    accountOption: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    const priceType = (rule, value, callback) => {
      const reg = new RegExp('(^' + rule.negative + '[1-9]\\d*(\\.\\d{1,' + rule.suffix + '})?$)|(^' + rule.negative + '0(\\.\\d{1,' + rule.suffix + '})?$)');
      const { required } = rule;
      if (!required) {
        // 非必填
        if (value) {
          if (!reg.test(value)) {
            this.adopt = false;
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // 格式不正确
          } else {
            this.adopt = true;
            return callback();
          }
        } else {
          return callback();
        }
      } else {
        if (value) {
          if (!reg.test(value)) {
            this.adopt = false;
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // 格式不正确
          } else {
            this.adopt = true;
            return callback();
          }
        } else {
          this.adopt = false;
          return callback(this.$i18n.t('basicData.CannotBeEmpty')); // '不能为空'
        }
      }
    };
    return {
      recharge: {
        // 付款方式
        paymentMethod: {
          required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change']
        },
        // 付款金额
        actualFee: {
          required: true, suffix: 4, negative: '-?', validator: priceType, trigger: ['blur', 'change']
        },
        // 账户
        accountAlias: {
          required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change']
        },
        // 付款时间
        acceptTime: {
          required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change']
        },
        // 币种
        currencyCode: {
          required: true, message: this.$t('basicData.CannotBeEmpty'), trigger: ['blur', 'change']
        },
        // 汇率
        exchangeRate: {
          required: false, suffix: 5, negative: '', validator: priceType, trigger: ['blur', 'change']
        }
      },
      fileList: [],
      adopt: true,
      exchangeRate: 0,
      allEqual: false,
      currencyList: [],
      formData: {
        id: '',
        providerId: '',
        payableNumber: '',
        accountAlias: '',
        currencyCode: 'BRL',
        currencyName: '',
        exchangeRate: '',
        actualFee: '',
        attachmentUrl: '',
        acceptTime: '',
        payableMethod: '',
        remark: '',
        ticketNumber: '',
        isPartialPayment: 0,
        providerName: '',
        billFee: ''
      },
      actualFee: 0,
      priceType: priceType,
      rateKey: new Date().getTime()
    };
  },
  computed: {
    paymentMethod() {
      return [
        { name: this.$i18n.t('basicData.cash'), code: 'cash' }, // 现金
        { name: this.$i18n.t('basicData.transferable'), code: 'transfer' }, // 转账
        { name: this.$i18n.t('basicData.online'), code: 'network' }, // 网络
        { name: this.$i18n.t('basicData.Elimination'), code: 'sterilisation' }, // 冲销
        { name: this.$i18n.t('basicData.pix'), code: 'pix' }, // PIX
        { name: this.$i18n.t('basicData.other'), code: 'other' } // 其他
      ];
    }
  },
  watch: {
    'formData.actualFee'(val) {
      this.rateKey = new Date().getTime();
      this.exchangeRate =
        (val ? Number(val) : 0) *
        (this.formData.exchangeRate ? Number(this.formData.exchangeRate) : 1);
      this.allEqual =
        this.exchangeRate > this.exchangeRate.toFixed(2) ||
        this.exchangeRate < this.exchangeRate.toFixed(2);
    },
    'formData.exchangeRate'(val) {
      this.exchangeRate =
        (val ? Number(val) : 1) * (this.formData.actualFee ? Number(this.formData.actualFee) : 0);
      this.allEqual =
        this.exchangeRate > this.exchangeRate.toFixed(2) ||
        this.exchangeRate < this.exchangeRate.toFixed(2);
    },
    'formData.currencyCode'(val) {
      this.rateKey = new Date().getTime();
      for (let i = 0; i < this.currencyList.length; i++) {
        if (val === this.currencyList[i].code) {
          this.formData.currencyName = this.currencyList[i].chineseName;
          break;
        }
      }
    }
  },
  created() {
    this.getCurrencyList();
    this.actualFee = cloneDeep(this.rechargeData.actualFee);
    this.formData = {
      id: this.rechargeData.id,
      providerId: this.rechargeData.providerId,
      payableNumber: this.rechargeData.payableNumber,
      providerName: this.rechargeData.providerName,
      billFee: this.rechargeData.billFee,
      currencyCode: 'BRL',
      currencyName: '',
      exchangeRate: '',
      actualFee: '',
      accountAlias: '',
      attachmentUrl: '',
      acceptTime: '',
      payableMethod: '',
      remark: '',
      ticketNumber: '',
      isPartialPayment: 0
    };
    for (let i = 0; i < this.currencyList.length; i++) {
      if (this.formData.currencyCode === this.currencyList[i].code) {
        this.formData.currencyName = this.currencyList[i].chineseName;
        break;
      }
    }
  },
  methods: {
    // 获取币种
    getCurrencyList() {
      const param = {
        pageNumber: 1,
        pageSize: 10000,
        params: {
          status: 1
        }
      };
      currencyTypePage(param).then((res) => {
        if (res.code === 200) {
          this.currencyList = res.data.records;
          for (let i = 0; i < this.currencyList.length; i++) {
            if (this.formData.currencyCode === this.currencyList[i].code) {
              this.formData.currencyName = this.currencyList[i].chineseName;
              break;
            }
          }
        }
      });
    },
    // 删除附件
    handleRemove(file, fileList) {
      this.formData.attachmentUrl = '';
      this.fileList = fileList;
      this.fileList.map(item => {
        this.formData.attachmentUrl = this.formData.attachmentUrl ? this.formData.attachmentUrl + ',' + item.url : item.url;
      });
    },
    // 上传文件类型
    beforeAvatarUpload(file) {
      const type = ['image/bmp', 'image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
      const isJPG = file.type;
      const isType = type.includes(isJPG);
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isType) {
        this.$message.error(this.$i18n.t('basicData.IncorrectFormatting')); // '文件格式不正确'
      }
      if (!isLt2M) {
        this.$message.error(this.$i18n.t('basicData.UploadFileSizeCannotExceed5MB')); // '上传文件大小不能超过 5MB!'
      }
      return isType && isLt2M;
    },
    // 资源上传数量限制
    handleExceed(files, fileList) {
      this.$message.warning(this.$i18n.t('basicData.Only1FileCanBeUploadedAtATime')); // 每次只能上传1个文件
    },
    // 开始上传
    submitUpload() {
      this.$refs.uploadFile.submit();
    },
    async enclosureUpload(option) {
      const file = option.file;
      const res = await uploadOosFinance(file, 'finance');
      if (res.code === 200) {
        const obj = {
          name: file.name,
          url: res.url
        };
        this.fileList = [...this.fileList, obj];
        this.formData.attachmentUrl = '';
        this.fileList.map(item => {
          this.formData.attachmentUrl = this.formData.attachmentUrl ? this.formData.attachmentUrl + ',' + item.url : item.url;
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    // 收款、取消
    editCreditLimit(type) {
      if (type === 1) {
        this.$emit('collectionUpdate', type);
      } else {
        this.$refs.rechargeData.validate((valid) => {
          if (valid) {
            const param = {
              id: this.formData.id,
              providerId: this.formData.providerId,
              payableNumber: this.formData.payableNumber,
              accountAlias: this.formData.accountAlias,
              actualFee: this.formData.actualFee,
              attachmentUrl: this.formData.attachmentUrl,
              acceptTime: this.formData.acceptTime,
              payableMethod: this.formData.payableMethod,
              remark: this.formData.remark,
              ticketNumber: this.formData.ticketNumber,
              exchangeRate: this.formData.exchangeRate,
              currencyCode: this.formData.currencyCode,
              currencyName: this.formData.currencyName,
              isPartialPayment: this.formData.isPartialPayment
            };
            apiSitePayableCrediting(param).then(res => {
              if (res.code === 200) {
                this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
                this.$emit('collectionUpdate', type);
              }
            }).catch(err => {
              console.error(err);
            });
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.app-container {
  height: 100%;
}
.form-container {
  width: 100%;
  height: calc(100% - 90px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.footer {
  position: sticky;
  left: 0;
  bottom: 5px;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #eeefee;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20000;
}
</style>
