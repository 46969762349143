// 渠道管理

const ChannelManage = {
  channelType: {
    zh: '渠道类型',
    en: 'channel type',
    pu: 'tipo de canal'
  },
  channelName: {
    zh: '渠道名称',
    en: 'channel name',
    pu: 'nome do canal'
  },
  estadoDoCanal: {
    zh: '渠道状态',
    en: 'estado do canal',
    pu: 'channel status'
  },
  ChannelID: {
    zh: '渠道ID',
    en: 'Channel ID',
    pu: 'ID do canal'
  },
  MaximumDeclaredTotalAmount: {
    zh: '最高申报总金额（元）',
    en: 'Maximum declared total amount (BRL)',
    pu: 'Valor total máximo declarado (BRL)'
  },
  MinimumDeclaredTotalAmount: {
    zh: '最低申报总金额（元）',
    en: 'Minimum declared total amount (BRL)',
    pu: 'Valor total mínimo declarado (BRL)'
  },
  MaximumOrderWeight: {
    zh: '最高下单重量',
    en: 'Maximum order weight',
    pu: 'Peso máximo do pedido'
  },
  MinimumOrderWeight: {
    zh: '最低下单重量',
    en: 'Minimum order weight',
    pu: 'Peso mínimo do pedido'
  },
  IPOfOrderData: {
    zh: '订单数据推送IP',
    en: 'IP of order data',
    pu: 'IP de envio de dados do pedido'
  },
  enable: {
    zh: '启用',
    en: 'enable',
    pu: 'habilitar'
  },
  Disable: {
    zh: '停用',
    en: 'Disable',
    pu: 'Desativar'
  },
  ServiceProviderName: {
    zh: '系统服务商名称',
    en: 'Service provider name on the System',
    pu: 'Nome do provedor de serviços no sistema'
  },
  ServiceProviderChannelName: {
    zh: '服务商渠道名称',
    en: 'Service provider channel name',
    pu: 'Nome do canal do provedor de serviços'
  },
  ServiceChannelDetails: {
    zh: '服务商渠道详情',
    en: '',
    pu: 'Detalhe do canal do fornecedor'
  },
  fixServiceChannel: {
    zh: '修改系统服务商渠道',
    en: '',
    pu: 'Modificar o canal do fornecedor do sistema'
  },

  channelStatus: {
    zh: '渠道状态',
    en: 'channel status',
    pu: 'estado do canal'
  },
  CreateCondition: {
    zh: '创建条件',
    en: '',
    pu: 'Condições de criação'
  },
  inputStr: {
    zh: '请输入 2 ~ 50 字符',
    en: '',
    pu: 'Favor inserir 2 ~ 50 caracteres'
  },
  inputNumbers: {
    zh: '请输入 6 ~ 20 数字',
    en: 'Please input numbers from 6~20',
    pu: 'Insira números de 6 a 20'
  },

  AssociatedServiceProvider: {
    zh: '关联系统服务商',
    en: 'Associated service provider on the System',
    pu: 'Provedor de serviço de sistema associado'
  },
  channelCode: {
    zh: '渠道代码',
    en: 'channel code',
    pu: 'código do canal'
  },
  name: {
    zh: '名称',
    en: 'name',
    pu: 'nome'
  },
  codeThreeLettersNumber: {
    zh: 'code(三字码)',
    en: 'code (three-letters/number)',
    pu: 'código (código de três letras/números)'
  },
  SystemServiceProvider: {
    zh: '系统服务商',
    en: 'System service provider',
    pu: 'Provedor de serviço do sistema'
  },
  NewSystemServiceProvider: {
    zh: '新增系统服务商',
    en: '',
    pu: 'Acrescenta um prestador de serviços de sistema'
  },
  providerCode: {
    zh: '系统服务商代码',
    en: '',
    pu: 'Código de prestador de serviços de sistema '
  },
  RelatedConfig: {
    zh: '系统服务商相关配置',
    en: '',
    pu: 'Configurações relacionadas ao prestador de serviços de sistema'
  },
  goRelatedConfig: {
    zh: '前往设置系统服务商相关配置',
    en: '',
    pu: 'Ir para configurar definições relacionada ao prestador de serviços de sistema'
  },
  SystemInfo: {
    zh: '系统服务商详情',
    en: '',
    pu: 'Detalhes do prestador de serviços de sistema'
  },
  modifyProvider: {
    zh: '修改系统服务商',
    en: '',
    pu: 'Modificar o prestador de serviços de sistema'
  },
  sureToStop: {
    zh: '确定停用吗？',
    en: '',
    pu: 'Suspensão de uso confirmada?'
  },
  PleaseProvidercode: {
    zh: '请输入系统服务商代码',
    en: '',
    pu: 'Favor inserir o código do prestador de serviços de sistema'
  },
  PleaseProviderName: {
    zh: '请输入系统服务商名称',
    en: '',
    pu: 'Favor inserir o nome do prestador de serviços de sistema'
  },
  PleaseRelatedConfig: {
    zh: '请输入系统服务商相关配置',
    en: '',
    pu: 'Favor inserir a configuração relevante do prestador de serviços de sistema'
  },
  NewProviderChannels: {
    zh: '新增服务商渠道',
    en: '',
    pu: 'Acrescenta um canail de prestador de serviço'
  },
  TakeNumberOrNot: {
    zh: '是否取号',
    en: '',
    pu: 'Tem certeza de sollicitar o número?'
  },
  changeOrderOrNot: {
    zh: '是否换单',
    en: '',
    pu: 'Tem certeza de mudar o pedido?'
  },
  maximumParcelWeight: {
    zh: '包裹最大重量(KG)',
    en: '',
    pu: 'Peso máximo de pacote'
  },
  Associatedprovider: {
    zh: '请输入关联系统服务商',
    en: '',
    pu: 'Favor inserir o prestador de serviços do sistema associado'
  },
  ChannelCode: {
    zh: '请输入渠道代码',
    en: '',
    pu: 'Favor inserir o código do canal'
  },
  inputServiceProviderChannelName: {
    zh: '请输入服务商渠道名称',
    en: '',
    pu: 'Favor inserir o nome do canal do prestador de serviços'
  },
  greaterThan0: {
    zh: '请输入大于0的数值',
    en: '',
    pu: 'Favor inserir um número maior que 0'
  },
  ServiceErrorMessage: {
    zh: '服务商错误信息',
    en: '',
    pu: 'Informações erradas do prestador de serviços'
  },
  ChineseTranslation: {
    zh: '中文翻译信息',
    en: '',
    pu: 'Informações da tradução em chinês'
  },
  EnglishTranslation: {
    zh: '英文翻译信息',
    en: '',
    pu: 'Informações da tradução em inglês'
  },
  PortugueseTranslated: {
    zh: '葡语翻译信息',
    en: '',
    pu: 'Informações da tradução em português'
  },
  to200Characters: {
    zh: '请输入1~200个字符的服务商错误信息',
    en: '',
    pu: 'Insira de 1 a 200 caractéres da informação errada de erro do  fornecedor de serviços'
  },
  SelectProvider: {
    zh: '请选择系统服务商',
    en: '',
    pu: 'Selecione um fornecedor de serviços de sistema'
  },
  inputChineseTranslation: {
    zh: '请输入中文翻译信息',
    en: '',
    pu: 'Insira as informações da tradução em chinês'
  },
  inputEnglishTranslation: {
    zh: '请输入英文翻译信息',
    en: '',
    pu: 'Insira as informações da tradução em inglês'
  },
  inputPortugueseTranslated: {
    zh: '请输入葡语翻译信息',
    en: '',
    pu: 'Insira as informações da tradução em português'
  },
  takeNumberTip: {
    zh: '默认为否，除非通过该渠道派送的包裹，必需向这个渠道获取运单号，展示在【服务商单号】才选择是，比如巴西邮政；',
    en: '',
    pu: 'O padrão é não, a menos que o pacote entregue por meio deste canal devam obter número de rastreio deste canal, exibido em [Número de rastreio do provedor de serviços] antes de selecionar Sim, como os Correios;'
  },
  whetherOrderTip: {
    zh: '默认为否，除非通过该渠道派送包裹必需使用渠道指定面单，比如巴西邮政；',
    en: '',
    pu: 'O padrão é não, a menos que uma folha de rosto específica do canal, como os Correios, seja necessária para entregar pacote por esse canal;'
  },
  ChannelWeight: {
    zh: '默认为500KG，如该渠道可运输重量小于这个重量，请输入实际可运输最大重量。',
    en: '',
    pu: 'O padrão é 500 kg. Se o peso transportável desse canal for inferior a 500 kg, insira o peso máximo transportável real.'
  },
  oddNumbers: {
    zh: '单号',
    en: '',
    pu: 'Número de pedido'
  },
  inputoddNumbers: {
    zh: '请扫描或输入单号',
    en: '',
    pu: 'Faça favor de bipar ou inserir o número de pedido'
  },
  serviceProvider: {
    zh: '服务商',
    en: '',
    pu: 'Fornecedor'
  },
  GenerateBag: {
    zh: '点击生成袋号',
    en: '',
    pu: 'Clique para gerar o número de saca'
  },
  inputNumberStr612: {
    zh: '请输入字母BG和数字, 长度为6到12位',
    en: '',
    pu: 'Digite as letras BG e os números, de 6 a 12 dígitos'
  },
  BagSealingTime: {
    zh: '封袋时间',
    en: '',
    pu: 'Tempo de selar a saca'
  },
  waybillNumber: {
    zh: '运单数',
    en: '',
    pu: 'Quantidade de waybill'
  },
  totalWeight: {
    zh: '总重量',
    en: '',
    pu: 'Peso total'
  },
  totalWeightkg: {
    zh: '总重量(KG)',
    en: '',
    pu: 'Peso total'
  }
};

export {
  ChannelManage
};

