<template>
  <el-card class="box-card">
    <div class="fontClsBold02">{{ $t('changepwd.opeartData') }}</div>
    <div class="operationData">
      <div class="operationData_item">
        <img :src="require(`@/assets/images/kanban/arriveNumber.png`)" alt="">
        <div style="margin-left: 10px">
          <div>{{ $t('customerManagements.NumberArrived') }}</div>
          <div class="number">{{ unloading }}</div>
        </div>
      </div>
      <div class="operationData_item">
        <img :src="require(`@/assets/images/kanban/senderNumber.png`)" alt="">
        <div style="margin-left: 10px">
          <div>{{ $t('navbar.shipmentTicketCount') }}</div>
          <div class="number">{{ loadding }}</div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'OperationData',
  props: {
    loadding: {
      type: Number,
      default: 0
    },
    unloading: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      value: '',
      options: [],
      centerData: {}
    };
  },
  mounted() {},
  methods: {}
};
</script>

<style scoped></style>
