var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nodeTable" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.dataList, "max-height": "300", border: "" },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("operationCenter.ind"),
              width: "60px",
              type: "index",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.operationTime"),
              prop: "createTime",
              width: "180",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("userManage.node"),
              prop: "operationNode",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type:
                            (_vm.status[scope.row.operationNode] &&
                              _vm.status[scope.row.operationNode].type) ||
                            "",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              (_vm.status[scope.row.operationNode] &&
                                _vm.status[scope.row.operationNode].name) ||
                                ""
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.operator"),
              prop: "createUserName",
              width: "180",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.details"),
              prop: "remarks",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    !scope.row.extra
                      ? _c("span", [_vm._v(_vm._s(scope.row.remarks))])
                      : _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.onOpen(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.remarks))]
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          style: { overflowY: "auto" },
          attrs: {
            title: _vm.$t("basicData.订单信息"),
            visible: _vm.dialogTableVisible,
            width: "50%",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [_c("logTable", { attrs: { "data-list": _vm.dialogData } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }