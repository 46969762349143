<template>
  <div style="height: 80vh;overflow-y: auto">
    <div v-if="tableInformation.orderPaymentList && tableInformation.orderPaymentList.length > 0" :key="showUploadFiles">
      <el-card v-for="(item, index) in tableInformation.orderPaymentList" :key="index" style="margin-bottom: 20px">
        <el-form label-position="top">
          <el-row>
            <el-col :span="8">
              <!--上传时间-->
              <el-form-item :label="$t('home.uploadTime')">
                <span>{{ item.createTime }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!--上传用户-->
              <el-form-item :label="$t('home.UploadUser')">
                <span>{{ item.createUserName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <!--支付凭证-->
              <el-form-item :label="$t('home.PaymentCertificate')">
                <div v-for="(list, ind) in item.paymentVoucherList" :key="ind" style="margin-bottom: 5px">
                  <div style="display: flex;align-items: center;cursor: pointer" @click="getImgSrcFile(list.url)">
                    <img :src="list.url" style="width: 80px;height: 60px;">
                    <div style="flex: 1;margin-left: 5px">{{ list.name }}</div>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <div style="border-top: 3px solid #eee">
            <div style="margin-top: 20px;">
              <!--安骏财务审核详情-->
              <span style="font-weight: bold">{{ $t('home.AnjunFinancialAuditDetails') }}</span>
              <!--财务审核-->
              <el-button
                v-if="item.reviewResults === 1"
                v-permit:remove="'but:collection-center:largeOrderManagement:financial-audit'"
                type="primary" size="small" style="margin-left: 10px"
                @click="examine(item)"
              >{{ $t('home.FinancialAudit') }}</el-button>
            </div>
            <div v-if="item.reviewResults !== 1">
              <el-row>
                <el-col :span="8">
                  <!--审核状态-->
                  <el-form-item :label="$t('Resource.ApprovalStatus')">
                    <span>{{ item.reviewResultsName }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <!--审核用户-->
                  <el-form-item :label="$t('home.AuditUser')">
                    <span>{{ item.reviewerName }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <!--审核结果-->
                  <el-form-item :label="$t('home.appliveResult')">
                    <span>{{ item.reviewResultsName }}</span>
                  </el-form-item>
                </el-col>
                <el-col v-if="item.reviewRemarks" :span="24">
                  <!--审核备注-->
                  <el-form-item :label="$t('basicData.审核备注')">
                    <div>{{ item.reviewRemarks }}</div>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-form>
      </el-card>
      <!--财务审核-->
      <el-dialog
        :title="$t('home.FinancialAudit')"
        width="600px"
        :visible.sync="examineDialogVisible"
        :append-to-body="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <div>
          <el-form
            ref="examineFormRef"
            :key="examineDialogVisible"
            :model="examineForm"
            label-position="top"
          >
            <div>
              <!--审核结果-->
              <el-form-item
                :label="$t('home.appliveResult')"
                prop="reviewResults"
                :rules="[
                  { required: true, message: $t('home.AuditResultCannotBeEmpty'), trigger: ['blur','change'] }
                ]"
              >
                <div style="line-height: 12px;margin-bottom: 20px;font-size: 12px">
                  <!--请确认客户的支付金额与应付金额是否一致-->
                  <span style="color: #f00;margin-left: 10px;">{{ $t('home.PleaseConfirmThatThePayment') }}</span>
                </div>
                <el-radio-group v-model="examineForm.reviewResults">
                  <!--支付金额一致，审核通过-->
                  <el-radio :label="3" style="display: block; margin-bottom: 10px">{{ $t('home.auditPassed') }}</el-radio>
                  <!--支付金额不一致，审核不通过-->
                  <el-radio :label="2" style="display: block">{{ $t('home.auditNotPassed') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <!--审核备注-->
            <el-form-item
              v-if="examineForm.reviewResults === 2"
              :label="$t('basicData.审核备注')"
              prop="reviewRemark"
              :rules="[
                { required: true, message: $t('home.AuditRemarkCannotBeEmpty'), trigger: ['blur','change'] }
              ]"
            >
              <el-input
                v-model="examineForm.reviewRemark"
                type="textarea"
                :rows="4"
                :placeholder="$t('home.PleaseEnterARemark')"
                :maxlength="100"
                show-word-limit
              />
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="examineDialogVisible = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
          <el-button type="primary" size="small" @click="confirmAudit">{{ $t('GlobalSetObj.confirm') }}</el-button>
        </div>
      </el-dialog>
    </div>
    <div v-else style="text-align: center">
      <!--暂无数据-->
      {{ $t('operatingPlatform.nullMsg') }}
    </div>
    <!--    不为审核通过或者待审核时允许上传-->
    <div v-if="![1,4].includes(tableInformation.paymentVoucher)" v-permit:remove="'but:collection-center:largeOrderManagement:upload-payment-voucher'">
      <!-- 上传支付凭证-->
      <el-button type="success" @click="uploadPayment()">{{ $t('orderDetails.UploadPaymentVoucher') }}</el-button>
    </div>
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('orderDetails.UploadPaymentVoucher')"
      :visible.sync="showUploadFiles"
      top="10vh"
      width="800px"
    >
      <div>
        <uploadVoucher
          :key="showUploadFiles" ref="uploadVoucher" :details="tableInformation"
          @closeUploadVoucher="closeUploadVoucher"
        />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showUploadFiles = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="saveDetail">{{ $t('GlobalSetObj.Salve') }}</el-button>
      </div>
    </el-dialog>
    <!-- 图片预览 -->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="imgArr" />
  </div>
</template>

<script>
import { getCargoOrderDetail } from '@/api/bulkExpressApi';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { payFinanceCheck } from '@/api/orderApi';
import UploadVoucher from './uploadVoucher.vue';

export default {
  name: 'PaymentVoucher',
  components: {
    UploadVoucher,
    ElImageViewer
  },
  props: {
    grandson: {
      type: Number
    }
  },
  data() {
    return {
      examineDialogVisible: false,
      examineForm: {
        orderId: '',
        reviewResults: 3,
        reviewRemark: '',
        reviewerId: '',
        paymentVoucherId: '',
        uploadCount: ''
      },
      tableInformation: {},
      showViewer: false,
      examineData: null,
      imgArr: [],
      showUploadFiles: false // 上传支付凭证弹窗
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 打开审核弹窗
    examine(data) {
      this.examineForm = {
        orderId: '',
        reviewResults: 3,
        reviewRemark: '',
        reviewerId: '',
        paymentVoucherId: '',
        uploadCount: ''
      };
      this.examineData = data;
      this.examineDialogVisible = true;
    },
    // 获取文件路径最后一个/后的名称
    getFileExtension(url) {
      const parts = url.split('/');
      return parts[parts.length - 1];
    },
    // 获取审核详情
    getData() {
      getCargoOrderDetail(this.grandson).then(res => {
        if (res.code === 200) {
          if (res.data.orderPaymentList && res.data.orderPaymentList.length > 0) {
            res.data.orderPaymentList.map(list => {
              list.paymentVoucherList = [];
              const imgList = list.paymentVoucher.split(',');
              imgList.map((item, index) => {
                const file = {
                  uid: index,
                  name: decodeURIComponent(this.getFileExtension(item)),
                  url: item
                };
                list.paymentVoucherList.push(file);
              });
            });
          }
          this.tableInformation = res.data;
          console.log(this.tableInformation, "====this.tableInformation");
        }
      });
    },
    // 查看图片
    getImgSrcFile(url) {
      function getFileExtensionName(url) {
        // 使用正则表达式匹配最后一个点之后的部分作为文件扩展名
        const match = url.match(/\.([a-zA-Z0-9]+)(?:[\?#]|$)/);
        if (match) {
          return match[1].toLowerCase();
        }
        return '';
      }
      function isImage(extension) {
        // 定义常见的图片文件扩展名
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
        return imageExtensions.includes(extension);
      }
      const extension = getFileExtensionName(url);
      if (isImage(extension)) {
        this.imgArr = [url];
        this.showViewer = true;
      } else {
        window.open(url);
      }
    },
    // 关闭图片预览
    closeViewer() {
      this.showViewer = false;
    },
    // 确定审核结果
    confirmAudit() {
      this.$refs.examineFormRef.validate(valid => {
        if (valid) {
          const param = {
            orderId: this.examineData.orderId,
            reviewResults: this.examineForm.reviewResults,
            reviewRemark: this.examineForm.reviewRemark,
            reviewerId: this.$store.state.user.id,
            paymentVoucherId: this.examineData.id,
            uploadCount: this.tableInformation.orderPaymentList.length
          };
          payFinanceCheck(param).then(res => {
            if (res.code === 200) {
              this.$message({
                message: this.$t('Resource.AuditSuccess'),
                type: 'success'
              });
              this.getData();
              this.bus.$emit('updateTableList');
              this.examineDialogVisible = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    // 打开上传支付凭证弹窗
    uploadPayment() {
      this.showUploadFiles = true;
    },
    // 关闭上传支付凭证弹窗
    closeUploadVoucher() {
      this.getData();
      this.bus.$emit('updateTableList');
      this.showUploadFiles = false;
    },
    // 保存凭证
    saveDetail() {
      this.$refs.uploadVoucher.submit();
    }
  }
};
</script>

<style scoped>

</style>
