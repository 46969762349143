var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { staticClass: "box-card" }, [
    _c(
      "div",
      { staticStyle: { "margin-bottom": "15px", "font-weight": "bold" } },
      [_vm._v("网点时效")]
    ),
    _c("div", {
      staticStyle: { height: "230px", width: "100%" },
      attrs: { id: "main01" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }