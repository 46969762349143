var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { staticClass: "box-card" }, [
    _c("div", { staticStyle: { "margin-bottom": "15px" } }, [
      _c("span", { staticClass: "fontClsBold02" }, [
        _vm._v(_vm._s(_vm.$t("changepwd.staticAgingMonitoring"))),
      ]),
    ]),
    _c("div", { staticStyle: { display: "flex" } }, [
      _c("div", { staticClass: "data_center" }, [
        _c("div", { staticClass: "titleLabel" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/kanban/yuan.png"), alt: "" },
          }),
          _c("div", { staticClass: "titleLabelTime" }, [
            _vm._v(_vm._s(_vm.$t("customerManagements.HoursDetention24"))),
          ]),
          _c("div", { staticClass: "titleLabelTimeDot" }, [
            _vm._v("..............."),
          ]),
        ]),
        _c("div", { staticClass: "numberTime" }, [
          _vm._v(_vm._s(_vm.centerData.twentyFourHours || 0)),
        ]),
      ]),
      _c("div", { staticClass: "data_center" }, [
        _c("div", { staticClass: "titleLabel" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/kanban/yuan.png"), alt: "" },
          }),
          _c("div", { staticClass: "titleLabelTime" }, [
            _vm._v(_vm._s(_vm.$t("customerManagements.HoursDetention48"))),
          ]),
          _c("div", { staticClass: "titleLabelTimeDot" }, [
            _vm._v("..............."),
          ]),
        ]),
        _c("div", { staticClass: "numberTime" }, [
          _vm._v(_vm._s(_vm.centerData.fortyEightHours || 0)),
        ]),
      ]),
      _c("div", { staticClass: "data_center" }, [
        _c("div", { staticClass: "titleLabel" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/kanban/yuan.png"), alt: "" },
          }),
          _c("div", { staticClass: "titleLabelTime" }, [
            _vm._v(_vm._s(_vm.$t("customerManagements.HoursDetention72"))),
          ]),
          _c("div", { staticClass: "titleLabelTimeDot" }, [
            _vm._v("..............."),
          ]),
        ]),
        _c("div", { staticClass: "numberTime" }, [
          _vm._v(_vm._s(_vm.centerData.seventyTwoHours || 0)),
        ]),
      ]),
      _c("div", { staticClass: "data_center" }, [
        _c("div", { staticClass: "titleLabel" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/kanban/yuan.png"), alt: "" },
          }),
          _c("div", { staticClass: "titleLabelTime" }, [
            _vm._v(_vm._s(_vm.$t("customerManagements.DayDetention7"))),
          ]),
          _c("div", { staticClass: "titleLabelTimeDot" }, [
            _vm._v("..............."),
          ]),
        ]),
        _c("div", { staticClass: "numberTime" }, [
          _vm._v(_vm._s(_vm.centerData.sevenDays || 0)),
        ]),
      ]),
      _c("div", { staticClass: "data_center" }, [
        _c("div", { staticClass: "titleLabel" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/kanban/yuan.png"), alt: "" },
          }),
          _c("div", { staticClass: "titleLabelTime" }, [
            _vm._v(_vm._s(_vm.$t("customerManagements.DayDetention15"))),
          ]),
        ]),
        _c("div", { staticClass: "numberTime" }, [
          _vm._v(_vm._s(_vm.centerData.fifteenDays || 0)),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }