<template>
  <div class="app-container">
    <el-card class="mb5">
      <el-form
        ref="queryForm"
        class="searchContainer"
        :model="queryForm"
        label-position="top"
      >
        <el-row :gutter="10">
          <el-col :span="6">
            <!--错误邮编-->
            <el-form-item :label="$t('basicData.IncorrectPostalCode')" prop="errorPostalCode">
              <el-input
                id="errorPostalCode" v-model="queryForm.params.errorPostalCode" :placeholder="$t('orderDetails.pleaseInput')"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--正确邮编-->
            <el-form-item :label="$t('basicData.CorrectPostalCode')" prop="correctPostalCode">
              <el-input
                id="correctPostalCode" v-model="queryForm.params.correctPostalCode" :placeholder="$t('orderDetails.pleaseInput')"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="left">
        <el-col :span="8">
          <!--查询-->
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="getDataSearch"
          >{{ $t('basicData.Search') }}
          </el-button>
          <!--重置-->
          <el-button
            icon="el-icon-refresh"
            size="mini"
            @click="queryFormReset"
          >{{ $t('basicData.reset') }}
          </el-button>
        </el-col>
      </el-row>
    </el-card>

    <el-card>
      <div class="mb10">
        <!--添加-->
        <el-button
          v-permit:remove="'but:finance:basicData:postalCodeCorrection:add'"
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="handlerOperation(1)"
        >{{ $t('basicData.Add') }}
        </el-button>
      </div>

      <el-table
        ref="table"
        :data="tableData"
        border
        class="table-height"
        :height="tableHeightRow.height"
      >
        <!--序号-->
        <el-table-column
          :label="$t('basicData.serialNumber')" type="index" width="60"
          align="center"
        >
          <template v-slot="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!--错误邮编州-->
        <el-table-column :label="$t('basicData.wrongZipCodeState')" prop="errorPostalCodeState" />
        <!--错误邮编-->
        <el-table-column :label="$t('basicData.IncorrectPostalCode')" prop="errorPostalCode" width="120" />
        <!--正确邮编-->
        <el-table-column :label="$t('basicData.CorrectPostalCode')" prop="correctPostalCode" width="120" />
        <!--状态-->
        <el-table-column :label="$t('basicData.status')" width="100">
          <template v-slot="scope">
            <!--启用-->
            <el-tag v-if="scope.row.state === 0">{{ $t('basicData.enable') }}</el-tag>
            <!--禁用-->
            <el-tag v-else-if="scope.row.state === 1" type="danger">{{ $t('basicData.disable') }}</el-tag>
          </template>
        </el-table-column>
        <!--创建人-->
        <el-table-column :label="$t('basicData.CreatePeople')" prop="createUserStr" width="140" />
        <!--创建时间-->
        <el-table-column :label="$t('basicData.CreationTime')" prop="createTime" width="160" />
        <!--最后修改人-->
        <el-table-column :label="$t('basicData.LastModifier')" prop="updateUserStr" width="140" />
        <!--最后修改时间-->
        <el-table-column :label="$t('basicData.LastModified')" prop="updateTime" width="160" />
        <!--操作-->
        <el-table-column :label="$t('basicData.operate')" width="160">
          <template v-slot="scope">
            <div>
              <!--编辑-->
              <el-button
                slot="reference" v-permit:remove="'but:finance:basicData:postalCodeCorrection:edit'" class="ml"
                size="mini"
                @click="handlerOperation(2, scope.row)"
              >{{ $t('basicData.Edit') }}</el-button>
              <!--确定禁用吗？-->
              <el-popconfirm
                v-if="scope.row.state === 0"
                v-permit:remove="'but:finance:basicData:postalCodeCorrection:start'"
                :title="$t('basicData.AreYouSureDisabled')"
                @confirm="asyncPostalCodeCorrectionUpdate(scope.row, 1)"
              >
                <!--禁用-->
                <el-button
                  slot="reference" class="ml" type="warning"
                  size="mini"
                >{{ $t('basicData.disable') }}</el-button>
              </el-popconfirm>
              <!--确定启用吗？-->
              <el-popconfirm
                v-if="scope.row.state === 1"
                v-permit:remove="'but:finance:basicData:postalCodeCorrection:start'"
                :title="$t('basicData.AreYouSureEnabled')"
                @confirm="asyncPostalCodeCorrectionUpdate(scope.row, 0)"
              >
                <!--启用-->
                <el-button
                  slot="reference" class="ml" type="primary"
                  size="mini"
                >{{ $t('basicData.enable') }}</el-button>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getData"
          @size-change="getData"
        />
      </div>
    </el-card>

    <!-- 新增/编辑弹窗 -->
    <el-drawer
      :visible.sync="drawerObj.visible"
      direction="ltr"
      :title="typeIndex === 1 ? $t('basicData.AddPostalCode') : $t('basicData.PostalCodeDetails')"
      size="50%"
      custom-class="postal-code-correction-drawer"
    >
      <details-cmp
        :key="editKey"
        :type-index="typeIndex"
        :edit-data="editData"
        @updatePage="updatePage"
      />
    </el-drawer>

  </div>
</template>

<script>
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { getUserName } from '@/utils/asyncTools';
import {
  apiPostalCodeCorrectionPage,
  apiPostalCodeCorrectionUpdate
} from '@/api/finance/postalCodeCorrection';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';

export default {
  name: 'PostalCodeCorrection',
  components: {
    'details-cmp': () => import('./components/DetailsCmp.vue')
  },

  mixins: [estimateTableHeight],

  data() {
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          errorPostalCode: '',
          correctPostalCode: ''
        }
      },
      tableData: [],
      total: 0,
      // 弹窗
      drawerObj: {
        visible: false
      },
      editData: {
        errorPostalCode: '',
        correctPostalCode: '',
        id: '',
        state: 1
      },
      typeIndex: 1,
      editKey: new Date().getTime(),
      userIdAndNameObj: null
    };
  },

  created() {
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.getData();
  },

  methods: {
    // 获取列表数据
    getData() {
      apiPostalCodeCorrectionPage(this.queryForm).then(res => {
        const { code, data } = res;
        if (code !== 200) {
          return;
        }
        this.tableData = data.records.map(item => {
          const createUserStr = getUserName(item.createUser, this.userIdAndNameObj);
          const updateUserStr = getUserName(item.updateUser, this.userIdAndNameObj);
          return {
            ...item,
            updateUserStr,
            createUserStr
          };
        });
        this.total = data.total;
      }).catch(err => {
        console.error(err);
      });
    },

    // 搜索
    getDataSearch() {
      this.queryForm.pageNumber = 1;
      this.getData();
    },

    // 重置
    queryFormReset() {
      this.queryForm.params.errorPostalCode = '';
      this.queryForm.params.correctPostalCode = '';

      this.getDataSearch();
    },

    // 启用禁用
    asyncPostalCodeCorrectionUpdate(row, type) {
      row.state = type;
      apiPostalCodeCorrectionUpdate(row).then(res => {
        const { code } = res;
        if (code !== 200) {
          return;
        }
        this.$message({
          message: this.$i18n.t('basicData.TheOperationSucceeded'), // '操作成功',
          type: 'success'
        });
        this.getData();
      }).catch(err => {
        console.error(err);
      });
    },

    // 添加 编辑
    handlerOperation(typeIndex, row) {
      this.typeIndex = typeIndex;
      if (row) {
        // 编辑
        this.editData = {
          errorPostalCodeState: row.errorPostalCodeState,
          errorPostalCode: row.errorPostalCode,
          correctPostalCode: row.correctPostalCode,
          state: row.state,
          id: row.id
        };
      }
      this.drawerObj.visible = true;
      this.editKey = new Date().getTime();
    },

    // 关闭弹窗
    updatePage(isBool) {
      this.drawerObj.visible = false;
      isBool && this.getData();
    }
  }
};
</script>

<style scoped>
.ml {
  margin-left: 10px;
}

::v-deep .el-drawer__body {
  height: calc(100% - 77px);
}
</style>

