<template>
  <div class="app-container">
    <el-form :inline="false" label-position="top" size="small">
      <el-row :gutter="20">
        <el-col :span="4">
          <!--  问题件类型  -->
          <el-form-item :label="$t('collectionCenter.problemPieceType')">
            <el-select
              v-model="queryForm.params.type"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              style="width: 100%"
            >
              <el-option :label="$t('collectionCenter.lanshou')" :value="0" />
              <el-option :label="$t('collectionCenter.dispatch')" :value="1" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--  问题件原因分类  -->
          <el-form-item :label="$t('changepwd.collectReason')">
            <el-select
              v-model="queryForm.params.reasonClassificationCode"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in problemReasonTypeList"
                :key="index"
                :label="item.dictDescribe"
                :value="item.dictCode"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--  问题件层级  -->
          <el-form-item :label="$t('changepwd.collectLevel')">
            <el-select
              v-model="queryForm.params.level"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              style="width: 100%"
            >
              <el-option :label="$t('changepwd.oneLeave')" :value="1" />
              <el-option :label="$t('changepwd.twoLeave')" :value="2" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--  状态   -->
          <el-form-item :label="$t('GlobalSetObj.status')">
            <el-select
              v-model="queryForm.params.status"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              style="width: 100%"
            >
              <el-option :label="$t('GlobalSetObj.enable')" :value="0" />
              <el-option :label="$t('GlobalSetObj.stop')" :value="1" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--问题件原因-->
          <el-form-item :label="$t('GlobalSetObj.problemReason')">
            <el-input
              v-model="queryForm.params.value"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              clearable
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="15">
          <!--搜索-->
          <el-button
            icon="el-icon-search" size="small" type="primary"
            @click="enterSearch"
          >{{ $t('newOrder.search') }}
          </el-button>
          <!--重置-->
          <el-button
            icon="el-icon-refresh-left" size="small" type="info"
            @click="resetForm"
          >{{ $t('newOrder.reset') }}
          </el-button>
          <el-button
            v-permit:remove="'btn:problemConfiguration:addProblem'"
            size="small"
            style="margin-bottom: 15px"
            type="primary"
            @click="addProblem"
          >{{ $t('changepwd.addProblem') }}
          </el-button>
        </el-col>
      </el-row>
    </el-form>
    <!--  表格数据  -->
    <el-table
      :data="tableData"
      :header-cell-style="{ background: '#f2f2f2', color: 'black' }"
      :height="620"
      border
      style="width: 100%"
    >
      <el-table-column
        :label="$t('GlobalSetObj.serial')" align="center" type="index"
        width="70"
      />
      <el-table-column
        v-for="item in tableHeaderList"
        :key="item.id"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
        :show-overflow-tooltip="false"
      >
        <template slot-scope="scope">
          <div v-if="item.prop === 'status'">
            <div v-if="scope.row.status === 1" style="color: #f56c6c">
              {{ $t('GlobalSetObj.stop') }}
            </div>
            <div v-else style="color: #409eff">{{ $t('GlobalSetObj.enable') }}</div>
          </div>
          <div v-else-if="item.prop === 'zhValue'">
            <div class="limit-lines">{{ scope.row.zhValue }}</div>
          </div>
          <div v-else-if="item.prop === 'enValue'">
            <div class="limit-lines">{{ scope.row.enValue }}</div>
          </div>
          <div v-else-if="item.prop === 'puValue'">
            <div class="limit-lines">{{ scope.row.puValue }}</div>
          </div>
          <div v-else-if="item.prop === 'remark'">
            <div class="limit-lines">{{ scope.row.remark }}</div>
          </div>
          <div v-else class="limit-lines">
            {{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '--' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('newOrder.operation')" align="center" fixed="right"
        width="100"
      >
        <template slot-scope="scope">
          <div style="display: flex; justify-content: space-between; align-items: center">
            <el-tooltip
              :content="$t('GlobalSetObj.edit')"
              class="item"
              effect="dark"
              placement="top"
            >
              <i class="el-icon-edit textOperator" @click="editMessage(scope.row)" />
            </el-tooltip>
            <el-tooltip
              v-if="scope.row.status === 1"
              :content="$t('GlobalSetObj.enable')"
              class="item"
              effect="dark"
              placement="top"
            >
              <i class="el-icon-circle-check textOperator" @click="updateStatus(scope.row)" />
            </el-tooltip>
            <el-tooltip
              v-else
              :content="$t('GlobalSetObj.stop')"
              class="item"
              effect="dark"
              placement="top"
            >
              <i class="el-icon-remove-outline textOperator1" @click="updateStatus(scope.row)" />
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0; float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!-- 新增问题件 -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="dialogTitle"
      :visible.sync="addCusTimeDialog"
      top="30px"
      width="750px"
      @close="handleCloseDialog"
    >
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        class="demo-ruleForm"
        label-position="top"
        label-width="auto"
      >
        <div style="height: 650px; padding: 0 10px; overflow: auto; width: 100%">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item :label="$t('collectionCenter.problemPieceType')" prop="category">
                <el-select v-model="ruleForm.category" :disabled="isDisable" style="width: 100%">
                  <el-option :label="$t('changepwd.putongProblem')" :value="0" />
                  <el-option :label="$t('changepwd.teshuProblem')" :value="1" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('collectionCenter.problemPieceType')" prop="type">
                <el-select
                  v-model="ruleForm.type"
                  :disabled="isDisable"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  style="width: 100%"
                >
                  <el-option :label="$t('collectionCenter.lanshou')" :value="0" />
                  <el-option :label="$t('collectionCenter.dispatch')" :value="1" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('changepwd.collectReason')" prop="reasonClassificationCode">
                <el-select
                  v-model="ruleForm.reasonClassificationCode"
                  :disabled="isDisable"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(item, index) in problemReasonTypeList"
                    :key="index"
                    :label="item.dictDescribe"
                    :value="item.dictCode"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('changepwd.numCode')" prop="numCode">
                <el-input
                  v-model="ruleForm.numCode"
                  :disabled="isDisable"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('changepwd.collectLevel')" prop="level">
                <el-select
                  v-model="ruleForm.level"
                  :disabled="isDisable"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  style="width: 100%"
                  @change="getoneLevalproblemList"
                >
                  <el-option :label="$t('changepwd.oneLeave')" :value="1" />
                  <el-option :label="$t('changepwd.twoLeave')" :value="2" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="ruleForm.level === 2" :span="12">
              <el-form-item :label="$t('changepwd.parentCode')" prop="parentCode">
                <div v-if="isDisable">
                  <el-input v-model="parentValue" :disabled="isDisable" style="width: 100%" />
                </div>
                <div v-else>
                  <el-select
                    v-model="ruleForm.parentCode"
                    :disabled="isDisable"
                    :placeholder="$t('GlobalSetObj.pleaseChoose')"
                    filterable
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in oneLevalproblemList"
                      :key="item.code"
                      :label="item.value"
                      :value="item.code"
                    />
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('changepwd.problemCode')" prop="code">
                <el-input
                  v-model="ruleForm.code"
                  :disabled="isDisable"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                  maxlength="50"
                  show-word-limit
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!--  状态   -->
              <el-form-item :label="$t('GlobalSetObj.status')" prop="status">
                <el-select
                  v-model="ruleForm.status"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  style="width: 100%"
                >
                  <el-option :label="$t('GlobalSetObj.enable')" :value="0" />
                  <el-option :label="$t('GlobalSetObj.stop')" :value="1" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('GlobalSetObj.sort')" prop="sort">
                <el-input
                  v-model="ruleForm.sort"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('changepwd.problemReasonZH')" prop="zhValue">
                <el-input
                  v-model="ruleForm.zhValue"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                  maxlength="200"
                  show-word-limit
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('changepwd.problemReasonEN')" prop="enValue">
                <el-input
                  v-model="ruleForm.enValue"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                  maxlength="200"
                  show-word-limit
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('changepwd.problemReasonPU')" prop="puValue">
                <el-input
                  v-model="ruleForm.puValue"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                  maxlength="200"
                  show-word-limit
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('changepwd.problemReasonEa')" prop="esValue">
                <el-input
                  v-model="ruleForm.esValue"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  clearable
                  maxlength="200"
                  show-word-limit
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('GlobalSetObj.remark')" prop="remark">
                <el-input
                  v-model="ruleForm.remark"
                  :placeholder="$t('GlobalSetObj.pleaseInput')"
                  :rows="3"
                  clearable
                  maxlength="500"
                  show-word-limit
                  style="width: 100%"
                  type="textarea"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cusSubmit(1)">{{ $t('GlobalSetObj.cancel') }}</el-button>
        <el-button
          size="small" type="primary" @click="cusSubmit(2)"
        >{{ $t('GlobalSetObj.confirm') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getParentProblemType,
  getproblemChangeAdd,
  getproblemChangeStatus,
  getproblemCongfigPage,
  getProblemReason,
  problemChangeUpdate
} from '@/api/resources';
import { IssueLevel, problemtypeLeixingObj, problemtypesObj } from '@/utils/enumData';
import { check999Number, checkStrNumber } from '@/utils/validate';

export default {
  name: 'ProblemConfiguration',
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        params: {
          reasonClassificationCode: '',
          type: '', // 问题件类型（0-揽收 1-派送）
          level: '', // 问题件层级（1-一级 2-二级）
          status: '', // 是否展示（0-展示 1-不展示）
          value: '' // 问题件原因
        }
      },
      tableData: [],
      tableHeaderList: [
        // 问题件类型分类
        { id: 1, label: this.$t('changepwd.categoryName'), prop: 'categoryName', cloWidth: '160' },
        // 问题件类型
        {
          id: 2,
          label: this.$t('orderCenterCont.problemPieceType'),
          prop: 'typeName',
          cloWidth: '150'
        },
        // 问题件原因分类
        {
          id: 3,
          label: this.$t('changepwd.collectReason'),
          prop: 'reasonClassificationName',
          cloWidth: '150'
        },
        // 数字代码
        {
          id: 4,
          label: this.$t('changepwd.numCode'),
          prop: 'numCode',
          cloWidth: '150'
        },
        // 问题件层级
        {
          id: 5,
          label: this.$t('changepwd.collectLevel'),
          prop: 'levelName',
          cloWidth: '150'
        },
        // 上级问题件
        {
          id: 111,
          label: this.$t('changepwd.parentCode'),
          prop: 'parentValue',
          cloWidth: '150'
        },
        // 问题件代码
        {
          id: 6,
          label: this.$t('changepwd.problemCode'),
          prop: 'code',
          cloWidth: '150'
        },
        // 问题件原因（中文）
        {
          id: 7,
          label: this.$t('changepwd.problemReasonZH'),
          prop: 'zhValue',
          cloWidth: '150'
        },
        // 问题件原因（英语）
        {
          id: 8,
          label: this.$t('changepwd.problemReasonEN'),
          prop: 'enValue',
          cloWidth: '150'
        },
        // 问题件原因（葡语）
        {
          id: 9,
          label: this.$t('changepwd.problemReasonPU'),
          prop: 'puValue',
          cloWidth: '150'
        },
        // 备注
        {
          id: 10,
          label: this.$t('GlobalSetObj.remark'),
          prop: 'remark',
          cloWidth: '150'
        },
        // 排序值
        {
          id: 11,
          label: this.$t('changepwd.sortValue'),
          prop: 'sort',
          cloWidth: '150'
        },
        // 状态
        {
          id: 12,
          label: this.$t('GlobalSetObj.status'),
          prop: 'status',
          cloWidth: '100'
        },
        // 创建时间
        {
          id: 13,
          label: this.$t('GlobalSetObj.CreationTime'),
          prop: 'createTime',
          cloWidth: '150'
        },
        // 创建人
        {
          id: 14,
          label: this.$t('GlobalSetObj.CreatePeople'),
          prop: 'createUserName',
          cloWidth: '150'
        },
        // 更新时间
        {
          id: 15,
          label: this.$t('GlobalSetObj.UpdateTime'),
          prop: 'updateTime',
          cloWidth: '150'
        },
        // 更新人
        {
          id: 16,
          label: this.$t('GlobalSetObj.Updater'),
          prop: 'updateUserName',
          cloWidth: '150'
        }
      ],
      userList: [], // 用户列表
      customerMerchant: [],
      addCusTimeDialog: false,
      dialogTitle: this.$t('changepwd.addProblem'),
      ruleForm: {
        category: '',
        type: '',
        level: '',
        numCode: '',
        parentCode: null,
        code: '',
        zhValue: '',
        enValue: null,
        puValue: '',
        esValue: null,
        remark: null,
        sort: null,
        status: null,
        reasonClassificationCode: ''
      },
      rules: {
        category: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        type: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        numCode: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'change' },
          {
            validator: check999Number,
            trigger: 'change' // 触发时机与 required 规则一致
          }
        ],
        parentCode: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        level: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        reasonClassificationCode: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        code: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'change' },
          {
            validator: checkStrNumber,
            trigger: 'change' // 触发时机与 required 规则一致
          }
        ],
        status: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        sort: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'change' },
          {
            validator: check999Number,
            trigger: 'change' // 触发时机与 required 规则一致
          }
        ],
        zhValue: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'change' }
        ],
        puValue: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'change' }
        ],
        enValue: [
          { required: false, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'change' }
        ],
        esValue: [
          { required: false, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'change' }
        ],
        remark: [
          { required: false, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'change' }
        ]
      },
      detailData: [],
      editId: null,
      problemReasonTypeList: [],
      oneLevalproblemList: [],
      isDisable: false,
      parentValue: ''
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '180px' : '110px'
      };
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getSearch();
    this.getProblemReasonType();
    // this.getoneLevalproblemList();
  },
  methods: {
    addProblem() {
      this.dialogTitle = this.$t('changepwd.addProblem');
      this.isDisable = false;
      this.editId = null;
      this.updateRules(true);
      this.addCusTimeDialog = true;
    },
    // 点击确定
    cusSubmit(val) {
      if (val === 1) {
        this.addCusTimeDialog = false;
      } else {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            const data = {
              ...this.ruleForm
            };
            if (this.dialogTitle === this.$t('changepwd.addProblem')) {
              getproblemChangeAdd(data).then((res) => {
                if (res.code === 200) {
                  this.addCusTimeDialog = false;
                  this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
                  this.getSearch();
                }
              });
            } else {
              data.id = this.editId;
              problemChangeUpdate(data).then((res) => {
                if (res.code === 200) {
                  this.addCusTimeDialog = false;
                  this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
                  this.getSearch();
                }
              });
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    // 编辑
    editMessage(row) {
      console.log(row, 'row编辑');
      this.dialogTitle = this.$t('changepwd.editProblem');
      this.isDisable = true;
      this.parentValue = row.parentValue || '--';
      this.editId = row.id;
      this.ruleForm = {
        category: row.category,
        type: row.type,
        level: row.level,
        numCode: row.numCode,
        parentCode: row.parentCode,
        code: row.code,
        zhValue: row.zhValue,
        enValue: row.enValue,
        puValue: row.puValue,
        esValue: row.esValue,
        remark: row.remark,
        sort: row.sort,
        status: row.status,
        reasonClassificationCode: row.reasonClassificationCode
      };
      this.updateRules(false);
      this.addCusTimeDialog = true;
    },
    updateRules(flage) {
      // 使用 Vue.set 确保响应式
      this.$set(this.rules, 'type', [
        { required: flage, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
      ]);
      this.$set(this.rules, 'category', [
        { required: flage, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
      ]);
      this.$set(this.rules, 'parentCode', [
        { required: flage, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
      ]);
      this.$set(this.rules, 'level', [
        { required: flage, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
      ]);
      this.$set(this.rules, 'reasonClassificationCode', [
        { required: flage, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
      ]);
      if (flage) {
        this.$set(this.rules, 'numCode', [
          { required: flage, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'change' },
          {
            validator: check999Number,
            trigger: 'change' // 触发时机与 required 规则一致
          }
        ]);
        this.$set(this.rules, 'code', [
          { required: flage, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'change' },
          {
            validator: checkStrNumber,
            trigger: 'change' // 触发时机与 required 规则一致
          }
        ]);
      } else {
        this.$set(this.rules, 'numCode', [
          { required: flage, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'change' }
        ]);
        this.$set(this.rules, 'code', [
          { required: flage, message: this.$t('GlobalSetObj.pleaseInput'), trigger: 'change' }
        ]);
      }
    },
    // 启用停用
    updateStatus(row) {
      const data = {
        id: row.id,
        status: row.status === 1 ? 0 : 1
      };
      this.$confirm(this.$t('changepwd.areYouSureProblemTip'), this.$t('collectionCenter.tips'), {
        confirmButtonText: this.$t('collectionCenter.confirm'), // '确定'
        cancelButtonText: this.$t('collectionCenter.cancel'), // '取消'
        type: 'warning'
      })
        .then(async() => {
          getproblemChangeStatus(data).then((res) => {
            if (res.code === 200) {
              this.$message.success(this.$t('GlobalSetObj.ModificationSuccessful'));
              this.getSearch();
            }
          });
        })
        .catch(() => {
          console.log('取消关闭');
        });
    },
    enterSearch() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.pageNumber = 1;
      this.timeValue = [];
      this.queryForm.params = {
        reasonClassificationCode: '',
        type: '', // 问题件类型（0-揽收 1-派送）
        level: '', // 问题件层级（1-一级 2-二级）
        status: '', // 是否展示（0-展示 1-不展示）
        value: '' // 问题件原因
      };
      this.getSearch();
    },
    // 查询
    getSearch() {
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.pageNumber;
      data.params = this.queryForm.params;
      getproblemCongfigPage(data).then((res) => {
        if (res.code === 200) {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.forEach((item) => {
            if (item.category !== null) {
              item.categoryName = problemtypeLeixingObj[item.category];
            }
            if (item.type !== null) {
              item.typeName = problemtypesObj[item.type];
            }
            if (item.level !== null) {
              item.levelName = IssueLevel[item.level];
            }
          });
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    },
    // 查询问题件原因分类
    getProblemReasonType() {
      getProblemReason().then((res) => {
        if (res.code === 200) {
          this.problemReasonTypeList = res.data;
        }
      });
    },
    // 查询一级问题件列表
    getoneLevalproblemList() {
      this.ruleForm.parentCode = null;
      getParentProblemType().then((res) => {
        if (res.code === 200) {
          this.oneLevalproblemList = res.data;
        }
      });
    },
    handleCloseDialog() {
      this.ruleForm = {
        category: '',
        type: '',
        level: '',
        numCode: '',
        parentCode: null,
        code: '',
        zhValue: '',
        enValue: null,
        puValue: '',
        esValue: null,
        remark: null,
        sort: null,
        status: null,
        reasonClassificationCode: ''
      };
      this.$refs.ruleForm.resetFields();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0 15px;
  padding-bottom: 15px;
}

::v-deep {
  .drawerClass header {
    border-bottom: 1px solid #aaa !important;
    margin-bottom: 0 !important;
    padding: 20px 20px 20px !important;
  }
}

.selectLabel ::v-deep .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.selectLabel ::v-deep .el-input__icon {
  line-height: 28px !important;
}

.selectType ::v-deep .el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.textOperator {
  color: #409eff;
  cursor: pointer;
  font-size: 22px;
}

.textOperator1 {
  color: #f56c6c;
  cursor: pointer;
  font-size: 22px;
}

.messgeList {
  height: 450px;
  background-color: #f5f5f5;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
}

.downLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f7fa;
  cursor: pointer;
  padding: 5px;
}

.downModule {
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 15px;
}

.limit-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}
</style>
