export const driver = {
  driverTitle: {
    zh: '司机',
    en: 'Drivers',
    pu: 'Motoristas'
  },
  createDriver: {
    zh: '添加新驾驶员',
    en: '',
    pu: 'Criar Motorista'
  },
  cpfLabel: {
    zh: '个人税号/CPF',
    en: '',
    pu: 'CPF'
  },
  cpfPlaceholder: {
    zh: '按个人税号/CPF搜索',
    en: '',
    pu: 'Pesquise pelo CPF'
  },
  nameLabel: {
    zh: '姓名',
    en: '',
    pu: 'Nome'
  },
  namePlaceholder: {
    zh: '按姓名搜索',
    en: '',
    pu: 'Pesquise pelo nome'
  },
  statusDriver: {
    zh: '驾驶员状态',
    en: '',
    pu: 'Status Motoristas'
  },
  activeDrivers: {
    zh: '有效驾驶员',
    en: '',
    pu: 'Motoristas Ativos'
  },
  disabledDrivers: {
    zh: '失效驾驶员',
    en: '',
    pu: 'Motoristas Desativados'
  },
  document: {
    zh: '个人/法人税号',
    en: '',
    pu: 'Documento'
  },
  cnhType: {
    zh: '驾照类型',
    en: '',
    pu: 'Tipo de CNH'
  },
  driverPoints: {
    zh: '驾驶员积分',
    en: '',
    pu: 'Pontos do Motorista'
  },
  outsourcing: {
    zh: '外包',
    en: '',
    pu: 'Terceirização'
  },
  yes: {
    zh: '是',
    en: '',
    pu: 'Sim'
  },
  no: {
    zh: '否',
    en: '',
    pu: 'Não'
  },
  edit: {
    zh: '编辑',
    en: '',
    pu: 'Editar'
  },
  delete: {
    zh: '删除',
    en: '',
    pu: 'Excluir'
  },
  restore: {
    zh: '恢复',
    en: '',
    pu: 'Restaurar'
  },

  // modal
  modalTitle: {
    zh: '创建司机',
    en: '',
    pu: 'Criando Motorista'
  },
  modalParagraph: {
    zh: '请填写下方的字段以完成在我们系统中创建司机的操作。',
    en: '',
    pu: 'Preencha os campos logo abaixo para realizar a criação de um motorista em nosso sistema'
  },
  modalNamePholder: {
    zh: '请输入您的姓名',
    en: '',
    pu: 'Digite seu nome'
  },
  modalPhone: {
    zh: '电话',
    en: '',
    pu: 'Telefone'
  },
  modalPhonePholder: {
    zh: '司机电话',
    en: '',
    pu: 'Telefone do Motorista'
  },
  modalCpfPholder: {
    zh: '司机 CPF',
    en: '',
    pu: 'CPF do Motorista'
  },
  modalDriverLicence: {
    zh: '驾驶证',
    en: '',
    pu: 'CNH do Motorista'
  },
  modalLicencePholder: {
    zh: '请输入驾驶证号码',
    en: '',
    pu: 'Digite a CNH do Motorista'
  },
  modalScoreLicence: {
    zh: '驾驶执照分数',
    en: '',
    pu: 'Pontuação da CNH'
  },
  modalScoreLicencePholder: {
    zh: '请输入驾驶执照分数',
    en: '',
    pu: 'Digite os pontos da CNH'
  },
  modalScoreDriver: {
    zh: '司机分数',
    en: '',
    pu: 'Pontuação do Motorista'
  },
  modalScoreDriverPholder: {
    zh: '请输入司机分数',
    en: '',
    pu: 'Digite os pontos do motorista'
  },
  modalLicenseType: {
    zh: '驾驶证类型',
    en: '',
    pu: 'Tipo de CNH'
  },
  modalLicenseTypePholder: {
    zh: '请输入驾驶证类型',
    en: '',
    pu: 'Digite o tipo de CNH'
  },
  modalLicenseValidate: {
    zh: '驾驶证有效期',
    en: '',
    pu: 'Validade da CNH'
  },
  modalBaseName: {
    zh: '基地名称',
    en: '',
    pu: 'Nome da Base'
  },
  modalUsername: {
    zh: '用户名',
    en: '',
    pu: 'Nome de Usuário'
  },
  modalOutsourcedDriver: {
    zh: '外包司机',
    en: '',
    pu: 'Motorista terceirizado'
  },
  modalPreviousButton: {
    zh: '上一页',
    en: '',
    pu: 'Anterior'
  },
  modalNextButton: {
    zh: '下一个',
    en: '',
    pu: 'Próximo'
  },
  modalSendButton: {
    zh: '发送',
    en: '',
    pu: 'Enviar'
  },

  // modal edit
  modalEditTitle: {
    zh: '编辑司机中',
    en: '',
    pu: 'Editando Motorista'
  },
  modalEditParagraph: {
    zh: '请填写下方的字段以完成在我们系统中编辑司机的操作。',
    en: '',
    pu: 'Preencha os campos logo abaixo para realizar a edição de um motorista em nosso sistema'
  },

  // modal delete
  modalDeleteTitle: {
    zh: '正在删除司机',
    en: '',
    pu: 'Excluindo motorista'
  },
  modalDeleteParagraph: {
    zh: '请填写下方的字段以完成在我们系统中删除司机的操作。',
    en: '',
    pu: 'Preencha os campos logo abaixo para realizar a exclusão de um motorista em nosso sistema'
  },
  modalDeleteObservation: {
    zh: '备注',
    en: '',
    pu: 'Observação'
  },
  modalDeletePlaceholder: {
    zh: '请输入一条删除备注',
    en: '',
    pu: 'Digite uma observação para a remoção'
  },

  // modal restore
  modalRestoreTitle: {
    zh: '您确定吗？',
    en: '',
    pu: 'Você tem certeza absoluta?'
  },
  modalRestoreParagraph: {
    zh: '此操作将恢复所选驾驶员的数据，使其在我们的系统中再次变为可用，可进行各种操作。',
    en: '',
    pu: 'Essa ação irá restaurar os dados do motorista selecionado, fazendo com que ele volte a ficar disponível para todo tipo de operação no nosso sistema'
  },
  modalButtonCancel: {
    zh: '取消',
    en: '',
    pu: 'Cancelar'
  },
  modalButtonRestore: {
    zh: '是的，我想要恢复',
    en: '',
    pu: 'Sim, quero restaurar'
  },

  // modal address

  modalCep: {
    zh: '邮政编码',
    en: '',
    pu: 'CEP'
  },
  modalCepPlaceholder: {
    zh: '输入卖家的邮政编码',
    en: '',
    pu: 'Digite o CEP do seller'
  },
  modalStreet: {
    zh: '街道',
    en: '',
    pu: 'Rua'
  },
  modalStreetPlaceholder: {
    zh: '输入街道名称',
    en: '',
    pu: 'Digite o nome da rua'
  },
  modalNumber: {
    zh: '号码',
    en: '',
    pu: 'Número'
  },
  modalNumberPlaceholder: {
    zh: '输入号码',
    en: '',
    pu: 'Digite o número'
  },
  modalNeighborhood: {
    zh: '社区',
    en: '',
    pu: 'Bairro'
  },
  modalNeighborhoodPlaceholder: {
    zh: '输入社区名称',
    en: '',
    pu: 'Digite o bairro'
  },
  modalMunicipality: {
    zh: '市',
    en: '',
    pu: 'Município'
  },
  modalMunicipalityPlaceholder: {
    zh: '输入市名',
    en: '',
    pu: 'Digite o municipio'
  },
  modalState: {
    zh: '州',
    en: '',
    pu: 'Estado'
  },
  modalStatePlaceholder: {
    zh: '输入州名',
    en: '',
    pu: 'Digite o estado'
  },
  modalComplement: {
    zh: '补充',
    en: '',
    pu: 'Complemento'
  },
  modalComplementPlaceholder: {
    zh: '输入补充内容',
    en: '',
    pu: 'Digite o complemento'
  },
  tip0327: {
    zh: '仅已闭环状态的才可修改',
    en: '',
    pu: 'Somente aqueles em um estado de circuito fechado podem ser modificados'
  },
  recipientCPF: {
    zh: '收件人CPF',
    en: 'Recipient CPF',
    pu: 'CPF do destinatário'
  },
  platform01: {
    zh: '平台:',
    en: 'Platform:',
    pu: 'Plataforma:' // 注：葡萄牙语缩写为 pt（巴西用 pt-BR）
  },
  closedLoopTime: {
    zh: '闭环时间',
    en: 'Closed-loop Time',
    pu: 'Tempo de Ciclo Fechado'
  }
};
