// 修改密码

const changepwd = {
  setNewPwd: {
    zh: '请设置新密码',
    en: '',
    pu: 'Favor definir nova senha'
  },
  oldPwd: {
    zh: '旧密码',
    en: '',
    pu: 'Senha antiga'
  },
  inputOldPwd: {
    zh: '请输入旧密码',
    en: '',
    pu: 'Favor digitar senha antiga'
  },
  newPwd: {
    zh: '新密码',
    en: '',
    pu: 'Senha nova'
  },
  inputNewPwd: {
    zh: '请输入新密码',
    en: '',
    pu: 'Favor senha nova'
  },
  inputHint: {
    zh: '必须由 8-12 位小写或大写字母、数字组成',
    en: '',
    pu: 'Deve consistir de 8 a 12 letras minúsculas ou maiúsculas e números'
  },
  checkPwd: {
    zh: '确认密码',
    en: '',
    pu: 'Confirmar senha'
  },
  inputNewCodeAgain: {
    zh: '请再次输入新密码',
    en: '',
    pu: 'Por favor digite novamente a nova senha'
  },
  requestedInputCode: {
    zh: '请按照要求输入密码',
    en: '',
    pu: 'Por favor digitar a senha conforme requerido'
  },
  newCodeNotOldCode: {
    zh: '新密码与旧密码不能相同',
    en: '',
    pu: 'A nova senha não pode ser a mesma que a antiga'
  },
  inputConfirmCode: {
    zh: '请输入确认密码',
    en: '',
    pu: 'Introduza a senha de confirmação, por favor'
  },
  loginAgain: {
    zh: '密码修改成功，请重新登录',
    en: '',
    pu: 'Senha alterada com sucesso, por favor faça o login novamente'
  },
  succeed: {
    zh: '成功',
    en: 'succeed',
    pu: 'suceder'
  },
  confirm: {
    zh: '确认',
    en: '',
    pu: 'Confirmar'
  },
  reset: {
    zh: '重置',
    en: 'reset',
    pu: 'Reconfigurar'
  },
  orderQuantity: {
    zh: '下单票数',
    en: '',
    pu: 'Qtd. de Pedidos Realizados'
  },
  customerSendQuantity: {
    zh: '客户出仓票数',
    en: '',
    pu: 'Qtd. de Pacotes Expedidos de Cliente'
  },
  collectQuantity: {
    zh: '揽收票数',
    en: 'reset',
    pu: 'Qtd. de Pacotes Coletados'
  },
  collectDifference: {
    zh: '揽收差异',
    en: '',
    pu: 'Diferença de Coleta'
  },
  collectMissScan: {
    zh: '疑似揽收漏扫',
    en: '',
    pu: 'Possível Faltou de Bipagem na Coleta'
  },
  collectLost: {
    zh: '疑似揽收丢失',
    en: '',
    pu: 'Possível Perda na Coleta'
  },
  collectLoadQuantity: {
    zh: '揽收装车票数',
    en: '',
    pu: 'Qtd. de Pacotes Carregados'
  },
  collectLoadDifference: {
    zh: '揽收装车差异',
    en: '',
    pu: 'Diferença no Carregamento de Coleta'
  },
  loadMissScan: {
    zh: '疑似装车漏扫',
    en: '',
    pu: 'Possível Faltou de Bipagem no Carregamento'
  },
  loadLost: {
    zh: '疑似揽收装车丢失',
    en: '',
    pu: 'Possível Perda no Carregamento de Coleta'
  },
  collectUserName: {
    zh: '揽收操作人',
    en: '',
    pu: 'Operador de Coleta'
  },
  collectLoadTime: {
    zh: '揽收装车时间',
    en: '',
    pu: 'Hora de Carregamento da Coleta'
  },
  collectLoadUserName: {
    zh: '揽收装车操作人',
    en: '',
    pu: 'Operador de Carregamento da Coleta'
  },
  UrgeToDo: {
    zh: '催办',
    en: '',
    pu: 'Exorto'
  },
  firstUnloadVehicleTime: {
    zh: 'HUB1卸车时间',
    en: '',
    pu: 'Horário de descarga no HUB1'
  },
  opeartData: {
    zh: '操作数据',
    en: '',
    pu: 'Operar dados'
  },
  slaNumber: {
    zh: '州/转运中心SLA达成',
    en: '',
    pu: 'Conclusão do SLA do estado/centro de transbordo'
  },
  staticAgingMonitoring: {
    zh: '州/转运中心滞留数据',
    en: '',
    pu: 'Dados de retração no estado/centro de transbordo'
  },
  networkError: {
    zh: '网络错误！请您稍后重试!',
    en: '',
    pu: 'Erro de Internet! Tente novamente mais tarde!'
  },
  networkError2: {
    zh: '您的网络已断开，请检查网络设置!',
    en: '',
    pu: 'Sua conexão à internet foi interrompida. Por favor, verifique as configurações da rede!'
  },
  serviceException: {
    zh: '服务异常,请稍后再试!',
    en: '',
    pu: 'Houve uma exceção de serviço. Por favor, tente novamente mais tarde'
  },
  collectReason: {
    zh: '问题件原因分类',
    en: '',
    pu: 'Classificação da causa da peça de problema'
  },
  collectLevel: {
    zh: '问题件层级',
    en: '',
    pu: 'Nível de pedidos problemáticos'
  },
  oneLeave: {
    zh: '一级',
    en: '',
    pu: 'Primeiro nível'
  },
  twoLeave: {
    zh: '二级',
    en: '',
    pu: 'Segundo nível'
  },
  categoryName: {
    zh: '问题件类型分类',
    en: '',
    pu: 'Classificação do tipo de pedido problemático'
  },
  numCode: {
    zh: '数字代码',
    en: '',
    pu: 'Código numérico'
  },
  parentCode: {
    zh: '上级问题件',
    en: '',
    pu: 'Pedido problemático superior'
  },
  problemCode: {
    zh: '问题件代码',
    en: '',
    pu: 'Código do pedido problemático'
  },
  sortValue: {
    zh: '排序值',
    en: '',
    pu: 'Valor de classificação'
  },
  problemReasonZH: {
    zh: '问题件原因（中文）',
    en: '',
    pu: 'Causa do pedido problemático (Chinês)'
  },
  problemReasonEN: {
    zh: '问题件原因（英语）',
    en: '',
    pu: 'Causa do pedido problemático (Inglês)'
  },
  problemReasonPU: {
    zh: '问题件原因（葡语）',
    en: '',
    pu: 'Causa do pedido problemático (Português)'
  },
  problemReasonEa: {
    zh: '问题件原因（西语）',
    en: '',
    pu: 'Causa do pedido problemático (Espanhol)'
  },
  addProblem: {
    zh: '新增问题件',
    en: '',
    pu: 'Adicionar pedido problemático'
  },
  editProblem: {
    zh: '编辑问题件',
    en: '',
    pu: 'Editar pedido problemático'
  },
  areYouSureProblemTip: {
    zh: '确认启用/停用该问题件吗？',
    en: '',
    pu: 'Confirma se deseja ativar/desativar esse pedido problemático?'
  },
  putongProblem: {
    zh: '普通问题件',
    en: '',
    pu: 'Pedido problemático comum'
  },
  teshuProblem: {
    zh: '特殊问题件',
    en: '',
    pu: 'Pedido problemático comum'
  },
  check999Number: {
    zh: '请输入0~999之间的数字',
    en: '',
    pu: 'Por favor, insira um número entre 0 e 999'
  },
  checkStrNumber: {
    zh: '请输入字母,数字,-或_',
    en: '',
    pu: 'Por favor, insira letras, números, o hífen (-) ou o sublinhado (_)'
  },
  selectResult: {
    zh: '请选择审核结果',
    en: '',
    pu: 'Por favor, selecione o resultado da revisão'
  },
  exportCrossBorderInvoice: {
    zh: '跨境发票信息导出',
    en: '',
    pu: 'Exportação da informação da fatura transfronteiriça'
  },
  dirNumber: {
    zh: 'DIR单号',
    en: '',
    pu: 'Número de localização DIR'
  },
  dirFile: {
    zh: 'DIR文件',
    en: '',
    pu: 'Ficheiro DIR'
  },
  ICMSFile: {
    zh: 'ICMS文件',
    en: '',
    pu: 'Ficheiro ICMS'
  },
  icmsBarcode: {
    zh: 'ICMS DARF(支付条形码)',
    en: '',
    pu: 'ICMS DARF (Código de Barras de Pagamento)'
  },
  payAuthCode: {
    zh: 'ICMS银行付款码',
    en: '',
    pu: 'Código de Pagamento Bancário ICMS'
  }
};
export { changepwd };
