<template>
  <div style="height: calc(100vh - 67px); overflow: auto;padding: 10px">
    <el-tabs v-model="activeName" type="border-card">
      <!--订单详情-->
      <el-tab-pane name="xq">
        <span slot="label"><i class="el-icon-s-grid" /> {{ $t('orderCenterCont.OrderDetails') }}</span>
        <DetailsCmp ref="DetailsCmp" v-bind="$attrs" />
      </el-tab-pane>
      <el-tab-pane name="gj">
        <span slot="label">{{ $t('orderCenterCont.TrajectoryQuery') }}</span>
        <Track v-bind="$attrs" />
      </el-tab-pane>
      <el-tab-pane name="pz">
        <!--支付凭证-->
        <span slot="label">{{ $t('home.PaymentCertificate') }}</span>
        <payment-voucher v-if="activeName === 'pz'" v-bind="$attrs" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import DetailsCmp from './showDetail';
import Track from './track';
import paymentVoucher from './paymentVoucher';

export default {
  name: 'Detail',
  props: {
    openIdx: {
      type: String
    }
  },
  components: {
    DetailsCmp,
    Track,
    paymentVoucher
  },
  data() {
    return {
      activeName: this.openIdx,
      isShow2: false,
      isShow3: false,
      isShow4: false
    };
  },
  methods: {}
};
</script>

<style scoped>
>>> .el-tabs{
  height: 100%;
  position: relative;
}
>>> .el-tabs__content{
  position: absolute;
  width: 100%;
  height: calc(100vh - 135px)
}
>>> .el-tab-pane{
  height: 100%;
}
</style>
