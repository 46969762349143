<!-- 查询组件 -->
<template>
  <div class="query-container">
    <el-form
      ref="form"
      class="form-container"
      :model="params"
      size="small"
      :label-position="$i18n.locale !== 'zh' ? 'top' : 'right'"
      :label-width="$i18n.locale !== 'zh' ? 'auto' : '160px'"
    >
      <!--主体-->
      <el-form-item :label="$t('receivablePayable.costumer')">
        <QueryProviderCmp :val="params.providerId" @change="val => params.providerId = val" />
      </el-form-item>

      <!-- 网点 -->
      <el-form-item :label="$t('receivablePayable.costOutlets')">
        <QuerySiteCmp
          :val="params.siteId"
          :provider-id="params.providerId"
          @change="val => params.siteId = val"
        />
      </el-form-item>

      <!-- 业务时间 -->
      <el-form-item :label="$t('basicData.BusinessTime')">
        <el-date-picker
          v-model="queryDate"
          style="width: 100%"
          type="datetimerange"
          :picker-options="dateLimit"
          :default-time="['00:00:00', '23:59:59']"
          :range-separator="$t('operationCenter.to')"
          :start-placeholder="$t('operationCenter.startDate')"
          :end-placeholder="$t('operationCenter.endDate')"
          value-format="yyyy-MM-dd HH:mm:ss"
        />
      </el-form-item>

      <!-- 业务单号 / 客户单号 -->
      <el-form-item>
        <template slot="label">
          <el-select
            v-model="params.numberField"
            :placeholder="$t('operationCenter.PleaseSelect')"
          >
            <el-option
              v-for="item in NUMBER_FIELD" :key="item.value" :label="item.label"
              :value="item.value"
            />
          </el-select>
        </template>
        <InputTextareaCmp
          ref="InputTextareaRef" :val="textVal" :max-length="20000"
          :autosize="{ minRows: 2, maxRows: 4}"
        />
      </el-form-item>

      <!-- 账单批次号 -->
      <el-form-item v-if="type === 1" :label="$t('receivablePayable.BillingBatchNumber')">
        <el-input v-model="params.billBatchNumber" :placeholder="$t('GlobalSetObj.pleaseInput')" :readonly="true" />
      </el-form-item>
      <!-- 付款单号 -->
      <el-form-item v-if="type === 2" :label="$t('receivablePayable.PaymentSlipNumber')">
        <el-input v-model="params.payableNumber" :placeholder="$t('GlobalSetObj.pleaseInput')" :readonly="true" />
      </el-form-item>

      <!-- 费用类型 -->
      <el-form-item :label="$t('basicData.ExpenseType')" prop="feeName">
        <FeeCodeCmp :val="params.feeCode" :account-type="2" @change="val => params.feeCode = val" />
      </el-form-item>

      <!--业务类型-->
      <el-form-item :label="$t('collectionCenter.businessT')">
        <el-select
          v-model="params.businessType"
          clearable
          :placeholder="$t('basicData.PleaseSelect')"
          style="width: 100%;"
        >
          <el-option
            v-for="item in BUSINESS_TYPE_TWO"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <!--揽收方式-->
      <el-form-item :label="$t('collectionCenter.lanshouTypee')">
        <el-select
          v-model="params.collectType"
          clearable
          :placeholder="$t('basicData.PleaseSelect')"
          style="width: 100%;"
        >
          <!--上门交件-->
          <el-option :key="1" :label="$t('collectionCenter.dropOff')" :value="1" />
          <!--上门揽收-->
          <el-option :key="2" :label="$t('collectionCenter.doorPickUp')" :value="2" />
        </el-select>
      </el-form-item>
    </el-form>

    <div class="form-footer">
      <!--查 询-->
      <el-button
        type="primary"
        size="small"
        @click="onSearch"
      >{{ $t('basicData.Search') }}</el-button>
      <!--重 置-->
      <el-button
        type="success"
        size="small"
        @click="onReset"
      >{{ $t('basicData.reset') }}</el-button>
      <!--取 消-->
      <el-button
        size="small"
        @click="onClose"
      >{{ $t('basicData.Cancel') }}</el-button>
    </div>
  </div>
</template>

<script>
import QueryProviderCmp from '@/views/finance/cmp/Query/QueryProviderCmp.vue';
import QuerySiteCmp from '@/views/finance/cmp/Query/QuerySiteCmp.vue';
import InputTextareaCmp from '@/views/finance/cmp/Query/InputTextareaCmp.vue';
import FeeCodeCmp from '@/views/finance/payableManage/feesPayable/component/FeeCodeCmp.vue';
import { cloneDeep } from 'lodash-es';
import { beforeSearch } from '@/views/finance/payableManage/feesPayable/enums.js';
import { SEARCH_PARAMS, NUMBER_FIELD } from '@/views/finance/costManagement/components/enums.js';
import { BUSINESS_TYPE_TWO } from '@/views/finance/utils.js';

export default {
  name: 'SearchForm',

  components: {
    QueryProviderCmp,
    QuerySiteCmp,
    InputTextareaCmp,
    FeeCodeCmp
  },

  props: {
    billBatchNumber: {
      type: String,
      default: ''
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    type: {
      type: [Number, String],
      default: ''
    }
  },

  data() {
    return {
      BUSINESS_TYPE_TWO,
      NUMBER_FIELD,
      params: {
        ...SEARCH_PARAMS
      },
      queryDate: [], // 业务时间
      textVal: '', // 输入框内容
      // 日期限制
      pickerMinDate: '',
      pickerRange: 30 * 24 * 60 * 60 * 1000, // 可选择日期范围 为30天
      loadUserList: []
    };
  },

  computed: {
    /**
     * 日期限制处理
     *    默认情况下限制30天
     *    如果输入了 业务单号 或 客户单号 或 批次号，则不限制时间
     */
    dateLimit() {
      const { textVal, pickerMinDate, pickerRange } = this;
      const { billBatchNumber } = this.params;

      // 默认限制30天
      return {
        // 设置不能选择的日期
        onPick: ({ minDate }) => {
          // 输入了内容
          if (textVal || billBatchNumber) {
            // 取消限制
            this.pickerMinDate = null;
            return;
          }

          if (minDate && pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          // 输入了内容
          if (textVal || billBatchNumber) {
            // 取消时间限制
            return false;
          }

          if (this.pickerMinDate) {
            return (time.getTime() > (pickerMinDate + pickerRange)) || (time.getTime() < (pickerMinDate - pickerRange));
          }
          return false;
        }
      };
    }
  },

  created() {
    this.resetBillAndPayableNumbers();
  },

  methods: {
    resetBillAndPayableNumbers() {
      if (this.type === 1) {
        this.params.billBatchNumber = this.billBatchNumber;
        this.params.payableNumber = '';
      } else {
        this.params.payableNumber = this.billBatchNumber;
        this.params.billBatchNumber = '';
      }
    },
    // 重置
    onReset() {
      this.params = { ...SEARCH_PARAMS };
      this.queryDate = [];
      this.textVal = '';
      this.resetBillAndPayableNumbers();
      this.$refs.InputTextareaRef.clear();
    },

    // 搜索
    onSearch() {
      const obj = cloneDeep(this.params);
      // 业务时间
      obj.startTime = this.queryDate?.length > 0 ? this.queryDate[0] : '';
      obj.endTime = this.queryDate?.length > 0 ? this.queryDate[1] : '';
      // 单号
      obj.numberList = this.$refs.InputTextareaRef.getValue();

      if (!this.isReadOnly) {
        // 校验时间和单号二选一
        // eslint-disable-next-line no-lone-blocks
        {
          const isBool = beforeSearch(obj);
          if (!isBool) {
            this.$message.warning(this.$t('receivablePayable.BusinessTimeOrOrderNumberCannotBeEmpty'));
            return;
          }
        }
      }

      this.$emit('search', obj);
    },

    // 取消
    onClose() {
      this.$emit('close');
    }
  }

};
</script>

<style scoped lang="scss">
.form-footer {
  text-align: center;
}
</style>
