import newRequest from '@/utils/new-request';

// 文件上传列表分页
export const apiFaFinancialImportExcelLogPage = data => {
  return newRequest({
    method: 'POST',
    url: '/faFinancialImportExcelLog/page',
    data,
    isLoading: true
  });
};
