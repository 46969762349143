<template>
  <el-card class="box-card">
    <div style="margin-bottom: 15px; font-weight: bold">网点滞留数据</div>
    <!--  表格数据  -->
    <el-table
      :data="stayTrackingVOS"
      :header-cell-style="{ background: '#f2f2f2', color: 'black' }"
      height="375px"
      stripe
      style="width: 100%"
    >
      <el-table-column :label="$t('navbar.siteName')" align="center" prop="siteName" />
      <el-table-column align="center" label="24H滞留" prop="twentyFourHours">
        <template #default="scope">
          <div v-if="scope.row.siteId === null" class="fontClsBold">
            {{ scope.row.twentyFourHours }}
          </div>
          <div v-else>{{ scope.row.twentyFourHours }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="48H滞留" prop="fortyEightHours">
        <template #default="scope">
          <div v-if="scope.row.siteId === null" class="fontClsBold">
            {{ scope.row.fortyEightHours }}
          </div>
          <div v-else>{{ scope.row.fortyEightHours }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="72H滞留" prop="seventyTwoHours">
        <template #default="scope">
          <div v-if="scope.row.siteId === null" class="fontClsBold">
            {{ scope.row.seventyTwoHours }}
          </div>
          <div v-else>{{ scope.row.seventyTwoHours }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="7天滞留" prop="sevenDays">
        <template #default="scope">
          <div v-if="scope.row.siteId === null" class="fontClsBold">
            {{ scope.row.sevenDays }}
          </div>
          <div v-else>{{ scope.row.sevenDays }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="15天滞留" prop="fifteenDays">
        <template #default="scope">
          <div v-if="scope.row.siteId === null" class="fontClsBold">
            {{ scope.row.fifteenDays }}
          </div>
          <div v-else>{{ scope.row.fifteenDays }}</div>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  name: 'NetworkRetentionData',
  props: {
    stayTrackingVOS: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  methods: {}
};
</script>

<style scoped></style>
