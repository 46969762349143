<template>
  <div class="icms-content">
    <el-card class="mb5">
      <el-button size="small" @click="clickEdit()">{{ $t('GlobalSetObj.edit') }}</el-button>
      <el-button
        :disabled="isSave === false" size="small" type="primary"
        @click="saveForm()"
      >{{ $t('basicData.Save') }}</el-button>
      <span style="margin-left: 20px;">
        {{ $t('basicData.LastUpdated') }}：{{ updateTime }}，{{ $t('basicData.LastUpdatedBy') }}：{{ updateUser }}
      </span>
    </el-card>

    <el-card>
      <el-form
        ref="tableFormEdit"
        :model="formData"
        label-width="0"
      >
        <el-table
          border
          :data="formData.tableData"
          :height="tableHeight"
        >
          <el-table-column
            v-for="(item, index) in currentColumns"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :fixed="item.fixed"
            align="center"
            min-width="140"
          >
            <template v-if="index === 0" #header>
              <div class="diagonal-header">
                <div class="title-right">{{ $t('collectionCenter.receivingState') }}</div>
                <div class="title-left">{{ $t('collectionCenter.stateOrigin') }}</div>
              </div>
            </template>
            <template slot-scope="scope">
              <div v-if="item.prop === 'sendTwoCode'">
                {{ scope.row.sendTwoCode }}
              </div>
              <div v-else>
                <el-form-item
                  :prop="'tableData.' + scope.$index + '.' + item.prop"
                  :rules="{ required: false, suffix: 2, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
                >
                  <span v-if="scope.row.edit === false">
                    {{ scope.row[item.prop] }}
                  </span>
                  <el-input
                    v-else v-model="scope.row[item.prop]" size="small"
                    style="width: 100px;" @change="scope.row.update = true"
                  />
                </el-form-item>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { apiGetTaxationEdit, apiGetTaxationReferList } from '@/api/finance/basicData';
import { getUserName } from '@/utils/asyncTools';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import resizeHeight from '@/views/finance/mixins/resizeHeight';

import dayjs from 'dayjs';

export default {
  name: 'Icms',
  mixins: [resizeHeight],
  data() {
    // 正负保留小数点
    const priceType = (rule, value, callback) => {
      const reg = new RegExp('(^' + rule.negative + '[1-9]\\d*(\\.\\d{1,' + rule.suffix + '})?$)|(^' + rule.negative + '0(\\.\\d{1,' + rule.suffix + '})?$)');
      const { required } = rule;
      if (!required) {
        // 非必填
        if (value !== null && value !== '' && value !== undefined) {
          if (!reg.test(value)) {
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // '格式不正确'
          } else {
            if (rule.package !== null && rule.package !== '' && rule.package !== undefined) {
              if (rule.negative1 === 2) {
                if (parseFloat(value) <= parseFloat(rule.package)) {
                  return callback(new Error(this.$i18n.t('basicData.MustGreaterThanStartingValue'))); // 必须大于起始值
                } else if (parseFloat(value) <= 0) {
                  return callback(new Error(this.$i18n.t('basicData.MustBeGreaterThan0'))); // 必须大于0
                }
              } else {
                if (parseFloat(value) >= parseFloat(rule.package)) {
                  return callback(new Error(this.$i18n.t('basicData.MustLessThanEndValue'))); // 必须小于结束值
                } else {
                  return callback();
                }
              }
            }
            return callback();
          }
        } else {
          return callback();
        }
      } else {
        if (value !== null && value !== '' && value !== undefined) {
          if (!reg.test(value)) {
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // '格式不正确'
          } else {
            if (rule.negative1 === 2) {
              if (parseFloat(value) <= parseFloat(rule.package)) {
                return callback(new Error(this.$i18n.t('basicData.MustGreaterThanStartingValue'))); // 必须大于起始值
              } else if (parseFloat(value) <= 0) {
                return callback(new Error(this.$i18n.t('basicData.MustBeGreaterThan0'))); // 必须大于0
              }
            } else {
              if (parseFloat(value) >= parseFloat(rule.package)) {
                return callback(new Error(this.$i18n.t('basicData.MustLessThanEndValue'))); // 必须小于结束值
              } else {
                return callback();
              }
            }
            return callback();
          }
        } else {
          return callback(this.$i18n.t('basicData.CannotBeEmpty')); // '不能为空'
        }
      }
    };
    return {
      updateTime: '',
      updateUser: '',
      currentColumns: [],
      formData: {
        tableData: []
      },
      isSave: false,
      priceType: priceType,
      userIdAndNameObj: {}
    };
  },
  computed: {
    tableHeight() {
      return (this.windowHeight - 267);
    }
  },
  created() {
    this.getTableDataColumns();
    // 用户列表
    this.userIdAndNameObj = getUserIdAndNameObj();
  },
  methods: {
    // 获取表格数据
    getTableDataColumns() {
      apiGetTaxationReferList({}).then(res => {
        console.log(res);
        if (res.code === 200) {
          this.currentColumns = res.data.columns;
          this.currentColumns.map(item => {
            if (item.prop === 'sendTwoCode') {
              item.fixed = 'left';
            }
          });
          this.updateTime = dayjs(res.data.updateTime).format('MM-DD-YYYY HH:mm');
          this.updateUser = getUserName(res.data.updateUser, this.userIdAndNameObj);
          this.formData.tableData = res.data.data.map(item => {
            item.edit = false;
            item.update = false;
            return item;
          });
        }
      });
    },
    // 编辑
    clickEdit() {
      this.formData.tableData.map(item => {
        item.edit = !item.edit;
      });
      this.isSave = !this.isSave;
    },
    // 保存
    saveForm() {
      this.$refs['tableFormEdit'].validate((valid) => {
        if (valid) {
          const formData = [];
          this.formData.tableData.map((item) => {
            if (item.update === true) {
              for (const key in item) {
                if (key !== 'sendTwoCode' && key !== 'edit' && key !== 'update') {
                  const obj = {
                    sendTwoCode: '',
                    receivingTwoCode: '',
                    taxation: ''
                  };
                  obj.sendTwoCode = item.sendTwoCode;
                  obj.receivingTwoCode = key;
                  obj.taxation = item[key];
                  formData.push(obj);
                }
              }
            }
          });
          apiGetTaxationEdit(formData).then(res => {
            if (res.code === 200) {
              this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
              this.getTableDataColumns();
              this.isSave = false;
            }
          });
        } else {
          this.$message.warning(this.$t('basicData.TheFormatContentIncorrect'));
          return false;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.icms-content {
  height: calc(100vh - 146px);
  overflow-y: auto;

  ::v-deep .el-form-item {
    margin-bottom: 0;
  }

  ::v-deep .el-table__header .el-table__cell:first-child,
  ::v-deep .el-table__header .el-table__cell:first-child .cell {
    padding: 0 !important;
  }
}

.diagonal-header {
  position: relative;
  width: 100%;
  height: 48px;  /* 固定高度 */
  min-width: 140px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 140%;
    height: 1px;
    background: #dde1e4;
    transform: rotate(19deg) translateY(-50%);
    transform-origin: 0 0;
  }

  .title-right {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 14px;
    z-index: 2;
  }

  .title-left {
    position: absolute;
    bottom: 0;
    left: 10px;
    font-size: 14px;
    z-index: 2;
  }
}
</style>
