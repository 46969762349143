<!-- 费用详情 -->
<template>
  <el-drawer
    :append-to-body="true"
    :title="$t('receivablePayable.FeeDetails')"
    :visible.sync="isVisible"
    custom-class="drawerClass"
    direction="ltr"
    size="80%"
  >
    <div class="details">
      <!-- 基本信息 -->
      <BasicForm :add-batch-fee="addBatchFee" :payable-type="payableType" />

      <!-- 费用明细 -->
      <FeeTable
        ref="detailsTableRef"
        :add-type="2"
        :fee-type-option="feeTypeOption"
        :query-form="queryForm"
        :is-allow-edit="addBatchFee.allowEdit"
        :list="addBatchFeeList"
      />

      <!-- 按钮 --><!---->
      <div class="btn">
        <!--保存 allowEdit true可编辑 false查看-->
        <el-button
          v-if="addBatchFee.allowEdit"
          v-permit:remove="permission" type="primary" size="small"
          @click="submit"
        >{{ $t('basicData.Save') }}</el-button>
        <!--关闭-->
        <el-button size="small" @click="onClose">{{ $t('orderCenterCont.ShutDown') }}</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import BasicForm from './basicForm.vue';
import FeeTable from './feeTable.vue';
import {
  apiPayableSiteExpenseAddFee,
  apiPayableSiteExpenseGetInfo,
  apiPayableTransportSiteExpenseAddFee,
  apiPayableTransportSiteExpenseGetInfo
} from '@/api/finance/receivables';
import { FEE_SOURCE } from '@/views/finance/payableManage/feesPayable/enums.js';
import { getPermission } from './utils.js';

export default {
  name: '',

  components: {
    BasicForm,
    FeeTable
  },

  props: {
    queryFeeParam: {
      type: Object,
      default: () => {
        return {};
      }
    },

    // 费用类型
    feeTypeOption: {
      type: Array,
      default: () => {
        return [];
      }
    },

    // 外部搜索条件
    queryForm: {
      type: Object,
      default: () => {
        return {};
      }
    },

    // 勾选
    selectData: {
      type: Array,
      default: () => {
        return [];
      }
    },

    // 账单类型
    payableType: {
      type: Number,
      required: true
    }

    // 是否打开了添加弹窗
    // isOpen: {
    //   type: Boolean,
    //   default: false
    // }
  },

  data() {
    return {
      isVisible: false,
      key: 1,

      addBatchFee: {
        allowEdit: true
      }, // 费用详情基本信息
      addBatchFeeList: [], // 费用详情
      permission: '' // 权限
    };
  },

  activated() {
    this.permission = getPermission(this.payableType);
  },
  created() {
    this.permission = getPermission(this.payableType);
  },

  methods: {
    // 父元素调用
    open() {
      this.isVisible = true;
      this.addBatchFeeList = [];
      setTimeout(() => {
        this.getDetails();
      }, 100);
    },

    // 费用详情
    getDetails() {
      const param = {
        waybillNumber: this.queryFeeParam.waybillNumber,
        businessType: this.queryFeeParam.businessType,
        payableType: this.queryFeeParam.payableType,
        siteId: this.queryFeeParam.siteId
      };

      const api = this.queryFeeParam.payableType === 5 ? apiPayableTransportSiteExpenseGetInfo : apiPayableSiteExpenseGetInfo;
      api(param).then(res => {
        if (res.code === 200) {
          res.data['inputTime'] = res.data['inputTime'] ? res.data['inputTime'].replace('T', ' ') : '';
          res.data.expenseSubList.map(item => {
            if (item.inputTime) {
              item.inputTime = item.inputTime.replace('T', ' ');
            }
            if (item.operateTime) {
              item.operateTime = item.operateTime.replace('T', ' ');
            }
            item.chargeWeight = res.data.chargeWeight;
            item.isEdit = false;
          });

          const arr = (res.data || []).expenseSubList.map(item => {
            const feeSourceStr = FEE_SOURCE[item.feeSource]?.name || '';
            return {
              ...item,
              feeSourceStr
            };
          });

          this.$set(this, 'addBatchFee', res.data);
          this.addBatchFeeList = arr;
        }
      });
      // if (this.queryFeeParam.payableType === 5) {
      //   apiPayableTransportSiteExpenseGetInfo(param).then(res => {
      //     if (res.code === 200) {
      //       res.data['inputTime'] = res.data['inputTime'] ? res.data['inputTime'].replace('T', ' ') : '';
      //       res.data.expenseSubList.map(item => {
      //         if (item.inputTime) {
      //           item.inputTime = item.inputTime.replace('T', ' ');
      //         }
      //         if (item.operateTime) {
      //           item.operateTime = item.operateTime.replace('T', ' ');
      //         }
      //         item.chargeWeight = res.data.chargeWeight;
      //         item.isEdit = false;
      //       });
      //
      //       const arr = (res.data || []).expenseSubList.map(item => {
      //         const feeSourceStr = FEE_SOURCE[item.feeSource]?.name || '';
      //         return {
      //           ...item,
      //           feeSourceStr
      //         };
      //       });
      //
      //       this.$set(this, 'addBatchFee', res.data);
      //       this.addBatchFeeList = arr;
      //     }
      //   });
      // } else {
      //   apiPayableSiteExpenseGetInfo(param).then(res => {
      //     if (res.code === 200) {
      //       res.data['inputTime'] = res.data['inputTime'] ? res.data['inputTime'].replace('T', ' ') : '';
      //       res.data.expenseSubList.map(item => {
      //         if (item.inputTime) {
      //           item.inputTime = item.inputTime.replace('T', ' ');
      //         }
      //         if (item.operateTime) {
      //           item.operateTime = item.operateTime.replace('T', ' ');
      //         }
      //         item.chargeWeight = res.data.chargeWeight;
      //         item.isEdit = false;
      //       });
      //
      //       const arr = (res.data || []).expenseSubList.map(item => {
      //         const feeSourceStr = FEE_SOURCE[item.feeSource]?.name || '';
      //         return {
      //           ...item,
      //           feeSourceStr
      //         };
      //       });
      //
      //       this.$set(this, 'addBatchFee', res.data);
      //       this.addBatchFeeList = arr;
      //     }
      //   });
      // }
    },

    // 提交
    submit() {
      const list = this.$refs.detailsTableRef.submitEdit();
      if (list.length === 0) {
        this.$message.warning(this.$i18n.t('receivablePayable.AddAtLeastOneCharge'));
        return;
      }
      const param = {
        waybillNumber: this.queryFeeParam.waybillNumber,
        expenseSubList: list,
        businessType: this.queryFeeParam.businessType,
        payableType: this.queryFeeParam.payableType
      };
      const fn = this.queryFeeParam.payableType === 5 ? apiPayableTransportSiteExpenseAddFee : apiPayableSiteExpenseAddFee;
      fn(param).then(res => {
        if (res.code !== 200) return;
        this.$message.success(this.$i18n.t('GlobalSetObj.SaveSuccessfully')); // 保存成功
        this.isVisible = false;
        this.$emit('close', true);
      }).catch(err => {
        console.error(err);
      });
    },

    onClose(isBool = false) {
      this.isVisible = false;
      this.$emit('close', isBool);
    }
  }
};
</script>

<style scoped>
.details {
  padding: 10px;
  width: 100%;
  height: calc(100vh - 80px);
  overflow-y: scroll;
}

.btn {
  position: sticky;
  left: 0;
  bottom: -10px;
  background-color: #fff;
  width: 100%;
  z-index: 10000;
  border-top: 1px solid #EBEEF5;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
