var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "query-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form-container",
          attrs: {
            model: _vm.params,
            size: "small",
            "label-position": _vm.$i18n.locale !== "zh" ? "top" : "right",
            "label-width": _vm.$i18n.locale !== "zh" ? "auto" : "160px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("basicData.PayablePrincipal") } },
            [
              _c("QueryProviderCmp", {
                attrs: { val: _vm.params.providerId },
                on: {
                  change: function (val) {
                    return (_vm.params.providerId = val)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("basicData.Outlets") } },
            [
              _c("QuerySiteCmp", {
                attrs: {
                  val: _vm.params.siteId,
                  "provider-id": _vm.params.providerId,
                },
                on: {
                  change: function (val) {
                    return (_vm.params.siteId = val)
                  },
                },
              }),
            ],
            1
          ),
          _vm.payableType !== 5
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("basicData.CustomerName") } },
                  [
                    _c("QueryCustomerCmp", {
                      attrs: { val: _vm.params.customerId },
                      on: {
                        change: function (val) {
                          return (_vm.params.customerId = val)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("basicData.CustomerCode") } },
                  [
                    _c("QueryCustomerCodeCmp", {
                      attrs: { val: _vm.params.customerCodeId },
                      on: {
                        change: function (val) {
                          return (_vm.params.customerCodeId = val)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("basicData.BusinessTime") } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  type: "datetimerange",
                  "picker-options": _vm.dateLimit,
                  "default-time": ["00:00:00", "23:59:59"],
                  "range-separator": _vm.$t("operationCenter.to"),
                  "start-placeholder": _vm.$t("operationCenter.startDate"),
                  "end-placeholder": _vm.$t("operationCenter.endDate"),
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                model: {
                  value: _vm.queryDate,
                  callback: function ($$v) {
                    _vm.queryDate = $$v
                  },
                  expression: "queryDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "template",
                { slot: "label" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("operationCenter.PleaseSelect"),
                      },
                      model: {
                        value: _vm.params.numberField,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "numberField", $$v)
                        },
                        expression: "params.numberField",
                      },
                    },
                    _vm._l(_vm.ORDER_TYPE, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("InputTextareaCmp", {
                ref: "InputTextareaRef",
                attrs: {
                  val: _vm.textVal,
                  "max-length": 20000,
                  autosize: { minRows: 2, maxRows: 4 },
                },
              }),
            ],
            2
          ),
          [1, 2].includes(_vm.payableType)
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      _vm.payableType === 2
                        ? _vm.$t("GlobalSetObj.driver")
                        : _vm.$t("basicData.揽收操作人"),
                  },
                },
                [
                  _c("CollectorsCmp", {
                    on: {
                      change: function (val) {
                        return (_vm.params.collectorIdList = val)
                      },
                    },
                    model: {
                      value: _vm.params.collectorIdList,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "collectorIdList", $$v)
                      },
                      expression: "params.collectorIdList",
                    },
                  }),
                ],
                1
              )
            : [3, 4].includes(_vm.payableType)
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("basicData.派送操作人") } },
                [
                  _c("SendCmp", {
                    attrs: { "payable-type": _vm.payableType },
                    on: {
                      change: function (val) {
                        return (_vm.params.dispatcherList = val)
                      },
                    },
                    model: {
                      value: _vm.params.dispatcherList,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "dispatcherList", $$v)
                      },
                      expression: "params.dispatcherList",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.selectKey === 1
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("basicData.BatchNo") } },
                [
                  _c("el-input", {
                    attrs: { readonly: _vm.isReadOnly },
                    model: {
                      value: _vm.params.billBatchNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "billBatchNumber", $$v)
                      },
                      expression: "params.billBatchNumber",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.selectKey === 2
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("receivablePayable.PaymentSlipNumber"),
                  },
                },
                [
                  _c("el-input", {
                    attrs: { readonly: _vm.isReadOnly },
                    model: {
                      value: _vm.params.payableNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "payableNumber", $$v)
                      },
                      expression: "params.payableNumber",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          [1, 2, 3, 4].includes(_vm.payableType)
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("receivablePayable.WhetherItHasBeenPaid"),
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: _vm.$t("operationCenter.PleaseSelect"),
                      },
                      model: {
                        value: _vm.params.isPaid,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "isPaid", $$v)
                        },
                        expression: "params.isPaid",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("GlobalSetObj.yes"), value: 1 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("GlobalSetObj.No"), value: 0 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          [1, 2, 3, 4].includes(_vm.payableType)
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t(
                      "receivablePayable.WhetherToGenerateAStatement"
                    ),
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: _vm.$t("operationCenter.PleaseSelect"),
                      },
                      model: {
                        value: _vm.params.isGenBill,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "isGenBill", $$v)
                        },
                        expression: "params.isGenBill",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("GlobalSetObj.yes"), value: 1 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("GlobalSetObj.No"), value: 0 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          [2].includes(_vm.payableType)
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("collectionCenter.businessT") } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$t("basicData.PleaseSelect"),
                      },
                      model: {
                        value: _vm.params.orderBusinessType,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "orderBusinessType", $$v)
                        },
                        expression: "params.orderBusinessType",
                      },
                    },
                    _vm._l(_vm.ORDER_BUSINESS_TYPE, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          [1, 2].includes(_vm.payableType)
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("collectionCenter.lanshouTypee") } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$t("basicData.PleaseSelect"),
                      },
                      model: {
                        value: _vm.params.collectType,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "collectType", $$v)
                        },
                        expression: "params.collectType",
                      },
                    },
                    _vm._l(_vm.COLLECT_TYPE, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("basicData.ExpenseType"),
                prop: "feeName",
              },
            },
            [
              _c("FeeCodeCmp", {
                attrs: {
                  val: _vm.params.feeCode,
                  "business-type": _vm.payableType,
                },
                on: {
                  change: function (val) {
                    return (_vm.params.feeCode = val)
                  },
                },
              }),
            ],
            1
          ),
          [1, 2, 3, 4].includes(_vm.payableType)
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("receivablePayable.orderStatus") } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: _vm.$t("operationCenter.PleaseSelect"),
                      },
                      model: {
                        value: _vm.params.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "status", $$v)
                        },
                        expression: "params.status",
                      },
                    },
                    _vm._l(_vm.ORDER_STATUS, function (item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.label, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          [1, 2].includes(_vm.payableType)
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.SenderState") } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                    },
                    model: {
                      value: _vm.params.senderState,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "senderState", $$v)
                      },
                      expression: "params.senderState",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          [3, 4].includes(_vm.payableType)
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newOrder.RecipientState") } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                    },
                    model: {
                      value: _vm.params.receiveState,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "receiveState", $$v)
                      },
                      expression: "params.receiveState",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          [5].includes(_vm.payableType)
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("GlobalSetObj.Carregador") } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          filterable: "",
                          clearable: "",
                          placeholder: _vm.$t("operationCenter.PleaseSelect"),
                        },
                        model: {
                          value: _vm.params.loadUserId,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "loadUserId", $$v)
                          },
                          expression: "params.loadUserId",
                        },
                      },
                      _vm._l(_vm.loadUserList, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: {
                            label: item.username + "（" + item.realName + "）",
                            value: item.id,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("GlobalSetObj.StartingStation") } },
                  [
                    _c("QuerySiteCmp", {
                      attrs: { val: _vm.params.startingSite, "site-status": 3 },
                      on: {
                        change: function (val) {
                          return (_vm.params.startingSite = val)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: { label: _vm.$t("GlobalSetObj.DestinationStation") },
                  },
                  [
                    _c("QuerySiteCmp", {
                      attrs: {
                        val: _vm.params.destinationSite,
                        "site-status": 3,
                      },
                      on: {
                        change: function (val) {
                          return (_vm.params.destinationSite = val)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("vehicle.modelLabel") } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          filterable: "",
                          clearable: "",
                          placeholder: _vm.$t("operationCenter.PleaseSelect"),
                        },
                        model: {
                          value: _vm.params.vehicleType,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "vehicleType", $$v)
                          },
                          expression: "params.vehicleType",
                        },
                      },
                      _vm._l(_vm.Transport_VEHICLE_MODEL, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          [2, 5].includes(_vm.payableType)
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("GlobalSetObj.NIV") } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                    },
                    model: {
                      value: _vm.params.tagNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "tagNumber", $$v)
                      },
                      expression: "params.tagNumber",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          [2].includes(_vm.payableType)
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("GlobalSetObj.licensePlateNumber") } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                    },
                    model: {
                      value: _vm.params.vehicleNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "vehicleNumber", $$v)
                      },
                      expression: "params.vehicleNumber",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          [2].includes(_vm.payableType)
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("placeOrder.CommercialOwner") } },
                [
                  _c("ComercianteCmp", {
                    attrs: { val: _vm.params.merchantId },
                    on: { change: _vm.onComercianteChange },
                  }),
                ],
                1
              )
            : _vm._e(),
          [5].includes(_vm.payableType)
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("basicData.typeOfRoute") } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: _vm.$t("operationCenter.PleaseSelect"),
                      },
                      model: {
                        value: _vm.params.routeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "routeType", $$v)
                        },
                        expression: "params.routeType",
                      },
                    },
                    _vm._l(_vm.LINE_TYPE, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "form-footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.onSearch },
            },
            [_vm._v(_vm._s(_vm.$t("basicData.Search")))]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "success", size: "small" },
              on: { click: _vm.onReset },
            },
            [_vm._v(_vm._s(_vm.$t("basicData.reset")))]
          ),
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.onClose } },
            [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }