<template>
  <div class="app-container">
    <el-card class="mb5">
      <div class="operational">
        <ActionCenter
          ref="operationalRef"
          :query-form="queryForm"
          :select-data="selectionList"
          @refresh="onRefresh"
        />

        <!-- 查询 -->
        <el-button
          type="primary" icon="el-icon-search" size="small"
          @click="isSearch = true"
        >{{ $t('receivablePayable.Search') }}</el-button>
      </div>
    </el-card>

    <el-card>
      <!-- 列表 -->
      <TableCmp
        ref="tableCmpRef"
        :query-form="queryForm"
        :table-header="TABLE_HEADER"
        @editFee="editFee"
        @selection-change="handleSelectionChange"
      />
    </el-card>

    <!-- 搜索弹出框 -->
    <el-drawer
      :append-to-body="true"
      :title="$t('receivablePayable.Search')"
      :visible.sync="isSearch"
      custom-class="drawerClass"
      direction="rtl"
      size="50%"
    >
      <QueryCmp @search="onSearch" @close="isSearch = false;" />
    </el-drawer>
  </div>
</template>

<script>
import ActionCenter from '@/views/finance/costManagement/costsFee/cmp/ActionCenter.vue';
import TableCmp from '@/views/finance/costManagement/components/TableCmp.vue';
import { beforeSearch } from '@/views/finance/payableManage/feesPayable/enums.js';
import { SEARCH_PARAMS, TABLE_HEADER } from '@/views/finance/costManagement/components/enums.js';

export default {
  name: 'CostsFee',

  components: {
    ActionCenter,
    TableCmp,
    QueryCmp: () => import('@/views/finance/costManagement/components/searchForm.vue')
  },

  data() {
    return {
      TABLE_HEADER,
      isSearch: false,
      // 搜索
      queryForm: {
        pageNumber: 1,
        pageSize: 100,
        params: {
          ...SEARCH_PARAMS
        }
      },
      selectionList: [] // 勾选
    };
  },

  created() {
  },

  methods: {
    // 搜索
    onSearch(queryForm) {
      this.isSearch = false;
      Object.assign(this.queryForm.params, { ...queryForm });
      this.onRefresh();
    },

    // 更新数据重新刷新列表
    onRefresh() {
      // 若查询条件为空，不刷新
      // 校验时间和单号二选一
      {
        const isBool = beforeSearch(this.queryForm.params);
        if (!isBool) {
          return;
        }
      }
      this.$refs.tableCmpRef.onSearch();
    },

    // 详情查看
    editFee(row) {
      const { feeSource } = row;
      // 快录生成
      if (feeSource === 3) {
        this.$refs.operationalRef.onQREditFee(row);
        return;
      }
      this.$refs.operationalRef.openNotQRDetail(row);
    },

    // 勾选
    handleSelectionChange(arr) {
      this.selectionList = arr;
    }
  }
};
</script>

<style scoped>
@import '../index.scss';
</style>

