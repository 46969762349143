import i18n from '@/lang';

// 付款状态
export const PAYABLE_STATUS = Object.freeze({
  0: {
    label: i18n.t('receivablePayable.PendingPayment1'), // 待核付
    value: 0
  },
  1: {
    label: i18n.t('receivablePayable.Paid1'), // 已核付
    value: 1
  },
  2: {
    label: i18n.t('receivablePayable.PartialPayment'), // 部分核付
    value: 2
  },
  3: {
    label: i18n.t('receivablePayable.付款中'), // 付款中
    value: 3
  },

  4: {
    label: i18n.t('receivablePayable.付款失败'), // 付款失败
    value: 4
  }
});

// 应付核付表头配置
export const PAYABLE_TABLE_COLUMNS = [
  // 付款单号、对账批次号
  { prop: 'payableNumber', label: i18n.t('receivablePayable.PaymentSlipNumber') + '/' + i18n.t('receivablePayable.ReconciliationBatchNumber'), width: 220, align: 'left' },
  // 账单时间
  { prop: 'billingTime', label: i18n.t('receivablePayable.BillingDate'), width: 290 },
  // 账单类型
  { prop: 'payableTypeStr', label: i18n.t('collectionCenter.TypeBill'), width: 200, align: 'center' },
  // 实际收款人
  { prop: 'actualRecipients', label: i18n.t('basicData.实际收款人'), width: 200, align: 'center' },
  // 付款状态
  { prop: 'payableStatusStr', label: i18n.t('receivablePayable.PaymentStatus'), width: 120, align: 'left' },
  // 是否请款
  { prop: 'requestPaymentStatus', label: i18n.t('receivablePayable.areYouRequestingPayment'), width: 120, align: 'left' },
  // 付款详细
  { prop: 'paymentDetails', label: i18n.t('receivablePayable.PaymentDetails'), width: 140, align: 'left' },
  // 付款主体名称
  { prop: 'providerName', label: i18n.t('receivablePayable.TheNameOfThePaymentEntity'), width: 160, align: 'left' },
  // 网点名称
  { prop: 'siteName', label: i18n.t('basicData.siteName'), width: 160, align: 'left' },
  // 业务类型
  { prop: 'businessType', label: i18n.t('collectionCenter.businessT'), width: 100, align: 'left' },
  // 订单数量
  { prop: 'orderQty', label: i18n.t('receivablePayable.TheNumberOfOrders'), width: 100, align: 'left' },
  // 费用条数
  { prop: 'feeQty', label: i18n.t('receivablePayable.TheNumberOfFees'), width: 100, align: 'left' },
  // 计费总重量（kg）
  { prop: 'chargeWeight', label: i18n.t('receivablePayable.TotalBillableWeight'), width: 140, align: 'left' },
  // 票单金额
  { prop: 'billFee', label: i18n.t('receivablePayable.TicketAmount'), width: 140, align: 'left' },
  // 实际收款金额
  { prop: 'actualFee', label: i18n.t('receivablePayable.TheActualAmountReceived'), width: 140, align: 'left' },
  // 核付人
  { prop: 'acceptUserName', label: i18n.t('receivablePayable.Redeemer'), width: 100, align: 'left' },
  // 核付时间
  { prop: 'acceptOperateTime', label: i18n.t('receivablePayable.TimeOfPayment'), width: 160, align: 'left' },
  // 交易时间
  { prop: 'acceptTime', label: i18n.t('basicData.TradingHours'), width: 160, align: 'left' },
  // 生成人员
  { prop: 'createUserName', label: i18n.t('receivablePayable.BuildThePerson'), width: 100, align: 'left' },
  // 生成时间
  { prop: 'createTime', label: i18n.t('receivablePayable.BuildTime'), width: 160, align: 'left' },
  // 审核人员
  { prop: 'auditUserName', label: i18n.t('receivablePayable.Auditors'), width: 100, align: 'left' },
  // 审核时间
  { prop: 'auditTime', label: i18n.t('receivablePayable.ReviewTime'), width: 160, align: 'left' },
  // 邮件发送状态
  { prop: 'emailSendingStatus', label: i18n.t('receivablePayable.MailSendingStatus'), width: 110, align: 'left' },
  // 接收账单邮件
  { prop: 'emailAcceptance', label: i18n.t('receivablePayable.ReceiveBillingMail'), width: 170, align: 'left' },
  // 发送人
  { prop: 'emailSenderIdStr', label: i18n.t('receivablePayable.Sender'), width: 100, align: 'left' },
  // 发送时间
  { prop: 'emailSendingTime', label: i18n.t('collectionCenter.SendingTime'), width: 160, align: 'left' },
  // 文件
  { prop: 'emailSenderFileUrl', label: i18n.t('Resource.file'), width: 160, align: 'left' },
  // 备注
  { prop: 'remark', label: i18n.t('receivablePayable.remark'), width: 160, align: 'left' }
];

// 应付核付详情表头配置
export const PAYABLE_DETAIL_TABLE_COLUMNS = [
  // 业务单号
  { prop: 'waybillNumber', label: i18n.t('receivablePayable.TicketNumber'), width: 180, fixed: 'left' },
  // 条形码
  { prop: 'orderNumber', label: i18n.t('collectionCenter.orderNumber'), width: 180, fixed: 'left' },
  // 应付主体
  { prop: 'providerName', label: i18n.t('basicData.PayablePrincipal'), width: 180, fixed: 'left' },
  // 应付网点
  { prop: 'siteName', label: i18n.t('basicData.PayableOutlets'), width: 180, fixed: 'left' },
  // 所属分区
  { prop: 'partitionName', label: i18n.t('basicData.PartitionName'), width: 160, fixed: 'left' },
  // 收件人城市
  { prop: 'receiveCity', label: i18n.t('collectionCenter.receiveCity'), width: 160, fixed: 'left' },
  // 收件人邮编
  { prop: 'receiveZipcode', label: i18n.t('collectionCenter.Recipientzc'), width: 160, fixed: 'left' },
  // 应付费用
  { prop: 'totalFee', label: i18n.t('basicData.FeesPayable'), width: 180, fixed: 'left' },
  // 计费重量
  { prop: 'chargeWeight', label: i18n.t('receivablePayable.BillableWeight'), width: 180, fixed: 'left' },
  // 订单状态
  { prop: 'status', label: i18n.t('collectionCenter.OrderStatus'), width: '', fixed: 'left' },
  // 客户名称
  { prop: 'customerAlias', label: i18n.t('basicData.CustomerName'), width: 140, fixed: 'left' },
  // 客户编号
  { prop: 'customerId', label: i18n.t('basicData.CustomerCode'), width: 140, fixed: 'left' },
  // 收件人州
  { prop: 'receiveState', label: i18n.t('newOrder.RecipientState'), width: '', fixed: 'left' },
  // 发件人州
  { prop: 'senderState', label: i18n.t('newOrder.SenderState'), width: '', fixed: 'left' },
  // 业务类型
  { prop: 'businessType', label: i18n.t('collectionCenter.businessT'), width: 180, fixed: 'left' },
  // 揽收方式
  { prop: 'collectType', label: i18n.t('collectionCenter.lanshouTypee'), width: 180, fixed: 'left' },
  // 揽收人
  { prop: 'collectorsName', label: i18n.t('basicData.揽收操作人'), width: 180, fixed: 'left' },
  // 派送人
  { prop: 'dispatcherName', label: i18n.t('basicData.派送操作人'), width: 180, fixed: 'left' },
  // 备注
  { prop: 'remark', label: i18n.t('basicData.remark'), width: 180, fixed: 'left' },
  // 车签号
  { prop: 'tagNumber', label: i18n.t('GlobalSetObj.NIV'), width: 160, fixed: 'left' },
  // 车牌号
  { prop: 'vehicleNumber', label: i18n.t('GlobalSetObj.licensePlateNumber'), width: 160, fixed: 'left' },
  // 商家号(商户名称)
  { prop: 'merchantCode', label: `${i18n.t('collectionCenter.MerchantNum')}(${i18n.t('GlobalSetObj.businessName')})`, width: 160, fixed: 'left' },
  // 结算类型
  { prop: 'settlementType', label: i18n.t('receivablePayable.settlementType'), width: 160, fixed: 'left' },
  // 派送车辆类型
  { prop: 'deliveryVehicleType', label: i18n.t('receivablePayable.deliveryVehicleType'), width: 280, fixed: 'left' },
  // 账号类型
  { prop: 'customerType', label: i18n.t('receivablePayable.accountType'), width: 160, fixed: 'left' },
  // 路线类型
  { prop: 'routeType', label: i18n.t('basicData.typeOfRoute'), width: 160, fixed: 'left' }
];
