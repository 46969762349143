<template>
  <div>
    <object
      :data="htmlUrl"
      type="text/html"
      width="100%"
      height="600px"
    ></object>
  </div>
</template>
<script>
export default {
  data() {
    return {
      htmlUrl: 'https://anjun-express-webtmp-1313947203.cos-website.sa-saopaulo.myqcloud.com/'
    };
  }
};
</script>
<style scoped>

</style>
