import newRequest from '@/utils/new-request';

// ----------------------- 成本费用 start -----------------------

// 分页查询
export function getCostSiteExpensePage(data, isBool = true) {
  return newRequest({
    method: 'POST',
    url: '/costSiteExpense/getPage',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
}

// 批量新增费用
export function getBatchAddCostSiteExpense(data, isBool = true) {
  return newRequest({
    method: 'POST',
    url: '/costSiteExpense/batchAddFee',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
}

// 单个新增费用
export function getAddCostSiteExpenseFee(data, isBool = true) {
  return newRequest({
    method: 'POST',
    url: '/costSiteExpense/addFee',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
}

// 批量备注
export function getCostSiteExpenseRemark(data, isBool = true) {
  return newRequest({
    method: 'POST',
    url: '/costSiteExpense/setRemark',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
}

// 批量删除费用
export function getBatchCostSiteExpenseFee(data, isBool = true) {
  return newRequest({
    method: 'POST',
    url: '/costSiteExpense/batch/delete',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
}

// 删除费用
export function getDeleteCostSiteExpenseFee(data, isBool = true) {
  return newRequest({
    method: 'POST',
    url: '/costSiteExpense/delete',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
}

// 详情
export function getCostSiteExpenseInfo(id, isBool = true) {
  return newRequest({
    method: 'GET',
    url: `/costSiteExpense/getInfo/${id}`,
    isLoading: isBool // 是否显示加载中参数
  });
}

// 下载模板
export function getImportTemplate() {
  return newRequest({
    method: 'GET',
    url: `/costSiteExpense/importEditFeeTemplate`,
    responseType: 'blob'
  });
}

// 导入模板
export function getImportFee(data) {
  return newRequest({
    method: 'POST',
    url: `/costSiteExpense/importEditFee`,
    data
  });
}

// 导出
export function getExportCostSiteExpense(data, isBool = true) {
  return newRequest({
    method: 'POST',
    url: '/costSiteExpense/export',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
}

// 生成对账单
export function getCreateCostSiteExpenseBill(data, isBool = true) {
  return newRequest({
    method: 'POST',
    url: '/costSiteBill/generateBillNumber',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
}

// ----------------------- 成本费用 end -----------------------

// ----------------------- 成本审核 start -----------------------

// 分页查询
export function getCostSiteExpenseBillPage(data, isBool = true) {
  return newRequest({
    method: 'POST',
    url: '/costSiteBill/getPage',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
}

// 撤回对账单
export function getCostSiteExpenseBillWithdraw(id, isBool = true) {
  return newRequest({
    method: 'PUT',
    url: `/costSiteBill/withdraw/${id}`,
    isLoading: isBool // 是否显示加载中参数
  });
}

// 审核对账单
export function getCostSiteExpenseBillAudit(data, isBool = true) {
  return newRequest({
    method: 'POST',
    url: '/costSiteBill/audit',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
}

// ---------------------- 成本审核 end --------------------------------

// ----------------------- 成本核付 start -----------------------------

// 分页查询
export function getSiteExpenseBillPage(data, isBool = true) {
  return newRequest({
    method: 'POST',
    url: '/siteCost/getPage',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
}

// 撤回
export function getSiteCostWithdraw(data, isBool = true) {
  return newRequest({
    method: 'POST',
    url: '/siteCost/withdraw',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
}

// 核付
export function getSiteCostCrediting(data, isBool = true) {
  return newRequest({
    method: 'POST',
    url: '/siteCost/crediting',
    data,
    isLoading: isBool // 是否显示加载中参数
  });
}

// ----------------------- 成本核付 end -----------------------------

