export const receivablePayable = {
  AddChargesInBulk: {
    zh: '批量添加费用',
    en: 'Add charges in bulk',
    pu: 'Adicionar encargos em massa'
  },
  GenerateReceivables: {
    zh: '生成应收',
    en: 'Generate receivables',
    pu: 'Gerar recebíveis'
  },
  remark: {
    zh: '备注',
    en: 'remark',
    pu: 'Observações'
  },
  ImportAddCharges: {
    zh: '导入添加费用',
    en: 'Import Add charges',
    pu: 'Importar Adicionar encargos'
  },
  ImportModifyExpenses: {
    zh: '导入修改费用',
    en: 'Import Modify expenses',
    pu: 'Importar Modificar despesas'
  },
  AddModifyExpenses: {
    zh: '添加/修改费用',
    en: 'Add/modify expenses',
    pu: 'Adicionar/modificar despesas'
  },
  AddModifyChargesBulk: {
    zh: '批量添加/修改费用',
    en: 'Add/modify charges in bulk',
    pu: 'Adicionar/modificar encargos em massa'
  },
  Export: {
    zh: '导出',
    en: 'Export',
    pu: 'Exportação'
  },
  SelectGenerateAccrualTime: {
    zh: '选择生成应收时间',
    en: 'Select Generate accrual time',
    pu: 'Selecione Gerar tempo de acumulação'
  },
  SelectGenerateAccrualDate: {
    zh: '选择生成应收日期',
    en: 'Select Generate accrual date',
    pu: 'Selecine gerar a data de recebimento previsto'
  },

  GenerateBillsPayable: {
    zh: '生成应付账单',
    en: 'Generate bills payable',
    pu: 'Gerar contas a pagar'
  },
  SelectGeneratePayableTime: {
    zh: '选择生成应付时间',
    en: 'Select Generate Payable Time',
    pu: 'Selecione Gerar Tempo de Pagamento'
  },
  TheTimeCannotBeEmpty: {
    zh: '时间不能为空',
    en: 'The time cannot be empty',
    pu: 'O tempo não pode estar vazio'
  },
  RecalculateThePrice: {
    zh: '重新计算价格',
    en: 'Recalculate the price',
    pu: 'Recalcular o preço'
  },
  Maximum100000: {
    zh: '上限10w',
    en: 'Maximum 100,000',
    pu: 'Máximo 100.000'
  },
  RecalculationPriceExceed100000Pieces: {
    zh: '重新计算价格上限不能超过10w',
    en: 'The recalculation price limit cannot exceed 100,000 pieces',
    pu: 'O limite de preço de recálculo não pode exceder 100.000 peças'
  },
  Delete: {
    zh: '删除',
    en: 'Delete',
    pu: 'Excluir'
  },
  Search: {
    zh: '查询',
    en: 'Search',
    pu: 'Procurar'
  },
  AddANote: {
    zh: '添加备注',
    en: 'Add a note',
    pu: 'Adicionar uma nota'
  },

  OperationalLogs: {
    zh: '添加备注',
    en: 'Operational logs',
    pu: 'Logs operacionais'
  },
  ReconciliationBatchGenerationSuccessful: {
    zh: '对账批次生成成功！',
    en: 'Reconciliation batch generation successful!',
    pu: 'Geração de lote de reconciliação bem-sucedida!'
  },
  ReconciliationBatchNumber: {
    zh: '对账批次号',
    en: 'Reconciliation batch number',
    pu: 'Número do lote de reconciliação'
  },
  CopyTheBatchNumber: {
    zh: '复制批次号',
    en: 'Copy the batch number',
    pu: 'Copiar o número do lote'
  },
  NoteAllDataSelectedChargeDeleted: {
    zh: '注意：会删除所选费用类型的所有数据',
    en: 'Note: All data for the selected charge type is deleted',
    pu: 'Nota: Todos os dados para o tipo de cobrança selecionado são excluídos'
  },
  ImportCalculations: {
    zh: '导入计算',
    en: 'Import calculations',
    pu: 'Cálculos de importação'
  },
  ImportCalculatePrices: {
    zh: '导入计算价格',
    en: 'Import to calculate prices',
    pu: 'Importação para calcular preços'
  },
  ImportWeightCalculations: {
    zh: '导入重量计算',
    en: 'Import weight calculations',
    pu: 'Cálculos de peso de importação'
  },
  ImportWeightsCalculatePrices: {
    zh: '导入重量计算价格',
    en: 'Import weights to calculate prices',
    pu: 'Pesos de importação para calcular preços'
  },
  tips: {
    zh: '提示',
    en: 'tips',
    pu: 'Dica'
  },
  DeleteTheFee: {
    zh: '删除费用',
    en: 'Delete the fee',
    pu: 'Excluir a taxa'
  },
  MustBeLessThan: {
    zh: '必须小于',
    en: 'Must be less than',
    pu: 'Deve ser menor a'
  },
  customer: {
    zh: '客户',
    en: 'customer',
    pu: 'cliente'
  },
  CollectedWeight: {
    zh: '揽收重量',
    en: 'Collected weight',
    pu: 'Peso coletado'
  },
  TransitWeight: {
    zh: '中转重量',
    en: 'Transit weight',
    pu: 'Peso de trânsito'
  },
  BillableWeight: {
    zh: '计费重量',
    en: 'Billable weight',
    pu: 'Peso faturável'
  },
  BillableWeightBeforeRecalculation: {
    zh: '计费重量(重算前)',
    en: 'Billable weight (before recalculation)',
    pu: 'Peso cobrado (antes do cálculo)'
  },
  BillableWeightRecalculated: {
    zh: '计费重量(重算后)',
    en: 'Billable weight (recalculated)',
    pu: 'Peso cobrado (após o recálculo)'
  },
  CustomerTrackingNumber: {
    zh: '客户单号',
    en: 'Customer tracking number',
    pu: 'Número de rastreamento de clientes'
  },
  MultipleNumberWrapEntry: {
    zh: '多个号码换行输入',
    en: 'Multiple number wrap entry',
    pu: 'Entrada de quebra automática de nulidade de vários números'
  },
  WhetherToGenerateAStatement: {
    zh: '是否生成对账单',
    en: 'Whether to generate a statement',
    pu: 'Se deve gerar uma instrução'
  },
  Generated: {
    zh: '已生成',
    en: 'Generated',
    pu: 'Gerado'
  },
  NotGenerated: {
    zh: '未生成',
    en: 'Not generated',
    pu: 'Não gerado'
  },
  BillingBatchNumber: {
    zh: '账单批次号',
    en: 'Billing batch number',
    pu: 'Número do lote de faturamento'
  },
  ReceiptBatchNumber: {
    zh: '收款批次号',
    en: 'Receipt batch number',
    pu: 'Número do lote do recibo'
  },
  ReconciliationStatus: {
    zh: '对账状态',
    en: 'Reconciliation status',
    pu: 'Status de reconciliação'
  },
  BillingName: {
    zh: '账单名称',
    en: 'Billing name',
    pu: 'Nome de faturamento'
  },
  IncomingBills: {
    zh: '收款账单',
    en: 'Incoming bills',
    pu: 'Faturas recebidas'
  },
  BillingStatus: {
    zh: '账单状态',
    en: 'Billing status',
    pu: 'Status de faturamento'
  },
  ToBeReviewed: {
    zh: '待审核',
    en: 'To be reviewed',
    pu: 'A rever'
  },
  Passed: {
    zh: '已通过',
    en: 'Passed',
    pu: 'Passado'
  },
  DoesNotPass: {
    zh: '未通过',
    en: 'Does not pass',
    pu: 'Sem passe'
  },
  PendingPayment: {
    zh: '待收款',
    en: 'Pending payment',
    pu: 'Pagamento pendente'
  },
  Received: {
    zh: '已收款',
    en: 'Received',
    pu: 'Recebido'
  },
  PartialCollection: {
    zh: '部分收款',
    en: 'Partial collection',
    pu: 'Coleta parcial'
  },
  PartialDisbursement: {
    zh: '部分付款',
    en: 'Partial disbursement',
    pu: 'Pagamento parcial'
  },
  BusinessPrincipalName: {
    zh: '应收主体名称',
    en: 'Receivable principal name',
    pu: 'Nome principal do recebível'
  },
  TheNumberOfOrders: {
    zh: '订单数量',
    en: 'The number of orders',
    pu: 'O número de pedidos'
  },
  TheNumberOfFees: {
    zh: '费用条数',
    en: 'The number of fees',
    pu: 'O número de taxas'
  },
  TotalBillableWeight: {
    zh: '计费总重量（kg）',
    en: 'Total billable weight (kg)',
    pu: 'Peso total faturável (kg)'
  },
  TotalAmountReceivable: {
    zh: '应收总金额',
    en: 'Total amount receivable',
    pu: 'Valor total a receber'
  },
  TheTotalAmountDue: {
    zh: '应付总金额',
    en: 'Total amount payable',
    pu: 'Montante total a pagar'
  },
  AverageAmountPerVote: {
    zh: '平均每票金额',
    en: 'Average amount per vote',
    pu: 'Valor médio por voto'
  },
  BuildThePerson: {
    zh: '生成人员',
    en: 'Build the person',
    pu: 'Construa a pessoa'
  },
  BuildTime: {
    zh: '生成时间',
    en: 'Build time',
    pu: 'Tempo de compilação'
  },
  examine: {
    zh: '审核',
    en: 'examine',
    pu: 'Exame'
  },
  Auditors: {
    zh: '审核人员',
    en: 'Auditors',
    pu: 'Contas'
  },
  ReviewTime: {
    zh: '审核时间',
    en: 'Review time',
    pu: 'Tempo de revisão'
  },
  AuditReason: {
    zh: '审核原因',
    en: 'Audit reason',
    pu: 'Motivo da auditoria'
  },
  ReconcileBatchDetails: {
    zh: '对账批次详情',
    en: 'Reconcile batch details',
    pu: 'Reconciliar detalhes do lote'
  },
  FeeDetails: {
    zh: '费用详情',
    en: 'Fee details',
    pu: 'Detalhes das taxas'
  },
  SelectAtLeastOnePieceOfData: {
    zh: '最少要选择一条数据',
    en: 'Select at least one piece of data',
    pu: 'Selecionar pelo menos um dado'
  },
  OnlyOnePieceOfDataCanBeSelected: {
    zh: '只能选择一条数据',
    en: 'Only one piece of data can be selected',
    pu: 'Apenas um dado pode ser selecionado'
  },
  ReceiptStatus: {
    zh: '收款状态',
    en: 'Receipt status',
    pu: 'Status do recibo'
  },
  Approved: {
    zh: '审核通过',
    en: 'Approved',
    pu: 'Aprovado'
  },
  withdraw: {
    zh: '撤回',
    en: 'withdraw',
    pu: 'retirar'
  },
  receivables: {
    zh: '收款',
    en: 'receivables',
    pu: 'Resiwapur'
  },
  TicketAmount: {
    zh: '票单金额',
    en: 'Ticket amount',
    pu: 'Valor de bilhete'
  },
  TheActualAmountReceived: {
    zh: '实际收款金额',
    en: 'The actual amount received',
    pu: 'O valor real recebido'
  },
  payee: {
    zh: '核收人',
    en: 'payee',
    pu: 'beneficiário'
  },
  CollectionTime: {
    zh: '核收时间',
    en: 'Collection time',
    pu: 'Tempo de recolha'
  },
  TheChargeTypeCannotBeEmpty: {
    zh: '费用类型不能为空',
    en: 'The charge type cannot be empty',
    pu: 'O tipo de carga não pode estar vazio'
  },
  ConfirmDeletionAllDataForSelectedExpenseType: {
    zh: '确认删除所选费用类型的所有数据？',
    en: 'Confirm deletion of all data for the selected expense type?',
    pu: 'Confirmar a exclusão de todos os dados para o tipo de despesa selecionado?'
  },
  TheCommentCannotBeEmpty: {
    zh: '备注不能为空',
    en: 'The comment cannot be empty',
    pu: 'O comentário não pode estar vazio'
  },
  AreYouSureGenerateReceivablesSelectedData: {
    zh: '确定给选中数据生成应收？',
    en: 'Are you sure to generate receivables for the selected data?',
    pu: 'Tem certeza de gerar recebíveis para os dados selecionados?'
  },
  UncheckedDataWillGenerateReceivablesAllDataQueried: {
    zh: '未勾选数据将会给查询到的所有数据都生成应收，确定生成应收？',
    en: 'Unchecked data will generate receivables for all data queried.',
    pu: 'Os dados não verificados gerarão receivables para todos os dados consultados.'
  },
  AreYouSureToGenerateAccountsPayableForTheSelectedData: {
    zh: '确定给选中数据生成应付？',
    en: '',
    pu: 'Confirma a geração de contas a pagar para os dados selecionados?'
  },
  UncheckedDataWillGenerateAccountsPayableForAllQueriedDataConfirmToGenerateAccountsPayable: {
    zh: '未勾选数据将会给查询到的所有数据都生成应付，确定生成应付？',
    en: '',
    pu: 'Os dados não selecionados gerarão contas a pagar para todos os dados encontrados na consulta. Confirma a geração de contas a pagar?'
  },
  PleaseSearchDataBeforeRecalculatingPrice: {
    zh: '请先搜索出数据后再做重算价格',
    en: 'Please search for the data before recalculating the price',
    pu: 'Por favor, procure os dados antes de recalcular o preço'
  },
  Recalculate: {
    zh: '点击”确认“按钮，将按照系统最新价格表对当前查询的所有“费用来源”为“系统生成”的费用进行重新核算，是否确认？',
    en: 'Click the "Confirm" button, all currently queried "fee source" is "system-generated" fees according to the latest price list of the system, will it be confirmed?',
    pu: 'Clique no botão "Confirmar", todas as "fontes de taxas" atualmente consultadas são taxas "geradas pelo sistema" de acordo com a lista de preços mais recente do sistema, será confirmado?'
  },
  PressRecalculateWithChecked: {
    zh: '点击”确认“按钮，将按照系统最新价格表对当前选中的“费用来源”为“系统生成”的费用进行重新核算，是否确认？',
    en: 'Click the "Confirm" button, and the currently selected "fee source" is "system-generated" fee will be recalculated according to the latest price list of the system.',
    pu: 'Clique no botão "Confirmar" e a "fonte de taxa" atualmente selecionada é "gerada pelo sistema" a taxa será recalculada de acordo com a lista de preços mais recente do sistema.'
  },
  WhetherItHasBeenPaid: {
    zh: '是否已支付',
    en: 'Whether payment has been made',
    pu: 'Se o pagamento foi feito'
  },
  Paid: {
    zh: '已付款',
    en: 'Paid',
    pu: 'Pagado'
  },
  NonPayment: {
    zh: '未付款',
    en: 'Non-payment',
    pu: 'Não pagamento'
  },
  付款中: {
    zh: '付款中',
    en: 'payment in progress',
    pu: 'pagamento em andamento'
  },
  付款失败: {
    zh: '付款失败',
    en: 'failed payment',
    pu: 'falha no pagamento'
  },
  WhetherOutTheWarehouse: {
    zh: '是否出库',
    en: 'Whether it is out of the warehouse',
    pu: 'Se está fora do armazém'
  },
  ShippedOut: {
    zh: '已出库',
    en: 'Shipped out',
    pu: 'Enviado para fora'
  },
  NotOutOfStock: {
    zh: '未出库',
    en: 'Not out of stock',
    pu: 'Não está fora de estoque'
  },
  TimeToCollectIntoTheTreasury: {
    zh: '揽收入库时间',
    en: 'Time to collect into the treasury',
    pu: 'Hora de recolher no tesouro'
  },
  CollectTheWeightOfTheLibrary: {
    zh: '揽收入库重量',
    en: 'Collect the weight of the library',
    pu: 'Peso da coleta até o armazém'
  },
  TransitInboundWeight: {
    zh: '中转入库重量',
    en: 'Transit inbound weight',
    pu: 'Peso em trânsito para o armazenamento'
  },
  FeeBreakdown: {
    zh: '费用明细',
    en: 'Fee breakdown',
    pu: 'Discriminação das taxas'
  },
  WhetherToCheckOrNot: {
    zh: '是否核收',
    en: 'Whether to check or not',
    pu: 'Verificação do recebimento ou não？'
  },
  AmountReceivable: {
    zh: '应收金额',
    en: 'Amount receivable',
    pu: 'Montante a receber'
  },
  EnterThePerson: {
    zh: '录入人员',
    en: 'Enter the person',
    pu: 'Gravador'
  },
  FlatSharingMethod: {
    zh: '平摊方式',
    en: 'Flat sharing method',
    pu: 'Método de compartilhamento igualitário'
  },
  SpreadEquallyByWeight: {
    zh: '按重量平摊',
    en: 'Spread equally by weight',
    pu: 'Igual participação por peso'
  },
  SplitEquallyByTheNumberOfVotes: {
    zh: '按票数平摊',
    en: 'Split equally by the number of votes',
    pu: 'Dividido igualmente pelo número de votos'
  },
  SpecifyTheAmount: {
    zh: '指定金额',
    en: 'Specify the amount',
    pu: 'Montante especificado'
  },
  SourceOfFees: {
    zh: '费用来源',
    en: 'Source of fees',
    pu: 'Fonte das taxas'
  },
  SystemGenerated: {
    zh: '系统生成',
    en: 'System generated',
    pu: 'Gerado pelo sistema'
  },
  AddManually: {
    zh: '手动添加',
    en: 'Add manually',
    pu: 'Adicionar manualmente'
  },
  CollectionSystemPush: {
    zh: '揽收系统推送',
    en: 'Collection system push',
    pu: 'Push do sistema de coleta'
  },
  OnlyOneChargeCanBeAdded: {
    zh: '只能添加一条费用',
    en: 'Only one charge can be added',
    pu: 'Apenas uma taxa pode ser adicionada'
  },
  AddAtLeastOneCharge: {
    zh: '最少添加一条费用',
    en: 'Add at least one charge',
    pu: 'Adicione um mínimo de uma taxa'
  },
  ImportPrompts: {
    zh: '导入提示',
    en: 'Import prompts',
    pu: 'Dicas de importação'
  },
  TotalImport: {
    zh: '总共导入',
    en: 'Total import',
    pu: 'Importado no total'
  },
  AllSuccessful: {
    zh: '全部成功',
    en: 'All successful',
    pu: 'Todos bem sucedidos'
  },
  PartiallySuccessful: {
    zh: '部分成功',
    en: 'Partially successful',
    pu: 'Parcialmente bem sucedido'
  },
  AllFail: {
    zh: '全部失败',
    en: 'All fail',
    pu: 'Todos falham'
  },
  ReasonForFailure: {
    zh: '失败原因',
    en: 'Reason for failure',
    pu: 'Motivo da falha'
  },
  piecesDataImported: {
    zh: '条数据',
    en: 'pieces of data are imported',
    pu: 'dados são importados'
  },
  dataSuccessful: {
    zh: '条数据',
    en: 'data successful',
    pu: 'dados bem-sucedidos'
  },
  piecesData: {
    zh: '条数据',
    en: 'pieces of data',
    pu: 'pedaços de dados'
  },
  ImportAddModifyExpenses: {
    zh: '导入添加/修改费用',
    en: 'Import add/modify expenses',
    pu: 'Importar e adicoinar/modifica as taxas'
  },
  ActionFields: {
    zh: '操作字段',
    en: 'Action fields',
    pu: 'Campos de operação'
  },
  BeforeOperation: {
    zh: '操作前',
    en: 'Before operation',
    pu: 'Pré-operação'
  },
  AfterTheOperation: {
    zh: '操作后',
    en: 'After the operation',
    pu: 'Após a operação'
  },
  ThePriceBecomesLowerAfterRecalculation: {
    zh: '重算后价格变低',
    en: 'The price becomes lower after recalculation',
    pu: 'Preço mais baixo após recálculo'
  },
  ThePriceBecomesHigherAfterRecalculation: {
    zh: '重算后价格变高',
    en: 'The price becomes higher after recalculation',
    pu: 'Preço mais alto após recálculo'
  },
  ThePriceAfterRecalculationIs0: {
    zh: '重算后价格为0',
    en: 'The price after recalculation is 0',
    pu: 'O preço após o recálculo é 0'
  },
  ThePriceRemainsUnchangedAfterRecalculation: {
    zh: '重算后价格不变',
    en: 'The price remains unchanged after recalculation',
    pu: 'Nenhuma mudança no preço após o recálculo'
  },
  ConfirmTheReplacementPrice: {
    zh: '确认替换价格',
    en: 'Confirm the replacement price',
    pu: 'Confirme o preço de substituição'
  },
  CancelTheRecalculation: {
    zh: '取消重算',
    en: 'Cancel the recalculation',
    pu: 'Confirmação do preço de substituição'
  },
  ReplacementSuccessful: {
    zh: '替换成功',
    en: 'Replacement successful',
    pu: 'Substituição bem sucedida'
  },
  TheStartTimeCannotBeGreaterThanTheEndTime: {
    zh: '起始时间不能大于结束时间',
    en: 'The start time cannot be greater than the end time',
    pu: 'A hora inicial não pode ser maior do que a hora final'
  },
  SearchTimeCannotBeEmpty: {
    zh: '搜索时间不能为空',
    en: 'Search time cannot be empty',
    pu: 'O tempo de procura não pode estar vazio'
  },
  ReviewTheLogs: {
    zh: '查看日志',
    en: 'Review the logs',
    pu: 'Ver log'
  },
  receivablesTime: {
    zh: '收款时间',
    en: 'receivables time',
    pu: 'Horario de recibo'
  },
  HistoricalPayoutAmount: {
    zh: '历史收款金额',
    en: 'Historical payout amount',
    pu: 'Montantes históricos recebidos'
  },
  ReceivedAmount: {
    zh: '收款金额',
    en: 'Received amount',
    pu: 'Montante recebido'
  },
  AreYouSureYouWantToWithdraw: {
    zh: '确定要撤回?',
    en: 'Are you sure you want to withdraw?',
    pu: 'Tem certeza de que quer se retirar?'
  },
  ToBeVerified: {
    zh: '待核收',
    en: 'To be verified',
    pu: 'Aguarda verificação do recebimento'
  },
  PartialResurvision: {
    zh: '部分核收',
    en: 'Partial resurvision',
    pu: 'Ressurgimento parcial'
  },
  PassMultipleDataMergingToGenerateOneReceipt: {
    zh: '通过（多条数据合并生成一个收款单）',
    en: 'Pass (multiple data merging to generate one receipt)',
    pu: 'Passar (dados múltiplos para gerar um recibo)'
  },
  GenerateMultipleReceiptsThrough: {
    zh: '通过（多条数据生成多个收款单）',
    en: 'Generate multiple receipts through (multiple pieces of data)',
    pu: 'Passar (dados múltiplos para gerar múltiplos recibo)'
  },
  PriceBeforeRecalculation: {
    zh: '价格（重算前）',
    en: 'Price (before recalculation)',
    pu: 'Preço (antes do recálculo)'
  },
  PriceAfterRecalculation: {
    zh: '价格（重算后）',
    en: 'Price (after recalculation)',
    pu: 'Preço (após recálculo)'
  },
  ImportRecalculatePricesTemplate: {
    zh: '导入重新核算价格模板',
    en: 'Import the Recalculate prices template',
    pu: 'Importar o modelo Recalcular preços'
  },
  ImportWeightRecalculationPriceTemplate: {
    zh: '导入重量重新核算价格模板',
    en: 'Import the Weight Recalculation price template',
    pu: 'Importar o modelo de preço de Recálculo de Peso'
  },
  DownloadTheTemplate: {
    zh: '下载模板',
    en: 'Download the template',
    pu: 'Baixe o modelo'
  },
  AllWithdrawn: {
    zh: '撤回全部',
    en: 'Withdraw all',
    pu: 'Retirar tudo'
  },
  OccurrenceTimeFormatIncorrect: {
    zh: '发生时间格式不正确',
    en: 'The Occurrence Time format is incorrect',
    pu: 'O formato de hora de ocorrência está incorreto'
  },
  Only20000PiecesImport: {
    zh: '每次仅导入20000条',
    en: 'Only 20,000 pieces are imported at a time',
    pu: 'Importar apenas 20.000 itens de cada vez'
  },
  ThirdPartyCharterFees: {
    zh: '第三方包车费用',
    en: 'Third-party charter fees',
    pu: 'Custo de Transporte de Terceiros'
  },
  TotalFeesPayable: {
    zh: '总应付费用',
    en: 'Total fees payable',
    pu: 'Custo Total a Pagar'
  },
  AmountDue: {
    zh: '应付金额',
    en: 'Amount due',
    pu: 'Valor a Pagar'
  },
  DateOfManufacture: {
    zh: '生产日期',
    en: 'Date of manufacture',
    pu: 'Data de Produção'
  },
  Verification: {
    zh: '核付',
    en: 'Verification',
    pu: 'Verificação'
  },
  PaymentSlipNumber: {
    zh: '付款单号',
    en: 'Payment slip number',
    pu: 'Número de pagamento'
  },
  PaymentStatus: {
    zh: '付款状态',
    en: 'Payment status',
    pu: 'Status do pagamento'
  },
  PendingPayment1: {
    zh: '待核付',
    en: 'Pending Payment',
    pu: 'A Ser Verificado'
  },
  Paid1: {
    zh: '已核付',
    en: 'Paid',
    pu: 'Verificado'
  },
  PartialPayment: {
    zh: '部分核付',
    en: 'Partial payment',
    pu: 'Verificação Parcial'
  },
  TheNameOfThePaymentEntity: {
    zh: '付款主体名称',
    en: 'The name of the payment entity',
    pu: 'Nome do pagador'
  },
  PaymentTime: {
    zh: '付款时间',
    en: 'Payment time',
    pu: 'Tempo de pagamento'
  },
  Redeemer: {
    zh: '核付人',
    en: 'Redeemer',
    pu: 'Verificador'
  },
  TimeOfPayment: {
    zh: '核付时间',
    en: 'Time of payment',
    pu: 'Prazo de pagamento'
  },
  HistoricalPaymentAmounts: {
    zh: '历史付款金额',
    en: 'Historical payment amounts',
    pu: 'Valor de pagamento histórico'
  },
  TheAmountOfThePayment: {
    zh: '付款金额',
    en: 'The amount of the payment',
    pu: 'Valor de pagamento'
  },
  ThePayableBodyCannotBeEmpty: {
    zh: '应付主体不能为空',
    en: 'The payable body cannot be empty',
    pu: 'A entidade pagadora não pode ser vazio'
  },
  TheBusinessTimeCannotBeEmpty: {
    zh: '业务时间不能为空',
    en: 'The business time cannot be empty',
    pu: 'Tempo de negócio não pode ser vazio'
  },
  OnlyOneDayCanBeSelected: {
    zh: '只能选择七天时间',
    en: 'Only seven days can be selected',
    pu: 'Você só pode escolher sete dias'
  },
  OnlyDataSameServiceTypeCanSelected: {
    zh: '只能选择业务类型相同的数据',
    en: 'Only data of the same service type can be selected',
    pu: 'Somente dados do mesmo tipo de serviço podem ser selecionados'
  },
  TheSearchCriteriaCannotBeEmpty: {
    zh: '搜索条件业务类型不能为空',
    en: 'The search criteria cannot be empty',
    pu: 'Os critérios de pesquisa não podem estar vazios'
  },
  IfTheServiceTypeCollection: {
    zh: '业务类型为揽收时不能进行导入重新计算价格',
    en: 'If the service type is Collection, you cannot import and recalculate the price',
    pu: 'Não é possível importar para recalcular o preço quando o tipo de negócio é range rover'
  },
  PleaseQueryCheckTheDataFirst: {
    zh: '请先查询/勾选数据',
    en: 'Please query/check the data first',
    pu: 'Consulte/selecione os dados'
  },
  PleaseFilterBusinessCustomer: {
    zh: '请筛选相同业务类型同一客户的数据进行批量添加费用',
    en: 'Please filter the data of the same business type and the same customer to add expenses in batches',
    pu: 'Filtre os dados do mesmo tipo de negócio e do mesmo cliente para adicionar taxas em massa'
  },
  BusinessTypePickupPickupCategoryCannotEmpty: {
    zh: '业务类型为揽收时揽收类别不能为空',
    en: 'If the business type is Pickup, the Pickup category cannot be empty',
    pu: 'Se o tipo de negócio for Recebimento, a categoria Recebimento não poderá estar vazia'
  },
  WhenPickupCategoryStockingPickup: {
    zh: '揽收类别为备货揽收时只能重新计算一天时间内的费用',
    en: 'When the pickup category is Stocking Pickup, the cost can only be recalculated for a one-day period',
    pu: 'Quando a categoria de retirada é Stocking Pickup, o custo só pode ser recalculado por um período de um dia'
  },
  QuickRecord: {
    zh: '快录',
    en: 'Quick Record',
    pu: 'registro rápido'
  },
  BatchImportClips: {
    zh: '批量导入快录',
    en: 'Batch import clips',
    pu: 'importar registro rápido em massa'
  },
  source: {
    zh: '来源',
    en: 'source',
    pu: 'origem'
  },
  QuickRecordGeneration: {
    zh: '快录生成',
    en: 'Quick record generation',
    pu: 'generação do registro rápido'
  },
  SnapshotFee: {
    zh: '快录费用',
    en: 'Snapshot fee',
    pu: 'custo do registro rápido'
  },
  TheNumberOutletsCustomersHasIncreasedSimultaneously: {
    zh: '网点对客户同步增加',
    en: 'The number of outlets to customers has increased simultaneously',
    pu: 'aumento simultâneo de pontos com os clientes'
  },
  TicketNumber: {
    zh: '业务单号',
    en: 'Ticket number',
    pu: 'número do negócio'
  },
  TheCompanyNetworkOutletsHasIncreasedSimultaneously: {
    zh: '公司对网点同步增加',
    en: "The company's network of outlets has increased simultaneously",
    pu: 'empresa aumenta os pontos simultaneamente'
  },
  Only500PiecesImport: {
    zh: '每次仅导入 500 条',
    en: 'Only 500 pieces are imported at a time',
    pu: 'Importar apenas 500 itens de cada vez'
  },
  Only3000PiecesImport: {
    zh: '每次仅导入 3000 条',
    en: 'Only 3000 pieces are imported at a time',
    pu: 'Importar apenas 3000 itens de cada vez'
  },
  Only50000PiecesImport: {
    zh: '每次仅导入 50000 条',
    en: 'Only 50000 pieces are imported at a time',
    pu: 'Importar apenas 50000 itens de cada vez'
  },
  Only100000PiecesImport: {
    zh: '每次仅导入 100000 条',
    en: 'Only 100000 pieces are imported at a time',
    pu: 'Importar apenas 100000 itens de cada vez'
  },
  ThisTemplateCannotBeChanged: {
    zh: '不可更改此模板',
    en: 'This template cannot be changed',
    pu: 'não pode modificar este modelo'
  },
  CompanyToSite: {
    zh: '公司对网点',
    en: 'Company to site',
    pu: 'Empresa para o site'
  },
  SiteToCustomer: {
    zh: '网点对客户',
    en: 'Site to customer',
    pu: 'Site para o cliente'
  },
  // OnlyDataSameServiceType: {
  //   zh: '一次仅能重算同一业务类型，业务时间为7天且数据量小于10w的数据',
  //   en: 'Only data of the same service type, which is 7 days old and has a data volume of less than 10 W, can be recalculated at a time',
  //   pu: 'Só pode recalcular os dados de mesmo negócio com volume menor que 100.000 e o período dentro do 7 dias de cada vez.'
  // },
  OnlyDataSameServiceType: {
    zh: '一次仅能重算业务时间为7天且数据量小于10w的数据',
    en: '',
    pu: 'Apenas é possível recalcular os dados com um período de operação de 7 dias por vez e com um volume de dados inferior a 100.000.'
  },
  KuaiFee: {
    zh: '客户为“kuai”，费用类型“揽收费”才能进行补推',
    en: 'The customer is "kuai", and the fee type is "collect fee"',
    pu: 'O cliente é "kuai", o tipo de taxa "solicitação de carga" pode ser reposto'
  },
  SendAJDRecalculation: {
    zh: '派送AJD',
    en: '',
    pu: 'Recalcular a entrega AJD'
  },
  cycle: {
    zh: '周期',
    en: '',
    pu: 'Ciclo'
  },
  totalInvoiceAmount: {
    zh: '发票总金额',
    en: 'Ticket number',
    pu: 'Valor total da fatura'
  },
  correct: {
    zh: '更正',
    en: '',
    pu: 'Corrija'
  },
  noReply: {
    zh: '无回复',
    en: '',
    pu: 'Sem resposta'
  },
  downloadLink: {
    zh: '当前选中的发票不存在下载链接',
    en: '',
    pu: 'A fatura selecionada atualmente não possui link de download'
  },
  ImportPostcodeCalculation: {
    zh: '导入邮编计算',
    en: '',
    pu: 'Importar cálculo de CEP'
  },
  ImportPostalCodeTemplate: {
    zh: '导入邮编重新核算价格模板',
    en: '',
    pu: 'Importar CEP para recalculação do modelo de preços'
  },
  billingTime: {
    zh: '账单时间',
    en: '',
    pu: 'Data de fatura'
  },
  BillingDate: {
    zh: '账单日期',
    en: 'Billing date',
    pu: 'Data de faturamento'
  },
  SendBill: {
    zh: '发送账单',
    en: 'Send a bill',
    pu: 'Envie sua fatura'
  },
  SendEmailConfirmation: {
    zh: '账单发送邮件确认',
    en: 'The bill is confirmed by email',
    pu: 'Faturamento enviar e-mail de confirmação'
  },
  ICMSAmount: {
    zh: 'ICMS金额',
    en: '',
    pu: 'Valor do ICMS'
  },
  shippingAmount: {
    zh: '运费金额',
    en: '',
    pu: 'Valor do frete'
  },
  BillingSubjectCNPJ: {
    zh: '开票主体CNPJ',
    en: '',
    pu: 'CNPJ do emitente da fatura'
  },
  MailSendingStatus: {
    zh: '邮件发送状态',
    en: 'Mail sending status',
    pu: 'Status de envio de e-mail'
  },
  ReceiveBillingMail: {
    zh: '接收账单邮件',
    en: 'Receive billing mail',
    pu: 'Receber e-mails de cobrança'
  },
  Sender: {
    zh: '发送人',
    en: 'sender',
    pu: 'Enviar uma pessoa'
  },
  DSPCheck: {
    zh: '派送模式为《DSP第三方》才能进行重算价格',
    en: 'Delivery mode is DSP third party for recalculation of prices',
    pu: 'O modo de entrega é DSP terceirizado para recálculo de preços'
  },
  DispatchDSPRecalculation: {
    zh: '派送DSP',
    en: 'Dispatch DSP recalculation',
    pu: 'Recálculo do DSP de despacho'
  },
  RecalculationOfTheCollectionAnjunHut: {
    zh: '安骏小屋(上门交件)',
    en: 'Anjun Hut (door-to-door delivery)',
    pu: 'Anjun Hut (entrega porta a porta)'
  },
  ManualAddition: {
    zh: '手工添加',
    en: 'Manual addition',
    pu: 'Adições ao manual'
  },
  OriginatingState: {
    zh: '始发州',
    en: 'originating state',
    pu: 'estado de origem'
  },
  PaymentOrderInformation: {
    zh: '付款单信息',
    en: 'Payment Order Information',
    pu: 'Informações sobre ordens de pagamento'
  },

  TotalAmountToBePaid: {
    zh: '应付合计',
    en: 'Total amount to be paid',
    pu: 'Valor total a ser pago'
  },

  TotalAmountPaid: {
    zh: '已付合计',
    en: 'Total amount paid',
    pu: 'Valor total pago'
  },

  TotalAmountOutstanding: {
    zh: '未付合计',
    en: 'Total amount outstanding',
    pu: 'Total em aberto'
  },
  手动开票: {
    zh: '手动开票',
    en: 'Manual Invoicing',
    pu: 'Faturamento manual'
  },
  搜索条件为空: {
    zh: '查询条件不允许全部为空;不包含费用类型,是否出库,是否生成对账单,是否核收',
    en: 'Query conditions are not allowed to be completely empty; does not include the type of cost, whether the warehouse, whether to generate statements, whether to check receipt',
    pu: 'Não é permitido que as condições de consulta sejam completamente vazias; não incluem o tipo de custo, se o depósito, se deve gerar declarações, se deve verificar o recebimento'
  },
  导入文件时间: {
    zh: '导入文件时间',
    en: '',
    pu: 'Tempo de importação do arquivo'
  },
  导入人: {
    zh: '导入人',
    en: '',
    pu: 'Importador'
  },
  导入时间: {
    zh: '导入时间',
    en: '',
    pu: 'Tempo de importação'
  },
  导入状态: {
    zh: '导入状态',
    en: '',
    pu: 'Status da importação'
  },
  操作功能: {
    zh: '操作功能',
    en: '',
    pu: 'Função de operação'
  },
  揽收费异常费用: {
    zh: '揽收费异常费用',
    en: '',
    pu: 'Cobrança de taxas anormais'
  },
  按单号导出: {
    zh: '按单号导出',
    en: 'Export by Order Number',
    pu: 'Exportar por número de pedido'
  },
  paymentMethod: {
    zh: '结算方式',
    en: 'Payment method',
    pu: 'Método de liquidação'
  },
  deliveryVehicleType: {
    zh: '派送车辆类型',
    en: 'Delivery vehicle type',
    pu: 'Tipo de veículo de entrega'
  },
  accountType: {
    zh: '账号类型',
    en: 'account type',
    pu: 'tipo da conta'
  },
  CollectionTrackingNumber: {
    zh: '揽收单号',
    en: '',
    pu: 'Número de Coleta'
  },
  SelectBillingDate: {
    zh: '选择账单日期',
    en: '',
    pu: 'Selecionar a data da fatura'
  },
  ImportReceivableGeneration: {
    zh: '导入生成应收',
    en: 'Import Generate Receivables',
    pu: 'Importar Geração de Contas a Receber'
  },
  ImportReceivableTemplate: {
    zh: '导入生成应收模板',
    en: 'Import Receivable Generation Template',
    pu: 'Importar Modelo de Geração de Contas a Receber'
  },
  PleaseSelectReceivableGenerationTime: {
    zh: '请先选择生成应收时间',
    en: 'Please select the receivable generation time',
    pu: 'Importe o modelo de geração de contas a receber'
  },
  ImportDataLimit: {
    zh: '导入数据不能超过30万条',
    en: 'Imported data cannot exceed 300,000 records',
    pu: 'Os dados importados não podem exceder 300.000 registros'
  },
  ImportSuccessMessage: {
    zh: '导入成功，如有数据未成功，未成功数据将会在1~10分钟内生成表格，请到财务中心的文件上传管理中的列表中下载',
    en: 'Import successful. If some data fails to process, it will be generated in a spreadsheet within 1 to 10 minutes. Please download it from the list in the file upload management of the Financial Center.',
    pu: 'Importação realizada com sucesso. Se houver dados não processados, eles serão gerados em uma planilha dentro de 1 a 10 minutos. Por favor, acesse a lista na gestão de upload de arquivos do Centro Financeiro para baixar.'
  },
  ImportTemplateIncorrect: {
    zh: '导入模板有误',
    en: '',
    pu: 'Erro no Modelo de Importação'
  },
  ThirdPartyCollection: {
    zh: '第三方揽收',
    en: '',
    pu: 'Coleta por treceiro'
  },
  AnjunCottage: {
    zh: '安骏小屋',
    en: '',
    pu: 'Mini Drop'
  },
  AJDDispatch: {
    zh: 'AJD派送',
    en: '',
    pu: 'Entrega por AJD'
  },
  DSPDispatch: {
    zh: 'DSP派送',
    en: '',
    pu: 'Entrega por DSP'
  },
  Transportation_trunk_branch: {
    zh: '运输（干/支线)',
    en: '',
    pu: 'Transporte (Rota Primária/Secundária )'
  },

  // 银行对账单
  BarCode: {
    zh: '条码',
    en: 'Bar Code',
    pu: 'Código de barra'
  },
  PaymentBatchNumber: {
    zh: '付款批次号',
    en: '',
    pu: 'Número do Lote de Pagamento'
  },
  CreatePaymentTime: {
    zh: '创建付款时间',
    en: '',
    pu: 'Tempo de Criação do Pagamento'
  },
  BatchNumberStatus: {
    zh: '批次号状态',
    en: '',
    pu: 'Status do Lote'
  },
  ImportFile: {
    zh: '导入文件',
    en: '',
    pu: 'Importar Arquivo'
  },
  DownloadTemplate: {
    zh: '下载模板',
    en: '',
    pu: 'Download o modelo'
  },
  InitiatePayment: {
    zh: '发起付款',
    en: '',
    pu: 'Iniciar Pagamento'
  },
  QueryResults: {
    zh: '查询结果',
    en: '',
    pu: 'Resultado da Pesquisa'
  },
  PaymentValidityPeriod: {
    zh: '付款有效期',
    en: '',
    pu: 'Validade do Pagamento'
  },
  PaymentSuccessTime: {
    zh: '付款成功时间',
    en: '',
    pu: 'Data de Sucesso do Pagamento'
  },
  FullPayment: {
    zh: '全部付款',
    en: '',
    pu: 'Pagamento Completo'
  },
  PaymentSuccessful: {
    zh: '付款成功',
    en: '',
    pu: 'Pagamento Bem-sucedido'
  },
  BillTemplate: {
    zh: '账单模板',
    en: '',
    pu: 'Modelo de Fatura'
  },
  customerNumber: {
    zh: '客户单号',
    en: 'customerNumber',
    pu: 'Número do pedido do cliente'
  },
  RecipientzipCode: {
    zh: '收件人邮编',
    en: "Recipient's zip code",
    pu: 'CEP do destinatário'
  },
  orderStatus: {
    zh: '订单状态',
    en: 'orderStatus',
    pu: 'Status do pedido'
  },
  userType: {
    zh: '用户类型',
    en: '',
    pu: 'Tipo de usuário'
  },
  settlementType: {
    zh: '结算类型',
    en: '',
    pu: 'Tipo de liquidação'
  },
  PaymentDetails: {
    zh: '付款详细',
    en: '',
    pu: 'Detalhes do pagamento'
  },
  areYouRequestingPayment: {
    zh: '是否请款',
    en: '',
    pu: 'Se deve ou não solicitar fundos'
  },
  markPaymentRequest: {
    zh: '标记请款',
    en: '',
    pu: 'Marcação dos convites'
  },
  cancelPaymentRequest: {
    zh: '取消请款',
    en: '',
    pu: 'Anulação de pedidos'
  },
  SheinExport: {
    zh: 'Shein揽收对账单导出',
    en: 'Shein Collection Statement Export',
    pu: 'Exportação de declaração da coleção Shein'
  },
  receiveName: {
    zh: '收件人名字',
    en: '',
    pu: 'Nome do destinatário'
  },
  SheinTailgatePartyBillExport: {
    zh: 'Shein尾派对账单导出',
    en: 'Shein Tailgate Party Bill Export',
    pu: 'Exportação da conta da Shein Tailgate Party'
  },
  businessTimeOrOrderNumberCannotBeEmpty: {
    zh: '业务时间或单号不能为空',
    en: 'Business time or order number cannot be empty',
    pu: 'O horário comercial ou o número do pedido não pode estar vazio'
  },
  costumer: {
    zh: '成本主体',
    en: 'costumer',
    pu: 'Nome do cliente'
  },
  costOutlets: {
    zh: '成本网点',
    en: 'Cost outlets',
    pu: 'Pontos de venda de custos'
  },
  totalCost: {
    zh: '成本总费用',
    en: 'total cost',
    pu: 'custo total'
  },
  subjectAndBusinessTypeCannotBeEmpty: {
    zh: '主体和业务类型不能为空',
    en: 'Subject and business type cannot be empty',
    pu: 'O assunto e o tipo de negócio não podem estar vazios'
  },
  subjectAndBusinessTypeMustBeTheSame: {
    zh: '主体和业务类型必须一致',
    en: 'Subject and business type must be the same',
    pu: 'O assunto e o tipo de negócio devem ser os mesmos'
  },
  addingCosts: {
    zh: '添加费用',
    en: 'Adding costs',
    pu: 'Acréscimo de custos'
  },
  dateOfOccurrence: {
    zh: '发生时间',
    en: 'Date of occurrence',
    pu: 'Data da ocorrência'
  },
  approvedOrNot: {
    zh: '是否核付',
    en: 'Approved or not',
    pu: 'Aprovado ou não'
  },
  DetermineTheStatementToBeGeneratedForTheSelectedData: {
    zh: '确定给选中数据生成对账单？',
    en: 'Determine the statement to be generated for the selected data?',
    pu: 'Determinar o extrato a ser gerado para os dados selecionados?'
  },
  UncheckedDataWillBeGivenToQueryAllTheDataToGenerateStatementsDetermineTheGenerationOf: {
    zh: '未勾选数据将会给查询到的所有数据都生成对账单，确定生成？',
    en: 'Unchecked data will be given to query all the data to generate statements, determine the generation of?',
    pu: 'Os dados não verificados serão fornecidos à consulta para todos os dados para gerar declarações, determinar a geração de?'
  },
  BusinessTimeOrOrderNumberCannotBeEmpty: {
    zh: '业务时间或单号不可为空',
    en: 'Business time or order number cannot be empty',
    pu: 'O horário comercial ou o número do pedido não pode estar vazio'
  },
  ActualAmountPaid: {
    zh: '实际付款金额',
    en: 'Actual amount paid',
    pu: 'Valor real pago'
  },
  Costs: {
    zh: '成本',
    en: 'costs',
    pu: 'Custos'
  }
};
