<template>
  <div class="app-container">
    <div class="flx-between">
      <div class="flex">
        <el-button
          v-permit:remove="'btn:receivableManagementSite:toExamine:export'" size="small" type="primary"
          @click="operationBtn"
        >{{ $t('receivablePayable.Export') }} <!--导出-->
        </el-button>
        <!--自定义栏目-->
        <CustomStatusBar
          :columns-data="DETAIL_TABLE_HEADER"
          :params="searchParams"
          :save-params="customParams"
          @updateColumns="updateColumns"
        />
      </div>
      <!--查询-->
      <el-button
        icon="el-icon-search" size="small" type="primary"
        @click="searchResources()"
      >{{ $t('receivablePayable.Search') }}
      </el-button>
    </div>
    <el-divider />
    <div v-loading="alertLoading" class="alert">
      <i class="el-icon-info icon" />
      <p>
        <span class="title">{{ $t('orderCenterCont.TotalCost') }}： </span>
        <span class="num">{{ totalFeeHead }}</span>
        <span v-if="totalFeeHeadItem&&Object.keys(totalFeeHeadItem).length" class="signal"> | </span>
      </p>
      <p v-for="(value, key, index) in totalFeeHeadItem" :key="index">
        <span class="title">{{ key }}：</span>
        <span class="num">{{ value }}</span>
        <span v-if="Object.keys(totalFeeHeadItem).length-1>index" class="signal"> | </span>
      </p>
    </div>
    <div>
      <!--<div class="alert">
        <i class="el-icon-info icon"></i>
        <div style="display: contents;flex: 1">
          <div style="padding: 0 10px;">
            <div>
              <span class="title">总费用（RMB）： </span>
              <span class="num">
                {{ billBatchData.receivablesRmb }}
                <span v-if="JSON.stringify(totalFee) !== '{}'">
                  （<span v-for="(item, key, i) of totalFee" :key="i">{{ item }}：{{ key }}；</span>）
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>-->

      <el-table
        ref="elTable"
        :key="tableKey"
        :data="tableData"
        :height="tableHeight"
        border
        @header-dragend="headerWidthChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          align="center"
          type="selection"
          width="55"
        />
        <el-table-column :label="$t('operationCenter.ind')" align="center" width="60px">
          <template v-slot="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in currentColumns"
          :key="index"
          :align="item.fixed"
          :label="item.label"
          :prop="item.prop"
          :show-overflow-tooltip="true"
          :width="item.width"
        >
          <template v-slot="scope">
            <div v-if="item.prop === 'businessType'">
              {{
                scope.row[item.prop] === 0
                  ? $t('basicData.CourierSystemBusiness')
                  : scope.row[item.prop] === 1 ? $t('basicData.CollectionSystemBusiness') : ''
              }}
            </div>
            <!--货物类型-->
            <div v-else-if="item.prop === 'cargoType'">
              <span>{{ CARGO_TYPE[scope.row[item.prop]] && CARGO_TYPE[scope.row[item.prop]].name || '' }}</span>
            </div>
            <div v-else-if="item.prop === 'collectType'">
              {{
                scope.row[item.prop] === 1
                  ? $t('collectionCenter.dropOff')
                  : scope.row[item.prop] === 2 ? $t('collectionCenter.doorPickUp') : ''
              }}
            </div>
            <div v-else>{{ scope.row[item.prop] }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, ind) in getCurrentColumns"
          :key="ind + 100"
          :align="item.fixed"
          :label="item.label"
          :prop="item.prop"
          :show-overflow-tooltip="true"
          :width="item.width"
        >
          <template v-slot="scope">
            <div>{{ scope.row[item.prop] }}</div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          :current-page.sync="queryForm.pageNumber"
          :page-size.sync="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          style="margin: 10px 0"
          @current-change="getExamineTableData"
          @size-change="getExamineTableData"
        />
      </div>
    </div>
    <!--  查询  -->
    <el-drawer
      :append-to-body="true"
      :title="$t('receivablePayable.Search')"
      :visible.sync="showSearch"
      custom-class="drawerClass"
      direction="rtl"
      size="50%"
    >
      <search-form
        :is-read-only="true"
        :select-key="1"
        :bill-batch-number="batchNumber"
        @search="searchFun"
        @close="showSearch = false"
      />
    </el-drawer>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import {
  apiExportCustomerBills, apiSiteExpenseExpenseSubTotal,
  apiSiteExpenseGetPage
} from '@/api/finance/receivables';
import { goResourceTaskList } from '@/utils/goExportList.js';
import { CARGO_TYPE } from '@/views/finance/basicData/quotationManagement/cmp/utils';
import { DETAIL_TABLE_HEADER } from '@/views/finance/receivableManagementSite/toExamine/utils';
import { BH_ORDER_TYPE_LIST, ORDER_TYPE_LIST, WAYBILL_STATUS } from '@/views/finance/utils';
import { QUERY_PARAMS } from '@/views/finance/receivableManagementSite/aRGenerationNew/utils';

export default {
  name: 'BatchNumberDetail',
  components: {
    searchForm: () => import('@/views/finance/receivableManagementSite/aRGenerationNew/cmp/searchForm/index.vue'),
    CustomStatusBar: () => import('@/components/customStatusBar/index.vue')
  },
  props: {
    // 批次号
    batchNumber: {
      type: String,
      default: ''
    },
    // 列表详情
    billBatchData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      CARGO_TYPE,
      // 物流订单
      WAYBILL_STATUS,
      // 揽收订单
      ORDER_TYPE_LIST,
      // 备货揽收订单
      BH_ORDER_TYPE_LIST,
      DETAIL_TABLE_HEADER, // 详情表头配置项
      tableKey: new Date().getTime(),
      queryForm: {
        orderDirection: 'DESC',
        orderProperty: '',
        pageNumber: 1,
        pageSize: 10,
        params: {
          ...QUERY_PARAMS
        }
      },
      total: 0,
      tableData: [],
      currentColumns: [],
      getCurrentColumns: [],
      selectData: [],
      showSearch: false,
      tableHeight: 0,
      totalFee: 0,
      searchParams: {
        type: 0,
        menuEnum: 'companyToSiteReviewDetails'
      },
      customParams: {
        templateField: '',
        templateName: '应收账单审核详情',
        userId: '',
        ...this.searchParams
      },
      totalFeeHead: null, // 总费用
      totalFeeHeadItem: {}, // 费用详细
      alertLoading: false // 总费用loading
    };
  },

  created() {
    this.tableKey = new Date().getTime();
    this.createUser = getUserIdAndNameObj();
    this.tableHeight = document.body.clientHeight - 220;
    this.queryForm.params.billBatchNumber = this.batchNumber;
    this.getExamineTableData();
    this.getExpenseSubTotal();
  },
  mounted() {
    // 页面加载的时候设置table的高度
    this.tableHeight = document.body.clientHeight - 260;
    // 页面大小该变的时候（缩放页面）设置table的高度（可加可不加）
    window.onresize = () => {
      this.tableHeight = document.body.clientHeight - 260;
    };
  },
  methods: {
    // 更新自定义栏目columns
    updateColumns(list) {
      this.$nextTick(() => {
        this.currentColumns = cloneDeep(list);
        this.tableKey = new Date().getTime();
      });

      // console.log(list, '列表呈现的columns');
    },
    // 表头宽度变化
    headerWidthChange() {
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
      });
    },
    // 列表数据勾选
    handleSelectionChange(arr) {
      this.selectData = arr;
    },
    // 导出操作
    operationBtn() {
      const ids = [];
      this.selectData.map(list => {
        ids.push(list.id);
      });
      const prop = [];
      const label = [];
      this.currentColumns.forEach(item => {
        if (item.prop !== 'customerAlias') {
          prop.push(item.prop);
          label.push(item.label);
        }
      });
      const param = {
        ...this.queryForm.params,
        fieldList: prop,
        headList: label,
        ids: ids
      };
      apiExportCustomerBills(param).then(res => {
        if (res.code === 200) {
          return goResourceTaskList();
        }
      }).then(res => {
        if (res) {
          this.$emit('closeDetail');
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 打开查询弹窗
    searchResources() {
      this.showSearch = true;
    },
    // 获取列表
    getExamineTableData() {
      const param = cloneDeep(this.queryForm);
      this.getCurrentColumns = [];
      // 获取列表数据
      apiSiteExpenseGetPage(param).then(resp => {
        if (resp.code === 200) {
          this.tableData = resp.data.map(arr => {
            // 物流订单 WAYBILL_STATUS    揽收订单 ORDER_TYPE_LIST  备货揽收订单 BH_ORDER_TYPE_LIST
            if ((arr.businessType === 0 || arr.businessType === 7) && arr.status) {
              /**
               * 1、业务类型 businessType 为 0，订单状态展示【物流订单】的运单状态
               */
              const s1 = this.WAYBILL_STATUS.find(item => item.code === arr.status) || '';
              arr.status = s1.label;
            } else if (arr.businessType === 1 && arr.collectionCategory === 0 && arr.status) {
              /**
               * 2、业务类型 businessType 为 1，且揽收类别 collectionCategory 为 0，订单状态展示【揽收订单】的订单状态
               */
              const s2 = this.ORDER_TYPE_LIST.find(item => item.collectStatus === arr.status) || '';
              arr.status = s2.collectStatusName;
            } else if (arr.businessType === 1 && arr.collectionCategory === 1 && arr.status) {
              /**
               * 3、业务类型 businessType 为 1，且揽收类别 collectionCategory 为 1，订单状态展示【备货揽收订单】的订单状态
               */
              const s3 = this.BH_ORDER_TYPE_LIST.find(item => item.collectStatus === arr.status) || '';
              arr.status = s3.collectStatusName;
            } else {
              arr.status = '';
            }
            return arr;
          });
          this.total = resp.total;
          if (resp.data.length > 0) {
            this.getCurrentColumns = [];
            resp.columns.map(item => {
              const obj = {
                prop: item.prop,
                // label: item.label.split('_')[0],
                label: item.label,
                width: 160,
                fixed: 'left',
                highlight: false
              };
              this.getCurrentColumns.push(obj);
              this.$nextTick(() => {
                this.$refs.elTable.doLayout();
                this.rowSelectFlag = true;
                this.tableData.map(list => {
                  const matchedIndex = this.selectData.findIndex(itemList => itemList.id === list.id);
                  this.$refs.elTable.toggleRowSelection(list, matchedIndex !== -1);
                });
                this.rowSelectFlag = false;
              });
            });
          }
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 查询
    searchFun(params) {
      console.log(params, '查询条件');
      this.showSearch = false;
      Object.assign(this.queryForm.params, { ...params });
      this.tableKey = new Date().getTime();
      this.queryForm.pageNumber = 1;
      this.getExamineTableData();
    },
    /**
     * @description 获取费用统计
     */
    getExpenseSubTotal() {
      this.alertLoading = true;
      const param = cloneDeep(this.queryForm.params);
      apiSiteExpenseExpenseSubTotal(param)
        .then((res) => {
          console.log(res.data);
          this.totalFeeHead = res.data.totalFee;
          this.totalFeeHeadItem = res.data.itemFee;
        }).finally(() => {
          this.alertLoading = false;
        });
    }
  }
};
</script>

<!--<style scoped>-->
<!--.alert {-->
<!--  background-color: #e6f7ff;-->
<!--  border: 1px solid #91d5ff;-->
<!--  width: 100%;-->
<!--  display: flex;-->
<!--  align-items: center;-->
<!--  border-radius: 4px;-->
<!--  padding: 6px 16px;-->
<!--  margin-bottom: 5px;-->
<!--}-->

<!--.alert p {-->
<!--  margin: 0;-->
<!--  padding: 0;-->
<!--}-->

<!--.alert .icon {-->
<!--  color: #409eff;-->
<!--  font-size: 24px;-->
<!--}-->

<!--.alert .title {-->
<!--  /*margin-left: 8px;*/-->
<!--  font-size: 14px;-->
<!--}-->

<!--.alert .num {-->
<!--  font-weight: 600;-->
<!--  font-size: 14px;-->
<!--}-->

<!--.alert .signal {-->
<!--  color: #409eff;-->
<!--}-->
<!--</style>-->
