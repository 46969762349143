var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        title: _vm.$t("receivablePayable.FeeDetails"),
        "append-to-body": true,
        visible: _vm.isVisible,
        "custom-class": "drawerClass",
        direction: "ltr",
        size: "80%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isVisible = $event
        },
      },
    },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "details" },
          [
            _c("BasicForm", { attrs: { "add-batch-fee": _vm.addBatchFee } }),
            _c(
              "el-card",
              { attrs: { header: _vm.$t("receivablePayable.FeeBreakdown") } },
              [
                _c("FeeTable", {
                  ref: "detailsTableRef",
                  attrs: {
                    "add-type": 2,
                    "business-type": _vm.addBatchFee.businessType,
                    list: _vm.addBatchFeeList,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "btn" },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "permit",
                    rawName: "v-permit:remove",
                    value: "btn:finance:costManagement:costsFee:Edit",
                    expression: "'btn:finance:costManagement:costsFee:Edit'",
                    arg: "remove",
                  },
                ],
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.submit },
              },
              [_vm._v(_vm._s(_vm.$t("basicData.Save")))]
            ),
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.onClose } },
              [_vm._v(_vm._s(_vm.$t("orderCenterCont.ShutDown")))]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }