<template>
  <div>
    <!--    一键剔除-->
    <el-button
      size="small" type="primary" :disabled="checkList.length === 0"
      @click="oneClickDel"
    >
      {{ $t('compensationManagement.oneClickRejection') }}
    </el-button>

    <el-dialog
      :title="$t('basicData.tips')"
      :visible.sync="dialogVisible"
      width="600px"
      @close="dialogVisible = false"
    >
      <el-form ref="ruleForm" size="small" :model="ruleForm">
        <el-form-item :label="$t('collectionCenter.AnjunTrackingNumber')" prop="orderNumbers">
          <el-input
            v-model="ruleForm.orderNumbers"
            :autosize="{ minRows: 1, maxRows: 3}"
            type="textarea"
            clearable
            :placeholder="$t('collectionCenter.BatchQuery') + '，' + $t('compensationManagement.Maximum500Orders')"
          />
        </el-form-item>
        <el-form-item
          :label="$t('compensationManagement.dataRange')" prop="claimsOrdersSource"
          :rules="{ required: true, message: $t('GlobalSetObj.required'), trigger: ['blur', 'change'] }"
        >
          <el-select
            v-model="ruleForm.claimsOrdersSource"
            :placeholder="$t('GlobalSetObj.pleaseChoose')"
            clearable
            style="width: 100%"
          >
            <el-option key="1" :label="$t('compensationManagement.dispatcherIsEmpty')" :value="1" />
            <el-option key="2" :label="$t('compensationManagement.locationsAreEmpty')" :value="2" />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('GlobalSetObj.cancel') }}</el-button>
        <el-button type="primary" @click="submitBtn">{{ $t('GlobalSetObj.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import { apiOneClickDelete } from '@/api/compensationManagement/list';

export default {
  props: {
    // 勾选索赔单数据
    checkList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        claimsOrdersSource: '',
        orderNumbers: ''
      }
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.clearForm();
      }
    }
  },
  methods: {
    // 一键剔除
    oneClickDel() {
      this.dialogVisible = true;
    },
    /**
     * 确定按钮
     */
    submitBtn() {
      this.$refs.ruleForm.validate(valid => {
        const params = cloneDeep(this.ruleForm);
        if (!valid) {
          return false;
        }
        params.orderNumbers = this.sanitizeClaimsNumberList(params.orderNumbers);
        if (params.orderNumbers.length > 500) {
          this.$message({
            type: 'warning',
            message: this.$t('compensationManagement.Maximum500Orders')
          });
          return false;
        }
        params.ids = this.checkList.map(item => item.id);
        apiOneClickDelete(params).then((res) => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: this.$t('GlobalSetObj.TheOperationSucceeded')
            });
            this.dialogVisible = false;
          }
        });
      });
    },
    /**
     * 清空表单
     */
    clearForm() {
      this.$refs.ruleForm.resetFields();
    },
    sanitizeClaimsNumberList(list) {
      return (list.split('\n')).filter(item => item && item.trim()).map(items => items.replace(/\s/g, '').trim());
    }
  }
};
</script>

