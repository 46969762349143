<template>
  <div>
    <el-tabs type="border-card">
      <el-tab-pane label="地区信息">
        <region />
      </el-tab-pane>
      <el-tab-pane label="ICMS税费参照表">
        <icms />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import region from './cmp/region';
import icms from './cmp/icms';
export default {
  name: 'DistrictManagement',
  components: {
    region,
    icms
  }
};
</script>

<style scoped>
 /deep/ .el-tabs--border-card>.el-tabs__content {
  padding: 10px;
}
</style>
