var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _c(
        "el-form",
        {
          attrs: { "label-position": "top" },
          model: {
            value: _vm.queryForm,
            callback: function ($$v) {
              _vm.queryForm = $$v
            },
            expression: "queryForm",
          },
        },
        [
          _c(
            "el-row",
            {
              staticStyle: { "flex-wrap": "wrap", "align-items": "center" },
              attrs: { type: "flex", justify: "space-between" },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("userManage.time") } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          clearable: false,
                          "default-time": ["00:00:00", "23:59:59"],
                          "end-placeholder": _vm.$t("collectionCenter.EndTime"),
                          "range-separator": "-",
                          "start-placeholder": _vm.$t(
                            "collectionCenter.StartTime"
                          ),
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "picker-options": _vm.pickerOptions,
                        },
                        on: { change: _vm.dateChange },
                        model: {
                          value: _vm.timeValue,
                          callback: function ($$v) {
                            _vm.timeValue = $$v
                          },
                          expression: "timeValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.region") + "：",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t("collectionCenter.tudo"),
                            clearable: "",
                            filterable: "",
                          },
                          on: { change: _vm.getSiteList },
                          model: {
                            value: _vm.queryForm.region,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "region", $$v)
                            },
                            expression: "queryForm.region",
                          },
                        },
                        _vm._l(_vm.bigAreaList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.transferCenter"),
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("collectionCenter.tudo"),
                            clearable: "",
                            "collapse-tags": "",
                            filterable: "",
                            multiple: "",
                          },
                          on: { change: _vm.checkChooseRegion },
                          model: {
                            value: _vm.queryForm.transferCenterIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "transferCenterIds", $$v)
                            },
                            expression: "queryForm.transferCenterIds",
                          },
                        },
                        _vm._l(_vm.siteList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "btn:order-center:complete-link:find",
                          expression: "'btn:order-center:complete-link:find'",
                          arg: "remove",
                        },
                      ],
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.searchClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("newOrder.search")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "btn:order-center:complete-link:reset",
                          expression: "'btn:order-center:complete-link:reset'",
                          arg: "remove",
                        },
                      ],
                      attrs: { size: "mini" },
                      on: { click: _vm.resetClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", [
        _c("div", [_vm._v(_vm._s(_vm.$t("collectionCenter.quantityUnit")))]),
        _c("div", { staticClass: "sumBox" }, [
          _c("div", { staticClass: "s_box" }, [
            _c("span", { staticClass: "sb_top top1" }, [
              _vm._v(_vm._s(_vm.$t("collectionCenter.endTakeUp"))),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.receivable")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p1.plannedCount)
              ),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.actualCollection")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p1.actualCount)
              ),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.notLanShou")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p1.difference)
              ),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("changepwd.collectMissScan")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p1.suspectedMissedCount)
              ),
            ]),
          ]),
          _c("div", { staticClass: "s_box" }, [
            _c("span", { staticClass: "sb_top top2" }, [
              _vm._v(_vm._s(_vm.$t("collectionCenter.collectLoading"))),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.shouldLoading")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p2.plannedCount)
              ),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.actualLoading")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p2.actualCount)
              ),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.differenceValue")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p2.difference)
              ),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("changepwd.loadMissScan")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p2.suspectedMissedCount)
              ),
            ]),
          ]),
          _c("div", { staticClass: "s_box" }, [
            _c("span", { staticClass: "sb_top top3" }, [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.firstLevelSortingCenter"))
              ),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("navbar.arrivalTicketCount")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p3.arrivalCount)
              ),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("navbar.shipmentTicketCount")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p3.departureCount)
              ),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.differenceValue")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p3.difference)
              ),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.suspectedMissedSweep")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p3.suspectedMissedCount)
              ),
            ]),
          ]),
          _c("div", { staticClass: "s_box" }, [
            _c("span", { staticClass: "sb_top top5" }, [
              _vm._v(_vm._s(_vm.$t("collectionCenter.deliveryLoading"))),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.shouldLoading")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p4.plannedCount)
              ),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.actualLoading")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p4.actualCount)
              ),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.differenceValue")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p4.difference)
              ),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("changepwd.loadMissScan")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p4.suspectedMissedCount)
              ),
            ]),
          ]),
          _c("div", { staticClass: "s_box" }, [
            _c("span", { staticClass: "sb_top top4" }, [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.secondThirdSortingCenters"))
              ),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("navbar.unloadTicketCount")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p5.unloadCount)
              ),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("customerManagements.loaderArrived")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p5.loadCount)
              ),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.differenceValue")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p5.difference)
              ),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(
                  _vm.$t("collectionCenter.suspectedUnloadingMissingSweep")
                ) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p5.unloadMissedScan)
              ),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("changepwd.loadMissScan")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p5.loadMissedScan)
              ),
            ]),
          ]),
          _c("div", { staticClass: "s_box" }, [
            _c("span", { staticClass: "sb_top top6" }, [
              _vm._v(_vm._s(_vm.$t("collectionCenter.nodeOperationData"))),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.networkEntry")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p6.inboundCount)
              ),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("orderCenterCont.dispatchTicketCount")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p6.dispatchCount)
              ),
            ]),
            _c("div", { staticClass: "sumDiv" }, [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.signerNumber")) +
                  "：" +
                  _vm._s(_vm.wholeProcessTotalsList.p6.signCount)
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "el-row",
        [
          _c("div", { staticClass: "topTitle" }, [
            _vm._v(_vm._s(_vm.$t("orderCenterCont.questionItemOperationData"))),
          ]),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    "max-height": 600,
                    border: "",
                    data: _vm.tableData_1,
                    "cell-style": _vm.tableCellStyle,
                  },
                },
                _vm._l(_vm.columns, function (column, index) {
                  return _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: column.prop,
                      label: column.label,
                      width: column.width,
                      align: "center",
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    "max-height": 600,
                    border: "",
                    data: _vm.tableData_2,
                    "cell-style": _vm.tableCellStyle,
                  },
                },
                _vm._l(_vm.columns, function (column, index) {
                  return _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: column.prop,
                      label: column.label,
                      width: column.width,
                      align: "center",
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c("div", { staticClass: "topTitle" }, [
            _vm._v(_vm._s(_vm.$t("collectionCenter.networkProblemRgtTotal"))),
          ]),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    "max-height": 600,
                    border: "",
                    data: _vm.tableData_3,
                    "cell-style": _vm.tableCellStyle,
                  },
                },
                _vm._l(_vm.columns_2, function (column, index) {
                  return _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: column.prop,
                      label: column.label,
                      width: column.width,
                      align: "center",
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    "max-height": 600,
                    border: "",
                    data: _vm.tableData_4,
                    "cell-style": _vm.tableCellStyle,
                  },
                },
                _vm._l(_vm.columns_2, function (column, index) {
                  return _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: column.prop,
                      label: column.label,
                      width: column.width,
                      align: "center",
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }