var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        "append-to-body": true,
        title: _vm.$t("receivablePayable.addingCosts"),
        visible: _vm.isVisible,
        "custom-class": "drawerClass",
        direction: "ltr",
        size: "80%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "content-box" }, [
        _c(
          "div",
          { staticClass: "details" },
          [
            _c(
              "el-card",
              {
                staticClass: "mb5",
                attrs: { header: _vm.$t("basicData.订单信息") },
              },
              [
                _c(
                  "div",
                  [
                    _c("span", { staticClass: "label" }, [
                      _c("span", { staticStyle: { color: "#f56c6c" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("userManage.time") + "：")),
                    ]),
                    _c("el-date-picker", {
                      attrs: {
                        size: "small",
                        type: "daterange",
                        "picker-options": _vm.pickerOptions,
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "-",
                        "start-placeholder": _vm.$t("GlobalSetObj.startDate"),
                        "end-placeholder": _vm.$t("GlobalSetObj.endDate"),
                      },
                      model: {
                        value: _vm.dateRange,
                        callback: function ($$v) {
                          _vm.dateRange = $$v
                        },
                        expression: "dateRange",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-card",
              { attrs: { header: _vm.$t("receivablePayable.FeeBreakdown") } },
              [
                _c("FeeTable", {
                  ref: "detailsTableRef",
                  attrs: { "add-type": 1, list: _vm.addBatchFeeList },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "btn" },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "permit",
                    rawName: "v-permit:remove",
                    value: "btn:finance:costManagement:costsFee:Edit",
                    expression: "'btn:finance:costManagement:costsFee:Edit'",
                    arg: "remove",
                  },
                ],
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.submit },
              },
              [_vm._v(_vm._s(_vm.$t("basicData.Save")))]
            ),
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.onClose } },
              [_vm._v(_vm._s(_vm.$t("orderCenterCont.ShutDown")))]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }