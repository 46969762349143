<template>
  <el-card class="box-card">
    <div style="margin-bottom: 15px; font-weight: bold">网点时效</div>
    <div id="main01" style="height: 230px; width: 100%" />
  </el-card>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'NetworkTimeValidity',
  props: {
    siteAgingVOS: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      chartDom: '',
      myChart: '',
      sourceList: []
    };
  },
  mounted() {
    // 获取网点时效数据
    this.getSiteTimeValidty();
    // 添加 resize 事件监听器
    window.addEventListener('resize', this.resizeHandler);
    this.getData();
  },
  methods: {
    getSiteTimeValidty() {
      console.log(this.siteAgingVOS, 'this.siteAgingVOS123213');
      this.siteAgingVOS.forEach((item) => {
        this.sourceList.push([
          item.deliverStationName,
          item.zeroDayPer,
          item.firstDayPer,
          item.secondDayPer
        ]);
      });
      this.sourceList.unshift(['product', '24小时签收率', '48小时签收率', '72小时签收率']);
      console.log(this.sourceList, 'this.sourceList');
    },
    // 渲染折线图
    getData() {
      const chartDom = document.getElementById('main01');
      this.myChart = echarts.init(chartDom);
      const option = {
        legend: {},
        tooltip: {},
        dataset: {
          source: this.sourceList
        },
        xAxis: { type: 'category' },
        yAxis: {
          // 设置 y 轴标签为百分比格式
          axisLabel: {
            formatter: '{value}%'
          }
        },
        series: [
          {
            type: 'bar',
            barWidth: '45',
            label: {
              show: true,
              position: 'top',
              formatter: function(params) {
                console.log(params.value, 'params.value');
                const value = params.value;
                const arr = [];
                value.forEach((item) => {
                  if (!isNaN(item)) {
                    const str = item + '%';
                    arr.push(str);
                  } else {
                    arr.push(item);
                  }
                });
                return arr[1];
              }
            }
          },
          {
            type: 'bar',
            barWidth: '45',
            label: {
              show: true,
              position: 'top',
              formatter: function(params) {
                console.log(params.value, 'params.value');
                const value = params.value;
                const arr = [];
                value.forEach((item) => {
                  if (!isNaN(item)) {
                    const str = item + '%';
                    arr.push(str);
                  } else {
                    arr.push(item);
                  }
                });
                return arr[2];
              }
            }
          },
          {
            type: 'bar',
            barWidth: '45',
            label: {
              show: true,
              position: 'top',
              formatter: function(params) {
                console.log(params.value, 'params.value');
                const value = params.value;
                const arr = [];
                value.forEach((item) => {
                  if (!isNaN(item)) {
                    const str = item + '%';
                    arr.push(str);
                  } else {
                    arr.push(item);
                  }
                });
                return arr[3];
              }
            }
          }
        ]
      };
      option && this.myChart.setOption(option);
    },
    resizeHandler() {
      this.myChart.resize();
    }
  }
};
</script>

<style scoped></style>
