export const getPermission = (type) => {
  let permission = '';
  switch (type) {
    case 1: // 安俊
      permission = 'btn:finance:payableManagement:hut:addEdit';
      break;
    case 2: // 第三方
      permission = 'btn:finance:payableManagement:threeCollect:addEdit';
      break;
    case 3: // AJD
      permission = 'btn:finance:payableManagement:ajd:addEdit';
      break;
    case 4: // DSP
      permission = 'btn:finance:payableManagement:dsp:addEdit';
      break;
    default: // 运输
      permission = 'btn:finance:payableManagement:transportation:addEdit';
      break;
  }
  return permission;
};
