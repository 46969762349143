<template>
  <div class="app-container">
    <el-card class="box-card mb5">
      <el-form
        ref="form" :model="queryForm" label-width="auto"
        size="small"
        label-position="top"
      >
        <div class="searchContainer">
          <el-row :gutter="24">
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <!--账单批次号-->
                <el-form-item :label="$t('receivablePayable.BillingBatchNumber')">
                  <el-input v-model="queryForm.params.billBatchNumber" :placeholder="$t('operationCenter.PleaseEnterTheContent')" />
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <!--对账状态-->
                <el-form-item :label="$t('receivablePayable.ReconciliationStatus')">
                  <el-select
                    v-model="queryForm.params.auditStatus"
                    filterable
                    multiple
                    :placeholder="$t('operationCenter.PleaseSelect')"
                    clearable
                    style="width: 100%;"
                  >
                    <!--待审核-->
                    <el-option key="1" :label="$t('receivablePayable.ToBeReviewed')" :value="0" />
                    <!--已通过-->
                    <el-option key="2" :label="$t('receivablePayable.Passed')" :value="1" />
                    <!--未通过-->
                    <el-option key="3" :label="$t('receivablePayable.DoesNotPass')" :value="2" />
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <!--成本主体-->
                <el-form-item :label="$t('receivablePayable.costumer')">
                  <el-select
                    v-model="queryForm.params.providerId"
                    filterable
                    :placeholder="$t('operationCenter.PleaseSelect')"
                    clearable
                    style="width: 100%;"
                    @change="selectedProvider"
                  >
                    <el-option
                      v-for="item in providerList"
                      :key="item.id"
                      :label="item.providerBody + '（' + item.providerName + '）(' + item.code + ')'"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <!--            <el-col :span="6">-->
            <!--              <div class="grid-content bg-purple">-->
            <!--                &lt;!&ndash;网点&ndash;&gt;-->
            <!--                <el-form-item :label="$t('receivablePayable.costOutlets') + '：'">-->
            <!--                  <QuerySiteCmp-->
            <!--                    :val="queryForm.params.siteId"-->
            <!--                    :clearable="true"-->
            <!--                    :provider-id="queryForm.params.providerId"-->
            <!--                    @change="val => queryForm.params.siteId = val"-->
            <!--                  />-->
            <!--                </el-form-item>-->
            <!--              </div>-->
            <!--            </el-col>-->
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <!--业务类型-->
                <el-form-item :label="$t('collectionCenter.businessType')">
                  <el-select
                    v-model="queryForm.params.businessType"
                    filterable
                    :placeholder="$t('operationCenter.PleaseSelect')"
                    clearable
                    style="width: 100%;"
                  >
                    <!--全部-->
                    <el-option key="0" :label="$t('basicData.all')" :value="null" />
                    <el-option
                      v-for="item in BUSINESS_TYPE_TWO"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-form>

      <el-button
        type="primary"
        size="small"
        icon="el-icon-search"
        @click="searchResources"
      >
        {{ $t('operationCenter.Procurar') }}
      </el-button>

      <el-button
        size="small"
        icon="el-icon-refresh"
        @click="reset"
      >
        {{ $t('operationCenter.reset') }}
      </el-button>
    </el-card>

    <el-card class="box-card">
      <div style="margin-bottom: 10px;">
        <div class="btn-group">
          <div class="btns">
            <!--审核-->
            <el-button
              v-permit:remove="'btn:finance:costManagement:costAudit:Edit'"
              size="small"
              type="primary"
              :disabled="!selectData.length"
              @click="operationBtn"
            >{{ $t('receivablePayable.examine') }}</el-button>

            <!--自定义栏目-->

            <TableStatisticiansCmp :table-data="tableData" />
          </div>

          <!-- 导出 -->
          <!--          <el-button-->
          <!--            v-permit:remove="" size="small" type="info"-->
          <!--            @click="onExport"-->
          <!--          >{{ $t('CenterForSorting.export') }}<i class="el-icon-download el-icon&#45;&#45;right" /></el-button>-->
        </div>

      </div>

      <div class="pending">
        <el-table
          ref="elTable"
          :data="tableData"
          class="table-height"
          :height="tableHeightRow.height"
          border
          @header-dragend="headerWidthChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          />
          <el-table-column :label="$t('operationCenter.ind')" width="60px" align="center">
            <template slot-scope="scope">
              <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(item, ind) in TABLE_AUDITS_HEADER"
            :key="ind"
            :prop="item.prop"
            :label="item.label"
            :align="item.align"
            :min-width="item.width"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <!--账单批次号-->
              <div v-if="item.prop === 'billBatchNumber'">
                <div class="batchNumber" @click="showBillBatchNumber(scope.row)">{{ scope.row.billBatchNumber }}</div>
              </div>
              <!-- 账单状态 -->
              <div v-else-if="item.prop === 'billStatusValue'">
                <!--待审核-->
                <span v-if="scope.row.billStatusValue === 0">{{ $t('receivablePayable.ToBeReviewed') }}</span>
                <!--已通过-->
                <span v-else-if="scope.row.billStatusValue === 1">{{ $t('receivablePayable.Passed') }}</span>
                <!--未通过-->
                <span v-else-if="scope.row.billStatusValue === 2">{{ $t('receivablePayable.DoesNotPass') }}</span>
              </div>
              <!-- 业务类型 -->
              <div v-else-if="item.prop === 'businessType'">
                {{ BUSINESS_TYPE_TWO[scope.row.businessType] && BUSINESS_TYPE_TWO[scope.row.businessType].label || '' }}
              </div>
              <div v-else>{{ scope.row[item.prop] }}</div>
            </template>
          </el-table-column>
          <!--操作-->
          <el-table-column
            :label="$t('basicData.operate')"
            align="center"
            width="140"
            fixed="right"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div
                v-if="[0,2].includes(scope.row.auditStatus) && $store.getters.button_permissions.includes('btn:finance:costManagement:costAudit:revoke')"
              >
                <el-button
                  type="danger"
                  size="mini"
                  @click="recall(scope.row)"
                >{{ $t('receivablePayable.withdraw') }}</el-button><!--撤回-->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页工具 -->
        <div class="pagination">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryForm.pageNumber"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size.sync="queryForm.pageSize"
            :total="total"
            background
            style="margin: 10px 0"
            @current-change="getCurrentPage"
            @size-change="getSizePage"
          />
        </div>
      </div>
    </el-card>

    <!--  对账批次详情  -->
    <el-drawer
      :title="$t('receivablePayable.ReconcileBatchDetails') + '-' + batchNumber"
      :append-to-body="true"
      :visible.sync="showDetail"
      direction="ltr"
      custom-class="drawerClass"
      :label="$t('receivablePayable.ReconcileBatchDetails')"
      size="80%"
    >
      <batch-number-detail
        :key="batchNumberKey"
        :batch-number="batchNumber"
        :bill-batch-data="billBatchData"
        :type="1"
        @closeDetail="closeDetail"
      />
    </el-drawer>

    <!--审核-->
    <el-dialog
      :title="$t('receivablePayable.examine')"
      :visible.sync="showExamine"
      width="600px"
    >
      <div>
        <div>
          <el-form
            :key="showExamine" ref="examineForm" :model="examineForm"
            label-width="auto"
          >
            <!--审核-->
            <el-form-item
              :label="$t('receivablePayable.examine')"
              prop="examine"
              :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-radio-group v-model="examineForm.examine">
                <div style="margin-bottom: 5px;">
                  <!--通过（多条数据合并生成一个收款单）-->
                  <el-radio :label="0">{{ $t('receivablePayable.PassMultipleDataMergingToGenerateOneReceipt') }}</el-radio>
                </div>
                <div style="margin-bottom: 5px;">
                  <!--通过（多条数据生成多个收款单）-->
                  <el-radio :label="1">{{ $t('receivablePayable.GenerateMultipleReceiptsThrough') }}</el-radio>
                </div>
                <div>
                  <!--不通过-->
                  <el-radio :label="2">{{ $t('receivablePayable.DoesNotPass') }}</el-radio>
                </div>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              :label="$t('receivablePayable.remark')"
              prop="remark"
              :rules="{ required: examineForm.examine === 2, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-input v-model="examineForm.remark" type="textarea" :rows="6" />
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="examine(1)">{{ $t('basicData.Cancel') }}</el-button>
        <el-button type="primary" @click="examine(2)">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import {
  getCostSiteExpenseBillAudit,
  getCostSiteExpenseBillPage,
  getCostSiteExpenseBillWithdraw
} from '@/api/finance/costManagement';
import { apiProviderPage } from '@/api/finance/basicData';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { BUSINESS_TYPE_TWO } from '@/views/finance/utils.js';
import { TABLE_AUDITS_HEADER } from '../enums.js';
// import QuerySiteCmp from '@/views/finance/cmp/Query/QuerySiteCmp.vue';

export default {
  name: 'CostAudit',

  components: {
    // QuerySiteCmp,
    batchNumberDetail: () => import('@/views/finance/costManagement/components/batchDetail.vue'),
    TableStatisticiansCmp: () => import('@/views/finance/costManagement/components/tableStatisticiansCmp.vue')
  },
  mixins: [estimateTableHeight],
  data() {
    return {
      TABLE_AUDITS_HEADER,
      BUSINESS_TYPE_TWO, // 业务类型
      queryForm: {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 10,
        params: {
          billBatchNumber: '', // 对账单批次号
          auditStatus: [0], // 对账状态
          // siteId: '', // 网点
          providerId: '', // 主体
          businessType: null // 业务类型
        }
      },
      createUser: [],
      showDetail: false,
      tableData: [],
      total: 0,
      batchNumberKey: new Date().getTime(),
      batchNumber: '',
      billBatchData: {},
      providerList: [],
      selectData: [],
      showExamine: false,
      examineForm: {
        examine: 2,
        remark: ''
      }
    };
  },
  computed: {},
  created() {
    this.createUser = getUserIdAndNameObj();
    // 获取应付主体
    this.getProviderPage();
    // 获取账单审核列表
    this.getExaminePage();
  },
  mounted() {
    this.bus.$on('pendingData', () => {
      this.getExaminePage();
    });
  },
  methods: {
    // 翻页
    getCurrentPage(val) {
      this.queryForm.pageNumber = val;
      this.getExaminePage();
    },
    // 每页显示数量变化
    getSizePage(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getExaminePage();
    },
    // 主体选项变化
    selectedProvider() {
      // this.queryForm.params.siteId = '';
    },
    // 撤回
    recall(row) {
      this.$confirm(this.$i18n.t('receivablePayable.AreYouSureYouWantToWithdraw'), this.$i18n.t('basicData.tips'), { // '确定要撤回?'
        confirmButtonText: this.$i18n.t('basicData.determine'), // '确定',
        cancelButtonText: this.$i18n.t('basicData.Cancel'), // '取消',
        type: 'warning'
      }).then(() => {
        getCostSiteExpenseBillWithdraw(row.id).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded'));
            this.getExaminePage();
          }
        });
      });
    },
    // 表头宽度变化
    headerWidthChange() {
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
      });
    },
    // 关闭详情
    closeDetail() {
      this.showDetail = false;
    },

    // 获取主体
    getProviderPage() {
      const param = {
        pageSize: 9999999,
        pageNumber: 1,
        params: {
          state: 1
        }
      };
      apiProviderPage(param, false).then(res => {
        if (res.status === 'OK') {
          this.providerList = res.data.records;
        }
      });
    },
    // 获取列表数据
    getExaminePage() {
      getCostSiteExpenseBillPage(this.queryForm).then(res => {
        if (res.code === 200) {
          this.tableData = (res.data.records || []).map(item => {
            return Object.freeze(item);
          });
          this.total = res.data.total;
        }
      }).catch(err => {
        console.error(err);
      });
    },

    // 列表数据勾选
    handleSelectionChange(arr) {
      this.selectData = arr;
    },
    // 获取对账批次详情
    showBillBatchNumber(data) {
      this.batchNumber = data.billBatchNumber;
      this.billBatchData = data;
      this.showDetail = true;
      this.batchNumberKey = new Date().getTime();
    },
    // 搜索
    searchResources() {
      this.queryForm.pageNumber = 1;
      this.getExaminePage();
    },
    // 重置
    reset() {
      this.queryForm.pageNumber = 1;
      this.queryForm.params = {
        billBatchNumber: '', // 对账单批次号
        auditStatus: [0], // 对账状态
        // siteId: '', // 网点
        providerId: '', // 主体
        businessType: null // 业务类型
      };
      this.getExaminePage();
    },
    /**
     * 审核
     */
    operationBtn() {
      if (this.selectData.length === 0) {
        this.$message.warning(this.$i18n.t('receivablePayable.SelectAtLeastOnePieceOfData')); // 最少要选择一条数据
        return;
      }
      this.showExamine = true;
    },
    // 审核
    examine(type) {
      if (type === 2) {
        const ids = [];
        const businessTypes = [];
        this.selectData.map(item => {
          if (businessTypes.includes(item.businessType) === false) {
            businessTypes.push(item.businessType);
          }
          ids.push(item.billBatchNumber);
        });
        if (this.examineForm.examine === 0) {
          if (businessTypes.length > 1) {
            this.$message.warning(this.$t('receivablePayable.OnlyDataSameServiceTypeCanSelected'));
            return;
          }
        }
        const param = {
          billBatchNumbers: ids,
          operationType: this.examineForm.examine,
          auditReason: this.examineForm.remark
        };
        this.$refs['examineForm'].validate((valid) => {
          if (valid) {
            getCostSiteExpenseBillAudit(param).then(res => {
              if (res.code === 200) {
                this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
                this.getExaminePage();
                this.showExamine = false;
                this.examineForm.examine = 2;
                this.examineForm.remark = '';
              }
            }).catch(err => {
              console.error(err);
            });
          }
        });
      } else {
        this.showExamine = false;
        this.examineForm.examine = 2;
        this.examineForm.remark = '';
      }
    }

    // 导出
    // onExport() {
    //   const { selectData } = this;
    //   // const query = cloneDeep(queryForm.params);
    //   const { params } = this.getQueryData();
    //
    //   // 指定导出
    //   if (selectData.length > 0) {
    //     params.ids = selectData.map(item => item.id);
    //   }
    //
    //   apiPayableSiteBillExport(params).then(res => {
    //     goResourceTaskList();
    //   }).catch(err => {
    //     console.error(err);
    //   });
    // }
  }
};
</script>

<style scoped>
.batchNumber {
  color: #007efd;
  cursor: pointer;
}

.app-container .searchContainer /deep/ .el-date-editor .el-range-separator {
  line-height: 25px;
}

.btn-group {
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btns {
  display: flex;
  align-items: center;
}

</style>
<style>
.columnTree .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  border-color: #aaa;
}
.columnTree .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #aaa;
}
.pending .el-table tr.background {
  background-color: #FF0000!important;
  color: #FFF;
}
.pending .el-table tr.background:hover td {
  background-color: #FF0000!important;
  color: #FFF;
}

</style>
