var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.queryForm, "label-position": "top" },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "numberClassSelect" },
                        [
                          _c(
                            "template",
                            { slot: "label" },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "orderDetails.pleaseChoose"
                                    ),
                                  },
                                  on: { change: _vm.handleChangeLable },
                                  model: {
                                    value: _vm.selectionType,
                                    callback: function ($$v) {
                                      _vm.selectionType = $$v
                                    },
                                    expression: "selectionType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: "1",
                                    attrs: {
                                      label:
                                        _vm.$t("newOrder.waybillNumber") +
                                        "/" +
                                        _vm.$t("GlobalSetObj.serviceLogistics"),
                                      value: 1,
                                    },
                                  }),
                                  _c("el-option", {
                                    key: "2",
                                    attrs: {
                                      label: _vm.$t(
                                        "orderCenterCont.TheOrderNumberCustomer"
                                      ),
                                      value: 2,
                                    },
                                  }),
                                  _c("el-option", {
                                    key: "3",
                                    attrs: {
                                      label: _vm.$t(
                                        "placeOrder.customerNumber"
                                      ),
                                      value: 3,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "searchOrder" },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "orderDetails.pleaseInput"
                                  ),
                                  clearable: "",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.searchFun(1)
                                  },
                                },
                                model: {
                                  value: _vm.queryForm.params.orderNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "orderNumber",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "queryForm.params.orderNumber",
                                },
                              }),
                              _c(
                                "div",
                                { staticStyle: { width: "40px" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "chaxunBtn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.searchFun(1)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont icon-chaxun",
                                        staticStyle: { "font-size": "24px" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { width: "38px" } },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "bottom-end",
                                        trigger: "manual",
                                      },
                                      model: {
                                        value: _vm.showNumList,
                                        callback: function ($$v) {
                                          _vm.showNumList = $$v
                                        },
                                        expression: "showNumList",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { width: "300px" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: _vm.$t(
                                                "newOrder.PreciseSearch"
                                              ),
                                              rows: 10,
                                              type: "textarea",
                                            },
                                            model: {
                                              value: _vm.searchNumberStr,
                                              callback: function ($$v) {
                                                _vm.searchNumberStr = $$v
                                              },
                                              expression: "searchNumberStr",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-top": "10px",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { size: "small" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.clearSearchNum()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("newOrder.empty")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    float: "right",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: { size: "small" },
                                                      on: {
                                                        click: _vm.openNumList,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "orderExport.close"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        plain: "",
                                                        size: "small",
                                                        type: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.searchFun(
                                                            2
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "newOrder.search"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "btnIcon",
                                          attrs: { slot: "reference" },
                                          on: { click: _vm.openText },
                                          slot: "reference",
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-liebiao1",
                                            staticStyle: {
                                              "font-size": "24px",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "numberClassSelect" },
                        [
                          _c(
                            "template",
                            { slot: "label" },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "operationCenter.PleaseSelect"
                                    ),
                                  },
                                  model: {
                                    value: _vm.queryForm.params.timeField,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "timeField",
                                        $$v
                                      )
                                    },
                                    expression: "queryForm.params.timeField",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: "1",
                                    attrs: {
                                      label: _vm.$t(
                                        "GlobalSetObj.CreationTime"
                                      ),
                                      value: 1,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "default-time": ["00:00:00", "23:59:59"],
                              "end-placeholder": _vm.$t(
                                "operationCenter.endDate"
                              ),
                              "range-separator": _vm.$t("operationCenter.to"),
                              "start-placeholder": _vm.$t(
                                "operationCenter.startDate"
                              ),
                              type: "datetimerange",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.queryDate,
                              callback: function ($$v) {
                                _vm.queryDate = $$v
                              },
                              expression: "queryDate",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { name: "customerId" } },
                        [
                          _c("template", { slot: "label" }, [
                            _c("p", { staticStyle: { margin: "6px 0px" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("receivablePayable.customer")) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "spareNumberPoo.Only10AreDisplayed"
                                ),
                                "remote-method": _vm.getCustomerPage,
                                clearable: "",
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.getCustomerPage()
                                },
                              },
                              model: {
                                value: _vm.queryForm.params.customerId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "customerId",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.customerId",
                              },
                            },
                            _vm._l(_vm.customerList, function (item, ind) {
                              return _c("el-option", {
                                key: ind,
                                attrs: {
                                  label:
                                    item.customerAlias +
                                    "(" +
                                    item.username +
                                    ")",
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-top": "12px" },
                          attrs: {
                            label: _vm.$t("orderCenterCont.WhetherSuccessful"),
                            name: "customerId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              model: {
                                value: _vm.queryForm.params.state,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm.params, "state", $$v)
                                },
                                expression: "queryForm.params.state",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("GlobalSetObj.all"),
                                  value: null,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("GlobalSetObj.yes"),
                                  value: 1,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("GlobalSetObj.No"),
                                  value: 0,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.searchNumberList.length > 0
                ? _c("el-row", [
                    _c(
                      "div",
                      { staticClass: "searchNum" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: { effect: "dark", placement: "bottom" },
                          },
                          [
                            _c("template", { slot: "content" }, [
                              _c("pre", [_vm._v(_vm._s(_vm.searchNumberStr))]),
                            ]),
                            _c("div", { staticClass: "searchNumWarp" }, [
                              _c("div", { staticClass: "wordBreak" }, [
                                _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.searchNameObj.label) +
                                      "： " +
                                      _vm._s(_vm.searchNumberList.join("，")) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "clearSearchNum",
                            on: {
                              click: function ($event) {
                                return _vm.clearSearchNum()
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-close" })]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-search", size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.searchResources(1)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("operationCenter.Procurar")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-refresh-left", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.searchResources(2)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("operationCenter.reset")) + " ")]
          ),
        ],
        1
      ),
      _c("el-card", [
        _c(
          "div",
          [
            _c(
              "el-table",
              { attrs: { data: _vm.tableData, border: "" } },
              [
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("operationCenter.ind"),
                    align: "center",
                    width: "60px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                (_vm.queryForm.pageNumber - 1) *
                                  _vm.queryForm.pageSize +
                                  scope.$index +
                                  1
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("orderCenterCont.TheOrderNumber"),
                    "show-overflow-tooltip": true,
                    align: "left",
                    "min-width": "160",
                    prop: "orderNumber",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "top",
                                    "popper-class": "copy",
                                    trigger: "hover",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        color: "black",
                                        "font-size": "12px",
                                        padding: "2px",
                                      },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copy(scope.row.orderNumber)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("orderDetails.copy")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "is-click-column",
                                      attrs: { slot: "reference" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.operationBtn(scope.row)
                                        },
                                      },
                                      slot: "reference",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(scope.row.orderNumber) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("newOrder.waybillNumber"),
                    "show-overflow-tooltip": true,
                    align: "left",
                    "min-width": "160",
                    prop: "waybillNumber",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "top",
                                    "popper-class": "copy",
                                    trigger: "hover",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        color: "black",
                                        "font-size": "12px",
                                        padding: "2px",
                                      },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copy(
                                            scope.row.waybillNumber
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("orderDetails.copy")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "reference" },
                                      slot: "reference",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(scope.row.waybillNumber) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("GlobalSetObj.serviceLogistics"),
                    "min-width": "160",
                    prop: "logisticsNumber",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "top",
                                    "popper-class": "copy",
                                    trigger: "hover",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        color: "black",
                                        "font-size": "12px",
                                        padding: "2px",
                                      },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copy(
                                            scope.row.logisticsNumber
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("orderDetails.copy")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "reference" },
                                      slot: "reference",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(scope.row.logisticsNumber) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("operationCenter.client"),
                    "show-overflow-tooltip": true,
                    align: "left",
                    "min-width": "160",
                    prop: "customerName",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [_vm._v(_vm._s(scope.row.customerName))]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("placeOrder.customerNumber"),
                    "show-overflow-tooltip": true,
                    align: "left",
                    "min-width": "160",
                    prop: "customerNumber",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [_vm._v(_vm._s(scope.row.customerNumber))]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("orderCenterCont.WhetherSuccessful"),
                    "show-overflow-tooltip": true,
                    align: "center",
                    "min-width": "120",
                    prop: "state",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.state == 0
                            ? _c("el-tag", { attrs: { type: "danger" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("operationCenter.fail")) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          scope.row.state == 1
                            ? _c("el-tag", { attrs: { type: "success" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("operationCenter.succeed")) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("GlobalSetObj.ReasonForFailure"),
                    prop: "failureMessage",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("orderCenterCont.CreationTime"),
                    "show-overflow-tooltip": true,
                    align: "left",
                    "min-width": "180",
                    prop: "createTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("orderCenterCont.AnjunDatagram"),
                    "show-overflow-tooltip": false,
                    align: "left",
                    "min-width": "200",
                    prop: "dealedRequestParams",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-tooltip", { attrs: { placement: "top" } }, [
                            _c(
                              "pre",
                              {
                                staticStyle: {
                                  "max-height": "400px",
                                  overflow: "auto",
                                },
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              [
                                _vm._v(
                                  "                " +
                                    _vm._s(scope.row.responseParams) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "paramsStyle" }, [
                              _vm._v(_vm._s(scope.row.responseParams)),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("orderCenterCont.CustomerDatagram"),
                    "show-overflow-tooltip": false,
                    align: "left",
                    "min-width": "200",
                    prop: "requestParams",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-tooltip", { attrs: { placement: "top" } }, [
                            _c(
                              "pre",
                              {
                                staticStyle: {
                                  "max-height": "400px",
                                  overflow: "auto",
                                },
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              [
                                _vm._v(
                                  "                " +
                                    _vm._s(scope.row.requestParams) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "paramsStyle" }, [
                              _vm._v(_vm._s(scope.row.requestParams)),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("el-pagination", {
              staticStyle: { margin: "10px 0" },
              attrs: {
                "current-page": _vm.queryForm.pageNumber,
                "page-size": _vm.queryForm.pageSize,
                "page-sizes": [10, 20, 50, 100],
                total: _vm.total,
                background: "",
                layout: "total, sizes, prev, pager, next, jumper",
              },
              on: {
                "update:currentPage": function ($event) {
                  return _vm.$set(_vm.queryForm, "pageNumber", $event)
                },
                "update:current-page": function ($event) {
                  return _vm.$set(_vm.queryForm, "pageNumber", $event)
                },
                "update:pageSize": function ($event) {
                  return _vm.$set(_vm.queryForm, "pageSize", $event)
                },
                "update:page-size": function ($event) {
                  return _vm.$set(_vm.queryForm, "pageSize", $event)
                },
                "current-change": _vm.getCurrentTableData,
                "size-change": _vm.getSizeTableData,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            title: _vm.$t("orderCenterCont.ViewDetails"),
            visible: _vm.drawerTag,
            "custom-class": "drawerClass",
            direction: "ltr",
            size: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawerTag = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "preStyle" },
            [
              _c(
                "el-form",
                {
                  ref: "updateForm",
                  staticStyle: {
                    padding: "20px",
                    height: "calc(100vh - 68px)",
                    "overflow-y": "auto",
                  },
                  attrs: { model: _vm.updateForm },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label:
                                    _vm.$t("orderCenterCont.TheOrderNumber") +
                                    "：",
                                  prop: "orderNumber",
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.updateForm.orderNumber)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-light" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label:
                                    _vm.$t("operationCenter.client") + "：",
                                  prop: "customerName",
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.updateForm.customerName)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-light" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label:
                                    _vm.$t("orderCenterCont.RequestTime") +
                                    "：",
                                  prop: "createTime",
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.updateForm.createTime)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-light" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { "label-width": "0" } },
                              [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "orderCenterCont.AnjunParameters"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", { staticClass: "messageCon" }, [
                                  _vm.updateForm.dealedRequestParams
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                            "line-height": "25px",
                                            padding: "5px",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-copy-document",
                                            staticStyle: {
                                              "font-size": "20px",
                                              cursor: "pointer",
                                            },
                                            attrs: {
                                              title: _vm.$t(
                                                "orderCenterCont.copy"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.copy(
                                                  _vm.updateForm
                                                    .dealedRequestParams
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("pre", [
                                    _vm._v(
                                      _vm._s(_vm.updateForm.dealedRequestParams)
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-light" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { "label-width": "0" } },
                              [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "orderCenterCont.AnjunReturnsParameters"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", { staticClass: "messageCon" }, [
                                  _vm.updateForm.dealedResponseParams
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                            "line-height": "25px",
                                            padding: "5px",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-copy-document",
                                            staticStyle: {
                                              "font-size": "20px",
                                              cursor: "pointer",
                                            },
                                            attrs: {
                                              title: _vm.$t(
                                                "orderCenterCont.copy"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.copy(
                                                  _vm.updateForm
                                                    .dealedResponseParams
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("pre", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.updateForm.dealedResponseParams
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-light" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { "label-width": "0" } },
                              [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "orderCenterCont.SupplierParameters"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", { staticClass: "messageCon" }, [
                                  _vm.updateForm.originalRequestParams
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                            "line-height": "25px",
                                            padding: "5px",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-copy-document",
                                            staticStyle: {
                                              "font-size": "20px",
                                              cursor: "pointer",
                                            },
                                            attrs: {
                                              title: _vm.$t(
                                                "orderCenterCont.copy"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.copy(
                                                  _vm.updateForm
                                                    .originalRequestParams
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("pre", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.updateForm.originalRequestParams
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-light" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { "label-width": "0" } },
                              [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "orderCenterCont.SupplierReturnsParameters"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", { staticClass: "messageCon" }, [
                                  _vm.updateForm.originalResponseParams
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                            "line-height": "25px",
                                            padding: "5px",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-copy-document",
                                            staticStyle: {
                                              "font-size": "20px",
                                              cursor: "pointer",
                                            },
                                            attrs: {
                                              title: _vm.$t(
                                                "orderCenterCont.copy"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.copy(
                                                  _vm.updateForm
                                                    .originalResponseParams
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("pre", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.updateForm.originalResponseParams
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-light" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { "label-width": "0" } },
                              [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "orderCenterCont.CustomerParameters"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", { staticClass: "messageCon" }, [
                                  _vm.updateForm.requestParams
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                            "line-height": "25px",
                                            padding: "5px",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-copy-document",
                                            staticStyle: {
                                              "font-size": "20px",
                                              cursor: "pointer",
                                            },
                                            attrs: {
                                              title: _vm.$t(
                                                "orderCenterCont.copy"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.copy(
                                                  _vm.updateForm.requestParams
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("pre", [
                                    _vm._v(
                                      _vm._s(_vm.updateForm.requestParams)
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content bg-purple-light" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { "label-width": "0" } },
                              [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "orderCenterCont.CustomerReturnParameters"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", { staticClass: "messageCon" }, [
                                  _vm.updateForm.responseParams
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                            "line-height": "25px",
                                            padding: "5px",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-copy-document",
                                            staticStyle: {
                                              "font-size": "20px",
                                              cursor: "pointer",
                                            },
                                            attrs: {
                                              title: _vm.$t(
                                                "orderCenterCont.copy"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.copy(
                                                  _vm.updateForm.responseParams
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("pre", [
                                    _vm._v(
                                      _vm._s(_vm.updateForm.responseParams)
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }