var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kanban_content" },
    [
      _c("div", { staticClass: "headerTitle" }, [
        _vm._v("快递运营看板(Kanban de operacao expressa)"),
      ]),
      _c(
        "div",
        { staticStyle: { "text-align": "right", "margin-bottom": "10px" } },
        [
          _c("span", { staticStyle: { padding: "0 10px" } }, [
            _vm._v("客户名称(Nome do cliente)"),
          ]),
          _c(
            "el-select",
            {
              staticStyle: { width: "250px" },
              attrs: {
                placeholder: _vm.$t("operationCenter.PleaseSelect"),
                clearable: "",
                "collapse-tags": "",
                filterable: "",
                multiple: "",
                size: "mini",
              },
              on: { change: _vm.selectCustomer },
              model: {
                value: _vm.customerIds,
                callback: function ($$v) {
                  _vm.customerIds = $$v
                },
                expression: "customerIds",
              },
            },
            _vm._l(_vm.localUserInfo, function (item, ind) {
              return _c("el-option", {
                key: ind,
                attrs: {
                  label: item.customerAlias + "(" + item.username + ")",
                  value: item.id,
                },
              })
            }),
            1
          ),
          _c("span", { staticStyle: { padding: "0 10px" } }, [
            _vm._v("商户名称(Nome do Mercador)"),
          ]),
          _c(
            "el-select",
            {
              staticStyle: { width: "250px" },
              attrs: {
                placeholder: _vm.$t("operationCenter.PleaseSelect"),
                clearable: "",
                "collapse-tags": "",
                filterable: "",
                multiple: "",
                size: "mini",
              },
              on: { change: _vm.selectMerchant },
              model: {
                value: _vm.merchantCodes,
                callback: function ($$v) {
                  _vm.merchantCodes = $$v
                },
                expression: "merchantCodes",
              },
            },
            _vm._l(_vm.merchantNameList, function (item, ind) {
              return _c("el-option", {
                key: ind,
                attrs: { label: item.merchantName, value: item.merchantCode },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          key: _vm.keyTime,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.CustomerSingleVolumeStatistics,
            "header-cell-style": { color: "black" },
            "span-method": _vm.objectSpanMethod,
          },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: { "min-width": "150", prop: "date" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.type === "local"
                        ? _c("div", { staticClass: "cloumH1" }, [
                            _c("div", [_vm._v("本地客户")]),
                            _c("div", [_vm._v("Cliente local")]),
                          ])
                        : scope.row.type === "localTotal"
                        ? _c("div", { staticClass: "cloumH1" }, [
                            _c("div", [_vm._v("合计 Somar o total")]),
                          ])
                        : scope.row.type === "crossBorder"
                        ? _c("div", { staticClass: "cloumH1" }, [
                            _c("div", [_vm._v("跨境单量")]),
                            _c("div", [_vm._v("Clientes internacionais")]),
                          ])
                        : scope.row.type === "crossBorderTotal"
                        ? _c("div", { staticClass: "cloumH1" }, [
                            _c("div", [_vm._v("合计 Somar o total")]),
                          ])
                        : _c("div", { staticClass: "cloumH1" }, [
                            _c("div", [_vm._v("总计 Total")]),
                          ]),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", [
                  _c("div", [_vm._v(_vm._s("客户单量统计"))]),
                  _c("div", [
                    _vm._v("Estatísticas de volume unitário de clientes"),
                  ]),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "el-table-column",
            { attrs: { align: "center", label: "件量 (Número de casos)" } },
            [
              _c("el-table-column", {
                attrs: {
                  label: "客户名称 (Clinte)",
                  "min-width": "160",
                  prop: "customerName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.dateColumn,
                  "min-width": "90",
                  prop: "waybillDailyTotal",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "日环比 (Anel do dia)",
                  "min-width": "110",
                  prop: "waybillDailyGrowthRate",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            style: {
                              color: scope.row.waybillDailyGrowthRateTTT
                                ? "#009c37"
                                : "red",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.waybillDailyGrowthRate) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "月累计 (Total acumulado)",
                  "min-width": "110",
                  prop: "waybillMonthTotal",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "月日均 (Média mensal)",
                  "min-width": "110",
                  prop: "waybillMonthAvg",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "月环比 (Comparação mês a mês)",
                  "min-width": "110",
                  prop: "waybillMonthGrowthRate",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            style: {
                              color: scope.row.waybillMonthGrowthRateTTT
                                ? "#009c37"
                                : "red",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.waybillMonthGrowthRate) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { align: "center", label: "单票重量 (Peso da fatura)" } },
            [
              _c("el-table-column", {
                attrs: {
                  label:
                    "当月均单票重量 (Peso médio do bilhete único para o mês)",
                  "min-width": "150",
                  prop: "currentMonthAvgWeightPerTicket",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label:
                    "上月均单票重量 (Peso médio do bilhete único no mês anterior)",
                  "min-width": "150",
                  prop: "lastMonthAvgWeightPerTicket",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "月环比 (Comparação mês a mês)",
                  "min-width": "120",
                  prop: "weightMonthGrowthRate",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            style: {
                              color: scope.row.weightMonthGrowthRateeTTT
                                ? "#009c37"
                                : "red",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.weightMonthGrowthRate) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "15px" } },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.SingleQuantitytrendList,
                "header-cell-style": { color: "black" },
                border: "",
              },
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: { prop: "type", width: "180" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.type === "local"
                            ? _c("div", { staticClass: "cloumH1" }, [
                                _c("div", [_vm._v("本地客户单量")]),
                                _c("div", [
                                  _vm._v("Volume unitário do cliente local"),
                                ]),
                              ])
                            : scope.row.type === "crossBroder"
                            ? _c("div", { staticClass: "cloumH1" }, [
                                _c("div", [_vm._v("跨境单量")]),
                                _c("div", [
                                  _vm._v("Volume unitário transfronteiriço"),
                                ]),
                              ])
                            : _c("div", { staticClass: "cloumH1" }, [
                                _c("div", [_vm._v("总计")]),
                                _c("div", [_vm._v("Total")]),
                              ]),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("div", [_vm._v(_vm._s("单量走势"))]),
                    _c("div", [_vm._v("Tendência do volume unitário")]),
                  ]),
                ],
                2
              ),
              _c("el-table-column", {
                attrs: {
                  label: "上月平均值 (Média do mês anterior)",
                  prop: "lastMonthAvg",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "近7天平均值 (Média dos últimos 7 dias)",
                  prop: "lastSevenDaysAvg",
                },
              }),
              _vm._l(_vm.SingleQuantitytrendHeadlist, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    label: item,
                    prop: item,
                    "show-overflow-tooltip": false,
                  },
                })
              }),
              _c("el-table-column", {
                attrs: {
                  label: "月环比 (Comparação mês a mês)",
                  prop: "monthGrowthRate",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            style: {
                              color: scope.row.monthGrowthRateTTT
                                ? "#009c37"
                                : "red",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(scope.row.monthGrowthRate) + " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "日环比 (Anel do dia)",
                  prop: "dailyGrowthRate",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            style: {
                              color: scope.row.dailyGrowthRateTTT
                                ? "#009c37"
                                : "red",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(scope.row.dailyGrowthRate) + " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "15px" } },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.ResourcesKanbanList,
                "header-cell-style": { color: "black" },
                "span-method": _vm.objectSpanMethod1,
                border: "",
              },
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: { prop: "type", width: "180" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.type === 1
                            ? _c("div", { staticClass: "cloumH1" }, [
                                _c("div", [_vm._v("一级派送员")]),
                                _c("div", [_vm._v("Despachante I")]),
                              ])
                            : scope.row.type === 2
                            ? _c("div", { staticClass: "cloumH1" }, [
                                _c("div", [_vm._v("二级中转操作员")]),
                                _c("div", [_vm._v("Operador de trânsito II")]),
                              ])
                            : _c("div", { staticClass: "cloumH1" }, [
                                _c("div", [_vm._v("干支线")]),
                                _c("div", [_vm._v("linhas tronco e ramais")]),
                              ]),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("div", [_vm._v(_vm._s("资源投入"))]),
                    _c("div", [_vm._v("Entradas de recursos")]),
                  ]),
                ],
                2
              ),
              _c("el-table-column", {
                attrs: {
                  label: "资源项 (Item de recurso)",
                  prop: "source",
                  width: "180",
                },
              }),
              _vm._l(_vm.ResourcesKanbanListHeader, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    label: item.time,
                    prop: item.label,
                    "show-overflow-tooltip": false,
                  },
                })
              }),
              _c("el-table-column", {
                attrs: {
                  label: "月环比 (Comparação mês a mês)",
                  prop: "monthOnMonth",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            style: {
                              color: scope.row.monthOnMonthTTT
                                ? "#009c37"
                                : "red",
                            },
                          },
                          [_vm._v(" " + _vm._s(scope.row.monthOnMonth) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "日环比 (Anel do dia)", prop: "dayOnDay" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            style: {
                              color: scope.row.dayOnDayTTT ? "#009c37" : "red",
                            },
                          },
                          [_vm._v(" " + _vm._s(scope.row.dayOnDay) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "15px" } },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.targetAchieveKanbanList,
                "header-cell-style": { color: "black" },
                border: "",
              },
            },
            [
              _c(
                "el-table-column",
                { attrs: { "min-width": "170", prop: "target" } },
                [
                  _c("template", { slot: "header" }, [
                    _c("div", [_vm._v(_vm._s("指标达成情况"))]),
                    _c("div", [_vm._v("Realização do indicador")]),
                  ]),
                ],
                2
              ),
              _c("el-table-column", {
                attrs: {
                  label: "上月累计(Acumulado do último mês)",
                  "min-width": "170",
                  prop: "lastMonthTotal",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "本月累计(Total do mês)",
                  "min-width": "160",
                  prop: "thisMonthTotal",
                },
              }),
              _vm._l(_vm.ResourcesKanbanListHeader, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    label: item.time,
                    prop: item.label,
                    "show-overflow-tooltip": false,
                    "min-width": "100",
                  },
                })
              }),
              _c("el-table-column", {
                attrs: {
                  label: "日环比(Anel do dia)",
                  "min-width": "100",
                  prop: "dayOnDay",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            style: {
                              color: scope.row.dayOnDayTTT ? "#009c37" : "red",
                            },
                          },
                          [_vm._v(" " + _vm._s(scope.row.dayOnDay) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "重点关注网点(Foco nos pontos de venda)",
                  "min-width": "120",
                  prop: "attentionSite",
                },
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }