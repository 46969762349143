<template>
  <!-- 页面名称 -->
  <div class="msgBox">
    <div v-if="openIndx=='tab2'">
      <div style="display: flex;justify-content: space-between;align-items: center">
        <el-button
          icon="el-icon-upload2"
          size="mini"
          @click="download"
        >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出 -->
        <div style="margin-right: 70px;display: flex;justify-content: space-between;align-items: center">
          <!-- 更新时间 -->
          <span v-show="updateTime">{{ $t('GlobalSetObj.UpdateTime') }}：{{ updateTime }}</span>
          <div class="dateBox">
            <span :class="{'d1': type == 4}" @click="get2Data(4)">{{ $t('collectionCenter.beforeYesterday') }}</span><!-- 前天 -->
            <span :class="{'d1': type == 0}" @click="get2Data(0)">{{ $t('collectionCenter.yesterday') }}</span><!-- 昨日 -->
            <span :class="{'d1': type == 1}" @click="get2Data(1)">{{ $t('collectionCenter.sameDay') }}</span><!-- 当天 -->
            <span :class="{'d1': type == 2}" @click="get2Data(2)">{{ $t('collectionCenter.nearlyAWeek') }}</span><!-- 近一周 -->
            <span :class="{'d1': type == 3}" @click="get2Data(3)">{{ $t('collectionCenter.sameMonth') }}</span><!-- 当月 -->
          </div>
        </div>
      </div>
      <el-divider />
    </div>
    <el-tabs v-model="openIndx">
      <el-tab-pane :label="tableName" name="tab1">
        <el-table
          ref="ref"
          :data="clickDate"
          :max-height="600"
          border
        >
          <el-table-column label="SP-Z-H005" align="center" prop="SP-Z-H005">
            <template slot-scope="scope">
              <div style="cursor: pointer" @click="detailClick(2, 1, 'SP-Z-H005')">{{ scope.row['SP-Z-H005'] }}</div>
            </template>
          </el-table-column>
          <el-table-column label="SP-Z-H006" align="center" prop="SP-Z-H006">
            <template slot-scope="scope">
              <div style="cursor: pointer" @click="detailClick(2, 2, 'SP-Z-H006')">{{ scope.row['SP-Z-H006'] }}</div>
            </template>
          </el-table-column>
          <el-table-column label="SP-Z-H002" align="center" prop="SP-Z-H002">
            <template slot-scope="scope">
              <div style="cursor: pointer" @click="detailClick(2, 3, 'SP-Z-H002')">{{ scope.row['SP-Z-H002'] }}</div>
            </template>
          </el-table-column>
          <el-table-column label="SP-Z-H004" align="center" prop="SP-Z-H004">
            <template slot-scope="scope">
              <div style="cursor: pointer" @click="detailClick(2, 4, 'SP-Z-H004')">{{ scope.row['SP-Z-H004'] }}</div>
            </template>
          </el-table-column>
          <el-table-column label="RJ-Z-H001" align="center" prop="RJ-Z-H001">
            <template slot-scope="scope">
              <div style="cursor: pointer" @click="detailClick(2, 6, 'RJ-Z-H001')">{{ scope.row['RJ-Z-H001'] }}</div>
            </template>
          </el-table-column>
          <el-table-column label="MG-Z-H001" align="center" prop="MG-Z-H001">
            <template slot-scope="scope">
              <div style="cursor: pointer" @click="detailClick(2, 5, 'MG-Z-H001')">{{ scope.row['MG-Z-H001'] }}</div>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane v-if="openIndx == 'tab2'" :label="tabName" name="tab2">
        <el-table
          ref="ref"
          :data="tableDate"
          :max-height="600"
          border
        >
          <el-table-column
            :label="$t('collectionCenter.ind')" align="center" type="index"
            width="60"
          ><!-- 序号 -->
            <template slot-scope="scope">
              <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <!-- 车牌号 -->
          <el-table-column :label="$t('collectionCenter.licensePlateNumber')" prop="vehicleNumber" align="center" />
          <!-- 条形码 -->
          <el-table-column :label="$t('collectionCenter.Barcode')" prop="scanNumber" align="center" />
          <!-- 揽收网点 -->
          <el-table-column :label="$t('collectionCenter.LanShouSite')" prop="collectSiteName" align="center" />
          <!-- 揽收时间 -->
          <el-table-column :label="$t('collectionCenter.lanshouT')" prop="collectTime" align="center" />
          <!-- 揽收人 -->
          <el-table-column :label="$t('collectionCenter.LanShouPeople')" prop="collectUserName" align="center" />
          <!-- 卸车人 -->
          <el-table-column :label="$t('collectionCenter.unloader')" prop="unloadUserName" align="center" />
          <!-- 卸车网点 -->
          <el-table-column :label="$t('collectionCenter.unloadDot')" prop="unloadSiteName" align="center" />
          <!-- 卸车时间 -->
          <el-table-column :label="$t('collectionCenter.unloadTime')" prop="unloadTime" align="center" />
        </el-table>
        <div class="right">
          <el-pagination
            :current-page.sync="queryForm.pageNumber"
            :page-size.sync="queryForm.pageSize"
            :page-sizes="[10, 20, 50, 100, 500, 1000]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            style="margin: 10px 0"
            @current-change="getNumPage"
            @size-change="getPageSize"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  getTransAnomalyPage,
  getTransAnomalyExport
} from '@/api/lanshou';
import dayjs from 'dayjs';
export default {
  props: ['taForm', 'clickVal'],
  data() {
    return {
      // 装车信息统计列表
      queryForm: this.taForm.queryForm,
      tableName: this.taForm.tableName, // 到达***转运中心
      tabName: '', // 明细页tab名
      tableDate: [],
      total: 0,
      updateTime: this.taForm.updateTime,
      type: this.taForm.dateType,
      openIndx: 'tab1',
      clickDate: this.clickVal
    };
  },
  mounted() {
    this.default();
  },
  methods: {
    default() {
      this.openIndx = 'tab1';
    },
    detailClick(val1, val2, val3) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = 10;
      this.queryForm.params.dashboardDetailType = val1;
      this.queryForm.params.transitType = val2;
      this.openIndx = 'tab2';
      this.tabName = val3;
      this.getNumPage();
    },
    get2Data(type) {
      this.type = type;
      const d = new Date();
      const day0 = dayjs(d).subtract(1, 'day').format('YYYY-MM-DD');
      const day1 = dayjs(d).format('YYYY-MM-DD');
      const day2 = dayjs(d).subtract(6, 'day').format('YYYY-MM-DD');
      const day3 = dayjs(d).startOf('month').format('YYYY-MM-DD');
      const day4 = dayjs(d).subtract(2, 'day').format('YYYY-MM-DD');
      const day5 = dayjs(d).startOf('week').format('YYYY-MM-DD');
      let date = {};
      if (type === 4) {
        date = {
          startTime: day4,
          endTime: day4
        };
      }
      if (type === 0) {
        date = {
          startTime: day0,
          endTime: day0
        };
      }
      if (type === 1) {
        date = {
          startTime: day1,
          endTime: day1
        };
      }
      if (type === 2) {
        date = {
          startTime: day5,
          endTime: day1
        };
      }
      if (type === 3) {
        date = {
          startTime: day3,
          endTime: day1
        };
      }
      this.queryForm = JSON.parse(JSON.stringify(this.taForm.queryForm));
      this.queryForm.params.startTime = date.startTime;
      this.queryForm.params.endTime = date.endTime;

      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = 10;
      getTransAnomalyPage(this.queryForm).then(res => {
        if (res.code === 200) {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      }).catch();
    },
    getNumPage() {
      getTransAnomalyPage(this.queryForm).then(res => {
        if (res.code === 200) {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      }).catch();
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      getTransAnomalyPage(this.queryForm).then(res => {
        if (res.code === 200) {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      }).catch();
    },
    download() {
      getTransAnomalyExport(this.queryForm.params).then(res => {
        if (res.code === 200) {
          this.exportThis();
        }
      });
    },
    exportThis() {
      const h = this.$createElement;
      this.$msgbox({
        // '提示'
        title: this.$t('collectionCenter.tips'),
        message: h('p', null, [
          // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
          h('span', this.$t('collectionCenter.exportChangeTip')),
          // '请勿重复导出！'
          h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
        ]),
        showCancelButton: true,
        // '立即前往'
        confirmButtonText: this.$t('collectionCenter.Immediately'),
        // '关闭'
        cancelButtonText: this.$t('collectionCenter.close')
      }).then(action => {
        if (action === 'confirm') {
          this.$router.push({ path: '/resource/exportTaskList' });
        }
      }).catch(() => {
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.msgBox ::v-deep .el-table--border{
  color: #606266 !important;
  border-color: #ebeef5 !important;
  border-bottom-color: #ebeef5 !important;
  border-right-color: #ebeef5 !important;
}
.dateBox{
  display: flex; justify-content: flex-start; align-items: center;
  margin-left: 5px;
  span{display: inline-block;border: 1px solid #5EBC7F;padding: 0 15px;box-sizing: border-box;cursor: pointer; text-align: center;color: #5EBC7F;}
  span:hover, span.d1:hover{background: #51d96b;color: #fff;}
  span.d1:hover{border: 1px solid #51D96B;box-sizing: border-box;}
  span.d1{background: linear-gradient(90deg, #51D96B, transparent);box-sizing: border-box;color: #009C37}
}
</style>
