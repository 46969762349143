var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "addFormBox" },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("basicData.UnderlyingData")) + " "),
          ]),
          _c(
            "el-form",
            {
              ref: "queryForm",
              staticClass: "queryFormClass",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-position": "top",
                size: "small",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "basicData.TheNameOfTheQuoteProposal"
                            ),
                            prop: "name",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("orderDetails.pleaseInput"),
                              clearable: "",
                              maxlength: "50",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.TheValidityPeriodBegins"),
                            prop: "startTime",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled:
                                _vm.operationType === 2 &&
                                _vm.editData &&
                                _vm.editData.offerPriceStatus === 1,
                              format:
                                _vm.$i18n.locale === "zh"
                                  ? _vm.zhDate
                                  : _vm.ptDate,
                              placeholder: _vm.$t("basicData.SelectADatetime"),
                              type: "datetime",
                              "picker-options": _vm.pickerOptions,
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.formData.startTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "startTime", $$v)
                              },
                              expression: "formData.startTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.businessT"),
                            prop: "businessType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.changeBusinessType(1)
                                },
                              },
                              model: {
                                value: _vm.formData.businessType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "businessType", $$v)
                                },
                                expression: "formData.businessType",
                              },
                            },
                            [
                              _c("el-option", {
                                key: "0",
                                attrs: {
                                  label: _vm.$t(
                                    "collectionCenter.expressDelivery"
                                  ),
                                  value: 0,
                                },
                              }),
                              _c("el-option", {
                                key: "1",
                                attrs: {
                                  label: _vm.$t("collectionCenter.lanshou"),
                                  value: 1,
                                },
                              }),
                              _c("el-option", {
                                key: "2",
                                attrs: {
                                  label: _vm.$t("collectionCenter.delivery"),
                                  value: 2,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.formData.businessType === 1
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("basicData.PickupCategory"),
                                prop: "collectionCategory",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "basicData.PleaseSelect"
                                    ),
                                    filterable: "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeBusinessType(2)
                                    },
                                  },
                                  model: {
                                    value: _vm.formData.collectionCategory,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "collectionCategory",
                                        $$v
                                      )
                                    },
                                    expression: "formData.collectionCategory",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: "0",
                                    attrs: {
                                      label: _vm.$t("basicData.OrdinaryPickUp"),
                                      value: 0,
                                    },
                                  }),
                                  _c("el-option", {
                                    key: "1",
                                    attrs: {
                                      label: _vm.$t(
                                        "basicData.StockingAndPickup"
                                      ),
                                      value: 1,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.PartitionSchemeName"),
                            prop: "partitionId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "spareNumberPoo.Only10AreDisplayed"
                                ),
                                "remote-method": _vm.getSubregionData,
                                filterable: "",
                                remote: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getOtherFeeDetail()
                                },
                              },
                              model: {
                                value: _vm.formData.partitionId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "partitionId", $$v)
                                },
                                expression: "formData.partitionId",
                              },
                            },
                            _vm._l(_vm.partitionSchemeList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.QuoteType"),
                            prop: "offerType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t("basicData.PleaseSelect"),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.offerTypeChange(
                                    _vm.formData.offerType
                                  )
                                },
                              },
                              model: {
                                value: _vm.formData.offerType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "offerType", $$v)
                                },
                                expression: "formData.offerType",
                              },
                            },
                            _vm._l(_vm.quoteTypeList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.formData.offerType === 3 || _vm.formData.offerType === 2
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("basicData.businessPrincipal"),
                                prop: "providerInfoId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "spareNumberPoo.Only10AreDisplayed"
                                    ),
                                    "remote-method": _vm.getProviderPage,
                                    filterable: "",
                                    remote: "",
                                    "reserve-keyword": "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.providerInfoChange(
                                        _vm.formData.providerInfoId
                                      )
                                    },
                                    focus: function ($event) {
                                      return _vm.getProviderPage()
                                    },
                                  },
                                  model: {
                                    value: _vm.formData.providerInfoId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "providerInfoId",
                                        $$v
                                      )
                                    },
                                    expression: "formData.providerInfoId",
                                  },
                                },
                                _vm._l(
                                  _vm.businessPrincipalList,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.providerName,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formData.offerType === 4
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("receivablePayable.customer"),
                                prop: "customerIds",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    multiple: !_vm.editData.id,
                                    placeholder: _vm.$t(
                                      "spareNumberPoo.Only10AreDisplayed"
                                    ),
                                    "remote-method": _vm.getCustomerPage,
                                    clearable: "",
                                    filterable: "",
                                    remote: "",
                                    "reserve-keyword": "",
                                  },
                                  on: { change: _vm.customerChange },
                                  model: {
                                    value: _vm.formData.customerIds,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "customerIds", $$v)
                                    },
                                    expression: "formData.customerIds",
                                  },
                                },
                                _vm._l(_vm.customerList, function (item, ind) {
                                  return _c("el-option", {
                                    key: ind,
                                    attrs: {
                                      label:
                                        item.customerAlias +
                                        "(" +
                                        item.username +
                                        ")",
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formData.offerType === 3
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("basicData.Outlets"),
                                prop: "siteIds",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    multiple: !_vm.editData.id,
                                    placeholder: _vm.$t(
                                      "basicData.PleaseSelect"
                                    ),
                                    filterable: "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.siteIdChange(
                                        _vm.formData.siteIds
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.formData.siteIds,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "siteIds", $$v)
                                    },
                                    expression: "formData.siteIds",
                                  },
                                },
                                [
                                  !_vm.formData.providerInfoId
                                    ? _c(
                                        "el-option",
                                        { attrs: { value: "" } },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "#f00",
                                                "font-weight": "bold",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "basicData.SelectTheBusinessPrincipalFirst"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._l(_vm.outletsList, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("basicData.PriceDetails")) + " "),
          ]),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "20px", clear: "both" } },
            [
              _c(
                "div",
                { staticStyle: { float: "left" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      model: {
                        value: _vm.formData.expressType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "expressType", $$v)
                        },
                        expression: "formData.expressType",
                      },
                    },
                    _vm._l(_vm.EXPRESS_TYPE, function (item) {
                      return _c(
                        "el-radio-button",
                        { key: item.value, attrs: { label: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.formData.cargoType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "cargoType", $$v)
                        },
                        expression: "formData.cargoType",
                      },
                    },
                    _vm._l(_vm.CARGO_TYPE, function (item) {
                      return _c(
                        "el-radio-button",
                        { key: item.value, attrs: { label: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.formData.partitionId
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        float: "left",
                        "margin-left": "20px",
                        display: "flex",
                      },
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "el-form-item__label",
                          staticStyle: {
                            width: "120px",
                            "line-height": "32px",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("basicData.PartitionName")) + "："
                          ),
                        ]
                      ),
                      _c("QueryPartVCmp", {
                        attrs: {
                          val: _vm.formData.partitionAffiliateId,
                          list: _vm.subregionList,
                        },
                        on: {
                          change: function (val) {
                            return (_vm.formData.partitionAffiliateId = val)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticStyle: { clear: "both" } }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "priceTableForm",
              staticStyle: { "margin-top": "10px" },
            },
            [
              _c(
                "el-form",
                {
                  ref: "tableForm1",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.formData,
                    "label-width": "0px",
                    size: "small",
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "20px" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { "margin-bottom": "10px" } },
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permit",
                                  rawName: "v-permit:remove",
                                  value:
                                    "btn:finance:basicData:quotationManagement:edit",
                                  expression:
                                    "'btn:finance:basicData:quotationManagement:edit'",
                                  arg: "remove",
                                },
                              ],
                              attrs: { size: "small", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAdd()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("basicData.AddARow")) + " ")]
                          ),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "bottom-start",
                                trigger: "hover",
                              },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("import-download", {
                                    key: _vm.exportKey,
                                    attrs: {
                                      "expense-type-list": _vm.expenseTypeList,
                                      "import-type": 1,
                                      "table-data": {
                                        formData: _vm.formData,
                                        subregionList: _vm.subregionList,
                                      },
                                      "table-data-obj":
                                        _vm.tableDataObj.collectFeesList,
                                    },
                                    on: {
                                      importPartition: _vm.importPartition,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: {
                                    slot: "reference",
                                    plain: "",
                                    size: "small",
                                  },
                                  slot: "reference",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("basicData.ImportDownload")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "bottom-start",
                                trigger: "hover",
                              },
                            },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  { staticStyle: { "margin-bottom": "10px" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearOffers(1)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "basicData.EmptyCurrentPartition"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearOffers(2)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "basicData.EmptyAllPartitions"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permit",
                                      rawName: "v-permit:remove",
                                      value:
                                        "btn:finance:basicData:quotationManagement:edit",
                                      expression:
                                        "'btn:finance:basicData:quotationManagement:edit'",
                                      arg: "remove",
                                    },
                                  ],
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: {
                                    slot: "reference",
                                    plain: "",
                                    size: "small",
                                  },
                                  slot: "reference",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("basicData.ClearTheOffer")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-table",
                        {
                          key: _vm.tableKey,
                          staticClass: "error-table",
                          attrs: {
                            data: _vm.formData.collectFeesList,
                            border: "",
                          },
                        },
                        [
                          _c(
                            "el-table-column",
                            {
                              attrs: {
                                label: _vm.$t("basicData.ExpenseType"),
                                "min-width": "180",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "collectFeesList." +
                                                  scope.$index +
                                                  ".feeTypeCode",
                                                rules: {
                                                  required: true,
                                                  message: _vm.$t(
                                                    "basicData.CannotBeEmpty"
                                                  ),
                                                  trigger: ["blur", "change"],
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "basicData.PleaseSelect"
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.chargeTypeChange(
                                                        scope
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.feeTypeCode,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "feeTypeCode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.feeTypeCode",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.expenseTypeList,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.id,
                                                      attrs: {
                                                        label: item.displayName,
                                                        value: item.code,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("basicData.ExpenseType"))
                                    ),
                                  ]),
                                ]),
                              ]),
                            ],
                            2
                          ),
                          _vm.formData.collectionCategory !== 1
                            ? _c(
                                "el-table-column",
                                {
                                  attrs: {
                                    label: _vm.$t("basicData.chargeType"),
                                    "min-width": "180",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      prop:
                                                        "collectFeesList." +
                                                        scope.$index +
                                                        ".billingType",
                                                      rules: {
                                                        required:
                                                          !_vm.formData
                                                            .collectionCategory,
                                                        message: _vm.$t(
                                                          "basicData.CannotBeEmpty"
                                                        ),
                                                        trigger: [
                                                          "blur",
                                                          "change",
                                                        ],
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          placeholder: _vm.$t(
                                                            "basicData.PleaseSelect"
                                                          ),
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.chargeTypeChange(
                                                              scope
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row
                                                              .billingType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "billingType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.billingType",
                                                        },
                                                      },
                                                      [
                                                        _c("el-option", {
                                                          key: 1,
                                                          attrs: {
                                                            label: _vm.$t(
                                                              "newOperationCenter.Weight"
                                                            ),
                                                            value: 1,
                                                          },
                                                        }),
                                                        _c("el-option", {
                                                          key: 2,
                                                          attrs: {
                                                            label: _vm.$t(
                                                              "collectionCenter.ballot"
                                                            ),
                                                            value: 2,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    982440397
                                  ),
                                },
                                [
                                  _c("template", { slot: "header" }, [
                                    _c("div", [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("basicData.chargeType"))
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.formData.businessType === 1 &&
                          _vm.formData.collectionCategory === 0
                            ? _c(
                                "el-table-column",
                                {
                                  attrs: {
                                    label: _vm.$t("newOrder.CollectionType"),
                                    "min-width": "180",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      prop:
                                                        "collectFeesList." +
                                                        scope.$index +
                                                        ".collectType",
                                                      rules: {
                                                        required: true,
                                                        message: _vm.$t(
                                                          "basicData.CannotBeEmpty"
                                                        ),
                                                        trigger: [
                                                          "blur",
                                                          "change",
                                                        ],
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          placeholder: _vm.$t(
                                                            "basicData.PleaseSelect"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row
                                                              .collectType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "collectType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.collectType",
                                                        },
                                                      },
                                                      [
                                                        _c("el-option", {
                                                          key: 1,
                                                          attrs: {
                                                            label: _vm.$t(
                                                              "collectionCenter.dropOff"
                                                            ),
                                                            value: 1,
                                                          },
                                                        }),
                                                        _c("el-option", {
                                                          key: 2,
                                                          attrs: {
                                                            label: _vm.$t(
                                                              "collectionCenter.doorPickUp"
                                                            ),
                                                            value: 2,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    949423346
                                  ),
                                },
                                [
                                  _c("template", { slot: "header" }, [
                                    _c("div", [
                                      _vm.formData.businessType === 1
                                        ? _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          )
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("newOrder.CollectionType")
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.formData.collectionCategory === 1
                            ? [
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "basicData.BoxCountStartsNotIncluded"
                                      ),
                                      "min-width": "180",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        prop:
                                                          "collectFeesList." +
                                                          scope.$index +
                                                          ".startCases",
                                                        rules: {
                                                          required: false,
                                                          negative: "",
                                                          suffix: 2,
                                                          negative1: 1,
                                                          package:
                                                            scope.row.endCases,
                                                          validator:
                                                            _vm.Integer0,
                                                          trigger: [
                                                            "blur",
                                                            "change",
                                                          ],
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        model: {
                                                          value:
                                                            scope.row
                                                              .startCases,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "startCases",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.startCases",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2763474543
                                    ),
                                  },
                                  [
                                    _c("template", { slot: "header" }, [
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("*")]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "basicData.BoxCountStartsNotIncluded"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ],
                                  2
                                ),
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "basicData.EndOfCaseCountIncluded"
                                      ),
                                      "min-width": "180",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        prop:
                                                          "collectFeesList." +
                                                          scope.$index +
                                                          ".endCases",
                                                        rules: {
                                                          required: false,
                                                          negative: "",
                                                          suffix: 2,
                                                          negative1: 2,
                                                          package:
                                                            scope.row
                                                              .startCases,
                                                          validator:
                                                            _vm.Integer0,
                                                          trigger: [
                                                            "blur",
                                                            "change",
                                                          ],
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        model: {
                                                          value:
                                                            scope.row.endCases,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "endCases",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.endCases",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2925974563
                                    ),
                                  },
                                  [
                                    _c("template", { slot: "header" }, [
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("*")]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "basicData.EndOfCaseCountIncluded"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ],
                                  2
                                ),
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      label: _vm.$t("basicData.Price"),
                                      "min-width": "180",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop:
                                                      "collectFeesList." +
                                                      scope.$index +
                                                      ".price",
                                                    rules: {
                                                      required: true,
                                                      suffix: 2,
                                                      negative: "",
                                                      validator: _vm.priceType,
                                                      trigger: [
                                                        "blur",
                                                        "change",
                                                      ],
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    model: {
                                                      value: scope.row.price,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "price",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.price",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3686703159
                                    ),
                                  },
                                  [
                                    _c("template", { slot: "header" }, [
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("*")]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("basicData.Price"))
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ],
                                  2
                                ),
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "basicData.ExceedingThePrice"
                                      ),
                                      "min-width": "180",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop:
                                                      "collectFeesList." +
                                                      scope.$index +
                                                      ".overExtraPrice",
                                                    rules: {
                                                      required: false,
                                                      suffix: 2,
                                                      negative: "",
                                                      validator: _vm.priceType,
                                                      trigger: [
                                                        "blur",
                                                        "change",
                                                      ],
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    model: {
                                                      value:
                                                        scope.row
                                                          .overExtraPrice,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "overExtraPrice",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.overExtraPrice",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2893025148
                                    ),
                                  },
                                  [
                                    _c("template", { slot: "header" }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "basicData.ExceedingThePrice"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  2
                                ),
                              ]
                            : [
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      label: _vm.$t("basicData.Begin"),
                                      "min-width": "240",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.billingType === 2
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            "collectFeesList." +
                                                            scope.$index +
                                                            ".startWeight",
                                                          rules: {
                                                            required: false,
                                                            negative: "",
                                                            suffix: 2,
                                                            negative1: 1,
                                                            package:
                                                              scope.row
                                                                .endWeight,
                                                            validator:
                                                              _vm.priceType,
                                                            trigger: [
                                                              "blur",
                                                              "change",
                                                            ],
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  flex: "1",
                                                                },
                                                              },
                                                              [
                                                                _c("el-input", {
                                                                  model: {
                                                                    value:
                                                                      scope.row
                                                                        .startWeight,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          scope.row,
                                                                          "startWeight",
                                                                          typeof $$v ===
                                                                            "string"
                                                                            ? $$v.trim()
                                                                            : $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "scope.row.startWeight",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  width: "35px",
                                                                  "text-align":
                                                                    "right",
                                                                },
                                                              },
                                                              [_vm._v("(KG)")]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            "collectFeesList." +
                                                            scope.$index +
                                                            ".startWeight",
                                                          rules: {
                                                            required: true,
                                                            negative: "",
                                                            suffix: 3,
                                                            negative1: 1,
                                                            package:
                                                              scope.row
                                                                .endWeight,
                                                            validator:
                                                              _vm.priceType,
                                                            trigger: [
                                                              "blur",
                                                              "change",
                                                            ],
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  flex: "1",
                                                                },
                                                              },
                                                              [
                                                                _c("el-input", {
                                                                  model: {
                                                                    value:
                                                                      scope.row
                                                                        .startWeight,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          scope.row,
                                                                          "startWeight",
                                                                          typeof $$v ===
                                                                            "string"
                                                                            ? $$v.trim()
                                                                            : $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "scope.row.startWeight",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  width: "35px",
                                                                  "text-align":
                                                                    "right",
                                                                },
                                                              },
                                                              [_vm._v("(KG)")]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                          ]
                                        },
                                      },
                                    ]),
                                  },
                                  [
                                    _c("template", { slot: "header" }, [
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("*")]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("basicData.Begin"))
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ],
                                  2
                                ),
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      label: _vm.$t("basicData.end"),
                                      "min-width": "240",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.billingType === 2
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            "collectFeesList." +
                                                            scope.$index +
                                                            ".endWeight",
                                                          rules: {
                                                            required: false,
                                                            negative: "",
                                                            suffix: 2,
                                                            negative1: 2,
                                                            package:
                                                              scope.row
                                                                .startWeight,
                                                            validator:
                                                              _vm.priceType,
                                                            trigger: [
                                                              "blur",
                                                              "change",
                                                            ],
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  flex: "1",
                                                                },
                                                              },
                                                              [
                                                                _c("el-input", {
                                                                  model: {
                                                                    value:
                                                                      scope.row
                                                                        .endWeight,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          scope.row,
                                                                          "endWeight",
                                                                          typeof $$v ===
                                                                            "string"
                                                                            ? $$v.trim()
                                                                            : $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "scope.row.endWeight",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  width: "35px",
                                                                  "text-align":
                                                                    "right",
                                                                },
                                                              },
                                                              [_vm._v("(KG)")]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            "collectFeesList." +
                                                            scope.$index +
                                                            ".endWeight",
                                                          rules: {
                                                            required: true,
                                                            negative: "",
                                                            suffix: 3,
                                                            negative1: 2,
                                                            package:
                                                              scope.row
                                                                .startWeight,
                                                            validator:
                                                              _vm.priceType,
                                                            trigger: [
                                                              "blur",
                                                              "change",
                                                            ],
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  flex: "1",
                                                                },
                                                              },
                                                              [
                                                                _c("el-input", {
                                                                  model: {
                                                                    value:
                                                                      scope.row
                                                                        .endWeight,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          scope.row,
                                                                          "endWeight",
                                                                          typeof $$v ===
                                                                            "string"
                                                                            ? $$v.trim()
                                                                            : $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "scope.row.endWeight",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  width: "35px",
                                                                  "text-align":
                                                                    "right",
                                                                },
                                                              },
                                                              [_vm._v("(KG)")]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                          ]
                                        },
                                      },
                                    ]),
                                  },
                                  [
                                    _c("template", { slot: "header" }, [
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("*")]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("basicData.end"))
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ],
                                  2
                                ),
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      label: _vm.$t("basicData.Price"),
                                      "min-width": "180",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "collectFeesList." +
                                                    scope.$index +
                                                    ".price",
                                                  rules: _vm.rules.price,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          flex: "1",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          model: {
                                                            value:
                                                              scope.row.price,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "price",
                                                                typeof $$v ===
                                                                  "string"
                                                                  ? $$v.trim()
                                                                  : $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.price",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    scope.row.billingType === 2
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "text-align":
                                                                "right",
                                                            },
                                                            style: {
                                                              width:
                                                                _vm.$i18n
                                                                  .locale !==
                                                                "zh"
                                                                  ? "50px"
                                                                  : "25px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " /" +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "basicData.ticket"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  },
                                  [
                                    _c("template", { slot: "header" }, [
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("*")]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("basicData.Price"))
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ],
                                  2
                                ),
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "basicData.ExceedingThePrice"
                                      ),
                                      "min-width": "180",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "collectFeesList." +
                                                    scope.$index +
                                                    ".overWeightExtraPrice",
                                                  rules:
                                                    _vm.rules
                                                      .overWeightExtraPrice,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          flex: "1",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          model: {
                                                            value:
                                                              scope.row
                                                                .overWeightExtraPrice,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "overWeightExtraPrice",
                                                                typeof $$v ===
                                                                  "string"
                                                                  ? $$v.trim()
                                                                  : $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.overWeightExtraPrice",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "35px",
                                                          "text-align": "right",
                                                        },
                                                      },
                                                      [_vm._v("/KG")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  },
                                  [
                                    _c("template", { slot: "header" }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "basicData.ExceedingThePrice"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("basicData.operate"),
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-popconfirm",
                                      {
                                        attrs: {
                                          title: _vm.$t(
                                            "basicData.AreYouSureYouWantToDelete"
                                          ),
                                        },
                                        on: {
                                          confirm: function ($event) {
                                            return _vm.handleDel(
                                              scope.$index,
                                              "collectFeesList"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              slot: "reference",
                                              size: "mini",
                                              type: "danger",
                                            },
                                            slot: "reference",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("basicData.Delete"))
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticStyle: { "margin-bottom": "10px" } },
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permit",
                                  rawName: "v-permit:remove",
                                  value:
                                    "btn:finance:basicData:quotationManagement:edit",
                                  expression:
                                    "'btn:finance:basicData:quotationManagement:edit'",
                                  arg: "remove",
                                },
                              ],
                              attrs: { size: "small", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAdd1()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("basicData.AddAdditionalRules")) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "bottom-start",
                                trigger: "hover",
                              },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("import-download", {
                                    key: _vm.exportKey,
                                    attrs: {
                                      "expense-type-list": _vm.expenseTypeList,
                                      "import-type": 2,
                                      "miscellaneous-list":
                                        _vm.miscellaneousList,
                                      "other-rules-raw-data":
                                        _vm.otherRulesRawData,
                                      "table-data": {
                                        formData: _vm.formData,
                                        subregionList: _vm.subregionList,
                                      },
                                      "table-data-obj":
                                        _vm.tableDataObj.collectFeesList,
                                    },
                                    on: {
                                      importPartition: _vm.importPartition,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: {
                                    slot: "reference",
                                    plain: "",
                                    size: "small",
                                  },
                                  slot: "reference",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("basicData.ImportDownload")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "bottom-start",
                                trigger: "hover",
                              },
                            },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  { staticStyle: { "margin-bottom": "10px" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearOtherOffers(1)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "basicData.EmptyCurrentPartition"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearOtherOffers(2)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "basicData.EmptyAllPartitions"
                                            )
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permit",
                                      rawName: "v-permit:remove",
                                      value:
                                        "btn:finance:basicData:quotationManagement:edit",
                                      expression:
                                        "'btn:finance:basicData:quotationManagement:edit'",
                                      arg: "remove",
                                    },
                                  ],
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: {
                                    slot: "reference",
                                    plain: "",
                                    size: "small",
                                  },
                                  slot: "reference",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("basicData.ClearTheOffer")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-table",
                        {
                          key: _vm.tableKey,
                          staticClass: "error-table",
                          attrs: { data: _vm.formData.feeTypeList, border: "" },
                        },
                        [
                          _c(
                            "el-table-column",
                            {
                              attrs: {
                                label: _vm.$t("basicData.ExpenseType"),
                                width: "200",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "feeTypeList." +
                                                  scope.$index +
                                                  ".feeTypeCode",
                                                rules: {
                                                  required: true,
                                                  message: _vm.$t(
                                                    "basicData.CannotBeEmpty"
                                                  ),
                                                  trigger: ["blur", "change"],
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "basicData.PleaseSelect"
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.feeTypeCodeChange(
                                                        scope.row.feeTypeCode,
                                                        scope.$index
                                                      )
                                                    },
                                                    focus: function ($event) {
                                                      return _vm.disabledFeeTyp()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.feeTypeCode,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "feeTypeCode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.feeTypeCode",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.miscellaneousList,
                                                  function (item, ind) {
                                                    return _c("el-option", {
                                                      key: ind,
                                                      attrs: {
                                                        disabled:
                                                          _vm.selectFeeTypeCode.includes(
                                                            item.code
                                                          ),
                                                        label: item.displayName,
                                                        value: item.code,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("*")]
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("basicData.ExpenseType"))
                                    ),
                                  ]),
                                ]),
                              ]),
                            ],
                            2
                          ),
                          _c("el-table-column", {
                            attrs: { label: _vm.$t("basicData.BillingRules") },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      scope.row.specialRules === 0
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "inline-block",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      prop:
                                                        "feeTypeList." +
                                                        scope.$index +
                                                        ".taxRate",
                                                      rules: {
                                                        required: true,
                                                        suffix: 3,
                                                        negative: "",
                                                        validator:
                                                          _vm.priceType,
                                                        trigger: [
                                                          "blur",
                                                          "change",
                                                        ],
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "basicData.TheAmountDeclaredForTheProduct"
                                                          )
                                                        ) +
                                                        " * "
                                                    ),
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        display: "inline-block",
                                                        width: "120px",
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row.taxRate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "taxRate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.taxRate",
                                                      },
                                                    }),
                                                    _vm._v(" % "),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "inline-block",
                                                  "margin-left": "20px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "basicData.MinimumStandards"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        display: "inline-block",
                                                        width: "120px",
                                                      },
                                                      model: {
                                                        value: scope.row.price,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "price",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.price",
                                                      },
                                                    }),
                                                    _vm._v(" BRL "),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ])
                                        : _vm._e(),
                                      scope.row.specialRules === 1
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop:
                                                      "feeTypeList." +
                                                      scope.$index +
                                                      ".taxRate",
                                                    rules: {
                                                      required: true,
                                                      suffix: 3,
                                                      negative: "",
                                                      validator: _vm.priceType,
                                                      trigger: [
                                                        "blur",
                                                        "change",
                                                      ],
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm.formData.businessType ===
                                                  0
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            "（" +
                                                              _vm.$t(
                                                                "basicData.CourierFee"
                                                              ) +
                                                              " + " +
                                                              _vm.$t(
                                                                "basicData.CourierTax"
                                                              ) +
                                                              "(ISS/ICMS)" +
                                                              "（" +
                                                              _vm.$t(
                                                                "basicData.InsuranceIncluded"
                                                              ) +
                                                              "））"
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm.formData.businessType ===
                                                  1
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            "（" +
                                                              _vm.$t(
                                                                "basicData.CollectFees"
                                                              ) +
                                                              " + " +
                                                              _vm.$t(
                                                                "basicData.CollectTaxes"
                                                              ) +
                                                              "(ISS/ICMS)" +
                                                              "（" +
                                                              _vm.$t(
                                                                "basicData.InsuranceIncluded"
                                                              ) +
                                                              "））"
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm.formData.businessType ===
                                                  2
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            "（" +
                                                              _vm.$t(
                                                                "basicData.DeliveryFee"
                                                              ) +
                                                              " + " +
                                                              _vm.$t(
                                                                "basicData.DeliveryTax"
                                                              ) +
                                                              "(ISS/ICMS)" +
                                                              "（" +
                                                              _vm.$t(
                                                                "basicData.InsuranceIncluded"
                                                              ) +
                                                              "））"
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" * "),
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      display: "inline-block",
                                                      width: "120px",
                                                    },
                                                    model: {
                                                      value: scope.row.taxRate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "taxRate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.taxRate",
                                                    },
                                                  }),
                                                  _vm._v(" % "),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      scope.row.specialRules === 2
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "inline-block",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      prop:
                                                        "feeTypeList." +
                                                        scope.$index +
                                                        ".taxRateOne",
                                                    },
                                                  },
                                                  [
                                                    _vm.formData
                                                      .businessType === 0
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "basicData.CourierFee"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm.formData
                                                      .businessType === 1
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "(" +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "basicData.CollectFees"
                                                                ) +
                                                                  " + " +
                                                                  _vm.$t(
                                                                    "basicData.insurance"
                                                                  )
                                                              ) +
                                                              ")"
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm.formData
                                                      .businessType === 2
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "basicData.DeliveryFee"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm._v(
                                                      " / (1 - ISS" +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "GlobalSetObj.rate"
                                                          )
                                                        ) +
                                                        ") "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" * "),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "inline-block",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      prop:
                                                        "feeTypeList." +
                                                        scope.$index +
                                                        ".taxRate",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " ISS" +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "GlobalSetObj.rate"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ])
                                        : _vm._e(),
                                      scope.row.specialRules === 3
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "inline-block",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      prop:
                                                        "feeTypeList." +
                                                        scope.$index +
                                                        ".taxRateOne",
                                                    },
                                                  },
                                                  [
                                                    _vm.formData
                                                      .businessType === 0
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "basicData.CourierFee"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm.formData
                                                      .businessType === 1
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "(" +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "basicData.CollectFees"
                                                                ) +
                                                                  " + " +
                                                                  _vm.$t(
                                                                    "basicData.insurance"
                                                                  )
                                                              ) +
                                                              ")"
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm.formData
                                                      .businessType === 2
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "basicData.DeliveryFee"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm._v(
                                                      " / (1 - ICMS" +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "GlobalSetObj.rate"
                                                          )
                                                        ) +
                                                        ") * ICMS" +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "GlobalSetObj.rate"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ])
                                        : _vm._e(),
                                      scope.row.specialRules === 7
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "inline-block",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      prop:
                                                        "feeTypeList." +
                                                        scope.$index +
                                                        ".taxRateOne",
                                                    },
                                                  },
                                                  [
                                                    _vm.formData
                                                      .businessType === 0
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "basicData.CourierFee"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm.formData
                                                      .businessType === 1
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "(" +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "basicData.CollectFees"
                                                                ) +
                                                                  " + " +
                                                                  _vm.$t(
                                                                    "basicData.insurance"
                                                                  )
                                                              ) +
                                                              ")"
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm.formData
                                                      .businessType === 2
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "basicData.DeliveryFee"
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm._v(
                                                      " * ISS" +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "GlobalSetObj.rate"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ])
                                        : _vm._e(),
                                      scope.row.specialRules === 4
                                        ? _c("div", [
                                            _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "inline-block",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        prop:
                                                          "feeTypeList." +
                                                          scope.$index +
                                                          ".weight",
                                                        rules: {
                                                          required: true,
                                                          suffix: 3,
                                                          negative: "",
                                                          validator:
                                                            _vm.priceType,
                                                          trigger: [
                                                            "blur",
                                                            "change",
                                                          ],
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "operationCenter.Weight"
                                                            )
                                                          ) +
                                                          " >= "
                                                      ),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          display:
                                                            "inline-block",
                                                          width: "120px",
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row.weight,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "weight",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.weight",
                                                        },
                                                      }),
                                                      _vm._v(" KG "),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "inline-block",
                                                    "margin-left": "20px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        prop:
                                                          "feeTypeList." +
                                                          scope.$index +
                                                          ".price",
                                                        rules: {
                                                          required: true,
                                                          suffix: 2,
                                                          negative: "",
                                                          validator:
                                                            _vm.priceType,
                                                          trigger: [
                                                            "blur",
                                                            "change",
                                                          ],
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "basicData.IncreaseThePrice"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          display:
                                                            "inline-block",
                                                          width: "120px",
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row.price,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "price",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.price",
                                                        },
                                                      }),
                                                      _vm._v(" BRL "),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ])
                                        : _vm._e(),
                                      scope.row.specialRules === 5
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "inline-block",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      prop:
                                                        "feeTypeList." +
                                                        scope.$index +
                                                        ".weight",
                                                      rules: {
                                                        required: true,
                                                        suffix: 3,
                                                        negative: "",
                                                        validator:
                                                          _vm.priceType,
                                                        trigger: [
                                                          "blur",
                                                          "change",
                                                        ],
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(" / "),
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        display: "inline-block",
                                                        width: "120px",
                                                      },
                                                      model: {
                                                        value: scope.row.weight,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "weight",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.weight",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      " KG（" +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "basicData.roundUp"
                                                          )
                                                        ) +
                                                        "） "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "inline-block",
                                                  "margin-left": "10px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      prop:
                                                        "feeTypeList." +
                                                        scope.$index +
                                                        ".price",
                                                      rules: {
                                                        required: true,
                                                        suffix: 2,
                                                        negative: "",
                                                        validator:
                                                          _vm.priceType,
                                                        trigger: [
                                                          "blur",
                                                          "change",
                                                        ],
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "basicData.increase"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        display: "inline-block",
                                                        width: "120px",
                                                      },
                                                      model: {
                                                        value: scope.row.price,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "price",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.price",
                                                      },
                                                    }),
                                                    _vm._v(" BRL "),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ])
                                        : _vm._e(),
                                      scope.row.specialRules === 6
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop:
                                                      "feeTypeList." +
                                                      scope.$index +
                                                      ".price",
                                                    rules: {
                                                      required: true,
                                                      suffix: 2,
                                                      negative: "",
                                                      validator: _vm.priceType,
                                                      trigger: [
                                                        "blur",
                                                        "change",
                                                      ],
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      display: "inline-block",
                                                      width: "120px",
                                                    },
                                                    model: {
                                                      value: scope.row.price,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "price",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.price",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " /" +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "basicData.ticket"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("basicData.operate"),
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-popconfirm",
                                      {
                                        attrs: {
                                          title: _vm.$t(
                                            "basicData.AreYouSureYouWantToDelete"
                                          ),
                                        },
                                        on: {
                                          confirm: function ($event) {
                                            return _vm.handleDel(
                                              scope.$index,
                                              "feeTypeList"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              slot: "reference",
                                              size: "mini",
                                              type: "danger",
                                            },
                                            slot: "reference",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("basicData.Delete"))
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "permit",
              rawName: "v-permit:remove",
              value: "btn:finance:basicData:quotationManagement:edit",
              expression: "'btn:finance:basicData:quotationManagement:edit'",
              arg: "remove",
            },
          ],
          staticClass: "addFooter",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.handleUpdatePage(false)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
            [_vm._v(_vm._s(_vm.$t("basicData.Save")))]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            title: _vm.$t("basicData.tips"),
            visible: _vm.dialogVisible,
            width: "400",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            _vm._l(_vm.errData, function (item, ind) {
              return _c(
                "div",
                {
                  key: ind,
                  staticStyle: { color: "red", "margin-bottom": "8px" },
                },
                [_vm._v(_vm._s(item))]
              )
            }),
            0
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("basicData.determine")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }