<template>
  <div class="kanban_content">
    <div class="headerTitle">快递运营看板(Kanban de operacao expressa)</div>
    <div style="text-align: right; margin-bottom: 10px">
      <span style="padding: 0 10px">客户名称(Nome do cliente)</span>
      <el-select
        v-model="customerIds"
        :placeholder="$t('operationCenter.PleaseSelect')"
        clearable
        collapse-tags
        filterable
        multiple
        size="mini"
        style="width: 250px"
        @change="selectCustomer"
      >
        <el-option
          v-for="(item, ind) in localUserInfo"
          :key="ind"
          :label="item.customerAlias + '(' + item.username + ')'"
          :value="item.id"
        />
      </el-select>
      <span style="padding: 0 10px">商户名称(Nome do Mercador)</span>
      <el-select
        v-model="merchantCodes"
        :placeholder="$t('operationCenter.PleaseSelect')"
        clearable
        collapse-tags
        filterable
        multiple
        size="mini"
        style="width: 250px"
        @change="selectMerchant"
      >
        <el-option
          v-for="(item, ind) in merchantNameList"
          :key="ind"
          :label="item.merchantName"
          :value="item.merchantCode"
        />
      </el-select>
    </div>
    <el-table
      :key="keyTime"
      :data="CustomerSingleVolumeStatistics"
      :header-cell-style="{ color: 'black' }"
      :span-method="objectSpanMethod"
      style="width: 100%"
    >
      <el-table-column min-width="150" prop="date">
        <template slot="header">
          <div>
            <div>{{ '客户单量统计' }}</div>
            <div>Estatísticas de volume unitário de clientes</div>
          </div>
        </template>
        <template slot-scope="scope">
          <div v-if="scope.row.type === 'local'" class="cloumH1">
            <div>本地客户</div>
            <div>Cliente local</div>
          </div>
          <div v-else-if="scope.row.type === 'localTotal'" class="cloumH1">
            <div>合计 Somar o total</div>
          </div>
          <div v-else-if="scope.row.type === 'crossBorder'" class="cloumH1">
            <div>跨境单量</div>
            <div>Clientes internacionais</div>
          </div>
          <div v-else-if="scope.row.type === 'crossBorderTotal'" class="cloumH1">
            <div>合计 Somar o total</div>
          </div>
          <div v-else class="cloumH1">
            <div>总计 Total</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="件量 (Número de casos)">
        <el-table-column label="客户名称 (Clinte)" min-width="160" prop="customerName" />
        <el-table-column :label="dateColumn" min-width="90" prop="waybillDailyTotal" />
        <el-table-column label="日环比 (Anel do dia)" min-width="110" prop="waybillDailyGrowthRate">
          <template slot-scope="scope">
            <div :style="{ color: scope.row.waybillDailyGrowthRateTTT ? '#009c37' : 'red' }">
              {{ scope.row.waybillDailyGrowthRate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="月累计 (Total acumulado)"
          min-width="110"
          prop="waybillMonthTotal"
        />
        <el-table-column label="月日均 (Média mensal)" min-width="110" prop="waybillMonthAvg" />
        <el-table-column
          label="月环比 (Comparação mês a mês)"
          min-width="110"
          prop="waybillMonthGrowthRate"
        >
          <template slot-scope="scope">
            <div :style="{ color: scope.row.waybillMonthGrowthRateTTT ? '#009c37' : 'red' }">
              {{ scope.row.waybillMonthGrowthRate }}
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column align="center" label="单票重量 (Peso da fatura)">
        <el-table-column
          label="当月均单票重量 (Peso médio do bilhete único para o mês)"
          min-width="150"
          prop="currentMonthAvgWeightPerTicket"
        />
        <el-table-column
          label="上月均单票重量 (Peso médio do bilhete único no mês anterior)"
          min-width="150"
          prop="lastMonthAvgWeightPerTicket"
        />
        <el-table-column
          label="月环比 (Comparação mês a mês)"
          min-width="120"
          prop="weightMonthGrowthRate"
        >
          <template slot-scope="scope">
            <div :style="{ color: scope.row.weightMonthGrowthRateeTTT ? '#009c37' : 'red' }">
              {{ scope.row.weightMonthGrowthRate }}
            </div>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <!--  单量走势  -->
    <div style="margin-top: 15px">
      <el-table
        :data="SingleQuantitytrendList"
        :header-cell-style="{ color: 'black' }"
        border
        style="width: 100%"
      >
        <el-table-column prop="type" width="180">
          <template slot="header">
            <div>{{ '单量走势' }}</div>
            <div>Tendência do volume unitário</div>
          </template>
          <template slot-scope="scope">
            <div v-if="scope.row.type === 'local'" class="cloumH1">
              <div>本地客户单量</div>
              <div>Volume unitário do cliente local</div>
            </div>
            <div v-else-if="scope.row.type === 'crossBroder'" class="cloumH1">
              <div>跨境单量</div>
              <div>Volume unitário transfronteiriço</div>
            </div>
            <div v-else class="cloumH1">
              <div>总计</div>
              <div>Total</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="上月平均值 (Média do mês anterior)" prop="lastMonthAvg" />
        <el-table-column label="近7天平均值 (Média dos últimos 7 dias)" prop="lastSevenDaysAvg" />
        <el-table-column
          v-for="(item, index) in SingleQuantitytrendHeadlist"
          :key="index"
          :label="item"
          :prop="item"
          :show-overflow-tooltip="false"
        />
        <el-table-column label="月环比 (Comparação mês a mês)" prop="monthGrowthRate">
          <template slot-scope="scope">
            <div :style="{ color: scope.row.monthGrowthRateTTT ? '#009c37' : 'red' }">
              {{ scope.row.monthGrowthRate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="日环比 (Anel do dia)" prop="dailyGrowthRate">
          <template slot-scope="scope">
            <div :style="{ color: scope.row.dailyGrowthRateTTT ? '#009c37' : 'red' }">
              {{ scope.row.dailyGrowthRate }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--  资源投入  -->
    <div style="margin-top: 15px">
      <el-table
        :data="ResourcesKanbanList"
        :header-cell-style="{ color: 'black' }"
        :span-method="objectSpanMethod1"
        border
        style="width: 100%"
      >
        <el-table-column prop="type" width="180">
          <template slot="header">
            <div>{{ '资源投入' }}</div>
            <div>Entradas de recursos</div>
          </template>
          <template slot-scope="scope">
            <div v-if="scope.row.type === 1" class="cloumH1">
              <div>一级派送员</div>
              <div>Despachante I</div>
            </div>
            <div v-else-if="scope.row.type === 2" class="cloumH1">
              <div>二级中转操作员</div>
              <div>Operador de trânsito II</div>
            </div>
            <div v-else class="cloumH1">
              <div>干支线</div>
              <div>linhas tronco e ramais</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="资源项 (Item de recurso)" prop="source" width="180" />
        <el-table-column
          v-for="(item, index) in ResourcesKanbanListHeader"
          :key="index"
          :label="item.time"
          :prop="item.label"
          :show-overflow-tooltip="false"
        />
        <el-table-column label="月环比 (Comparação mês a mês)" prop="monthOnMonth">
          <template slot-scope="scope">
            <div :style="{ color: scope.row.monthOnMonthTTT ? '#009c37' : 'red' }">
              {{ scope.row.monthOnMonth }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="日环比 (Anel do dia)" prop="dayOnDay">
          <template slot-scope="scope">
            <div :style="{ color: scope.row.dayOnDayTTT ? '#009c37' : 'red' }">
              {{ scope.row.dayOnDay }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--  指标单程情况  -->
    <div style="margin-top: 15px">
      <el-table
        :data="targetAchieveKanbanList"
        :header-cell-style="{ color: 'black' }"
        border
        style="width: 100%"
      >
        <el-table-column min-width="170" prop="target">
          <template slot="header">
            <div>{{ '指标达成情况' }}</div>
            <div>Realização do indicador</div>
          </template>
        </el-table-column>
        <el-table-column
          label="上月累计(Acumulado do último mês)"
          min-width="170"
          prop="lastMonthTotal"
        />
        <el-table-column label="本月累计(Total do mês)" min-width="160" prop="thisMonthTotal" />
        <el-table-column
          v-for="(item, index) in ResourcesKanbanListHeader"
          :key="index"
          :label="item.time"
          :prop="item.label"
          :show-overflow-tooltip="false"
          min-width="100"
        />
        <el-table-column label="日环比(Anel do dia)" min-width="100" prop="dayOnDay">
          <template slot-scope="scope">
            <div :style="{ color: scope.row.dayOnDayTTT ? '#009c37' : 'red' }">
              {{ scope.row.dayOnDay }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="重点关注网点(Foco nos pontos de venda)"
          min-width="120"
          prop="attentionSite"
        />
      </el-table>
    </div>
  </div>
</template>

<script>
import {
  getCustomerWaybillDashboard,
  getLocalCustomerPage,
  getMerchantPageByCustomerId,
  getresourceInputboard,
  gettargetAchieveBoard,
  singleQuantitytrend
} from '@/api/orderApi';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export default {
  name: 'Index',
  data() {
    return {
      SingleQuantitytrendList: [], // 单量走势数据
      SingleQuantitytrendHeadlist: [],
      localUserInfo: [],
      customerIds: [],
      merchantCodes: [],
      merchantNameList: [],
      CustomerSingleVolumeStatistics: [], // 客户单量
      spanArr: [], // 用于存储每一行的合并信息
      spanArr1: [], // 用于存储每一行的合并信息
      pos: 0, // 记录当前处理的行索引
      dateColumn: '',
      ResourcesKanbanList: [],
      ResourcesKanbanListHeader: [],
      targetAchieveKanbanList: [],
      keyTime: ''
    };
  },
  mounted() {
    this.getHeaderColumn();
    this.getResourcesKanban(); // 资源投入看板
    this.getTargetAchieveKanban(); // 指标达成看板
    this.CustomerWaybillDashboard(); // 客户单量统计
    this.getSingleQuantitytrend(); // 单量走势数据
    this.getLocalUserInfo(); // 分页查询本地客户信息（排除szanjun）
    this.MerchantPageByCustomerId(); // 商户根据客户ID
  },
  methods: {
    // 获取表头
    getHeaderColumn() {
      // 获取当前日期
      // 使用插件
      dayjs.extend(utc);
      dayjs.extend(timezone);
      const currentDate = dayjs().tz('America/Sao_Paulo');
      // 循环获取近七天的日期
      for (let i = 1; i < 8; i++) {
        const date = currentDate.subtract(i, 'day').format('YYYY-MM-DD');
        const obj = {
          time: date,
          label: 'day' + (8 - i)
        };
        this.ResourcesKanbanListHeader.unshift(obj);
      }
      console.log(this.ResourcesKanbanListHeader, 'this.ResourcesKanbanListHeader');
    },
    selectMerchant() {
      this.CustomerWaybillDashboard();
    },
    selectCustomer() {
      this.CustomerWaybillDashboard();
    },
    // 查询商户根据客户ID
    CustomerWaybillDashboard() {
      const data = {
        customerIds: this.customerIds,
        merchantCodes: this.merchantCodes
      };
      getCustomerWaybillDashboard(data).then((res) => {
        if (res.code === 200) {
          if (res.data.length > 0) {
            this.keyTime = new Date().getTime();
            this.dateColumn = res.data[0].date;
            this.CustomerSingleVolumeStatistics = res.data;
            this.CustomerSingleVolumeStatistics.forEach((item) => {
              item.waybillDailyGrowthRateTTT = item.waybillDailyGrowthRate > 0;
              item.waybillMonthGrowthRateTTT = item.waybillMonthGrowthRate > 0;
              item.weightMonthGrowthRateeTTT = item.weightMonthGrowthRate > 0;
              item.waybillDailyGrowthRate = item.waybillDailyGrowthRate + '%';
              item.waybillMonthGrowthRate = item.waybillMonthGrowthRate + '%';
              item.weightMonthGrowthRate = item.weightMonthGrowthRate + '%';
            });
            this.getSpanArr(res.data);
          }
        }
      });
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      console.log(this.spanArr, 'this.spanArrthis.spanArrthis.spanArr');
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
    },
    objectSpanMethod1({ row, column, rowIndex, columnIndex }) {
      console.log(this.spanArr1, 'this.spanArr1');
      if (columnIndex === 0) {
        const _row = this.spanArr1[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
    },
    getSpanArr1(data) {
      this.spanArr1 = [];
      this.pos = 0;
      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr1.push(1);
          this.pos = 0;
        } else {
          // 判断当前行的 type 是否和上一行相同
          if (data[i].type === data[i - 1].type) {
            this.spanArr1[this.pos]++;
            this.spanArr1.push(0);
          } else {
            this.spanArr1.push(1);
            this.pos = i;
          }
        }
      }
    },
    getSpanArr(data) {
      this.spanArr = [];
      this.pos = 0;
      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          // 判断当前行的 type 是否和上一行相同
          if (data[i].type === data[i - 1].type) {
            this.spanArr[this.pos]++;
            this.spanArr.push(0);
          } else {
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
    },
    // 分页查询本地客户信息（排除szanjun）
    getLocalUserInfo() {
      const data = {
        pageNum: 1,
        pageSize: 100,
        params: {
          quickSearch: ''
        }
      };
      getLocalCustomerPage(data).then((res) => {
        if (res.code === 200) {
          this.localUserInfo = res.data.records;
        }
      });
    },
    // 查询商户根据客户ID
    MerchantPageByCustomerId() {
      const data = {
        pageNum: 1,
        pageSize: 5000,
        params: {
          customerId: 24020, // 客户id（szanjun传24020）
          merchantName: '' // 商户名称
        }
      };
      getMerchantPageByCustomerId(data).then((res) => {
        if (res.code === 200) {
          this.merchantNameList = res.data.records;
        }
      });
    },
    // 单量走势
    getSingleQuantitytrend() {
      singleQuantitytrend().then((res) => {
        if (res.code === 200) {
          this.SingleQuantitytrendList = [];
          if (res.data.data.length) {
            res.data.data.forEach((item) => {
              item.monthGrowthRateTTT = item.monthGrowthRate > 0;
              item.dailyGrowthRateTTT = item.dailyGrowthRate > 0;
              item.monthGrowthRate = item.monthGrowthRate + '%';
              item.dailyGrowthRate = item.dailyGrowthRate + '%';
              const obj = this.handleFlattend(item);
              this.SingleQuantitytrendList.push(obj);
            });
          }
          this.SingleQuantitytrendHeadlist = res.data.dates;
        }
      });
    },
    // 资源投入看板
    getResourcesKanban() {
      getresourceInputboard().then((res) => {
        if (res.code === 200) {
          if (res.data.length) {
            res.data.forEach((item) => {
              item.monthOnMonthTTT = this.isPercentageGreaterThanZero(item.monthOnMonth);
              item.dayOnDayTTT = this.isPercentageGreaterThanZero(item.dayOnDay);
            });
            this.ResourcesKanbanList = res.data;
            this.getSpanArr1(res.data);
          }
        }
      });
    },
    // 指标达成看板
    getTargetAchieveKanban() {
      gettargetAchieveBoard().then((res) => {
        if (res.code === 200) {
          if (res.data.length) {
            res.data.forEach((item) => {
              item.dayOnDayTTT = this.isPercentageGreaterThanZero(item.dayOnDay);
            });
            this.targetAchieveKanbanList = res.data;
          }
        }
      });
    },
    // 平铺对象方法
    handleFlattend(data) {
      const flattened = {};
      for (const key in data) {
        if (typeof data[key] === 'object' && data[key] !== null) {
          for (const subKey in data[key]) {
            flattened[`${subKey}`] = data[key][subKey];
          }
        } else {
          flattened[key] = data[key];
        }
      }
      return flattened;
    },
    // 判断百分比是否大于零
    isPercentageGreaterThanZero(percentageStr) {
      const numberStr = percentageStr.replace('%', '');
      const number = parseFloat(numberStr) / 100;
      return number > 0;
    }
  }
};
</script>

<style scoped>
@import './index.css';
</style>
