var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lanShouOrdersBox" },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-form",
            [
              _c(
                "el-row",
                {
                  staticClass: "selectClass",
                  staticStyle: { "flex-wrap": "wrap" },
                  attrs: { type: "flex", justify: "start" },
                },
                [
                  _c(
                    "el-col",
                    { staticClass: "col2" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selectTimeClass autoWidth",
                          attrs: {
                            placeholder: _vm.$t(
                              "collectionCenter.pleaseChoose"
                            ),
                          },
                          on: { change: _vm.changeTime },
                          model: {
                            value: _vm.selectTime,
                            callback: function ($$v) {
                              _vm.selectTime = $$v
                            },
                            expression: "selectTime",
                          },
                        },
                        [
                          _c("template", { slot: "prefix" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (
                                    _vm.timeList.find(function (item) {
                                      return item.value === _vm.selectTime
                                    }) || {}
                                  ).time
                                ) +
                                " "
                            ),
                          ]),
                          _vm._l(_vm.timeList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.time, value: item.value },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "delMl" },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              clearable: false,
                              "default-time": ["00:00:00", "23:59:59"],
                              "end-placeholder": _vm.$t(
                                "collectionCenter.EndTime"
                              ),
                              "range-separator": _vm.$t("collectionCenter.to"),
                              "start-placeholder": _vm.$t(
                                "collectionCenter.StartTime"
                              ),
                              type: "datetimerange",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            on: { change: _vm.dateChange },
                            model: {
                              value: _vm.timeValue,
                              callback: function ($$v) {
                                _vm.timeValue = $$v
                              },
                              expression: "timeValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { staticClass: "col2" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "selectTimeClass autoWidth",
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "collectionCenter.pleaseChoose"
                                    ),
                                  },
                                  on: { change: _vm.changeSite },
                                  model: {
                                    value: _vm.selectSite,
                                    callback: function ($$v) {
                                      _vm.selectSite = $$v
                                    },
                                    expression: "selectSite",
                                  },
                                },
                                [
                                  _c("template", { slot: "prefix" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (
                                            _vm.stList.find(function (item) {
                                              return (
                                                item.value === _vm.selectSite
                                              )
                                            }) || {}
                                          ).label
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _vm._l(_vm.stList, function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm.selectSite == "s1"
                            ? _c(
                                "el-col",
                                { staticStyle: { width: "auto" } },
                                [
                                  _c(
                                    "el-form-item",
                                    { staticClass: "siteClass" },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          ref: "searchSite1",
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "collectionCenter.pleaseChoose"
                                            ),
                                            clearable: "",
                                            filterable: "",
                                            multiple: "",
                                          },
                                          on: { focus: _vm.getNewestSite },
                                          nativeOn: {
                                            input: function ($event) {
                                              return _vm.filterData(1)
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.queryForm.params.siteIdList,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryForm.params,
                                                "siteIdList",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "queryForm.params.siteIdList",
                                          },
                                        },
                                        _vm._l(
                                          _vm.siteIdL,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.name,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectSite == "s2"
                            ? _c(
                                "el-col",
                                { staticStyle: { width: "auto" } },
                                [
                                  _c(
                                    "el-form-item",
                                    { staticClass: "siteClass" },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          ref: "searchSite2",
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "collectionCenter.pleaseChoose"
                                            ),
                                            clearable: "",
                                            filterable: "",
                                            multiple: "",
                                          },
                                          on: { focus: _vm.getNewestSite },
                                          nativeOn: {
                                            input: function ($event) {
                                              return _vm.filterData(2)
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.queryForm.params
                                                .signSiteIdList,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryForm.params,
                                                "signSiteIdList",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "queryForm.params.signSiteIdList",
                                          },
                                        },
                                        _vm._l(
                                          _vm.siteIdL,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.name,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.CustomerN"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              on: {
                                change: _vm.changeMerchant,
                                focus: _vm.getNewestCustomer,
                              },
                              model: {
                                value: _vm.customerId,
                                callback: function ($$v) {
                                  _vm.customerId = $$v
                                },
                                expression: "customerId",
                              },
                            },
                            _vm._l(_vm.customerIdList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.username, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("collectionCenter.tipo") } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                filterable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.orderType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "orderType",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.orderType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.tudo"),
                                  value: 0,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.normal"),
                                  value: 1,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.D2DOrder"),
                                  value: 4,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.cutOff"),
                                  value: 2,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.bulkyOrder"),
                                  value: 3,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "col10",
                      staticStyle: { width: "auto" },
                      attrs: { span: 6 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "m" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selectTimeClass autoWidth",
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                              },
                              on: { change: _vm.changeMerchant },
                              model: {
                                value: _vm.merchantChoose,
                                callback: function ($$v) {
                                  _vm.merchantChoose = $$v
                                },
                                expression: "merchantChoose",
                              },
                            },
                            [
                              _c("template", { slot: "prefix" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (
                                        _vm.merchantCodeOrName.find(function (
                                          item
                                        ) {
                                          return item.m === _vm.merchantChoose
                                        }) || {}
                                      ).name
                                    ) +
                                    " "
                                ),
                              ]),
                              _vm._l(
                                _vm.merchantCodeOrName,
                                function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: { label: item.name, value: item.m },
                                  })
                                }
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-select",
                            {
                              attrs: {
                                loading: _vm.loading,
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseInput"
                                ),
                                "remote-method": _vm.remoteMethod,
                                clearable: "",
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                              },
                              on: { change: _vm.changeValue },
                              model: {
                                value: _vm.merchantCodeValue,
                                callback: function ($$v) {
                                  _vm.merchantCodeValue = $$v
                                },
                                expression: "merchantCodeValue",
                              },
                            },
                            _vm._l(
                              _vm.merchantCodeList,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.name },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.SendZipCode"),
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseInput"
                              ),
                              clearable: "",
                            },
                            model: {
                              value: _vm.queryForm.params.senderZipCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "senderZipCode",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "queryForm.params.senderZipCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.receiptZipCode"),
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseInput"
                              ),
                              clearable: "",
                            },
                            model: {
                              value: _vm.queryForm.params.receiverZipCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "receiverZipCode",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "queryForm.params.receiverZipCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.orderStatus"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                                multiple: "",
                              },
                              model: {
                                value: _vm.queryForm.params.collectStatusList,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "collectStatusList",
                                    $$v
                                  )
                                },
                                expression:
                                  "queryForm.params.collectStatusList",
                              },
                            },
                            _vm._l(_vm.orderTypeList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.collectStatusName,
                                  value: item.collectStatus,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { staticClass: "col2" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "selectTimeClass autoWidth",
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "collectionCenter.pleaseChoose"
                                    ),
                                  },
                                  on: { change: _vm.changePeople },
                                  model: {
                                    value: _vm.selectPeople,
                                    callback: function ($$v) {
                                      _vm.selectPeople = $$v
                                    },
                                    expression: "selectPeople",
                                  },
                                },
                                [
                                  _c("template", { slot: "prefix" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (
                                            _vm.selectPeopleList.find(function (
                                              item
                                            ) {
                                              return (
                                                item.value === _vm.selectPeople
                                              )
                                            }) || {}
                                          ).label
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _vm._l(
                                    _vm.selectPeopleList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm.selectPeople == "n1"
                            ? _c(
                                "el-col",
                                { staticStyle: { width: "auto" } },
                                [
                                  _c(
                                    "el-form-item",
                                    { staticClass: "siteClass" },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            loading: _vm.loading,
                                            placeholder: _vm.$t(
                                              "collectionCenter.pleaseInput"
                                            ),
                                            "remote-method":
                                              _vm.driverNameMethod,
                                            clearable: "",
                                            filterable: "",
                                            remote: "",
                                            "reserve-keyword": "",
                                          },
                                          model: {
                                            value:
                                              _vm.queryForm.params.driverId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryForm.params,
                                                "driverId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "queryForm.params.driverId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.driverNameList,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.driverName,
                                                value: item.driverId,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectPeople == "n2"
                            ? _c(
                                "el-col",
                                { staticStyle: { width: "auto" } },
                                [
                                  _c(
                                    "el-form-item",
                                    { staticClass: "siteClass" },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            loading: _vm.loading,
                                            placeholder: _vm.$t(
                                              "collectionCenter.pleaseInput"
                                            ),
                                            "remote-method":
                                              _vm.signDriverNameMethod,
                                            clearable: "",
                                            filterable: "",
                                            remote: "",
                                            "reserve-keyword": "",
                                          },
                                          model: {
                                            value:
                                              _vm.queryForm.params.signDriverId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryForm.params,
                                                "signDriverId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "queryForm.params.signDriverId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.signDriverNameList,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.name,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.bagNumber"),
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseInput"
                              ),
                              clearable: "",
                            },
                            model: {
                              value: _vm.queryForm.params.tagNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryForm.params, "tagNumber", $$v)
                              },
                              expression: "queryForm.params.tagNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.lanshouType"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.customerCollectType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "customerCollectType",
                                    $$v
                                  )
                                },
                                expression:
                                  "queryForm.params.customerCollectType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.toCollect"),
                                  value: "2",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.dropOff"),
                                  value: "1",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { staticClass: "col2" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "selectTimeClass autoWidth",
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "collectionCenter.pleaseChoose"
                                    ),
                                  },
                                  on: { change: _vm.changeCode },
                                  model: {
                                    value: _vm.selectCode,
                                    callback: function ($$v) {
                                      _vm.selectCode = $$v
                                    },
                                    expression: "selectCode",
                                  },
                                },
                                [
                                  _c("template", { slot: "prefix" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (
                                            _vm.selectCodeList.find(function (
                                              item
                                            ) {
                                              return (
                                                item.value === _vm.selectCode
                                              )
                                            }) || {}
                                          ).label
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _vm._l(
                                    _vm.selectCodeList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { staticStyle: { width: "auto" } },
                            [
                              _c(
                                "el-form-item",
                                { staticClass: "siteClass" },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "collectionCenter.BatchQuery"
                                      ),
                                      autosize: "",
                                      type: "textarea",
                                    },
                                    on: { blur: _vm.ft },
                                    nativeOn: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.BlockNewlineNum($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.numberList,
                                      callback: function ($$v) {
                                        _vm.numberList = $$v
                                      },
                                      expression: "numberList",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "col10",
                      staticStyle: { width: "auto" },
                      attrs: { span: 6 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "m" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selectTimeClass autoWidth",
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                              },
                              on: { change: _vm.changeState },
                              model: {
                                value: _vm.selectState,
                                callback: function ($$v) {
                                  _vm.selectState = $$v
                                },
                                expression: "selectState",
                              },
                            },
                            [
                              _c("template", { slot: "prefix" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (
                                        _vm.stateList.find(function (item) {
                                          return item.value === _vm.selectState
                                        }) || {}
                                      ).name
                                    ) +
                                    " "
                                ),
                              ]),
                              _vm._l(_vm.stateList, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.name,
                                    value: item.value,
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                          _vm.selectState === "s1"
                            ? _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "collectionCenter.BatchQuery"
                                  ),
                                  autosize: "",
                                  type: "textarea",
                                },
                                nativeOn: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.BlockNewlineSenderState($event)
                                  },
                                },
                                model: {
                                  value: _vm.queryForm.params.senderState,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "senderState",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.params.senderState",
                                },
                              })
                            : _vm._e(),
                          _vm.selectState === "s2"
                            ? _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "collectionCenter.BatchQuery"
                                  ),
                                  autosize: "",
                                  type: "textarea",
                                },
                                nativeOn: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.BlockNewlineReceiveState($event)
                                  },
                                },
                                model: {
                                  value: _vm.queryForm.params.receiveState,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "receiveState",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.params.receiveState",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "col10",
                      staticStyle: { width: "auto" },
                      attrs: { span: 6 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "m" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selectTimeClass autoWidth",
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                              },
                              on: { change: _vm.changeCity },
                              model: {
                                value: _vm.selectCity,
                                callback: function ($$v) {
                                  _vm.selectCity = $$v
                                },
                                expression: "selectCity",
                              },
                            },
                            [
                              _c("template", { slot: "prefix" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (
                                        _vm.cityList.find(function (item) {
                                          return item.value === _vm.selectCity
                                        }) || {}
                                      ).name
                                    ) +
                                    " "
                                ),
                              ]),
                              _vm._l(_vm.cityList, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.name,
                                    value: item.value,
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                          _vm.selectCity === "c1"
                            ? _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "collectionCenter.BatchQuery"
                                  ),
                                  autosize: "",
                                  type: "textarea",
                                },
                                nativeOn: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.BlockNewlineSenderCity($event)
                                  },
                                },
                                model: {
                                  value: _vm.queryForm.params.senderCity,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "senderCity",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.params.senderCity",
                                },
                              })
                            : _vm._e(),
                          _vm.selectCity === "c2"
                            ? _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "collectionCenter.BatchQuery"
                                  ),
                                  autosize: "",
                                  type: "textarea",
                                },
                                nativeOn: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.BlockNewlineReceiveCity($event)
                                  },
                                },
                                model: {
                                  value: _vm.queryForm.params.receiveCity,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "receiveCity",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.params.receiveCity",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "auto" }, attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "collectionCenter.PlatformWhichBelongs"
                            ),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                              },
                              model: {
                                value: _vm.queryForm.params.platform,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "platform",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.platform",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: 1, label: "shein" },
                              }),
                              _c("el-option", {
                                attrs: { value: 2, label: "kwai" },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t(
                                    "collectionCenter.expressDelivery"
                                  ),
                                  value: 3,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("collectionCenter.other"),
                                  value: 0,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { justify: "space-between", type: "flex" } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:order:lanshou-order:find",
                          expression:
                            "'btn:collection-center:order:lanshou-order:find'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        icon: "el-icon-search",
                        size: "mini",
                        type: "primary",
                      },
                      on: { click: _vm.searchClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.search")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:order:lanshou-order:reset",
                          expression:
                            "'btn:collection-center:order:lanshou-order:reset'",
                          arg: "remove",
                        },
                      ],
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.resetClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:order:lanshou-order:export",
                          expression:
                            "'btn:collection-center:order:lanshou-order:export'",
                          arg: "remove",
                        },
                      ],
                      class:
                        _vm.idList.length <= 0 && _vm.isClick
                          ? "onlyClickOnce10"
                          : "",
                      attrs: { icon: "el-icon-upload2", size: "mini" },
                      on: { click: _vm.download },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.export")))]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:order:lanshou-order:dispatchDriver",
                          expression:
                            "'btn:collection-center:order:lanshou-order:dispatchDriver'",
                          arg: "remove",
                        },
                      ],
                      attrs: { icon: "el-icon-truck", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.schedulingDriver(1)
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("collectionCenter.schedulingDriver")) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:order:lanshou-order:reDispatchDriver",
                          expression:
                            "'btn:collection-center:order:lanshou-order:reDispatchDriver'",
                          arg: "remove",
                        },
                      ],
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.schedulingDriver(2)
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("collectionCenter.schedulingDriverAgain")
                        ) + " "
                      ),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:order:lanshou-order:cancelDispatch",
                          expression:
                            "'btn:collection-center:order:lanshou-order:cancelDispatch'",
                          arg: "remove",
                        },
                      ],
                      attrs: { icon: "el-icon-remove-outline", size: "mini" },
                      on: { click: _vm.cancelDriverClick },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("collectionCenter.cancelSchedule")) + " "
                      ),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:order:lanshou-order:allExport",
                          expression:
                            "'btn:collection-center:order:lanshou-order:allExport'",
                          arg: "remove",
                        },
                      ],
                      class: _vm.isClickAll ? "onlyClickOnce10" : "",
                      attrs: {
                        disabled: _vm.ifExport,
                        icon: "el-icon-upload",
                        size: "mini",
                      },
                      on: { click: _vm.downloadAll },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tableClass" },
        [
          _c(
            "el-table",
            {
              ref: "tableList",
              attrs: { data: _vm.tableDate, "max-height": 600, border: "" },
              on: { select: _vm.batchSelect, "select-all": _vm.batchSelectAll },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.ind"),
                  align: "center",
                  type: "index",
                  width: "60",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.queryForm.pageNumber - 1) *
                                _vm.queryForm.pageSize +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.lanshouOrderNum"),
                  prop: "orderNumber",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.Barcode"),
                  prop: "scanNumber",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.customerOrderNum"),
                  prop: "customerNumber",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.clientID"),
                  prop: "customerId",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.CustomerName"),
                  prop: "customerName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.tipoTitle"),
                  prop: "orderType",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.BagNumber"),
                  prop: "orderBagNumber",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.carNumber"),
                  prop: "tagNumber",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.parcelNum"),
                  prop: "packageNumber",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.lanshouTypee"),
                  prop: "customerCollectTypeName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.bagWeight"),
                  prop: "weight",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.bagCost"),
                  prop: "declaredValue",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.lanshouImg"),
                  align: "center",
                  prop: "finishSignUrl",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              title: _vm.$t("collectionCenter.lanshouImg"),
                              content: "",
                              placement: "left-start",
                              trigger: "hover",
                              width: "300",
                            },
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: scope.row.finishSignUrl,
                                  expression: "scope.row.finishSignUrl",
                                },
                              ],
                              staticStyle: { width: "26px", height: "36px" },
                              attrs: {
                                slot: "reference",
                                src: scope.row.finishSignUrl,
                                alt: "",
                              },
                              slot: "reference",
                            }),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !scope.row.finishSignUrl,
                                    expression: "!scope.row.finishSignUrl",
                                  },
                                ],
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v(_vm._s(_vm.$t("collectionCenter.Null")))]
                            ),
                            scope.row.finishSignUrl
                              ? _c("img", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                  attrs: {
                                    src: scope.row.finishSignUrl,
                                    alt: "",
                                  },
                                })
                              : _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("collectionCenter.NoContentYet")
                                    )
                                  ),
                                ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.orderCreateT"),
                  prop: "createTime",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.ExpectedCollectionTime"),
                  prop: "expectTime",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.ActualCollectionTime"),
                  prop: "scanTime",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.OrderStatus"),
                  prop: "collectStatusName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.collectionDot"),
                  prop: "siteName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.LanShouPeople"),
                  prop: "driverName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.loader"),
                  prop: "loadedUserName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.loadTime"),
                  prop: "loadedTime",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.loadingSignPicture"),
                  align: "center",
                  prop: "loadSignImg",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              title: _vm.$t(
                                "collectionCenter.loadingSignPicture"
                              ),
                              content: "",
                              placement: "left-start",
                              trigger: "hover",
                              width: "300",
                            },
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: scope.row.loadSignImg,
                                  expression: "scope.row.loadSignImg",
                                },
                              ],
                              staticStyle: { width: "26px", height: "36px" },
                              attrs: {
                                slot: "reference",
                                src: scope.row.loadSignImg,
                                alt: "",
                              },
                              slot: "reference",
                            }),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !scope.row.loadSignImg,
                                    expression: "!scope.row.loadSignImg",
                                  },
                                ],
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v(_vm._s(_vm.$t("collectionCenter.Null")))]
                            ),
                            scope.row.loadSignImg
                              ? _c("img", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                  attrs: {
                                    src: scope.row.loadSignImg,
                                    alt: "",
                                  },
                                })
                              : _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("collectionCenter.NoContentYet")
                                    )
                                  ),
                                ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.unloader"),
                  prop: "unloadUserName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.unloadTime"),
                  prop: "unloadTime",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.unloadSignImg"),
                  prop: "unloadSignImg",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              title: _vm.$t("collectionCenter.unloadSignImg"),
                              content: "",
                              placement: "left-start",
                              trigger: "hover",
                              width: "300",
                            },
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: scope.row.unloadSignImg,
                                  expression: "scope.row.unloadSignImg",
                                },
                              ],
                              staticStyle: { width: "26px", height: "36px" },
                              attrs: {
                                slot: "reference",
                                src: scope.row.unloadSignImg,
                                alt: "",
                              },
                              slot: "reference",
                            }),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !scope.row.unloadSignImg,
                                    expression: "!scope.row.unloadSignImg",
                                  },
                                ],
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v(_vm._s(_vm.$t("collectionCenter.Null")))]
                            ),
                            scope.row.unloadSignImg
                              ? _c("img", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                  attrs: {
                                    src: scope.row.unloadSignImg,
                                    alt: "",
                                  },
                                })
                              : _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("collectionCenter.NoContentYet")
                                    )
                                  ),
                                ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.customerMerchantNumber"),
                  prop: "merchantCode",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.NameOfCustomerMerchant"),
                  prop: "merchantName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.signImage"),
                  align: "center",
                  prop: "signerImg",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              title: _vm.$t("collectionCenter.signImage"),
                              content: "",
                              placement: "left-start",
                              trigger: "hover",
                              width: "300",
                            },
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: scope.row.signerImg,
                                  expression: "scope.row.signerImg",
                                },
                              ],
                              staticStyle: { width: "26px", height: "36px" },
                              attrs: {
                                slot: "reference",
                                src: scope.row.signerImg,
                                alt: "",
                              },
                              slot: "reference",
                            }),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !scope.row.signerImg,
                                    expression: "!scope.row.signerImg",
                                  },
                                ],
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v(_vm._s(_vm.$t("collectionCenter.Null")))]
                            ),
                            scope.row.signerImg
                              ? _c("img", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                  attrs: { src: scope.row.signerImg, alt: "" },
                                })
                              : _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("collectionCenter.NoContentYet")
                                    )
                                  ),
                                ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.deliveryDot"),
                  prop: "signSiteName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.signer"),
                  prop: "signerUsername",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.signT"),
                  prop: "signerTime",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.postZ"),
                  prop: "senderZipcode",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.postAds"),
                  prop: "senderAddress",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.postT"),
                  prop: "senderPhone",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.platform"),
                  prop: "platformName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.closeTime"),
                  prop: "closeTime",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.commodityInvoiceNumber"),
                  prop: "goodsInvoicNo",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.logisticsInvoiceType"),
                  prop: "logisticsInvoiceType",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.logisticsInvoiceNumber"),
                  prop: "logisticsInvoiceNo",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.invoiceSecretKey"),
                  prop: "logisticsInvoiceSecretKey",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("newOrder.CancellationTime"),
                  prop: "cancelTime",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.cancelReason"),
                  "show-overflow-tooltip": false,
                  align: "center",
                  prop: "cancelReason",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-tooltip", { attrs: { placement: "top" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "max-height": "200px",
                                overflow: "auto",
                                "max-width": "500px",
                              },
                              attrs: { slot: "content" },
                              slot: "content",
                            },
                            [_vm._v(_vm._s(scope.row.cancelReason))]
                          ),
                          _c("div", { staticClass: "paramsStyle" }, [
                            _vm._v(_vm._s(scope.row.cancelReason)),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.batchNo"),
                  prop: "batchNo",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.waveNumberCreationTime"),
                  prop: "cutOffCreateTime",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.actPickUpCode"),
                  prop: "actPickUpCode",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.clearImage"),
                  prop: "clearImageList",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(
                        scope.row.clearImageList,
                        function (cImg, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticStyle: { "max-height": "60px" },
                            },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "top",
                                    "popper-class": "copy",
                                    trigger: "hover",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copy(cImg)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("orderDetails.copy")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "clearImageClass",
                                      attrs: { slot: "reference" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openImg(cImg)
                                        },
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v(_vm._s(cImg))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      )
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operatingPlatform.scanWeight"),
                  align: "center",
                  prop: "weighingWeight",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operatingPlatform.volumeWeight"),
                  align: "center",
                  prop: "volumeWeight",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operatingPlatform.long"),
                  align: "center",
                  prop: "length",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operatingPlatform.width"),
                  align: "center",
                  prop: "width",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operatingPlatform.height"),
                  align: "center",
                  prop: "height",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("newOrder.clientExitTime"),
                  align: "center",
                  prop: "customerSendTime",
                },
              }),
              _vm.isViewInformation
                ? _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("collectionCenter.operation"),
                      align: "center",
                      fixed: "right",
                      width: "160",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "white-space": "normal" },
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.cellClick(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("collectionCenter.viewInformation")
                                    ) + " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1085047911
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-pagination", {
            staticStyle: { margin: "10px 0" },
            attrs: {
              "current-page": _vm.queryForm.pageNumber,
              "page-size": _vm.queryForm.pageSize,
              "page-sizes": _vm.isViewMoreRows
                ? [10, 20, 50, 100, 500, 1000, 2000]
                : [10, 20, 50, 100, 500, 1000],
              total: _vm.total,
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "current-change": _vm.getLanShouOrdersPage,
              "size-change": _vm.getPageSize,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "35%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialogBox" },
            [
              _c(
                "el-form",
                {
                  ref: "ScheduleBox",
                  attrs: {
                    model: _vm.dialogForm,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("collectionCenter.site") } },
                    [
                      _c(
                        "div",
                        { staticClass: "dialogC" },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              on: {
                                change: _vm.chooseSite,
                                focus: _vm.getNewestSite,
                              },
                              model: {
                                value: _vm.dialogForm.siteName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dialogForm, "siteName", $$v)
                                },
                                expression: "dialogForm.siteName",
                              },
                            },
                            _vm._l(_vm.siteIdL, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("collectionCenter.driver") } },
                    [
                      _c(
                        "div",
                        { staticClass: "dialogC" },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              on: { focus: _vm.focusDriver },
                              model: {
                                value: _vm.dialogForm.driverId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dialogForm, "driverId", $$v)
                                },
                                expression: "dialogForm.driverId",
                              },
                            },
                            _vm._l(_vm.driverList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.username, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.ExpectedCollectionT"),
                        prop: "expectLanshouTime",
                      },
                    },
                    [
                      _c("div", { staticClass: "dialogC" }, [
                        _c(
                          "div",
                          { staticClass: "block" },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                clearable: false,
                                "picker-options": _vm.pickerOptionsSJ,
                                placeholder: _vm.$t(
                                  "collectionCenter.pleaseChoose"
                                ),
                                type: "datetime",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                              },
                              model: {
                                value: _vm.dialogForm.expectLanshouTime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dialogForm,
                                    "expectLanshouTime",
                                    $$v
                                  )
                                },
                                expression: "dialogForm.expectLanshouTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.contactPhone"),
                        prop: "expectLanshouTel",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "dialogC" },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseInput"
                              ),
                              clearable: "",
                            },
                            model: {
                              value: _vm.dialogForm.expectLanshouTel,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dialogForm,
                                  "expectLanshouTel",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "dialogForm.expectLanshouTel",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.LanShouAddress"),
                        prop: "expectLanshouAddress",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "dialogC" },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "collectionCenter.pleaseInput"
                              ),
                              autosize: "",
                              clearable: "",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.dialogForm.expectLanshouAddress,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dialogForm,
                                  "expectLanshouAddress",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "dialogForm.expectLanshouAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("collectionCenter.note") } },
                    [
                      _c(
                        "div",
                        { staticClass: "dialogC" },
                        [
                          _c("el-input", {
                            attrs: {
                              autosize: "",
                              placeholder: "",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.dialogForm.expectLanshouScanNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dialogForm,
                                  "expectLanshouScanNumber",
                                  $$v
                                )
                              },
                              expression: "dialogForm.expectLanshouScanNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.dialogCancel } }, [
                _vm._v(_vm._s(_vm.$t("collectionCenter.cancel"))),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.dialogConfirm },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.drawerIsOpen
        ? _c(
            "div",
            { staticClass: "drawerOpen" },
            [
              _c(
                "el-drawer",
                {
                  attrs: {
                    direction: _vm.direction,
                    title: _vm.drawerTitle,
                    visible: _vm.drawerIsOpen,
                    size: "40%",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.drawerIsOpen = $event
                    },
                  },
                },
                [
                  _c("div", { staticClass: "drawerBox" }, [
                    _c("div", { staticClass: "drawerClass" }, [
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("collectionCenter.recipient"))),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.receiveName)),
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("collectionCenter.RecipientMailbox"))
                          ),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.receiveMail)),
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("collectionCenter.RecipientzipCode"))
                          ),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.receiveZipcode)),
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("collectionCenter.RecipientPhone"))
                          ),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.receivePhone)),
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("collectionCenter.receiptState"))
                          ),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.receiveState)),
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("collectionCenter.receiptCity"))
                          ),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.receiveCity)),
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("collectionCenter.receiptRegional"))
                          ),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.receiveArea)),
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("collectionCenter.receiptAddress"))
                          ),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.receiveAddress)),
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("collectionCenter.receiptStreet"))
                          ),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.receiveStreet)),
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("collectionCenter.receiptHouseNumber")
                            )
                          ),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.receiveHouseNumber)),
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("collectionCenter.receiptNeighborhood")
                            )
                          ),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.receiveNeighborhood)),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "drawerBox" }, [
                    _c("div", { staticClass: "drawerClass mb5" }, [
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("collectionCenter.postPerson"))),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.senderName)),
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("collectionCenter.postMail"))),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.senderMail)),
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("collectionCenter.postZip"))),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.senderZipcode)),
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("collectionCenter.postTel"))),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.senderPhone)),
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("collectionCenter.postState"))),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.senderState)),
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("collectionCenter.postCity"))),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.senderCity)),
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("collectionCenter.postRegional"))
                          ),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.senderArea)),
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("collectionCenter.postAddress"))
                          ),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.senderAddress)),
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("collectionCenter.postStreet"))),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.senderStreet)),
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("collectionCenter.postHouseNumber"))
                          ),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.senderHouseNumber)),
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("collectionCenter.postNeighborhood"))
                          ),
                        ]),
                        _vm._v(_vm._s(_vm.drawerList.senderNeighborhood)),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }