<template>
  <el-upload
    ref="resourcesUp"
    action
    :accept="accept"
    :auto-upload="autoUpload"
    :http-request="enclosureUpload"
    :on-exceed="handleExceed"
    :on-remove="handleRemove"
    :multiple="multiple"
    :limit="limit"
    :drag="drag"
    :before-upload="beforeUpload"
    :file-list="fileList"
    :on-error="onError"
    :on-change="handleChange"
  >
    <template v-if="drag">
      <i class="el-icon-upload" />
      <!-- 将文件拖到此处，或   点击上传  -->
      <!-- <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div> -->
      <div class="el-upload__text">{{ uploadText }}</div>

      <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
      <div slot="tip" class="el-upload__tip">{{ tips }}</div>
    </template>

    <template v-else>
      <!-- 选取文件 -->
      <el-button
        slot="trigger" size="small" type="primary"
        style="margin-right: 5px"
      >{{ $t('spareNumberPoo.SelectTheFile') }}</el-button>
      <!-- <el-button size="small" type="success" @click="handleUpload">上传</el-button> -->
    </template>
  </el-upload>
</template>

<script>
import { uploadOos } from '@/utils/ali_oos_util';
import i18n from '@/lang/index.js';

// const customHeaders = {
//   'Content-Type': 'application/x-www-form-urlencoded' // 这里设置编码格式
// };

export default {
  name: '',

  props: {
    // 回显文件列表
    fileArr: {
      type: Array,
      default: () => []
    },

    // 文件在服务器上的文件夹名称
    serverFolderName: {
      type: String,
      default: 'workOrder'
    },

    // 限制上传文件大小 单位MB
    fileSize: {
      type: Number,
      default: 5
    },

    // 文件类型 需要传入文件的后缀，而非文件后缀名
    // 如excel 需要传入 ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
    /**
     * 文件类型 需要传入文件的后缀，而非文件后缀名
     * 如excel 需要传入 ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
     * 可以参考文件 src\enums\evaluateEnums.js
     */
    fileType: {
      type: Array,
      default: () => []
    },

    // 是否自动上传
    autoUpload: {
      type: Boolean,
      default: false
    },

    // 限制上传文件数量
    limit: {
      type: Number,
      default: 1
    },

    // 是否多选
    multiple: {
      type: Boolean,
      default: false
    },

    // 是否启用拖拽上传
    drag: {
      type: Boolean,
      default: false
    },

    // 提示文字
    tips: {
      type: String,
      default: ''
    },

    // 上传框内文字提示
    uploadText: {
      type: String,
      default: i18n.t('basicData.DragTheFileHereOrClickUpload')
    }
  },

  data() {
    return {
      fileList: []
    };
  },

  computed: {
    // 文件类型
    accept() {
      if (this.fileType.length === 0) {
        return '';
      } else {
        return this.fileType.join(',');
      }
    }
  },

  watch: {
    fileArr: {
      handler(val) {
        if (val.length !== 0) {
          this.fileList = val;
        }
      },
      immediate: true,
      deep: true
    }
  },

  created() {

  },

  methods: {
    // 文件上传
    enclosureUpload(option) {
      const file = option.file;
      uploadOos(file, 'workOrder').then(res => {
        if (res.code === 200) {
          const obj = {
            name: file.name,
            url: res.url
          };
          this.fileList.push(obj);
          this.$emit('change', this.fileList);
        }
      }).catch(err => {
        console.error(err);
      });
    },

    // 上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
    beforeUpload(file) {
      const fileSize = file.size / 1024 / 1024 > this.fileSize;

      // 上传的文件大小不能超过 5MB
      if (fileSize) {
        this.$message.warning(`上传文件大小不能超过 ${this.fileSize}MB!`);
        return false;
      }

      // 上传的文件类型限制
      if (this.fileType.length !== 0) {
        const isBool = this.fileType.includes(file.type);
        if (!isBool) {
          this.$message.warning('文件格式不正确');
          return false;
        }
      }
      return true;
    },

    handleChange(file, fileList) {
    },

    // 资源上传数量限制
    handleExceed() {
      this.$message.warning(`只能上传${this.limit}个文件`);
    },

    // 添加未确定删除文件
    handleRemove(file, fileList) {
      this.fileList = fileList;
      this.$emit('change', this.fileList);
    },

    // 开始上传
    handleUpload() {
      this.$refs.resourcesUp.submit();
    },

    // 上传失败
    onError(err, file) {
      console.error(err);
      this.$message.error(`${file.name} 上传失败`);
      // this.$refs.resourcesUp.clearFiles();
    },

    // 清空列表
    clearFiles() {
      this.fileList = [];
      this.$refs.resourcesUp.clearFiles();
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
