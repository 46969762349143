<template>
  <div class="app-container">
    <div ref="searchForm" class="mb5">
      <el-card class="box-card">
        <el-form
          ref="form" :model="queryForm" label-width="auto"
          label-position="top"
        >
          <div class="searchContainer">
            <el-row :gutter="10">
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--路线-->
                  <el-form-item :label="$t('GlobalSetObj.route') + '：'">
                    <el-input v-model="queryForm.params.routeNumber" />
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--路线类型-->
                  <el-form-item :label="$t('basicData.typeOfRoute') + '：'">
                    <el-select
                      v-model="queryForm.params.routeType"
                      :placeholder="$t('GlobalSetObj.all')"
                      clearable
                      filterable
                      style="width:100%"
                    >
                      <el-option
                        v-for="item in LINE_TYPE"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--始发网点-->
                  <el-form-item :label="$t('GlobalSetObj.StartingStation') + '：'">
                    <el-select
                      v-model="queryForm.params.originSiteId"
                      :placeholder="$t('GlobalSetObj.all')"
                      clearable
                      filterable
                      style="width:100%"
                    >
                      <el-option
                        v-for="item in siteList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <!--州-->
                  <el-form-item :label="$t('GlobalSetObj.state') + '：'">
                    <el-select
                      v-model="queryForm.params.provincesId"
                      :placeholder="$t('GlobalSetObj.all')"
                      clearable
                      filterable
                      style="width:100%"
                    >
                      <el-option
                        v-for="item in allTraceStateList"
                        :key="item.id"
                        :label="item.twoCode + (item.name ? '（' + item.name + '）' : '')"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-form>
        <!--搜索-->
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="searchResources()"
        >
          {{ $t('operationCenter.Procurar') }}
        </el-button>
        <el-button
          type="info"
          size="small"
          icon="el-icon-refresh-left"
          @click="resetResources()"
        >
          {{ $t('operationCenter.reset') }}
        </el-button>
      </el-card>
    </div>
    <el-card class="box-card">
      <div class="mb10">
        <div class="btn-group">
          <div class="btn-group-item">
            <!--添加-->
            <el-button
              v-permit:remove="'btn:payableManagement:routeDistanceReference:add'"
              size="small"
              type="primary"
              @click="appendRoute(1)"
            >{{ $t('GlobalSetObj.append') }}</el-button>
            <!--导入-->
            <el-popover
              placement="bottom-start"
              trigger="hover"
            >
              <div>
                <import-download
                  :key="downloadKey" :site-list="siteList"
                  :all-trace-state-list="allTraceStateList" @importPartition="importPartition"
                />
              </div>
              <!--导入/下载-->
              <el-button
                slot="reference"
                v-permit:remove="'btn:payableManagement:routeDistanceReference:Import'"
                size="small"
                style="margin-inline: 10px"
              >
                {{ $t('basicData.ImportDownload') }}
              </el-button>
            </el-popover>
            <!--更新距离-->
            <el-button
              v-permit:remove="'btn:payableManagement:routeDistanceReference:UpdateDistance'"
              size="small"
              type="primary"
              :disabled="!selectedData.length"
              @click="updateDistance()"
            >{{ $t('GlobalSetObj.UpdateDistance') }}</el-button>
            <!--导出-->
            <el-button
              v-permit:remove="'btn:payableManagement:routeDistanceReference:export'"
              size="small"
              type="info"
              @click="exportRoute()"
            >{{ $t('CenterForSorting.export') }}</el-button>
          </div>
        </div>
      </div>
      <div class="pending">
        <el-table
          ref="elTable"
          :key="tableKey"
          class="table-height"
          :data="tableData"
          row-key="id"
          border
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          />
          <el-table-column
            :label="$t('operationCenter.ind')"
            align="center"
            width="80px"
          >
            <template slot-scope="scope">
              <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-for="item in currentColumns"
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
            :align="item.align"
            :min-width="item.width"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <!--是否启用-->
              <div v-if="item.prop === 'state'">
                <el-tag v-show="scope.row.state === 1" type="danger" size="small">
                  {{ $t('GlobalSetObj.stop') }}
                </el-tag>
                <el-tag v-show="scope.row.state === 0" type="success" size="small">
                  {{ $t('GlobalSetObj.enable') }}
                </el-tag>
              </div>
              <!--是否始发网点-->
              <div v-else-if="item.prop === 'originatingPoint'">
                <el-tag v-show="scope.row.originatingPoint === 0" type="success" size="small">
                  {{ $t('GlobalSetObj.yes') }}
                </el-tag>
                <el-tag v-show="scope.row.originatingPoint === 1" type="danger" size="small">
                  {{ $t('GlobalSetObj.No') }}
                </el-tag>
              </div>
              <!--最后修改人-->
              <div v-else-if="item.prop === 'updateUser'">
                {{ userIdAndNameObj[scope.row.updateUser] }}
              </div>
              <!--路线类型-->
              <div v-else-if="item.prop === 'routeType'">
                {{ LINE_TYPE[scope.row.routeType] && LINE_TYPE[scope.row.routeType].label || '' }}
              </div>
              <div v-else>{{ scope.row[item.prop] }}</div>
            </template>
          </el-table-column>
          <!--操作-->
          <el-table-column
            :label="$t('basicData.operate')"
            align="center"
            width="200"
            fixed="right"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="appendRoute(2, scope.row)"
              >{{ $t('GlobalSetObj.edit') }}</el-button> <!--编辑-->
              <el-button
                v-permit:remove="'btn:payableManagement:routeDistanceReference:delete'"
                type="danger"
                size="mini"
                @click="deleteItem(scope.row)"
              >{{ $t('receivablePayable.Delete') }}</el-button> <!--删除-->
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页工具 -->
        <div class="pagination">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryForm.pageNumber"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size.sync="queryForm.pageSize"
            :total="total"
            background
            style="margin: 10px 0"
            @current-change="getExamineCurrentPage"
            @size-change="getExamineSizePage"
          />
        </div>
      </div>
    </el-card>
    <!--  新增路线距离参考  -->
    <el-drawer
      :append-to-body="true"
      :visible.sync="showDetail"
      :close-on-press-escape="false"
      :with-header="false"
      :wrapper-closable="false"
      direction="ltr"
      custom-class="drawerClass"
      size="600px"
    >
      <addDetail
        :key="showDetail"
        :site-list="siteList"
        :all-trace-state-list="allTraceStateList"
        :add-or-edit="addOrEdit"
        :detail-data="detailData"
        @closeAdd="closeAdd"
      />
    </el-drawer>
  </div>
</template>
<script>
import {
  apiRouteDistanceReferenceDelete,
  apiRouteDistanceReferenceExport,
  apiRouteDistanceReferencePage,
  apiRouteDistanceReferenceToUpdateDistance
} from '@/api/finance/receivables';
import { selectInfoByStateTwo } from '@/api/orderApi';
import { apiAreaPage } from '@/api/finance/basicData';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { goResourceTaskList } from '@/utils/goExportList';
import { cloneDeep } from 'lodash-es';
import addDetail from '@/views/finance/basicData/cope/routeDistanceReference/cmp/addDetail.vue';
import { LINE_TYPE } from '@/views/finance/enums.js';

export default {
  name: 'RouteDistanceReference',
  components: {
    addDetail,
    'import-download': () => import('./cmp/importDownload.vue')
  },
  data() {
    return {
      LINE_TYPE,
      // 搜索表单
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          routeNumber: '',
          originSiteId: '',
          provincesId: ''
        }
      },
      siteList: [], // 网点
      allTraceStateList: [], // 全部州
      tableKey: new Date().getTime(),
      tableData: [], // 列表展示数据
      total: 0,
      addOrEdit: 1, // 1 新增 2 编辑
      showDetail: false,
      detailData: {},
      activeName: '1',
      selectedData: [], // 列表已勾选数据
      downloadKey: new Date().getTime(),
      userIdAndNameObj: null
    };
  },
  computed: {
    currentColumns() {
      return [
        // 路线
        { prop: 'routeNumber', label: this.$i18n.t('routes.routesTitle'), width: 220, align: 'left' },
        // 路线类型
        { prop: 'routeType', label: this.$i18n.t('basicData.typeOfRoute'), width: 120, align: 'left' },
        // 出发地网点
        { prop: 'originSiteName', label: this.$i18n.t('GlobalSetObj.departurePointSite'), width: 220, align: 'left' },
        // 出发地网点地址
        { prop: 'originSiteAddress', label: this.$i18n.t('GlobalSetObj.departurePointBranchAddress'), width: 220, align: 'left' },
        // 出发地经纬度
        { prop: 'originSiteLatLongitude', label: this.$i18n.t('GlobalSetObj.DepartureLatitudeAndLongitude'), width: 220, align: 'left' },
        // 目的地网点
        { prop: 'destinationSiteName', label: this.$i18n.t('GlobalSetObj.destinationPointSite'), width: 220, align: 'left' },
        // 目的地网点地址
        { prop: 'destinationSiteAddress', label: this.$i18n.t('GlobalSetObj.destinationPointBranchAddress'), width: 220, align: 'left' },
        // 目的地经纬度
        { prop: 'destinationSiteLatLongitude', label: this.$i18n.t('GlobalSetObj.DestinationLatitudeAndLongitude'), width: 220, align: 'left' },
        // 距离（KM）
        { prop: 'distance', label: this.$i18n.t('collectionCenter.distance') + '(KM)', width: 220, align: 'left' },
        // 始发网点
        { prop: 'originatingPoint', label: this.$i18n.t('GlobalSetObj.StartingStation'), width: 220, align: 'left' },
        // 始发地-目的地距离（KM）
        { prop: 'totalDistance', label: this.$i18n.t('GlobalSetObj.DistanceDestination') + '(KM)', width: 220, align: 'left' },
        // 所属州
        { prop: 'provinces', label: this.$i18n.t('operationTips.belongState'), width: 220, align: 'left' },
        // 是否启用
        { prop: 'state', label: this.$i18n.t('collectionCenter.ifUse'), width: 220, align: 'left' },
        // 最后修改人
        { prop: 'updateUser', label: this.$i18n.t('basicData.LastModifier'), width: 220, align: 'left' },
        // 最后修改时间
        { prop: 'updateTime', label: this.$i18n.t('basicData.LastModified'), width: 220, align: 'left' }
      ];
    }
  },
  created() {
    // 获取网点选项
    this.getSiteList();
    // 获取州选项
    this.getTraceStateList();
    // 获取列表
    this.getTableData();
    this.userIdAndNameObj = getUserIdAndNameObj();
  },
  methods: {
    // 打开新增\编辑路线弹窗
    appendRoute(type, row) {
      this.showDetail = true;
      this.addOrEdit = type;
      if (row) {
        this.detailData = cloneDeep(row);
      }
    },
    // 获取网点列表
    getSiteList() {
      const data = {};
      selectInfoByStateTwo(data).then((res) => {
        if (res.status === 'OK') {
          this.siteList = res.data.siteList;
        }
      });
    },
    // 获取轨迹州列表
    getTraceStateList() {
      const param = {
        pageNumber: 1,
        pageSize: 99999,
        params: {
          level: 1,
          parentId: ''
        }
      };
      apiAreaPage(param, false).then(res => {
        if (res.code === 200) {
          this.allTraceStateList = res.data.records;
        }
      });
    },
    // 关闭详情弹窗
    closeAdd(type) {
      this.detailData = {};
      this.showDetail = false;
      if (type) {
        this.getTableData();
      }
    },
    // 获取列表数据
    getTableData() {
      const table = this.$refs.elTable;
      const selectedKeys = this.selectedData;
      apiRouteDistanceReferencePage(this.queryForm).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.total = res.data.total;

          // 记录选中行状态，防止刷新后消失
          this.$nextTick(() => {
            table?.clearSelection(); // 先清空所有选中状态
            const rows = this.tableData.filter(data => selectedKeys.includes(data.id));
            rows.forEach(row => {
              table.toggleRowSelection(row);
            });
          });
        }
      });
    },
    // 导出路线参照表
    exportRoute() {
      this.queryForm.params.ids = this.selectedData;
      apiRouteDistanceReferenceExport(this.queryForm.params).then(res => {
        if (res.code === 200) {
          goResourceTaskList();
        }
      });
    },
    // 更新距离
    updateDistance() {
      this.$confirm(this.$t('GlobalSetObj.confirmToUpdateTheSelectedRouteDistance'), this.$t('operationCenter.tips'), {
        confirmButtonText: this.$t('customerManagements.confirm'), // '确定',
        cancelButtonText: this.$t('customerManagements.cancel'), // '取消',
        type: 'warning'
      }).then(() => {
        const param = {
          ids: this.selectedData
        };
        apiRouteDistanceReferenceToUpdateDistance(param).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('GlobalSetObj.TheOperationSucceeded')); // 操作成功
            this.getTableData();
          }
        });
      });
    },
    // 搜索
    searchResources() {
      this.queryForm.pageNumber = 1;
      this.getTableData();
    },
    // 重置
    resetResources() {
      this.queryForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          routeNumber: '',
          originSiteId: '',
          provincesId: ''
        }
      };
    },
    // 删除列表行
    deleteItem(row) {
      this.$confirm(this.$t('customerManagements.AreYouSureDelete'), this.$t('operationCenter.tips'), {
        confirmButtonText: this.$t('customerManagements.confirm'), // '确定',
        cancelButtonText: this.$t('customerManagements.cancel'), // '取消',
        type: 'warning'
      }).then(() => {
        apiRouteDistanceReferenceDelete(row.id).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('GlobalSetObj.TheOperationSucceeded')); // 操作成功
            this.getTableData();
          }
        });
      });
    },
    // 列表勾选数据
    handleSelectionChange(arr) {
      this.selectedData = [];
      arr.map(item => {
        this.selectedData.push(item.id);
      });
    },
    // 导入成功更新列表数据
    importPartition() {
      this.getTableData();
    },
    // 点击页码翻页
    getExamineCurrentPage(val) {
      this.queryForm.pageNumber = val;
      this.getTableData();
    },
    // 每页显示数量变化
    getExamineSizePage(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getTableData();
    }
  }
};
</script>

<style scoped>

</style>
