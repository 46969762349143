var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "box-card" },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "15px", "font-weight": "bold" } },
        [_vm._v("网点滞留数据")]
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.stayTrackingVOS,
            "header-cell-style": { background: "#f2f2f2", color: "black" },
            height: "375px",
            stripe: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("navbar.siteName"),
              align: "center",
              prop: "siteName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "24H滞留",
              prop: "twentyFourHours",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.siteId === null
                      ? _c("div", { staticClass: "fontClsBold" }, [
                          _vm._v(" " + _vm._s(scope.row.twentyFourHours) + " "),
                        ])
                      : _c("div", [_vm._v(_vm._s(scope.row.twentyFourHours))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "48H滞留",
              prop: "fortyEightHours",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.siteId === null
                      ? _c("div", { staticClass: "fontClsBold" }, [
                          _vm._v(" " + _vm._s(scope.row.fortyEightHours) + " "),
                        ])
                      : _c("div", [_vm._v(_vm._s(scope.row.fortyEightHours))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "72H滞留",
              prop: "seventyTwoHours",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.siteId === null
                      ? _c("div", { staticClass: "fontClsBold" }, [
                          _vm._v(" " + _vm._s(scope.row.seventyTwoHours) + " "),
                        ])
                      : _c("div", [_vm._v(_vm._s(scope.row.seventyTwoHours))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "7天滞留", prop: "sevenDays" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.siteId === null
                      ? _c("div", { staticClass: "fontClsBold" }, [
                          _vm._v(" " + _vm._s(scope.row.sevenDays) + " "),
                        ])
                      : _c("div", [_vm._v(_vm._s(scope.row.sevenDays))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "15天滞留", prop: "fifteenDays" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.siteId === null
                      ? _c("div", { staticClass: "fontClsBold" }, [
                          _vm._v(" " + _vm._s(scope.row.fifteenDays) + " "),
                        ])
                      : _c("div", [_vm._v(_vm._s(scope.row.fifteenDays))]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }