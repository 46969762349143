var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("div", { staticClass: "timeClass" }, [
            _vm.activeName === "hz"
              ? _c(
                  "div",
                  {
                    staticClass: "allExportClass",
                    on: { click: _vm.allExport },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
                )
              : _vm._e(),
            _vm.activeName === "mx"
              ? _c(
                  "div",
                  {
                    staticClass: "allExportClass",
                    on: { click: _vm.exportDetail },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.明细导出")))]
                )
              : _vm._e(),
            _c("div", [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.updateTime,
                      expression: "updateTime",
                    },
                  ],
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("GlobalSetObj.UpdateTime")) +
                      "：" +
                      _vm._s(_vm.updateTime)
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "dateBox" }, [
              _c(
                "span",
                {
                  class: { d1: _vm.type == 4 },
                  on: {
                    click: function ($event) {
                      return _vm.getData(4)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.beforeYesterday")))]
              ),
              _c(
                "span",
                {
                  class: { d1: _vm.type == 0 },
                  on: {
                    click: function ($event) {
                      return _vm.getData(0)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.yesterday")))]
              ),
              _c(
                "span",
                {
                  class: { d1: _vm.type == 1 },
                  on: {
                    click: function ($event) {
                      return _vm.getData(1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.sameDay")))]
              ),
              _c(
                "span",
                {
                  class: { d1: _vm.type == 2 },
                  on: {
                    click: function ($event) {
                      return _vm.getData(2)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.nearlyAWeek")))]
              ),
              _c(
                "span",
                {
                  class: { d1: _vm.type == 3 },
                  on: {
                    click: function ($event) {
                      return _vm.getData(3)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.sameMonth")))]
              ),
            ]),
          ]),
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.tabName + _vm.$t("collectionCenter.summary"),
                    name: "hz",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "tableClass" },
                    [
                      _c(
                        "el-table",
                        {
                          staticClass: "t1",
                          attrs: { data: _vm.tableData1, border: "" },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectTotalNum",
                              label: _vm.$t("collectionCenter.MDScanVolume"),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "skipClass",
                                        staticStyle: {
                                          "font-size": "20px",
                                          "font-weight": "bold",
                                          color: "#3972BA",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toMX(scope.row, 1)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.collectTotalNum) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectLoadTotalNum",
                              label: _vm.$t("collectionCenter.loadCapacity"),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "skipClass",
                                        staticStyle: {
                                          "font-size": "20px",
                                          "font-weight": "bold",
                                          color: "#22B956",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toMX(scope.row, 2)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.collectLoadTotalNum
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "pendingLoadTotalNum",
                              label: _vm.$t("collectionCenter.stayTruckload"),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "skipClass",
                                        staticStyle: {
                                          "font-size": "20px",
                                          "font-weight": "bold",
                                          color: "#EF9F03",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toMX(scope.row, 3)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.pendingLoadTotalNum
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectLoadRate",
                              label: _vm.$t("collectionCenter.loadingRate"),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "20px",
                                          "font-weight": "bold",
                                          color: "#22B956",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.collectLoadRate) +
                                            "%"
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "pendingLoadRate",
                              label: _vm.$t("collectionCenter.stayLoadRate"),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "20px",
                                          "font-weight": "bold",
                                          color: "#F23237",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.pendingLoadRate) +
                                            "%"
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-table",
                        {
                          staticClass: "t2",
                          attrs: {
                            data: _vm.tableData2,
                            "max-height": 600,
                            border: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "siteName",
                              label: _vm.$t("collectionCenter.MDName"),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", { staticClass: "clickClass" }, [
                                      _vm._v(_vm._s(scope.row.siteName)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectLoadNum",
                              label: _vm.$t("collectionCenter.loadCapacity"),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "skipClass",
                                        on: {
                                          click: function ($event) {
                                            return _vm.toMX(scope.row, 4)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.collectLoadNum))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "estimatedLoadNum",
                              label: _vm.$t(
                                "collectionCenter.predictTruckload"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "skipClass pC",
                                        on: {
                                          click: function ($event) {
                                            return _vm.toMX(scope.row, 5)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.estimatedLoadNum)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectLoadScanNum",
                              label: _vm.$t(
                                "collectionCenter.loadedScansNumber"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "skipClass",
                                        on: {
                                          click: function ($event) {
                                            return _vm.toMX(scope.row, 6)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.collectLoadScanNum)
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "loadScanRate",
                              label: _vm.$t("collectionCenter.loadingScanRate"),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(scope.row.loadScanRate) + "%"
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c("el-pagination", {
                            staticStyle: { margin: "10px 0" },
                            attrs: {
                              layout: "total, sizes, prev, pager, next, jumper",
                              "current-page": _vm.form2.pageNumber,
                              "page-sizes": [10, 20, 50, 100, 500, 1000],
                              "page-size": _vm.form2.pageSize,
                              total: _vm.total2,
                              background: "",
                            },
                            on: {
                              "update:currentPage": function ($event) {
                                return _vm.$set(_vm.form2, "pageNumber", $event)
                              },
                              "update:current-page": function ($event) {
                                return _vm.$set(_vm.form2, "pageNumber", $event)
                              },
                              "update:pageSize": function ($event) {
                                return _vm.$set(_vm.form2, "pageSize", $event)
                              },
                              "update:page-size": function ($event) {
                                return _vm.$set(_vm.form2, "pageSize", $event)
                              },
                              "current-change": _vm.getDatePage,
                              "size-change": _vm.getPageSize,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm.activeName == "mx"
                ? _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label:
                          _vm.tabName + _vm.$t("collectionCenter.particulars"),
                        name: "mx",
                      },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          staticClass: "t2",
                          attrs: {
                            data: _vm.tableDataMX,
                            "max-height": 600,
                            border: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "scanNumber",
                              label: _vm.$t("collectionCenter.Barcode"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "bagNumber",
                              label: _vm.$t("collectionCenter.BagNumber"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectTime",
                              label: _vm.$t("collectionCenter.lanshouT"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectUserName",
                              label: _vm.$t("collectionCenter.LanShouPeople"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "bagTime",
                              label: _vm.$t("collectionCenter.sealingBagTime"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "bagUserName",
                              label: _vm.$t("collectionCenter.BagSealer"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "siteLoadTime",
                              label: _vm.$t(
                                "collectionCenter.networkLoadingTime"
                              ),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "siteLoadSiteName",
                              label: _vm.$t(
                                "customerManagements.loadSiteIdList"
                              ),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "siteLoadUserName",
                              label: _vm.$t("collectionCenter.networkLoader"),
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "siteLoadVehicleNumber",
                              label: _vm.$t(
                                "collectionCenter.licensePlateNumber"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c("el-pagination", {
                            staticStyle: { margin: "10px 0" },
                            attrs: {
                              layout: "total, sizes, prev, pager, next, jumper",
                              "current-page": _vm.formMX.pageNumber,
                              "page-sizes": [10, 20, 50, 100, 500, 1000],
                              "page-size": _vm.formMX.pageSize,
                              total: _vm.totalMX,
                              background: "",
                            },
                            on: {
                              "update:currentPage": function ($event) {
                                return _vm.$set(
                                  _vm.formMX,
                                  "pageNumber",
                                  $event
                                )
                              },
                              "update:current-page": function ($event) {
                                return _vm.$set(
                                  _vm.formMX,
                                  "pageNumber",
                                  $event
                                )
                              },
                              "update:pageSize": function ($event) {
                                return _vm.$set(_vm.formMX, "pageSize", $event)
                              },
                              "update:page-size": function ($event) {
                                return _vm.$set(_vm.formMX, "pageSize", $event)
                              },
                              "current-change": _vm.getDatePageMX,
                              "size-change": _vm.getPageSizeMX,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }