var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex" } },
    [
      _c("el-card", { staticClass: "box-card", staticStyle: { flex: "1" } }, [
        _c("div", { staticClass: "labelRight" }, [
          _c("div", { staticClass: "labelRight_item" }, [
            _c("div", { staticClass: "labelRight_itemTitle" }, [
              _vm._v("在途票数"),
            ]),
            _c("div", { staticClass: "labelRight_itemNumber" }, [
              _vm._v(_vm._s(_vm.onDataV3.enRoute || 0)),
            ]),
          ]),
          _c("div", [
            _c("div", { staticClass: "labelRight_itemTitle" }, [
              _vm._v("实时库存"),
            ]),
            _c(
              "div",
              {
                staticClass: "labelRight_itemNumber",
                staticStyle: { "margin-bottom": "20px", color: "#0c82b6" },
              },
              [_vm._v(" " + _vm._s(_vm.onDataV3.inventory || 0) + " ")]
            ),
          ]),
        ]),
      ]),
      _c(
        "el-card",
        {
          staticClass: "box-card",
          staticStyle: { flex: "1", "margin-left": "10px" },
        },
        [
          _c("div", { staticClass: "labelRight" }, [
            _c("div", { staticClass: "labelRight_item" }, [
              _c("div", { staticClass: "labelRight_itemTitle" }, [
                _vm._v("AJD派送占比"),
              ]),
              _c(
                "div",
                {
                  staticClass: "labelRight_itemNumber",
                  staticStyle: { color: "#564ab0" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.onDataV3.ajdDispatchingRate || 0) + " "
                  ),
                ]
              ),
            ]),
            _c("div", [
              _c("div", { staticClass: "labelRight_itemTitle" }, [
                _vm._v("DSP派送占比"),
              ]),
              _c(
                "div",
                {
                  staticClass: "labelRight_itemNumber",
                  staticStyle: { "margin-bottom": "20px", color: "#009743" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.onDataV3.dspDispatchingRate || 0) + " "
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }