<template>
  <div class="app-container">
    <el-form :inline="false" label-position="top" size="small">
      <el-row :gutter="20">
        <el-col :span="7">
          <!--留言时间-->
          <el-form-item prop="timeType">
            <template slot="label">
              <div class="selectLabel">
                <el-select
                  v-model="queryForm.params.timeType"
                  :style="IsWidth"
                >
                  <el-option key="1" :label="$t('GlobalSetObj.CreationTime')" :value="1" />
                  <el-option key="2" :label="$t('customerManagements.genterTiem')" :value="2" />
                </el-select>
              </div>
            </template>
            <el-date-picker
              v-model="timeValue"
              :clearable="false"
              :default-time="['00:00:00', '23:59:59']"
              :end-placeholder="$t('GlobalSetObj.endDate')"
              :format="$i18n.locale=='zh'?zhDate:ptDate"
              :start-placeholder="$t('GlobalSetObj.startDate')"
              range-separator="~"
              style="width:100%"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!-- 客户名称  -->
          <el-form-item :label="$t('orderCenterCont.CustomerName')">
            <el-select
              v-model="queryForm.params.customerId"
              :placeholder="$t('GlobalSetObj.OnlyAreDisplayed')"
              :remote-method="searchGetUserList"
              clearable
              filterable
              remote
              reserve-keyword
              style="width: 100%"
              @change="searchGetUserListFocus2"
              @focus="searchGetUserListFocus1"
            >
              <el-option
                v-for="(item,index) in userList"
                :key="index"
                :label="item.username"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!-- 商户  -->
          <el-form-item :label="$t('collectionCenter.CommercialOwner')">
            <el-select
              v-model="queryForm.params.merchantId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              :remote-method="getapiCustomerPageList"
              clearable
              filterable
              remote
              reserve-keyword
              style="width: 100%"
              @change="searchgetapiCustomerPageList"
              @focus="searchgetapiCustomerPageList1"
            >
              <el-option
                v-for="(item,index) in customerMerchant"
                :key="index"
                :label="item.merchantName + '（' + item.merchantCode + '）'"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--  状态   -->
          <el-form-item :label="$t('GlobalSetObj.status')">
            <el-select
              v-model="queryForm.params.status"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              style="width: 100%"
            >
              <el-option :label="$t('GlobalSetObj.all')" :value="null" />
              <el-option :label="$t('GlobalSetObj.enable')" :value="1" />
              <el-option :label="$t('GlobalSetObj.stop')" :value="0" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="15">
          <!--搜索-->
          <el-button
            icon="el-icon-search" size="small" type="primary"
            @click="enterSearch"
          >{{
            $t('newOrder.search')
          }}
          </el-button>
          <!--重置-->
          <el-button
            icon="el-icon-refresh-left" size="small" type="info"
            @click="resetForm()"
          >{{
            $t('newOrder.reset')
          }}
          </el-button>
          <el-button
            size="small" style="margin-bottom: 15px" type="primary"
            @click="addCusTime"
          >{{ $t('customerManagements.newCusTime') }}
          </el-button>
        </el-col>
      </el-row>
    </el-form>
    <!--  表格数据  -->
    <el-table
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black'}"
      border
      style="width: 100%"
    >
      <el-table-column
        :label="$t('GlobalSetObj.serial')" align="center" type="index"
        width="70"
      />
      <el-table-column
        v-for="item in tableHeaderList"
        :key="item.id"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
        :show-overflow-tooltip="false"
      >
        <template slot-scope="scope">
          <div v-if="item.prop==='merchantName' && (scope.row.merchantName && scope.row.merchantCode)">
            <span>
              {{ scope.row.merchantName }}（{{ scope.row.merchantCode }}）
            </span>
          </div>
          <div v-else-if="item.prop==='statusTypeObjName'">
            <el-tag
              v-if="scope.row.status === 0" effect="dark" size="small"
              type="danger"
            >{{
              scope.row.statusTypeObjName
            }}
            </el-tag>
            <el-tag
              v-else effect="dark" size="small"
              type=""
            >{{ scope.row.statusTypeObjName }}
            </el-tag>
          </div>
          <div v-else-if="item.prop==='calculatedDay'">
            <div style="line-height: 30px;">
              <el-tag
                v-for="(item1,index) in scope.row.calculatedDayList1"
                :key="index" :type="`${ scope.row.calculatedDayList[index] > 5 ? 'danger' : ''}`" effect="dark"
                size="small" style="margin-right: 5px;"
              >
                {{ item1 }}
              </el-tag>
            </div>
          </div>
          <div v-else>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '- -' }}</div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('newOrder.operation')"
        align="center"
        fixed="right"
        width="120"
      >
        <template slot-scope="scope">
          <div style="display: flex;justify-content: space-between;align-items: center;">
            <el-tooltip
              :content="$t('customerManagements.downloadTimeTable')" class="item" effect="dark"
              placement="top"
            >
              <i class="el-icon-download textOperator" @click="downloadOrder(scope.row)" />
            </el-tooltip>
            <el-tooltip
              :content="$t('GlobalSetObj.edit')" class="item" effect="dark"
              placement="top"
            >
              <i class="el-icon-edit textOperator" @click="editMessage(scope.row)" />
            </el-tooltip>
            <el-tooltip
              v-if="scope.row.status === 0"
              :content="$t('GlobalSetObj.enable')" class="item" effect="dark"
              placement="top"
            >
              <i class="el-icon-circle-check textOperator" @click="updateStatus(scope.row)" />
            </el-tooltip>
            <el-tooltip
              v-else
              :content="$t('GlobalSetObj.stop')" class="item" effect="dark"
              placement="top"
            >
              <i class="el-icon-remove-outline textOperator1" @click="updateStatus(scope.row)" />
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.pageNumber"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!-- 新增客户视角 -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="cusTimeDialogTitle"
      :visible.sync="addCusTimeDialog"
      width="550px"
      @close="handleAddCusTimeDialog"
    >
      <el-form
        ref="ruleForm" :model="ruleForm" :rules="rules"
        class="demo-ruleForm"
        label-width="auto"
      >
        <el-form-item :label="$t('customerManagements.selectCus')" prop="customerId">
          <el-select
            v-model="ruleForm.customerId"
            :placeholder="$t('GlobalSetObj.OnlyAreDisplayed')"
            :remote-method="searchGetUserList"
            clearable
            filterable
            remote
            reserve-keyword
            style="width: 100%"
            @change="searchGetUserListFocus"
            @focus="searchGetUserListFocus1"
          >
            <el-option
              v-for="(item,index) in userList"
              :key="index"
              :label="item.username"
              :value="item.id + '~' + item.username"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="isSelect" :label="$t('collectionCenter.CommercialOwner')" prop="merchantId">
          <div style="overflow: hidden">
            <el-select
              v-model="ruleForm.merchantId"
              :placeholder="$t('GlobalSetObj.OnlyAreDisplayed')"
              :remote-method="serchMerchantIdList"
              clearable
              filterable
              remote
              reserve-keyword
              style="width: 100%"
              @change="serchMerchantIdList1"
              @focus="serchMerchantIdList2"
            >
              <el-option
                v-for="(item,index) in merchantIdList"
                :key="index"
                :label="item.merchantName + '（' + item.merchantCode + '）'"
                :value="item.id"
              />
            </el-select>
          </div>
        </el-form-item>
        <el-form-item :label="$t('customerManagements.timeCalcuDay')" prop="calculatedDay">
          <el-select
            v-model="ruleForm.calculatedDay" :placeholder="$t('GlobalSetObj.pleaseChoose')" multiple
            style="width: 100%"
          >
            <el-option :label="$t('customerManagements.weekOne')" value="1" />
            <el-option :label="$t('customerManagements.weekTwo')" value="2" />
            <el-option :label="$t('customerManagements.weekThree')" value="3" />
            <el-option :label="$t('customerManagements.weekFoure')" value="4" />
            <el-option :label="$t('customerManagements.weekFirtFive')" value="5" />
            <el-option :label="$t('customerManagements.weekSix')" value="6" />
            <el-option :label="$t('customerManagements.weekSeven')" value="7" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('customerManagements.genterTiem')" prop="effectiveTime">
          <el-date-picker
            v-model="ruleForm.effectiveTime"
            :default-time="defaultTime"
            :picker-options="pickerOptions"
            :placeholder="$t('newOrder.SelectDatetime')"
            style="width: 100%"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>
        <el-form-item :label="$t('customerManagements.agingType')" prop="agingType">
          <el-select
            v-model="ruleForm.agingType"
            style="width: 100%"
          >
            <el-option :label="$t('customerManagements.anDay')" :value="0" />
            <el-option :label="$t('customerManagements.anTime')" :value="1" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('customerManagements.timeframeStartingPointName')" prop="timeframeStartingPoint">
          <el-select
            v-model="ruleForm.timeframeStartingPoint" :placeholder="$t('GlobalSetObj.pleaseChoose')"
            style="width: 100%"
          >
            <el-option
              v-for="(item,index) in startLabel" :key="index" :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="">
          <template slot="label">
            <el-button size="small" type="primary" @click="triggerFileInput">{{
              $t('customerManagements.clickUpload')
            }}
            </el-button>
          </template>
          <input
            ref="referenceUpload"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            style="display: none"
            type="file"
            @change="uploadFile($event)"
          >
          <div class="">
            <span class="downModule" @click="downLoadFile">{{ $t('orderCenterCont.DownloadTemplate') }}</span>
            <span style="font-size: 12px;color: red;">{{ $t('customerManagements.pleaseUploadLI') }}</span>
          </div>
        </el-form-item>
        <div v-if="fileName" class="downLink">
          <div>{{ fileName }}</div>
          <div><i class="el-icon-error" @click="delFile" /></div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cusSubmit(1)">{{ $t('GlobalSetObj.cancel') }}</el-button>
        <el-button size="small" type="primary" @click="cusSubmit(2)">{{
          $t('GlobalSetObj.confirm')
        }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  apiCustomerPage,
  apiCustomerPageList,
  getMerchantByCustomerId,
  getMerchantByCustomerIdSearch,
  localAgingAdd,
  localAgingdownload,
  localAgingdownloadmodule,
  localAgingEdit,
  localAgingGetConfigPage,
  localAgingupdateStatus
} from '@/api/customer';
import dayjs from 'dayjs';

export default {
  name: 'RequestMessageList',
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        params: {
          timeType: 1,
          status: null, // 状态：1、启用，2、停用
          customerId: '', // 客户id
          merchantId: ''
        }
      },
      tableData: [],
      tableHeaderList: [
        // 创建时间
        { id: 2, label: this.$t('GlobalSetObj.CreationTime'), prop: 'createTime', cloWidth: '160' },
        // 客户名称
        { id: 3, label: this.$t('orderCenterCont.CustomerName'), prop: 'customerName', cloWidth: '150' },
        // 商户
        { id: 100, label: this.$t('collectionCenter.CommercialOwner'), prop: 'merchantName', cloWidth: '150' },
        // 生效时间
        { id: 4, label: this.$t('customerManagements.genterTiem'), prop: 'effectiveTime', cloWidth: '150' },
        // 时效模式
        { id: 20, label: this.$t('customerManagements.agingType'), prop: 'agingTypeName', cloWidth: '150' },
        // 状态
        { id: 6, label: this.$t('GlobalSetObj.status'), prop: 'statusTypeObjName', cloWidth: '100' },
        // 时效计算天数
        { id: 7, label: this.$t('customerManagements.timeCalcuDay'), prop: 'calculatedDay', cloWidth: '310' },
        // 时效起点
        {
          id: 12,
          label: this.$t('customerManagements.timeframeStartingPointName'),
          prop: 'timeframeStartingPointName',
          cloWidth: '150'
        },
        // 创建人
        { id: 8, label: this.$t('GlobalSetObj.CreatePeople'), prop: 'creatorName', cloWidth: '150' },
        // 最新操作人
        { id: 9, label: this.$t('customerManagements.newOptioner'), prop: 'updaterName', cloWidth: '150' },
        // 最新操作时间
        { id: 11, label: this.$t('customerManagements.newOptionTime'), prop: 'updateTime', cloWidth: '160' }
      ],
      userList: [], // 用户列表
      customerMerchant: [],
      addCusTimeDialog: false,
      cusTimeDialogTitle: this.$t('customerManagements.newCusTime'),
      ruleForm: {
        customerId: '',
        calculatedDay: [],
        effectiveTime: '',
        timeframeStartingPoint: '',
        agingType: 0,
        merchantId: ''
      },
      rules: {
        customerId: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        calculatedDay: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        effectiveTime: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        timeframeStartingPoint: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ],
        agingType: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ]
      },
      detailData: [],
      fileName: '',
      fileData: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; // 一天的毫秒数
        }
      },
      defaultTime: '',
      weekList: [
        'lkjl',
        this.$t('customerManagements.weekOne'),
        this.$t('customerManagements.weekTwo'),
        this.$t('customerManagements.weekThree'),
        this.$t('customerManagements.weekFoure'),
        this.$t('customerManagements.weekFirtFive'),
        this.$t('customerManagements.weekSix'),
        this.$t('customerManagements.weekSeven')
      ],
      startLabel: [
        { value: 'crete_time', label: this.$t('collectionCenter.orderCreateT') },
        { value: 'customer_send_time', label: this.$t('orderCenterCont.customerDeliveryTime') },
        { value: 'collect_scan_time', label: this.$t('collectionCenter.lanshouT') },
        { value: 'collect_seal_vehicle_time', label: this.$t('GlobalSetObj.collectSealVehicleTime') },
        { value: 'receipt_time', label: this.$t('GlobalSetObj.oneTransitInboundTime') }
      ],
      editId: null,
      merchantIdList: [],
      sizeFile: '',
      isSelect: false
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '180px' : '110px'
      };
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    // this.getTimer();
    this.searchGetUserList();
    this.getSearch();
    this.searchgetapiCustomerPageList('');
    this.getapiCustomerPageList('');
  },
  methods: {
    triggerFileInput() {
      this.$refs.referenceUpload.click();
    },
    // 上传文件
    uploadFile(e) {
      console.log(e, 'LKLK');
      this.fileData = e.target.files[0];
      this.fileName = e.target.files[0].name;
      this.sizeFile = e.target.files[0].size;
      // const formData = new FormData();
      // formData.append('customerId', 'value1');
      // formData.append('key2', 'value2');
      // // 如果有文件上传
      // formData.append('file', this.fileData, this.fileName);
    },
    handleChange(val) {
      console.log(val, 'handleChange');
    },
    addCusTime() {
      this.searchGetUserList('');
      this.cusTimeDialogTitle = this.$t('customerManagements.newCusTime');
      this.merchantIdList = [];
      this.editId = null;
      this.addCusTimeDialog = true;
    },
    // 点击确定
    cusSubmit(val) {
      if (val === 1) {
        this.addCusTimeDialog = false;
      } else {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            const formData = new FormData();
            const arr = this.ruleForm.customerId.split('~');
            formData.append('customerId', arr[0]);
            formData.append('customerName', arr[1]);
            if (this.ruleForm.merchantId) {
              formData.append('merchantId', this.ruleForm.merchantId);
            }
            formData.append('effectiveTime', this.ruleForm.effectiveTime);
            formData.append('timeframeStartingPoint', this.ruleForm.timeframeStartingPoint);
            formData.append('agingType', this.ruleForm.agingType);
            const calculatedDay = this.ruleForm.calculatedDay.join(',');
            formData.append('calculatedDay', calculatedDay);
            // 如果有文件上传
            if (this.fileName) {
              formData.append('sizeFile', this.sizeFile);
              if (this.editId) {
                if (this.fileData) {
                  formData.append('file', this.fileData, this.fileName);
                }
                console.log(this.fileData, 'this.fileData');
                formData.append('fileName', this.fileName);
              } else {
                formData.append('file', this.fileData, this.fileName);
                formData.append('fileName', this.fileName);
              }
            } else {
              this.$message.error(this.$t('customerManagements.pleaseUploadFile'));
              return;
            }
            if (this.cusTimeDialogTitle === this.$t('customerManagements.newCusTime')) {
              localAgingAdd(formData).then(res => {
                if (res.code===200) {
                  this.addCusTimeDialog = false;
                  this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
                  this.getSearch();
                }
              });
            } else {
              formData.append('id', this.editId);
              localAgingEdit(formData).then(res => {
                if (res.code===200) {
                  this.addCusTimeDialog = false;
                  this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
                  this.getSearch();
                }
              });
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    downLoadFile() {
      localAgingdownloadmodule().then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'table_data.csv'); // 更改为实际的文件名和扩展名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // 清理 URL
      });
    },
    // 删除文件
    delFile() {
      this.fileName = '';
      this.fileData = null;
      this.sizeFile = '';
    },
    // 编辑
    editMessage(row) {
      const _this = this;
      const data = {
        pageNumber: 1, // 页数
        pageSize: 10, // 每页 行数
        params: {
          quickSearch: row.customerName
        }
      };
      apiCustomerPage(data).then(res => {
        if (res.code===200) {
          this.userList = res.data.records;
          console.log(row, 'editMessage');
          this.cusTimeDialogTitle = this.$t('customerManagements.editCusTime');
          this.editId = row.id;
          getMerchantByCustomerIdSearch(row.customerId, row.merchantName).then(res => {
            if (res.code===200) {
              _this.merchantIdList = res.data;
              this.isSelect = _this.merchantIdList.length > 0;
              _this.$nextTick(() => {
                _this.addCusTimeDialog = true;
                _this.ruleForm.timeframeStartingPoint = row.timeframeStartingPoint;
                _this.ruleForm.calculatedDay = row.calculatedDayList;
                _this.ruleForm.effectiveTime = row.effectiveTime;
                _this.ruleForm.customerId = row.customerId + '~' + row.customerName;
                _this.ruleForm.agingType = row.agingType;
                _this.ruleForm.merchantId = row.merchantId;
                _this.fileName = row.fileName;
              });
            }
          });
        }
      });
    },
    // 下载
    downloadOrder(row) {
      localAgingdownload(row.id).then((res) => {
        if (res.code===200) {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          })
            .then((action) => {
              if (action === 'confirm') {
                this.$router.push({ path: '/views/resource/exportTaskList' });
              }
            })
            .catch(() => {
            });
        }
      });
    },
    // 启用停用
    updateStatus(row) {
      const data = {
        id: row.id,
        status: row.status === 1 ? 0 : 1
      };
      this.$confirm(this.$t('customerManagements.areSureStop'), this.$t('collectionCenter.tips'), {
        confirmButtonText: this.$t('collectionCenter.confirm'), // '确定'
        cancelButtonText: this.$t('collectionCenter.cancel'), // '取消'
        type: 'warning'
      }).then(async() => {
        localAgingupdateStatus(data).then(res => {
          if (res.code===200) {
            this.$message.success(this.$t('GlobalSetObj.ModificationSuccessful'));
            this.getSearch();
          }
        });
      }).catch(() => {
        console.log('取消关闭');
      });
    },
    // 获取最近一周时间
    getTimer() {
      // 获取当前时间
      const now = dayjs();
      const today = dayjs().format('YYYY-MM-DD');
      const oneWeekAgo = dayjs().subtract(1, 'months').format('YYYY-MM-DD');
      this.timeValue = [oneWeekAgo + ' 00:00:00', today + ' 23:59:59'];
      this.defaultTime = now.format('HH:mm:ss');
    },
    enterSearch() {
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.pageNumber = 1;
      this.timeValue = [];
      // this.getTimer();
      this.queryForm.params = {
        timeType: 1,
        status: null, // 状态：1、启用，2、停用
        customerId: '', // 客户id
        merchantId: ''
      };
      this.getSearch();
      this.searchgetapiCustomerPageList('');
      this.getapiCustomerPageList('');
    },
    // 查询
    getSearch() {
      const obj = {
        'crete_time': this.$t('collectionCenter.orderCreateT'),
        'customer_send_time': this.$t('orderCenterCont.customerDeliveryTime'),
        'collect_scan_time': this.$t('collectionCenter.lanshouT'),
        'collect_seal_vehicle_time': this.$t('GlobalSetObj.collectSealVehicleTime'),
        'receipt_time': this.$t('GlobalSetObj.oneTransitInboundTime')
      };
      const statusTypeObj = {
        1: this.$t('GlobalSetObj.enable'),
        0: this.$t('GlobalSetObj.stop')
      };
      const timeTypeObj = {
        1: this.$t('customerManagements.anTime'),
        0: this.$t('customerManagements.anDay')
      };
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.pageNumber;
      data.params = this.queryForm.params;
      if (this.timeValue && this.timeValue.length > 0) {
        data.params.beginTime = this.timeValue[0];
        data.params.endTime = this.timeValue[1];
      } else {
        data.params.beginTime = null;
        data.params.endTime = null;
      }
      localAgingGetConfigPage(data).then(res => {
        if (res.code===200) {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.forEach(item => {
            item.statusTypeObjName = statusTypeObj[item.status];
            item.agingTypeName = timeTypeObj[item.agingType];
            item.calculatedDayList = item.calculatedDay.split(',');
            item.calculatedDayList.sort();
            item.calculatedDayList1 = item.calculatedDayList.map(item => {
              return this.weekList[item];
            });
            if (item.timeframeStartingPoint) {
              item.timeframeStartingPointName = obj[item.timeframeStartingPoint];
            }
          });
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getSearch();
    },
    handleAddCusTimeDialog() {
      this.fileName = '';
      this.fileData = null;
      this.ruleForm = {
        customerId: '',
        calculatedDay: [],
        effectiveTime: '',
        timeframeStartingPoint: '',
        agingType: 0,
        merchantId: ''
      };
      this.$refs.referenceUpload.value = '';
      this.$refs.ruleForm.resetFields();
      this.isSelect = false;
    },
    // 滚动到底部
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.messageListRef.scrollTo({
          top: this.$refs.messageListRef.scrollHeight,
          behavior: 'smooth'
        });
      });
    },
    // 配合选择客户搜索商户
    serchMerchantIdList(val = '') {
      const arr = this.ruleForm.customerId.split('~');
      getMerchantByCustomerIdSearch(arr[0], val).then(res => {
        if (res.code===200) {
          this.merchantIdList = res.data;
          this.isSelect = true;
        }
      });
    },
    serchMerchantIdList1(val) {
      if (!val) {
        this.serchMerchantIdList('');
      }
      if (this.userList.length === 0) {
        this.serchMerchantIdList('');
      }
    },
    serchMerchantIdList2() {
      console.log('searchgetapiCustomerPageList1');
      if (this.merchantIdList.length === 0) {
        this.serchMerchantIdList('');
      }
    },
    // 查用户
    searchGetUserList(val = '') {
      const data = {
        pageNumber: 1, // 页数
        pageSize: 10, // 每页 行数
        params: {
          quickSearch: val
        }
      };
      apiCustomerPage(data).then(res => {
        if (res.code===200) {
          this.userList = res.data.records;
        }
      });
    },
    searchgetapiCustomerPageList(val) {
      if (!val) {
        this.getapiCustomerPageList('');
      }
      if (this.userList.length === 0) {
        this.getapiCustomerPageList('');
      }
    },
    searchgetapiCustomerPageList1() {
      console.log('searchgetapiCustomerPageList1');
      if (this.customerMerchant.length === 0) {
        this.getapiCustomerPageList('');
      }
    },
    getapiCustomerPageList(val = '') {
      apiCustomerPageList(val).then(res => {
        if (res.code===200) {
          this.customerMerchant = res.data;
        }
      });
    },
    searchGetUserListFocus(val) {
      if (!val) {
        this.searchGetUserList('');
      }
      if (this.userList.length === 0) {
        this.searchGetUserList('');
      }
      if (val) {
        const arr = val.split('~');
        getMerchantByCustomerId(arr[0]).then(res => {
          if (res.code===200) {
            console.log(res, 'getMerchantByCustomerId');
            this.merchantIdList = res.data;
            this.isSelect = this.merchantIdList.length > 0;
          }
        });
      } else {
        this.merchantIdList = [];
        this.isSelect = false;
      }
      this.ruleForm.merchantId = '';
      this.queryForm.params.merchantId = '';
    },
    searchGetUserListFocus2(val) {
      if (!val) {
        this.searchGetUserList('');
      }
      if (this.userList.length === 0) {
        this.searchGetUserList('');
      }
      if (val) {
        getMerchantByCustomerId(val).then(res => {
          if (res.code===200) {
            console.log(res, 'getMerchantByCustomerId');
            this.merchantIdList = res.data;
          }
        });
      } else {
        this.merchantIdList = [];
      }
      this.queryForm.params.merchantId = '';
    },
    searchGetUserListFocus1() {
      if (this.userList.length === 0) {
        this.searchGetUserList('');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0 15px;
  padding-bottom: 15px;
}

::v-deep {
  .drawerClass header {
    border-bottom: 1px solid #aaa !important;
    margin-bottom: 0 !important;
    padding: 20px 20px 20px !important;
  }
}

.selectLabel ::v-deep .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.selectLabel ::v-deep .el-input__icon {
  line-height: 28px !important;
}

.selectType ::v-deep .el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.textOperator {
  color: #409eff;
  cursor: pointer;
  font-size: 22px;
}

.textOperator1 {
  color: #f56c6c;
  cursor: pointer;
  font-size: 22px;
}

.messgeList {
  height: 450px;
  background-color: #f5f5f5;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
}

.downLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f7fa;
  cursor: pointer;
  padding: 5px;
}

.downModule {
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 15px;
}
</style>
