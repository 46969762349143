<template>
  <el-card class="box-card">
    <div style="margin-bottom: 15px">
      <span class="fontClsBold02">{{ $t('changepwd.staticAgingMonitoring') }}</span>
    </div>
    <div style="display: flex">
      <div class="data_center">
        <div class="titleLabel">
          <img :src="require(`@/assets/images/kanban/yuan.png`)" alt="">
          <div class="titleLabelTime">{{ $t('customerManagements.HoursDetention24') }}</div>
          <div class="titleLabelTimeDot">...............</div>
        </div>
        <div class="numberTime">{{ centerData.twentyFourHours || 0 }}</div>
      </div>
      <div class="data_center">
        <div class="titleLabel">
          <img :src="require(`@/assets/images/kanban/yuan.png`)" alt="">
          <div class="titleLabelTime">{{ $t('customerManagements.HoursDetention48') }}</div>
          <div class="titleLabelTimeDot">...............</div>
        </div>
        <div class="numberTime">{{ centerData.fortyEightHours || 0 }}</div>
      </div>
      <div class="data_center">
        <div class="titleLabel">
          <img :src="require(`@/assets/images/kanban/yuan.png`)" alt="">
          <div class="titleLabelTime">{{ $t('customerManagements.HoursDetention72') }}</div>
          <div class="titleLabelTimeDot">...............</div>
        </div>
        <div class="numberTime">{{ centerData.seventyTwoHours || 0 }}</div>
      </div>
      <div class="data_center">
        <div class="titleLabel">
          <img :src="require(`@/assets/images/kanban/yuan.png`)" alt="">
          <div class="titleLabelTime">{{ $t('customerManagements.DayDetention7') }}</div>
          <div class="titleLabelTimeDot">...............</div>
        </div>
        <div class="numberTime">{{ centerData.sevenDays || 0 }}</div>
      </div>
      <div class="data_center">
        <div class="titleLabel">
          <img :src="require(`@/assets/images/kanban/yuan.png`)" alt="">
          <div class="titleLabelTime">{{ $t('customerManagements.DayDetention15') }}</div>
        </div>
        <div class="numberTime">{{ centerData.fifteenDays || 0 }}</div>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'AllocateRetentionData',
  props: {
    centerData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>

<style scoped></style>
