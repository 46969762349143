var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "postalCodeCorrectionInfo" }, [
    _c(
      "div",
      { staticClass: "addFormBox" },
      [
        _c(
          "el-form",
          {
            ref: "queryForm",
            staticClass: "queryFormClass",
            attrs: {
              model: _vm.formData,
              rules: _vm.rules,
              size: "small",
              "label-width": _vm.$i18n.locale === "zh" ? "120px" : "",
              "label-position": _vm.$i18n.locale !== "zh" ? "top" : "right",
            },
          },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("basicData.wrongZipCodeState") } },
                  [
                    _c("el-input", {
                      attrs: {
                        id: "errorPostalCodeState",
                        placeholder:
                          _vm.$t("orderDetails.pleaseInput") +
                          "，" +
                          _vm.$t(
                            "basicData.MultipleCharactersSeparatedByCommas"
                          ),
                        clearable: "",
                        maxlength: "20",
                        "show-word-limit": "",
                      },
                      model: {
                        value: _vm.formData.errorPostalCodeState,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "errorPostalCodeState",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.errorPostalCodeState",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("basicData.IncorrectPostalCode"),
                      prop: "errorPostalCode",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        id: "errorPostalCode",
                        placeholder: _vm.$t("orderDetails.pleaseInput"),
                        clearable: "",
                        maxlength: "8",
                        "show-word-limit": "",
                      },
                      model: {
                        value: _vm.formData.errorPostalCode,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "errorPostalCode",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.errorPostalCode",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("basicData.CorrectPostalCode"),
                      prop: "correctPostalCode",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        id: "correctPostalCode",
                        placeholder: _vm.$t("orderDetails.pleaseInput"),
                        clearable: "",
                        maxlength: "8",
                        "show-word-limit": "",
                      },
                      model: {
                        value: _vm.formData.correctPostalCode,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "correctPostalCode",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.correctPostalCode",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("basicData.status") + "：",
                      prop: "state",
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.formData.state,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "state", $$v)
                          },
                          expression: "formData.state",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: 1 } }, [
                          _vm._v(_vm._s(_vm.$t("basicData.disable"))),
                        ]),
                        _c("el-radio-button", { attrs: { label: 0 } }, [
                          _vm._v(_vm._s(_vm.$t("basicData.enable"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "addFooter" },
      [
        _c(
          "el-button",
          {
            on: {
              click: function () {
                return _vm.handleUpdatePage(false)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
        ),
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
          [_vm._v(_vm._s(_vm.$t("basicData.Save")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }