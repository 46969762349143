<template>
  <el-card class="box-card">
    <div class="fontClsBold02">{{ $t('changepwd.slaNumber') }}</div>
    <div id="main" style="height: 230px; width: 100%" />
  </el-card>
</template>

<script>
import * as echarts from 'echarts';
import dayjs from 'dayjs';

export default {
  name: 'SlaNumber',
  props: {
    siteSlaRateList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      chartDom: '',
      myChart: '',
      rowTime: []
    };
  },
  mounted() {
    this.getTimeRow();
    // 添加 resize 事件监听器
    window.addEventListener('resize', this.resizeHandler);
    this.getData();
  },
  methods: {
    // 渲染折线图
    getData() {
      const chartDom = document.getElementById('main');
      console.log(chartDom, 'chartDomchartDom');
      this.myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: 'axis',
          formatter: function(params) {
            let tooltipStr = params[0].name + '<br/>';
            params.forEach((param) => {
              tooltipStr += `${param.value}%<br/>`;
            });
            return tooltipStr;
          }
        },
        xAxis: {
          type: 'category',
          data: this.rowTime
        },
        yAxis: [
          {
            // 设置网格线为虚线
            splitLine: {
              // 网格线
              lineStyle: {
                type: 'dashed', // 设置网格线类型 dotted：虚线   solid:实线
                color: '#EDEDED'
              },
              show: true // 隐藏或显示
            },
            type: 'value',
            axisLabel: {
              color: 'rgba(132, 132, 132, 1)',
              fontWeight: '400',
              formatter: '{value}%', // 设置y轴标签格式为百分比
              // 设置字体和字号
              fontFamily: 'Arial',
              fontSize: 12
            }
          }
        ],
        series: [
          {
            label: {
              show: true,
              position: 'top',
              formatter: '{c}%'
            },
            data: this.siteSlaRateList,
            type: 'line',
            lineStyle: {
              color: '#aa8eec' // 折线颜色
            },
            // 节点颜色
            itemStyle: {
              color: '#aa8eec' // 折线颜色
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(130, 144, 235,0.3)'
                },
                {
                  offset: 1,
                  color: 'rgb(130, 144, 235,0.1)'
                }
              ])
            }
          }
        ]
      };
      option && this.myChart.setOption(option);
    },
    resizeHandler() {
      this.myChart.resize();
    },
    // 获取时间
    getTimeRow() {
      // 获取当前日期
      const currentDate = dayjs();
      // 循环获取近七天的日期
      for (let i = 0; i < 7; i++) {
        const date = currentDate.subtract(i, 'day').format('YYYY-MM-DD');
        this.rowTime.unshift(date);
      }
    }
  }
};
</script>

<style scoped></style>
