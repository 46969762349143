var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "TABLE_LIST" },
    [
      _c(
        "virtual-scroll",
        {
          key: _vm.key,
          ref: "virtualScroll",
          attrs: { data: _vm.tableData, "item-size": 62, "key-prop": "id" },
          on: {
            change: function (virtualList) {
              return (_vm.list = virtualList)
            },
          },
        },
        [
          _c(
            "el-table",
            {
              key: _vm.key,
              ref: "table",
              staticClass: "table-height",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.list,
                height: _vm.tableHeightRow.height,
                "row-key": "id",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("virtual-column", {
                attrs: { width: "50", type: "selection" },
              }),
              _c("virtual-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.serial"),
                  width: "50",
                  type: "index",
                },
              }),
              _vm._l(_vm.tableHeader, function (item) {
                return _c("el-table-column", {
                  key: item.prop,
                  attrs: {
                    align: item.fixed,
                    label: item.label,
                    "min-width": item.width,
                    prop: item.prop,
                    "show-overflow-tooltip": true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            item.prop === "waybillNumber"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#409eff",
                                      cursor: "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editFee(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row[item.prop]))]
                                )
                              : item.prop === "status"
                              ? _c("div", [_vm._v(_vm._s(scope.row.statusStr))])
                              : item.prop === "collectType"
                              ? _c("div", [
                                  _vm._v(_vm._s(scope.row.collectTypeStr)),
                                ])
                              : item.prop === "feeSource"
                              ? _c("div", [
                                  _vm._v(_vm._s(scope.row.feeSourceStr)),
                                ])
                              : item.prop === "merchantCode"
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row[item.prop]) +
                                      _vm._s(
                                        scope.row["merchantName"]
                                          ? "(" +
                                              scope.row["merchantName"] +
                                              ")"
                                          : ""
                                      ) +
                                      " "
                                  ),
                                ])
                              : item.prop === "vehicleType"
                              ? _c("div", [
                                  _vm._v(_vm._s(scope.row.vehicleTypeStr)),
                                ])
                              : item.prop === "customerType"
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.customerType === 1
                                          ? _vm.$t("GlobalSetObj.selfSupport")
                                          : scope.row.customerType === 2
                                          ? _vm.$t("GlobalSetObj.three")
                                          : ""
                                      ) +
                                      " "
                                  ),
                                ])
                              : item.prop === "routeType"
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      (_vm.LINE_TYPE[scope.row.routeType] &&
                                        _vm.LINE_TYPE[scope.row.routeType]
                                          .label) ||
                                        ""
                                    )
                                  ),
                                ])
                              : item.prop === "orderBusinessType"
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.orderBusinessType === 1
                                          ? _vm.$t(
                                              "collectionCenter.expressDelivery"
                                            )
                                          : scope.row.orderBusinessType === 0
                                          ? _vm.$t("GlobalSetObj.Collect")
                                          : ""
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("div", [
                                  _vm._v(_vm._s(scope.row[item.prop])),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _vm._l(_vm.currentColumnsArr, function (item) {
                return _c("el-table-column", {
                  key: item.key,
                  attrs: {
                    label: item.label,
                    prop: item.prop,
                    "show-overflow-tooltip": true,
                    "render-header": _vm.renderHeader,
                  },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            staticStyle: { margin: "10px 0" },
            attrs: {
              "current-page": _vm.queryForm.pageNumber,
              "page-size": _vm.queryForm.pageSize,
              "page-sizes": [100, 200, 500, 1000],
              total: _vm.total,
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "current-change": _vm.onCurrentChange,
              "size-change": _vm.onSinzeChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }