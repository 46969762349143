var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        height: "calc(100vh - 67px)",
        overflow: "auto",
        padding: "10px",
      },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { name: "xq" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c("i", { staticClass: "el-icon-s-grid" }),
                _vm._v(" " + _vm._s(_vm.$t("orderCenterCont.OrderDetails"))),
              ]),
              _c(
                "DetailsCmp",
                _vm._b({ ref: "DetailsCmp" }, "DetailsCmp", _vm.$attrs, false)
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { name: "gj" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _vm._v(_vm._s(_vm.$t("orderCenterCont.TrajectoryQuery"))),
              ]),
              _c("Track", _vm._b({}, "Track", _vm.$attrs, false)),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { name: "pz" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _vm._v(_vm._s(_vm.$t("home.PaymentCertificate"))),
              ]),
              _vm.activeName === "pz"
                ? _c(
                    "payment-voucher",
                    _vm._b({}, "payment-voucher", _vm.$attrs, false)
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }