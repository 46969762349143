<template>
  <div class="app-container">
    <el-card class="box-card" style="margin-bottom: 10px">
      <el-form size="small">
        <!--创建时间-->
        <el-form-item :label="$t('newOrder.orderCreateTime')">
          <el-date-picker
            v-model="timeValue"
            :clearable="false"
            :default-time="['00:00:00', '23:59:59']"
            :format="$i18n.locale === 'zh' ? zhDate : ptDate"
            :placeholder="$t('newOrder.SelectDatetime')"
            :picker-options="pickerOptions"
            range-separator="~"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 400px;"
            @blur="refreshDate"
          />
        </el-form-item>
        <el-button
          icon="el-icon-search" size="small" type="primary"
          @click="searchTable(1)"
        >
          {{ $t('GlobalSetObj.search') }}
        </el-button>
        <el-button icon="el-icon-refresh-left" size="small" @click="searchTable(2)">
          {{ $t('operationCenter.reset') }}
        </el-button>
      </el-form>
    </el-card>
    <el-card class="box-card">
      <el-table
        :data="tableData"
        :span-method="arraySpanMethod"
        :header-cell-style="{background:'#f2f2f2',color:'black', textAlign:'center' }"
        :cell-class-name="cellClassName"
        border
      >
        <el-table-column
          v-for="(item, ind) in columnList"
          :key="ind"
          :align="item.align"
          :label="item.label"
          :min-width="item.width"
          :prop="item.prop"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <!-- 业务模板 - 快递 -->
            <div v-if="item.prop === 'businessModule'">{{ $t('collectionCenter.expressDelivery') }}</div>
            <!-- 业务类型 -->
            <div v-else-if="item.prop === 'businessType'">
              <!-- 本地 -->
              <div v-if="scope.row[item.prop] === 'local'">{{ $t('collectionCenter.local') }}</div>
              <!-- 跨境 -->
              <div v-else-if="scope.row[item.prop] === 'crossBorder'">{{ $t('collectionCenter.crossBorder') }}</div>
            </div>
            <div v-else>
              {{
                (scope.row[item.prop] !== '' && scope.row[item.prop] !== null && scope.row[item.prop] !== undefined) ? scope.row[item.prop] : '--'
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, ind) in currentColumnList"
          :key="ind + item.prop"
          :align="item.align"
          :label="item.label"
          :min-width="item.width"
          :prop="item.prop"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>
              {{
                (scope.row[item.prop] !== '' && scope.row[item.prop] !== null && scope.row[item.prop] !== undefined) ? scope.row[item.prop] : '--'
              }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { getDataOrderDailyApi } from '@/api/orderApi';
export default {
  name: 'SingleVolumeDailyReport',
  data() {
    return {
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      choiceDate0: '',
      timeValue: [],
      pickerOptions: {
        // 设置不能选择的日期
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate0 = minDate.getTime();
          if (maxDate) {
            this.choiceDate0 = '';
          }
        },
        disabledDate: (time) => {
          const choiceDateTime = new Date(this.choiceDate0).getTime();
          const minTime = new Date(choiceDateTime).setDate(
            new Date(choiceDateTime).getDate() - 30
          );
          const maxTime = new Date(choiceDateTime).setDate(
            new Date(choiceDateTime).getDate() + 30
          );
          const min = minTime;
          const newDate =
            new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000;
          const max = newDate < maxTime ? newDate : maxTime;
          // 如果已经选中一个日期 则 返回 该日期前后一个月时间可选
          if (this.choiceDate0) {
            return time.getTime() < min || time.getTime() > max;
          }
          // 若一个日期也没选中 则 返回 当前日期以前日期可选
          return time.getTime() > newDate;
        }
      },
      columnList: [
        // 业务模块
        { label: this.$t('GlobalSetObj.BusinessModule'), prop: 'businessModule', width: 160, align: 'center' },
        // 业务类型
        { label: this.$t('navbar.businessType'), prop: 'businessType', width: 160, align: 'center' },
        // 客户
        { label: this.$t('GlobalSetObj.client'), prop: 'customerName', width: 180, align: 'center' },
        // 合计
        { label: this.$t('GlobalSetObj.sum'), prop: 'total', width: 120, align: 'center' }
      ],
      currentColumnList: [],
      tableData: []
    };
  },
  created() {
    this.getTimer();
    this.getTableData();
  },
  methods: {
    // 获取最近一星期时间
    getTimer() {
      const day = new Date();
      const brazilTime = day.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM-DD');
      const oneWeekAgo = dayjs(brazilTime).subtract(6, 'day').format('YYYY-MM-DD');
      this.timeValue = [oneWeekAgo + ' 00:00:00', today + ' 23:59:59'];
    },
    // 日期选择器失去焦点
    refreshDate() {
      this.choiceDate0 = '';
    },
    // 搜索、重置
    searchTable(type) {
      if (type === 2) {
        // 重置
        this.getTimer();
      }
      this.getTableData();
    },
    // 将日期转换为 YYYY-MM-DD 格式
    convertDateFormat(dateString) {
      const dateArr = dateString.split(/[-/]/);
      const day = Number(dateArr[2]);
      const month = Number(dateArr[1]);
      const year = Number(dateArr[0]);
      // 获取 YYYY-MM-DD 格式
      return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    },
    // 获取表格数据
    getTableData() {
      if (this.timeValue && this.timeValue.length === 2) {
        const queryParams = {
          startTime: this.timeValue[0],
          endTime: this.timeValue[1]
        };
        getDataOrderDailyApi(queryParams).then(res => {
          if (res.code === 200) {
            const daysOfWeek = {
              'Sun': this.$t('GlobalSetObj.Sunday'), // '星期日',
              'Mon': this.$t('GlobalSetObj.Monday'), // '星期一',
              'Tue': this.$t('GlobalSetObj.Tuesday'), // '星期二',
              'Wed': this.$t('GlobalSetObj.Wednesday'), // '星期三',
              'Thu': this.$t('GlobalSetObj.Thursday'), // '星期四',
              'Fri': this.$t('GlobalSetObj.Friday'), // '星期五',
              'Sat': this.$t('GlobalSetObj.Saturday') // '星期六'
            };
            this.currentColumnList = res.data.dateList.map((item) => {
              const dateWeek = item.split(/[(（]/)[0];
              // 删除 '）'
              const removeLastCharacter = (str) => {
                return str.slice(0, -1);
              };
              const week = daysOfWeek[removeLastCharacter(item.split(/[(（]/)[1])]; // 星期语言转换
              const date = this.convertDateFormat(dateWeek);
              const currentDate = new Date(date);
              const month = String(currentDate.getMonth() + 1).padStart(2, '0');
              const day = String(currentDate.getDate()).padStart(2, '0');
              const formattedDate = this.$i18n.locale === 'zh'
                ? month + this.$t('newOrder.Month') + day + this.$t('newOrder.day')
                : day + this.$t('newOrder.day') + month + this.$t('newOrder.Month');
              return {
                label: formattedDate + '(' + week + ')',
                prop: item,
                width: 220,
                align: 'center'
              };
            });
            const dataArr = res.data.customerDailyDataList.map((item) => {
              switch (item.customerName) {
                case 'shein':
                  item.customerName = this.$i18n.locale === 'zh' ? 'SHEIN本地' : 'SEHIN Local';
                  break;
                case 'brazilLocalCustomer':
                  item.customerName = this.$i18n.locale === 'zh' ? '巴西本地客户' : this.$t('collectionCenter.BrazilianLocalCustomer');
                  break;
                case 'insider':
                  item.customerName = this.$i18n.locale === 'zh' ? 'insider(MG客户)' : 'insidertomador';
                  break;
                case 'sheinCrossBorder':
                  item.customerName = this.$i18n.locale === 'zh' ? 'SEHIN跨境' : 'SEHIN Transfronteiriça';
                  break;
                case 'cainiaoCrossBorder':
                  item.customerName = this.$i18n.locale === 'zh' ? '菜鸟跨境' : 'cainiao Transfronteiriça';
                  break;
                case 'shopeeCrossBorder':
                  item.customerName = this.$i18n.locale === 'zh' ? 'shopee跨境' : 'shopee Transfronteiriça';
                  break;
                case 'temuCrossBorder':
                  item.customerName = this.$i18n.locale === 'zh' ? 'temu跨境' : 'temu Transfronteiriça';
                  break;
              }
              return {
                ...item,
                ...item.dailyData
              };
            });
            // 将本地数据排在前面，跨境排在后面
            dataArr.sort((a, b) => {
              if (a.businessType === 'local' && b.businessType !== 'local') {
                return -1; // a 在 b 前面
              }
              if (a.businessType !== 'local' && b.businessType === 'local') {
                return 1; // b 在 a 前面
              }
              // 如果 businessType 相同或都不是 'local'，按字母顺序排序
              return a.businessType.localeCompare(b.businessType);
            });
            const objDate = {};
            res.data.dateList.map((item) => {
              objDate[item] = res.data.total.dailyTotal[item] || 0;
            });
            const obj = {
              'businessModule': 'express', // 快递
              'businessType': '',
              'customerName': this.$t('GlobalSetObj.sum'), // 合计
              'total': res.data.total.total,
              ...objDate
            };
            dataArr.unshift(obj);
            this.tableData = dataArr;
          }
        });
      } else {
        this.$message({
          type: 'warning',
          message: this.$t('collectionCenter.PleaseSelectTime')
        });
      }
    },
    // 合并单元格表格业务模块相同的数据
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // 业务模块
      if (columnIndex === 0) {
        if (rowIndex > 0 && row.businessModule === this.tableData[rowIndex - 1].businessModule) {
          return [0, 0];
        } else {
          let rowspan = 1;
          for (let i = rowIndex + 1; i < this.tableData.length; i++) {
            if (this.tableData[i].businessModule === row.businessModule) {
              rowspan++;
            } else {
              break;
            }
          }
          return [rowspan, 1];
        }
      }
      // 业务类型
      if (columnIndex === 1) {
        if (rowIndex > 0 && row.businessType === this.tableData[rowIndex - 1].businessType) {
          return [0, 0];
        } else {
          let rowspan = 1;
          for (let i = rowIndex + 1; i < this.tableData.length; i++) {
            if (this.tableData[i].businessType === row.businessType) {
              rowspan++;
            } else {
              break;
            }
          }
          return [rowspan, 1];
        }
      }
      return [1, 1];
    },
    // 设置单元格样式
    cellClassName({ row, column, rowIndex, columnIndex }) {
      // 根据条件返回不同的类名
      if (column.property === 'customerName' && row.customerName === this.$t('GlobalSetObj.sum')) {
        return 'totalStyle';
      }
      // 其他条件...
      return '';
    }
  }
};
</script>

<style>
.totalStyle {
  font-weight: bold;
  color: black;
}
</style>
