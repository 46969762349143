<template>
  <div style="display: flex">
    <el-card class="box-card" style="flex: 1">
      <div class="labelRight">
        <div class="labelRight_item">
          <div class="labelRight_itemTitle">在途票数</div>
          <div class="labelRight_itemNumber">{{ onDataV3.enRoute || 0 }}</div>
        </div>
        <div>
          <div class="labelRight_itemTitle">实时库存</div>
          <div class="labelRight_itemNumber" style="margin-bottom: 20px; color: #0c82b6">
            {{ onDataV3.inventory || 0 }}
          </div>
        </div>
      </div>
    </el-card>
    <el-card class="box-card" style="flex: 1; margin-left: 10px">
      <div class="labelRight">
        <div class="labelRight_item">
          <div class="labelRight_itemTitle">AJD派送占比</div>
          <div class="labelRight_itemNumber" style="color: #564ab0">
            {{ onDataV3.ajdDispatchingRate || 0 }}
          </div>
        </div>
        <div>
          <div class="labelRight_itemTitle">DSP派送占比</div>
          <div class="labelRight_itemNumber" style="margin-bottom: 20px; color: #009743">
            {{ onDataV3.dspDispatchingRate || 0 }}
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'OnTimeShow',
  props: {
    onDataV3: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>

<style scoped></style>
