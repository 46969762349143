var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "80vh", "overflow-y": "auto" } },
    [
      _vm.tableInformation.orderPaymentList &&
      _vm.tableInformation.orderPaymentList.length > 0
        ? _c(
            "div",
            { key: _vm.showUploadFiles },
            [
              _vm._l(
                _vm.tableInformation.orderPaymentList,
                function (item, index) {
                  return _c(
                    "el-card",
                    { key: index, staticStyle: { "margin-bottom": "20px" } },
                    [
                      _c(
                        "el-form",
                        { attrs: { "label-position": "top" } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("home.uploadTime"),
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.createTime)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("home.UploadUser"),
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.createUserName)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "home.PaymentCertificate"
                                        ),
                                      },
                                    },
                                    _vm._l(
                                      item.paymentVoucherList,
                                      function (list, ind) {
                                        return _c(
                                          "div",
                                          {
                                            key: ind,
                                            staticStyle: {
                                              "margin-bottom": "5px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                  cursor: "pointer",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.getImgSrcFile(
                                                      list.url
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "80px",
                                                    height: "60px",
                                                  },
                                                  attrs: { src: list.url },
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      flex: "1",
                                                      "margin-left": "5px",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(list.name))]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { "border-top": "3px solid #eee" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "20px" } },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "home.AnjunFinancialAuditDetails"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  item.reviewResults === 1
                                    ? _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "permit",
                                              rawName: "v-permit:remove",
                                              value:
                                                "but:collection-center:largeOrderManagement:financial-audit",
                                              expression:
                                                "'but:collection-center:largeOrderManagement:financial-audit'",
                                              arg: "remove",
                                            },
                                          ],
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            type: "primary",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.examine(item)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("home.FinancialAudit")
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              item.reviewResults !== 1
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 8 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "Resource.ApprovalStatus"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.reviewResultsName
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 8 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label:
                                                      _vm.$t("home.AuditUser"),
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.reviewerName)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 8 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label:
                                                      _vm.$t(
                                                        "home.appliveResult"
                                                      ),
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.reviewResultsName
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          item.reviewRemarks
                                            ? _c(
                                                "el-col",
                                                { attrs: { span: 24 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label:
                                                          _vm.$t(
                                                            "basicData.审核备注"
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.reviewRemarks
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.$t("home.FinancialAudit"),
                    width: "600px",
                    visible: _vm.examineDialogVisible,
                    "append-to-body": true,
                    "close-on-click-modal": false,
                    "close-on-press-escape": false,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.examineDialogVisible = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-form",
                        {
                          key: _vm.examineDialogVisible,
                          ref: "examineFormRef",
                          attrs: {
                            model: _vm.examineForm,
                            "label-position": "top",
                          },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("home.appliveResult"),
                                    prop: "reviewResults",
                                    rules: [
                                      {
                                        required: true,
                                        message: _vm.$t(
                                          "home.AuditResultCannotBeEmpty"
                                        ),
                                        trigger: ["blur", "change"],
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "line-height": "12px",
                                        "margin-bottom": "20px",
                                        "font-size": "12px",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#f00",
                                            "margin-left": "10px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "home.PleaseConfirmThatThePayment"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.examineForm.reviewResults,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.examineForm,
                                            "reviewResults",
                                            $$v
                                          )
                                        },
                                        expression: "examineForm.reviewResults",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          staticStyle: {
                                            display: "block",
                                            "margin-bottom": "10px",
                                          },
                                          attrs: { label: 3 },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("home.auditPassed"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-radio",
                                        {
                                          staticStyle: { display: "block" },
                                          attrs: { label: 2 },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("home.auditNotPassed")
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.examineForm.reviewResults === 2
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("basicData.审核备注"),
                                    prop: "reviewRemark",
                                    rules: [
                                      {
                                        required: true,
                                        message: _vm.$t(
                                          "home.AuditRemarkCannotBeEmpty"
                                        ),
                                        trigger: ["blur", "change"],
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 4,
                                      placeholder: _vm.$t(
                                        "home.PleaseEnterARemark"
                                      ),
                                      maxlength: 100,
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.examineForm.reviewRemark,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.examineForm,
                                          "reviewRemark",
                                          $$v
                                        )
                                      },
                                      expression: "examineForm.reviewRemark",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              _vm.examineDialogVisible = false
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.confirmAudit },
                        },
                        [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")))]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            2
          )
        : _c("div", { staticStyle: { "text-align": "center" } }, [
            _vm._v(" " + _vm._s(_vm.$t("operatingPlatform.nullMsg")) + " "),
          ]),
      ![1, 4].includes(_vm.tableInformation.paymentVoucher)
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value:
                    "but:collection-center:largeOrderManagement:upload-payment-voucher",
                  expression:
                    "'but:collection-center:largeOrderManagement:upload-payment-voucher'",
                  arg: "remove",
                },
              ],
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.uploadPayment()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("orderDetails.UploadPaymentVoucher")))]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("orderDetails.UploadPaymentVoucher"),
            visible: _vm.showUploadFiles,
            top: "10vh",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showUploadFiles = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("uploadVoucher", {
                key: _vm.showUploadFiles,
                ref: "uploadVoucher",
                attrs: { details: _vm.tableInformation },
                on: { closeUploadVoucher: _vm.closeUploadVoucher },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showUploadFiles = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveDetail } },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Salve")))]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.showViewer
        ? _c("el-image-viewer", {
            attrs: { "on-close": _vm.closeViewer, "url-list": _vm.imgArr },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }