<!--索赔规则详情-->
<template>
  <div class="query-container">
    <el-form
      ref="ruleForm"
      class="form-container"
      :model="ruleForm"
      :rules="rules"
      size="small"
      :label-position="$i18n.locale !== 'zh'?'top':'right'"
      :label-width="$i18n.locale !== 'zh'?'auto':'120px'"
    >
      <!--      规则编码-->
      <el-form-item :label="$t('compensationManagement.rulesEncoding')">
        <el-input v-model="ruleForm.ruleCode" disabled :placeholder="$t('receivablePayable.SystemGenerated')" />
      </el-form-item>
      <!--      规则名称-->
      <el-form-item :label="$t('GlobalSetObj.RuleName')" prop="ruleName">
        <el-input
          v-model="ruleForm.ruleName" maxlength="100" :placeholder="$t('operationCenter.PleaseEnterTheContent')"
          clearable
        />
      </el-form-item>
      <!--      业务类型-->
      <el-form-item :label="$t('collectionCenter.businessT')" prop="businessType">
        <el-select
          v-model="ruleForm.businessType"
          filterable
          :placeholder="$t('operationCenter.PleaseSelect')"
          clearable
          style="width: 100%;"
          @change="handleBusinessTypeChange"
        >
          <el-option key="5" :label="$t('compensationManagement.AJDDispatch')" :value="5" />
          <el-option key="6" :label="$t('compensationManagement.DSPDispatch')" :value="6" />
        </el-select>
      </el-form-item>
      <!--      索赔类型-->
      <el-form-item :label="$t('compensationManagement.ClaimType')" prop="claimType">
        <el-select
          v-model="ruleForm.claimType"
          filterable
          :placeholder="$t('operationCenter.PleaseSelect')"
          clearable
          style="width: 100%;"
        >
          <el-option key="1" :label="$t('compensationManagement.TimeoutNotClosedLoop')" :value="1" />
        </el-select>
      </el-form-item>
      <!--      计算节点-->
      <el-form-item :label="$t('compensationManagement.computeNode')">
        <el-select
          v-model="ruleForm.calculationNode"
          filterable
          :placeholder="$t('operationCenter.PleaseSelect')"
          clearable
          disabled
          style="width: 100%;"
        >
          <!-- 入库时间-->
          <el-option key="1" :label="$t('compensationManagement.byTimeOfFirstEntry')" value="1" />
          <!-- 派送任务创建时间-->
          <el-option key="2" :label="$t('compensationManagement.deliveryTaskCreationTime')" value="2" />
        </el-select>
      </el-form-item>
      <!-- 计算期间-->
      <el-form-item :label="$t('compensationManagement.calculationPeriod')" prop="calculationPeriod">
        <el-input v-model="ruleForm.calculationPeriod" clearable :placeholder="$t('operationCenter.PleaseEnterTheContent')">
          <template slot="append">{{ $t('orderCenterCont.days') }}</template>
        </el-input>
      </el-form-item>
      <!-- 生效期间-->
      <el-form-item :label="$t('compensationManagement.commencementDate')" prop="date">
        <el-date-picker
          v-model="ruleForm.date"
          type="daterange"
          range-separator="-"
          :start-placeholder="$t('GlobalSetObj.startDate')"
          :end-placeholder="$t('GlobalSetObj.endDate')"
          value-format="yyyy-MM-dd"
          style="width: 100%;"
        />
      </el-form-item>
      <!-- 州-->
      <el-form-item :label="$t('orderDetails.State')" prop="state">
        <el-select
          v-model="ruleForm.state"
          :placeholder="$t('GlobalSetObj.pleaseChoose')"
          clearable
          filterable
          multiple
          style="width: 100%;"
          @change="handleStateChange"
        >
          <el-option key="all" :label="$t('GlobalSetObj.all')" value="ALL" />
          <el-option
            v-for="item in stateList"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          >
            <span style="float: left">{{ item.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.name }}</span>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <div class="form-footer">
      <el-button size="small" @click="onClose">{{ $t('GlobalSetObj.cancel') }}</el-button>
      <el-button size="small" type="primary" @click="handleSubmit">{{ $t('GlobalSetObj.determine') }}</el-button>
    </div>
  </div>
</template>

<script>
import {
  apiClaimRuleConfigAdd,
  apiClaimRuleConfigEdit
} from '@/api/compensationManagement/rule.js';
import { cloneDeep } from 'lodash-es';

export default {
  name: '',
  props: {
    // 州列表
    stateList: {
      type: Array,
      default: () => []
    },
    // 规则详情ID
    id: {
      type: [String, Number],
      default: ''
    },
    // 详情
    row: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ruleForm: {
        ruleName: '', // 规则名称
        businessType: '', // 业务类型
        claimType: 1, // 索赔类型
        calculationNode: '', // 计算节点
        calculationPeriod: 15, // 计算期间
        date: '', // 生效期间
        state: ['ALL'], // 生效期间对应的州id数组
        ruleState: 0, // 规则状态 0 启用 1禁用
        ruleCode: '' // 规则编码
      },
      rules: {
        ruleName: [
          { required: true, message: this.$t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] },
          { min: 1, max: 100, message: this.$t('spareNumberPoo.noMore100Code'), trigger: ['blur', 'change'] }
        ],
        businessType: [
          { required: true, message: this.$t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ],
        claimType: [
          { required: true, message: this.$t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ],
        calculationPeriod: [
          { required: true, message: this.$t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] },
          { pattern: /^[1-9]\d*$/, message: this.$t('collectionCenter.pleaseInputInteger'), trigger: ['blur', 'change'] }
        ],
        date: [
          { required: true, message: this.$t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ],
        state: [
          { required: true, message: this.$t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ]
      }
    };
  },
  created() {
    if (this.id) {
      this.ruleForm = cloneDeep(this.row);
      this.handleBusinessTypeChange(this.ruleForm.businessType);
      this.handleStateChange(this.ruleForm.state.split(','));
      this.$set(this.ruleForm, 'date', [this.ruleForm.effectivePeriodStart.slice(0, 10), this.ruleForm.effectivePeriodEnd.slice(0, 10)]);
      delete this.ruleForm.effectivePeriodStart;
      delete this.ruleForm.effectivePeriodEnd;
    }
  },
  methods: {
    // 业务类型变更事件
    handleBusinessTypeChange(rowVal) {
      if (!rowVal) {
        this.ruleForm.calculationNode = '';
        return;
      }
      // 根据业务类型设置对应的计算节点类型
      switch (rowVal) {
        case 5:
          this.ruleForm.calculationNode = '2';
          break;
        case 6:
          this.ruleForm.calculationNode = '1';
          break;
        default:
          this.ruleForm.calculationNode = ''; // 其他未定义情况设为空
          break;
      }
    },

    /**
     * 州变更事件
     * 默认选中全部，不包含全部时选中选择的州id数组
     * @param rowVal 选择的州数组
     */
    handleStateChange(rowVal) {
      // console.log('handleStateChange', rowVal);
      const processedValues = Array.isArray(rowVal) ? [...rowVal] : [];
      const ALL_OPTION = 'ALL';
      // 判断是否包含 ALL 选项
      const hasAllOption = processedValues.includes(ALL_OPTION);
      if (hasAllOption) {
        // 如果包含 ALL 选项
        if (processedValues[0] === ALL_OPTION && processedValues.length > 1) {
          // ALL 在首位且数组长度大于 1，去掉 ALL 选项
          this.ruleForm.state = processedValues.filter(value => value !== ALL_OPTION);
        } else {
          // 其他情况，只保留 ALL 选项
          this.ruleForm.state = [ALL_OPTION];
        }
      } else {
        // 不包含 ALL 选项，直接赋值
        this.ruleForm.state = processedValues;
      }
    },

    // 保存事件
    handleSubmit() {
      const params = cloneDeep(this.ruleForm);
      params.effectivePeriodStart = params.date[0];
      params.effectivePeriodEnd = params.date[1];
      params.state = params.state.join(',');
      params.ruleState = params.ruleState || 0;
      delete params.date;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 保存逻辑
          const api = this.id ? apiClaimRuleConfigEdit : apiClaimRuleConfigAdd;
          api(params).then((res) => {
            if (res.code === 200) {
              this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
              this.$emit('close', true);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 关闭事件
    onClose() {
      this.$emit('close');
    }
  }
};
</script>

