<!--自定义栏目组件-->
<template>
  <div class="customizableBtn">
    <el-button size="small" @click="openColumns">{{ $t('orderCenterCont.CustomizeListColumn') }}</el-button>

    <el-drawer
      :append-to-body="true"
      :visible.sync="showColumn"
      direction="ltr"
      custom-class="drawerClass"
      :title="$t('orderCenterCont.SelectColumn')"
      width="500px"
      @close="closeDrawer()"
    >
      <div style="padding: 20px;height: calc(100vh - 140px);overflow: auto" class="columnTree">
        <el-tree
          :key="dragging"
          ref="elTree"
          :allow-drop="allowDrop"
          :allow-drag="allowDrag"
          :data="columnList"
          :default-checked-keys="checkedColumns"
          :props="defaultProps"
          check-on-click-node
          default-expand-all
          draggable
          node-key="prop"
          show-checkbox
          @check="handleCheckChange"
          @node-drop="handleDrop"
        />
      </div>
      <div style="padding: 10px 20px;text-align: right;border-top: 1px solid #ccc;">
        <el-button size="small" type="primary" @click="determineColumns()">{{ $t('operationCenter.determine') }}</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  apiCustomColumnTemplateList,
  apiCustomColumnTemplateUpdate,
  apiCustomColumnTemplateInsert
} from '@/api/finance/receivables';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'CustomizableBtn',

  props: {
    params: { // 获取列表参数
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
    saveParams: { // 保存参数配置信息
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
    columnsData: { // 默认自定义列数据
      type: Array,
      required: true,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      showColumn: false, // 控制显示隐藏
      dragging: new Date().getTime(), // 拖拽标识，解决拖拽闪烁问题
      columnList: [ // 自定义列数据
        {
          label: this.$i18n.t('basicData.all'), // '全部',
          prop: '',
          children: [
            ...this.columnsData
          ]
        }
      ],
      defaultProps: {
        children: 'children',
        label: 'label'
      }, // 树形控件的默认属性配置
      customColumnId: null, // 自定义列模板ID
      selectedColumn: [], // 选中列数据
      checkedColumns: [] // 选中列数据props数组
    };
  },

  created() {
    this.getCustomColumnTemplateList();
  },

  methods: {
    /**
     * 获取自定义列模板列表
     */
    getCustomColumnTemplateList() {
      apiCustomColumnTemplateList(this.params)
        .then((res) => {
          if (res.code === 200) {
            if (res.data && res.data.length > 0) {
              this.customColumnId = res.data[0].id;
              this.selectedColumn = JSON.parse(res.data[0].templateField);
            } else {
              this.customColumnId = null;
              this.selectedColumn = [];
            }

            this.customColumnData();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    customColumnData() {
      // 清空checkedColumns以防重复添加
      this.checkedColumns = this.selectedColumn.length
        ? this.selectedColumn.map(item => item.prop)
        : this.columnsData.map(item => item.prop);

      // console.log(this.checkedColumns, '选中的columns');

      // 更新列表columns数据
      const allColumns = this.updateColumns();

      this.$emit('updateColumns', allColumns);
    },
    /**
     * 更新操作按钮
     */
    determineColumns() {
      const neKey = this.$refs.elTree.getCheckedKeys();
      const columns = [];
      this.columnList[0].children.map(list => {
        if (neKey.includes(list.prop)) {
          columns.push(list);
        }
      });
      // console.log(columns, 'columns');

      const obj = cloneDeep(this.saveParams);
      obj.userId = this.$store.state.user.id;
      obj.id = this.customColumnId;
      obj.templateField = JSON.stringify(columns);
      // console.log(obj, 'params');
      const fn = this.customColumnId ? apiCustomColumnTemplateUpdate : apiCustomColumnTemplateInsert;
      fn(obj).then(res => {
        if (res.code === 200) {
          this.showColumn = false;
          this.$message.success(this.$t('basicData.TheOperationSucceeded'));
          this.getCustomColumnTemplateList();
        }
      }).catch(() => {
        console.log('error');
      });
    },
    /**
     * 打开列表列显示设置
     * 1.重置check状态
     * 2.初始默认tree列表
     */
    openColumns() {
      const populateCheckedColumns = (list) => {
        this.checkedColumns = list.map(item => item.prop);
      };

      // 检查是否需要从selectedColumn或columnsData中填充checkedColumns
      if (this.selectedColumn.length > 0) {
        populateCheckedColumns(this.selectedColumn);
      } else {
        populateCheckedColumns(this.columnsData);
      }

      this.updateColumns();

      this.showColumn = true;
      this.dragging = new Date().getTime();
    },
    /**
     * 关闭抽屉组件
     */
    closeDrawer() {
      this.showColumn = false;
    },
    /**
     * 处理复选框状态改变的事件
     * @param data 与复选框相关的数据对象
     * @param checked 包含复选框状态的对象，其中 `checkedKeys` 属性是一个数组，包含了所有当前被选中的复选框的键值
     */
    handleCheckChange(data, checked) {
      this.checkedColumns = checked.checkedKeys;
    },
    /**
     * 处理节点拖放事件的方法
     * 当用户将一个节点拖放到目标位置时，此方法会被调用。
     */
    handleDrop() {
      this.dragging = new Date().getTime();
    },
    /**
     * 判断是否允许将拖动节点放置在目标节点上
     * @param draggingNode 拖动中的节点对象
     * @param dropNode 放置目标节点对象
     * @param type 放置类型，如 'inner' 表示内部放置
     * @returns 如果目标节点层级为2且放置类型不为 'inner'，则返回 true，否则返回 false
     */
    allowDrop(draggingNode, dropNode, type) {
      return dropNode.level === 2 && type !== 'inner';
    },
    /**
     * 判断节点是否允许被拖动
     * @param node 要判断的节点对象
     * @returns {boolean} 如果节点层级为2，则返回true，表示允许拖动；否则返回false
     */
    allowDrag(node) {
      return node.level === 2;
    },
    /**
     * 更新tree列数据
     * @returns {*[]}
     */
    updateColumns() {
      const allColumns = [];
      this.checkedColumns.forEach(col => {
        this.columnsData.forEach(item => {
          if (item.prop === col) {
            allColumns.push(item);
          }
        });
      });

      // console.log(allColumns, 'allColumns');
      // 更新tree
      const temList = getDifference(this.columnsData, this.checkedColumns);

      // console.log(temList, 'temList');

      this.columnList[0].children = [...allColumns, ...temList];

      return allColumns;
    }
  }
};

function getDifference(map1, map2) {
  const difference = [];

  // 遍历map1中的每个元素
  map1.forEach(item1 => {
    // 检查item1的key是否在map2中存在
    const keyExistsInMap2 = map2.some(item2 => item2 === item1.prop);

    // 如果item1的key在map2中不存在，则将其添加到差集中
    if (!keyExistsInMap2) {
      difference.push(item1);
    }
  });

  return difference;
}
</script>

<style lang="scss" scoped>
.customizableBtn {
  margin-inline: 10px;
}
</style>
