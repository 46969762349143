<template>
  <!-- 全环节看板 -->
  <div class="Box">
    <el-form v-model="queryForm" label-position="top">
      <el-row type="flex" justify="space-between" style="flex-wrap: wrap;align-items: center">
        <el-col :span="8">
          <!-- 时间： -->
          <el-form-item :label="$t('userManage.time')">
            <el-date-picker
              v-model="timeValue"
              :clearable="false"
              :default-time="['00:00:00', '23:59:59']"
              :end-placeholder="$t('collectionCenter.EndTime')"
              range-separator="-"
              :start-placeholder="$t('collectionCenter.StartTime')"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
              @change="dateChange"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!-- 大区： -->
          <el-form-item :label="$t('collectionCenter.region') + '：'">
            <el-select
              v-model="queryForm.region"
              :placeholder="$t('collectionCenter.tudo')"
              clearable
              filterable
              @change="getSiteList"
            >
              <el-option
                v-for="(item, index) in bigAreaList"
                :key="index"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--转运中心-->
          <el-form-item :label="$t('collectionCenter.transferCenter')">
            <el-select
              v-model="queryForm.transferCenterIds"
              :placeholder="$t('collectionCenter.tudo')"
              clearable
              collapse-tags
              filterable
              multiple
              style="width: 100%;"
              @change="checkChooseRegion"
            >
              <el-option
                v-for="item in siteList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-button
            v-permit:remove="'btn:order-center:complete-link:find'"
            type="primary"
            size="mini"
            @click="searchClick"
          >{{ $t('newOrder.search') }}</el-button><!-- 搜索 -->
          <el-button
            v-permit:remove="'btn:order-center:complete-link:reset'"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
        </el-col>
      </el-row>
    </el-form>
    <div>
      <!-- 数量单位：票 -->
      <div>{{ $t('collectionCenter.quantityUnit') }}</div>
      <div class="sumBox">
        <div class="s_box">
          <!-- 末端揽收 -->
          <span class="sb_top top1">{{ $t('collectionCenter.endTakeUp') }}</span>
          <!-- 应揽收 -->
          <div class="sumDiv">{{ $t('collectionCenter.receivable') }}：{{ wholeProcessTotalsList.p1.plannedCount }}</div>
          <!-- 实际揽收 -->
          <div class="sumDiv">{{ $t('collectionCenter.actualCollection') }}：{{ wholeProcessTotalsList.p1.actualCount }}</div>
          <!-- 未揽收 -->
          <div class="sumDiv">{{ $t('collectionCenter.notLanShou') }}：{{ wholeProcessTotalsList.p1.difference }}</div>
          <!-- 疑似揽收漏扫 -->
          <div class="sumDiv">{{ $t('changepwd.collectMissScan') }}：{{ wholeProcessTotalsList.p1.suspectedMissedCount }}</div>
        </div>
        <div class="s_box">
          <!-- 揽收装车 -->
          <span class="sb_top top2">{{ $t('collectionCenter.collectLoading') }}</span>
          <!-- 应装车 -->
          <div class="sumDiv">{{ $t('collectionCenter.shouldLoading') }}：{{ wholeProcessTotalsList.p2.plannedCount }}</div>
          <!-- 实际装车 -->
          <div class="sumDiv">{{ $t('collectionCenter.actualLoading') }}：{{ wholeProcessTotalsList.p2.actualCount }}</div>
          <!-- 差值 -->
          <div class="sumDiv">{{ $t('collectionCenter.differenceValue') }}：{{ wholeProcessTotalsList.p2.difference }}</div>
          <!-- 疑似装车漏扫 -->
          <div class="sumDiv">{{ $t('changepwd.loadMissScan') }}：{{ wholeProcessTotalsList.p2.suspectedMissedCount }}</div>
        </div>
        <div class="s_box">
          <!-- 一级分拣中心 -->
          <span class="sb_top top3">{{ $t('collectionCenter.firstLevelSortingCenter') }}</span>
          <!-- 到件票数 -->
          <div class="sumDiv">{{ $t('navbar.arrivalTicketCount') }}：{{ wholeProcessTotalsList.p3.arrivalCount }}</div>
          <!-- 发件票数 -->
          <div class="sumDiv">{{ $t('navbar.shipmentTicketCount') }}：{{ wholeProcessTotalsList.p3.departureCount }}</div>
          <!-- 差值 -->
          <div class="sumDiv">{{ $t('collectionCenter.differenceValue') }}：{{ wholeProcessTotalsList.p3.difference }}</div>
          <!-- 疑似到件漏扫 -->
          <div class="sumDiv">{{ $t('collectionCenter.suspectedMissedSweep') }}：{{ wholeProcessTotalsList.p3.suspectedMissedCount }}</div>
        </div>
        <div class="s_box">
          <!-- 派送装车 -->
          <span class="sb_top top5">{{ $t('collectionCenter.deliveryLoading') }}</span>
          <!-- 应装车 -->
          <div class="sumDiv">{{ $t('collectionCenter.shouldLoading') }}：{{ wholeProcessTotalsList.p4.plannedCount }}</div>
          <!-- 实际装车 -->
          <div class="sumDiv">{{ $t('collectionCenter.actualLoading') }}：{{ wholeProcessTotalsList.p4.actualCount }}</div>
          <!-- 差值 -->
          <div class="sumDiv">{{ $t('collectionCenter.differenceValue') }}：{{ wholeProcessTotalsList.p4.difference }}</div>
          <!-- 疑似装车漏扫 -->
          <div class="sumDiv">{{ $t('changepwd.loadMissScan') }}：{{ wholeProcessTotalsList.p4.suspectedMissedCount }}</div>
        </div>
        <div class="s_box">
          <!-- 二三级分拣中心 -->
          <span class="sb_top top4">{{ $t('collectionCenter.secondThirdSortingCenters') }}</span>
          <!-- 卸车票数 -->
          <div class="sumDiv">{{ $t('navbar.unloadTicketCount') }}：{{ wholeProcessTotalsList.p5.unloadCount }}</div>
          <!-- 装车票数 -->
          <div class="sumDiv">{{ $t('customerManagements.loaderArrived') }}：{{ wholeProcessTotalsList.p5.loadCount }}</div>
          <!-- 差值 -->
          <div class="sumDiv">{{ $t('collectionCenter.differenceValue') }}：{{ wholeProcessTotalsList.p5.difference }}</div>
          <!-- 疑似卸车漏扫 -->
          <div class="sumDiv">{{ $t('collectionCenter.suspectedUnloadingMissingSweep') }}：{{ wholeProcessTotalsList.p5.unloadMissedScan }}</div>
          <!-- 疑似装车漏扫 -->
          <div class="sumDiv">{{ $t('changepwd.loadMissScan') }}：{{ wholeProcessTotalsList.p5.loadMissedScan }}</div>
        </div>
        <div class="s_box">
          <!-- 网点操作数据 -->
          <span class="sb_top top6">{{ $t('collectionCenter.nodeOperationData') }}</span>
          <!-- 网点入库 -->
          <div class="sumDiv">{{ $t('collectionCenter.networkEntry') }}：{{ wholeProcessTotalsList.p6.inboundCount }}</div>
          <!-- 派件票数 -->
          <div class="sumDiv">{{ $t('orderCenterCont.dispatchTicketCount') }}：{{ wholeProcessTotalsList.p6.dispatchCount }}</div>
          <!-- 签收票数 -->
          <div class="sumDiv">{{ $t('collectionCenter.signerNumber') }}：{{ wholeProcessTotalsList.p6.signCount }}</div>
        </div>
      </div>
    </div>

    <el-row>
      <!-- 问题件操作数据 -->
      <div class="topTitle">{{ $t('orderCenterCont.questionItemOperationData') }}</div>
      <el-col :span="12">
        <el-table
          :max-height="600"
          border
          :data="tableData_1"
          style="width: 100%"
          :cell-style="tableCellStyle"
        >
          <el-table-column
            v-for="(column, index) in columns" :key="index"
            :prop="column.prop"
            :label="column.label"
            :width="column.width"
            align="center"
          />
        </el-table>
      </el-col>
      <el-col :span="12">
        <el-table
          :max-height="600"
          border
          :data="tableData_2"
          style="width: 100%"
          :cell-style="tableCellStyle"
        >
          <el-table-column
            v-for="(column, index) in columns" :key="index"
            :prop="column.prop"
            :label="column.label"
            :width="column.width"
            align="center"
          />
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <!-- 网点问题件登记统计 -->
      <div class="topTitle">{{ $t('collectionCenter.networkProblemRgtTotal') }}</div>
      <el-col :span="12">
        <el-table
          :max-height="600"
          border
          :data="tableData_3"
          style="width: 100%"
          :cell-style="tableCellStyle"
        >
          <el-table-column
            v-for="(column, index) in columns_2" :key="index"
            :prop="column.prop"
            :label="column.label"
            :width="column.width"
            align="center"
          />
        </el-table>
      </el-col>
      <el-col :span="12">
        <el-table
          :max-height="600"
          border
          :data="tableData_4"
          style="width: 100%"
          :cell-style="tableCellStyle"
        >
          <el-table-column
            v-for="(column, index) in columns_2" :key="index"
            :prop="column.prop"
            :label="column.label"
            :width="column.width"
            align="center"
          />
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getTransferCenterMap,
  getWholeProcess
} from '@/api/orderApi';
import dayjs from 'dayjs';
export default {
  name: 'CompleteLink',
  data() {
    return {
      timeValue: [],
      bigAreaList: [
        { name: 'SP' },
        { name: 'MG/RJ/ES' },
        { name: 'SC/RS/PR' },
        { name: 'BA/AL/S' },
        { name: 'PE/PB/RN/CE/PI/MA' }
      ],
      siteList: [],
      // 日期时间范围在31以内
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = '';
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== '') {
            const one = 30 * 24 * 3600 * 1000;
            const minTime = this.selectDate - one;
            const maxTime = this.selectDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        }
      },
      queryForm: {
        startDataDate: '',
        endDataDate: '',
        region: null,
        transferCenterIds: null
      },
      columns: [
        { prop: 'ind', label: this.$t('collectionCenter.ind'), width: 60 },
        { prop: 'reason', label: this.$t('collectionCenter.CauseOfTheP') },
        { prop: 'totalTickets', label: this.$t('collectionCenter.ballot') }
      ],
      tableData_1: [],
      tableData_2: [],
      columns_2: [
        { prop: 'ind', label: this.$t('collectionCenter.ind'), width: 60 },
        { prop: 'siteName', label: this.$t('collectionCenter.siteName') },
        { prop: 'totalProblemTickets', label: this.$t('orderCenterCont.issueTicketCount') },
        { prop: 'totalDeliveryTickets', label: this.$t('orderCenterCont.dispatchTicketCount') }
      ],
      tableData_3: [],
      tableData_4: [],
      wholeProcessTotalsList: {
        p1: {},
        p2: {},
        p3: {},
        p4: {},
        p5: {},
        p6: {}
      }
    };
  },
  created() {
    this.default();
    this.getSiteList('');
    this.searchClick();
  },
  methods: {
    tableCellStyle({ row, column, rowIndex, columnIndex }) {
      // 序号列背景色
      if (column.property === 'ind') {
        return 'background-color: #f6f6f6;';
      }
      return '';
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        this.queryForm.startDataDate = '';
        this.queryForm.endDataDate = '';
        return;
      }
      this.queryForm.startDataDate = val[0];
      this.queryForm.endDataDate = val[1];
    },
    checkChooseRegion() {
      if (!this.queryForm.region) {
        this.queryForm.transferCenterIds = null;
        this.$message.warning(this.$t('orderCenterCont.chooseRegion'));
      }
    },
    getSiteList(val) {
      this.queryForm.transferCenterIds = null;
      const data = {
        district: val
      };
      getTransferCenterMap(data).then(res => {
        if (res.code === 200) {
          this.siteList = [];
          for (const val in res.data.districtMap) {
            // eslint-disable-next-line no-prototype-builtins
            if (res.data.districtMap.hasOwnProperty(val)) {
              const label = res.data.districtMap[val];
              const value = Number(val);
              this.siteList.push({
                label,
                value
              });
            }
          }
        }
      });
    },
    default() {
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.queryForm = {
        startDataDate: today + ' 00:00:00',
        endDataDate: today + ' 23:59:59',
        region: null,
        transferCenterIds: null
      };
      this.getSiteList('');
    },
    resetClick() {
      this.default();
      this.searchClick();
    },
    searchClick() {
      if (!this.queryForm.startDataDate || !this.queryForm.endDataDate) {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      getWholeProcess(this.queryForm).then(res => {
        if (res.code === 200) {
          // 票数集合
          this.wholeProcessTotalsList = {};
          res.data.wholeProcessTotals.forEach(list => {
            // dataType  1：末端揽收、2：揽收装车、3：一级分拣中心、4：派送装车、5：二三级分拣中心、6：网点操作数据
            if (list.dataType === 1) { // 1：末端揽收
              this.wholeProcessTotalsList.p1 = {
                plannedCount: list.plannedCount,
                actualCount: list.actualCount,
                difference: list.difference,
                suspectedMissedCount: list.suspectedMissedCount
              };
            }
            if (list.dataType === 2) { // 2：揽收装车
              this.wholeProcessTotalsList.p2 = {
                plannedCount: list.plannedCount,
                actualCount: list.actualCount,
                difference: list.difference,
                suspectedMissedCount: list.suspectedMissedCount
              };
            }
            if (list.dataType === 3) { // 3：一级分拣中心
              this.wholeProcessTotalsList.p3 = {
                arrivalCount: list.arrivalCount,
                departureCount: list.departureCount,
                difference: list.difference,
                suspectedMissedCount: list.suspectedMissedCount
              };
            }
            if (list.dataType === 4) { // 4：派送装车
              this.wholeProcessTotalsList.p4 = {
                plannedCount: list.plannedCount,
                actualCount: list.actualCount,
                difference: list.difference,
                suspectedMissedCount: list.suspectedMissedCount
              };
            }
            if (list.dataType === 5) { // 5：二三级分拣中心
              this.wholeProcessTotalsList.p5 = {
                unloadCount: list.unloadCount,
                loadCount: list.loadCount,
                difference: list.difference,
                unloadMissedScan: list.unloadMissedScan,
                loadMissedScan: list.loadMissedScan
              };
            }
            if (list.dataType === 6) { // 6：网点操作数据
              this.wholeProcessTotalsList.p6 = {
                inboundCount: list.inboundCount,
                dispatchCount: list.dispatchCount,
                signCount: list.signCount
              };
            }
          });

          // 获取问题件操作数据————top10ProblemReasons
          // 获取问题件登记统计数据————top10SiteProblemStats
          let top10ProblemReasons = res.data.top10ProblemReasons;
          top10ProblemReasons = top10ProblemReasons.map((arr, index) => {
            const ind = index + 1;
            return {
              ind,
              ...arr
            };
          });
          const halfLength_1 = Math.ceil(top10ProblemReasons.length / 2);
          this.tableData_1 = top10ProblemReasons.slice(0, halfLength_1);
          this.tableData_2 = top10ProblemReasons.slice(halfLength_1);

          let top10SiteProblemStats = res.data.top10SiteProblemStats;
          top10SiteProblemStats = top10SiteProblemStats.map((arr, index) => {
            const ind = index + 1;
            return {
              ind,
              ...arr
            };
          });
          const halfLength_2 = Math.ceil(top10SiteProblemStats.length / 2);
          this.tableData_3 = top10SiteProblemStats.slice(0, halfLength_2);
          this.tableData_4 = top10SiteProblemStats.slice(halfLength_2);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.sumBox{
  display: flex;
  justify-content: space-between;
  align-items: self-start;
  flex-wrap: wrap;
  .s_box{
    border: 1px solid #E5E5E5;
    border-radius: 3px;
    padding: 6px;
    box-sizing: border-box;
    width: 16%;
    height: 200px;
    overflow-y: auto;
    .sb_top{
      display: inline-block;width: 100%;padding: 3px 0;box-sizing: border-box;
      font-weight: bold;font-size: 16px;text-align: center;
      margin-bottom: 20px;
    }
    .top1{background: #F4FDF7;color: #009C37}
    .top2{background: #FFF9EF;color: #FFA700}
    .top3{background: #F8F8FF;color: #5A5AED}
    .top4{background: #F4FAFF;color: #3E9BE8}
    .top5{background: #FFF9EF;color: #FFA700}
    .top6{background: #F4FDF7;color: #009C37}
    .sumDiv{font-size: 15px;line-height: 25px}
  }
}
::v-deep .el-table__empty-block{min-height: 34px !important;}
::v-deep .el-table__empty-text{line-height: 34px !important;}
::v-deep .el-date-editor, ::v-deep .el-select {
  width: 100% !important;
}
::v-deep .el-table .el-table__cell{
  padding: 5px 0 !important;
}
::v-deep .el-table th.el-table__cell{
  background-color: #f6f6f6;
  color: #000;
}
.topTitle{
  margin-top: 30px;
  text-align: center;
  font-size: 15px;
  color: #000;
  font-weight: bold;
  background-color: #f6f6f6;
  padding: 5px 0;
  border: 1px solid #EBEEF5;
  border-bottom: none;
  box-sizing: border-box;
}
.Box{
  padding: 20px;
  box-sizing: border-box;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
