var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "box-card" },
    [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "15px",
            "font-weight": "bold",
            color: "#be8130",
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("navbar.trackingStop")) + " ")]
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.trackingStopMonitorVOS,
            "header-cell-style": { color: "black" },
            height: "375px",
            stripe: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("navbar.siteName"),
              align: "center",
              prop: "siteName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.owningTransitCenter1"),
              align: "center",
              prop: "department",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.department || "--"))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "停更1天", prop: "stopDays1" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.siteId === null
                      ? _c("div", { staticClass: "fontClsBold03" }, [
                          _vm._v(
                            " " + _vm._s(scope.row.stopDays1 || "--") + " "
                          ),
                        ])
                      : _c("div", [
                          _vm._v(_vm._s(scope.row.stopDays1 || "--")),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "停更3天", prop: "stopDays3" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.siteId === null
                      ? _c("div", { staticClass: "fontClsBold03" }, [
                          _vm._v(
                            " " + _vm._s(scope.row.stopDays3 || "--") + " "
                          ),
                        ])
                      : _c("div", [
                          _vm._v(_vm._s(scope.row.stopDays3 || "--")),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "停更7天", prop: "stopDays7" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.siteId === null
                      ? _c("div", { staticClass: "fontClsBold03" }, [
                          _vm._v(
                            " " + _vm._s(scope.row.stopDays7 || "--") + " "
                          ),
                        ])
                      : _c("div", [
                          _vm._v(_vm._s(scope.row.stopDays7 || "--")),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "停更15天", prop: "stopDays15" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.siteId === null
                      ? _c("div", { staticClass: "fontClsBold03" }, [
                          _vm._v(
                            " " + _vm._s(scope.row.stopDays15 || "--") + " "
                          ),
                        ])
                      : _c("div", [
                          _vm._v(_vm._s(scope.row.stopDays15 || "--")),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "停更30天", prop: "stopDays30" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.siteId === null
                      ? _c("div", { staticClass: "fontClsBold03" }, [
                          _vm._v(
                            " " + _vm._s(scope.row.stopDays30 || "--") + " "
                          ),
                        ])
                      : _c("div", [
                          _vm._v(_vm._s(scope.row.stopDays30 || "--")),
                        ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }