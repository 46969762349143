import request from '@/utils/request';

const baseURL2 = process.env.VUE_APP_BASE_API1;
const baseURL5 = process.env.VUE_APP_manualPush;
const baseURL1 = process.env.VUE_APP_ADDRESS;
const baseURLAdmin = process.env.VUE_APP_ADMIN;
const baseURLFINANCE = process.env.VUE_APP_FINANCE;

// 快递下单————快递业务增加费用测算
export const queryCalculateFee = (data) => {
  return request({
    url: `/siteExpense/queryCalculateFee`,
    method: 'post',
    data,
    baseURL: baseURLFINANCE
  });
};

// 全环节看板————获取面板数据
export const getWholeProcess = (data) => {
  return request({
    url: `/report/wholeProcess/getWholeProcess`,
    method: 'post',
    data,
    baseURL: baseURLAdmin
  });
};

// 全环节看板————通过所选大区获取转运中心数据
export const getTransferCenterMap = (data) => {
  return request({
    url: `/report/wholeProcess/getTransferCenterMap`,
    method: 'post',
    data,
    baseURL: baseURLAdmin
  });
};

/**
 * 客户----分页数据
 */
export const getCustomer = (data) => {
  return request.post('/customer/customerPage', data);
};
/**
 * 字典列表
 */
export const dictLangList = (data) => {
  return request.post('/dict/page', data);
};
/**
 * 字典列表——新增字典
 */
export const dlAdd = (data) => {
  return request.post('/dict', data);
};
/**
 * 字典列表——修改字典
 */
export const dlEditAdd = (data) => {
  return request.put('/dict/' + data.id, data);
};

/**
 * 多语言新增
 */
export const LangMoreAdd = (data) => {
  return request.post('/dictLang', data);
};
/**
 * 多语言新增——id下拉框
 */
export const LangMoreId = (data) => {
  return request.post('/dict/list', data);
};
/**
 * 字典多语言列表
 */
export const dictLangMoreList = (data) => {
  // return request.post('/dictLang/list', data);
  return request.post('/dictLang/page', data);
};
/**
 * 字典多语言编辑
 */
export const dictLangMoreEdit = (data) => {
  return request.put('/dictLang/' + data.id, data);
};
/**
 * 偏远邮编管理
 */
export const postcodePage = (data) => {
  return request.post('/postcode/page', data);
};

/**
 * 获取订单列表
 */
export const getOrderList = (data) => {
  return request.post('/order/page', data); // 管理端
  // return request.post('/clientOrder/page', data); // 客户端
};
/**
 * 客户端根据订单id返回子运单信息
 */
export const getOrderWaybillByOrderId = (orderId) => {
  return request.get('orderWaybill/getOrderWaybillByOrderId/' + orderId);
};
/**
 * 批量导入
 return request.post('/order/import?file=' + data);
 */
export const orderImport = (data) => {
  return request({
    method: 'POST',
    url: '/order/customerImport/' + data.customerId,
    data: data.file,
    headers: { 'content-type': 'multipart/form-data' }
  });
};

/**
 * 下单前先验证客户单号
 */
export const checkCustomerNumber = (data) => {
  return request.post(
    '/clientOrder/checkCustomerNumber?customerNumber=' +
      data.customerNumber +
      '&customerId=' +
      data.customerId
  );
};

/**
 * 校验邮编
 */
export const checkPostcode = (data) => {
  return request.get('/postcode/check?postcode=' + data);
};

/**
 * 下单
 */
export const createOrder = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/client/create',
    data,
    baseURL: baseURL2
  });
};
/**
 * 复制订单
 */

export const CopyOrder = (data) => {
  return request.post('/order/orderWaybillInfo', data);
};

/**
 * 分页查询客户常用收件地址
 */
export const pageCustomerReceive = (data) => {
  return request.post('/order/pageCustomerReceive', data);
};
/**
 * 分页查询客户常用发件地址
 */
export const pageCustomerSender = (data) => {
  return request.post('/order/pageCustomerSender', data);
};
/**
 * 同步收件人客户地址
 */
export const createCustomerReceive = (data) => {
  return request.post('/order/createCustomerReceive', data);
};
/**
 * 同步发件人客户地址
 */
export const createCustomerSender = (data) => {
  return request.post('/order/createCustomerSender', data);
};
/**
 * 硬删除发件人信息
 */
export const deleteCustomerSender = (id) => {
  return request.get(`/order/deleteCustomerSender/${id}`);
};
/**
 * 硬删除收件人信息
 */
export const deleteCustomerReceive = (id) => {
  return request.get(`/order/deleteCustomerReceive/${id}`);
};
/**
 * 修改客户常用发件地址
 */
export const updateCustomerSender = (data) => {
  return request.post('/order/updateCustomerSender', data);
};
/**
 * 修改客户常用收件地址
 */
export const updateCustomerReceive = (data) => {
  return request.post('/order/updateCustomerReceive', data);
};
/**
 * 导出选中订单
 */
export const orderExport = (data) => {
  return request.post('/order/orderExport', data);
};
/**
 * 查看订单详情
 */
export const getOrderDetail = (id) => {
  // return request.get('/logistics/order/getOrderDetails?orderId=' + data);
  return request.get('/order/' + id);
};
/**
 * 获取订单列表
 */
// export const getOrderList = (data) => {
//   // return request.post('/logistics/order/page', data);
//   return request.post('/order/page', data);
// };
/**
 * 查看订单详情
 */
// export const getOrderDetail = (data) => {
//   return request.get('/logistics/order/getOrderDetails?orderId=' + data);
// };
/**
 * 添加订单
 */
// export const addOrderItem = (data) => {
//   return request.post('/logistics/order/add', data);
// };
/**
 * 订单作废
 */
export const deleteOrderItem = (data) => {
  return request.post(
    '/logistics/order/invalid?ids=' +
      data.ids +
      '&operator=' +
      data.operator +
      '&problemRemarks=' +
      data.problemRemarks +
      '&problemType=' +
      data.problemType
  );
};
/**
 * 修改订单
 */
export const changeOrderItem = (data) => {
  return request.post('/logistics/order/edit', data);
};
/**
 * 获取所有国家选项
 */
export const getCountryList = (data) => {
  return request.get('/logistics/country/getCountryList', data);
};
/**
 * 获取国家树型结构
 */
// export const getCountryTreeType = (data) => {
//   return request.get('/logistics/lgArea/treeList', data);
// };
/**
 * 分页获取国家列表
 */
// export const getPageCountry = (data) => {
//   return request.post('/logistics/country/page', data);
// };
/**
 * 添加国家
 */
// export const addCountryItem = (data) => {
//   return request.post('/logistics/country/add', data);
// };
/**
 * 修改国家
 */
// export const changeCountryItem = (data) => {
//   return request.post('/logistics/country/edit', data);
// };
/**
 * 删除国家
 */
// export const deleteCountryItem = (data) => {
//   return request.post('/logistics/country/delete?id=' + data);
// };
/**
 * 获取收发件人列表
 */
export const getAddressBook = (data) => {
  return request.post('/logistics/addressBook/page', data);
};
/**
 * 添加收、发人
 */
export const addSendReceivePeople = (data) => {
  return request.post('/logistics/addressBook/add', data);
};

// 编辑收、发人
export const apiEditAddressBook = (data) => {
  return request.put('/logistics/addressBook/edit', data);
};

/**
 * 设置默认收寄件人
 */
export const setSendReceivePeople = (data) => {
  return request.post('/logistics/addressBook/setDefault?id=' + data.id + '&&type=' + data.type);
};
/**
 * 删除收寄件人
 */
export const deleteSendReceivePeople = (ids) => {
  // return request.post('/logistics/addressBook/delete?id=' + data);
  return request.delete(`/logistics/addressBook/delete/${ids}`);
};
/**
 * 获取收件人
 */
// export const getReceivePeople = (data) => {
//   return request.get('/logistics/addressBook/getReceiveList', data);
// };

/**
 * 新 收/发件人
 */
// export const apiAddressBook = (data, isBool = true) => {
//   return request({
//     method: 'post',
//     data,
//     url: `/logistics/addressBook/getList`,
//     isLoading: isBool // 是否显示加载中参数
//   });
//   // return request.post('/logistics/addressBook/getList', data);
// };

/**
 * 获取发件人
 */
// export const getSendPeople = (data) => {
//   return request.get('/logistics/addressBook/getSendList', data);
// };
/**
 * 省份列表
 */
// export const getProvinceList = (data) => {
//   return request.post('/logistics/province/page', data);
// };
/**
 * 省份添加
 */
export const addNewProvince = (data) => {
  return request.post('/logistics/province/add', data);
};
/**
 * 修改省份
 */
export const changeNewProvince = (data) => {
  return request.put('/logistics/province/edit', data);
};
/**
 * 删除省份
 */
// export const deleteNewProvince = (data) => {
//   return request.delete('/logistics/province/delete/' + data);
// };

/**
 * 添加城市
 */
// export const addNewCity = (data) => {
//   return request.post('/logistics/city/add', data);
// };
/**
 * 修改城市
 */
export const changeNewCity = (data) => {
  return request.put('/logistics/city/edit', data);
};
/**
 * 删除城市
 */
export const deleteNewCity = (data) => {
  return request.delete('/logistics/city/delete/' + data);
};
/**
 * 新增地区
 */
export const addLgAreaList = (data) => {
  return request.post('/area/add', data);
};
// 地区列表查询
export const getLgAreaListData = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/area/list`,
    isLoading: isBool // 是否显示加载中参数
  });
  // return request.post('/logistics/lgArea/list', data);
};

/**
 * 地区分页查询
 */
export const getLgAreaList = (data) => {
  return request.post('/area/page', data);
};
/**
 * 地区修改
 */
export const editLgAreaList = (data) => {
  return request.put('/area/edit', data);
};
/**
 * 地区删除
 */
export const deleteLgAreaList = (data) => {
  return request.delete('/area/delete/' + data);
};
/**
 * 渠道分页查询
 */
export const channelPage = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/logistics/customerChannel/page`,
    isLoading: isBool // 是否显示加载中参数
  });
  // return request.post('/logistics/customerChannel/page', data);
};
/**
 * 客户渠道详情
 */
export const channelDetail = (data) => {
  return request.get('/logistics/customerChannel/getById/' + data);
};

// 获取客户渠道选项
export const getChannelPage = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/logistics/channel/route/statistical/channel/page`,
    isLoading: isBool // 是否显示加载中参数
  });
  // return request.post('/logistics/channel/route/statistical/channel/page', data);
};

/**
 * 获取所有渠道
 */
export const channelList = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/logistics/customerChannel/list`,
    isLoading: isBool // 是否显示加载中参数
  });
  // return request.post('/logistics/customerChannel/list', data);
};

/**
 * 新增渠道
 */
export const addChannel = (data) => {
  return request.post('/logistics/customerChannel/add', data);
};
/**
 * 修改渠道
 */
export const editChannel = (data) => {
  return request.post('/logistics/customerChannel/edit', data);
};
/**
 * 删除渠道
 */
// export const deleteChannel = (id) => {
//   return request.post('/logistics/customerChannel/delete/' + id);
// };
/**
 * 订单日志
 */
// export const getOrderLog = (id, orderId) => {
//   return request.get('/logistics/order/getOrderOperateLog/' + id + '/' + orderId);
// };
/**
 * 获取轨迹列表
 */
export const getTrajectoryList = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/logistics/shipment/page`,
    isLoading: isBool // 是否显示加载中参数
  });
  // return request.post('/logistics/shipment/page', data);
};
/**
 * 添加轨迹
 */
export const addTrajectory = (data) => {
  return request.post('/logistics/shipment/add', data);
};
/**
 * 修改轨迹
 */
export const changeTrajectory = (data) => {
  return request.put('/logistics/shipment/edit', data);
};
/**
 * 删除轨迹
 */
export const deleteTrajectory = (id) => {
  return request.delete('/logistics/shipment/delete/' + id);
};

/**
 * 获取渠道关联邮路
 */
export const getChannelRoute = (data) => {
  return request.post('/logistics/channel/route/page', data);
};
/**
 * 新增渠道相关邮路
 */
export const addChannelRoute = (data) => {
  return request.post('/logistics/channel/route/add', data);
};
/**
 * 修改渠道相关邮路
 */
export const changeChannelRoute = (data) => {
  return request.put('/logistics/channel/route/edit', data);
};
/**
 * 删除渠道相关邮路
 */
export const deleteChannelRoute = (id) => {
  return request.delete('/logistics/channel/route/delete/' + id);
};
/**
 * 获取所有轨迹服务商
 */
export const getAllTrajectory = (data) => {
  return request.get('/logistics/shipment/list', data);
};
/**
 * 订单上传
 */
// export const uploadOrderData = (id, data) => {
//   return request.post('/logistics/order/uploadOrderData/' + id, data);
// };
/**
 * 订单模板导出
 */
export const exportOrders = (id) => {
  return request.get('/logistics/order/exportOrderTemplate/' + id);
};

/**
 * 轨迹状态分页查询
 */
export const getTrackState = (data) => {
  return request.post('/logistics/trackState/page', data);
};
/**
 * 添加轨迹状态
 */
export const addTrackState = (data) => {
  return request.post('/logistics/trackState/add', data);
};
/**
 * 轨迹状态修改
 */
export const changeTrackState = (data) => {
  return request.put('/logistics/trackState/edit', data);
};
/**
 * 删除轨迹状态
 */
export const deleteTrackState = (id) => {
  return request.delete('/logistics/trackState/delete/' + id);
};
/**
 * 获取轨迹状态参照
 */
export const getTrackStateRef = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/logistics/trackStateRef/page`,
    isLoading: isBool // 是否显示加载中参数
  });
  // return request.post('/logistics/trackStateRef/page', data);
};
/**
 * 添加轨迹状态参照
 */
export const addTrackStateRef = (data) => {
  return request.post('/logistics/trackStateRef/add', data);
};
/**
 * 修改轨迹状态参照
 */
export const changeTrackStateRef = (data) => {
  return request.put('/logistics/trackStateRef/edit', data);
};
/**
 * 删除轨迹状态参照
 */
export const deleteTrackStateRef = (id) => {
  return request.delete('/logistics/trackStateRef/delete/' + id);
};
/**
 * 获取所有轨迹状态
 */
export const getTrackStateRefAll = (data) => {
  return request.get('/logistics/trackState/list', data);
};
/**
 * 临时匹配
 */
export const getTrackStateTemp = (data) => {
  return request.post('/logistics/trackStateTemp/page', data);
};
/**
 * 批量匹配
 */
export const batchmatchState = (data) => {
  return request.post(
    'logistics/trackStateTemp/batchmatchState?ids=' +
      data.ids +
      '&stateCode=' +
      data.stateCode +
      '&type=' +
      data.type
  );
};

/**
 * 新增匹配临时轨迹
 */
export const addTrackStateTemp = (data) => {
  return request.post('/logistics/trackStateTemp/add', data);
};
/**
 * 删除临时轨迹
 */
export const deleteTrackStateTemp = (id) => {
  return request.delete('/logistics/trackStateTemp/delete/' + id);
};
/**
 * 获取渠道支持国家
 */
export const getChannelCountryIds = (id) => {
  return request.get('/logistics/customerChannel/getEnableCountryList/' + id);
};
/**
 * 设置支持渠道国家
 */
export const setEnableCountry = (data) => {
  return request.post('/logistics/customerChannel/enableCountry', data);
};
/**
 * 获取支持国家信息
 */
// export const getEnableCountryInfo = (id, code) => {
//   return request.get('/logistics/customerChannel/getEnableCountryInfo/' + id + '/' + code);
// };
/**
 * 获取渠道号码规则
 */
export const getNumberRules = (id) => {
  return request.get('/logistics/customerChannel/getNumberRules/' + id);
};
/**
 * 设置渠道号码规则
 */
export const setNumberRules = (data) => {
  return request.post('/logistics/customerChannel/setNumberRules', data);
};
/**
 * 获取服务商渠道列表
 */
export const providerChannelList = (data) => {
  return request.get('/providerChannel/list', data);
};

/**
 * 手动匹配状态
 */
export const matchState = (data) => {
  return request.post(
    '/logistics/trackStateTemp/matchState?id=' +
      data.id +
      '&type=' +
      data.type +
      '&stateCode=' +
      data.stateCode
  );
};
/**
 * 轨迹列表
 */
export const getTrackList = (data) => {
  return request.post('/logistics/track/page', data);
};
/**
 * 下载渠道相关邮路模板
 */
export const exportChannelRouteTemplate = () => {
  return '/api/logistics/channel/route/exportChannelRouteTemplate';
};
/**
 * 批量导入渠道相关邮路
 */
export const uploadChannelRouteData = (data) => {
  return request.post('/logistics/channel/route/uploadChannelRouteData', data);
};
/**
 * 订单商品删除
 */
export const orderItemDelete = (data) => {
  return request.post('/logistics/orderItem/delete?id=' + data);
};

// 创建物流订单，下单取号
export const apiCreateLogisticsOrder = (ids) => {
  return request.get(`/logistics/order/applyServiceChannelOrder/${ids}`);
};
// 创建物流订单
// export const createLogisticsOrder = data => {
//   return request.post('/logistics/order/createLogisticsOrder', data);
// };

// 跟进信息 - 列表
export const apiChannelFollowList = (ids, type) => {
  return request.get(`/channelFollow/listByCustomerId/${ids}/${type}`);
};

// 跟进信息 - 新增
export const apiChannelFollowAdd = (data) => {
  return request.post('/channelFollow/add/', data);
};

// 跟进信息 - 删除
export const apiChannelFollowDel = (ids) => {
  return request.put(`/channelFollow/delete/${ids}`);
};

// 下单日志
export const orderLog = (data) => {
  // return request.post('/logistics/order/log/page', data);
  return request.post('/orderLog/page', data);
};
// 获取订单操作日志
export const getOperateOrderLog = (data) => {
  return request.get('/logistics/order/getOperateOrderLog/' + data);
};
// 导出单号轨迹
export const trackExport = (data) => {
  return request.post('/logistics/track/export', data);
};
// 客户渠道操作日志
export const channelManageLog = (data) => {
  return request.post('/channelOperateLog/page', data);
};
// 获取风控物流订单
export const getRiskControlOrder = (data) => {
  return request.post('/logistics/riskControlOrder/page', data);
};
// 获取风控订单详情
export const getRiskControlOrderDetail = (data) => {
  return request.get('/logistics/riskControlOrder/getById/' + data);
};
// 导出风控订单
// export const exportRiskControlOrder = data => {
//   return request.post('/logistics/riskControlOrder/list', data);
// };
export const exportRiskControlOrder = (data) => {
  return request.post('/logistics/riskControlOrder/export/riskControlOrder', data);
};

// 扣件
export const detainOrder = (data) => {
  return request.post(
    '/logistics/order/problem?ids=' +
      data.id +
      '&operator=' +
      data.userId +
      '&problemRemarks=' +
      data.problemRemarks +
      '&problemType=' +
      data.problemType
  );
};
// 问题件取消
export const cancelProblem = (data) => {
  return request.post('/logistics/order/cancel/problem?ids=' + data.id + '&operator=' + data.userId);
};
// 导入订单
export const batchCreateOrder = (userId, data) => {
  return request.post('/batchCreateOrder/order/client/create/' + userId, data);
};
// 轨迹抓取报表
export const trackGradStatistical = () => {
  return request.get('/trajectoryTracking/trackGradStatistical');
};
// 轨迹统计
export const trackStatistics = (data) => {
  return request.post('/logistics/track/track/statistics', data);
};
// 轨迹统计详情
export const statisticsDetail = (data) => {
  return request.post('/logistics/track/track/statistics/detail', data);
};
// 轨迹抓取导出
export const exportStatisticsDetail = (data) => {
  return request.post('/logistics/track/track/statistics/export', data);
};

// 添加人工轨迹
export const addTrackEvent = (data) => {
  return request.post('/trackEvent/add', data);
};
// 人工轨迹分页查询
export const getTrackEvent = (data) => {
  return request.post('/trackEvent/page', data);
};
// 查询所有安骏状态
export const getAnJunAllState = () => {
  return request.get('/logistics/trackStateRef/getAnjunAllState');
};
// 人工轨迹修改
// export const editTrackEvent = (data) => {
//   return request.put('/trackEvent/edit', data);
// };
// 删除人工轨迹
// export const deleteTrackEvent = (id) => {
//   return request.delete('/trackEvent/delete/' + id);
// };
// 获取袋号
export const getBillNumber = (id) => {
  return request.get('/logistics/track/getBagNumberByAirBillNumber?airBillNumber=' + id);
};
// 人工轨迹制作
export const makeTrajectory = (data) => {
  return request.post(
    '/trackArtificial/maketrajectory?address=' +
      data.address +
      '&airBillNumber=' +
      data.airBillNumber +
      '&bagNumbers=' +
      data.bagNumbers +
      '&date=' +
      data.date +
      '&event=' +
      data.event +
      '&status=' +
      data.status
  );
};
// 人工轨迹分页
export const getTrajectoryPage = (data) => {
  return request.post('/trackArtificial/page', data);
};
// 绑定提单号导入
export const bindingAirBillNumber = (data) => {
  return request.post('/logistics/track/bindingAirBillNumber', data);
};
export const importAirBillOfLading = (data) => {
  return request.post('/logistics/track/importAirBillOfLading', data);
};
// 导出订单
export const apiFindOrderListWithOrderItem = (data) => {
  return request.post('/logistics/order/findOrderListWithOrderItem', data);
};
// 导出10000条带商品
export const exportOrderItem = (data) => {
  return request.post('/logistics/order/export/orderData', data);
};
// 导出50000条不带商品
export const exportOrderNo = (data) => {
  return request.post('/logistics/order/export/orderData/excitem', data);
};
// 快递来源分页
export const pageWmsExpressSource = (data) => {
  return request.post('/wmsExpressSource/page', data);
};
// 删除订单来源
export const deleteWmsExpressSource = (data) => {
  return request.post('/wmsExpressSource/delete?id=' + data);
};
// 添加快递
export const addWmsExpressSource = (data) => {
  return request.post('/wmsExpressSource/add', data);
};
// 编辑快递
export const editWmsExpressSource = (data) => {
  return request.post('/wmsExpressSource/edit', data);
};
// 更换轨迹服务商
export const changeShipmentByAirBillNumber = (data) => {
  return request.post(
    '/logistics/track/changeShipmentByAirBillNumber?bagNumbers=' +
      data.bagNumbers +
      '&shipmentCode=' +
      data.shipmentCode
  );
};
// 导出订单数据推送
export const exportDataPush = (data) => {
  return request.post('/orderDataPushLog/export/dataPush', data);
};

// 上传自飞订单
export const apiAirBillConsolidationDetail = (data) => {
  return request.post('/wms/airBill/airBillConsolidationDetail', data);
};

// 获取订单数据推送分页
export const getOrderDataPushPage = (data) => {
  return request.post('/orderDataPushLog/page', data);
};

// 订单数据推送
export const getOrderDataRepeatPush = (referenceNums) => {
  return request.post('/orderDataPushLog/repeatPush', referenceNums); // timedApi/task/order/repeatPush
};

// 参考号计价
export const calculator = (data) => {
  return request.post('/fa/bill/calculator?referenceNums=' + data.referenceNums); // timedApi/task/order/repeatPush
};
// 参考号扣费
export const billDeduct = (data) => {
  return request.post('/fa/bill/deduct?referenceNums=' + data.referenceNums); // timedApi/task/order/repeatPush
};
// 制作闭环轨迹
export const makeCloseTrajectory = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/trackArtificial/make/close/trajectory`,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 轨迹提单管理分页
export const getTrackBillPage = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/logistics/track/airway/bill/page`,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 跟进信息修改
export const billDialogue = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/logistics/track/airway/bill/dialogue`,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 添加轨迹提单管理
export const addBillDialogue = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/logistics/track/airway/bill/add`,
    isLoading: isBool // 是否显示加载中参数
  });
};
export const editBillDialogue = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/logistics/track/airway/bill/edit`,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 航空提单管里时间修改
export const billEditTimes = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/logistics/track/airway/bill/editTimes`,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 地址纠错分页查询
export const getAddressPage = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/address/error/correction/page`,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 添加纠错信息
export const addAddress = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/address/error/correction/add`,
    isLoading: isBool // 是否显示加载中参数
  });
};

// 编辑
export const apiCorrectionEdit = (data) => {
  return request.post('/address/error/correction/edit', data);
};

// 删除纠错信息
export const deleteAddress = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/address/error/correction/delete`,
    isLoading: isBool // 是否显示加载中参数
  });
};

// 启用 / 禁用
export const apiUpdateStatus = (ids) => {
  return request.post(`/address/error/correction/updateStatus/${ids}`);
};

// 地址纠错临时匹配分页
export const matchingPage = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/address/error/temp/page`,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 地址纠错
export const matchingAddress = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/address/error/temp/handle/errors`,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 17清除缓存
export const clearCache17 = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/logistics/track/clearCache?orderNos=' + data,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 批量更新订单预报重量
export const editForecastWeight = (data) => {
  return request.post('/logistics/order/edit/forecastWeight', data);
};

// 手动拉取钮门订单
// const EmmisOrder = process.env.VUE_APP_PULL_EMMIS_ORDER;
export const pullEmmisOrder = (data) => {
  return request.post('/logistics/track/pullEmmisOrder', data);
  // return request({
  //   method: 'POST',
  //   baseURL: EmmisOrder,
  //   url: '/task/manual/pullEmmisOrder',
  //   data: data
  // });
};
// 替换提单号
export const replaceAirBillNumber = (data) => {
  return request.post('/logistics/track/replaceAirBillNumber', data);
};

// 根据订单号,参考号,物流单号扫描查询订单
export const apiOrderScan = (data) => {
  return request.get(`/logistics/order/scan?orderNos=${data}`);
};
// 获取查询条件下的所有状态
export const getAnjunState = (data) => {
  return request.post('/logistics/trackStateRef/getAnjunState', data);
};
// 修改HSCODE
export const batchHSCode = (data) => {
  return request.post('/logistics/order/edit/batchHSCode', data);
};
/**
 * @description: 巴西订单导入模板下载
 * @param { String } userId
 */
export const exportWeightTemplate = (data) => {
  return request.get(`/logistics/order/exportWeightTemplate/${data}`);
};
/**
 * @description: 巴西本地逆向物流订单数据导入
 * @param { File } file 表格
 */
export const weightData = (data) => {
  return request({
    url: '/logistics/order/import/weightData',
    data,
    method: 'post',
    headers: { 'content-type': 'multipart/form-data; boundary=something' }
  });
};
// 订单统计页面
export const orderStatistic = (data) => {
  return request.post('/order/orderStatistic', data);
};
/**
 * 根据客户id查询商户
 */
export const customerMerchant = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/invoice/customerMerchant',
    data,
    baseURL: baseURL2
  });
};
/**
 * 根据客户id查询商户
 */
export const inquireMerchant = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/invoice/inquireMerchant',
    data,
    baseURL: baseURL2
  });
};
/**
 * 校验邮编
 */
export const checkedzipcode = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/checked/zipcode',
    data,
    baseURL: baseURL2
  });
};
export const apiOrderLog = (data) => {
  return request({
    method: 'POST',
    url: '/orderLog/page',
    data
  });
};
// 运单时效统计
export const orderWaybillTimeRecord = (data) => {
  // return request.post('/orderWaybillTimeRecord/page', data);
  return request({
    method: 'POST',
    url: '/orderWaybillTimeRecord/page',
    data,
    baseURL: baseURLAdmin
  });
};
// 运单时效统计导出
export const orderWaybillTimeRecordExport = (data) => {
  return request.post('/orderWaybillTimeRecord/export', data);
};
// 供应商数据
export const apiGetProviderWaybill = (data) => {
  return request({
    method: 'POST',
    url: '/provider/getProviderWaybill',
    data
  });
};
// 导出供应商数据
export const apiProviderExport = (data) => {
  return request({
    method: 'POST',
    url: '/provider/export',
    data
  });
};
// 网点扫描率分页
export const trackStatisticalsiteScanlist = (data) => {
  return request.post('/trackStatistical/siteScan/page', data);
};
// 网点扫描率分页
export const trackStatisticalExport = (data) => {
  return request.post('/trackStatistical/siteScan/export', data);
};
// 运单状态查询
export const waybillDashboard = (data) => {
  return request.post('/waybillDashboard/waybillStatusPage', data);
};
// 客户运单情况
export const customerWaybillInfo = (data) => {
  return request.post('/waybillDashboard/customerWaybillInfo', data);
};
// 订单推送数据查询
export const pushOrderWaybill = (data) => {
  return request.post('/waybillDashboard/pushOrderWaybill', data);
};
// 月季度运单情况
export const monthlyWaybillStatus = (data) => {
  return request.post('/waybillDashboard/monthlyWaybillStatus', data);
};

/**
 * @description: 全链路时效
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const trackStatisticalFullLinkPage = (data) => {
  return request.post('/trackStatistical/fullLink/page', data);
};

export const trackStatisticalFullLinkExport = (data) => {
  return request.post('/trackStatistical/fullLink/export', data);
};
// SHEIN日派送数据监控列表查询（废弃）
export const aipSheinDeliveredDaily = (data) => {
  return request.post('/orderWaybillTimeRecord/sheinDeliveredDaily', data);
};
// SHEIN日派送数据监控列表导出（废弃）
export const aipSheinDeliveredDailyExport = (data) => {
  return request.post('/orderWaybillTimeRecord/sheinDeliveredDaily/export', data);
};
// 分页查询州省
export const aipSheinAgingAreaGetState = (data) => {
  return request.get('/sheinAgingArea/getStateList', data);
};
/**
 * @description: 系统消息,添加消息
 * @param data
 */
export const sendMessage = (data) => {
  return request.post('/message/sendProviderMessage', data);
};
/**
 * @description: 系统消息,消息查询
 * @param data
 */
export const selectProviderMessage = (data) => {
  return request.post('/message/selectProviderMessage', data);
};
// 运单时效统计
export const shipmentPage = (data) => {
  return request.post('/shipment/page', data);
};
// 运单时效统计
export const shipmentExportAll = (data) => {
  return request.post('/shipment/exportAll', data);
};
// 运单时效统计
export const selectWaybillDetails = (waybill) => {
  return request.get(`shipment/selectWaybillDetails/${waybill}`);
};
// 运单时效统计
export const selectSiteByState = (state) => {
  return request.get(`/shipment/selectSiteByState/${state}`);
};
/**
 * 根据州，网点，操作人查询
 */
export const selectInfoByStateTwo = (data) => {
  return request.post(`/shipment/selectInfoByStateTwo`, data);
};

/**
 * @description: SHEIN日派送数据监控
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const reportSheinDeliveredDaily = (data) => {
  return request.post('/report/deliveredDaily/monitor', data);
};

/**
 * @description: SHEIN日派送数据监控导出
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const reportSheinDeliveredDailyExport = (data) => {
  return request.post('/report/deliveredDaily/export', data);
};

/**
 * @description: SHEIN日派送数据监控票数详情查询
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const reportSheinDeliveredDailyByDate = (data) => {
  return request.post('/report/deliveredDaily/oneDay', data);
};

// SHEIN日派送数据监控票数详情查询 导出
export const reportSheinDeliveredDailyByDateExport = (data) => {
  return request.post('/report/deliveredDaily/oneDay/export', data);
};

// 多次签收分页
export const manyTimesSignpage = (data) => {
  return request.post('/manyTimesSign/pageQuery', data);
};
// 多次签收导出
export const manyTimesExportQuery = (data) => {
  return request.post('/manyTimesSign/exportQuery', data);
};
// 分拨错发率 分页查询
export const allocationErrorRate = (data) => {
  return request.post('/orderWaybillTimeRecord/allocationErrorRate', data);
};
// 分拨错发率导出
export const exportAllocationErrorRate = (data) => {
  return request.post('/orderWaybillTimeRecord/exportAllocationErrorRate', data);
};
// 轨迹分页
export const trackPushLogPage = (data) => {
  // return request.post('/trackPushLog/page', data);
  return request({
    url: `/trackPushLog/page`,
    method: 'post',
    data,
    baseURL: baseURLAdmin
  });
};
// 轨迹看板
export const trackPushLogDashboard = (data) => {
  return request.get('/trackPushLog/dashboard', data);
};
// 轨迹节点
export const getTrackStateMap = (data) => {
  return request.get('/dictLang/dictCode/trackNode', data);
};
// 轨迹导出
export const trackPushLogExport = (data) => {
  return request.post('/trackPushLog/export', data);
};
/**
 * 轨迹推送
 */
export const trackPushLogPUsh = (data) => {
  return request({
    method: 'POST',
    url: '/manual/push/track',
    data,
    baseURL: baseURL5
  });
};
// 查询接收用户
export const getUserList = (data) => {
  return request.post('/message/userList', data);
};
// 添加地址
export const addCustomerAddress = (data) => {
  return request.post('/order/addCustomerAddress', data);
};
// 获取地址列表
export const pageCustomerAddress = (data) => {
  return request.post('/order/pageCustomerAddress', data);
};
// 获取转运中心列表
export const getSiteTransfer = () => {
  return request.get('/site/transfer');
};
// 指定转运中心的数据
export const getTransferDashboard = (id) => {
  return request.get(`/report/transferDashboard/${id}`);
};
// 指定转运中心的下载单号
export const getTransferDashdownload = (id) => {
  return request.get(`/report/transferDashboard/download/${id}`);
};
// 查询车签号车牌号
export const vehicleTagNumber = (data) => {
  return request.post('/loadUnloadSum/vehicleTagNumber/page', data);
};
// 派送装车监控  ==> 分页
export const loadUnloadSumpageQuery = (data) => {
  return request.post('/loadUnloadSum/pageQuery', data);
};
// 查询网点信息及用户信息
export const loadUnloadSumuserAndSite = (data) => {
  return request.post('/loadUnloadSum/userAndSite/page', data);
};
// 派送装车监控  汇总导出
export const loadUnloadSumExportAll = (data) => {
  return request.post('/loadUnloadSum/exportLoadMonitor', data);
};
// 派送装车监控  导出明细列表
export const loadUnloadSumExportDetail = (data) => {
  return request.post('/loadUnloadSum/export', data);
};
// 轨迹停更监控  分页
export const stationaryTrackMonitorPage = (data) => {
  return request.post('/report/stationaryTrackMonitor/page', data);
};
// 轨迹停更监控  导出
export const stationaryTrackMonitorPageexport = (data) => {
  return request.post('/report/stationaryTrackMonitor/export', data);
};
// 退件数据监控  分页
export const returnDataMonitoringPage = (data) => {
  return request.post('/report/returnDataMonitoring/pageList', data);
};
// 退件数据监控  明细列表
export const returnDataMonitoringdetail = (data) => {
  return request.post('/report/returnDataMonitoring/detail/pageList', data);
};
// 退件数据监控  导出
export const returnDataMonitoringexport = (data) => {
  return request.post('/report/returnDataMonitoring/export', data);
};
// 退件数据监控  分明细列表导出
export const returnDataMonitoringdetailexport = (data) => {
  return request.post('/report/returnDataMonitoring/detail/export', data);
};
// 客户列表下拉框
export const getCustomerListList1 = () => {
  return request.get('/report/collectData/getCustomerList?type=1');
};
// 揽收监控报表
export const endcollectDataPage = (data) => {
  return request.post('/report/collectData/list', data);
};
// 揽收监控报表   明细
export const endcollectDataDetail = (data) => {
  return request.post('/report/collectData/detail', data);
};
// 揽收监控报表   明细导出
export const endcollectDataDetailExport = (data) => {
  return request.post('/report/collectData/export/detail', data);
};
// 揽收监控报表  导出
export const endcollectDataPageExport = (data) => {
  return request.post('/report/collectData/export/list', data);
};
// 每日派送数据监控优化  客户列表请求
export const deliveredDailyCustomers = (data) => {
  return request.get('/report/deliveredDaily/customers', data);
};
// 查看装卸车签名与图片
export const loadUnloadSumqueryPictureList = (data) => {
  return request.post('/loadUnloadSum/queryPictureList', data);
};
// 分拨滞留监控分页查询
export const dispatchPage = (data) => {
  return request.post('/report/dispatch/page', data);
};
// 分拨滞留监控明细
export const dispatchPageDetail = (data) => {
  return request.post('/report/dispatch/detail', data);
};
// 分拨滞留监控明细
export const dispatchPageexportDetail = (data) => {
  return request.post('/report/dispatch/export/detail', data);
};
//  退件操作记录  分页查询
export const returnOperationRecordpageQuery = (data) => {
  return request.post('/returnOperationRecord/pageQuery', data);
};
//  退件操作记录  查询操作人接口
export const queryOperationUser = (data) => {
  return request.post('/returnOperationRecord/queryOperationUser', data);
};
//  退件操作记录  导出
export const returnOperationRecordexportAll = (data) => {
  return request.post('/returnOperationRecord/exportAll', data);
};
// 静态时效监控相关  分页查询
export const fullLinkMonitorgetPage = (data) => {
  return request.post('/report/fullLinkMonitor/getPage', data);
};
// 静态时效监控相关  分页查询
export const fullLinkMonitorexport = (data) => {
  return request.post('/report/fullLinkMonitor/export', data);
};

/**
 * @description 今日总操作数据
 * @param type
 * @returns {Promise<AxiosResponse<any>>}
 */
export const waybillDashboardOrderOperateData = (type) => {
  return request({
    url: `/waybillDashboard/orderOperateData?orderType=${type}`,
    method: 'get',
    baseURL: baseURLAdmin
  });
};

/**
 * @description 分拨操作数据
 * @param type
 * @returns {Promise<AxiosResponse<any>>}
 */
export const waybillDashboardTransferOperateData = (data) => {
  return request({
    url: `/waybillDashboard/transferOperateData?startTime=${data.startTime}&endTime=${data.endTime}&level=${data.level}&orderType=${data.orderType}`,
    method: 'get',
    baseURL: baseURLAdmin
  });
};

/**
 * @description 客户下单情况
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const waybillDashboardCustomerWaybillInfo = (data) => {
  return request({
    url: `/waybillDashboard/customerWaybillInfo`,
    method: 'post',
    data,
    baseURL: baseURLAdmin
  });
};

/**
 * @description 问题件操作数据
 * @param type
 * @returns {Promise<AxiosResponse<any>>}
 */
export const waybillDashboardProblemData = (type) => {
  return request({
    url: `/waybillDashboard/problemData?orderType=${type}`,
    method: 'get',
    baseURL: baseURLAdmin
  });
};

// 服务商订单 更新
export const apiProviderOrderUpdateWaybillInfo = (data) => {
  return request({
    url: '/provider/order/update/waybillInfo',
    method: 'post',
    data,
    baseURL: baseURLAdmin
  });
};
// 起点仓
export const warehouseSite = () => {
  return request.post('/site/get/transit/warehouse/site');
};
// 获取运单列表数据
export const orderWaybillPage = (data) => {
  return request.post('/orderWaybill/page', data);
};
// 编辑地址
export const editAddress = (data) => {
  return request.post('/address/depot/update', data);
};
// 删除地址
export const delAddress = (data) => {
  return request.post('/address/depot/deleted', data);
};
// 导出地址
export const exportAddress = (data) => {
  return request.post('/address/depot/export', data);
};
// 获取地址列表数据
export const addressPage = (data) => {
  return request({
    url: '/address/depot/page',
    method: 'post',
    data,
    baseURL: baseURLAdmin
  });
};
// 添加地址
export const addAddressMange = (data) => {
  return request.post('/address/depot/add', data);
};
// 签收经纬度报表导出2  签收经纬度监控
export const signLocationPage = (data) => {
  return request.post('/report/signLocation/page', data);
};
// 签收经纬度报表导出2
export const signLocationexportSign = (data) => {
  return request.post('/report/signLocation/export', data);
};
// 派送装车监控  汇总导出
export const tfkDirExport = (data) => {
  return request.post('/loadUnloadSum/exportAll/tfkDir', data);
};
// 强制签收审核  分页
export const lgStorageForceSignpage = (data) => {
  return request({
    url: `/lgStorageForceSign/page`,
    method: 'post',
    data,
    baseURL: baseURLAdmin
  });
};
// PC-服务质量-审核
export const lgStorageForceSignAudits = (data) => {
  // return request.post('/lgStorageForceSign/audits', data);
  return request({
    url: `/lgStorageForceSign/audits`,
    method: 'post',
    data,
    baseURL: baseURLAdmin
  });
};
// PC-服务质量-审核  根据州查询网点
export const selectlnfoByState = (data) => {
  return request.post('/lgStorageForceSign/selectlnfoByState', data);
};
// PC-服务质量-审核  根据网点查询派送员
export const selectlnfoBySite = (data) => {
  return request.post('/lgStorageForceSign/selectlnfoBySite', data);
};
// 州省查询
export const selectState = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/addressSelector/v1/optionSelectForState',
    data,
    baseURL: baseURL1,
    isLoading: isBool
  });
};
// 城市查询
export const selectCity = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/addressSelector/v1/optionSelectForCity',
    data,
    baseURL: baseURL1,
    isLoading: isBool
  });
};
// 获取业务模块单量日报数据
export const getDataOrderDailyApi = (data) => {
  return request({
    method: 'POST',
    url: '/report/orderDaily/getData',
    data,
    baseURL: baseURLAdmin
  });
};
// 指定转运中心的数据
export const getTransFerData = (data) => {
  return request.post('/report/transferDashboard/getTransFerData', data);
};
//  获取当前用户的转运中心所属州和转运中心
export const queryUserTransferAndState = () => {
  return request.get('/site/queryUserTransferAndState');
};
export const queryTransferByState = (data) => {
  return request.get(`/site/queryTransferByState/${data}`);
};
// 转运看板优化v3
export const transferDashboardgetTransFerData = (data) => {
  return request({
    method: 'POST',
    url: '/report/transferDashboard/getTransFerData',
    data,
    baseURL: baseURLAdmin
  });
};
// 快递运营看板  ==> 单量走势
export const singleQuantitytrend = (data) => {
  return request({
    method: 'POST',
    url: '/customerWaybillDashboard/waybillTrend',
    data,
    baseURL: baseURLAdmin
  });
};
// 快递运营看板  ==> 分页查询本地客户信息（排除szanjun）
export const getLocalCustomerPage = (data) => {
  return request({
    method: 'POST',
    url: '/customer/getLocalCustomerPage',
    data,
    baseURL: baseURLAdmin
  });
};
// 快递运营看板  ==> 分页查询商户根据客户ID
export const getMerchantPageByCustomerId = (data) => {
  return request({
    method: 'POST',
    url: '/customerMerchant/getMerchantPageByCustomerId',
    data,
    baseURL: baseURLAdmin
  });
};
// 快递运营看板  ==> 客户单量统计
export const getCustomerWaybillDashboard = (data) => {
  return request({
    method: 'POST',
    url: '/customerWaybillDashboard/waybillStatistics',
    data,
    baseURL: baseURLAdmin
  });
};
// 快递运营看板  ==> 资源投入看板
export const getresourceInputboard = () => {
  return request({
    method: 'get',
    url: '/operationBoard/resourceInput/list',
    baseURL: baseURLAdmin
  });
};
// 快递运营看板  ==> 指标达成看板
export const gettargetAchieveBoard = () => {
  return request({
    method: 'get',
    url: '/operationBoard/targetAchieve/list',
    baseURL: baseURLAdmin
  });
};
// 支付凭证审核
export const payFinanceCheck = (data) => {
  return request({
    method: 'post',
    url: '/cargoOrder/financeCheck',
    data,
    baseURL: baseURLAdmin
  });
};

/**
 * @description 大件订单上传支付凭证
 * @param data
 * @returns {AxiosPromise}
 */
export const addPaymentVoucherApi = (data) => {
  return request({
    method: 'post',
    url: '/order/addPaymentVoucher',
    data,
    baseURL: baseURLAdmin
  });
};
