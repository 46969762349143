var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-form",
                {
                  ref: "formQuery",
                  attrs: { "label-position": "top", size: "small" },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.usernameId"),
                                prop: "accountCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseInput"
                                  ),
                                },
                                model: {
                                  value: _vm.queryForm.params.accountCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "accountCode",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.params.accountCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "customerManagements.AccountName"
                                ),
                                prop: "acountName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseInput"
                                  ),
                                },
                                model: {
                                  value: _vm.queryForm.params.acountName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "acountName",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.params.acountName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("customerManagements.realName"),
                                prop: "realName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseInput"
                                  ),
                                },
                                model: {
                                  value: _vm.queryForm.params.realName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryForm.params,
                                      "realName",
                                      $$v
                                    )
                                  },
                                  expression: "queryForm.params.realName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("userManage.provider"),
                                prop: "orgName",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "operationCenter.PleaseSelect"
                                    ),
                                    clearable: "",
                                    filterable: "",
                                  },
                                  on: { change: _vm.changeProvider },
                                  model: {
                                    value: _vm.queryForm.params.orgName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "orgName",
                                        $$v
                                      )
                                    },
                                    expression: "queryForm.params.orgName",
                                  },
                                },
                                _vm._l(_vm.providerList, function (item) {
                                  return _c("el-option", {
                                    key: item.orgId,
                                    attrs: {
                                      label: item.orgName,
                                      value: item.orgName,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("navbar.siteName"),
                                prop: "orgName",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "operationCenter.PleaseSelect"
                                    ),
                                    clearable: "",
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.queryForm.params.siteId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "siteId",
                                        $$v
                                      )
                                    },
                                    expression: "queryForm.params.siteId",
                                  },
                                },
                                _vm._l(_vm.siteList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.AccountRole"),
                                prop: "roleName",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "operationCenter.PleaseSelect"
                                    ),
                                    clearable: "",
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.queryForm.params.roleName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "roleName",
                                        $$v
                                      )
                                    },
                                    expression: "queryForm.params.roleName",
                                  },
                                },
                                _vm._l(
                                  _vm.vendorRoleList1,
                                  function (item, ind) {
                                    return _c("el-option", {
                                      key: ind,
                                      attrs: {
                                        label:
                                          item.roleName +
                                          "(" +
                                          item.portugal +
                                          ")",
                                        value: item.roleName,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.status"),
                                prop: "accountState",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "operationCenter.PleaseSelect"
                                    ),
                                    clearable: "",
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.queryForm.params.accountState,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "accountState",
                                        $$v
                                      )
                                    },
                                    expression: "queryForm.params.accountState",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: "0",
                                    attrs: {
                                      label: _vm.$t("GlobalSetObj.all"),
                                      value: null,
                                    },
                                  }),
                                  _c("el-option", {
                                    key: "1",
                                    attrs: {
                                      label: _vm.$t("GlobalSetObj.enable"),
                                      value: 1,
                                    },
                                  }),
                                  _c("el-option", {
                                    key: "2",
                                    attrs: {
                                      label: _vm.$t("GlobalSetObj.stop"),
                                      value: 0,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-search",
                                size: "small",
                                type: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.searchTable()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("GlobalSetObj.search")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-card",
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:supplierManagement:vendorAccountNumber:enable",
                          expression:
                            "'btn:supplierManagement:vendorAccountNumber:enable'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        disabled: _vm.isEnable,
                        size: "small",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.updateUserName(1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("GlobalSetObj.enable")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:supplierManagement:vendorAccountNumber:stop",
                          expression:
                            "'btn:supplierManagement:vendorAccountNumber:stop'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        disabled: _vm.isEnable,
                        size: "small",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.updateUserName(0)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("GlobalSetObj.stop")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:supplierManagement:vendorAccountNumber:add",
                          expression:
                            "'btn:supplierManagement:vendorAccountNumber:add'",
                          arg: "remove",
                        },
                      ],
                      staticStyle: { float: "right" },
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.addUserName(1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("GlobalSetObj.add")) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  attrs: { data: _vm.tableData, border: "" },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", type: "selection", width: "55" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("operationCenter.ind"),
                      align: "center",
                      width: "60px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.total -
                                    ((_vm.queryForm.pageNumber - 1) *
                                      _vm.queryForm.pageSize +
                                      scope.$index)
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("userManage.usernameId"),
                      "show-overflow-tooltip": true,
                      align: "left",
                      "min-width": "120",
                      prop: "code",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("div", [_vm._v(_vm._s(scope.row.code))])]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("customerManagements.AccountName"),
                      "show-overflow-tooltip": true,
                      align: "center",
                      "min-width": "160",
                      prop: "username",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.username))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("customerManagements.realName"),
                      "min-width": "160",
                      prop: "realName",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("navbar.promisseType"),
                      "min-width": "160",
                      prop: "userTypeName",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("GlobalSetObj.Estado"),
                      "min-width": "160",
                      prop: "stateName",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("userManage.provider"),
                      "show-overflow-tooltip": true,
                      align: "left",
                      "min-width": "160",
                      prop: "orgName",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.orgName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("navbar.siteName"),
                      "min-width": "160",
                      prop: "siteName",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("GlobalSetObj.AccountRole"),
                      "show-overflow-tooltip": false,
                      align: "center",
                      "min-width": "140",
                      prop: "roleName",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.roleName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("GlobalSetObj.status"),
                      "show-overflow-tooltip": false,
                      align: "center",
                      "min-width": "120",
                      prop: "userState",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.accountState === 1
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("GlobalSetObj.enable")) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                            scope.row.accountState === 0
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("GlobalSetObj.stop")) + " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("GlobalSetObj.settlementType"),
                      "min-width": "160",
                      prop: "settlementType",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("home.receiveMsgType"),
                      "min-width": "150",
                      prop: "receiveMsgTypes",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "CPF", "min-width": "120", prop: "cpf" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("GlobalSetObj.CreatePeople"),
                      "show-overflow-tooltip": false,
                      align: "center",
                      "min-width": "160",
                      prop: "createUserName",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.createUserName)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("GlobalSetObj.CreationTime"),
                      "show-overflow-tooltip": false,
                      align: "center",
                      "min-width": "160",
                      prop: "createTime",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.createTime))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("GlobalSetObj.operate"),
                      align: "center",
                      fixed: "right",
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      content: _vm.$t(
                                        "userManage.resetPassword"
                                      ),
                                      effect: "dark",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-popconfirm",
                                      {
                                        attrs: {
                                          title: _vm.$t(
                                            "GlobalSetObj.AreYouSureResetPassword"
                                          ),
                                        },
                                        on: {
                                          confirm: function ($event) {
                                            return _vm.resetPassword(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              slot: "reference",
                                              size: "mini",
                                              type: "text",
                                            },
                                            slot: "reference",
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "iconfont icon-ResetPassword hoverClass",
                                              staticStyle: {
                                                "font-size": "25px",
                                                color: "#666",
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      content: _vm.$t("GlobalSetObj.edit"),
                                      effect: "dark",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permit",
                                            rawName: "v-permit:remove",
                                            value:
                                              "btn:supplierManagement:vendorAccountNumber:add",
                                            expression:
                                              "'btn:supplierManagement:vendorAccountNumber:add'",
                                            arg: "remove",
                                          },
                                        ],
                                        staticStyle: {
                                          "margin-left": "10px",
                                          color: "#666",
                                        },
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addUserName(2, scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-edit-outline hoverClass",
                                          staticStyle: { "font-size": "25px" },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    staticStyle: { margin: "10px 0" },
                    attrs: {
                      "current-page": _vm.queryForm.pageNumber,
                      "page-size": _vm.queryForm.pageSize,
                      "page-sizes": [10, 20, 50, 100],
                      total: _vm.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageNumber", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageNumber", $event)
                      },
                      "update:pageSize": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageSize", $event)
                      },
                      "update:page-size": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageSize", $event)
                      },
                      "current-change": _vm.getCurrentTableData,
                      "size-change": _vm.getSizeChangeTableData,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.userForm.title,
            visible: _vm.userForm.visible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.userForm, "visible", $event)
            },
            close: _vm.closeUserNameForm,
          },
        },
        [
          _c(
            "el-form",
            {
              key: _vm.userForm.visible,
              ref: "serFormRef",
              attrs: {
                model: _vm.userForm,
                rules: _vm.userRules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("customerManagements.AccountName"),
                            prop: "username",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.editType === 2,
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                              autocomplete: "off",
                              maxlength: "50",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.userForm.username,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.userForm,
                                  "username",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "userForm.username",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("customerManagements.realName"),
                            prop: "realName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                              autocomplete: "off",
                              maxlength: "50",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.userForm.realName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.userForm,
                                  "realName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "userForm.realName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("navbar.promisseType"),
                            prop: "userType",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.handleUserType },
                              model: {
                                value: _vm.userForm.userType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.userForm, "userType", $$v)
                                },
                                expression: "userForm.userType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v(_vm._s(_vm.$t("navbar.anjunAccount"))),
                              ]),
                              _c("el-radio", { attrs: { label: 4 } }, [
                                _vm._v(_vm._s(_vm.$t("navbar.stateAccount"))),
                              ]),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("navbar.providerAccount"))
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("userManage.provider"),
                            prop: "orgId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              on: { change: _vm.handleProviderName },
                              model: {
                                value: _vm.userForm.orgId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.userForm, "orgId", $$v)
                                },
                                expression: "userForm.orgId",
                              },
                            },
                            _vm._l(_vm.providerList, function (item) {
                              return _c("el-option", {
                                key: item.orgId,
                                attrs: {
                                  label: item.orgName,
                                  value: item.orgId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("navbar.opretaSite"),
                            prop: "siteId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "operationCenter.PleaseSelect"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              on: { change: _vm.handleSiteName },
                              model: {
                                value: _vm.userForm.siteId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.userForm, "siteId", $$v)
                                },
                                expression: "userForm.siteId",
                              },
                            },
                            _vm._l(_vm.siteList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.AccountRole"),
                            prop: "roleId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "operationCenter.PleaseSelect"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.userForm.roleId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.userForm, "roleId", $$v)
                                },
                                expression: "userForm.roleId",
                              },
                            },
                            _vm._l(_vm.vendorRoleList, function (item, ind) {
                              return _c("el-option", {
                                key: ind,
                                attrs: {
                                  label: item.roleName,
                                  value: item.roleId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.TypeOfSettlement
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.settlementType"),
                                prop: "settlementType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                  },
                                  model: {
                                    value: _vm.userForm.settlementType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.userForm,
                                        "settlementType",
                                        $$v
                                      )
                                    },
                                    expression: "userForm.settlementType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: 1,
                                    attrs: { label: "RPA", value: "RPA" },
                                  }),
                                  _c("el-option", {
                                    key: 2,
                                    attrs: { label: "CNPJ", value: "CNPJ" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "CPF", prop: "cpf" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                              clearable: "",
                            },
                            model: {
                              value: _vm.userForm.cpf,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.userForm,
                                  "cpf",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "userForm.cpf",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.isShowState
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("operationTips.belongState"),
                                prop: "state",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "operationCenter.PleaseSelect"
                                    ),
                                    clearable: "",
                                    filterable: "",
                                    multiple: "",
                                  },
                                  model: {
                                    value: _vm.userForm.state,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.userForm, "state", $$v)
                                    },
                                    expression: "userForm.state",
                                  },
                                },
                                _vm._l(_vm.StateList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.label,
                                      value: item.label,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("home.receiveMsgType"),
                            prop: "receiveMsgType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                clearable: "",
                                multiple: "",
                              },
                              model: {
                                value: _vm.receiveMsgType,
                                callback: function ($$v) {
                                  _vm.receiveMsgType = $$v
                                },
                                expression: "receiveMsgType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("home.receiveMsgType00"),
                                  value: 0,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.editType === 1
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("navbar.accountPosword"),
                                prop: "password",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseInput"
                                  ),
                                  autocomplete: "off",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.userForm.password,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.userForm,
                                      "password",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "userForm.password",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editType === 1
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("customerManagements.checkPwd"),
                                prop: "confirmPassword",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "GlobalSetObj.pleaseInput"
                                  ),
                                  autocomplete: "off",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.userForm.confirmPassword,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.userForm,
                                      "confirmPassword",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "userForm.confirmPassword",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v(_vm._s(_vm.$t("roleManage.confirm")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.userForm.visible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("roleManage.cancel")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }