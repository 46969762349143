var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "basicTable" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20, type: "flex" } },
        [
          [0, 2].includes(_vm.status)
            ? [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value:
                          "but:compensationManagement:compensationList:details:add",
                        expression:
                          "'but:compensationManagement:compensationList:details:add'",
                        arg: "remove",
                      },
                    ],
                    attrs: {
                      type: "primary",
                      size: "small",
                      disabled: !_vm.isAddDisabled,
                    },
                    on: {
                      click: function ($event) {
                        _vm.isAdd = true
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("GlobalSetObj.append")))]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "info",
                      size: "small",
                      disabled: !_vm.isAddDisabled,
                    },
                    on: {
                      click: function ($event) {
                        _vm.isImport = true
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("CenterForSorting.Import")))]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permit",
                        rawName: "v-permit:remove",
                        value:
                          "but:compensationManagement:compensationList:details:remove",
                        expression:
                          "'but:compensationManagement:compensationList:details:remove'",
                        arg: "remove",
                      },
                    ],
                    attrs: {
                      type: "danger",
                      size: "small",
                      disabled: !_vm.multipleSelection.length,
                    },
                    on: { click: _vm.onRemove },
                  },
                  [_vm._v(_vm._s(_vm.$t("ClaimsManagement.Remove")))]
                ),
              ]
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { type: "info", size: "small", disabled: !_vm.id },
              on: { click: _vm.onExport },
            },
            [_vm._v(_vm._s(_vm.$t("collectionCenter.export")))]
          ),
          _c("StatisticiansTipsCmp", { attrs: { arr: _vm.arr } }),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("span", { staticClass: "label" }, [
                _vm._v(
                  _vm._s(_vm.$t("compensationManagement.orderStatus")) + "："
                ),
              ]),
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    size: "small",
                    placeholder: _vm.$t("operationCenter.PleaseSelect"),
                  },
                  on: { change: _vm.searchOrderType },
                  model: {
                    value: _vm.orderType,
                    callback: function ($$v) {
                      _vm.orderType = $$v
                    },
                    expression: "orderType",
                  },
                },
                _vm._l(_vm.optionList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.name, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.isShow
            ? _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c("span", { staticClass: "label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("compensationManagement.currentOrderStatus")
                      ) + "："
                    ),
                  ]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        size: "small",
                        placeholder: _vm.$t("operationCenter.PleaseSelect"),
                      },
                      on: { change: _vm.searchOrderType },
                      model: {
                        value: _vm.realOrderType,
                        callback: function ($$v) {
                          _vm.realOrderType = $$v
                        },
                        expression: "realOrderType",
                      },
                    },
                    _vm._l(_vm.optionList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.name, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "virtual-scroll",
        {
          key: _vm.tableKey,
          ref: "virtualScroll",
          attrs: {
            data: _vm.tableData,
            "item-size": 50,
            "key-prop": "barCode",
          },
          on: {
            change: function (virtualList) {
              return (_vm.list = virtualList)
            },
          },
        },
        [
          _c(
            "el-table",
            {
              key: _vm.tableKey,
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.list,
                "max-height": "300",
                border: "",
                "row-key": "barCode",
                "highlight-selection-row": true,
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("virtual-column", {
                attrs: { width: "55", type: "selection" },
              }),
              _c("virtual-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.serial"),
                  width: "100",
                  type: "index",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ChannelManage.oddNumbers"),
                  prop: "barCode",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.customerNum"),
                  prop: "customerNumber",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("collectionCenter.AnjunTrackingNumber"),
                  prop: "waybillNumber",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("orderCenterCont.DeclaredValue"),
                  prop: "totalDeclaredValue",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("compensationManagement.orderStatus"),
                  prop: "statusText",
                  width: "160",
                },
              }),
              _vm.isShow
                ? [
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t(
                          "compensationManagement.currentOrderStatus"
                        ),
                        prop: "realTimeWaybillStatusText",
                        width: "160",
                      },
                    }),
                  ]
                : _vm._e(),
              _vm.$i18n.locale === "zh"
                ? _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("ClaimsManagement.CurrentTrajectory"),
                      prop: "trajectory",
                      width: "160",
                    },
                  })
                : _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("ClaimsManagement.CurrentTrajectory"),
                      prop: "trajectoryPu",
                      width: "160",
                    },
                  }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ClaimsManagement.TrackTime"),
                  prop: "trajectoryTime",
                  width: "160",
                },
              }),
              !_vm.isColumn
                ? [
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.infeatSite"),
                        prop: "deliverWarehouseStationName",
                        width: "160",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.entrega"),
                        prop: "deliverStationName",
                        width: "160",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("orderCenterCont.deliveryPerson"),
                        prop: "dispatchName",
                        width: "160",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("orderCenterCont.deliveryTime"),
                        prop: "deliveredTime",
                        width: "160",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.SigningTime"),
                        width: "160",
                        prop: "signTime",
                      },
                    }),
                  ]
                : [
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("collectionCenter.LanShouPeople"),
                        prop: "dispatchName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("collectionCenter.lanshouT"),
                        prop: "deliveredTime",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("collectionCenter.siteName"),
                        prop: "deliverStationName",
                      },
                    }),
                  ],
              _vm.isShow
                ? [
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t(
                          "compensationManagement.earliestInboundOutlets"
                        ),
                        prop: "firstConsigneeSiteName",
                        width: "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t(
                          "compensationManagement.earliestEntryTime"
                        ),
                        prop: "firstConsigneeTime",
                        width: "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t(
                          "compensationManagement.latestWarehouseEntryPoints"
                        ),
                        prop: "realTimeSiteName",
                        width: "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t(
                          "compensationManagement.latestStorageTime"
                        ),
                        prop: "consigneeTime",
                        width: "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("newOrder.RecipientState"),
                        prop: "receiveState",
                        width: "120",
                      },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      ![0, 2].includes(_vm.status)
        ? _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                staticStyle: { margin: "10px 0" },
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  "current-page": _vm.queryTable.pageNumber,
                  "page-sizes": [10, 20, 50, 100, 500],
                  "page-size": _vm.queryTable.pageSize,
                  total: _vm.total,
                  background: "",
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.queryTable, "pageNumber", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.queryTable, "pageNumber", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.queryTable, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.queryTable, "pageSize", $event)
                  },
                  "current-change": _vm.getCurrentTableData,
                  "size-change": _vm.getSizeTableData,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("orderCenterCont.AddOrder"),
            visible: _vm.isAdd,
            width: "500px",
            "append-to-body": "",
            "close-on-click-modal": false,
            "before-close": _vm.onClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isAdd = $event
            },
          },
        },
        [
          _c("el-input", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: {
              type: "textarea",
              placeholder: _vm.$t("trajectory.multipleOrders"),
              autosize: { minRows: 5, maxRows: 10 },
            },
            model: {
              value: _vm.orderList,
              callback: function ($$v) {
                _vm.orderList = $$v
              },
              expression: "orderList",
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.onClose } },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.add },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.determine")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("CenterForSorting.Import"),
            visible: _vm.isImport,
            width: "500px",
            "append-to-body": "",
            "close-on-click-modal": false,
            "before-close": _vm.onClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isImport = $event
            },
          },
        },
        [
          _c("ImportExcelCmp", {
            attrs: {
              "import-tmp-url":
                "https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/upload-file/workOrder/%E7%B4%A2%E8%B5%94%E6%A8%A1%E6%9D%BF-202410241030-20241025T104256.xlsx?q-sign-algorithm=sha1&q-ak=AKID2vc8UcWIfqskPtEKDtMfookXDD1e6JP6&q-sign-time=1729824176;2045184176&q-key-time=1729824176;2045184176&q-header-list=host&q-url-param-list=&q-signature=263596cbd8621e8468e0302c265fa0254729354d",
              "key-and-val-obj": _vm.excelKeyAndValue,
            },
            on: { change: _vm.onImportClick },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }