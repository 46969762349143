var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { staticClass: "box-card" }, [
    _c("div", { staticClass: "fontClsBold02" }, [
      _vm._v(_vm._s(_vm.$t("changepwd.slaNumber"))),
    ]),
    _c("div", {
      staticStyle: { height: "230px", width: "100%" },
      attrs: { id: "main" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }